/*
 * bgImageFallback - Allows for fallback to a default image or a default background color if main image not found.
 * Fallback background image or color is shown initially while the main image is loading, then swapped out. If the
 * main image errors out, then the fallback is already shown. Main image is loaded via javascript HTMLImageElement,
 * which stores in the browsers local cache for when we set it via the css background-image.
 *
 * Usage:
 * <div class='parent-div-that-recieves-background-image'>
 *   <bg-image-fallback src="{{backgroundImage}}" fallback-src="" fallback-color="#00539b">
 *   </bg-image-fallback>
 * </div>
 *
 * Parameters:
 * src - Source of the image you want to display
 * fallback-src - Source of image you want to fall back to if image specified in src can't be found
 * fallback-color - Hex color to display as background if fallback-src isn't specified
 *
 */

import { Component, OnChanges, Input, ElementRef, Renderer2, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { hasValue } from 'src/app/core/helpers/app.helpers';

@Component({
  selector: 'bg-image-fallback',
  template: '<img #image [hidden]="true"/>',
})
export class BGImageFallbackComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public src: string;
  @Input() public fallbackSrc: string;
  @Input() public fallbackColor: string;
  @ViewChild('image') public imageElement;

  private errorListener;
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit() {
    // We need to listen for loading errors
    const el = this.imageElement.nativeElement;
    this.errorListener = this.renderer.listen(el, 'error', () => this.handleImageError());
  }

  public ngOnChanges() {
    if (hasValue(this.src)) {
      this.setElementSource(this.src);
    } else if (hasValue(this.fallbackSrc)) {
      this.setElementSource(this.fallbackSrc);
    } else if (hasValue(this.fallbackColor)) {
      this.setParentStyle('background', `#${this.fallbackColor}`);
    }
  }

  private getElementSource() {
    return this.imageElement.nativeElement.getAttribute('src');
  }

  private setElementSource(src) {
    // Set the parent style to the background image right away.
    // We don't want to wait for the fallback check before actually seeing the image
    if (hasValue(src)) {
      this.setParentStyle('background-image', `url('${src}')`);
      this.renderer.setAttribute(this.imageElement.nativeElement, 'src', src);
    } else {
      this.removeParentStyle('background-image');
      this.renderer.removeAttribute(this.imageElement.nativeElement, 'src');
    }
  }

  private handleImageError() {
    const elSrc = this.getElementSource();
    // Did we try and load the src?
    if (elSrc == this.src) {
      // We should try and load the fallback src
      if (hasValue(this.fallbackSrc)) {
        this.setElementSource(this.fallbackSrc);
        return;
      }
    }

    // If we got this far we will be setting the background to a color
    if (hasValue(this.fallbackColor)) {
      this.setParentStyle('background', `#${this.fallbackColor}`);
    }
  }

  private setParentStyle(style: string, value: string) {
    this.renderer.setStyle(this.element.nativeElement.parentNode, style, value);
  }
  private removeParentStyle(style: string) {
    this.renderer.removeStyle(this.element.nativeElement.parentNode, style);
  }

  public ngOnDestroy() {
    // Clear all listeners when this component is destroyed
    if (hasValue(this.errorListener)) {
      this.errorListener();
      this.errorListener = null;
    }
  }
}
