/* eslint-disable prefer-const */
/* eslint-disable max-lines */
import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  ContentChild,
  QueryList,
  ViewRef,
  ViewChildren,
  ElementRef,
  Renderer2,
  ViewContainerRef,
  TemplateRef,
  EventEmitter,
} from '@angular/core';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import { BaseComponent } from 'src/app/components/base-component.component';
import { StorageService } from 'src/app/core/services/storageService';
import {
  TST_WIDGET_LABELS,
  TST_THEMES,
  TST_DATE_FORMAT,
  ORDINAL_NUMBER_STRING,
  FlightFormDefs,
  AirDestination,
  AirDestinationList,
  AirPassengers,
  AirPreferences,
  AirSearch,
  TSTDatePicker,
  ALBERTA_AIRPORTS,
  GUEST_INCREMENTORS,
  REGEX,
} from 'src/app/models/tst/tst';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { TSTService } from 'src/app/core/services/tstService';
import _ from 'underscore';
import { NgForm, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbCalendar, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap/dropdown/dropdown';
import { format, addDays, getDate, getMonth, getYear } from 'date-fns';
/* import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material'; */
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'tst-custom-form',
  templateUrl: 'tstCustomForm.template.html',
  styleUrls: ['tstCustomForm.scss'],
  providers: [NgbDropdownConfig],
  host: {
    '(window:keyup)': 'debounceKeyEvents($event)',
  },
})
export class TSTCustomFormComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  public TST_THEMES = TST_THEMES;
  public TST_WIDGET_LABELS = TST_WIDGET_LABELS;

  public themes;
  public TST_DATE_FORMAT;
  public mq;
  @Input() public tstType: string;
  @Output() public destinationFieldCleared = new EventEmitter<any>();
  @Input() public airportCode;
  @Input() public defaultDepartureAirport: string;

  /**
   * formgroups for gathering data and functionality
   * */
  public formFields: any[];
  public flightOptions: FormGroup;
  public flightForm: FormGroup;
  public addedFlightGroup: FormGroup;
  public formOptions: FormGroup;
  public formGuestOptions: FormGroup;
  public staticFieldsGroup: FormGroup;
  public formOptionControls: any[];
  public selectedFormOptions: any;
  public radioControl;
  public returnFormGroup: FormGroup;
  public onewayFormGroup: FormGroup;
  public multicityFormGroup: FormGroup;
  public selectedFormGroup: FormGroup;
  public selectedForm: any;
  public fromCityStatus: FormControl;
  public toCityStatus: FormControl;
  public dateFormat;

  /**
   * dates
   */
  public date = new Date();
  public tomorrow = new Date();
  public dayAfterTomorrow = new Date();
  public todaysDate = new Date();
  public minDepartDate = new Date();
  public latestFlightDate = new Date();
  public datepickerPlacement = { placement: 'bottom-left' };

  /**
   * ngbDate vars (ng-bootstrap date type)
   * For date range picker
   */

  /* public dialogRef: MatDialogRef<TSTCustomFormComponent>; */
  public dates: TSTDatePicker;
  @ViewChild('dropDownElDepart')
  public dropDownElDepart: ElementRef<NgbDropdown>;
  @ViewChild('dropDownElReturn')
  public dropDownElReturn: ElementRef<NgbDropdown>;
  @ViewChild('dropDownElDepartSingle')
  public dropDownElDepartSingle: ElementRef<NgbDropdown>;

  /**
   * misc
   */
  public options = [];
  private api: any;
  public showFlightClass: boolean;
  public getElRefFromQueryListTimeout: number;
  public afterViewCheckedDone: boolean;
  public sessionValues: any[];
  public isMultiCity: boolean;
  public isReturn: boolean;
  public isOneway: boolean;
  public dialogOpen: boolean;
  public addedFlights: any;
  public numAdultsFormItem;
  public currentAutoComplete;
  public currentAutoCompleteSelection;
  public autoCompleteOpen;
  public autoCompleteCurrentForm;
  public autoCompleteCurrentIndex;
  public downCount: number = 0;
  public destination: AirDestination;
  public destinations: AirDestinationList;
  public preferences: AirPreferences;
  public passengers: AirPassengers;
  public airSearch: AirSearch;
  private roundtripUrlParams: URLSearchParams;
  private multicityUrlParams: URLSearchParams;
  private onewayUrlParams: URLSearchParams;
  public isDatePickerTouchUi: boolean;
  public linkerParm;
  public ABAirports;
  public classOfService;
  public guestTypeCount: number = 1;
  public passengerIncrementorData;
  public flightsDatePickerPlaceholder: Date;
  public flightTabSection: string = '';

  /**
   * Autocomplete elements/objects
   */
  @ViewChild('form')
  public form: ElementRef;
  @ViewChildren('flightFormElements')
  public flightFormElements: QueryList<any>;
  @ViewChild('autoCompleteLeaveCity')
  public autoCompleteLeaveCity: ElementRef;
  @ViewChild('autoCompletetoCity')
  public autoCompletetoCity: ElementRef;
  @ViewChild('fromCityInput')
  public fromCityInput: ElementRef;
  @ViewChild('toCityInput')
  public toCityInput: ElementRef;
  @ViewChild('staticFlightFields')
  public staticFlightFields: ElementRef;
  @ViewChild('returnDatePickerElRef')
  public returnDatePickerElRef: ElementRef;
  @ViewChildren('tstAddedFlightFields')
  public tstAddedFlightFields: QueryList<ElementRef>;
  @ViewChildren('autoCompleteFromUL')
  public autoCompleteFromUL: QueryList<ElementRef>;
  @ViewChildren('autoCompleteToUL')
  public autoCompleteToUL: QueryList<ElementRef>;
  @ViewChildren('datePickerAddedFields')
  public datePickerAddedFields: QueryList<ElementRef>;
  @ViewChildren('injectedFlightsContainer')
  public injectedFlightsContainer: QueryList<ElementRef>;
  @ContentChild('dateRangeDialog') public dpRange: TemplateRef<any>;

  private keyPressDebounceSubject: Subject<any> = new Subject();

  /**
   * viewContainer/Templates for adding additional flights
   */
  @ViewChild('additionalFlightContainerRef', { read: ViewContainerRef })
  public additionalFlightContainerRef: ViewContainerRef;
  @ViewChild('addedFlightsTemplateRef')
  public addedFlightsTemplateRef: TemplateRef<ElementRef>;
  public addedFlightViewRef;

  @ViewChild('guestIncrementorViewContainer', { read: ViewContainerRef })
  public guestIncrementorViewContainer: ViewContainerRef;
  @ViewChild('guestIncrementorTemplateRef')
  public guestIncrementorTemplateRef: TemplateRef<ElementRef>;
  public packagesGuestsDialog: TemplateRef<ElementRef>;
  public packagesGuests: ElementRef;
  public travellerHiddenFields: ElementRef;
  @ViewChild('dateRangeDialog') public dateRangeDialog: TemplateRef<ElementRef>;
  @ViewChild('singleDateDialog')
  public singleDateDialog: TemplateRef<ElementRef>;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService,
    private fb: FormBuilder,
    public tstService: TSTService,
    private renderer: Renderer2,
    private flightFormDefs: FlightFormDefs,

    private storageService: StorageService,
    public clndr: NgbCalendar,
    private el: ElementRef,
    private dts: TSTDatePicker,
    public dropdownConfig: NgbDropdownConfig
  ) {
    super();
    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.updateMQ();
      })
    ).attach(this);
    this.TST_WIDGET_LABELS = TST_WIDGET_LABELS;
    this.ABAirports = ALBERTA_AIRPORTS;
    this.themes = TST_THEMES;
    this.passengerIncrementorData = GUEST_INCREMENTORS.flight;
    this.TST_DATE_FORMAT = TST_DATE_FORMAT;
    this.formFields = this.flightFormDefs.formDefs;
    this.fromCityStatus = new FormControl(false, Validators.requiredTrue);
    this.toCityStatus = new FormControl(false, Validators.requiredTrue);

    // initialize url param objects
    this.destination = new AirDestination(null, null, null);
    this.destinations = new AirDestinationList(this.destination);
    // return flight and multi/one-way expect diff codes
    this.classOfService = '';
    this.preferences = new AirPreferences({
      nonstop: false,
      flexDate: false,
      classOfService: this.classOfService,
    });
    this.passengers = new AirPassengers(1, 0, 0, []);
    this.airSearch = new AirSearch(this.destinations, this.preferences, this.passengers, '');
    this.roundtripUrlParams = new URLSearchParams(); // ============= in IE: 'URLSearchParams' is undefined
    this.multicityUrlParams = new URLSearchParams();
    this.onewayUrlParams = new URLSearchParams();

    this.api = {
      autocomplete: {
        name: 'autocomplete',
        endpoint: 'https://albertateachers.tstllc.net/web-services/common/autocomplete',
        parameters: {
          options: ['"airports"', '"excludeAirports"'],
          callback: '',
          lon: 0,
          lat: 0,
          term: '',
        },
      },
      return: {
        name: 'return',
        endpoint: 'https://albertateachers.tstllc.net/flight/search',
        formGroup: this.returnFormGroup,
        parameters: [
          { f: '' }, //		Departure City Code
          { ft: 'a' }, //		From Type
          { d: '' }, //		Departure Date,
          { dt: 'at' }, //	Departure Date
          { t: '' }, //		Arrival City Code
          { tt: 'a' }, //		To Type
          { r: '' }, //		Return Date
          { rt: 'at' }, //	Return Time
          { a: 1 }, //		Number of Adults
          { s: 0 }, //		Number of Seniors
          { m: 0 }, //		Children and their ages
          { st: 'rt' }, //	Search Type (roundtrip[rt], oneway(ow), multicity[mc])
          { ns: 'f' }, //		Not stop? t/f (true/false)
          // { cs: 'econ' }, //	travelling class e/b/f (econ/bus/first) empty for all class
          { fd: 'f' }, //		flexible date t/f
        ],
      },
      multicity: {
        name: 'multicity',
        endpoint: 'https://albertateachers.tstllc.net/flight/assets/results',

        formGroup: this.multicityFormGroup,
        parameters: [
          { st: 'multicity' },
          { airSearch: this.airSearch },
          { packaged: false },
          /* { airCanadaPromotionCode: '' }, */
        ],
      },
      oneway: {
        name: 'oneway',
        endpoint: 'https://albertateachers.tstllc.net/flight/assets/results',
        formGroup: this.onewayFormGroup,
        parameters: [
          { st: 'oneway' },
          { destination: this.destination.destination },
          { preferences: this.preferences },
          { passengers: this.passengers },
          /* { airCanadaPromotionCode: '' }, */
        ],
      },
    };

    this.returnFormGroup = this.api.return.formGroup = this.setupSubmitFormGroups(this.api.return.parameters, this.returnFormGroup);
    this.onewayFormGroup = this.api.oneway.formGroup = this.setupSubmitFormGroups(this.api.oneway.parameters, this.onewayFormGroup);
    this.multicityFormGroup = this.api.multicity.formGroup = this.setupSubmitFormGroups(
      this.api.multicity.parameters,
      this.multicityFormGroup
    );
    this.selectedForm = this.api.return;
    this.selectedFormGroup = this.returnFormGroup;
    this.showFlightClass = this.afterViewCheckedDone = false;
    this.selectedFormOptions = { formOptions: ['st', 'rt', 'Round+Trip'] };
    this.addedFlights = [];

    this.isReturn = true;
    this.sessionValues = [];
    _.each(
      this.formFields,
      (f) => {
        if (f.name == 'addedFlights') f.flights = this.addedFlights;
        const key = this.storageService.session.get(f.name);
        const val = {};
        val[f.name] = key ? key : null;
        val[f.name] && this.sessionValues.push(val);
      },
      this
    );

    this.tomorrow.setDate(this.todaysDate.getDate() + 1);
    this.dayAfterTomorrow.setDate(this.todaysDate.getDate() + 2);
    this.minDepartDate = this.tomorrow;
    this.latestFlightDate.setFullYear(this.todaysDate.getFullYear() + 1);
    this.autoCompleteOpen = false;
    this.dateFormat = format;

    // NgbsDate's
    this.dates = dts;
    this.dates.dateRngCalendar = clndr;

    this.dates.dateRngFromDate = null;
    this.dates.dateRngToday = this.dates.dateRngCalendar.getToday();
    this.dates.dateRngMin = this.dates.dateRngCalendar.getToday();
    this.dates.dateRngMax = this.dates.dateRngCalendar.getNext(this.dates.dateRngCalendar.getToday(), 'd', 330);
    this.dates.fromDateLong = this.tstService.convertNgDateToLongDate(this.dates.dateRngFromDate);
    this.dates.toDateLong = this.tstService.convertNgDateToLongDate(this.dates.dateRngToDate);
    this.dates.msg = 'Pick your departure date';

    this.flightsDatePickerPlaceholder = this.tstService.convertNgDateToLongDate(this.dates.dateRngMin);

    this.flightsDatePickerPlaceholder.setDate(this.flightsDatePickerPlaceholder.getDate() + 1);
  }

  public ngOnInit() {
    /** Use form builder to create new forms and its fields */
    this.flightForm = this.fb.group({
      staticFields: this.fb.group({}),
      flightOptions: this.fb.group({}),
      addedFlights: this.fb.group({}),
      formGuestOptions: this.fb.group({}),
    });

    this.passengerIncrementorData.form = this.flightForm.get('formGuestOptions') as FormGroup;

    this.keyPressDebounceSubject
      .pipe(debounce(() => timer(30)))
      .subscribe((event) => {
        this.onkeyEvent(event);
      })
      .attach(this);

    // variable to reference form groups
    this.flightOptions = this.flightForm.get('flightOptions') as FormGroup;
    this.addedFlightGroup = this.flightForm.get('addedFlights') as FormGroup;

    /**
     * formFields defined in tst.module
     * formFields object array defines settings our form items
     * so can loop through and create ng formGroups and controls
     * */
    _.each(this.formFields, (field, i) => {
      let formGrp = this.flightForm.get('staticFields') as FormGroup;
      if (field.name == 'fromCity') field.statusControl = this.fromCityStatus;
      if (field.name == 'toCity') field.statusControl = this.toCityStatus;
      if (field.name != 'formOptions' && field.name != 'numGuests') {
        const val = hasValue(field.sessionKey) ? this.storageService.session.get(field.sessionKey) : '';
        if (hasValue(formGrp)) this.addFormItemToArray(field, formGrp, val);

        this.sessionValues.length &&
          val &&
          _.each(
            this.sessionValues,
            (sv: any) => {
              const keys = Object.keys(sv);
              keys[0] == field.name && this.updateSubmitForm(field.name, [val, 'METRO_CODE']);
            },
            this
          );
      }
      if (field.name == 'numGuests') {
        this.formGuestOptions = this.flightForm.get('formGuestOptions') as FormGroup;

        if (hasValue(formGrp)) {
          this.addControlToGroup('numAdults', new FormControl(1), this.formGuestOptions);
          field.guests.numAdultsControl = this.formGuestOptions.get('numAdults');
          this.addControlToGroup('numChildren', new FormControl(0), this.formGuestOptions);
          field.guests.numChildrenControl = this.formGuestOptions.get('numChildren');
          this.addControlToGroup('numSeniors', new FormControl(0), this.formGuestOptions);
          field.guests.numSeniorsControl = this.formGuestOptions.get('numSeniors');

          formGrp = this.fb.group(this.formGuestOptions.controls);
          field.formGroup = field.control = this.formGuestOptions;
        }
      }
      if (field.name == 'formOptions') {
        const optionControl = this.createFormControl(field);
        if (hasValue(field.formControls) && hasValue(this.flightOptions) && optionControl && field.formControls.length) {
          const selectedValue = field.formControls.find((c) => c.selected).value;
          field.control = optionControl;
          this.formOptionControls = field.formControls;
          this.addControlToGroup(field.name, field.control, this.flightOptions, selectedValue);
          this.radioControl = this.flightForm.get('flightOptions').get('formOptions');
        }
      }
    });

    this.setUpFormFields();
    this.updateMQ();
  }

  public ngOnChanges(changes: SimpleChanges) {
    /**
     * airportCode is selected in the tstSearch component's autocomplete(s) OR univeral autoComplete component
     * So we need to update/verify flight form on changes
     */
    if (changes.airportCode) {
      const code =
        changes.airportCode.currentValue && changes.airportCode.currentValue[0] ? this.airportCode[0].replace(/[()]/g, '') : null;
      this.toCityStatus.setValue(code ? true : false);
      const field = code
        ? _.find(this.formFields, (ff) => {
            return ff.name == 'toCity';
            // tslint:disable-next-line:indent
          })
        : null;
      if (field) {
        field.airport.label = this.airportCode.input;
        this.destination.destination.to.code = code;
      }
    }
    this.updateMQ();
  }

  public ngAfterViewInit() {
    // update form fields when view ready
    this.setUpFormFields();

    // update flight type option filters
    this.optionsFormControl();

    // update autocomplete fields
    this.addFlightFieldUpdate();

    // subscribe to changes in Querylist, so we can update new  autocomplete fields when multicity
    this.flightFormElements.changes
      .subscribe((list) => {
        this.addFlightFieldUpdate();
      })
      .attach(this);

    /**
     * When to and from location fields change, we need to
     * validate that it includes an airport code
     * */
    this.flightForm.controls.staticFields.valueChanges
      .subscribe((change) => {
        if (change.fromCity) {
          const fc = REGEX.extractAirportCode.test(change.fromCity);
          this.destination.destination.from.code = fc ? change.fromCity : '';
          this.fromCityStatus.setValue(fc ? true : false);
        }
        if (change.toCity) {
          const tc = REGEX.extractAirportCode.test(change.toCity);
          this.destination.destination.to.code = tc ? change.toCity : '';
          this.toCityStatus.setValue(tc ? true : false);
        }
      })
      .attach(this);

    // Guests/traveller selector component (formStepper)
    this.formGuestOptions.valueChanges
      .subscribe((change) => {
        this.incrementorChanges(change);
      })
      .attach(this);

    // gtm
    // this.tstService.gtmInitTracking(this.form.nativeElement, this);
  }

  // flight options changes (return/oneway/multiCity)
  private optionsFormControl() {
    this.flightOptions.valueChanges
      .subscribe((change) => {
        /**
         * if there are added multicity flights, and switching back to rt or ow,
         * submit won't work unless the added flights are removed.
         */
        if (this.addedFlights.length && change.formOptions && hasValue(change.formOptions.length > 1) && change.formOptions[1] != 'mt') {
          _.each(this.addedFlights, (af: any) => {
            this.removeFlight(af);
          });
        }
        this.selectedFormOptions = change;
        this.isMultiCity = this.selectedFormOptions.formOptions[1] == 'mc' ? true : false;
        this.isReturn = this.selectedFormOptions.formOptions[1] == 'rt' ? true : false;
        this.isOneway = this.selectedFormOptions.formOptions[1] == 'ow' ? true : false;
        // leave class of service blank for all class always
        // this.classOfService = this.isReturn ? 'Econ' : 'Economy';
        // this.preferences.preferences.classOfService = this.classOfService;

        // update validators for return and leave date controls
        const leaveCtrl = this.flightForm.get(['staticFields', 'datePickerLeave']);
        const returnCtrl = this.flightForm.get(['staticFields', 'datePickerRange']);
        if (this.isMultiCity || this.isOneway) {
          leaveCtrl.setValidators([Validators.required]);
          returnCtrl.clearValidators();
          returnCtrl.updateValueAndValidity();
        } else {
          returnCtrl.setValidators([Validators.required]);
          leaveCtrl.clearValidators();
          leaveCtrl.updateValueAndValidity();
        }

        this.selectedForm = this.isReturn ? this.api.return : this.isOneway ? this.api.oneway : this.api.multicity;
        this.selectedFormGroup = this.selectedForm.formGroup;
      })
      .attach(this);
  }

  /** Form setup functions */
  private setupSubmitFormGroups(parameters, formGroup: FormGroup) {
    formGroup = new FormGroup({});
    let keys = null;
    _.each(
      parameters,
      (param: any) => {
        keys = Object.keys(param);
        const control: FormControl = new FormControl(keys[0]);
        control.setValue(param[keys[0]]);
        formGroup.addControl(keys[0], control);
      },
      this
    );
    formGroup.addControl('fromCityStatus', this.fromCityStatus);
    formGroup.addControl('toCityStatus', this.toCityStatus);
    return formGroup;
  }

  private setUpFormFields() {
    _.each(
      this.formFields,
      (field) => {
        if (field.name == 'fromCity') {
          field.elRef = this.fromCityInput;
          field.autoCompleteList = this.autoCompleteLeaveCity;
          field.statusControl = this.fromCityStatus;
        }
        if (field.name == 'toCity') {
          field.elRef = this.toCityInput;
          field.autoCompleteList = this.autoCompletetoCity;
          field.statusControl = this.toCityStatus;
        }
        if (field.name == 'datePickerLeave') {
          field.datePicker.matDatePicker = 'datePickerLeave';
        }
        if (field.name == 'returnDatePicker') {
          field.datePicker.matDatePicker = 'returnDatePicker';
        }
        if (field.name == 'numGuests') {
          this.numAdultsFormItem = field;
        }
        if (field.name == 'formOptions') {
          _.each(field.formControls, (fc) => {
            this.updateFormOptions(fc, field);
          });
        }
      },
      this
    );
  }
  /** end Form setup functions */

  /** Validation functions/Template functions */
  public validateLocationField(field: any = null) {
    return field && field.control && REGEX.extractAirportCode.test(field.control.value) ? true : false;
  }

  public validateDateField(date: any = null) {
    let valid = false;
    if (date) {
      valid = REGEX.validDateFormat.test(date);
    }
    return valid;
  }

  /** Range DatePicker Functions */
  /**
   * Handles logic when a day is clicked on date-range datepicker
   * @param date NgbDate
   */
  public dateRangeSelection(date: NgbDate, data: any = null) {
    // let elementToTrigger = null;
    data.closeCurrent = false;
    data.ngbd = this.tstService.convertToNgbDate(date, this.dates.dateRngCalendar);

    // local function to call this.updateSubmitForm()
    const updateSubmitForm = () => {
      this.updateSubmitForm(data && data.field ? data.field.name : '', [
        format(this.dates.fromDateLong, TST_DATE_FORMAT),
        data && data.index ? data.index : null,
        format(this.dates.toDateLong ? this.dates.toDateLong : addDays(this.dates.fromDateLong, 1), TST_DATE_FORMAT),
      ]);
    };

    /**
     * If depart datepicker picked
     */
    if (data.flightDirection == 'depart') {
      const targetElement = this.form.nativeElement.querySelectorAll(
        'div.tst-form__control.tst-form__control--wrap.tst-form__control--datepicker.tst-form__control--dropDownElDepart.notValid.dateEmpty'
      );

      if (targetElement.length === 1) {
        $('.departureDateErrorMessage').remove();
        $('.returnDateErrorMessage').remove();
      }
      this.tstService.doDepart(this.dates, data);
      this.latestFlightDate = this.dates.fromDateLong;
      updateSubmitForm();
    } else {
      /**
       * If return datepicker picked
       */
      $('.returnDateErrorMessage').remove();

      this.tstService.doReturn(this.dates, data);
      updateSubmitForm();
    }

    // data.element && data.element.close();
    data.closeCurrent && data.element && data.element.close();
    data.elementToTrigger && data.elementToTrigger.open();
  }

  /**
   * Handles logic when a day is clicked on single datepicker
   * @param date NgbDate
   */
  public datePickerSelection(date: NgbDate, data: any = null) {
    // local function to call this.updateSubmitForm()
    const updateSubmitForm = () => {
      this.updateSubmitForm(name, [
        format(theDate, TST_DATE_FORMAT),
        data && data.index ? data.index : null,
        format(this.dates.toDateLong ? this.dates.toDateLong : addDays(this.dates.fromDateLong, 1), TST_DATE_FORMAT),
      ]);
    };

    const ngbd = this.tstService.convertToNgbDate(date, this.dates.dateRngCalendar);

    const targetElement = this.form.nativeElement.querySelectorAll(
      'div.tst-form__control.tst-form__control--wrap.tst-form__control--datepicker.' +
        (this.flightTabSection === 'oneway' || this.flightTabSection === 'multicity' ? 'tst-form__control--dropDownElDepart' : '') +
        'notValid.dateEmpty'
    );

    if (targetElement.length === 1) {
      $('.departureDateErrorMessage').remove();
    }

    let name = data && data.field && data.field.name ? data.field.name : '';

    this.dates.dateRngFromDate = ngbd;
    let theDate = this.tstService.convertNgDateToLongDate(this.dates.dateRngFromDate);
    this.dates.dateRngToDate = this.dates.endDate = null;
    updateSubmitForm();

    // for multicity datepicker
    if (name == '' && data.field.label) {
      const flight = _.find(this.addedFlights, (f: any) => {
        return f.id == data.index; // Warning is expected
      });
      if (flight) flight.date = theDate;
      name = data.field.label;
    } else {
      this.dates.fromDateLong = this.dates.startDate = theDate;
    }
    this.minDepartDate = theDate;
    updateSubmitForm();
    data.element && data.element.close();
  }

  // gets proper english for addedForms (1st, 2nd, 10th, 21st etc.)
  public getOrdinalNumber(id: number = null) {
    id = hasValue(id) ? id + 2 : null;
    let ordinal = '';
    if (hasValue(id)) ordinal = ORDINAL_NUMBER_STRING(id);
    return ordinal;
  }

  // handles dialog for traveller incrementor
  public formStepperDialogState(event) {}

  // handles output from formStepper component
  public formStepperCallback(event) {
    if (hasValue(event.values.numAdults)) this.passengers.adults = event.values.numAdults;
    if (hasValue(event.values.numChildren)) this.passengers.children = event.values.numChildren;
    if (hasValue(event.values.numSeniors)) this.passengers.seniors = event.values.numSeniors;

    const hiddenField = this.form.nativeElement.querySelector('.tst-search__incrementor-hidden');

    if (hiddenField) {
      // get trigger if exists
      const trigger = event.control.props.trigger ? event.control.props.trigger : null;
      const keys = Object.keys(event.values);
      this.passengers.minor = [];
      this.passengers.infants = 0;
      this.passengers.youths = 0;
      _.each(
        keys,
        (a: any) => {
          const val: number = event.values[a];
          if (/(childAges)+/g.test(a)) {
            if (val < 2) this.passengers.infants = this.passengers.infants + 1;
            if (val > 11) this.passengers.youths = this.passengers.youths + 1;
            this.passengers.minor.push({ age: val });
          }
        },
        this
      );
    }
  }
  /** end Validation functions/Template functions */

  /**
   * Dyanamically created form elements Methods
   */
  private addFlightGroup(index): FormGroup {
    const additionalCityFrom: FormControl = new FormControl({
      name: `additionalCityFrom-${index}`,
    });
    const additionalCityTo: FormControl = new FormControl({
      name: `additionalCityTo-${index}`,
    });
    const additionalFlightDate: FormControl = new FormControl({
      name: `additionalFlightDate-${index}`,
    });
    return this.fb.group({
      additionalCityFrom,
      additionalCityTo,
      additionalFlightDate,
    });
  }

  // funtion adds new flight when 'add flight' clicked
  public addGroup(): void {
    const index = this.addedFlights.length;
    // create new form group
    const newformGroup = this.addFlightGroup(index);
    const destination = new AirDestination(null, null, null);
    this.destinations.push(destination);
    const formFields: any[] = [];

    // main object to manage new form groups
    const addedFlight = {
      id: this.addedFlights.length,
      form: this.flightForm,
      formGroup: newformGroup,
      formFields: formFields,
      viewRef: null,
      visible: false,
      removed: false,
      airSearch: this.airSearch,
      minDate: this.minDepartDate,
      date: null,
    };

    // add list of objects to manage our new form
    addedFlight.formFields = [
      {
        type: 'autocomplete',
        control: newformGroup.get(['additionalCityFrom']),
        label: 'fromCity',
        formGroup: newformGroup,
        autoCompleteList: null,
        open: false,
        elementRefs: {
          class: 'tst-form__autocomplete--from-' + (index + 1),
        },
        options: { label: null, code: null },
        airport: { label: null, code: null },
        params: {
          destination: new AirDestination('', '', ''),
        },
      },
      {
        type: 'autocomplete',
        control: newformGroup.get(['additionalCityTo']),
        label: 'toCity',
        formGroup: newformGroup,
        autoCompleteList: null,
        open: false,
        elementRefs: {
          class: 'tst-form__autocomplete--to-' + (index + 1),
        },
        options: { label: null, code: null },
        airport: { label: null, code: null },
        params: {
          destination: new AirDestination('', '', ''),
        },
      },
      {
        type: 'datepicker',
        control: newformGroup.get(['additionalFlightDate']),
        label: 'departDate',
        formGroup: newformGroup,
        elementRefs: {
          qlRef: this.datePickerAddedFields,
          class: 'tst-form__date-picker-input--to-' + (index + 1),
        },
        options: { label: null, code: null },
      },
    ];

    // added the hidden fields to new group (others were created in addFlightGroup())
    _.each(
      _.filter(addedFlight.formFields, (i) => {
        return i.type == 'hidden' ? i : false;
      }),
      (item, i) => {
        this.addControlToGroup(item.label, item.control, item.formGroup);
      }
    );

    // add to addedFlight group in main form
    this.addControlToGroup('flight' + index, newformGroup, this.addedFlightGroup);

    // injected the new fields into containerRef and return injected node
    const addedFlightContext = { $implicit: addedFlight };
    const _addedFlightViewRef: ViewRef = this.tstService.insertTemplateRef(
      this.additionalFlightContainerRef,
      this.addedFlightsTemplateRef,
      addedFlightContext
    );

    // inject into viewContainerRef
    let rootNode: any = null;
    if (hasValue(_addedFlightViewRef)) {
      addedFlight.viewRef = _addedFlightViewRef;
      rootNode = this.injectFlightFields(_addedFlightViewRef);
    }

    // insert returned rootnode into desired place in DOM
    if (rootNode) {
      const _node = this.tstService.placeRootNodes(rootNode[0], this.staticFlightFields, 'insertAdjacentElement', 'beforeend');

      // update object to manage all added flights
      addedFlight.visible = true;
      this.addedFlights.push(addedFlight);
    }
  }
  // end addGroup()

  public addFlightFieldUpdate() {
    const addedFlight = this.addedFlights[this.addedFlights.length - 1];
    // update new formfield
    if (hasValue(addedFlight) && hasValue(addedFlight.viewRef) && hasValue(addedFlight.viewRef.rootNodes)) {
      _.each(
        addedFlight.formFields,
        (element: any) => {
          // update auto complete fields
          if (hasValue(element.elementRefs) && hasValue(element.elementRefs.class)) {
            let _rootnode = null;
            if (addedFlight.viewRef.rootNodes) {
              _rootnode = this.staticFlightFields.nativeElement.querySelector('.' + element.elementRefs.class);
            }
            if (_rootnode) {
              element.autoCompleteList = _rootnode;
            }
          }
        },
        this
      );
    }
  }
  /** end Dyanamically created form elements */

  /**
   * Autocomplete functions
   **/
  public autocompleteTyping(value: string, target, index: any = null) {
    if (target.name == 'fromCity') target.statusControl.setValue(false);
    if (target.name == 'toCity') target.statusControl.setValue(false);
    let _targetAutoComplete = null;
    this.autoCompleteCurrentForm = target;
    this.autoCompleteCurrentIndex = index;
    // if was set when initialized, good otherwise find it again
    if (hasValue(target.autoCompleteList)) {
      _targetAutoComplete = target.autoCompleteList;
    } else {
      _.each(this.flightFormElements.toArray(), (item: any, i) => {
        _targetAutoComplete = item.nativeElement.querySelector('.' + target.elementRefs.class);
      });
    }

    // normalize autocomplete element <ul> we'll need to update
    const autoComp = (this.currentAutoComplete =
      hasValue(_targetAutoComplete) && hasValue(_targetAutoComplete.nativeElement)
        ? _targetAutoComplete.nativeElement
        : _targetAutoComplete);

    /* this.autoCompleteLeaveCity.nativeElement = this.currentAutoComplete; */
    if (hasValue(autoComp)) {
      if (value.length) {
        // flush current results
        if (autoComp.childNodes.length) {
          this.resetAutocomplete(autoComp);
        }
        if (value.length > 2) {
          autoComp.classList.remove('hide');
          target.open = true;
          this.autoCompleteOpen = true;
          this.downCount = 0;
          this.tstService
            .getAirports(value, this.api.autocomplete)
            .subscribe((data) => {
              if (data.airports) {
                // remove previously created dom elements from ac
                autoComp.innerHTML = '';
                this.options = data.airports;
                if (this.options.length) {
                  _.each(this.options, (item) => {
                    if (hasValue(item.label)) {
                      const airportLI = this.renderer.createElement('li');

                      if (item == this.options[0]) {
                        this.renderer.addClass(airportLI, 'activeAutoCompleteItem');
                        this.downCount++;
                      }

                      const airportAnchor = this.renderer.createElement('a');
                      const airportLiText = this.renderer.createText(item.label);
                      this.renderer.appendChild(airportAnchor, airportLiText);
                      this.renderer.appendChild(airportLI, airportAnchor);
                      this.renderer.appendChild(autoComp, airportLI);
                      this.renderer.setAttribute(airportLI, 'data-airport-code', item.value);
                      this.renderer.setAttribute(airportLI, 'data-airport-label', item.label);
                      this.renderer.setAttribute(airportLI, 'data-airport-type', item.type);
                    }
                  });
                  /* let unique;
									if (autoComp.children.length)
										unique = _.unique(autoComp.children); */
                }
              } else {
                autoComp.classList.add('hide');
              }
            })
            .attach(this);
        } else {
          value.length < 3 && autoComp.classList.add('hide');
        }
      } else {
        autoComp.classList.add('hide');
        this.destinationFieldCleared.emit(true);
      }
    }
  }

  public autoCompleteChoice(e: any, control: any, index: any = null) {
    control.open = !control.open;
    this.autoCompleteOpen = false;
    const _name = hasValue(control.name) ? control.name : hasValue(control.label) ? control.label : '';
    const _params = hasValue(control.params) ? control.params : null;

    // Remove error message if present
    if ($('.goingToErrorMessage').length) {
      $('.goingToErrorMessage').remove();
    }

    let airportType;
    let airportLabel;

    if (e.target.dataset && e.target.dataset.airportCode && e.target.dataset.airportLabel) {
      control.airport.code = e.target.dataset.airportCode;
      airportLabel = control.airport.label = e.target.dataset.airportLabel;
      airportType = control.airport.type = e.target.dataset.airportType;
    } else {
      // The item selected in flight autocomplete
      const selectedItem: HTMLElement = (e.target as HTMLAnchorElement).parentElement;
      control.airport.code = selectedItem.getAttribute('data-airport-code');
      airportLabel = control.airport.label = selectedItem.getAttribute('data-airport-label');
      airportType = control.airport.type = selectedItem.getAttribute('data-airport-type');
    }

    this.updateSubmitForm(_name, [airportLabel, airportType, index, _params]);
  }

  public resetAutocomplete(acField: any = null) {
    acField = acField ? acField : this.currentAutoComplete;
    if (acField && acField.childNodes) {
      _.each(acField.childNodes, (node) => {
        this.renderer.removeChild(acField, node);
      });
      acField.classList.add('hide');
    }
  }

  public debounceKeyEvents(event: any) {
    this.keyPressDebounceSubject.next(event);
  }
  /** not quite finished. ability to click down/up/return keys to select an airport */
  public onkeyEvent(event: any) {
    const ac = this.currentAutoComplete ? this.currentAutoComplete : null;
    if (this.autoCompleteOpen) {
      this.currentAutoCompleteSelection = null;

      if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
        event.key == 'ArrowUp' && this.downCount > 0 && this.downCount--;
        event.key == 'ArrowDown' && this.downCount++;
        const listCount = ac && ac.childNodes.length;
        this.currentAutoCompleteSelection = listCount ? ac.childNodes[this.downCount - 1] : null;
        this.currentAutoCompleteSelection && this.currentAutoCompleteSelection.classList.add('activeAutoCompleteItem');

        listCount &&
          _.each(
            ac.childNodes,
            (node: any, i) => {
              i != this.downCount - 1 && node.classList.remove('activeAutoCompleteItem');
            },
            this
          );

        // populate selection from autocomplete when using arrow up and down
        if (this.currentAutoCompleteSelection) {
          this.autoCompleteCurrentForm.airport.code = this.currentAutoCompleteSelection.getAttribute('data-airport-code');
          this.autoCompleteCurrentForm.airport.label = this.currentAutoCompleteSelection.getAttribute('data-airport-label');
          this.autoCompleteCurrentForm.airport.type = this.currentAutoCompleteSelection.getAttribute('data-airport-type');
        }
      }

      if (event.key == 'Enter') {
        if (!this.currentAutoCompleteSelection) {
          this.currentAutoCompleteSelection = ac.childNodes[this.downCount - 1];
        }

        this.autoCompleteChoice({ target: this.currentAutoCompleteSelection }, this.autoCompleteCurrentForm, this.autoCompleteCurrentIndex);
      }
    }
  }
  /** end Autocomplete functions */

  /** Functions that manage ux with form
   * && for preparing submission to TST
   * */
  private updateFormOptions(item: any, field: any) {
    item.formGroup = this.flightOptions;
    field.control = item.control;
  }

  private updateSubmitForm(control: any = null, options: any = null) {
    if (hasValue(control)) {
      if (control == 'fromCity') {
        if (!hasValue(options[2])) {
          this.destination.destination.from.code = options[0];
          this.destination.destination.from.type = options[1];
        } else {
          this.destinations.destinations[options[2] + 1].destination.from.code = options[0];
        }
      }
      if (control == 'toCity') {
        if (!hasValue(options[2])) {
          this.destination.destination.to.code = options[0];
          this.destination.destination.to.type = options[1];
        } else {
          this.destinations.destinations[options[2] + 1].destination.to.code = options[0];
        }
      }

      if (control == 'fromCity' || control == 'toCity') {
        const _control = _.find(this.formFields, (field: any) => {
          return field.name == control;
        });
        _control && _control.statusControl && _control.statusControl.setValue(true);
      }

      if (control == 'datePickerLeave') this.destination.departDate = this.destination.destination.depart.date = options[0];
      if (control == 'departDate') this.destinations.destinations[options[1] + 1].destination.depart.date = options[0];
      if (control == 'datePickerRange') {
        this.destination.departDate = this.destination.destination.depart.date = options[0];
        this.destination.returnDate = this.destination.destination.return.date = options[2];
      }
    }
  }

  /**
   *
   * @param form this.addedFlights - object array populated from addGroup()
   * @param e optional $event from template event
   */
  public removeFlight(form, e: any = null) {
    // remove from form group obj
    if (this.addedFlights.length) {
      _.each(this.addedFlights, (af: any, i) => {
        // re-index
        af.id = i;
      });
      const index = form.id;
      const _afArray = this.addedFlights[index];
      const _afGroup = _afArray.form.get('addedFlights') as FormGroup;
      const _afGroupChild = _afArray.form.get('addedFlights.flight' + index) as FormGroup;

      if (hasValue(_afGroupChild) && hasValue(_afGroup)) {
        // must pass control as string not reference to obj
        _afGroup.removeControl('flight' + index);
      }

      // remove AirSearch submission form
      if (this.destinations && this.destinations.destinations.length) {
        const _dests: any = this.destinations;
        this.destinations.destinations = _.filter(
          this.destinations.destinations,
          function (d, i) {
            return index + 1 != i;
          },
          index
        );
      }

      // remove from view
      if (hasValue(form.viewRef) && hasValue(form.viewRef.rootNodes.length)) {
        _.each(
          form.viewRef.rootNodes,
          function (node: any) {
            this.renderer.removeChild(node.parentElement, node);
            this.addedFlights[index].visible = false;
          },
          this
        );
      }

      // remove from our custom array
      this.addedFlights = _.filter(
        this.addedFlights,
        (a: any) => {
          return a.id !== index;
        },
        this
      );
    }
  }

  private checkForms(fg: FormGroup) {
    const controls = fg.controls;
    let invalid = [];

    const goingToErrorMessage = this.form.nativeElement.querySelectorAll('.goingToErrorMessage');
    const departureDateErrorMessage = this.form.nativeElement.querySelectorAll('.departureDateErrorMessage');
    const returnDateErrorMessage = this.form.nativeElement.querySelectorAll('.returnDateErrorMessage');

    for (const name in controls) {
      if (name === 'st') {
        if (controls['st'].value === 'oneway' || controls['st'].value === 'multicity') {
          this.flightTabSection = controls['st'].value;

          const cityEmptyInput = this.form.nativeElement.querySelectorAll(
            'div.tst-form__control.tst-form__control--wrap.tst-form__control--autocomplete.cityEmpty'
          );

          const dateEmptyInput = this.form.nativeElement.querySelectorAll(
            'div.tst-form__control.tst-form__control--wrap.tst-form__control--datepicker.dateEmpty'
          );

          if (cityEmptyInput.length === 1) {
            invalid.push('cityEmpty');

            if (goingToErrorMessage.length === 0) {
              $('.tst-form__item.tst-form__item--text.tst-form__item--toCity.ng-star-inserted.rt').append(
                '<div class="goingToErrorMessage"><strong>Select a Destination</strong></div>'
              );
              $('.goingToErrorMessage').addClass('tst-search__form--error-message color-red text-white');
            }
          }

          if (dateEmptyInput.length === 1) {
            invalid.push('dateEmpty');

            if (departureDateErrorMessage.length === 0) {
              $('.tst-form__item.tst-form__item--date.tst-form__item--datePickerLeave.bottom-left.rt.dropdown.ng-star-inserted').append(
                '<div class="departureDateErrorMessage"><strong>Select a Departure Date<strong></strong></div>'
              );

              $('.departureDateErrorMessage').addClass('tst-search__form--error-message color-red text-white');
            }
          }
        }
      } else {
        break;
      }
    }

    for (const name in controls) {
      // const currentControl = controls[name];
      // special case for date picker

      // Other
      if (controls[name].invalid) {
        invalid.push(name);

        switch (name) {
          case 't': {
            if (goingToErrorMessage.length === 1) {
              break;
            } else {
              $('.tst-form__item.tst-form__item--text.tst-form__item--toCity.ng-star-inserted').append(
                '<div class="goingToErrorMessage"><strong>Select a Destination</strong></div>'
              );
              $('.goingToErrorMessage').addClass('tst-search__form--error-message color-red text-white');
            }

            if (!this.mq.mobile) {
              if (this.mq.tablet) {
                $('.tst-form__item.tst-form__item--select.tst-form__item--numGuests.ng-star-inserted').css('margin-top', '1.5rem');
              }
            }
            break;
          }
          case 'd': {
            if (departureDateErrorMessage.length === 1) {
              break;
            } else {
              $('.tst-form__item.tst-form__item--date.tst-form__item--datePickerRangeFrom.bottom-left.dropdown.ng-star-inserted').append(
                '<div class="departureDateErrorMessage"><strong>Select a Departure Date</strong></div>'
              );

              $('.departureDateErrorMessage').addClass('tst-search__form--error-message color-red text-white');
            }
            break;
          }
          case 'r': {
            if (returnDateErrorMessage.length === 1) {
              break;
            } else {
              // Listen to the departure date change
              $('.tst-form__item.tst-form__item--date.tst-form__item--datePickerRangeTo.bottom-left.dropdown.ng-star-inserted').append(
                '<div class="returnDateErrorMessage"><strong>Select a Return Date</strong></div>'
              );

              $('.returnDateErrorMessage').addClass('tst-search__form--error-message color-red text-white');
            }
            break;
          }
        }
      }
    }
    return invalid;
  }

  public submitFlight(e: any) {
    const submitFormInvalidControls = this.checkForms(this.selectedForm.formGroup);
    const staticFormInvalidControls = this.checkForms(this.flightForm.get('staticFields') as FormGroup);

    if (submitFormInvalidControls.length > 0 || staticFormInvalidControls.length > 0) {
      return;
    }

    this.linkerParm = this.tstService.gtmTrackingCallback();

    if (hasValue(this.selectedForm.formGroup)) {
      /**
       * Return
       */
      if (this.isReturn) {
        const formValue = this.selectedFormGroup.value; // this.form should be a FormGroup
        const minors = this.passengers.minor && this.passengers.minor.length ? this.passengers.minor : null;
        for (const key in formValue) {
          if (formValue.hasOwnProperty(key)) {
            if (key == 'm') {
              if (minors && minors.length)
                for (let i = 0; i < minors.length; i++) {
                  let ages = '';
                  ages += 'm' + i;
                  this.roundtripUrlParams.set(ages, minors[i].age);
                }
              else {
                // old way, not sure if could occur?
                for (let i = 0; i < formValue[key]; i++) {
                  let ages = '';
                  ages += 'm' + i;
                  this.roundtripUrlParams.set(ages, '2');
                }
              }
            } else {
              const val = formValue[key];
              this.roundtripUrlParams.set(key, val);
            }
          }
        }

        // add GA code to params
        if (this.linkerParm) this.roundtripUrlParams.append('_ga', this.linkerParm);

        this.addSessionVars(this.roundtripUrlParams);

        window.location.assign(this.selectedForm.endpoint + '?' + this.roundtripUrlParams.toString());
      }
      /**
       * OneWay
       */
      if (this.isOneway) {
        const formValue = this.selectedForm.formGroup.value; // this.form should be a FormGroup

        hasValue(formValue.st) && this.onewayUrlParams.append('st', formValue.st);
        /* hasValue(formValue.airCanadaPromotionCode) &&
						formValue.airCanadaPromotionCode != '' &&
						this.onewayUrlParams.append(
							'airCanadaPromotionCode',
							formValue.airCanadaPromotionCode
						); */
        let _destinationParams = this.buildQueryParams(this.destination, 'destination', 0, this.onewayUrlParams);
        let _preferencesParams = this.buildQueryParams(formValue.preferences.preferences, 'preferences', 0, this.onewayUrlParams);

        let _passengerParams = this.buildQueryParams(formValue.passengers, 'passengers', 0, this.onewayUrlParams);

        // add GA code to params
        if (this.linkerParm) this.onewayUrlParams.append('_ga', this.linkerParm);

        this.addSessionVars(this.onewayUrlParams);

        window.location.assign(this.selectedForm.endpoint + '?' + this.onewayUrlParams.toString());
      }
      /**
       * Multicity
       */
      if (this.isMultiCity) {
        // Also verify the added flights
        if (this.addedFlights && this.addedFlights.length > 0) {
          const anyInvalidForms = (this.addedFlights as any[]).some((ev) => this.checkForms(ev.formGroup).length > 0);
          if (anyInvalidForms) {
            return;
          }
        }

        const formValue = this.selectedForm.formGroup.value; // this.form should be a FormGroup

        /* set single values */
        hasValue(formValue.st) && this.multicityUrlParams.append('st', formValue.st);
        /* hasValue(formValue.airCanadaPromotionCode) &&
						formValue.airCanadaPromotionCode != '' &&
						this.multicityUrlParams.append(
							'airCanadaPromotionCode',
							formValue.airCanadaPromotionCode
						); */
        hasValue(formValue.packaged) && this.multicityUrlParams.append('packaged', formValue.packaged);

        /* set nested object values values */
        if (hasValue(formValue.airSearch) && formValue.airSearch.destinations.destinations.length) {
          _.each(formValue.airSearch.destinations.destinations, (destination, i) => {
            let _destinationParams = this.buildQueryParams(destination, 'destination', i, this.multicityUrlParams);
          });
          _.each(formValue.airSearch.preferences, (preferences, i) => {
            let _preferencesParams = this.buildQueryParams(preferences, 'preferences', i, this.multicityUrlParams);
          });

          let _passengerParams = this.buildQueryParams(formValue.airSearch.passengers, 'passengers', 0, this.multicityUrlParams);
        }

        // add GA code to params
        if (this.linkerParm) this.multicityUrlParams.append('_ga', this.linkerParm);

        this.addSessionVars(this.multicityUrlParams);

        window.location.assign(this.selectedForm.endpoint + '?' + this.multicityUrlParams.toString());
      }
    }
  }

  private addSessionVars(urlParams: URLSearchParams) {
    const _st = urlParams.get('st');
    if (_st == 'rt') {
      this.storageService.session.put('fromCity', urlParams.get('f'));
      this.storageService.session.put('toCity', urlParams.get('t'));
      this.storageService.session.put('departDate', urlParams.get('d'));
      this.storageService.session.put('returnDate', urlParams.get('r'));
    }
    if (_st == 'oneway') {
      this.storageService.session.put('fromCity', urlParams.get('airSearch.from.code'));
      this.storageService.session.put('toCity', urlParams.get('airSearch.to.code'));
      this.storageService.session.put('departDate', urlParams.get('airSearch.depart.date'));
    }
    if (_st == 'multicity') {
      this.storageService.session.put('fromCity', urlParams.get('airSearch.destinations[0].from.code'));
      this.storageService.session.put('toCity', urlParams.get('airSearch.destinations[0].to.code'));
      this.storageService.session.put('departDate', urlParams.get('airSearch.destinations[0].depart.date'));
    }
  }

  public incrementorChanges(change) {
    // if incrementor at least three items are shown, decrease font size
    this.guestTypeCount = 0;
    const keys = Object.keys(change);
    keys.length &&
      _.each(keys, (key: any) => {
        this.guestTypeCount += change[key] > 0 ? 1 : 0;
      });
  }

  public updateGuestTypeCount() {
    // {'tst-form__count1':guestTypeCount >=0,'tst-form__count2':guestTypeCount >1,'tst-form__count3':guestTypeCount >2}
    if (this.guestTypeCount == 0) {
      return 'tst-form__count0';
    } else if (this.guestTypeCount == 1) {
      return 'tst-form__count1';
    } else if (this.guestTypeCount == 2) {
      return 'tst-form__count2';
    } else if (this.guestTypeCount == 3) {
      return 'tst-form__count3';
    } else {
      return 'tst-form__count4';
    }
  }

  /**
   *  needed for oneway and multicity as the url params for those flight types are nested objects
   */
  private buildQueryParams(param: any, paramName: string, index: number, paramObject: URLSearchParams) {
    const urp = paramObject.get('st');
    const prefix = 'airSearch.';
    if (paramName == 'preferences') {
      if (hasValue(param)) {
        for (const key in param) {
          if (param.hasOwnProperty(key)) {
            paramObject.append(prefix + paramName + '.' + key, param[key]);
          }
        }
      }
    }
    if (paramName == 'passengers') {
      if (hasValue(param)) {
        for (const key in param) {
          // if (param.hasOwnProperty(key) && key != 'minor') {
          if (param.hasOwnProperty(key)) {
            if (key != 'minor') {
              paramObject.append(prefix + paramName + '.' + key, param[key]);
            } else if (param[key].length) {
              for (let i = 0; i < param[key].length; i++) {
                paramObject.append(prefix + paramName + '.' + key + '[' + i + '].age', param[key][i].age);
              }
            }
          }
        }
      }
    }
    if (paramName == 'destination') {
      const dest = param.destination || param || null;
      if (hasValue(dest)) {
        for (const key in dest) {
          if (dest.hasOwnProperty(key)) {
            // to -> airSearch.destination[].to.xyz
            if (key == 'to') {
              if (urp == 'multicity') {
                paramObject.append(prefix + 'destinations[' + index + '].to.code', dest[key].code);
                paramObject.append(prefix + 'destinations[' + index + '].to.type', dest[key].type);
              }
              if (urp == 'oneway' && index == 0) {
                paramObject.append(prefix + 'to.code', dest[key].code);
                paramObject.append(prefix + 'to.type', dest[key].type);
              }
            }
            // from -> airSearch.destination[].from.xyz
            if (key == 'from') {
              if (urp == 'multicity') {
                paramObject.append(prefix + 'destinations[' + index + '].from.code', dest[key].code);
                paramObject.append(prefix + 'destinations[' + index + '].from.type', dest[key].type);
              }
              if (urp == 'oneway' && index == 0) {
                paramObject.append(prefix + 'from.code', dest[key].code);
                paramObject.append(prefix + 'from.type', dest[key].type);
              }
            }
            // depart -> airSearch.destination[].depart.xyz
            if (key == 'depart') {
              if (urp == 'multicity') {
                paramObject.append(prefix + 'destinations[' + index + '].depart.date', dest[key].date);
                paramObject.append(prefix + 'destinations[' + index + '].depart.time', dest[key].time);
                paramObject.append(prefix + 'destinations[' + index + '].depart.flex', dest[key].flex);
              }
              if (urp == 'oneway' && index == 0) {
                paramObject.append(prefix + 'depart.date', dest[key].date);
                paramObject.append(prefix + 'depart.time', dest[key].time);
                paramObject.append(prefix + 'depart.flex', dest[key].flex);
              }
            }
          }
        }
      }
    }
    return paramObject.toString();
  }
  /** end  manage ux/preparing submission to TST functions */

  /** Utility functions */
  private createFormControl(item: any, value: any = ''): FormControl {
    return this.fb.control({
      [item.name]: value,
    });
  }
  private addControlToGroup(item: string, control: any, fg: FormGroup, value: any = null) {
    value && control.setValue(value);
    fg.addControl(item, control);
  }

  private addFormItemToArray(item, formArray: FormGroup, value: any = ''): void {
    if (item.name == 'toCity' || item.name == 'fromCity') {
      item.control = this.createFormControl(item);
      item.airport.label = value;
      item.required && item.control.setValidators([Validators.required]);
    } else if (item.name == 'datePickerLeave' || item.name == 'datePickerRange' || item.name == 'returnDatePicker') {
      item.control = this.createFormControl(item, [value]);
      // item.required && item.control.setValidators([Validators.required]);
    } else {
      item.control = this.createFormControl(item);
    }

    formArray.addControl(`${item.name}`, item.control);
  }

  private moveRootNodes(rootNode, target, method, placementType) {
    clearTimeout(this.getElRefFromQueryListTimeout);
    if (hasValue(target) && hasValue(rootNode) && rootNode.length) {
      return this.tstService.placeRootNodes(rootNode[0], target, method, placementType);
    } else {
      this.getElRefFromQueryListTimeout = this.usingTimeout(
        window.setTimeout(() => this.moveRootNodes(rootNode, target, method, placementType), 200)
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  private injectFlightFields(ViewRef, cls: string = 'tst-form__form-items') {
    if (hasValue(ViewRef)) {
      const rootNodes = ViewRef.rootNodes;
      if (rootNodes.length) {
        const rootNode = this.tstService.getRootNodesFromView(rootNodes, 'classList', cls, 'array');

        return rootNode;
      }
    } else {
      return false;
    }
  }

  public findElementRefFromQLByClass(ql: any, cls: string) {
    let elementRef = null;
    if (hasValue(ql.length)) {
      _.each(ql, function (item: any) {
        elementRef = _.find(item, (el) => {
          const _el: any = el;
          return _.find(_el.classList, function (e: any) {
            e == cls;
          });
        });
      });
    }
    return elementRef;
  }

  // in template, we need to reference the hidden field's name to define them
  public getFormControlNameForHiddenFields(param) {
    const keys = Object.keys(param);
    return keys[0];
  }

  public getBindingForHiddenFields(param) {
    const keys = Object.keys(param);
    return hasValue(keys[1]) ? keys[1] : '';
  }

  /**
   * placement of date picker
   */
  public setPlacement() {
    let y = 'bottom';
    let x = 'left';
    const width = window.innerWidth;
    /* 	const position = this.elementRef.nativeElement.getBoundingClientRect();
		x = position.left <= width - position.right ? 'left' : 'right'; */
    return y + '-' + x;
  }

  // matchMedia
  private updateMQ() {
    this.mq = {
      mobile: this.matchMediaService.matches(MEDIA_TYPE.Phone),
      tablet: this.matchMediaService.matches(MEDIA_TYPE.Tablet),
      desktop: this.matchMediaService.matches(MEDIA_TYPE.Desktop),
    };
    this.isDatePickerTouchUi = !this.mq.mobile ? false : true;
    /* this.tstService.closeDialogs(this.matDialog); */
    //	this.dropdownConfig.placement = this.setPlacement();
  }
  /** end Utility functions */

  /** GTM */
  public handleEvent(e) {
    /**
     * // addEventListener looks for function called "handleEvent" if passed an obj
     */
    this.linkerParm = this.tstService.gtmTrackingCallback(e);
  }
  /** end GTM */
}
