<div [ngStyle]="{display: showLoading ? 'block': 'none'}"
     class="row o-payment__loading-page">
    <div class="col-12 text-center p-3">
        <div class="spinner-border text-primary"
             style="width: 3rem; height: 3rem;"
             role="status">
            <span class="sr-only">{{'policy-confirmation.activity_deal_cards.loading' | translate}}</span>
        </div>
    </div>
</div>
<div class="c-activity-card__section"
     *ngIf="this.cards?.length > 0">
    <div class="c-activity-card__title">
        <div>
            <h2>
                {{'policy-confirmation.activity_deal_cards.title' | translate | format:destination}}
            </h2>
        </div>
        <div class="c-activity-card__view-all"><strong><a href=" https://www.amatravel.ca/products-search-result/activities"
                   target="_blank">
                    {{'policy-confirmation.activity_deal_cards.view_all' | translate}}</a></strong></div>
    </div>
    <div class="c-activity-card__subtitle">
        <h2>
            {{'policy-confirmation.activity_deal_cards.subtitle' | translate}}</h2>
    </div>
    <div class="c-activity-card__container">
        <ng-container *ngFor="let card of this.cards">
            <div class="c-activity-card">
                <div class="c-activity-card__header">
                    <img src="{{card.imageUrl}}"
                         alt="{{card.title}}"
                         title="{{card.title}}" />
                </div>
                <div class="c-activity-card__body">
                    <star-rating *ngIf="card.rating"
                                 [rating]="card.rating" />
                    <strong>{{card.title}}</strong><br /><br />
                    <strong>{{'policy-confirmation.activity_deal_cards.activity_location' | translate}}
                    </strong>{{card.displayLocation}}<br />
                    <strong>{{'policy-confirmation.activity_deal_cards.activity_duration' | translate}} </strong>{{card.duration}}<br />
                </div>
                <div class="c-activity-card__footer text-centre">
                    <div class="c-activity-card__price"><strong>{{card.totalCost | currency}}</strong></div>
                    <div><a href="{{card.bookingUrl}}"
                           target="_blank">{{'policy-confirmation.activity_deal_cards.activity_details' | translate}}</a></div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="c-activity-card__see-more">
        <a href="https://www.amatravel.ca/products-search-result/activities?destination={{this.destination}}"
           target="_blank">{{'policy-confirmation.activity_deal_cards.see_more' | translate}}</a>
    </div>
</div>