<header class="c-ama-header p-0 u-ama-bg-color--navy-blue">
    <div class="container">
        <div class="row">
            <div class="c-ama-header__profile col-7 col-sm-12 my-2">
                <span dropdown
                      #dropdown="bs-dropdown"
                      class="d-flex justify-content-end w-100">
                    <span class="c-ama-header__profile-icon">
                        <ng-container *ngIf="userProfile.isAuthenticated; else loggedOutIcon">
                            <span (click)="dropdown.toggle(true)">
                                <svg-icon name="login" />
                            </span>
                        </ng-container>
                        <ng-template #loggedOutIcon>
                            <svg-icon linkLogin
                                      name="login" />
                        </ng-template>
                    </span>
                    <span class="c-ama-header__profile-identity d-none d-sm-inline">
                        <span *ngIf="userProfile.isAuthenticated; else loggedOutText"
                              (click)="dropdown.toggle(true)">
                            {{'header.greeting_verb' | translate }}<ng-container *ngIf="userProfile?.profile?.firstName">,
                                {{ userProfile?.profile.firstName }}</ng-container>
                        </span>
                        <ng-template #loggedOutText><span linkLogin>{{'header.guest_greeting' | translate }}</span></ng-template>
                    </span>
                    <span id="basic-link-dropdown"
                          *dropdownMenu
                          class="c-ama-header__profile-menu dropdown-menu dropdown-menu dropdown-menu-right"
                          role="menu"
                          aria-labelledby="basic-link">
                        <span class="c-ama-header__logout-reveal row">
                            <span class="c-ama-header__logout-reveal-close">
                                <a class="c-close-button ml-a u-ama-color--royal-blue"
                                   aria-label="Close"
                                   (click)="dropdown.toggle(false)">
                                    <svg-icon name="close" />
                                </a>
                            </span>
                            <span class="c-ama-header__logout-reveal-greeting col-12 mb-2 text-center">
                                {{userProfile?.userName}}
                            </span>
                            <span class="c-ama-header__logout-reveal-btn">
                                <a class="text-white btn btn-primary"
                                   (click)="logout()"
                                   href="/Account/SignOut">{{'header.signout' | translate }}</a>
                            </span>
                        </span>
                        <span class="c-ama-header__profile-menu-pointer">
                            <svg-icon name="caret-filled" />
                        </span>
                    </span>
                    <span *ngIf="userProfile.isAuthenticated"
                          class="c-ama-header__profile-arrow d-none d-sm-flex"
                          (click)="dropdown.toggle(true)">
                        <svg-icon name="chevron-down" />
                    </span>
                </span>
            </div>
            <a class="c-ama-header__brand col-5 col-sm-4 col-md-3 col-lg-3 mb-sm-2 mb-md-3 d-flex align-self-center"
               href="https://www.amatravel.ca"
               attr.analytic-ama='header-home-link'>
                <span class="c-ama-header__brand-svg">
                    <svg-icon name="amaTravelLogo" />
                </span>
            </a>

            <div class="c-ama-header___call col-sm-8 col-md-9 col-lg-9 mb-2 d-none d-sm-flex justify-content-end">
                <div class="c-ama-header__call-wrapper media mr-2">
                    <img class="align-self-center mr-3"
                         src="{{settings.assets}}/icon-call-us.jpg"
                         alt="Call us icon" />
                    <div class="media-body align-self-center">
                        <span class="small">{{'header.questions' | translate}}</span>
                        <h5 class="mt-0"
                            [innerHTML]="'header.call' | translate | safeHtml"></h5>
                    </div>
                </div>

                <ul class="navbar-nav mt-0">
                    <li class="nav-item mt-1">
                        <div class="small">{{'header.hours.m-f' | translate}}</div>
                        <div class="small">{{'header.hours.s-s' | translate}}</div>
                    </li>
                </ul>
            </div>


        </div>
    </div>
</header>

<router-outlet name="content-header" />

<sign-in-banner *ngIf="!userProfile.isAuthenticated" />