import { Component, OnInit } from '@angular/core';
import { select, NgRedux } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { IAppState } from 'src/app/models/app-state.model';
import { IQuoteOption, ITripDetails } from 'src/app/models/data.interfaces';
import { ProductGroup } from 'src/app/models/products';

@Component({
  selector: 'confirmed-policy',
  templateUrl: './confirmed-policy.component.html',
})
export class ConfirmedPolicyComponent implements OnInit {
  public readonly countryName: string;
  public readonly subRegionName: string;
  productGroup = ProductGroup;

  @select(['policyInfo', 'policyNumber']) readonly policyNumber$: Observable<string>;
  @select(['policyInfo', 'currentQuoteDetails', 'quoteOption']) readonly quoteOption$: Observable<IQuoteOption>;
  @select(['policyInfo', 'tripDetails']) readonly tripDetails$: Observable<ITripDetails>;
  constructor(private readonly reduxStore: NgRedux<IAppState>) {
    const policyInfo = this.reduxStore.getState().policyInfo;
    if (policyInfo && policyInfo.tripDetails) {
      const td = policyInfo.tripDetails;
      this.countryName = td.countrySelected.name;
      this.subRegionName = td.subRegionSelected != null ? td.subRegionSelected.name : null;
    }
  }

  ngOnInit() {}
}
