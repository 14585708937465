import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { FunnelRoutes } from 'src/app/models/data.interfaces';
import { environment } from 'src/environments/environment';
import { PosternQueryParamsKey } from 'src/app/components/postern/postern.component';
import { PosternService } from '../services/postern.service';
import { GoogleAnalyticsService, FunnelStream } from '../services/google.analytics.service';

@Injectable()
export class InitialRouteGuard implements CanActivate {
  private validEntryFound = false;
  private funnelStreamSet = false;

  constructor(
    private readonly router: Router,
    private readonly posternService: PosternService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {}

  /**
   * The InitialRouteGuard is completed once both the valid entry point
   * is found and the funnel stream property is set.
   */
  public isComplete() {
    return this.validEntryFound && this.funnelStreamSet;
  }

  /**
   * Used during testing to simulate a reload.
   */
  public reset() {
    this.validEntryFound = false;
    this.funnelStreamSet = false;
  }

  /**
   * Set the funnel stream property. Will only happen once for every valid entry point.
   */
  public setFunnelStream(funnelStream: FunnelStream) {
    if (funnelStream && !this.funnelStreamSet) {
      this.funnelStreamSet = true;
      this.googleAnalyticsService.setFunnelStream(funnelStream);
    }
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // The initial route guard is completed.
    if (this.isComplete()) {
      return true;
    }

    // Find the valid entry point
    let result: boolean | UrlTree = true;
    if (!this.validEntryFound) {
      this.validEntryFound = true;
      result = this.isValidEntryPoint(next);
    }

    // Once we are in a valid entry point we should set the funnel stream property.
    if (result === true) {
      this.setFunnelStream(this.getFunnelStream(next));
    }

    return result;
  }

  /**
   * Not all routes are valid entry points for the funnel.
   */
  private isValidEntryPoint(next: ActivatedRouteSnapshot): boolean | UrlTree {
    // In development mode all routes are valid entry points
    if (!environment.production) {
      return true;
    }

    // Is this a valid initial funnel route?
    const validSteps = [FunnelRoutes.coverage, FunnelRoutes.loadQuote, FunnelRoutes.postern, FunnelRoutes.retrievePolicy];
    if (validSteps.includes(next.data.step)) {
      // A valid route indicates a completely new starting point for the user.
      // We no longer want to store the postern state.
      sessionStorage.removeItem(PosternQueryParamsKey);
      return true;
    }

    // If this route falls under renew tree, we should set the redirect back to renew.
    if (next.data.renew) {
      return this.router.parseUrl('/renew');
    }

    // Otherwise, check session storage in case of a full reload scenario.
    try {
      const posternQueryParams = JSON.parse(sessionStorage.getItem(PosternQueryParamsKey));
      if (posternQueryParams) {
        const resolved = this.posternService.resolve(posternQueryParams);
        if (resolved) {
          this.posternService.updateStore(resolved);
          this.setFunnelStream(resolved.funnelStream);
          return resolved.nextUrlTree;
        }
      }
    } catch (e) {}

    // Fallback to the coverage page
    return this.router.parseUrl('/coverage');
  }

  private getFunnelStream(next: ActivatedRouteSnapshot): FunnelStream {
    const step = next.data.step;
    if (step === FunnelRoutes.coverage) {
      return FunnelStream.Direct;
    } else if (step === FunnelRoutes.loadQuote) {
      return FunnelStream.LoadQuote;
    } else if (step === FunnelRoutes.postern) {
      const resolved = this.posternService.resolve(next.queryParams);
      if (resolved && resolved.funnelStream) {
        return resolved.funnelStream;
      }
    } else if (step === FunnelRoutes.retrievePolicy) {
      return FunnelStream.Renewal;
    }
  }
}
