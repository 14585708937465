import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'stencil-content-sidebar',
  templateUrl: './stencil-content-sidebar.component.html',
})
export class StencilContentSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
