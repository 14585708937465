<div *injector="{}; let matchMedia = matchMedia;"
     class="c-traveller-conditions card card--info-light text-body">
  <div class="card-header h5 pr-5">
    <strong>
      <i class="c-traveller-conditions__header-icon">
        <svg-icon name="shield-plus" />
      </i>
      <span>{{ 'review_quote.traveller_conditions.title' | translate }}</span>
    </strong>
    <span class="c-form-label-tooltip">
      <i class="c-form-label-tooltip__icon">
        <button type="button"
                class="btn btn-link m-0 p-0"
                tabindex="-1"
                (click)="showHelpText = !showHelpText">
          <svg-icon name="question_mark_circle" />
        </button>
      </i>
    </span>
    <div class="c-traveller-conditions__header-info"
         *ngIf="showHelpText"
         [innerHtml]="'review_quote.traveller_conditions.tooltip' | translate | safeHtml"></div>
  </div>
  <div class="card-body">
    <div class="card-title"
         [innerHtml]="'review_quote.traveller_conditions.blurb' | translate | safeHtml"></div>
    <section class="c-traveller-conditions__collapse"
             [ngClass]="{'c-traveller-conditions__collapse--open': showStabilityPeriod}">
      <button class="btn btn-sm btn-link"
              (click)="showStabilityPeriod = !showStabilityPeriod">
        <label>
          <b class="u-tc--plus"
             *ngIf="!showStabilityPeriod">+</b>
          <b class="u-tc--minus"
             *ngIf="showStabilityPeriod">-</b>
          <span [innerHtml]="'review_quote.traveller_conditions.stability_period_title' | translate | safeHtml"></span>
        </label>
      </button>

      <div class="c-traveller-conditions__collapse-content"
           *ngIf="showStabilityPeriod"
           [innerHtml]="'review_quote.traveller_conditions.stability_period_content' | translate | safeHtml">
      </div>
    </section>
    <div class="card-text mb-0">
      <strong>{{ 'review_quote.traveller_conditions.prompt' | translate }}</strong>
    </div>
    <form connect="tripDetails"
          class="container">
      <div class="form-group mb-0">
        <div class="row">
          <ng-template #connectArrayDirective="connectArrayFix"
                       connectArrayFix
                       let-index
                       connectArrayOf="agesOfTravellers">
            <div class="col-12 col-md-6 form-check d-inline-block mb-3 rounded bg-white">
              <input class="form-check-input"
                     type="checkbox"
                     value="false"
                     id="travellerPreEx{{index}}"
                     ngControl
                     ngModel
                     (change)="checkChanged()"
                     [connectArrayFixModel]="connectArrayDirective"
                     [connectArrayFixIndex]="index"
                     name='hasPreExistingCondition'
                     #travellerAge="ngModel" />
              <label class="form-check-label"
                     for="travellerPreEx{{index}}">{{ 'review_quote.traveller_conditions.traveller_label' | translate }} {{ index + 1 }}
                ({{ 'review_quote.traveller_conditions.age_label' | translate }} {{ connectArrayDirective.array.value[index]?.age }})
              </label>
            </div>
          </ng-template>
        </div>
      </div>
    </form>

  </div>
</div>