import { each, isUndefined, isNull } from 'underscore';

/**
 * Determine if this object has a value. It cannot be undefined or null.
 */
export const hasValue = (value) => !isUndefined(value) && !isNull(value);

import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * A base class that contains functions that are useful in
 * most components.
 */
@Directive()
export class BaseComponent implements OnDestroy {
  private subscriptions: Subscription[];
  private timeouts: number[];
  private intervals: number[];
  public constructor() {
    this.subscriptions = [];
    this.timeouts = [];
    this.intervals = [];
  }

  /**
   * Clear the subscription when the component is destroyed.
   */
  protected using(subscription: Subscription) {
    this.subscriptions.push(subscription);
    return subscription;
  }

  /**
   * Clear the timeout when the component is destroyed.
   */
  protected usingTimeout(timeout: number) {
    this.timeouts.push(timeout);
    return timeout;
  }

  /**
   * Clear the interval when the component is destroyed.
   */
  protected usingInterval(interval: number) {
    this.intervals.push(interval);
    return interval;
  }

  public ngOnDestroy() {
    this.clearDisposables();
  }

  protected clearDisposables() {
    each(this.subscriptions, (s) => s.unsubscribe());
    each(this.timeouts, (t) => clearTimeout(t));
    each(this.intervals, (i) => clearInterval(i));
  }
}
