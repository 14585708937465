import { Pipe, PipeTransform } from '@angular/core';
import { sprintf } from 'sprintf-js';

@Pipe({ name: 'format' })
export class SprintfPipe implements PipeTransform {
  constructor() {}

  transform(format: any, ...args) {
    return sprintf(format, ...args);
  }
}
