import { IPolicyInfo } from './policy-info.model';
import { ITripDetails } from './trip-details.model';
import { ITravellerInfo } from './traveller.model';
import { IQuoteOption } from './quote-option.model';
import { IMedicalQuestionnaire } from './medical-questionnaire.model';
import { ICurrentQuoteDetails } from './current-quote-details.model';
import { IServerError } from './errors.model';

export class AppState implements IAppState {
  quoteID?: string;
  policyInfo: IPolicyInfo;
  tripDetails: ITripDetails;
  travellerInfo: ITravellerInfo;
  quoteOptions: IQuoteOption[];
  currentQuoteDetails: ICurrentQuoteDetails;
  medicalQuestionnaires: IMedicalQuestionnaire[];
  lastPolicy?: {
    tripDetails: ITripDetails;
    travellerInfo: ITravellerInfo;
  };

  constructor() {
    this.policyInfo = {
      dateQuoteInitiated: new Date(),
      dateLastEdited: new Date(),
      policyNumber: null,
      currentQuoteDetails: null,
      tripDetails: null,
    };
    this.tripDetails = { numberOfTravellers: 1, countrySelected: null, subRegionSelected: null, agesOfTravellers: [{ age: null }] };
    this.travellerInfo = { membershipNumber: null, otherTravellers: [] };
    this.quoteOptions = new Array<IQuoteOption>();
    this.medicalQuestionnaires = new Array<IMedicalQuestionnaire>();
  }
}

export interface IAppState {
  quoteID?: string;
  policyInfo: IPolicyInfo;
  tripDetails: ITripDetails;
  travellerInfo: ITravellerInfo;
  quoteOptions: IQuoteOption[];
  currentQuoteDetails?: ICurrentQuoteDetails;
  lastServerError?: IServerError;
  medicalQuestionnaires?: IMedicalQuestionnaire[];
  lastPolicy?: {
    tripDetails: ITripDetails;
    travellerInfo: ITravellerInfo;
  };
}
