import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlSigningService {
  constructor(private readonly httpClient: HttpClient) {}

  public signUrl(url: string): Observable<string> {
    return this.httpClient.post(
      `${environment.baseApiUrl}/sign_url`,
      {
        url: url,
      },
      {
        responseType: 'text',
      }
    );
  }
}
