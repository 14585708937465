import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base-component.component';
import { Unsubscribe } from 'amaweb-tsutils';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { TenantEnum } from 'src/app/models/tenant.enum';
import { TranslateService } from '@ngx-translate/core';

@Unsubscribe()
@Component({
  selector: 'infobar',
  templateUrl: './infobar.component.html',
  styleUrls: ['./infobar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfobarComponent extends BaseComponent {
  public path: string;
  public tenant: string;
  public tenantEnum = TenantEnum;
  public window = window;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clubConfigurationService: ClubConfigurationService,
    private translateService: TranslateService
  ) {
    super();
    this.using(this.activatedRoute.data.subscribe((d) => (this.path = d.path))).attach(this);
    this.tenant = this.clubConfigurationService.appConfig.settings.tenant;
  }

  get getUrl() {
    return this.translateService.instant(this.path + '.url');
  }

  get hasUrl() {
    return this.getUrl !== this.path + '.url';
  }
}
