<form class="c-renew-quote mx-auto mt-3"
	  #f="ngForm"
	  id="renew-policy"
	  analytic-ama="form"
	  (submit)="submit(f)">
	<p>{{ 'renew_quote.preamble' | translate }}</p>
	<h2 class="h6 mb-4">{{ 'renew_quote.header2' | translate }}</h2>

	<div class="mb-4">
		<div class="row">
			<div class="col-12 col-sm-5 mb-4 mb-md-0">
				<label class="h6">
					{{ 'renew_quote.policy_number.label' | translate }}
				</label>
				<div>
					<input class="form-control"
						   type="text"
						   name="policyNumber"
						   placeholder="{{ 'renew_quote.policy_number.placeholder' | translate }}"
						   required
						   minlength="6"
						   #policyNumberInput="ngModel"
						   [(ngModel)]="policyNumber"
						   [ngClass]="{ 'is-invalid': (f.submitted) && policyNumberInput.invalid }" />
					<div *ngIf="(f.submitted && policyNumberInput.invalid)"
						 class="invalid-feedback">
						<span *ngIf="policyNumberInput.errors?.required">{{ 'renew_quote.policy_number.required' | translate }}</span>
						<span *ngIf="policyNumberInput.errors?.minlength">{{ 'renew_quote.policy_number.invalid' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-7">
				<label class="h6">
					{{ 'renew_quote.last_name.label' | translate }}
				</label>
				<div>
					<input class="form-control"
						   type="text"
						   name="lastName"
						   placeholder="{{ 'renew_quote.last_name.placeholder' | translate }}"
						   required
						   #lastNameInput="ngModel"
						   [(ngModel)]="lastName"
						   [ngClass]="{ 'is-invalid': (f.submitted) && lastNameInput.invalid }" />
					<div *ngIf="(f.submitted && lastNameInput.invalid)"
						 class="invalid-feedback">
						<span *ngIf="lastNameInput.errors?.required">{{ 'renew_quote.last_name.required' | translate }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="(f.submitted && !isLoading) && (foundPolicy === false || exceededAttempts)"
		 class="invalid-feedback d-block mb-3">
		<div *ngIf="foundPolicy === false"
			 [innerHTML]="'renew_quote.errors.not_found' | translate"></div>
		<div *ngIf="exceededAttempts"
			 [innerHTML]="'renew_quote.errors.exceeded_attempts' | translate"></div>
	</div>

	<button type="submit"
			id="retrieve-policy-submit"
			class="btn btn-block btn-lg btn-secondary col-md-5"
			[ngClass]="{'btn--loading': isLoading}">
		{{ 'renew_quote.retrieve_policy_btn' | translate }}
	</button>
</form>