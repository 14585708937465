import { HeapService } from './heap.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Observable, Subject, lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ICurrentUserInformation, ProductGroup } from '../../models/data.interfaces';
import { GoogleUserCookieService } from './google-user-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseApiUrl: string;

  // NOTE: Never expose the subject directly as this would allow external consumers to call on its next function.
  // That is something that only this service should do.
  private readonly currentUserSubject = new Subject<ICurrentUserInformation>();

  /**
   * An Observable<ICurrentUserInformation> that can be subscribed to to receive notifications
   * of when the current user has changed. Useful when signing in or out of the application.
   */
  public readonly currentUserChanged: Observable<ICurrentUserInformation> = this.currentUserSubject.asObservable();

  /**
   * Returns the current user information.
   */
  public currentUser: ICurrentUserInformation;

  private userDeclaredProvince: string;

  constructor(
    private readonly http: HttpClient,
    private readonly googleUserCookieService: GoogleUserCookieService,
    private heapService: HeapService
  ) {
    this.baseApiUrl = `${environment.baseApiUrl}/user`;
  }

  /**
   * Fetches information about the current user from the server. Should only be called
   * during application initialization.
   */
  public initialize(): Promise<ICurrentUserInformation> {
    return lastValueFrom(
      this.http.get<ICurrentUserInformation>(this.baseApiUrl).pipe(
        tap((info: ICurrentUserInformation) => {
          // Reset the declared province
          this.userDeclaredProvince = null;
          this.currentUser = info;
          this.googleUserCookieService.updateFromCurrentUser(this.currentUser);
          this.currentUserSubject.next(info);

          if (info.profile && info.profile.authID) {
            this.heapService.identify(info.profile.authID);
            this.heapService.enrichUserSession(info.profile);
          } else {
            this.heapService.resetIdentity();
          }
        })
      ),
      undefined
    );
  }

  /**
   * Authenticates a user and returns a promise containing details about the authenticated user.
   * Also emits a multicast broadcast via currentUserChanged Observable.
   * @param email The email address to use for signing in.
   * @param password The password to use for signing in.
   */
  public signIn(email: string, password: string): Promise<ICurrentUserInformation> {
    return lastValueFrom(
      this.http.post<ICurrentUserInformation>(`${this.baseApiUrl}/signin`, { email, password }).pipe(
        tap((info: ICurrentUserInformation) => {
          // Reset the declared province
          this.userDeclaredProvince = null;
          this.currentUser = info;
          this.currentUserSubject.next(info);
        })
      ),
      undefined
    );
  }

  public setUserDeclaredProvinceCode(provinceCode: string): void {
    this.userDeclaredProvince = provinceCode;
  }

  /**
   * Visitors to Canada will not have a declared province but we still need to
   * stop the modal from appearing again.
   */
  public setUserIsVisitorToCanada(): void {
    this.userDeclaredProvince = ProductGroup.VisitorsToCanadaFlow;
  }

  public isUserProvinceSet(): boolean {
    return this.userDeclaredProvince != null && this.userDeclaredProvince.trim().length !== 0;
  }
}
