import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IServerError, IAppState, IKnownError } from '../../models/data.interfaces';
import { NgRedux } from '@angular-redux-ivy/store';

import { AppInsights } from 'applicationinsights-js';

import { IServerErrorAction, SalesFunnelActionType } from '../../store/reducer';
import { ErrorDialogService } from './error-dialog.service';

const INTERCEPTED_ERROR_MESSAGE = 'Intercepted Server Error';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly errorDialogService: ErrorDialogService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appState: IAppState = this.reduxStore.getState();

    if (appState && appState.quoteID != null) {
      req = req.clone({
        setHeaders: {
          'X-Funnel-Quote-Token': appState.quoteID,
        },
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        // Check if the error should be ignored
        if (ignoreError(errorResponse)) {
          return throwError(() => errorResponse);
        }

        // Get the current product group flow
        const currentReduxState = this.reduxStore.getState();
        let productGroupFlow = 'Unknown';
        let productSelected = 'Unknown';
        if (currentReduxState.tripDetails != null) {
          productGroupFlow = currentReduxState.tripDetails.productGroupFlow;
          productSelected = currentReduxState.tripDetails.productSelected;
        }

        // In the production environment the 500 error status codes are
        // intercepted by the WAF which ends up returning HTML with a support ticket.
        // The server side error interceptor should have already tracked the exception in app insights.
        // We just need to track the support id and show the error page.
        if (errorResponse.error != null && typeof errorResponse.error === 'string') {
          let supportId = null;
          const match = /Support ID: (\d+)/.exec(errorResponse.error);
          if (match && match.length >= 2) {
            supportId = match[1];
          }

          AppInsights.trackException(new Error(INTERCEPTED_ERROR_MESSAGE), null, {
            'requested-url': errorResponse.url,
            'http-status-code': errorResponse.status.toString(),
            'product-flow': productGroupFlow,
            'product-selected': productSelected,
            'support-ticket-id': supportId,
          });
          AppInsights.flush();

          // We need to set the last server error for the unexpected server error page
          this.reduxStore.dispatch(
            setLastServerError({
              statusCode: errorResponse.status,
              message: INTERCEPTED_ERROR_MESSAGE,
            } as IServerError)
          );
        } else if (errorResponse.error != null) {
          const serverError: IServerError = errorResponse.error as IServerError;

          // .net Core API error - Object Validation Errors
          if (!serverError.isCustomError && serverError.errors) {
            serverError.statusCode = errorResponse.status;

            const mappedErrors = new Array<IKnownError>();
            Object.entries(serverError.errors).forEach(([key, value]) => {
              mappedErrors.push({
                clientCode: (value as IKnownError).clientCode,
                isClientResolvable: true,
                // If there are modal validation errors they will not have a client message.
                clientMessage: (value as IKnownError).clientMessage || (value as any),
              });
            });
            serverError.errors = mappedErrors;
            serverError.message = 'Data Error';
          }

          // Do not log errors which are client resolvable
          if (serverError.errors && serverError.errors.some((x) => !x.isClientResolvable)) {
            AppInsights.trackException(
              new Error(errorResponse.message),
              null,
              {
                'server-error-message': serverError.message,
                'requested-url': errorResponse.url,
                'known-errors': JSON.stringify(serverError.errors),
                'http-status-code': serverError.statusCode.toString(),
                'product-flow': productGroupFlow,
                'product-selected': productSelected,
              },
              {
                'known-errors-count': serverError.errors != null ? serverError.errors.length : 0,
              }
            );
            AppInsights.flush();
          }

          this.reduxStore.dispatch(setLastServerError(serverError));

          switch (serverError.statusCode) {
            case 400: // Bad Request
            case 409: // Conflict
            case 410: // Gone
              this.errorDialogService.show();
              return throwError(() => errorResponse);
            case 404: // Not Found
            case 405: // Method not allowed
              this.router.navigate(['/not-found']);
              return throwError(() => errorResponse);
          }
        }

        // For all other scenarios throw the error and go to the unexpected-server-error page.
        this.router.navigate(['/unexpected-server-error']);
        return throwError(() => errorResponse);
      })
    );
  }
}

function setLastServerError(error: IServerError): IServerErrorAction {
  return {
    type: SalesFunnelActionType.SET_LAST_SERVER_ERROR,
    payload: error,
  };
}

/**
 * Certain actions like sign in should not go to the unexpected error page.
 * We also do not want to log application insight errors when they are not needed.
 */
function ignoreError(e: HttpErrorResponse): boolean {
  if (e && e.status === 401 && e.url && e.url.indexOf('api/user/signin') !== -1) {
    return true;
  }
  return false;
}
