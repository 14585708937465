import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, IServerError, IKnownError } from '../../models/data.interfaces';
import { SalesFunnelActionType, IServerErrorAction } from '../../store/reducer';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  private readonly blacklistedErrorCodes: Array<number>;

  constructor(private readonly reduxStore: NgRedux<IAppState>, private readonly translateService: TranslateService) {
    // TODO: As error codes become blacklisted from being shown, update this to include them.
    this.blacklistedErrorCodes = [4001, 4010, 6000, 6001];
    // error needs silented (UI will handle it)
    // 6000 - Answers are missing in Medical Questionnaire
    // 6001 - one or more invalid responses to questions on the medical questionnaire.
    // 4001 - problem getting quote
    // 4010 - Maximum age for Visitor to Canada plan is 85
  }

  private isErrorCodeBlackListed(errorCode: number): boolean {
    return this.blacklistedErrorCodes.indexOf(errorCode) !== -1;
  }

  private clearLastServerError(): IServerErrorAction {
    return {
      type: SalesFunnelActionType.CLEAR_LAST_SERVER_ERROR,
    };
  }

  private formatKnownErrors(errors: IKnownError[]): string {
    if (errors == null || errors.length === 0) {
      return '';
    }

    let html = '<ul class="list-group">';

    errors.forEach((error: IKnownError) => {
      html += `<li class='list-group-item text-left'>${error.clientMessage}</li>`;
    });

    html += '</ul>';
    return html;
  }

  public showErrorMessage(message: string): void {
    const options: SweetAlertOptions = {
      customClass: 'c-sweetalert2-modal c-sweetalert2-modal--error',
      confirmButtonClass: 'btn btn-primary btn-lg',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      titleText: message,
      text: 'Sorry, but we had an unexpected error. Please try reloading the page. If this problem persists, please call us.',
      type: 'error',
      showCloseButton: true,
    };
    Swal.fire(options);
  }

  public show(): void {
    const error: IServerError = Object.assign({}, this.reduxStore.getState().lastServerError);

    let knownErrors: IKnownError[] = error.errors || [];

    if (typeof knownErrors !== 'undefined' && knownErrors.length) {
      knownErrors = knownErrors.filter((knownError) => !this.isErrorCodeBlackListed(knownError.clientCode));
    }

    this.reduxStore.dispatch(this.clearLastServerError());

    this.translateService
      .get('generic_error_popup_title')
      .pipe(take(1))
      .subscribe((titleText) => {
        Swal.fire({
          customClass: 'c-sweetalert2-modal c-sweetalert2-modal--error',
          confirmButtonClass: 'btn btn-primary btn-lg',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          titleText,
          html: knownErrors && knownErrors.length > 0 ? this.formatKnownErrors(knownErrors) : error.message,
          type: 'error',
          showCloseButton: true,
        } as SweetAlertOptions);
      })
      .attach(this);
  }
}
