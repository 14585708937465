<input type="text"
       class="form-control"
       [autocomplete]="autocomplete"
       bsDatepicker
       #bsDatepickerInstance="bsDatepicker"
       [id]="id"
       [placeholder]="placeholder"
       (bsValueChange)="bsValueChange($event)"
       [bsConfig]="{ dateInputFormat: ('calendar_date_display_format_bs_picker' | translate), showWeekNumbers: false, containerClass: 'theme-default', selectFromOtherMonth: true }"
       [bsValue]="value"
       [required]="required"
       [isDisabled]="isDisabled"
       [ngClass]="inputClass"
       [minDate]="minDate"
       [maxDate]="maxDate"
       [isOpen]="isOpen"
       (onHidden)="bsOnHidden($event)"
       (onShown)="onShown.emit($event)"
       [tabindex]="tabindex"
       placement="top" />