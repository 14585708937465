import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, NgRedux } from '@angular-redux-ivy/store';

import { Observable } from 'rxjs';

import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { addYears } from 'date-fns';

import { SalesFunnelActionType } from '../../store/reducer';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { BaseComponent } from 'src/app/components/base-component.component';
import { setQuoteOptions } from 'src/app/store/trip-details-quote-option-retrieval.middleware';
import { ITripDetails, InsuranceProduct, ProductGroup, IAppState, IAppConfigSettings } from 'src/app/models/data.interfaces';
import { updateCurrentQuoteDeductible } from 'src/app/store/current-quote-details.reducer';
import { Unsubscribe } from 'amaweb-tsutils';
import { TenantEnum } from 'src/app/models/tenant.enum';

@Unsubscribe()
@Component({
  selector: 'coverage-type',
  templateUrl: './coverage-type.component.html',
})
export class CoverageTypeComponent extends BaseComponent implements OnInit, OnDestroy {
  TenantEnum = TenantEnum;
  insuranceProduct = InsuranceProduct;
  productGroup = ProductGroup;
  tenant: string;
  minDepartureDate: Date = new Date();
  maxDepartureDate: Date = addYears(this.minDepartureDate, 2);
  showCovidMessage = true;

  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<ProductGroup>;

  appConfigSetting: IAppConfigSettings;

  constructor(
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly clubConfigurationService: ClubConfigurationService,
    private readonly scrollToService: ScrollToService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private readonly matchMediaService: MatchMediaService
  ) {
    super();
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;
  }

  ngOnInit() {
    // change product group flow should clean up fetched quote.
    this.using(
      this.productGroupFlow$.subscribe(() => {
        this.reduxStore.dispatch(setQuoteOptions([]));
      })
    ).attach(this);

    this.tenant = this.clubConfigurationService.appConfig.settings.tenant;
    this.showCovidMessage &&= this.tenant !== TenantEnum.ATA;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  public setCovidCondition = (value: boolean) => {
    setTimeout(() => (this.showCovidMessage = value && this.tenant !== TenantEnum.ATA), 0);
  };

  public setProductGroupFlow = (selectedProductFlow: ProductGroup) => {
    // Change the product group flow and clear the product selected.
    this.reduxStore.dispatch({
      type: SalesFunnelActionType.UPDATE_SELECTED_PRODUCT_GROUP,
      tripDetailsState: { productGroupFlow: selectedProductFlow, productSelected: null } as ITripDetails,
    });
    // Clear the deductible
    this.reduxStore.dispatch(updateCurrentQuoteDeductible(null));
  };

  triggerScrollTo() {
    setTimeout(() => {
      this.checkBreakpointSize();
    }, 0);
  }

  checkBreakpointSize() {
    // Only do the scroll on the 'phone' size media type
    if (this.matchMediaService.matches(MEDIA_TYPE.Phone)) {
      const config: ScrollToConfigOptions = {
        target: 'coverageTypeForm',
        duration: 1400,
        easing: 'easeInOutQuint',
        offset: 220,
      };
      this.scrollToService.scrollTo(config);
    }
  }
}
