<div *injector="{
  quoteOption: quoteOption$,
  tripDetails: tripDetails$,
  activeStep: activeStep$,
  currentQuoteDetails: currentQuoteDetails$,
  singleTripProductIsSelected: singleTripProductIsSelected$,
  multiTripProductIsSelected: multiTripProductIsSelected$
};
let quoteOption = quoteOption;
let tripDetails = tripDetails;
let activeStep = activeStep;
let currentQuoteDetails = currentQuoteDetails;
let singleTripProductIsSelected = singleTripProductIsSelected;
let multiTripProductIsSelected = multiTripProductIsSelected;
"
     class="c-tds">
  <div class="card">
    <div *ngIf="tripDetails?.productGroupFlow !== productGroup.RentalVehicleDamageFlow"
         class="card-header text-center h3">
      <ng-container *ngIf="!FunnelRoutes.tripDetails; else dynamic_text">
        {{ 'trip_details_summary.title.default' | translate }}
      </ng-container>
      <ng-template #dynamic_text>
        <ng-container *ngIf="quoteOption; else default_text">
          {{ 'trip_details_summary.title.starts_with' | translate }} {{ quoteOption?.productName }}
        </ng-container>
        <ng-template #default_text>{{ 'trip_details_summary.title.default' | translate }}
        </ng-template>
      </ng-template>
    </div>

    <div *ngIf="tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow"
         class="card-header text-center h3"
         [innerHtml]="'trip_details_summary.rvd.title' | translate | safeHtml">
    </div>

    <div class="c-tds__content card-body">
      <ul class="list-group list-group-flush">
        <li *ngIf="tripDetails?.productGroupFlow !== productGroup.RentalVehicleDamageFlow"
            class="c-tds__item list-group-item">
          <i class="c-tds__item-icon">
            <svg-icon name="users" />
          </i>
          <div class="c-tds__item-info">
            <label class="c-tds__item-label">{{ 'trip_details_summary.travellers_covered.prompt' | translate }}:</label>
            <span class="c-tds__item-value">{{ tripDetails?.numberOfTravellers }}</span>
          </div>
        </li>
        <li class="c-tds__item list-group-item"
            *ngIf="!renew">
          <i class="c-tds__item-icon">
            <svg-icon name="globe" />
          </i>
          <div class="c-tds__item-info">
            <label class="c-tds__item-label">{{ 'trip_details_summary.travelling_to.prompt' | translate }}:</label>

            <span class="c-tds__item-value">{{ tripDetails?.subRegionSelected?.name }}</span>

            <span class="c-tds__item-value">{{ tripDetails?.countrySelected?.name }}</span>
          </div>
        </li>

        <li class="c-tds__item list-group-item"
            *ngIf="(singleTripProductIsSelected
                      || tripDetails?.productGroupFlow === productGroup.SingleTripFlow
                      || tripDetails?.productGroupFlow === productGroup.VisitorsToCanadaFlow
                      || tripDetails?.productGroupFlow === productGroup.TCIFlow
                      || tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow )
                                && !multiTripProductIsSelected">
          <i class="c-tds__item-icon">
            <svg-icon name="calendar" />
          </i>
          <div class="c-tds__item-info">
            <label
                   class="c-tds__item-label">{{ (tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow ? 'trip_details_summary.rvd.policy_effective_dates.prompt' : 'trip_details_summary.policy_effective_dates.prompt') | translate }}:</label>
            <span class="c-tds__item-value"
                  *ngIf="tripDetails?.departureDate || tripDetails?.returnDate">
              <span class="d-inline-block">{{ tripDetails?.departureDate | date:dateFormat:'UTC' }}</span>
              -
              <span class="d-inline-block">{{ tripDetails?.returnDate | date:dateFormat:'UTC' }}</span>
            </span>
            <span class="c-tds__item-value"
                  *ngIf="tripDetails?.durationOfFirstTrip">({{ tripDetails?.durationOfFirstTrip }}
              {{ 'trip_details_summary.days' | translate }})</span>
          </div>
        </li>
        <ng-container *ngIf="multiTripProductIsSelected || 
            tripDetails?.productGroupFlow === productGroup.MultiTripFlow || 
            tripDetails?.productGroupFlow === productGroup.RepurchaseFlow">
          <li class="c-tds__item list-group-item">
            <i class="c-tds__item-icon">
              <svg-icon name="calendar" />
            </i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.policy_start_date.prompt' | translate }}:</label>
              <span class="c-tds__item-value">{{ tripDetails.policyStartDate | date: dateFormat:'UTC' }}</span>
            </div>
          </li>

          <li class="c-tds__item list-group-item"
              *ngIf="currentRouteUrl !== '/trip-details' && !quoteSelected && !renew && (tripDetails?.durationOfFirstTrip ) ">
            <i class="c-tds__item-icon">
              <svg-icon name="calendar_range" />
            </i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.first_trip_duration.prompt' | translate }}:</label>
              <span class="c-tds__item-value">{{ tripDetails?.durationOfFirstTrip }} Days</span>
            </div>
          </li>
        </ng-container>

        <li class="c-tds__item list-group-item"
            *ngIf="activeStep >= FunnelRoutes.travellerInfo
                && quoteOption?.hasDeductibleDiscount">
          <i class="c-tds__item-icon">
            <svg-icon name="deductible" />
          </i>
          <div class="c-tds__item-info">
            <label class="c-tds__item-label">{{ 'trip_details_summary.deductible.prompt' | translate }}:</label>
            <span class="c-tds__item-value">{{ currentQuoteDetails?.deductibleAmount | currency:'CAD':'symbol-narrow':'1.2-2' }}
            </span>
          </div>
        </li>

        <!-- {{ this ng-container should only be visible when there is quote selected }} -->
        <ng-container *ngIf="activeStep >= FunnelRoutes.travellerInfo && quoteOption">
          <li *ngIf="quoteOption?.hasMemberDiscount
                    || quoteOption?.hasFamilyDiscount
                    || quoteOption?.hasDeductibleDiscount
                    || quoteOption?.hasPromoDiscount"
              class="c-tds__item c-tds__item--subtotal-price list-group-item">
            <i class="c-tds__item-icon"></i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.subtotal.prompt' | translate }}:</label>
              <span class="c-tds__item-value">
                {{ quoteOption?.subtotalPrice | currency }}
              </span>
            </div>
          </li>
          <li *ngIf="quoteOption?.hasDeductibleDiscount"
              class="c-tds__item c-tds__item--deductible-discount-price list-group-item">
            <i class="c-tds__item-icon"></i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.deductible_discount.prompt' | translate }}:</label>
              <span class="c-tds__item-value">
                (-{{ quoteOption?.deductibleDiscount | currency }}) </span>
            </div>
          </li>
          <li *ngIf="quoteOption?.hasFamilyDiscount"
              class="c-tds__item c-tds__item--family-discount-price list-group-item"
              [ngClass]="{'c-tds__item--price-increase': quoteOption?.familyDiscount < 0}">
            <i class="c-tds__item-icon"></i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.family_discount.prompt' | translate }}:</label>
              <span class="c-tds__item-value">
                {{ quoteOption?.familyDiscount  | discount  }} </span>
            </div>
          </li>
          <li *ngIf="quoteOption?.hasMemberDiscount"
              class="c-tds__item c-tds__item--member-discount-price list-group-item">
            <i class="c-tds__item-icon"></i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.member_discount.prompt' | translate }}:</label>

              <span class="c-tds__item-value">(-{{ quoteOption?.memberDiscount | currency }})</span>
            </div>
          </li>
          <li *ngIf="quoteOption?.hasPromoDiscount"
              class="c-tds__item c-tds__item--promo-discount-price list-group-item">
            <i class="c-tds__item-icon"></i>
            <div class="c-tds__item-info">
              <label class="c-tds__item-label">{{ 'trip_details_summary.promo_discount.prompt' | translate }}:</label>

              <span class="c-tds__item-value">(-{{ quoteOption?.promoDiscount | currency }})</span>
            </div>
          </li>
          <li class="c-tds__item c-tds__item--total-price list-group-item text-center">
            <div class="alert alert-dark d-block w-100">
              <div class="c-tds__item-info justify-content-center">
                <label class="c-tds__item-label">{{ 'trip_details_summary.total_cost.prompt' | translate }}:</label>
                <span class="c-tds__item-value text-success">{{ quoteOption?.totalPrice | currency }}
                </span>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>