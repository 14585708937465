<ng-container *injector="{
  productGroupFlow: productGroupFlow$,
  quoteOptions: quoteOptions$,
  tripDetails: tripDetails$,
  destinationSelected: destinationSelected$
};
let productGroupFlow = productGroupFlow;
let quoteOptions = quoteOptions;
let tripDetails = tripDetails;
let matchMedia = matchMedia;
let destinationSelected = destinationSelected;
">
  <ng-container *injector="{
    isRvd: (productGroupFlow === productGroup.RentalVehicleDamageFlow),
    hasOptions: (quoteOptions && quoteOptions.length > 0)
  };
  let isRvd = isRvd;
  let hasOptions = hasOptions;
  ">
    <!-- {{ quoteSelected | json }} -->
    <div class="o-review-quote">
      <div *ngIf="!isRvd"
           class="row">
        <div class="col-12">
          <h2>{{ 'review_quote.your_quote_title' | translate | format:quoteSelected?.productName }}</h2>
          <p class="font-weight-bold">{{ 'review_quote.feature_list.heading' | translate }}</p>
          <ul class="c-checklist c-checklist--icon-secondary">
            <li *ngIf="productGroupFlow !== productGroup.VisitorsToCanadaFlow">
              {{ 'review_quote.feature_list.item_1' | translate }}
            </li>
            <li>
              {{ 'review_quote.feature_list.item_2' | translate }}
            </li>
            <li>
              {{ 'review_quote.feature_list.item_3' | translate }}
            </li>
            <li *ngIf="medicalOnlyProductIsSelected">
              {{ 'review_quote.feature_list.item_4' | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="isRvd"
           class="row">
        <div class="col-12">

          <h2>{{ 'review_quote.rvd.your_quote_title' | translate }}</h2>
          <h5>{{ 'review_quote.rvd.your_quote_subtitle' | translate }}</h5>

          <p class="font-weight-bold mt-4">{{ 'review_quote.rvd.feature_list.heading' | translate }}</p>

          <ul class="c-checklist c-checklist--icon-secondary">
            <li [innerHTML]="'review_quote.rvd.feature_list.item_1' | translate | safeHtml">
            </li>
            <li [innerHTML]="'review_quote.rvd.feature_list.item_2' | translate | safeHtml">
            </li>
            <li [innerHTML]="'review_quote.rvd.feature_list.item_3' | translate | safeHtml">
            </li>
            <li [innerHTML]="'review_quote.rvd.feature_list.item_4' | translate | safeHtml">
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!isRvd"
           class="row">
        <div class="col-12">
          <traveller-conditions *ngIf="preExistingConditionsSupported"
                                #travellerConditions
                                (checkValuesChanging)="onTravellerConditionsCheckChanging()" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <form #f="ngForm"
                connect="currentQuoteDetails">
            <div class="c-tds c-tds--review-quote"
                 *ngIf="(quoteOptions && product) || isRvd">
              <div class="card">
                <div class="card-header text-center"
                     *ngIf="!isRvd">
                  {{ 'review_quote.premium_details_title' | translate }}
                </div>
                <div class="card-header text-center"
                     *ngIf="isRvd">
                  {{ 'trip_details_summary.rvd.title' | translate }}
                </div>
                <div class="c-tds__content card-body">
                  <ul class="list-group list-group-flush">
                    <li *ngIf="isRvd"
                        class="c-tds__item c-tds__item--trip-data list-group-item d-block pt-sm-0">
                      <single-trip #rvdSingleTrip
                                   id="coverageTypeForm"
                                   [hideCTA]="true"
                                   [productGroupFlow]="productGroup.RentalVehicleDamageFlow"
                                   translatePrefix="rvd"
                                   [minDepartureDate]="minDepartureDate"
                                   [maxDepartureDate]="maxDepartureDate"
                                   analyticsPrefix="rvd"
                                   analyticClassDeparture="rvd-pick-up-date"
                                   analyticClassReturn="rvd-drop-off-date"
                                   analyticClassSubmit="rvd-submit"
                                   analyticSubmitId="rvd" />
                    </li>
                    <li *ngIf="isRvd"
                        class="c-tds__item c-tds__item--trip-data list-group-item d-block pt-sm-0 row justify-content-lg-start mt-sm-2">
                      <div class="col-12 o-trip-details">
                        <div class="c-form-label-tooltip form-group o-trip-details__destination">
                          <h3 class="d-inline-block h5">
                            {{ 'review_quote.rvd.destination.title' | translate }}
                          </h3>
                          <select id="selectCountry"
                                  ngControl
                                  [ngModel]="destinationSelected"
                                  required
                                  #countrySelectedModel="ngModel"
                                  [ngModelOptions]="{standalone: true}"
                                  (change)="travellingDestinationSelected($event.target.value)"
                                  class="form-control o-trip-details__country-select"
                                  [ngClass]="{ 'is-invalid': f.submitted && countrySelectedModel.invalid }">
                            <option selected
                                    value="">{{ 'trip_details.destination.drop_down_placeholder' | translate }}</option>
                            <ng-container *ngFor="let country of countries">
                              <ng-container *ngIf="country.subRegions.length > 0; else otherCountries">
                                <optgroup label="{{ country.name }}">
                                  <option *ngFor="let subRegion of country.subRegions"
                                          [value]=" country.code + '|' + subRegion.code ">{{subRegion.name }}</option>
                                </optgroup>
                              </ng-container>
                              <option *ngIf="country.subRegions.length === 0"
                                      [value]="country.code">{{ country.name }}</option>
                            </ng-container>
                          </select>
                          <div *ngIf="f.submitted && countrySelectedModel.invalid"
                               class="invalid-feedback">
                            <span>{{ 'trip_details.destination.required' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="c-tds__item c-tds__item--trip-data list-group-item d-block d-md-none"
                        *ngIf="product && !multiTripProductIsSelected">
                      <div class="c-tds__item-info">
                        <label *ngIf="!isRvd"
                               class="c-tds__item-label font-weight-bold">{{ 'trip_details_summary.policy_effective_dates.prompt' | translate }}:</label>
                        <span class="c-tds__item-value"
                              *ngIf="!isRvd">
                          <span class="d-inline-block text-muted mr-1">
                            {{ tripDetails?.departureDate | date:dateFormat:'UTC' }}
                            -
                          </span>
                          <span class="d-inline-block text-muted">{{ tripDetails?.returnDate | date:dateFormat:'UTC' }}</span>
                        </span>
                      </div>
                    </li>
                    <li class="c-tds__item c-tds__item--trip-data list-group-item d-block d-md-none"
                        *ngIf="product && multiTripProductIsSelected">
                      <div class="c-tds__item-info">
                        <label
                               class="c-tds__item-label font-weight-bold">{{ 'trip_details_summary.policy_start_date.prompt' | translate }}:</label>
                        <span class="c-tds__item-value text-muted">{{ tripDetails?.policyStartDate | date: dateFormat:'UTC' }}</span>
                      </div>
                    </li>
                    <li class="c-tds__item c-tds__item--trip-data list-group-item d-block d-md-none"
                        *ngIf="!isRvd">
                      <div class="c-tds__item-info">
                        <label
                               class="c-tds__item-label font-weight-bold">{{ 'trip_details_summary.travelling_to.prompt' | translate }}:</label>
                        <span class="c-tds__item-value text-muted">{{ tripDetails?.countrySelected?.name }}</span>
                      </div>
                    </li>
                    <li class="c-tds__item c-tds__item--trip-data list-group-item d-block d-md-none"
                        *ngIf="!isRvd">
                      <div class="c-tds__item-info">
                        <label
                               class="c-tds__item-label font-weight-bold">{{ 'trip_details_summary.travellers_covered.prompt' | translate }}:</label>
                        <span class="c-tds__item-value text-muted">{{ tripDetails?.numberOfTravellers }}</span>
                      </div>
                    </li>

                    <li class="c-tds__item c-tds__item--description">
                      <div class="c-tds__item-desktop d-none d-md-block">
                        <ng-container *ngIf="product && !multiTripProductIsSelected && !isRvd">
                          <p class="mb-2 font-weight-bold">
                            {{'review_quote.deductable_single_trip' | translate}}
                            {{ tripDetails?.countrySelected.name }}
                            {{'review_quote.deductable_preposition' | translate}}
                            <span class="d-inline-block text-muted mr-1">{{ tripDetails?.departureDate | date:dateFormat:'UTC' }}
                              -</span>
                            <span class="d-inline-block text-muted">{{ tripDetails?.returnDate | date:dateFormat:'UTC' }}</span>
                          </p>
                        </ng-container>
                        <ng-container *ngIf="product && multiTripProductIsSelected && !isRvd">
                          <p class="mb-2">
                            {{'review_quote.deductable_multi_trip' | translate}}
                            <span class="d-inline-block text-muted mr-1">{{ tripDetails?.departureDate | date:dateFormat:'UTC' }}
                              -</span>
                            <span class="d-inline-block text-muted">{{ multiTripEndDate | date:dateFormat:'UTC' }}</span>
                            <ng-container *ngIf="product && product.code != insuranceProduct.AnnualPremiumPackage">
                              {{'review_quote.deductable_multi_trip_post' | translate}}
                            </ng-container>
                          </p>
                          <p class="mb-2 font-weight-bold">{{'review_quote.deductable_edit_default' | translate}}
                          </p>
                        </ng-container>
                        <ng-container *ngIf="showDeductableText">
                          <p class="mb-2">{{'review_quote.hasDeductable_claim' | translate}}</p>
                          <p>{{'review_quote.hasDeductable_edit' | translate}}</p>
                        </ng-container>
                      </div>

                    </li>

                    <li class="c-tds__editable list-group-item">
                      <div class="container-fluid">
                        <div class="row justify-content-sm-centerZZZ justify-content-md-startZZZ">
                          <ng-container *ngIf="product && multiTripProductIsSelected">
                            <div class="col-12 col-sm-8 col-md-8 col-lg-4">
                              <div class="c-tds__item list-group-item">
                                <i class="c-tds__item-icon c-tds__item-icon--tooltip">
                                  <button type="button"
                                          class="btn btn-link m-0 p-0"
                                          [tooltip]="tripDurationTemplate"
                                          container="body"
                                          placement="right"
                                          containerClass="tooltip-large"
                                          tabindex="-1">
                                    <svg-icon name="question_mark_circle" />
                                  </button>
                                  <ng-template #tripDurationTemplate>
                                    <div
                                         [innerHtml]="(renew ? 'review_quote.trip_duration.renew_tooltip' : 'review_quote.trip_duration.tooltip') | translate | format:(tripDetails?.departureDate | date:dateFormat:'UTC'):(multiTripEndDate | date:dateFormat:'UTC') | safeHtml">
                                    </div>
                                  </ng-template>
                                </i>
                                <div class="c-tds__item-info">
                                  <label class="c-tds__item-label">{{ 'review_quote.trip_duration.prompt' | translate }}:</label>
                                  <span class="c-tds__item-value w-100">
                                    <select class="custom-select"
                                            id="planDays"
                                            name="planDays"
                                            ngControl
                                            ngModel>
                                      <option *ngFor="let planDays of coverageDurations"
                                              [value]="planDays">
                                        {{ planDays }} {{ 'review_quote.trip_duration.days' | translate }}
                                      </option>
                                    </select>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-4"
                                 *ngIf="!renew">
                              <div class="c-tds__item list-group-item">
                                <i class="c-tds__item-icon c-tds__item-icon--tooltip">
                                  <button type="button"
                                          class="btn btn-link m-0 p-0"
                                          tooltip="{{ 'review_quote.top_up.tooltip' | translate }}"
                                          container="body"
                                          placement="{{ matchMedia.md_max ? 'right' : 'top' }}"
                                          containerClass="tooltip-large"
                                          tabindex="-1">
                                    <svg-icon name="question_mark_circle" />
                                  </button>
                                </i>
                                <div class="c-tds__item-info">
                                  <label class="c-tds__item-label">{{ 'review_quote.top_up.prompt' | translate }}:</label>
                                  <span class="c-tds__item-value w-100">
                                    <label class="custom-select"
                                           style="background: transparent;">{{topUpDays}}
                                      {{ 'review_quote.top_up.days' | translate }}</label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <div class="col-12 col-sm-8 col-md-8 col-lg-4"
                               *ngIf="deductibleAmounts && deductibleAmounts.length > 0">
                            <div class="c-tds__item list-group-item">
                              <i class="c-tds__item-icon c-tds__item-icon--tooltip">
                                <button type="button"
                                        class="btn btn-link m-0 p-0"
                                        tooltip="{{ 'review_quote.deductible.tooltip' | translate }}"
                                        container="body"
                                        placement="{{ (matchMedia.md_max || !multiTripProductIsSelected) ? 'right' : 'top' }}"
                                        containerClass="tooltip-large"
                                        tabindex="-1">
                                  <svg-icon name="question_mark_circle" />
                                </button>
                              </i>
                              <div class="c-tds__item-info">
                                <label class="c-tds__item-label">{{ 'review_quote.deductible.prompt' | translate }}:</label>
                                <span class="c-tds__item-value w-100">
                                  <select class="custom-select"
                                          ngControl
                                          id="deductibleAmount"
                                          [ngModel]="deductibleAmount"
                                          (ngModelChange)="onDeductibleChange($event)"
                                          [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let deductible_amount of deductibleAmounts"
                                            [value]="deductible_amount">
                                      {{ deductible_amount | currency:'CAD':'symbol-narrow':'1.2-2' }}
                                    </option>
                                  </select>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-5"
                               [ngClass]="{'col-xl-5': product && !multiTripProductIsSelected}">

                            <div class="alert alert-dark">
                              <loading-overlay *ngIf="showLoading" />
                              <div class="c-tds__item c-tds__item--subtotal-price list-group-item">
                                <div class="c-tds__item-info"
                                     *ngIf="quoteSelected?.memberDiscount
                            || quoteSelected?.companionDiscount
                            || quoteSelected?.deductibleDiscount
                            || quoteSelected?.familyDiscount
                            || quoteSelected?.hasPromoDiscount">
                                  <label class="c-tds__item-label">{{ 'review_quote.subtotal.prompt' | translate }}:</label>
                                  <span class="c-tds__item-value">
                                    {{ quoteSelected?.subtotalPrice   | currency }}
                                  </span>
                                </div>
                              </div>
                              <ng-container *ngIf="quoteSelected?.hasDeductibleDiscount">
                                <div class="c-tds__item c-tds__item--deductible-discount-price list-group-item">
                                  <div class="c-tds__item-info">
                                    <label class="c-tds__item-label">{{ 'review_quote.deductible_discount.prompt' | translate }}:</label>
                                    <span class="c-tds__item-value">
                                      (-{{ quoteSelected?.deductibleDiscount | currency }})
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="quoteSelected?.hasFamilyDiscount">
                                <div class="c-tds__item c-tds__item--family-discount-price list-group-item"
                                     [ngClass]="{'c-tds__item--price-increase': quoteSelected?.familyDiscount < 0}">
                                  <div class="c-tds__item-info">
                                    <label class="c-tds__item-label">{{ 'review_quote.family_discount.prompt' | translate }}:</label>
                                    <span class="c-tds__item-value">
                                      {{ quoteSelected?.familyDiscount  | discount  }}
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="quoteSelected?.hasMemberDiscount">
                                <div class="c-tds__item c-tds__item--member-discount-price list-group-item">
                                  <div class="c-tds__item-info">
                                    <label class="c-tds__item-label">{{ 'review_quote.member_discount.prompt' | translate }}:</label>

                                    <span class="c-tds__item-value">
                                      (-{{ quoteSelected?.memberDiscount | currency }})
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="quoteSelected?.hasPromoDiscount">
                                <div class="c-tds__item c-tds__item--promo-discount-price list-group-item">
                                  <div class="c-tds__item-info">
                                    <label class="c-tds__item-label">{{ 'review_quote.promo_discount.prompt' | translate }}:</label>

                                    <span class="c-tds__item-value">
                                      (-{{ quoteSelected?.promoDiscount | currency }})
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                              <div class="c-tds__item c-tds__item--total-price list-group-item">
                                <div class="c-tds__item-info">
                                  <label class="c-tds__item-label">{{ 'review_quote.total_cost.prompt' | translate }}:</label>
                                  <span class="c-tds__item-value text-success">
                                    {{ quoteSelected?.totalPrice  | currency  }}
                                  </span>
                                </div>
                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <ng-container *ngIf="matchMedia.xs_max && !renew && (!isRvd || hasOptions)">
                  <div class="c-tds__quote-link">
                    <save-quote [disabled]="showLoading" />
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>

      </div>
      <div class="row">
        <div class="col-12 mb-4 d-flex flex-column flex-md-row">
          <a tabindex="-1"
             href="javascript:void(0)"
             (click)="showEligibilityRequirementsModal()">{{ 'policy_matrix.view_eligibility_requirements' | translate }}</a>

          <a class="ml-0 ml-md-2"
             href="{{ 'review_quote.policy_booklet_url' | translate }}"
             target="_blank">{{ 'review_quote.view_policy_booklet' | translate }}</a>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="btn-group mr-2 d-block d-sm-inline-block"
               role="group">
            <button class="btn btn-secondary btn-lg btn--icon-chevron-right col-12"
                    id="review-quote-submit"
                    type="button"
                    (click)="openEligibilityDialog($event)"
                    [disabled]="showLoading">
              <span class="px-1">{{ 'review_quote.submit' | translate }}</span>
            </button>
          </div>
          <save-quote *ngIf="!matchMedia.xs_max && !renew && (!isRvd || hasOptions)"
                      [disabled]="showLoading" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-5">
          <h3 class="h4 d-inline-block">
            <span class="pr-3">{{ 'review_quote.coverage_details_title' | translate }}</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <accordion>
            <accordion-group *ngFor="let item of highlights">
              <div accordion-heading>
                <strong>
                  <span>{{ item.title }}</span>
                  <span *ngIf="item.title_quote">&nbsp;</span>
                </strong>
                <small *ngIf="item.title_quote">
                  <span>-&nbsp;</span>
                  <span [innerHTML]="item.title_quote | safeHtml"></span>
                </small>
                <svg-icon name="caret" />
              </div>
              <section class="accordion-content">
                <p class="mb-0"
                   *ngIf="isString(item.desc); else buildDescTemplate"
                   [innerHTML]="item.desc | safeHtml"></p>

                <ng-template #buildDescTemplate>
                  <div class="dynamic-description">
                    <div *ngFor="let part of item.desc">
                      <button *ngIf="part.tooltip"
                              type="button"
                              class="btn btn-link m-0 p-0"
                              [tooltip]="part.tooltip"
                              container="body"
                              placement="{{ matchMedia.md_max ? 'right' : 'top' }}"
                              containerClass="tooltip-large"
                              tabindex="-1">
                        {{ part.text }}
                      </button>

                      <p *ngIf="!part.tooltip"
                         class="mb-0"
                         [innerHTML]="part.text | translate | safeHtml"></p>
                    </div>
                  </div>
                </ng-template>
              </section>
            </accordion-group>
          </accordion>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            <small [innerHTML]="'review_quote.rvd.legal_notice' | translate | safeHtml"></small>
          </p>
          <p class="mb-0">
            <small>{{ 'review_quote.terms_and_conditions_apply' | translate}}</small>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>