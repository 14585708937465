<tabset #wizard
        class="c-wizard d-none"
        [class.d-block]="visible">
  <tab *ngFor="let tab of tabData; let tabIndex = index;"
       [routerLink]="tab.path"
       [customClass]="'analytic-ama--link--nav-' + tab.analyticsClass"
       routerLinkActive="active"
       [active]="tab.active"
       (selectTab)="navigate(tab.path); tab.active = true;"
       (deselect)="tab.active = false"
       [disabled]="activeTabIndex < tabIndex">
    <ng-template tabHeading>
      <section class="c-wizard__step">
        <div class="c-wizard__step-inner">
          <figure class="c-wizard__step-icon">
            <svg-icon name="checkmark" />
          </figure>
          <span class="c-wizard__step-label"
                [innerHTML]="'wizard.tabs.' + tab.translation_key | translate | safeHtml"></span>
        </div>
      </section>
    </ng-template>
  </tab>
</tabset>