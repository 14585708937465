import { Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import { BaseComponent } from '../../base-component.component';
import { BannerOptions } from './bannerOptions';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { TST_THEMES } from 'src/app/models/tst/tst';
import _ from 'underscore';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'banner',
  templateUrl: 'banner.html',
})
export class BannerComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() public options: BannerOptions;

  public showBanner: boolean;
  public hideBannerImage: boolean;
  public classes: any;
  public imageStyles: any;
  public showTST: boolean;
  public showFeaturedImageContent: boolean;
  public tstThemes = TST_THEMES;
  public isTstBanner: boolean;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private matchMediaService: MatchMediaService, private cd: ChangeDetectorRef) {
    super();
    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.handleChanges();
      })
    ).attach(this);
  }

  public ngOnInit() {
    this.isTstBanner = false;
    this.options ?? (this.options = this.getDefaultOptions());
    this.handleChanges();
  }

  public ngOnChanges() {
    this.handleChanges();
  }
  public handleChanges() {
    let isCustom = false;
    let isTST = false;
    let isShort = false;
    this.classes = {};
    this.imageStyles = {};
    this.showTST = false;
    this.showFeaturedImageContent = false;
    this.showBanner = false;

    if (hasValue(this.options) && (hasValue(this.options.featuredImage) || this.options.showTSTWidget)) {
      this.showBanner = true;
      this.showFeaturedImageContent = hasValue(this.options.featuredImageContent);
      this.showTST = this.options.showTSTWidget;

      // Compute the classes
      if (this.options.showTSTWidget) {
        if (this.options.bannerHeight > 600) {
          isCustom = true;
        } else {
          isTST = true;
        }
      } else if (this.options.bannerHeight && this.options.bannerHeight !== 320) {
        isCustom = true;
      } else {
        isShort = true;
      }

      this.classes = {
        'c-banner--custom-height': isCustom,
        'c-banner--tst-banner-height': isTST,
        'c-banner--short': isShort,
      };

      // Check the match media type
      if (!this.matchMediaService.matches(MEDIA_TYPE.Phone)) {
        if (isCustom) {
          this.imageStyles = {
            height: `${this.options.bannerHeight}px`,
          };
        }
        this.hideBannerImage = false;
      } else {
        this.hideBannerImage = true;
      }
      this.cd.detectChanges();
    }
  }

  public isTSTBannerPage(event) {
    this.isTstBanner = event;
  }
  public getDefaultOptions(): BannerOptions {
    const defaultOptions = new BannerOptions();
    defaultOptions.showTSTWidget = true;
    defaultOptions.defaultTSTTab = 'hotel';
    defaultOptions.defaultTSTDepartureAirportCode = null;
    defaultOptions.bannerHeight = 600;
    defaultOptions.isFeaturedImageFullWidth = true;
    defaultOptions.featuredImage =
      'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/man-looking-out-over-mountain-range.jpg';
    defaultOptions.fallbackImage = '/assets/other/homepage-default-banner-min.jpg';
    defaultOptions.defaultTSTImageMobile = '/assets/other/homepage-mobile-beach-banner.jpg';
    defaultOptions.fallbackColor = '';
    defaultOptions.featuredImageContent = null;
    defaultOptions.showTagline = false;
    defaultOptions.taglineFront = null;
    defaultOptions.taglineBack = null;
    return defaultOptions;
  }
}
