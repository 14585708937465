import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'error-modal',
  templateUrl: './error-modal.component.html',
})
export class ErrorModalComponent implements OnInit {
  title: string;
  message: string;
  primaryButtonText: string;
  primaryButtonRoute: string;
  secondaryButtonText: string;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onSubmit() {}
}
