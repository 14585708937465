import { Pipe, PipeTransform } from '@angular/core';

/**
 * A general pipe that allows function calls to be called from the template.
 */
@Pipe({ name: 'map' })
export class MapPipe implements PipeTransform {
  constructor() {}

  transform(format: any, fn: any, ...args: any[]) {
    return fn && fn(format, ...args);
  }
}
