<header class="c-ata-header p-0 u-ama-bg-color--navy-blue">
  <div class="container">
    <div class="row justify-content-between">
      <div class="c-ata-header__brand d-flex align-self-center mt-3 mb-3">
        <a class="c-ata-header__brand-svg mr-3"
           routerLink="/coverage"
           routerLinkActive="active"
           analytic-ama="form">
          <img src="assets/other/ata_logo.png"
               alt="ata_logo" />
        </a>
        <div class="vr"></div>
        <a class="ml-3 d-flex align-items-center c-ata-header__brand-svg"
           routerLink="/coverage"
           attr.analytic-ama='header-home-link'>
          <img src="assets/ama/ama_logo.png"
               alt="ama_logo" />
        </a>
      </div>

      <div class="c-ata-header___call mb-2 d-none d-md-flex justify-content-end">
        <div class="c-ata-header__call-wrapper media mr-2">
          <img class="align-self-center mr-3"
               src="assets/ama/icon-call-us.jpg"
               alt="Call us icon" />
          <div class="media-body align-self-center">
            <span class="small">{{'header.questions' | translate}}</span>
            <h5 class="mt-0 call-us"
                [innerHTML]="'header.call' | translate | safeHtml"></h5>
          </div>
        </div>

        <ul class="navbar-nav mt-0 justify-content-center">
          <li class="nav-item">
            <div class="small">{{'header.hours.m-f' | translate}}</div>
            <div class="small">{{'header.hours.s-s' | translate}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<router-outlet name="content-header" />