/**
 * Form control that increments or decrements (steps) multiple number fields
 * similar to https://css-tricks.com/number-increment-buttons/
 * Used on TST widget for Guests or travellers on various tabs
 * To prepare, create as defined in 'app/models/tst/GUEST_INCREMENTORS',
 * and create a formGroup with the controls you want to increment
 * Set the FormGroup to the 'form' attribute. see 'data' @Input()
 */
import {
  Component,
  OnInit,
  OnChanges,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  TemplateRef,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/components/base-component.component';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import _ from 'underscore';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'form-stepper',
  templateUrl: 'formStepper.template.html',
  styleUrls: ['formStepper.scss'],
  providers: [NgbDropdownConfig],
})
export class FormStepperComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('incrementForm')
  public incrementForm: TemplateRef<ElementRef>;
  @ViewChild('selectTrigger')
  public selectTrigger: TemplateRef<ElementRef>;

  @Output()
  public callback = new EventEmitter<any>();
  @Output()
  public dialogOpenState = new EventEmitter<any>();
  @Output()
  public triggerOutput = new EventEmitter<any>();
  @Output()
  public triggerInput = new EventEmitter<any>();

  @Input()
  public data; // for TST widget defined in app/models/tst/GUEST_INCREMENTORS
  @Input()
  public dialog;
  @Input()
  public coords: number[];
  @Input()
  public title;
  @Input()
  public globalMax: number;
  public controls: any[];
  public hasInclude: boolean;
  public mq;
  public full: boolean;
  public showThresholdWarning: boolean = false;
  public showTotalWarning: boolean = false;
  public combinedTotalWarning: boolean = false;
  public dialogOpen: boolean = false;
  public dialogName;
  public lastChangedControl;
  public lastChangeDirection: string;
  public hasTriggers: boolean = false;
  public triggerElements: any[];
  private dialogTopLevelParent;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService,
    private elementRef: ElementRef,
    public dropdownConfig: NgbDropdownConfig
  ) {
    super();
    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.updateMQ();
      })
    ).attach(this);
    this.controls = [];
    this.dropdownConfig.placement = this.setPlacement();
  }

  public ngOnInit() {
    this.dialogName = hasValue(this.data.dialogName) ? this.data.dialogName : null;
    this.coords = hasValue(this.data.coords) ? this.data.coords : [0, 0];

    if (hasValue(this.data.include) && this.data.include.length) this.filterControls();
    else this.hasInclude = false;

    this.title = hasValue(this.data.title) ? this.data.title : 'Please Choose';
    this.updateMQ();
    this.data.form.valueChanges.subscribe((val) => {
      this.isFull();
      this.updateTSTForm(val);
    });
    this.globalMax = this.globalMax ? this.globalMax : 6;
  }

  public ngOnChanges() {
    this.updateMQ();
  }

  public ngAfterViewInit() {}

  public filterControls() {
    this.hasInclude = true;
    if (hasValue(this.data.include) && this.data.include.length) {
      _.each(
        this.data.include,
        (e: any) => {
          const ctrl = this.data.form.get(e.control);
          if (hasValue(ctrl)) {
            e.defaultVal && ctrl.setValue(e.defaultVal);
            this.controls.push({
              control: ctrl, // formControl
              props: e, // custom data
            });
          }
        },
        this
      );
    }
  }

  public decrease(params) {
    this.lastChangedControl = params.control;
    this.lastChangeDirection = 'decrease';
    const total = this.getTotal();
    const max = this.getMax();
    this.showTotalWarning = total - 1 > max ? true : false;
    this.processCombinedTotals(params, 'minus');

    if (!this.combinedTotalWarning && params.control.control.value > params.control.props.config.min && total - 1) {
      params.control.control.value--;
      this.data.form.get(params.control.props.control).setValue(params.control.control.value);
      if (this.data.config && this.data.config.total) this.data.config.total--;
    }

    if (hasValue(params.control.props.config.threshold) && params.control.control.value < params.control.props.config.threshold)
      this.showThresholdWarning = false;

    // if changed field has a trigger, process trigger or if just callback, do that.
    if (this.lastChangedControl.props.trigger) this.processTrigger(this.lastChangedControl, this.lastChangeDirection);

    return params.control.control.value;
  }
  public increase(params) {
    this.lastChangedControl = params.control;
    this.lastChangeDirection = 'increase';
    const total = this.getTotal();
    const max = this.getMax();
    this.showTotalWarning = total + 1 > max ? true : false;
    this.processCombinedTotals(params, 'add');

    if (!this.combinedTotalWarning && total < max && params.control.control.value < params.control.props.config.max) {
      params.control.control.value++;
      this.data.form.get(params.control.props.control).setValue(params.control.control.value);
      if (this.data.config && this.data.config.total) this.data.config.total++;
    }

    if (hasValue(params.control.props.config.threshold) && params.control.control.value >= params.control.props.config.threshold) {
      this.showThresholdWarning = true;
    }

    // if changed field has a trigger, process trigger or if just callback, do that.
    if (total < max && (this.lastChangedControl.props.trigger || this.data.callback))
      this.processTrigger(this.lastChangedControl, this.lastChangeDirection);

    return params.control.control.value;
  }

  private getMax(): number {
    /** max values for all items in stepper */
    const max = this.data.config && this.data.config.totalMax ? this.data.config.totalMax : this.globalMax;
    return max;
  }

  private getTotal(): number {
    let total: number = 0;
    _.each(
      this.controls,
      (c) => {
        total += c.control.value;
      },
      this
    );
    return total;
  }

  private updateTSTForm(val: any = null) {
    /** called from valueChanges.subscribe() */
    _.each(
      this.controls,
      (c) => {
        if (!c.props.ignoreUpdate) {
          if (hasValue(c.props.tstFieldForm) && hasValue(c.props.tstField))
            if (c.props.tstFieldForm[c.props.tstField] && c.control) {
              c.props.tstFieldForm[c.props.tstField].value = c.control.value;
              //	defaultVal = c.props.defaultVal ? c.props.defaultVal : 0;
            }
        }
      },
      this
    );

    if (!this.lastChangedControl.props.trigger && this.data.callback) this.processCallback();
  }

  private processTrigger(control, type: string) {
    /**
     * Triggers are when value changes need to trigger more fields to be generated/removed
     * e.g. an age selector generated for each child added.
     */
    const trigger = control.props.trigger;
    const max = control.props.config.max ? control.props.config.max : null;
    const min = control.props.config.min ? control.props.config.min : 0;
    if (trigger) {
      const addedItems = trigger.addItems.length;
      switch (trigger.controlType) {
        case 'select': {
          if (type == 'increase') {
            if (!max || addedItems < max) {
              this.data.form.addControl(trigger.namePrefix + addedItems, new FormControl(trigger.initialValue, Validators.required));
              trigger.addItems.push({
                control: this.data.form.get(trigger.namePrefix + addedItems),
              });
            }
          }
          if (type == 'decrease') {
            if (addedItems > min) {
              this.data.form.removeControl(trigger.namePrefix + (addedItems - 1));
              trigger.addItems.pop();
            }
          }
          break;
        }
      }
    }
    this.data.callback && this.processCallback();
  }

  public triggerChange(event, item, type) {
    item.control.setValue(event.target.value);
    this.data.callback && this.processCallback();
  }

  private processCallback() {
    /**
     * callbacks refer to outputing each change to formStepper controls
     * type: string
     * values: value changes to form stepper item,
     * data: object passed via @input,
     * control: control item just changed
     */
    this.callback.emit({
      type: this.data.type,
      values: this.data.form.getRawValue(),
      data: this.data,
      control: this.lastChangedControl,
    });
  }

  private getCombinedTotals() {
    const cTotals = this.data.config.combinedTotals;
    let total = 0;
    _.each(cTotals.items, (item: any) => {
      total += this.data.form.get(item).value;
    });
    return total;
  }

  private processCombinedTotals(params, change: any = 'minus') {
    /**
     * combinedTotals is when multiple control values together have a max or min threshold
     * eg. must have at least one adult or senior to book flight. */
    let combinedTotals: any;
    if (hasValue(this.data.config.combinedTotals)) combinedTotals = this.getCombinedTotals();
    if (combinedTotals != null && this.data.config.combinedTotals.items.indexOf(params.control.props.control) > -1) {
      if (change == 'minus')
        this.combinedTotalWarning = !(combinedTotals - 1 >= this.data.config.combinedTotals.compareValue) ? true : false;
      if (change == 'add') this.combinedTotalWarning = !(combinedTotals + 1 >= this.data.config.combinedTotals.compareValue) ? true : false;
    } else {
      this.combinedTotalWarning = false;
    }
  }

  public setPlacement() {
    const y = 'bottom';
    const width = window.innerWidth;
    const position = this.elementRef.nativeElement.getBoundingClientRect();
    const x = position.left <= width - position.right ? 'left' : 'right';
    return y + '-' + x;
  }

  public isFull() {
    // if at least three items are shown, decrease font size
    let count = 0;
    _.each(
      this.controls,
      (c) => {
        c.control.value > 0 && count++;
      },
      this
    );
    this.full = count > 2 ? true : false;
  }

  private updateMQ() {
    this.mq = {
      mobile: this.matchMediaService.matches(MEDIA_TYPE.Phone),
      tablet: this.matchMediaService.matches(MEDIA_TYPE.Tablet),
      desktop: this.matchMediaService.matches(MEDIA_TYPE.Desktop),
    };
    this.dropdownConfig.placement = this.setPlacement();
  }

  public dismissWarning(bool: string) {
    if (bool == 'showTotalWarning') this.showTotalWarning = false;
    if (bool == 'showThresholdWarning') this.showThresholdWarning = false;
    if (bool == 'combinedTotalWarning') this.combinedTotalWarning = false;
  }
}
