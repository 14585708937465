import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { Injectable } from '@angular/core';
import { ProductGroup } from 'src/app/models/products';
import { IAppState } from 'src/app/models/app-state.model';
import { startOfToday } from 'date-fns';
import { ExternalLinkParamsToAppStateMapper } from 'src/app/components/postern/external-link-to-app-state-mapper.service';
import { QueryParamsMapper } from 'src/app/components/postern/query-param-mapper.service';
import { Params, UrlTree, Router } from '@angular/router';
import { FunnelStream } from './google.analytics.service';
import { NgRedux } from '@angular-redux-ivy/store';
import { SalesFunnelActionType, ILoadQuoteAction } from 'src/app/store/reducer';

export interface IPosternResolvedState {
  initialState: IAppState;
  nextUrlTree: UrlTree;
  funnelStream: FunnelStream;
}

@Injectable({
  providedIn: 'root',
})
export class PosternService {
  constructor(
    private readonly router: Router,
    private readonly externalLinkParamsToAppStateMapper: ExternalLinkParamsToAppStateMapper,
    private readonly reduxStore: NgRedux<IAppState>
  ) {}

  public resolve(paramMap: Params): IPosternResolvedState {
    const initialState = this.externalLinkParamsToAppStateMapper.mapExternalLinkToAppState(QueryParamsMapper.mapInitialState(paramMap));

    // Where should we redirect the user?
    if (initialState.tripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
      return {
        initialState,
        nextUrlTree: this.router.parseUrl('review-quote'),
        funnelStream: FunnelStream.RentalVehicleDamage,
      };
    } else if (this.validateAppState(initialState)) {
      return {
        initialState,
        nextUrlTree: this.router.parseUrl('trip-details'),
        funnelStream: FunnelStream.Widget,
      };
    } else {
      return {
        initialState,
        nextUrlTree: this.router.parseUrl('coverage'),
        funnelStream: FunnelStream.Direct,
      };
    }
  }

  private validateAppState(initialState: IAppState): boolean {
    /*
    -- if we have a single trip flow and both departure departure date and return date are valid
    -- if we have a multi trip flow and both policy start date and duration of first trip are valid
    -- Forward user to the /trip-details page, otherwise params are invalid and navigate to /coverage
    */
    const td = initialState.tripDetails;
    const flow = td.productGroupFlow;

    // The url parameter is parsed as UTC. Therefore we need to compare it the same way.
    const start = DateHelpers.forceIntoUtc(startOfToday());

    if (flow === ProductGroup.SingleTripFlow || flow === ProductGroup.TCIFlow || flow === ProductGroup.VisitorsToCanadaFlow) {
      return td.departureDate >= start && td.returnDate ? true : false;
    }

    if (flow === ProductGroup.MultiTripFlow) {
      return td.policyStartDate >= start && td.durationOfFirstTrip ? true : false;
    }
    return false;
  }

  public updateStore(resolved: IPosternResolvedState) {
    this.reduxStore.dispatch({
      type: SalesFunnelActionType.LOAD_QUOTE,
      payload: resolved.initialState,
    } as ILoadQuoteAction);
  }
}
