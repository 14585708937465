import { Injectable, NgZone } from '@angular/core';
import { hasValue } from 'src/app/components/base-component.component';
import { Subject, Observable } from 'rxjs';
import { values, any } from 'underscore';

/**
 * The various media type names supported by the match media service.
 */
export enum MEDIA_TYPE {
  Print = 'print',
  Screen = 'screen',
  TravelPhone = 'travel_phone',
  TravelTablet = 'travel_tablet',
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop',
  LDesktop = 'ldesktop',
  XLDesktop = 'xldesktop',
  Portrait = 'portrait',
  Landscape = 'landscape',
  Retina = 'retina',
  // Bootstrap media queries
  xs_max = 'xs_max',
  sm = 'sm',
  sm_max = 'sm_max',
  md = 'md',
  md_max = 'md_max',
  lg = 'lg',
  lg_max = 'lg_max',
  xl = 'xl',
}

/**
 * All media type names in an array
 */
export const MEDIA_TYPE_NAMES: MEDIA_TYPE[] = values(MEDIA_TYPE);

/**
 * Rules associated with each enum type.
 */
const MEDIA_TYPE_RULES = {
  // Old media types
  [MEDIA_TYPE.Print]: 'print',
  [MEDIA_TYPE.Screen]: 'screen',
  [MEDIA_TYPE.TravelPhone]: '(max-width: 736px)',
  [MEDIA_TYPE.TravelTablet]: '(min-width: 737px) and (max-width: 991px)',
  [MEDIA_TYPE.Phone]: '(max-width: 575px)',
  [MEDIA_TYPE.Tablet]: '(min-width: 576px) and (max-width: 991px)',
  [MEDIA_TYPE.Desktop]: '(min-width: 992px)',
  [MEDIA_TYPE.LDesktop]: '(min-width: 1200px)',
  [MEDIA_TYPE.XLDesktop]: '(min-width: 1920px)',
  [MEDIA_TYPE.Portrait]: '(orientation: portrait)',
  [MEDIA_TYPE.Landscape]: '(orientation: landscape)',
  [MEDIA_TYPE.Retina]: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
  // Bootstrap media queries, uses the values in the _settings.config.scss
  [MEDIA_TYPE.xs_max]: '(max-width: 575px)',
  [MEDIA_TYPE.sm]: '(min-width: 576px)',
  [MEDIA_TYPE.sm_max]: '(max-width: 767px)',
  [MEDIA_TYPE.md]: '(min-width: 768px)',
  [MEDIA_TYPE.md_max]: '(max-width: 991px)',
  [MEDIA_TYPE.lg]: '(min-width: 992px)',
  [MEDIA_TYPE.lg_max]: '(max-width: 1199px)',
  [MEDIA_TYPE.xl]: '(min-width: 1200px)',
};

@Injectable({
  providedIn: 'root',
})
export class MatchMediaService {
  /**
   * This property is added here only for the purpose of templates being able to access it.
   * Example:
   * <div [hidden]="vm.matchMediaService.matches(vm.matchMediaService.MEDIA_TYPE.Desktop)"/>
   */
  public MEDIA_TYPE = MEDIA_TYPE;

  private matchResults;
  private changeSubject: Subject<any>;

  constructor(private ngZone: NgZone) {
    this.matchResults = {};

    // A hot observable
    this.changeSubject = new Subject();

    if (hasValue(window.matchMedia)) {
      // Create a listener for each media type
      MEDIA_TYPE_NAMES.forEach((type) => {
        const rule = MEDIA_TYPE_RULES[type];
        const mql = window.matchMedia(rule);
        // Update the result right away
        this.updateMatch(type, mql.matches);
        // Add a listener for all future updates
        mql.addEventListener('change', (m) => this.updateMatch(type, m.matches));
      });
    }
  }

  private updateMatch(type: MEDIA_TYPE, value) {
    this.matchResults[type] = !!value;
    // Re-enter angular 4 context when updating the match
    this.ngZone.run(() => this.changeSubject.next(null));
  }

  /**
   * Check if any of the types are currently being matched.
   */
  public matches(...types: MEDIA_TYPE[]) {
    return any(types, (type) => this.matchResults[type]);
  }

  /**
   * Listen to changes with the media types.
   */
  public onChange(): Observable<any> {
    // Attempts at adding debounce to this subject
    // only caused weird rendering issues in chrome and IE.
    // There aren't too many real benefits of optimizing the
    // event emission since they are so rare.
    return this.changeSubject;
  }
}
