import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
  selector: '[emailpattern][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailPatternDirective, multi: true }],
})
export class EmailPatternDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.pattern(/[\w._+-]*@[\w\s+-.]+(\.)+[a-zA-Z]{2,}/)(control);
  }
}
