<div class="o-save-quote">
    <div class="modal-header justify-content-center align-items-center">
        <h3 class="modal-title">{{ 'save_quote.title' | translate }}</h3>
        <button type="button"
                class="u-close u-close--light-hover"
                data-dismiss="modal"
                aria-label="Close"
                (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="o-save-quote__blurb">{{'save_quote.blurb' | translate }}</p>
        <form connect="travellerInfo"
              class="o-save-quote__form"
              #f="ngForm"
              [ngClass]="{'clickedOnceEmailEmpty' : (submitClickedOnce || f.touched) && primaryEmailAddress.invalid}"
              (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12 col-md-10 m-auto">
                    <div class="row my-2">
                        <div class="form-group col-12">
                            <label class="h6 font-weight-bold mt-3"
                                   for="primaryEmailAddress">{{ 'save_quote.email_address.prompt' | translate }}</label>
                            <input type="text"
                                   id="primaryEmailAddress"
                                   class="form-control"
                                   placeholder="{{ 'save_quote.email_address.place_holder' | translate }}"
                                   emailpattern
                                   ngControl
                                   ngModel
                                   name="emailAddress"
                                   #primaryEmailAddress="ngModel"
                                   required
                                   email
                                   minlength="5"
                                   [ngClass]="{ 'is-invalid': (submitClickedOnce || f.touched) && primaryEmailAddress.invalid }" />
                            <div *ngIf="(submitClickedOnce || f.touched) && primaryEmailAddress.invalid"
                                 class="invalid-feedback">
                                <span
                                      *ngIf="primaryEmailAddress.errors?.required">{{ 'save_quote.email_address.required' | translate }}</span>
                                <span *ngIf="primaryEmailAddress.errors?.email">{{ 'save_quote.email_address.invalid' | translate }}</span>
                                <span
                                      *ngIf="primaryEmailAddress.errors?.pattern">{{ 'traveller_info.email_address.invalidPattern' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row my-2">
                        <div class="form-check col-12">
                            <div class="col-12">
                                <input class="form-check-input"
                                       type="checkbox"
                                       value=""
                                       (change)="termsChecked = !termsChecked"
                                       required
                                       id="saveQuoteTerms"
                                       [ngClass]="{ 'is-invalid': (f.form.touched || submitClickedOnce) && !termsChecked }" />
                                <label class="form-check-label"
                                       for="saveQuoteTerms"
                                       [innerHTML]="'save_quote.terms.terms_label' | translate | safeHtml">
                                </label>
                                <div *ngIf="(submitClickedOnce) && !termsChecked"
                                     class="invalid-feedback">
                                    <span *ngIf="!termsChecked">{{ 'save_quote.terms.invalid' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <button type="button"
                                    class="col-12 no-gutters btn btn-secondary btn-lg"
                                    id="save-quote-submit"
                                    [ngClass]="{'btn--loading': isLoading}"
                                    (click)="onSubmit()">
                                <span class="px-1">{{ 'save_quote.submit' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>