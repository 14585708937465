import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppInsights } from 'applicationinsights-js';

import { IAppConfig, IServerConfig } from '../../models/app-config.model';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { IClubWebsiteMapping } from '../../models/data.interfaces';
import { IEventConfig } from 'src/app/models/event-config-model';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClubConfigurationService {
  private readonly baseApiUrl: string;

  private readonly clubMapping: Array<IClubWebsiteMapping> = [
    {
      province: 'Alberta',
      clubName: 'CAAAMA',
      code: 'AB',
      url: 'https://ama.ab.ca/travel/travel-insurance',
    },
    {
      province: 'British Columbia',
      clubName: 'CAABC',
      code: 'BC',
      url: 'https://www.bcaa.com/insurance/travel',
    },
    {
      province: 'New Brunswick',
      clubName: 'ATL',
      code: 'NB',
      url: 'https://www.atlantic.caa.ca/travel-with-caa/travel-insurance.html',
    },
    {
      province: 'Newfoundland and Labrador',
      clubName: 'ATL',
      code: 'NL',
      url: 'https://www.atlantic.caa.ca/travel-with-caa/travel-insurance.html ',
    },
    {
      province: 'Nova Scotia',
      clubName: 'ATL',
      code: 'NS',
      url: 'https://www.atlantic.caa.ca/travel-with-caa/travel-insurance.html',
    },
    {
      province: 'Manitoba',
      clubName: 'CAAMB',
      code: 'MB',
      url: 'https://www.caamanitoba.com/insurance/travel',
    },
    {
      province: 'Ontario (Niagara region)',
      clubName: 'CAANIA',
      code: 'ON',
      url: 'https://caaniagara.ca/insurance/travel',
    },
    {
      province: 'Ontario (Ottawa region)',
      clubName: 'CAANEO',
      code: 'ON',
      url: 'https://caaneo.ca/travel/caa-travel-insurance',
    },
    {
      province: 'Ontario (South Central region)',
      clubName: 'CAASCO',
      code: 'ON',
      url: 'https://www.caasco.com/insurance/travel',
    },
    {
      province: 'Prince Edward Island',
      clubName: 'ATL',
      code: 'PE',
      url: 'https://www.atlantic.caa.ca/travel-with-caa/travel-insurance.html',
    },
    {
      province: 'Saskatchewan',
      clubName: 'CAASK',
      code: 'SK',
      url: 'https://caask.ca/insurance/travel/caa-travel-insurance',
    },
    {
      province: 'Northwest Territories',
      clubName: 'CAAAMA',
      code: 'NT',
      url: '',
    },
  ];

  public appConfig: IAppConfig;

  public serverConfig: IServerConfig;

  constructor(private readonly httpClient: HttpClient) {
    this.baseApiUrl = `${environment.baseApiUrl}/configuration`;
  }

  public getConfig(): Promise<IAppConfig> {
    return lastValueFrom(this.httpClient.get<IAppConfig>(`${this.baseApiUrl}`).pipe(tap((config) => (this.appConfig = config))), undefined);
  }

  public getServerConfig(): Promise<IServerConfig> {
    return lastValueFrom(
      this.httpClient.get<IServerConfig>(`${this.baseApiUrl}/server-config`).pipe(
        tap((config) => {
          this.serverConfig = config;
          // Get AppInsights downloaded and installed
          if (config.applicationInsightsKey) {
            AppInsights.downloadAndSetup({ instrumentationKey: config.applicationInsightsKey });
          }

          // An issue was found with the appinsights library and zone.js.
          // The error was being incorrectly tracked and kept showing up as [object ErrorEvent]
          // in appinsights. This patch function is responsible for remapping the function parameters.
          const originalOnError = window['onerror'];
          window['onerror'] = function (message, url, lineNumber, columnNumber, error) {
            if (message && Object.prototype.toString.call(message) === '[object ErrorEvent]') {
              const unwrapped = message as ErrorEvent;
              message = unwrapped.message;
              url = unwrapped.filename;
              lineNumber = unwrapped.lineno;
              columnNumber = unwrapped.colno;
              error = unwrapped.error;
            }
            originalOnError.apply(this, [message, url, lineNumber, columnNumber, error]);
          };
        })
      ),
      undefined
    );
  }

  public isProvinceCodeSupported(provinceCode: string): boolean {
    provinceCode = provinceCode && provinceCode.toLowerCase();
    return this.appConfig.settings.supported_province_codes.map((x) => x.toLowerCase()).indexOf(provinceCode) !== -1;
  }

  public getClubWebsite(provinceCode: string): IClubWebsiteMapping | null {
    return this.clubMapping.find((m) => m.code === provinceCode.toUpperCase()) || null;
  }

  public getEventConfig(): Observable<IEventConfig> {
    return this.httpClient.get<IEventConfig>(`${this.baseApiUrl}/event-config`);
  }
}
