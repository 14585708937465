import { TenantEnum } from 'src/app/models/tenant.enum';
/* eslint-disable max-len */
import { Component } from '@angular/core';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { BaseComponent } from '../../base-component.component';
import { IAppConfigSettings } from 'src/app/models/app-config.model';
import { BannerOptions } from '../../tst-widget/banner/bannerOptions';

@Component({
  selector: 'main-page-layout',
  templateUrl: './main-page-layout.component.html',
})
export class MainPageLayoutComponent extends BaseComponent {
  TenantEnum = TenantEnum;
  appConfigSetting: IAppConfigSettings;
  public bannerOptions: BannerOptions;

  constructor(private readonly clubConfigurationService: ClubConfigurationService) {
    super();
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;
  }
}
