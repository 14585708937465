import { attach, AttachedSubscription } from 'amaweb-tsutils';
import { identity, Observable, of, Subscription } from 'rxjs';
import { delay, finalize, tap } from 'rxjs/operators';

interface IWithLoadOptions {
  firstValue?: boolean;
  delay?: number;
}

declare module 'rxjs' {
  interface Subscription {
    /**
     * Attach the subscription to a class annotated with @Unsubscribe.
     */
    attach(component: any): AttachedSubscription;
  }

  interface Observable<T> {
    withLoad(callback: (a: boolean) => void, options?: IWithLoadOptions): Observable<T>;
  }
}

function withLoad<T>(this: Observable<T>, callback: (a: boolean) => void, options?: IWithLoadOptions) {
  options = { firstValue: true, ...options };

  const loading$ = of(null)
    .pipe(
      options.delay ? delay(options.delay) : identity,
      tap((_) => callback(true))
    )
    .subscribe();

  const setLoadFinished = () => {
    loading$.unsubscribe();
    callback(false);
  };

  return (options.firstValue ? this.pipe(tap(setLoadFinished)) : this).pipe(finalize(setLoadFinished));
}

Subscription.prototype.attach = attach;
Observable.prototype.withLoad = withLoad;
