<div class="exclusive-deals-container"
     id="section-start">

  <div class="row px-4 px-lg-0">
    <div class="d-flex col-12 p-0">
      <div class="col-12 col-lg-6 pl-0">
        <h2 class="h1">Exclusive Travel Deals for ATA Members</h2>

        <p>Welcome to the start of a new school year! As you embark on another year of teaching, grading, and moulding young
          Albertans, don’t forget to start planning your next well-deserved break. And as an ATA member, you also deserve the
          best prices we can offer! These travel deals are specially curated for hardworking and dedicated teachers like you
          to help you plan your next holiday.
        </p>

        <p>
          Are you an AMA member too? Then stack your savings! Use your AMA membership when you’re booking your trip to save on
          everything you’ll need between here and there. That includes:
        </p>

        <ul class="checklist lightcheck">
          <li class="lightcheck">Up to 20% off car rentals
          </li>
          <li class="lightcheck">Up to 30% off on hotels</li>
          <li class="lightcheck">Best Price Guarantee on vacation packages booked online</li>
          <li class="lightcheck">Best Price Guarantee on hotels in North America</li>
          <li class="lightcheck">Exclusive savings and perks on select cruise lines, tour companies, Parks Canada passes, ski
            tickets, Canada Golf Cards, and more</li>
        </ul>

        <p>Stop dreaming and start planning! Book your appointment online today with an AMA travel agent, visit your nearest AMA
          centre, or call us toll-free <span class="text-nowrap"><a href="tel:18554748753"
               class="text-decoration-none"><strong>1-855-474-8753</strong></a></span>.</p>

      </div>
      <div class="col-6 d-none d-lg-block pr-0">
        <img alt="illustration of a family travelling in asia"
             style="max-width:525px; float:right;"
             src="https://amatravelinternal.blob.core.windows.net/icons/illustrations/asia-family-travel.png" />
      </div>
    </div>
  </div>

  <div class="row">
    <ng-container *ngFor="let deal of deals"
                  [ngTemplateOutlet]="deals_anchor_button"
                  [ngTemplateOutletContext]="{ deal: deal }" />
  </div>
  <deals-section *ngFor="let deal of deals"
                 [title]="deal.sectionTitle"
                 [anchor]="deal.anchor">

    <ng-container *ngIf="deal.title === 'Flight Deals'"
                  [ngTemplateOutlet]="flight_deal" />
    <ng-container *ngIf="deal.title === 'Package and Tour Deals'"
                  [ngTemplateOutlet]="packages_deal" />
    <ng-container *ngIf="deal.title === 'Professional Consulting'"
                  [ngTemplateOutlet]="professional_consulting" />

  </deals-section>

  <div class="col-sm-12 clearfix mt-5"
       style="border: 3px solid #00539b">
    <div class="text-center d-flex flex-column align-items-center"
         style="position: relative; top: -25px;">
      <div class="text-center">
        <div class="inline-block"
             style="background-color: #fff; padding-top: 6px;">
          <h2> Let Us Help You Choose A Vacation That’s Right For You </h2>
        </div>
      </div>
      <div class="col-12 col-md-8 text-center">
        <div class="u-padding-top-half u-margin-none">
          <p>Call <strong><a href="tel:1-855-474-8753"
                 class="invert-hover-link">1-855-474-8753</a></strong> to book these offers, or visit your nearest AMA
            centre to book with a travel agent. We recommend booking an appointment in advance. Professional consulting fee
            applies when booking with a travel agent. </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <a class="invert-hover-link font-weight-bold"
       (click)="scrollToAnchor($event, 'section-start')">
      Return to Top
    </a>
  </div>
</div>

<ng-template #deals_anchor_button
             let-deal="deal">
  <div class="col-12 col-md-3 d-flex mb-3">
    <div class="mpacks d-flex align-items-center w-100 px-3 py-4"
         (click)="scrollToAnchor($event, deal.anchor)">
      <div class="mr-3"
           style="max-width:100px"><img alt="flights icon"
             class="mw-100"
             src="{{deal.iconSrc}}"></div>
      <div>
        <a class="text-decoration-none"
           href="#{{deal.anchor}}">
          <h4 class="h6">{{deal.title}}</h4>
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #flight_deal>
  <section>
    <!--Air Canada-->
    <div class="row ama-card__header mt-3 mb-2 u-ama-bg-color--navy-blue pl-3">
      <h2 class="ama-card__header-title text-uppercase">Air Canada</h2>
    </div>
    <div class="row p-3 pt-lg-0">
      <div class="my-3 row">
        <div class="col-sm-6">
          <img src="https://amatravelinternal.blob.core.windows.net/suppliers/air-canada/air-canada-logo.png"
               alt="Air Canada Logo"
               style="max-width:250px" />
        </div>
      </div>
      <div class="d-flex row flex-wrap cruise-partner">
        <div class="col-12 col-md-8">
          <div>
            <h4 class="h5">ATA Offer - 10% OFF Canada and USA</h4>
            <ul class="pl-4">
              <li>Offer applicable on Air Canada flights (not including code-share flights)</li>
              <li>Receive 10% off available Economy class fares on Standard, Flex and Comfort rates, not including taxes
                and
                fees</li>
              <li>Click on Advanced Search in the Flight booking engine and then click on "Advanced Options" then enter the following Air
                Canada Promotion Code in the Promo Code box REU7ZMQ1</li>
              <li>Travel September 2, 2024, through August 30, 2025</li>
              <li>Valid for new bookings made by April 30, 2025</li>
              <li>Identify yourself as an ATA member and request this offer when booking with a travel agent</li>
              <li>No blackout dates</li>
              <li>Not applicable on Basic fares</li>
            </ul>
          </div>
          <ng-container [ngTemplateOutlet]="questions_cta" />
        </div>
        <div class="col-12 col-md-4">
          <div class="p-3 u-ama-bg-color--gray">
            <div class="d-none d-md-block">
              <img src="https://amatravelinternal.blob.core.windows.net/suppliers/air-canada/air-canada-vacations-e-gfit-card.jpg"
                   alt="air canada giftcard overlay on photo landscape photo of tropical beach"
                   class="mw-100" />
            </div>
            <div class="col-12 u-center mt-3">
              <p><strong>Cash Back for Your Next Trip</strong></p>

              <p>All AMA members earn 5% cash back in reward dollars with every Air Canada Gift card purchased from
                AMA.
                Ask your travel agent for more details on using a gift card to pay for your flight, travel options,
                advance seat selection, change fees and more. You can also use your Air Canada Gift Card to book
                your
                next flight-inclusive package with Air Canada Vacations®.</p>
              <p>
                <a href="https://store.ama.ab.ca/cards/air-canada"
                   class="invert-hover-link"
                   target="_blank">
                  <svg-icon name="external-link"
                            [styles]="'width: 18px; height: 19px; fill: currentColor;'" />Buy online
                </a>
                today or at your nearest AMA centre!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Westjet-->
    <div class="row ama-card__header mt-3 mb-2 u-ama-bg-color--navy-blue pl-3">
      <h2 class="ama-card__header-title text-uppercase">WESTJET</h2>
    </div>
    <div class="row p-3 pt-lg-0">
      <div class="my-3 row">
        <div class="col-sm-6">
          <img src="https://amatravelinternal.blob.core.windows.net/touroperators/logos/westjet_rgb_2022.png"
               alt="WestJet Logo"
               style="max-width:250px" />
        </div>
      </div>
      <div class="d-flex row flex-wrap cruise-partner">
        <div class="col-12 col-md-8">
          <div>
            <h4 class="h5">ATA Offer - 10% OFF Canada and USA</h4>
            <ul class="pl-4">
              <li>Offer applicable on WestJet flights (not including code-share flights)</li>
              <li>Receive 10% off available regular price Basic, Econo and Premium base fares, not including taxes and fees
              </li>
              <li>Travel by April 30, 2025</li>
              <li>Valid for new bookings made by April 30, 2025</li>
              <li>Identify yourself as an ATA member and request this offer when booking with a travel agent.</li>
              <li>No blackout dates</li>
              <li>Not applicable on sale fares</li>
            </ul>
          </div>
          <ng-container [ngTemplateOutlet]="questions"
                        [ngTemplateOutletContext]="{ availableOnline: false }" />
        </div>
        <div class="col-12 col-md-4"></div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #packages_deal>
  <section>
    <div class="row ama-card__header mt-3 mb-2 u-ama-bg-color--navy-blue pl-3">
      <h2 class="ama-card__header-title text-uppercase">Westjet Vacations</h2>
    </div>
    <div class="row p-3 pt-lg-0">
      <div class="my-3 row">
        <div class="col-sm-6">
          <img src="https://amatravelinternal.blob.core.windows.net/branding/vendors/vendor-westjet.png"
               alt="WestJet Vacation Logo"
               style="max-width:250px" />
        </div>
      </div>
      <div class="d-flex row flex-wrap cruise-partner">
        <div class="col-12 col-md-8">
          <div>
            <h4 class="h5">ATA Offer –$100 WestJet Gift Card</h4>
            <ul class="pl-4">
              <li>Receive a $100 WestJet Gift Card on applicable Package Vacation Bookings</li>
              <li>One Gift Card per booking</li>
              <li>Minimum spend of $2200 CAD base fare per person before taxes and fees, based on double adult occupancy</li>
              <li>Travel anytime if space is available</li>
              <li>Valid for new bookings made between September 1 and April 30, 2025</li>
              <li>No blackout dates</li>
              <li>WestJet Gift Card can be redeemed on the final payment if booked with a travel agent or towards a new
                booking with WestJet Vacations or WestJet Airlines, or towards add-on products (seats/bags) for packages
                booked with full payment on westjet.com/managetrips</li>
              <li>Gift Card is electronic and will be issued and emailed to the first listed traveller at the first listed
                email on the booking</li>
              <li>Identify yourself as an ATA member and request this offer when booking with a travel agent</li>
              <li>All bookings will be tracked by AMA and Gift Card will be delivered via email within 2 weeks of making the
                initial payment</li>
              <li>Not combinable with group fares</li>
            </ul>
          </div>
          <ng-container [ngTemplateOutlet]="questions_cta" />
        </div>
        <div class="col-12 col-md-4"></div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #questions
             let-availableOnline="availableOnline">
  <div class="row pt-4 px-2 mb-3 baby-blue-bg">
    <div class="col-12 col-md-6 col-lg-4 pb-sm-3">
      <div class="ama-questions mt-1">
        <div class="ama-questions__content">
          <div class="ama-questions__icon">
            <svg-icon name="phone"
                      [styles]="'width: 32px; height: 32px; fill: white;'" />
          </div>
          <div class="ama-questions__text">
            Call Toll-Free
          </div>
          <div class="ama-questions__text">
            To Book This Offer
            <div class="ama-questions__text">
              <a class="ama-questions__link"
                 href="tel:18554748753">1-855-474-8753</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-8">
      <p><strong>To book this offer call toll-free <span class="text-nowrap"><a href="tel:18554748753"
               class="invert-hover-link">1-855-474-8753</a></span> or visit your nearest AMA centre. We
          recommend
          booking an appointment in advance.</strong><br>
        <span class="small">{{availableOnline ? "Available to book online." : "Offer is not available to book online currently."}}
          Professional consulting fee applies when booking with a
          travel
          agent. <a (click)="scrollToAnchor($event, deals[deals.length - 1].anchor)"
             href="#{{anchor}}"
             class="invert-hover-link">See details</a>.</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #questions_cta>
  <div class="row px-2 mb-3 baby-blue-bg">
    <div class="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center my-sm-4">
      <a [href]="'coverage.exclusive_saving.cta_url' | translate"
         target="_blank"
         class="btn btn-block btn-secondary text-capitalize cta-btn">{{ 'coverage.exclusive_saving.cta_title' | translate }}</a>
    </div>
    <div class="col-12 col-md-6 col-lg-8 pt-md-4 pb-md-2">
      <p><strong>If you prefer to book with an agent call toll-free <span class="text-nowrap"><a href="tel:18554748753"
               class="invert-hover-link">1-855-474-8753</a></span> or visit your nearest centre. We
          recommend
          booking an appointment in advance.</strong><br>
        <span class="small">
          Professional consulting fee applies when booking with a travel agent.
          <a (click)="scrollToAnchor($event, deals[deals.length - 1].anchor)"
             href="#{{anchor}}"
             class="invert-hover-link">See details</a>.</span>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #professional_consulting>
  <div class="full-grey-bg pb-3">
    <div class="row">
      <div class="col-12 pb-2 pt-2">
        <div class="col-12 mt-3">
          <h3 class="mb-1">EVERYTHING YOU GET WHEN BOOKING WITH AN <span style="white-space:nowrap;">AMA
              TRAVEL AGENT</span></h3>
          <p>If you’re spending thousands of dollars on a trip, you want to know that it’s going to be everything you’ve
            imagined it to be — and maybe even better than you imagined it.
          </p>
          <p>
            That’s the benefit of meeting with an AMA Travel agent. When you book a consultation, you’re getting much, much
            more than just someone arranging your flights or booking your hotel (although that’s certainly part of it).</p>
          <p>
            These sessions are the time to ask every question, get every answer, and to turn what would be a normal vacation
            into a memory you’ll love reliving for the rest of your life.
          </p>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 col-md-6 mt-3 pb-2 pt-2">
        <h3 class="mb-1 col-12">WHAT DOES THE CONSULTATION GET ME?</h3>
        <ul class="checklist lightcheck mt-1">
          <li class="lightcheck"> <strong>Trustworthy Recommendations</strong> - <span class="small">Our travel partners
              treat our AMA family like their own. They aren’t just the best choice for you, they’re some of the best
              choices in the entire industry!</span></li>


          <li class="lightcheck pt-2"><strong>A One-Stop-Shop for All Your Questions</strong> - <span class="small">Our agents
              stay on top of everything our clients need, including health and safety
              rules, country entry requirements, and more. A travel agent can answer your questions before you even
              ask them!</span>
          </li>

          <li class="lightcheck pt-2"><strong>Your Time Back</strong> - <span class="small">Doing all your own
              research and finding that red-eye flight might save a few dollars, but why not let your vacation be a
              vacation? Our travel agents know where to find deals in places you might not even think to look. </span>
          </li>

          <li class="lightcheck pt-2"><strong>Better Deals</strong> - <span class="small">From tours in
              Southeast Asia to the most luxurious cruises around the world, our agents are up-to-date with all our
              partners’ latest offers and the AMA member benefits you can use to get an even sweeter deal.</span>
          </li>

          <li class="lightcheck pt-2"><strong>The Inside Perspective on Your Destination</strong> - <span class="small">Our
              agents have been around the world, trying the best food, finding spectacular iconic
              features, and discovering everything that you simply have to try. And, because they share their
              experiences, consulting with one AMA Travel agent is like having a consultation with all of them!</span>
          </li>

          <li class="lightcheck pt-2"><strong>Travel Insurance Made Easy</strong> - <span class="small">Travel
              with confidence knowing you have the exact coverage you need without paying more than you have to. Our
              travel insurance experts know our insurance products front-to-back and will make it easy to understand.
            </span></li>

          <li class="lightcheck pt-2"><strong>Support Before, During, and After Your Trip</strong> - <span class="small">If
              your hotel loses your booking, or your flight is cancelled for days, booking with AMA
              Travel (even if it’s online) means that you have the backing of a real person back home who knows you
              and what you’re going through and is here to support you through it.
            </span></li>
        </ul>
      </div>
      <div class="col-12 col-md-6 mt-3 pb-2 pt-2">
        <h3 class="mt-0 mb-1">OUR PROFESSIONAL CONSULTING FEE BREAKDOWN</h3>
        <p>We can guarantee that you’ll look back on the cost of consultation and know it was the best money you spent to
          make your trip something amazing. As an AMA member you receive a savings on the professional consulting fee or
          if you prefer, book your trip online and don’t pay a fee at all.<br>

          When booking with an experienced AMA travel agent for car, hotel, cruises, flights, packages/tours, rail,
          ferries and visas the professional consulting fee varies on the product you book. Other conditions may apply. It
          should also be noted that there is no consulting fee when purchasing items such as travel insurance, ski
          tickets, or parks passes.</p>

        <section class="mt-3 mb1">
          <h3>Professional Consulting Fees</h3>
          <div class="c-tabbed-table ">
            <mat-tab-group class="c-tabbed-table__group"
                           #consultingTabGroup>
              <mat-tab label="Car"
                       class="c-tabbed-table__tab">
                <div class="c-tabbed-table__content">
                  <div class="c-tabbed-table__heading-row">
                    <div class="c-tabbed-table__heading-cell c-tabbed-table__heading-cell--spacer col-4">
                      <div class="c-tabbed-table__c-tabbed-table__title">

                      </div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">AMA Member</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">Non Member</div>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-4">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>North America</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$25</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$50</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-4">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>International</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$35</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$70</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__footer">
                    <div class="col-12">
                      <p> Fees apply to each car rental booking. Other terms and conditions may apply. </p>
                    </div>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Hotel"
                       class="c-tabbed-table__tab">
                <div class="c-tabbed-table__content">
                  <div class="c-tabbed-table__heading-row">
                    <div class="c-tabbed-table__heading-cell c-tabbed-table__heading-cell--spacer col-4">
                      <div class="c-tabbed-table__c-tabbed-table__title">
                         
                      </div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">AMA Member</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">Non Member</div>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-4">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>North America</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$25</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$50</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-4">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>International</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$35</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$70</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__footer">
                    <div class="col-md-12">
                      <p> Fees apply to each hotel booking. Other terms and conditions may apply. </p>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Package and Tour"
                       class="c-tabbed-table__tab">
                <div class="c-tabbed-table__content">
                  <div class="c-tabbed-table__heading-row">
                    <div class="c-tabbed-table__heading-cell c-tabbed-table__heading-cell--spacer col-3">
                      <div class="c-tabbed-table__c-tabbed-table__title">
                         
                      </div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">AMA Member</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">AMA Member Family</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">Non Member</div>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-3">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>North America including Sun - Hawaii, Mexico & Caribbean</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$59</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$118</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$99</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-3">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>International </p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$99</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$198</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$149</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__footer">
                    <div class="col-md-12">
                      <p>Fees are per person + GST. Family rate includes 2 spouses and dependent children
                        living at home up to the age of 25. Other terms and conditions may apply. Ask us
                        about our fees for customized itineraries.</p>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Cruise"
                       class="c-tabbed-table__tab">
                <div class="c-tabbed-table__content">
                  <div class="c-tabbed-table__heading-row">
                    <div class="c-tabbed-table__heading-cell c-tabbed-table__heading-cell--spacer col-4">
                      <div class="c-tabbed-table__c-tabbed-table__title">
                         
                      </div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">AMA Member</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-4">
                      <div class="c-tabbed-table__title">Non Member</div>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-4">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>Cruise Counsellor Fees</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$99</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-4">
                      <h3 class="u-text-color-travel-orange">$149</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__footer">
                    <div class="col-md-12">
                      <p> Fees are per person + GST. Family rate of $198 available and includes 2 spouses and
                        dependent children living at home up to the age of 25. Other terms and conditions
                        may apply. Ask us about our fees for customized itineraries. </p>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Flight"
                       class="c-tabbed-table__tab">
                <div class="c-tabbed-table__content">
                  <div class="c-tabbed-table__heading-row">
                    <div class="c-tabbed-table__heading-cell c-tabbed-table__heading-cell--spacer col-3">
                      <div class="c-tabbed-table__c-tabbed-table__title">
                         
                      </div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">AMA Member</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">AMA Member Family</div>
                    </div>
                    <div class="c-tabbed-table__heading-cell col-3">
                      <div class="c-tabbed-table__title">Non Member</div>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-3">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>North America including Sun - Hawaii, Mexico & Caribbean</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$99</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$198</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$149</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__row">
                    <div class="c-tabbed-table__cell col-3">
                      <div class="c-tabbed-table__horizontal-heading">
                        <p>International</p>
                      </div>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$149</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$298</h3>
                    </div>
                    <div class="c-tabbed-table__cell col-3">
                      <h3 class="u-text-color-travel-orange">$199</h3>
                    </div>
                  </div>
                  <div class="c-tabbed-table__footer">
                    <div class="col-12">
                      <p>Fees are per person + GST. Family rate includes 2 spouses and dependent children
                        living at home up to the age of 25. Other terms and conditions may apply. Ask us
                        about our fees for customized itineraries.</p>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>