import { ConnectBase, FormStore } from '@angular-redux-ivy/form';
import { Directive } from '@angular/core';

import { NgForm } from '@angular/forms';

// For template forms (with implicit NgForm)
@Directive({ selector: 'form[connect]:not([formGroup])', exportAs: 'connectDirective' })
export class ConnectDirective extends ConnectBase {
  constructor(protected store: FormStore, protected formGroup: NgForm) {
    super();
  }
}
