<form *injector="{currentQuoteOption: currentQuoteOption$}; 
let currentQuoteOption = currentQuoteOption;
let matchMedia = matchMedia;"
      #f="ngForm"
      connect="travellerInfo"
      (ngSubmit)="onSubmit(f)"
      class="o-traveller"
      id="traveller-info">
  <div class="row">
    <div class="col-12">
      <h2>{{ 'traveller_info.title' | translate }}</h2>
      <p *ngIf="productGroupFlow !== ProductGroup.RentalVehicleDamageFlow">{{ 'traveller_info.prompt' | translate }}</p>
    </div>
    <div class="col-12">
      <div class="o-traveller__info o-traveller__info--primary">
        <span class="o-traveller__info-label">
          <i class="o-traveller__info-count"></i>
          <span>{{ 'traveller_info.primary_traveller_label' | translate }}
            <small *ngIf="productGroupFlow !== ProductGroup.RentalVehicleDamageFlow"
                   class="o-traveller__info-age">({{ 'traveller_info.age_label' | translate }}: {{ primaryTravellerAge }})</small>
          </span>
        </span>
        <i class="o-traveller__info-tooltip">
          <button type="button"
                  class="btn btn-link m-0 p-0"
                  [tooltip]="(productGroupFlow === ProductGroup.RentalVehicleDamageFlow ? 'traveller_info.primary_traveller_tooltip_rvd' : 'traveller_info.primary_traveller_tooltip') | translate"
                  [placement]="matchMedia.lg ? 'top' : 'left'"
                  container="body"
                  tabindex="-1">
            <svg-icon name="question_mark_circle" />
          </button></i>
      </div>
    </div>
  </div>
  <!--<START Primary Traveller Info-->
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <div class="form-group">
        <label class="h6 font-weight-bold mt-3"
               for="primaryFirstName">{{ 'traveller_info.first_name.prompt' | translate }}</label>
        <input type="text"
               id="primaryFirstName"
               class="form-control"
               autocomplete="section-primarytraveller given-name"
               placeholder="{{ 'traveller_info.first_name.place_holder' | translate }}"
               ngModel
               ngControl
               name="firstName"
               #primaryFirstName="ngModel"
               required
               maxlength="150"
               [ngClass]="{ 'is-invalid': (f.submitted) && primaryFirstName.invalid }" />
        <div *ngIf="(f.submitted && primaryFirstName.invalid)"
             class="invalid-feedback">
          <span *ngIf="primaryFirstName.errors?.required">{{ 'traveller_info.first_name.required' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <div class="form-group">
        <label class="h6 font-weight-bold mt-3"
               for="primaryLastName">{{ 'traveller_info.last_name.prompt' | translate }}</label>
        <input type="text"
               id="primaryLastName"
               class="form-control"
               autocomplete="section-primarytraveller family-name"
               placeholder="{{ 'traveller_info.last_name.place_holder' | translate }}"
               ngModel
               ngControl
               name="lastName"
               #primaryLastName="ngModel"
               required
               maxlength="150"
               [ngClass]="{ 'is-invalid': (f.submitted) && primaryLastName.invalid }" />
        <div *ngIf="(f.submitted && primaryLastName.invalid)"
             class="invalid-feedback">
          <span>{{ 'traveller_info.last_name.required' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <div class="form-group">
        <label class="h6 font-weight-bold mt-3"
               for="primaryDateOfBirth">{{ 'traveller_info.date_of_birth.prompt' | translate }}</label>
        <div class="input-group">
          <div class="input-group__overlay-input">
            <button class="form-control border-0"
                    type="button"
                    id="btnPrimaryDateOfBirth"
                    (click)="primaryDateOfBirthIsOpen = !primaryDateOfBirthIsOpen"
                    [attr.aria-expanded]="primaryDateOfBirth.isOpen">
              <svg-icon name="calendar" />
            </button>
          </div>
          <date-input #primaryDateOfBirth="ngModel"
                      [inputClass]="{ 'is-invalid': (f.submitted && primaryDateOfBirth.invalid) }"
                      placeholder="{{ 'traveller_info.date_of_birth.place_holder' | translate }}"
                      [required]="true"
                      ngControl
                      ngModel
                      name="dateOfBirth"
                      [maxDate]="maxDateOfBirth"
                      [autocomplete]="'section-primarytraveller bday'"
                      [isOpen]="primaryDateOfBirthIsOpen"
                      (onHidden)="primaryDateOfBirthIsOpen = false"
                      (onShown)="onShowPicker($event)"
                      [customValidation]="validateAge"
                      [customValidationData]="primaryTravellerAge"
                      [initialAge]="primaryTravellerAge"
                      tabindex="-1" />
          <div *ngIf="(f.submitted && primaryDateOfBirth.invalid)"
               class="invalid-feedback">
            <span *ngIf="primaryDateOfBirth.errors?.required">{{ 'traveller_info.date_of_birth.required' | translate }}</span>
            <div *ngIf="primaryDateOfBirth.value && primaryDateOfBirth.errors?.age"
                 [innerHtml]="'traveller_info.date_of_birth.invalid_age_format' | translate | format:primaryTravellerAge:primaryTravellerActualAge | safeHtml">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6">
      <div class="form-group">
        <label class="h6 font-weight-bold mt-3"
               for="primarySelectGender">{{ 'traveller_info.gender.prompt' | translate }}</label>
        <select id="primarySelectGender"
                ngControl
                ngModel
                autocomplete="section-primarytraveller sex"
                #primaryGender="ngModel"
                name="gender"
                class="form-control col-sm-5 col-md-6"
                required
                [ngClass]="{ 'is-invalid': (f.submitted) && primaryGender.invalid }">
          <option selected></option>
          <option *ngFor="let gender of genderOptions | keyvalue:genderOrder | slice:0:3"
                  [value]="gender.key">{{ gender.value }}</option>
        </select>
        <div *ngIf="(f.submitted && primaryGender.invalid)"
             class="invalid-feedback">
          <span>{{ 'traveller_info.gender.required' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6">
      <div class="form-group">
        <label class="h6 font-weight-bold mt-3"
               for="primaryEmailAddress">{{ 'traveller_info.email_address.prompt' | translate }}</label>
        <input type="text"
               id="primaryEmailAddress"
               class="form-control"
               autocomplete="section-primarytraveller email"
               placeholder="{{ 'traveller_info.email_address.place_holder' | translate }}"
               ngControl
               ngModel
               emailpattern
               name="emailAddress"
               #primaryEmailAddress="ngModel"
               required
               email
               maxlength="150"
               minlength="5"
               [ngClass]="{ 'is-invalid': (f.submitted) && primaryEmailAddress.invalid }" />
        <div *ngIf="f.submitted && primaryEmailAddress.invalid"
             class="invalid-feedback">
          <span *ngIf="primaryEmailAddress.errors?.required">{{ 'traveller_info.email_address.required' | translate }}</span>
          <span *ngIf="primaryEmailAddress.errors?.email">{{ 'traveller_info.email_address.invalid' | translate }}</span>
          <span *ngIf="primaryEmailAddress.errors?.pattern">{{ 'traveller_info.email_address.invalidPattern' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6"
         *ngIf="!isVisitorToCanada">
      <div class="form-group">
        <div class="c-form-label-tooltip">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryPhoneNumber"><span>{{ 'traveller_info.phone_number.prompt' | translate }}</span>
            <i class="c-form-label-tooltip__icon">
              <button type="button"
                      class="btn btn-link m-0 p-0"
                      tabindex="-1"
                      [tooltip]="(productGroupFlow === ProductGroup.RentalVehicleDamageFlow ? 'traveller_info.phone_number.tooltip_rvd' : 'traveller_info.phone_number.tooltip') | translate"
                      [placement]="matchMedia.lg ? 'top' : 'left'"
                      container="body"
                      placement="top"
                      tabindex="-1">
                <svg-icon name="question_mark_circle" />
              </button></i></label>
        </div>

        <input type="text"
               id="primaryPhoneNumber"
               class="form-control"
               autocomplete="section-primarytraveller tel"
               placeholder="{{ 'traveller_info.phone_number.place_holder' | translate }}"
               ngControl
               ngModel
               inputmode="numeric"
               name="phoneNumber"
               #primaryPhoneNumber="ngModel"
               mask="(000) 000-0000"
               minlength="10"
               required
               [ngClass]="{ 'is-invalid': (f.submitted) && primaryPhoneNumber.invalid }" />
        <div *ngIf="(f.submitted && primaryPhoneNumber.invalid)"
             class="invalid-feedback">
          <span *ngIf="primaryPhoneNumber.errors?.required">{{ 'traveller_info.phone_number.required' | translate }}</span>
          <span *ngIf="primaryPhoneNumber.errors?.minlength">{{ 'traveller_info.phone_number.min_length' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <ng-container *ngIf="!isVisitorToCanada">
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryAddressLineOne">{{ 'traveller_info.address.prompt' | translate }}</label>
          <input type="text"
                 id="primaryAddressLineOne"
                 class="form-control"
                 autocomplete="section-primarytraveller address-line1"
                 placeholder="{{ 'traveller_info.address.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="addressLineOne"
                 #primaryAddressLineOne="ngModel"
                 required
                 maxlength="400"
                 [ngClass]="{ 'is-invalid': (f.submitted) && primaryAddressLineOne.invalid }" />
          <div *ngIf="(f.submitted && primaryAddressLineOne.invalid)"
               class="invalid-feedback">
            <span *ngIf="primaryAddressLineOne.errors?.required">{{ 'traveller_info.address.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryCity">{{ 'traveller_info.city.prompt' | translate }}</label>
          <input type="text"
                 id="primaryCity"
                 class="form-control"
                 autocomplete="section-primarytraveller address-level2"
                 placeholder="{{ 'traveller_info.city.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="city"
                 #primaryCity="ngModel"
                 required
                 maxlength="150"
                 [ngClass]="{ 'is-invalid': (f.submitted) && primaryCity.invalid }" />
          <div *ngIf="(f.submitted && primaryCity.invalid)"
               class="invalid-feedback">
            <span *ngIf="primaryCity.errors?.required">{{ 'traveller_info.city.required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryProvinceCode">{{ 'traveller_info.province.prompt' | translate }}</label>
          <select id="primaryProvinceCode"
                  ngControl
                  ngModel
                  autocomplete="section-primarytraveller address-level1"
                  #primaryProvinceCode="ngModel"
                  name="provinceCode"
                  class="form-control"
                  clubSupportedProvinces
                  required
                  maxlength="2"
                  [ngClass]="{ 'is-invalid': (f.submitted) && primaryProvinceCode.invalid }">
            <option selected></option>
            <option *ngFor="let province of clubSupportedProvinces"
                    [value]="province?.code">{{ province?.name }}</option>
          </select>
          <div *ngIf="(f.submitted && primaryProvinceCode.invalid)"
               class="invalid-feedback">
            <span *ngIf="primaryProvinceCode.errors?.required">{{ 'traveller_info.province.required' | translate }}</span>
            <span *ngIf="primaryProvinceCode.errors?.unsupportedProvince">{{ 'traveller_info.province.unsupported' | translate }}</span>
          </div>
        </div>

      </div>

      <div class="col-12 col-sm-3">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryPostalCode">{{ 'traveller_info.postal_code.prompt' | translate }}</label>
          <input type="text"
                 id="primaryPostalCode"
                 class="form-control"
                 autocomplete="section-primarytraveller postal-code"
                 placeholder="{{ 'traveller_info.postal_code.canadian_place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="postalCode"
                 oninput="this.value = this.value.toUpperCase()"
                 #primaryPostalCode="ngModel"
                 required
                 mask="S0S 0S0"
                 minlength="6"
                 [disabled]="isLoading"
                 [ngClass]="{ 'is-invalid': (f.submitted) && primaryPostalCode.invalid }" />
          <div *ngIf="(f.submitted && primaryPostalCode.invalid)"
               class="invalid-feedback">
            <span *ngIf="primaryPostalCode.errors?.required">{{ 'traveller_info.postal_code.required' | translate }}</span>
            <span *ngIf="primaryPostalCode.errors?.minlength">{{ 'traveller_info.postal_code.min_length' | translate }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isVisitorToCanada">

      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="addressLineOne">{{ 'traveller_info.address.prompt' | translate }}</label>
          <input type="text"
                 id="addressLineOne"
                 class="form-control"
                 autocomplete="section-primarytraveller address-line1"
                 placeholder="{{ 'traveller_info.address.place_holder' | translate }}"
                 ngControl
                 ngModel
                 required
                 name="addressLineOne"
                 maxLength="400"
                 #addressLineOne="ngModel"
                 [ngClass]="{ 'is-invalid': (f.submitted) && addressLineOne.invalid }" />
          <div *ngIf="(f.submitted && addressLineOne.invalid)"
               class="invalid-feedback">
            <span *ngIf="addressLineOne.errors?.required">{{ 'traveller_info.address.required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="city">{{ 'traveller_info.city.prompt' | translate }}</label>
          <input type="text"
                 id="city"
                 class="form-control"
                 autocomplete="section-primarytraveller address-level2"
                 placeholder="{{ 'traveller_info.city.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="city"
                 #city="ngModel"
                 required
                 maxlength="150"
                 [ngClass]="{ 'is-invalid': (f.submitted) && city.invalid }" />
          <div *ngIf="(f.submitted && city.invalid)"
               class="invalid-feedback">
            <span *ngIf="city.errors?.required">{{ 'traveller_info.city.required' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="primaryPostalCode">{{ 'traveller_info.postal_code_international.prompt' | translate }}</label>
          <input type="text"
                 id="primaryPostalCode"
                 class="form-control"
                 ngControl
                 ngModel
                 autocomplete="section-primarytraveller postal-code"
                 name="postalCode"
                 maxLength="10"
                 oninput="this.value = this.value.toUpperCase()"
                 #primaryPostalCode="ngModel"
                 [ngClass]="{ 'is-invalid': (f.submitted) && primaryPostalCode.invalid }" />
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="country">{{ 'traveller_info.country.prompt' | translate }}</label>
          <select id="country"
                  ngControl
                  ngModel
                  required
                  autocomplete="section-primarytraveller country"
                  #country="ngModel"
                  name="country"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': (f.submitted) && country.invalid }">
            <option selected
                    value="">{{ 'traveller_info.country.drop_down_placeholder' | translate  }}</option>
            <option *ngFor="let country of countries"
                    [value]="country.code">{{ country.name }}</option>
          </select>
          <div *ngIf="(f.submitted && country.invalid)"
               class="invalid-feedback">
            <span>{{ 'traveller_info.country.required' | translate }}</span>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
  <!--<END Primary Traveller Info-->
  <ng-container *ngIf="isVisitorToCanada">
    <div class="row">
      <div class="col-12">
        <h3 class="mt-4"> {{ 'traveller_info.visiting_address_in_canada.title' | translate }} </h3>
        <p>{{ 'traveller_info.visiting_address_in_canada.subtitle' | translate }} </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="visitingAddress">{{ 'traveller_info.visiting_address_in_canada.address.prompt' | translate }}</label>
          <input type="text"
                 id="visitingAddress"
                 class="form-control"
                 autocomplete="off"
                 placeholder="{{ 'traveller_info.visiting_address_in_canada.address.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="visitingAddress"
                 maxLength="400"
                 #visitingAddress="ngModel"
                 required
                 [ngClass]="{ 'is-invalid': (f.submitted) && visitingAddress.invalid }" />
          <div *ngIf="(f.submitted && visitingAddress.invalid)"
               class="invalid-feedback">
            <span
                  *ngIf="visitingAddress.errors?.required">{{ 'traveller_info.visiting_address_in_canada.address.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="visitingCity">{{ 'traveller_info.visiting_address_in_canada.city.prompt' | translate }}</label>
          <input type="text"
                 id="visitingCity"
                 class="form-control"
                 autocomplete="off"
                 placeholder="{{ 'traveller_info.visiting_address_in_canada.city.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="visitingCity"
                 maxLength="150"
                 #visitingCity="ngModel"
                 required
                 [ngClass]="{ 'is-invalid': (f.submitted) && visitingCity.invalid }" />
          <div *ngIf="(f.submitted && visitingCity.invalid)"
               class="invalid-feedback">
            <span *ngIf="visitingCity.errors?.required">{{ 'traveller_info.visiting_address_in_canada.city.required' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-3">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="visitingPostalCode">{{ 'traveller_info.visiting_address_in_canada.postal_code.prompt' | translate }}</label>
          <input type="text"
                 id="visitingPostalCode"
                 class="form-control"
                 autocomplete="off"
                 placeholder="{{ 'traveller_info.visiting_address_in_canada.postal_code.place_holder' | translate }}"
                 ngControl
                 ngModel
                 name="visitingPostalCode"
                 oninput="this.value = this.value.toUpperCase()"
                 #visitingPostalCode="ngModel"
                 required
                 mask="S0S 0S0"
                 minlength="6"
                 [ngClass]="{ 'is-invalid': (f.submitted) && visitingPostalCode.invalid }" />
          <div *ngIf="(f.submitted && visitingPostalCode.invalid)"
               class="invalid-feedback">
            <span
                  *ngIf="visitingPostalCode.errors?.required">{{ 'traveller_info.visiting_address_in_canada.postal_code.required' | translate }}</span>
            <span
                  *ngIf="visitingPostalCode.errors?.minlength">{{ 'traveller_info.visiting_address_in_canada.postal_code.min_length' | translate }}</span>
          </div>
        </div>

      </div>
      <div class="col-12 col-sm-6 offset-sm-3">
        <div class="form-group">
          <div class="c-form-label-tooltip">
            <label class="h6 font-weight-bold mt-3"
                   for="visitingPhoneNumber"><span>{{ 'traveller_info.visiting_address_in_canada.phone_number.prompt' | translate }}</span>
              <i class="c-form-label-tooltip__icon">
                <button type="button"
                        class="btn btn-link m-0 p-0"
                        tooltip="{{ 'traveller_info.visiting_address_in_canada.phone_number.tooltip' | translate }}"
                        [placement]="matchMedia.lg ? 'left' : 'top'"
                        container="body"
                        placement="top"
                        tabindex="-1">
                  <svg-icon name="question_mark_circle" />
                </button></i></label>
          </div>
          <input type="text"
                 id="visitingPhoneNumber"
                 class="form-control"
                 placeholder="{{ 'traveller_info.visiting_address_in_canada.phone_number.place_holder' | translate }}"
                 ngControl
                 ngModel
                 autocomplete="off"
                 name="visitingPhoneNumber"
                 inputmode="numeric"
                 #visitingPhoneNumber="ngModel"
                 mask="(000) 000-0000"
                 minlength="10"
                 maxLength="50"
                 required
                 [ngClass]="{ 'is-invalid': (f.submitted) && visitingPhoneNumber.invalid }" />
          <div *ngIf="(f.submitted && visitingPhoneNumber.invalid)"
               class="invalid-feedback">
            <span
                  *ngIf="visitingPhoneNumber.errors?.required">{{ 'traveller_info.visiting_address_in_canada.phone_number.required' | translate }}</span>
            <span
                  *ngIf="visitingPhoneNumber.errors?.minlength">{{ 'traveller_info.visiting_address_in_canada.phone_number.min_length' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- AMA Membership-->
  <div class="row"
       *ngIf="currentQuoteOption?.hasMemberDiscount">
    <div class="col-12 col-sm-12 col-md-12">
      <membership-number ngModel
                         ngControl
                         name="membershipNumber"
                         [isLoggedIn]="loggedIn"
                         [showValidation]="f.submitted"
                         [required]="currentQuoteOption?.hasMemberDiscount"
                         [isDisabled]="isLoading"
                         [postalCode]="f.controls['postalCode']?.valid ? f.value.postalCode : '' " />
    </div>
  </div>
  <!-- END AMA Membership-->
  <!-- Start Other Traveller Info -->
  <ng-template connectArrayFix
               #connectArrayFix="connectArrayFix"
               let-index
               connectArrayOf="otherTravellers"
               *ngIf="otherTravellers?.length > 0">
    <div class="row">
      <div class="col-12">
        <div class="o-traveller__info o-traveller__info--other">
          <span class="o-traveller__info-label">
            <i class="o-traveller__info-count"></i>
            <span>{{ 'traveller_info.traveller_label' | translate }} {{ index + 2 }}
              <small class="o-traveller__info-age">({{ 'traveller_info.age_label' | translate }}: {{ otherTravellers[index]?.age }})</small>
            </span>
          </span>
        </div>
        <div class="form-check">
          <input class="form-check-input"
                 id="addressSameAsPrimary{{index}}"
                 type="checkbox"
                 ngControl
                 ngModel
                 [connectArrayFixModel]="connectArrayFix"
                 [connectArrayFixIndex]="index"
                 [value]="true"
                 name="addressSameAsPrimary"
                 #addressSameAsPrimary="ngModel" />
          <label class="form-check-label"
                 for="addressSameAsPrimary{{index}}">{{ 'traveller_info.address_same_as_primary.prompt' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="firstName{{index}}">{{ 'traveller_info.first_name.prompt' | translate }}</label>
          <input type="text"
                 id="firstName{{index}}"
                 class="form-control"
                 autocomplete="off"
                 placeholder="{{ 'traveller_info.first_name.place_holder' | translate }}"
                 ngModel
                 ngControl
                 [connectArrayFixModel]="connectArrayFix"
                 [connectArrayFixIndex]="index"
                 name="firstName"
                 #firstName="ngModel"
                 required
                 maxlength="150"
                 [ngClass]="{ 'is-invalid': (f.submitted) && firstName.invalid }" />
          <div *ngIf="(f.submitted && firstName.invalid)"
               class="invalid-feedback">
            <span *ngIf="firstName.errors?.required">{{ 'traveller_info.first_name.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="lastName{{index}}">{{ 'traveller_info.last_name.prompt' | translate }}</label>
          <input type="text"
                 id="lastName{{index}}"
                 class="form-control"
                 autocomplete="off"
                 placeholder="{{ 'traveller_info.last_name.place_holder' | translate }}"
                 ngModel
                 ngControl
                 [connectArrayFixModel]="connectArrayFix"
                 [connectArrayFixIndex]="index"
                 name="lastName"
                 #lastName="ngModel"
                 required
                 maxlength="150"
                 [ngClass]="{ 'is-invalid': (f.submitted) && lastName.invalid }" />
          <div *ngIf="(f.submitted && lastName.invalid)"
               class="invalid-feedback">
            <span>{{ 'traveller_info.last_name.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="dateOfBirth{{index}}">{{ 'traveller_info.date_of_birth.prompt' | translate }}</label>
          <div class="input-group">
            <div class="input-group__overlay-input">
              <button class="form-control border-0"
                      type="button"
                      id="btnDateOfBirth{{index}}"
                      (click)="otherTravellers[index].datepickerIsOpen = !otherTravellers[index].datepickerIsOpen"
                      [attr.aria-expanded]="dateOfBirth.isOpen">
                <svg-icon name="calendar" />
              </button>
            </div>
            <date-input #dateOfBirth="ngModel"
                        [inputClass]="{ 'is-invalid': (f.submitted && dateOfBirth.invalid) }"
                        placeholder="{{ 'traveller_info.date_of_birth.place_holder' | translate }}"
                        [required]="true"
                        ngControl
                        ngModel
                        [maxDate]="maxDateOfBirth"
                        [autocomplete]="'off'"
                        [connectArrayFixModel]="connectArrayFix"
                        [connectArrayFixIndex]="index"
                        data-id="dateOfBirth{{index}}"
                        name="dateOfBirth"
                        [isOpen]="otherTravellers[index]?.datepickerIsOpen"
                        (onHidden)="otherTravellers[index].datepickerIsOpen = false"
                        (onShown)="onShowPicker($event)"
                        tabindex="-1"
                        [customValidation]="validateAge"
                        [customValidationData]="otherTravellers[index]?.age"
                        [initialAge]="otherTravellers[index]?.age" />
            <div *ngIf="(f.submitted && dateOfBirth.invalid)"
                 class="invalid-feedback">
              <span *ngIf="dateOfBirth.errors?.required">{{ 'traveller_info.date_of_birth.required' | translate }}</span>
              <div *ngIf="dateOfBirth.value && dateOfBirth.errors?.age"
                   [innerHtml]="'traveller_info.date_of_birth.invalid_age_format' | translate | format:otherTravellers[index]?.age:otherTravellers[index]?.actualAge | safeHtml">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <div class="form-group">
          <label class="h6 font-weight-bold mt-3"
                 for="selectGender{{index}}">{{ 'traveller_info.gender.prompt' | translate }}</label>
          <select id="selectGender{{index}}"
                  ngControl
                  ngModel
                  autocomplete="off"
                  [connectArrayFixModel]="connectArrayFix"
                  [connectArrayFixIndex]="index"
                  #selectGender="ngModel"
                  name="gender"
                  class="form-control col-sm-5 col-md-6"
                  required
                  [ngClass]="{ 'is-invalid': (f.submitted) && selectGender.invalid }">
            <option selected></option>
            <option *ngFor="let gender of genderOptions | keyvalue:genderOrder | slice:0:3"
                    [value]="gender.key">{{ gender.value }}</option>
          </select>
          <div *ngIf="(f.submitted && selectGender.invalid)"
               class="invalid-feedback">
            <span>{{ 'traveller_info.gender.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!isVisitorToCanada; then CanadianAddress else ForeignAddress"></div>
      <ng-template #CanadianAddress>
        <ng-container *ngIf="!addressSameAsPrimary.value">
          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="addressLineOne{{index}}">{{ 'traveller_info.address.prompt' | translate }}</label>
              <input type="text"
                     id="addressLineOne{{index}}"
                     class="form-control"
                     autocomplete="off"
                     placeholder="{{ 'traveller_info.address.place_holder' | translate }}"
                     ngControl
                     ngModel
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     name="addressLineOne"
                     #addressLineOne="ngModel"
                     [required]="!addressSameAsPrimary.value"
                     maxlength="400"
                     [class.is-invalid]=" (f.submitted) && addressLineOne.invalid  " />
              <div *ngIf="(f.submitted && addressLineOne.invalid)"
                   class="invalid-feedback">
                <span *ngIf="addressLineOne.errors?.required">{{ 'traveller_info.address.required' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="city{{index}}">{{ 'traveller_info.city.prompt' | translate }}</label>
              <input type="text"
                     id="city{{index}}"
                     class="form-control"
                     ngControl
                     ngModel
                     autocomplete="off"
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     name="city"
                     placeholder="{{ 'traveller_info.city.place_holder' | translate }}"
                     #city="ngModel"
                     [required]="!addressSameAsPrimary.value"
                     maxlength="150"
                     [class.is-invalid]=" (f.submitted) && city.invalid " />
              <div *ngIf="(f.submitted && city.invalid)"
                   class="invalid-feedback">
                <span *ngIf="city.errors?.required">{{ 'traveller_info.city.required' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="provinceCode{{index}}">{{ 'traveller_info.province.prompt' | translate }}</label>
              <select id="provinceCode{{index}}"
                      ngControl
                      ngModel
                      autocomplete="off"
                      [connectArrayFixModel]="connectArrayFix"
                      [connectArrayFixIndex]="index"
                      #provinceCode="ngModel"
                      name="provinceCode"
                      class="form-control"
                      clubSupportedProvinces
                      [required]="!addressSameAsPrimary.value"
                      [class.is-invalid]="(f.submitted) && provinceCode.invalid">
                <option selected></option>
                <option *ngFor="let province of clubSupportedProvinces"
                        [value]="province?.code">{{ province?.name }}</option>
              </select>
              <div *ngIf="(f.submitted && provinceCode.invalid)"
                   class="invalid-feedback">
                <span *ngIf="provinceCode.errors?.required">{{ 'traveller_info.province.required' | translate }}</span>
                <span *ngIf="provinceCode.errors?.unsupportedProvince">{{ 'traveller_info.province.unsupported' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="postalCode{{index}}">{{ 'traveller_info.postal_code.prompt' | translate }}</label>
              <input type="text"
                     id="postalCode{{index}}"
                     class="form-control"
                     ngControl
                     ngModel
                     autocomplete="off"
                     placeholder="{{ 'traveller_info.postal_code.canadian_place_holder' | translate }}"
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     name="postalCode"
                     oninput="this.value = this.value.toUpperCase()"
                     #postalCode="ngModel"
                     [required]="!addressSameAsPrimary.value"
                     [mask]="addressSameAsPrimary.value? '.*' : 'S0S 0S0'"
                     minlength="6"
                     [class.is-invalid]="(f.submitted) && postalCode.invalid" />
              <div *ngIf="(f.submitted && postalCode.invalid)"
                   class="invalid-feedback">
                <span *ngIf="postalCode.errors?.required">{{ 'traveller_info.postal_code.required' | translate }}</span>
                <span *ngIf="postalCode.errors?.minlength">{{ 'traveller_info.postal_code.min_length' | translate }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #ForeignAddress>
        <ng-container *ngIf="!addressSameAsPrimary.value">
          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="addressLineOne{{index}}">{{ 'traveller_info.address.prompt' | translate }}</label>
              <input type="text"
                     id="addressLineOne{{index}}"
                     class="form-control"
                     placeholder="{{ 'traveller_info.address.place_holder' | translate }}"
                     ngControl
                     ngModel
                     autocomplete="off"
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     required
                     name="addressLineOne"
                     maxLength="400"
                     #addressLineOne="ngModel"
                     [ngClass]="{ 'is-invalid': (f.submitted) && addressLineOne.invalid }" />
              <div *ngIf="(f.submitted && addressLineOne.invalid)"
                   class="invalid-feedback">
                <span *ngIf="addressLineOne.errors?.required">{{ 'traveller_info.address.required' | translate }}</span>
              </div>
            </div>

          </div>

          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="city{{index}}">{{ 'traveller_info.city.prompt' | translate }}</label>
              <input type="text"
                     id="city{{index}}"
                     class="form-control"
                     ngControl
                     ngModel
                     autocomplete="off"
                     placeholder="{{ 'traveller_info.city.place_holder' | translate }}"
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     name="city"
                     #city="ngModel"
                     required
                     maxlength="150"
                     [ngClass]="{ 'is-invalid': (f.submitted) && city.invalid }" />
              <div *ngIf="(f.submitted && city.invalid)"
                   class="invalid-feedback">
                <span *ngIf="city.errors?.required">{{ 'traveller_info.city.required' | translate }}</span>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="primaryPostalCode{{index}}">{{ 'traveller_info.postal_code_international.prompt' | translate }}</label>
              <input type="text"
                     id="primaryPostalCode{{index}}"
                     class="form-control"
                     ngControl
                     ngModel
                     autocomplete="off"
                     [connectArrayFixModel]="connectArrayFix"
                     [connectArrayFixIndex]="index"
                     name="postalCode"
                     maxLength="10"
                     oninput="this.value = this.value.toUpperCase()"
                     #primaryPostalCode="ngModel"
                     [ngClass]="{ 'is-invalid': (f.submitted) && primaryPostalCode.invalid }" />
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-6">
            <div class="form-group">
              <label class="h6 font-weight-bold mt-3"
                     for="country{{index}}">{{ 'traveller_info.country.prompt' | translate }}</label>
              <select id="country{{index}}"
                      ngControl
                      ngModel
                      autocomplete="off"
                      [connectArrayFixModel]="connectArrayFix"
                      [connectArrayFixIndex]="index"
                      required
                      #otherTravellerCountry="ngModel"
                      name="country"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': (f.submitted) && otherTravellerCountry.invalid }">
                <option selected
                        value="">{{ 'traveller_info.country.drop_down_placeholder' | translate  }}</option>
                <option *ngFor="let country of countries"
                        [value]="country.code">{{ country.name }}</option>
              </select>
              <div *ngIf="(f.submitted && otherTravellerCountry.invalid)"
                   class="invalid-feedback">
                <span>{{ 'traveller_info.country.required' | translate }}</span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template> <!-- #foreignCountry-->
    </div> <!-- row -->
  </ng-template> <!-- connectArrayFix let-index-->

  <!-- End Other Traveller Info-->
  <div class="row">
    <div class="col-12">
      <div class="form-check mt-3 mb-5">
        <input class="form-check-input"
               id="optInEmail"
               type="checkbox"
               ngControl
               ngModel
               [value]=true
               name="optInEmail" />
        <label class="form-check-label"
               for="optInEmail"
               innerHtml="{{ 'traveller_info.opt_in_email.prompt' | translate }}">
        </label>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-12">
      <div class="btn-group mr-2 d-block d-sm-inline-block"
           role="group">
        <button class="btn btn-secondary btn-lg btn--icon-chevron-right col-12"
                id="traveller-info-submit"
                [ngClass]="{'btn--loading': isLoading}"
                type="submit">
          <span class="px-1">{{ 'traveller_info.submit' | translate }}</span>
        </button>
      </div>
      <save-quote *ngIf="!xsMax && !renew"
                  [disabled]="isLoading" />
    </div>
  </div>
</form>