import { Component } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { InsuranceProduct, ProductGroup, multiTripProductIsSelected, singleTripProductIsSelected } from 'src/app/models/products';
import { IQuoteOption, ITripDetails, ICurrentQuoteDetails } from 'src/app/models/data.interfaces';
import { Router, ActivatedRoute } from '@angular/router';
import { FunnelRoutes } from 'src/app/models/funnel-routes.enum';
import { BaseComponent } from '../base-component.component';
import { map } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'trip-details-summary',
  templateUrl: './trip-details-summary.component.html',
})
export class TripDetailsSummaryComponent extends BaseComponent {
  @select(['tripDetails']) readonly tripDetails$: Observable<ITripDetails>;
  @select(['quoteOptions']) readonly quoteOptions$: Observable<[IQuoteOption]>;
  @select(['currentQuoteDetails']) readonly currentQuoteDetails$: Observable<ICurrentQuoteDetails>;
  readonly quoteOption$: Observable<IQuoteOption>;
  readonly multiTripProductIsSelected$: Observable<boolean>;
  readonly singleTripProductIsSelected$: Observable<boolean>;

  insuranceProduct = InsuranceProduct;
  productGroup = ProductGroup;
  FunnelRoutes = FunnelRoutes;

  dateFormat: string;
  quoteSelected: IQuoteOption;
  readonly activeStep$: Observable<number>;

  renew: boolean;

  constructor(private configService: ClubConfigurationService, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.quoteOption$ = this.currentQuoteDetails$.pipe(map((x) => x && x.quoteOption));
    this.multiTripProductIsSelected$ = this.quoteOption$.pipe(
      map((quoteOption) => {
        return quoteOption && multiTripProductIsSelected(quoteOption.productCode);
      })
    );
    this.singleTripProductIsSelected$ = this.quoteOption$.pipe(
      map((quoteOption) => {
        return quoteOption && singleTripProductIsSelected(quoteOption.productCode);
      })
    );

    this.dateFormat = this.configService.appConfig.i18n.en.calendar_date_display_format || 'MM/dd/yyyy';
    this.activeStep$ = this.activatedRoute.data.pipe(map((data) => data.step));

    this.using(this.activatedRoute.data.subscribe((d) => (this.renew = d.renew))).attach(this);
  }
}
