import { IMedicalQuestionnaire } from './medical-questionnaire.model';

export interface ITravellerAge {
  age?: number;
  hasPreExistingCondition?: boolean;
}
export interface ITravellerInfo {
  id?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  gender?: Gender;
  phoneNumber?: string;
  emailAddress?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  postalCode?: string;
  provinceCode?: string;
  city?: string;
  country?: string;
  membershipNumber?: string;
  referralSource?: string;
  optInEmail?: boolean;
  otherTravellers?: ITraveller[];
  visitingPhoneNumber?: string;
  visitingAddress?: string;
  visitingCity?: string;
  visitingPostalCode?: string;
}

export interface ITraveller {
  id?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  gender?: Gender;
  addressLineOne?: string;
  addressLineTwo?: string;
  postalCode?: string;
  provinceCode?: string;
  city?: string;
  country?: string;
  addressSameAsPrimary?: boolean;
}

export enum Gender {
  Male = 1,
  Female = 2,
  Other = 0,
}
