<div class="o-save-quote">
    <div class="modal-header justify-content-center align-items-center">
        <h3 class="modal-title">{{ 'session_timeout_modal.title' | translate }}</h3>
        <button type="button"
                class="u-close u-close--light-hover"
                data-dismiss="modal"
                aria-label="Close"
                (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-10 m-auto">
                <div class="row my-2">
                    <div class="form-group col-12"
                         [innerHtml]="'session_timeout_modal.message_format' | translate | format:countdown | safeHtml">

                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12">
                        <button type="button"
                                class="col-12 no-gutters btn btn-secondary btn-lg"
                                id="session-timeout-submit"
                                (click)="close()">
                            <span class="px-1">{{ 'session_timeout_modal.primary_button' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>