import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icons.component.html',
})
export class SvgIconsComponent implements OnInit, OnChanges {
  @Input() styles: string;
  @Input() name: string;
  public url: string;
  constructor() {}

  public ngOnInit() {
    this.updateUrl();
  }

  public ngOnChanges() {
    this.updateUrl();
  }

  private updateUrl() {
    // A bug was found where SVG's would not properly render if a hashtag is part of the URL.
    // #_ga=utmsddfsdfsdf
    // Another issue was found where a missing query string would result in the svg not being found.
    this.url = `${window.location.origin}${window.location.pathname}${window.location.search}#${this.name}`;
  }
}
