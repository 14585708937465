import { LocationService } from './locationService';
import { StorageService } from './storageService';
import { TSTService } from './tstService';
import { NgModule } from '@angular/core';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { HttpClientModule } from '@angular/common/http';

/**
 * The Angular 4 module that contains all services.
 */
@NgModule({
  imports: [NgxPageScrollCoreModule.forRoot({ duration: 0 }), HttpClientModule],
  exports: [NgxPageScrollCoreModule],
  providers: [LocationService, StorageService, TSTService],
})
export class ServiceModule {}
