<div *injector="{
  labelFormat: labelFormat$,
  numTrav: numTrav$
};
let labelFormat = labelFormat;
let numTrav = numTrav;
"
     class="c-form-stepper">
  <div class="c-form-stepper__label">
    <span class="c-form-stepper__label-content">{{labelFormat | translate | format:numTrav}}</span>
  </div>
  <div class="c-form-stepper__buttons">
    <span class="c-form-stepper__btn c-form-stepper_btn--minus {{analyticsPrefix}}-add-traveller"
          [ngClass]="{'c-form-stepper_btn--disabled': numTrav <= 1}"
          (click)="incrementNumTrav(-1)"
          analytic-ama="form">
      <svg-icon name="minus-circle" />
    </span>
    <span class="c-form-stepper__btn c-form-stepper_btn--plus {{analyticsPrefix}}-remove-traveller"
          [ngClass]="{'c-form-stepper_btn--disabled': numTrav >= maxNumberOfTravellers}"
          (click)="incrementNumTrav(1)"
          analytic-ama="form">
      <svg-icon name="plus-circle" />
    </span>
  </div>
</div>