import { ExternalLinkParams } from './query-param-mapper.service';
import { IAppState } from 'src/app/models/app-state.model';
import {
  GetAppInitialState,
  GetTravellerAgeInitialState,
  GetTravellerInitialState,
  GetInitialTripDetails,
  GetTravellerAgeInitialRVDState,
} from 'src/app/store/initial-states';
import { ProductGroup, CANADA_COUNTRY, productGrouping, InsuranceProduct } from 'src/app/models/products';
import { addDays, differenceInDays } from 'date-fns';
import { ITravellerAge, ITraveller } from 'src/app/models/traveller.model';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { Injectable } from '@angular/core';
import { CountriesService } from 'src/app/core/services/countries.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalLinkParamsToAppStateMapper {
  readonly maxNumberOfTravellers: number;

  constructor(private readonly clubConfigurationService: ClubConfigurationService, private countriesService: CountriesService) {
    this.maxNumberOfTravellers = this.clubConfigurationService.appConfig.settings.maximum_number_of_travellers;
  }

  public mapExternalLinkToAppState(externalLink: ExternalLinkParams): IAppState {
    const appInitialState: IAppState = { ...GetAppInitialState(), ...{ tripDetails: { ...GetInitialTripDetails(), ...externalLink } } };

    if (!externalLink.productGroupFlow && externalLink.departureDate && externalLink.returnDate) {
      appInitialState.tripDetails.productGroupFlow = ProductGroup.SingleTripFlow;
    }

    if (externalLink.utm_source) {
      appInitialState.travellerInfo.referralSource = externalLink.utm_source;
    }

    if (!externalLink.productGroupFlow && externalLink.policyStartDate && externalLink.durationOfFirstTrip) {
      appInitialState.tripDetails.productGroupFlow = ProductGroup.MultiTripFlow;
    }

    if (appInitialState.tripDetails.productGroupFlow === ProductGroup.VisitorsToCanadaFlow) {
      appInitialState.tripDetails.countrySelected = CANADA_COUNTRY;
    }

    if (!externalLink.numberOfTravellers || externalLink.numberOfTravellers < 1) {
      appInitialState.tripDetails.numberOfTravellers = 1;
    }

    if (externalLink.numberOfTravellers > this.maxNumberOfTravellers) {
      appInitialState.tripDetails.numberOfTravellers = this.maxNumberOfTravellers;
    }

    if (!externalLink.departureDate && !externalLink.returnDate && externalLink.policyStartDate && externalLink.durationOfFirstTrip) {
      appInitialState.tripDetails.departureDate = externalLink.policyStartDate;
      appInitialState.tripDetails.returnDate = addDays(externalLink.policyStartDate, externalLink.durationOfFirstTrip - 1);
    }

    if (!externalLink.policyStartDate && !externalLink.durationOfFirstTrip && externalLink.departureDate && externalLink.returnDate) {
      appInitialState.tripDetails.policyStartDate = externalLink.departureDate;
      appInitialState.tripDetails.durationOfFirstTrip = differenceInDays(externalLink.returnDate, externalLink.departureDate) + 1;
    }

    if (appInitialState.tripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
      // prettier-ignore
      appInitialState.tripDetails.agesOfTravellers =
        new Array<ITravellerAge>(appInitialState.tripDetails.numberOfTravellers)
        .fill(GetTravellerAgeInitialRVDState());
    } else {
      // prettier-ignore
      appInitialState.tripDetails.agesOfTravellers =
      new Array<ITravellerAge>(appInitialState.tripDetails.numberOfTravellers)
      .fill(GetTravellerAgeInitialState());
    }

    // prettier-ignore
    appInitialState.travellerInfo.otherTravellers =
    new Array<ITraveller>(appInitialState.tripDetails.numberOfTravellers - 1)
    .fill(GetTravellerInitialState());

    // By default we want to set the product selected for RVD.
    if (appInitialState.tripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
      appInitialState.tripDetails.productSelected = InsuranceProduct.RentalVehicleDamageInsurance;
    } else {
      appInitialState.tripDetails.productSelected = null;
    }

    // The country selection is only enabled for RVD
    if (appInitialState.tripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow && externalLink.country) {
      const country = this.countriesService.getCountryByCode(externalLink.country);
      if (country) {
        const hasSubRegions = country.subRegions && country.subRegions.length > 0;
        if (hasSubRegions && externalLink.subRegion) {
          // The country has subregions so we need to select one
          const subRegion = this.countriesService.getSubRegionByCode(country, externalLink.subRegion);
          if (subRegion) {
            appInitialState.tripDetails.countrySelected = country;
            appInitialState.tripDetails.subRegionSelected = subRegion;
          }
        } else if (!hasSubRegions) {
          // The country does not have subregions so we can select the country directly.
          appInitialState.tripDetails.countrySelected = country;
        }
      }
    }

    return appInitialState;
  }
}
