<div *injector="{activeStep: activeStep$}; let activeStep = activeStep;"
     class="o-site-layout"
     [ngClass]="{
        'o-site-layout--coverage': activeStep === FunnelRoutes.coverage,
        'o-site-layout--trip-details': activeStep === FunnelRoutes.tripDetails,
        'o-site-layout--review-quote': activeStep === FunnelRoutes.reviewQuote,
        'o-site-layout--traveller-info': activeStep === FunnelRoutes.travellerInfo,
        'o-site-layout--payment': activeStep === FunnelRoutes.payment,
        'o-site-layout--policy-confirmation': activeStep === FunnelRoutes.policyConfirmation,
        'o-site-layout--error-page': (activeStep === FunnelRoutes.pageNotFound) || (activeStep === FunnelRoutes.unexpectedServerError),
        'o-site-layout--load-quote': activeStep === FunnelRoutes.loadQuote
      }"
     checkMobile>
  <site-header />
  <banner *ngIf="appConfigSetting.tenant === TenantEnum.ATA && isHomePage"></banner>
  <main class="o-site-content"
        role="main">
    <wizard />

    <section class="o-site-content__body">
      <router-outlet name="content-main" />
      <router-outlet name="content-sidebar" />
    </section>

    <section class="o-site-content__footer">
      <router-outlet name="content-footer" />
    </section>

  </main>
  <member-join-widget *ngIf="tenant === TenantEnum.ATA && isHomePage"></member-join-widget>
  <site-footer />
</div>
<svg-definitions />