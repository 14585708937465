export class BannerOptions {
  public showTSTWidget: boolean;
  public defaultTSTTab: string;
  public defaultTSTDepartureAirportCode: string;
  public bannerHeight: any;
  public isFeaturedImageFullWidth: boolean;
  public featuredImage: string;
  public fallbackImage: string;
  public fallbackColor: string;
  public featuredImageContent: string;
  public showTagline: boolean;
  public taglineFront: string;
  public taglineBack: string;
  public defaultTSTImageMobile: string;
}
