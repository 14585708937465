import { NgRedux } from '@angular-redux-ivy/store';
import { Component, Input, OnInit } from '@angular/core';
import { Unsubscribe } from 'amaweb-tsutils';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { UserService } from 'src/app/core/services/user.service';
import { IAppState } from 'src/app/models/app-state.model';
import { ICurrentUserInformation, IUserLocation } from 'src/app/models/user.model';
import { BaseComponent, hasValue } from '../../base-component.component';
import { IAppAction, ITravellerInfoAction, SalesFunnelActionType } from 'src/app/store/reducer';
import { ITripDetailsAction } from '../../coverage-type/tripdetails.reducer';
import { AppInsights } from 'applicationinsights-js';
import { ITravellerInfo } from 'src/app/models/traveller.model';
import { merge } from 'lodash-es';
import { ProductGroup } from 'src/app/models/data.interfaces';
import { SelectProvinceModalComponent } from '../../select-province-modal/select-province-modal.component';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';

@Unsubscribe()
@Component({
  selector: 'ata-header',
  templateUrl: './ata-header.component.html',
})
export class AtaHeaderComponent extends BaseComponent implements OnInit {
  @Input() public settings: any;
  public userProfile: ICurrentUserInformation;
  public tenant: string;

  constructor(
    private readonly userService: UserService,
    private readonly store: NgRedux<IAppState>,
    private readonly clubConfigService: ClubConfigurationService,
    private readonly modalService: BsModalService
  ) {
    super();
  }

  ngOnInit() {
    this.tenant = this.clubConfigService.appConfig.settings.tenant;
    this.userProfile = this.userService.currentUser;
    this.patchPrimaryTravellerFromProfile();

    this.using(
      this.userService.currentUserChanged.subscribe((currentUser: ICurrentUserInformation) => {
        this.userProfile = currentUser;

        /*  No auth for ata
        // Setting the authenticated user context helps us with individual user counts in the Azure portal
        if (currentUser.isAuthenticated) {
          // Sanitize the username for app insights purposes.
          const sanitizedUserId = currentUser.userName.replace(/[,;=| ]+/g, '_');
          AppInsights.setAuthenticatedUserContext(sanitizedUserId);
        } else {
          AppInsights.clearAuthenticatedUserContext();
        }
        */
        AppInsights.clearAuthenticatedUserContext();

        this.patchPrimaryTravellerFromProfile();
      })
    ).attach(this);

    // Check the province
    if (this.userService.isUserProvinceSet() === false) {
      this.checkProvince();
    }
  }

  private translateUserProfileToITravellerInfo(): [ITravellerInfo, boolean] {
    const travellerInfo = this.store.getState().travellerInfo;
    let invalidProvinceDetected = false;

    const profile = this.userProfile.profile;
    const location = this.getCurrentLocation();

    if (profile != null) {
      travellerInfo.firstName = travellerInfo.firstName || profile.firstName;
      travellerInfo.lastName = travellerInfo.lastName || profile.lastName;
      travellerInfo.membershipNumber = travellerInfo.membershipNumber || profile.membershipNumber;
      travellerInfo.emailAddress = travellerInfo.emailAddress || profile.email;
      travellerInfo.phoneNumber = travellerInfo.phoneNumber || profile.homePhone;
      travellerInfo.dateOfBirth = travellerInfo.dateOfBirth || (profile.birthDate && DateHelpers.parseDate(profile.birthDate, true));

      if (profile.address != null) {
        const address = profile.address;

        travellerInfo.city = travellerInfo.city || address.city;
        travellerInfo.addressLineOne = travellerInfo.addressLineOne || address.streetAddress;
        travellerInfo.provinceCode = travellerInfo.provinceCode || address.province;
        travellerInfo.postalCode = travellerInfo.postalCode || address.postalCode;
      }
    }

    /* No auth for ata
    // For testing purposes
    if (this.userProfile && this.userProfile.isAuthenticated) {
      try {
        const travellerInfoOverride = JSON.parse(localStorage.getItem('traveller-info-override')) as ITravellerInfo;
        if (hasValue(travellerInfoOverride)) {
          travellerInfo = merge(travellerInfo, travellerInfoOverride);
        }
      } catch (e) {}
    }
    */
    const tripDetails = this.store.getState().tripDetails;

    // if workflow not picked yet, we don't want to prefill traveller info.
    const workflowPicked = tripDetails.productGroupFlow != null || tripDetails.productSelected != null;

    // for visitors to canada flow, we don't want to override user's original from country/province/city
    const isVisitorToCanada = tripDetails && tripDetails.productGroupFlow === ProductGroup.VisitorsToCanadaFlow;
    if (location != null && workflowPicked && !isVisitorToCanada) {
      travellerInfo.city = travellerInfo.city || location.city;
      travellerInfo.country = travellerInfo.country || location.country;
      travellerInfo.provinceCode = travellerInfo.provinceCode || location.provinceCode;
    }

    // Are we dealing with an unsupported province?
    if (travellerInfo.provinceCode && !this.clubConfigService.isProvinceCodeSupported(travellerInfo.provinceCode)) {
      travellerInfo.provinceCode = null;
      invalidProvinceDetected = true;
    }

    return [travellerInfo, invalidProvinceDetected];
  }

  private patchPrimaryTravellerFromProfile() {
    const [travellerInfoState, invalidProvinceDetected] = this.translateUserProfileToITravellerInfo();

    // Show a modal when an invalid province is detected
    if (this.userService.isUserProvinceSet() === false && invalidProvinceDetected) {
      this.showProvinceModal();
    }

    // Update the traveller info state
    this.store.dispatch({
      type: SalesFunnelActionType.UPDATE_PRIMARY_TRAVELLER_FROM_USER_PROFILE,
      travellerInfoState,
    } as ITravellerInfoAction);

    // Try and set the "I am a member" flag when the user is logged in.
    if (this.userProfile && this.userProfile.isAuthenticated) {
      const tripDetailsState = this.store.getState().tripDetails;
      // Only do this if the isClubMember hasn't been filled in yet.
      if (tripDetailsState.isClubMember === null || tripDetailsState.isClubMember === undefined) {
        this.store.dispatch({
          type: SalesFunnelActionType.UPDATE_TRIP_DETAILS,
          tripDetailsState: {
            ...tripDetailsState,
            // Make sure this is a truthy value
            isClubMember: !!(travellerInfoState.membershipNumber && travellerInfoState.membershipNumber.length > 0),
          },
        } as ITripDetailsAction);
      }
    }
  }

  private getCurrentLocation(): IUserLocation {
    // The default location has no information
    let location: IUserLocation = null;

    // Include information from the current user
    if (hasValue(this.userProfile) && hasValue(this.userProfile.location)) {
      location = merge(location, this.userProfile.location);
    }

    // For testing purposes
    try {
      const locationOverride = JSON.parse(localStorage.getItem('location-override')) as IUserLocation;
      if (hasValue(locationOverride)) {
        location = merge(location, locationOverride);
      }
    } catch (e) {}
    return location;
  }

  private checkProvince(): void {
    const location = this.getCurrentLocation();
    // Check if the province is supported
    if (location) {
      const isProvinceSupported = this.clubConfigService.isProvinceCodeSupported(location.provinceCode);
      if (isProvinceSupported) {
        this.userService.setUserDeclaredProvinceCode(location.provinceCode);
        return;
      }
      this.showProvinceModal();
    }
  }

  private showProvinceModal(): void {
    const config: ModalOptions = {
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered',
    };
    this.modalService.show(SelectProvinceModalComponent, config);
  }
  public logout() {
    this.store.dispatch({
      type: SalesFunnelActionType.CLEAR_WHOLE_STATE,
    } as IAppAction);
  }
}
