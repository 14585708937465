import { Component } from '@angular/core';
import { SessionTimeoutService } from './components/session-timeout-modal/session-timeout-modal';

@Component({
  selector: 'osf-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private sessionTimeout: SessionTimeoutService) {
    this.sessionTimeout.watchForIdle();
  }
}
