import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAppState, IMedicalQuestionnaire, InstrumentationEventNames } from '../../models/data.interfaces';
import { QuoteMapper } from './quote-mapper.service';
import { tap } from 'rxjs/operators';
import { AppInsights } from 'applicationinsights-js';

@Injectable({
  providedIn: 'root',
})
export class MedicalQuestionnaireService {
  private readonly baseApiUrl: string;

  constructor(private readonly httpClient: HttpClient, private readonly quoteMapper: QuoteMapper) {
    this.baseApiUrl = `${environment.baseApiUrl}/medicalquestionnaire`;
  }

  private performAction(action: string, saveQuoteData: IAppState, travellerIndex: number): Observable<IMedicalQuestionnaire> {
    const tripDetails = this.quoteMapper.mapIAppStateToServerAppState(saveQuoteData).tripDetails;
    // Remove all other medical questionnaires
    const medQ =
      tripDetails.medicalQuestionnaires && tripDetails.medicalQuestionnaires.find((x) => x.relatesToTravellerIndex === travellerIndex);
    if (medQ) {
      tripDetails.medicalQuestionnaires = [medQ];
    } else {
      // We are loading the travellers medical questionnaire for the first time.
      tripDetails.medicalQuestionnaires = [];
    }
    // Remove all other travellers, this will stop any null age validation checks
    tripDetails.agesOfTravellers = [tripDetails.agesOfTravellers[travellerIndex]];

    // Now perform the request
    return this.httpClient
      .post<IMedicalQuestionnaire>(`${this.baseApiUrl}/${action}`, {
        tripDetails,
        travellerIndex,
      })
      .pipe(
        tap((questionnaire) => {
          if (questionnaire && questionnaire.isCompleted) {
            AppInsights.trackEvent(InstrumentationEventNames.MedicalQuestionnaireCompleted);
          } else {
            AppInsights.trackEvent(InstrumentationEventNames.MedicalQuestionnaireSectionProvided);
          }
        })
      );
  }

  public loadInitial(saveQuoteData: IAppState, travellerIndex: number): Observable<IMedicalQuestionnaire> {
    AppInsights.trackEvent(InstrumentationEventNames.MedicalQuestionnaireStarted);

    return this.performAction('loadInitial', saveQuoteData, travellerIndex);
  }

  public moveToNextSection(saveQuoteData: IAppState, travellerIndex: number): Observable<IMedicalQuestionnaire> {
    return this.performAction('moveToNextSection', saveQuoteData, travellerIndex);
  }

  public moveToPreviousSection(saveQuoteData: IAppState, travellerIndex: number): Observable<IMedicalQuestionnaire> {
    return this.performAction('moveToPreviousSection', saveQuoteData, travellerIndex);
  }
}
