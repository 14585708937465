export enum FunnelRoutes {
  coverage = 0,
  tripDetails = 1,
  reviewQuote = 2,
  travellerInfo = 3,
  payment = 4,
  policyConfirmation = 5,
  pageNotFound = 6,
  unexpectedServerError = 7,
  loadQuote = 8,
  postern = 9,
  /**
   * Used in the renewal flow.
   */
  retrievePolicy = 10,
  /**
   * Used in the renewal flow.
   */
  policyDetails = 11,
}
