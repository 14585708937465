import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { take } from 'rxjs/operators';
import { QuoteService } from 'src/app/core/services/quote.service';
import { Subscription } from 'rxjs';
import { IAppState, IServerLoadQuoteResponse } from 'src/app/models/data.interfaces';
import { NgRedux } from '@angular-redux-ivy/store';
import { ILoadQuoteAction, SalesFunnelActionType, IUpdateQuoteIdAction } from 'src/app/store/reducer';
import { QuoteMapper } from 'src/app/core/services/quote-mapper.service';
import { setQuoteOptions } from 'src/app/store/trip-details-quote-option-retrieval.middleware';
import { updateCurrentQuoteDetails } from 'src/app/store/current-quote-details.reducer';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  templateUrl: './load-quote.component.html',
  selector: 'load-quote',
})
export class LoadQuoteComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly quoteService: QuoteService,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly quoteMapper: QuoteMapper
  ) {}

  ngOnInit(): void {
    const urlToken = this.route.snapshot.queryParamMap.get('token');
    if (urlToken == null || urlToken === '') {
      this.router.navigate(['/not-found']);
    }

    // Dispatch the quote ID into the store as easly as possible so that it can be used as a request header
    // by the http error interceptor when fetching the quote.
    const updateQuoteIdAction: IUpdateQuoteIdAction = {
      type: SalesFunnelActionType.UPDATE_QUOTE_ID,
      payload: urlToken,
    };

    this.reduxStore.dispatch(updateQuoteIdAction);

    this.quoteService
      .getQuote(urlToken)
      .pipe(take(1))
      .subscribe({
        next: (response: IServerLoadQuoteResponse) => {
          if (response != null) {
            // Our implementation of redux is synchronous, the following dispatch
            // events that are fired do not trigger any API calls.
            // Therefore it is safe to assume that one dispatch will finish before the next.
            const appState: IAppState = this.quoteMapper.mapServerStateToIAppState(response.quoteInProgress);
            this.reduxStore.dispatch(this.loadQuote(appState));
            this.reduxStore.dispatch(setQuoteOptions(response.quoteOptions));

            // There should only be one option when coming back from the server
            const selectedOption = response.quoteOptions[0];
            let topUpDays = appState.tripDetails.durationOfFirstTrip - selectedOption.planDays;
            if (topUpDays < 0) {
              topUpDays = 0;
            }
            // The selected option will also have the saved flag set.
            this.reduxStore.dispatch(
              updateCurrentQuoteDetails({
                planDays: selectedOption.planDays,
                topUpDays: topUpDays,
                quoteOption: selectedOption,
                sumInsured: selectedOption.sumInsured,
              })
            );

            // Now go to review quote.
            this.router.navigate(['/review-quote']);
          } else {
            this.router.navigate(['/not-found']);
          }
        },
        error: () => {
          // The quote could not be found.
          this.router.navigate(['/coverage']);
        },
      })
      .attach(this);
  }

  private loadQuote(payload: IAppState): ILoadQuoteAction {
    return {
      type: SalesFunnelActionType.LOAD_QUOTE,
      payload: payload,
    } as ILoadQuoteAction;
  }
}
