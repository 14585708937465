import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PosternService } from 'src/app/core/services/postern.service';

export const PosternQueryParamsKey = 'postern:initialQueryParams';

@Component({
  selector: 'postern',
  template: ``,
})
export class PosternComponent implements OnInit {
  constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly posternService: PosternService) {}

  ngOnInit() {
    const resolved = this.posternService.resolve(this.route.snapshot.queryParams);

    // Save the initial postern data to handle reloading a page.
    try {
      sessionStorage.setItem(PosternQueryParamsKey, JSON.stringify(this.route.snapshot.queryParams));
    } catch (e) {}

    this.posternService.updateStore(resolved);
    const url = resolved.nextUrlTree instanceof Array && resolved.nextUrlTree.length > 0 ? resolved.nextUrlTree[0] : resolved.nextUrlTree;
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
