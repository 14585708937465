import { Component, OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, IServerError } from 'src/app/models/data.interfaces';
import { IServerErrorAction, SalesFunnelActionType } from 'src/app/store/reducer';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  lastServerError: IServerError;

  constructor(private readonly reduxStore: NgRedux<IAppState>, private readonly googleAnalyticsService: GoogleAnalyticsService) {
    googleAnalyticsService.sendDocumentReferrer();
  }

  private clearLastServerError(): IServerErrorAction {
    return {
      type: SalesFunnelActionType.CLEAR_LAST_SERVER_ERROR,
    };
  }

  ngOnInit() {
    this.lastServerError = Object.assign({}, this.reduxStore.getState().lastServerError);

    this.reduxStore.dispatch(this.clearLastServerError());
  }
}
