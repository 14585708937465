/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import {
  Component,
  ViewChild,
  ElementRef,
  TemplateRef,
  Renderer2,
  ViewChildren,
  ViewContainerRef,
  QueryList,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import _ from 'underscore';
import { LocationService } from 'src/app/core/services/locationService';
import isEqual from 'date-fns/is_equal';
import { BaseComponent } from 'src/app/components/base-component.component';

import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
/* import { MatDialog, MatDialogRef } from '@angular/material';
import { MatDatepicker } from '@angular/material/datepicker'; */
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { StorageService } from 'src/app/core/services/storageService';
import {
  TstTabConfig,
  TstConfig,
  TST_WIDGET_LABELS,
  TST_PRODUCT_TYPES,
  TST_DATE_FORMAT,
  TST_THEMES,
  DATEPICKERS,
  TSTDatePicker,
  ICON_INJECTIONS,
  GUEST_INCREMENTORS,
  TST_MAP_FIELDS,
  ALBERTA_AIRPORTS,
  AUTOCOMPLETE_ENDPOINTS,
  TST_AC_DEFAULT_TYPES,
  REGEX,
} from 'src/app/models/tst/tst';

import { BannerOptions } from '../../banner/bannerOptions';
import { format, addDays } from 'date-fns';
import { TSTService } from 'src/app/core/services/tstService';
import { first } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

const TST_EVENT_WIDGET_CREATED = 'tst-widget-created';
const dateFormat = format;

class AdvancedTstTabConfig extends TstTabConfig {
  public load: boolean;
  public widgetObject: ElementRef;
  public tabObject: ElementRef;
  public tstForm: any;
  public datePickers: any[];
  public open;
  public submitButton;
}

export interface IGuestData {
  type: string;
  form: FormGroup;
}

export const TST_ALL_INCLUSIVE_DESTINATIONS = [
  'All South',
  'Antigua and Barbuda',
  'Antigua',
  'All Aruba',
  'Aruba',
  'Bahamas',
  'Great Exuma',
  'Nassau',
  'Barbados',
  'Bridgetown',
  'All Costa Rica',
  'El Jobo',
  'Liberia',
  'Playa Tambor',
  'Puntarenas',
  'Samara',
  'Cuba',
  'Cayo Coco',
  'Cayo Santa Maria',
  'Havana',
  'Holguin',
  'Varadero',
  'All Curacao',
  'Curacao',
  'Dominican Republic',
  'Cabarete',
  'La Romana',
  'Puerto Plata',
  'Punta Cana',
  'Samana',
  'Santo Domingo',
  'Sosua',
  'Kinetta',
  'Jamaica',
  'Montego Bay',
  'Negril',
  'Ocho Rios',
  'Runaway Bay',
  'Witehouse',
  'Mexico',
  'Cancun',
  'Cozumel',
  'huatulco',
  'Ixtapa',
  'Los Cabos',
  'Manzanillo',
  'Mazatlan',
  'Playa Mujeres',
  'Puerto Escondido',
  'Puerto Vallarta',
  'Riviera Maya',
  'Riviera Nayarit',
  'Gamboa',
  'Playa Blanca',
  'Palm Island',
];

@Unsubscribe()
@Component({
  selector: 'tst-search',
  templateUrl: `tstSearch.template.html`,
  styleUrls: ['tstSearch.scss', 'tstWidget.scss'],
})
export class TstSearchComponent extends BaseComponent implements OnChanges, AfterViewInit, OnInit {
  public cmsTab: string;

  /** Timeouts */
  private tryCreateWidgetTimeout: number;
  private orderDepartureCitiesTimeout: number;
  private trySetActiveTabTimeout: number;
  private gtmCarsTimout: number;
  private widgetObjectSetTimeout: number;
  private setActiveTabTimeout: number;
  private prepackageDepartOptionsTimeout: number;
  private prepackageDepartOptionsSetupTimeout: number;
  private autoCompleteUIListTimeout: number;
  private cruiseTravelerOptionsTimeout: number;
  private activityAutoCompleteSetValidityTimeout: number;

  public enableTours: boolean = true;
  public enableActivityAutoFillDestinations: boolean = true;

  /** templateRefs/ViewContainerRefs/ElementRefs */
  @ViewChild('datePickerViewContainer', { read: ViewContainerRef })
  public datePickerViewContainer: ViewContainerRef;
  @ViewChild('datePickerTemplateRef')
  public datePickerTemplateRef: TemplateRef<any>;
  @ViewChildren('datePickersTemplates')
  public datePickersTemplates: QueryList<ElementRef>;
  public datePickerViewRef;

  @ViewChild('guestIncrementorViewContainer', { read: ViewContainerRef })
  public guestIncrementorViewContainer: ViewContainerRef;
  @ViewChild('packagesGuestsDialog')
  public packagesGuestsDialog: TemplateRef<ElementRef>;
  @ViewChildren('incrementorTemplates')
  public incrementorTemplates: QueryList<ElementRef>;
  @ViewChild('packagesGuests')
  public packagesGuests: TemplateRef<ElementRef>;
  public guestIncrementorViewRef;
  @ViewChild('hotelGuests')
  public hotelGuests: TemplateRef<ElementRef>;
  @ViewChild('container')
  public container: ElementRef;
  @ViewChildren('formTabs')
  public formTabs: QueryList<ElementRef>;
  @ViewChild('tabTrack')
  public tabTrack: ElementRef;
  @ViewChildren('forms')
  public forms: QueryList<ElementRef>;

  @ViewChild('vacationsTabOptions')
  public vacationsTabOptions: ElementRef;
  @ViewChild('activitiesTourOptions')
  public activitiesTourOptions: ElementRef;
  @ViewChild('optionsForm')
  public optionsForm: TemplateRef<ElementRef>;
  @ViewChild('dynamicOptions')
  public dynamicOptions: ElementRef;
  @ViewChild('formFieldPrepackaged')
  public formFieldPrepackaged: ElementRef;

  @ViewChild('selectArrowContainer', { read: ViewContainerRef })
  public selectArrowContainer: ViewContainerRef;
  @ViewChild('selectArrow')
  public selectArrow: TemplateRef<any>;
  public arrowViewRef;

  @ViewChild('iconInjectViewContainer', { read: ViewContainerRef })
  public iconInjectViewContainer: ViewContainerRef;
  @ViewChild('injectedIcons')
  public injectedIcons: TemplateRef<ElementRef>;
  public iconViewRef;
  @ViewChild('injectedfaIcons')
  public injectedfaIcons: TemplateRef<ElementRef>;

  @ViewChild('customFormContainer', { read: ViewContainerRef })
  public customFormContainer: ViewContainerRef;
  @ViewChild('customFlightFormTemplateRef')
  public customFlightFormTemplateRef: TemplateRef<ElementRef>;
  public flightCustomFormViewRef;

  @ViewChild('cruiseOptionsContainer', { read: ViewContainerRef })
  public cruiseOptionsContainer: ViewContainerRef;
  @ViewChild('cruiseOptions')
  public cruiseOptions: TemplateRef<ElementRef>;
  public cruiseOptionsViewRef;
  public insertedCruiseOptions;

  @ViewChild('allInclusiveOptionsContainer', { read: ViewContainerRef })
  public allInclusiveOptionsContainer: ViewContainerRef;
  @ViewChild('allInclusiveOptions')
  public allInclusiveOptions: TemplateRef<ElementRef>;
  public allInclusiveOptionsViewRef;
  public insertedAllInclusiveOptions;

  @ViewChild('cruiseAdvancedFieldsContainer', { read: ViewContainerRef })
  public cruiseAdvancedFieldsContainer: ViewContainerRef;
  @ViewChild('cruiseAdvancedFieldsTemplate')
  public cruiseAdvancedFieldsTemplate: TemplateRef<ElementRef>;
  public cruiseAdvancedFieldsViewRef;

  @ViewChild('cruiseMatPanelContainer', { read: ViewContainerRef })
  public cruiseMatPanelContainer: ViewContainerRef;
  @ViewChild('cruiseMatPanel')
  public cruiseMatPanel: TemplateRef<ElementRef>;
  public cruiseMatPanelViewRef;

  @ViewChild('advancedSearchContainer', { read: ViewContainerRef })
  public advancedSearchContainer: ViewContainerRef;
  @ViewChild('advancedSearchLink')
  public advancedSearchLink: TemplateRef<ElementRef>;
  public advancedSearchLinkViewRef;

  @ViewChild('dateRangeDialog') public dateRangeDialog: TemplateRef<ElementRef>;
  @ViewChild('singleDateDialog')
  public singleDateDialog: TemplateRef<ElementRef>;

  /** Inputs/Outputs */
  @Input()
  public enableTmi: boolean = false;

  @Output()
  public currentTab = new EventEmitter<[string, string, boolean, string]>();
  @Output()
  public clickedTab = new EventEmitter<string>();
  @Output()
  public tabClickedStateEmit = new EventEmitter<string>();
  @Output()
  public destinationFieldCleared = new EventEmitter<boolean>();
  @Output()
  public updatedLocation = new EventEmitter<boolean>();
  @Input()
  public tstTypes;
  @Input()
  public selectedLocation;
  @Input()
  public valueTyped;
  @Input() public tabClickedStateUpdate;
  @Input()
  public showBanner: boolean = false;
  @Input()
  public showValueProp: boolean = false;
  @Input()
  public showPromo: boolean = false;
  @Input()
  public theme: string;
  @Input()
  public isBanner: boolean;
  @Input()
  public currentUrl: string;
  @Input()
  public bannerOptions: BannerOptions;
  @Input()
  public locationState: any;
  @Input()
  @Input()
  public defaultTab: string;
  @Input()
  public defaultDepartureAirport: string;
  @Input()
  public defaultDestinationAirport: string;
  @Input()
  public defaultDestinationCity: string;
  public dateFormat;

  /** DatePickers */
  public todaysDate = new Date();
  public theDate = new Date(); // used to set [min] using offset defined this.datePickers within template
  public tomorrow = new Date();
  public dayAfterTomorrow = new Date();
  public tstCutoffDate = new Date(); // max date tst allow for future (1 year as of now)
  public minReturnDate;
  public minDepartDate;
  public datePickers = DATEPICKERS;
  public dateRangeClickCount: number = 0;
  public showDateRangeMsg: boolean = false;
  public datepickerPlacement = { placement: 'bottom-left' };

  /**
   * ngbDate vars (ng-bootstrap date type)
   * For date range picker
   */

  /* public dialogRef: MatDialogRef<TstSearchComponent>; */
  public dates: TSTDatePicker;

  // ids of cruise fields to hide. look for class name of container div or id/name of field to swap these out.
  public cruiseFieldsToHide = [
    { id: 'cruiseDeparturePort', el: null },
    { id: 'cruiseDays', el: null },
    { id: 'cruiseShip', el: null },
    { id: 'cruisePromotion', el: null },
  ];
  /** Misc.*/
  public activeTab;
  public activeForm: AdvancedTstTabConfig;
  public bannerConfig: AdvancedTstTabConfig[];
  public bannerConfigGrouped: AdvancedTstTabConfig[];
  public divRootNodes;
  public dpRootNodes;
  public divRootNodesIncr;
  public showGroupOptions: boolean = false;
  public placedIncrementors: any[];
  public form: any;
  public formVacationOptions: any;
  public activityFormOptions: any;
  public formGuestOptionsPackages: any;
  public formGuestOptionsDynamic: any;
  public formGuestOptionsHotels: any;
  public formRentalGuestOptionsHotels: any;
  public formCruiseOptions: any;
  public mq;
  public searchisAllInclusive: boolean = true;
  public departDate: any;
  public TST_WIDGET_LABELS = TST_WIDGET_LABELS;
  public GUEST_INCREMENTORS = GUEST_INCREMENTORS;
  public icons = ICON_INJECTIONS;
  public airports = ALBERTA_AIRPORTS;
  public tstLoaded: boolean = false;
  public activeTypeState: string = '';
  public mobileActiveForm: any;
  public vacationPackageSelect: any;
  public currentVacationOption: any;
  public currentActivityOption: any;
  public cruiseMatPanelRootNode;
  public oceanSelected: boolean = true;
  public riverSelected: boolean = true;
  public tstConfig: TstConfig;
  public dialogRefFormStepper: any;
  public childAges;
  public iconOutlet;
  public selectedLocationTypes = TST_PRODUCT_TYPES;
  public selectedLocationName: string;
  public locationStatesToReset = ['begin', 'removed', 'bypassed'];
  public liveLocationField;
  public liveAutoCompleteList;
  public liveAutoCompleteLocation;
  public liveAutoCompleteHighlighted;
  public liveAutoCompleteFocus: boolean;
  public liveAutoCompleteDownKeyClicked;
  public disableSubmit: boolean;
  public liveCurrentKey;
  public cachedLocations;
  public chosenTerm;
  public tabClickedState; // keep state of whether tab has been clicked or 'un'-clicked. Also whether tst form initially off open or not
  public mappedFields = TST_MAP_FIELDS;
  public isDatePickerTouchUi: boolean;
  public featuredImage: string;
  public airportCode: any = '';
  public ppkgDestEventCounter: number = 0;
  public addListenerChecks: any;
  public dynamicOptionsValid: boolean = true;
  public tabTitle: string = 'Plan your activities before you travel and discover amazing things to do at your destination.';
  public allInclusiveCheckboxLabel = 'All-Inclusive Only';
  public formOptionValues = {
    activity: [TST_WIDGET_LABELS.ACTIVITY_TAB, 'activity'],
    tour: ['tour', 'tour'],
    flightHotel: [TST_WIDGET_LABELS.DYNAMIC_TAB, 'flight-hotel', 'fh'],
    flightHotelCar: [TST_WIDGET_LABELS.DYNAMIC_TAB, 'flight-hotel-car', 'fhc'],
    flightCar: [TST_WIDGET_LABELS.DYNAMIC_TAB, 'flight-car', 'fc'],
    hotelCar: [TST_WIDGET_LABELS.DYNAMIC_TAB, 'hotel-car', 'hc'],
  };

  constructor(
    private locationService: LocationService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService,
    public tstService: TSTService,
    private renderer: Renderer2,
    private router: Router,
    private storageService: StorageService,
    public clndr: NgbCalendar,
    private dts: TSTDatePicker
  ) {
    super();
    this.todaysDate.setHours(0, 0, 0, 0); // set to start of day
    this.iconOutlet = TST_WIDGET_LABELS.PACKAGE_TAB;
    this.tstService
      .getConfig()
      .pipe(first())
      .subscribe((config) => {
        this.tstConfig = config;
      })
      .attach(this);

    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.updateMQ();
      })
    ).attach(this);
    this.router.events
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.tabClickedState = this.mq.mobile && val.url == '/' ? false : true;
          this.tabClickedStateEmit.emit(this.tabClickedState);

          // #9683 - Dispose/remove auto complete dropdown list elements (Search for #9683 for more changes down)
          $('body > ul.ui-autocomplete').remove();
        }
      })
      .attach(this);
    this.theme = hasValue(this.theme) ? this.theme : TST_THEMES.DARK;
    this.currentVacationOption = TST_WIDGET_LABELS.PACKAGE_TAB;
    this.currentActivityOption = TST_WIDGET_LABELS.ACTIVITY_TAB;
    this.dateFormat = dateFormat;
    /** NgbsDate's */
    this.dates = dts;
    this.dates.dateRngCalendar = clndr;
    /* this.dates.dateRngFromDate = this.dates.dateRngCalendar.getToday(); */
    this.dates.dateRngToday = this.dates.dateRngCalendar.getToday();
    this.dates.dateRngMin = this.dates.dateRngToday;
    this.dates.dateRngMax = this.dates.dateRngCalendar.getNext(this.dates.dateRngCalendar.getToday(), 'd', 330);
    this.dates.fromDateLong = this.tstService.convertNgDateToLongDate(this.dates.dateRngFromDate);
    this.dates.toDateLong = this.tstService.convertNgDateToLongDate(this.dates.dateRngToDate);
    this.dates.msg = '';

    // Datepicker dates */
    this.tomorrow.setDate(this.todaysDate.getDate() + 1);
    this.dayAfterTomorrow.setDate(this.todaysDate.getDate() + 2);
    this.minDepartDate = this.tomorrow;
    this.minReturnDate = this.dayAfterTomorrow;
    this.tstCutoffDate.setDate(this.tstCutoffDate.getDate() + 330);
    _.each(
      this.datePickers,
      (dp: any) => {
        // set placeholders and min depart date
        dp.minDepartDate = addDays(dp.minDepartDate, dp.minDateOffset);
        dp.placeHolder = dateFormat(dp.minDepartDate, TST_DATE_FORMAT);
      },
      this
    );

    this.placedIncrementors = [];
    this.dialogRefFormStepper = { dialogRef: null, open: false };
    this.liveAutoCompleteFocus = false;
    this.addListenerChecks = {};
  }

  private clearDate() {
    this.dates.dateRngFromDate = this.dates.dateRngToDate = null;
    this.dates.startDate = this.dates.fromDateLong = null;
    this.dates.endDate = this.dates.toDateLong = null;
  }

  /** _____________________________ angular-lifecycle functions */

  public ngOnInit() {
    this.tabClickedState = this.mq.mobile ? false : true;
    this.tabClickedStateEmit.emit(this.tabClickedState);
    this.getPage();
    this.updateAirport();
    this.cachedLocations = this.storageService.session.get('AllLocations:CachedResults');
  }

  public ngAfterViewInit() {
    this.tstService
      .getConfig()
      .pipe(first())
      .subscribe((config) => {
        this.bannerConfig = config.tabs.map<AdvancedTstTabConfig>((t) => ({
          ...t,
          load: false,
          widgetObject: null,
          tabObject: null,
          tstForm: null,
          datePickers: null,
          open: false,
          submitButton: null,
        }));
        const type = hasValue(this.cmsTab) ? this.cmsTab : this.getProductType();

        this.activeForm = _.find(this.bannerConfig, (item) => {
          return item.type == type;
        });
        this.activeTab = this.activeForm;

        /** create form elements */
        this.activityFormOptions = new FormGroup({
          formType: new FormControl(this.formOptionValues.activity),
        });

        this.formVacationOptions = new FormGroup({
          formType: new FormControl(this.formOptionValues.flightHotel),
          // allInclusive: new FormControl(true),
        });

        this.formCruiseOptions = new FormGroup({
          ocean: new FormControl(this.oceanSelected),
          river: new FormControl(this.riverSelected),
          oceanHidden: new FormControl(this.oceanSelected),
          riverHidden: new FormControl(this.riverSelected),
        });

        this.formGuestOptionsPackages = new FormGroup({
          numAdults: new FormControl(1),
          numChildren: new FormControl(0),
          numSeniors: new FormControl(0),
        });
        this.formGuestOptionsDynamic = new FormGroup({
          numAdults: new FormControl(1),
          numChildren: new FormControl(0),
          numSeniors: new FormControl(0),
        });
        this.formGuestOptionsHotels = new FormGroup({
          hotelRoomsCount: new FormControl(1),
          hotelAdultsCount: new FormControl(1),
          hotelChildrenCount: new FormControl(0),
        });

        this.formRentalGuestOptionsHotels = new FormGroup({
          vacationRentalsAdultsCount: new FormControl(1),
          vacationRentalsChildrenCount: new FormControl(0),
        });

        /** Group so we can structure template easier */
        this.bannerConfigGrouped = this.groupBannerConfig(this.bannerConfig, 'group');

        this.tstTypes = hasValue(this.tstTypes)
          ? this.tstTypes
          : [
              this.TST_WIDGET_LABELS.PACKAGE_TAB,
              this.TST_WIDGET_LABELS.DYNAMIC_TAB,
              this.TST_WIDGET_LABELS.TOURS_TAB,
              this.TST_WIDGET_LABELS.HOTEL_TAB,
              this.TST_WIDGET_LABELS.VACATION_RENTALS,
              this.TST_WIDGET_LABELS.FLIGHT_TAB,
              this.TST_WIDGET_LABELS.CRUISE_TAB,
              this.TST_WIDGET_LABELS.CAR_TAB,
              this.TST_WIDGET_LABELS.ACTIVITY_TAB,
              // this.TST_WIDGET_LABELS.TMI_TAB,
              // tslint:disable-next-line:indent
            ];

        _.each(
          this.bannerConfig,
          (x) => {
            const io = this.tstTypes.indexOf(x.type);
            x.load = io < 0 ? false : true;
          },
          this
        );

        const ppkg = _.find(this.mappedFields, (field) => {
          // get prepackaged item in mappedFields
          return field.type == TST_WIDGET_LABELS.PACKAGE_TAB;
        });

        this.tryInitWidget();
        this.optionsFormControl();
        this.insertDatePickers();
        this.insertAdvancedCruiseFields();
        this.customForms();
        this.setupAutoFillTriggers(ppkg);
      })
      .attach(this);
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.updateMQ();
    this.tabClickedState = changes.tabClickedStateUpdate ? this.tabClickedStateUpdate : this.tabClickedState;

    if (changes.selectedLocation && changes.selectedLocation.currentValue) {
      this.selectedLocationTypes = _.union(this.selectedLocation.types, this.selectedLocation.suggestedTypes);
      this.selectedLocationName = this.selectedLocation.name;
      // get "going to" field to update its value;
      this.portLocationfieldValueToTST(this.selectedLocation, true);

      if (this.activeForm.type == 'prepackaged' && TST_ALL_INCLUSIVE_DESTINATIONS.includes(this.selectedLocation.name)) {
        const item = _.find(this.bannerConfig, (itm) => {
          return itm.type == 'prepackaged';
        });

        const allInclusiveCheckbox = document.getElementById('tst-search__all-inclusive-checkbox');
        const c = item.tstForm.querySelector('#inclusive');
        const featuresValue = item.tstForm.querySelector('[name=features]');

        if (allInclusiveCheckbox) {
          allInclusiveCheckbox.style.visibility = 'visible';
          c.style.visibility = 'visible';
          c.checked = true;
          featuresValue.value = 'ALL_INCLUSIVE';
        }
      }
    }

    if (
      (this.mq.mobile && this.locationState == 'begin') ||
      (this.mq.mobile && this.locationState == 'removed') ||
      (this.mq.mobile && this.locationState == 'selected')
    ) {
      this.tabClickedState = false;
    }
  }

  /** _____________________________ Setup widget */

  private getPage() {
    // getting current page options from bannerOptions input
    this.cmsTab = this.defaultTab ? this.defaultTab : this.bannerOptions ? this.bannerOptions.defaultTSTTab : null;
    this.featuredImage = this.bannerOptions ? this.bannerOptions.featuredImage : null;
    if (this.mq && this.mq.mobile && this.currentUrl == 'coverage') {
      if (this.activeTab) this.trySetActiveTab(this.activeTab.type);
      else this.cmsTab && this.trySetActiveTab(this.cmsTab);
    } else {
      this.cmsTab && this.trySetActiveTab(this.cmsTab);
    }
  }

  /**
   * initialize the tst widgets
   * called from ngAfterViewInit() && and timeout
   */
  private tryInitWidget() {
    clearTimeout(this.tryCreateWidgetTimeout);
    const TST = window['TST'];
    if (hasValue(TST) && TST.SearchWidget != undefined && hasValue(this.forms) && this.forms.length > 0) {
      TST.staging = {
        config: {
          host: 'albertateachers.tstllc.net',
        },
        onReady: function () {},
      };
      TST.init(TST.config);
      /*
			// TST staging domain ->
			TST.init(TST.staging.config);
			*/

      // Need to run this as it modifies widgetObject and tabObject properties
      _.each(this.bannerConfig, (item) => {
        this.getElementRef(item);
      });

      const itemTypes = _.map(this.bannerConfig, (a) => a.type);
      let hotelWidget; // vacation rentals is in the same fieldset as hotels when receiving from tst api

      $('.tst-loading-zone').on(TST_EVENT_WIDGET_CREATED, {}, (e) => {
        const tstLoadingZone = $('.tst-loading-zone');
        tstLoadingZone.find('section > fieldset').addClass('active');

        $('.tst-search__forms').addClass('tst-loading-zone');
        tstLoadingZone.removeClass('tst-loading-zone');

        _.each(itemTypes, (name) => {
          let widget = $(`.${name}-section`)[0];
          if (name === 'hotel') {
            hotelWidget = widget.cloneNode(true);
          }
          if (name === 'rentals') widget = hotelWidget;
          if (widget != null) $(`.tst-search__form--${name}`)[0].appendChild(widget);
        });

        _.each(this.bannerConfig, (item) => {
          const element = $(`.tst-search__form--${item.type}`)[0];
          this.initWidget(element, item);
        });

        this.tstLoaded = true;
        if (this.mq.mobile && this.currentUrl !== 'coverage') {
          this.autoXScroll();
        }
      });

      // this.zone.runOutsideAngular(() => {
      TST.SearchWidget.create({
        tag: '.tst-loading-zone',
        products: _.filter(itemTypes, (a) => a != 'tmi'),
        productOptions: { tmi: { content: false } },
        tabDirection: 'horizontal',
        flightTimes: true,
        extras: { autoComplete: true, datePicker: true },
      });
    } else {
      this.tryCreateWidgetTimeout = this.usingTimeout(window.setTimeout(() => this.tryInitWidget(), 200));
    }
  }

  /**
   * gets the selector in dom to create each tst form on
   * //called from tryInitWidget()
   */
  private getFormSelector(el: ElementRef) {
    const attribute: any = _.find(el.nativeElement.attributes, (a) => a.name.startsWith('_ngcontent'));
    let classSelector = el.nativeElement.className.replace(/\s/g, '.');
    // if active, have to do it again
    classSelector = classSelector.indexOf('active') > 0 ? classSelector.replace(/\s/g, '.') : classSelector;
    return hasValue(el.nativeElement.className) && hasValue(attribute) ? `[${attribute.name}].` + classSelector : attribute;
  }

  /**
   * gets ElementRef type object form @viewChildren in template for tabs and forms
   * // called from tryInitWidget()
   */
  private getElementRef(item: AdvancedTstTabConfig) {
    clearTimeout(this.widgetObjectSetTimeout);
    const type = item.type;
    const qlf = this.forms.filter((q) => {
      return q.nativeElement.id.replace('form-', '') == type;
    });
    const qlt = this.formTabs.filter((q) => {
      return q.nativeElement.id.replace('tab-', '') == type;
    });

    if (hasValue(qlf[0])) {
      item.widgetObject = qlf[0];
      item.tabObject = qlt[0];
    } else {
      this.widgetObjectSetTimeout = this.usingTimeout(window.setTimeout(() => this.getElementRef(item), 200));
    }
    return item;
  }

  /**
   * when available get currect tab (based on cms page data or what tab was clicked).
   * called from constructor, tabClicked, ngOnChanges, vacationButtonHander
   */
  private trySetActiveTab(label: any = null, callback: () => void = null) {
    clearTimeout(this.trySetActiveTabTimeout);
    const product = label ? label : this.getProductType();
    // normalize prepackaged name from cms
    // product =
    // 	product == 'package' ? this.TST_WIDGET_LABELS.PACKAGE_TAB : product;
    // //last selected in vacations tab...
    // product =
    // 	this.currentVacationOption &&
    // 	product == this.TST_WIDGET_LABELS.DYNAMIC_TAB
    // 		? this.currentVacationOption
    // 		: product;
    if (this.formTabs) {
      this.setActiveForm(product);
      this.setDefaultDestination(product);
      if (callback) callback();
    } else {
      this.trySetActiveTabTimeout = this.usingTimeout(window.setTimeout(() => this.trySetActiveTab(product, callback), 200));
    }
  }

  private setDefaultDestination(label: string) {
    const a = this.mappedFields.find((x) => x.type === label);
    let targetField;

    switch (label) {
      case TST_WIDGET_LABELS.PACKAGE_TAB:
        if (a.type === TST_WIDGET_LABELS.PACKAGE_TAB) {
          targetField = a.form
            ? a.form.querySelector(
                '[name=' + a.fields.destination.control.name + ']' // tslint:disable-next-line:indent
              )
            : null;

          if (targetField && hasValue(this.defaultDestinationCity)) {
            targetField.value = this.defaultDestinationCity.includes(',')
              ? this.defaultDestinationCity.split(',')[0].trim()
              : this.defaultDestinationCity;
          }
        }
        break;
      case TST_WIDGET_LABELS.DYNAMIC_TAB:
        if (a.type === TST_WIDGET_LABELS.DYNAMIC_TAB) {
          targetField = a.form
            ? a.form.querySelector(
                '[name=' + a.fields.destination.control.name + ']' // tslint:disable-next-line:indent
              )
            : null;
          if (targetField && hasValue(this.defaultDepartureAirport)) {
            targetField.value = this.defaultDepartureAirport;
          }
        }
        break;
      case TST_WIDGET_LABELS.HOTEL_TAB:
      case TST_WIDGET_LABELS.VACATION_RENTALS:
        if (a.type === TST_WIDGET_LABELS.HOTEL_TAB || a.type === TST_WIDGET_LABELS.VACATION_RENTALS) {
          targetField = a.form
            ? a.form.querySelector(
                '[name=' + a.fields.depart.control.name + ']' // tslint:disable-next-line:indent
              )
            : null;
          if (targetField && hasValue(this.defaultDestinationCity)) {
            targetField.value = this.defaultDestinationCity;
          }
        }
        break;
      case TST_WIDGET_LABELS.CAR_TAB:
        if (a.type === TST_WIDGET_LABELS.CAR_TAB) {
          const pickUpLocation = a.form
            ? a.form.querySelector(
                '[name=' + a.fields.depart.control.name + ']' // tslint:disable-next-line:indent
              )
            : null;
          const dropOffLocation = a.form
            ? a.form.querySelector(
                '[name=' + a.fields.destination.control.name + ']' // tslint:disable-next-line:indent
              )
            : null;
          if (pickUpLocation && dropOffLocation && hasValue(this.defaultDestinationAirport)) {
            pickUpLocation.value = this.defaultDestinationAirport;
            dropOffLocation.value = this.defaultDestinationAirport;
          }
        }
        break;
      case TST_WIDGET_LABELS.ACTIVITY_TAB:
        if (a.type === TST_WIDGET_LABELS.ACTIVITY_TAB) {
          targetField = a.form ? a.form.querySelector('input[type="text"]') : null;
          if (hasValue(this.defaultDestinationCity) && targetField)
            targetField.value = this.defaultDestinationCity.includes(',')
              ? this.defaultDestinationCity.split(',')[0].trim()
              : this.defaultDestinationCity;
        }
        break;
    }
  }

  /* set the active form
   * called from trySetActiveTab()
   */
  private setActiveForm(label) {
    clearTimeout(this.setActiveTabTimeout);

    if (label == 'activity') {
      this.activeForm = _.find(this.bannerConfig, (item) => {
        if (this.activityFormOptions.value.formType[0] == 'activity') {
          return item.type == 'activity';
        } else {
          return item.type == 'tour';
        }
      });
    } else {
      this.activeForm = _.find(this.bannerConfig, (item) => {
        return item.type == label;
      });
    }

    if (
      hasValue(this.activeForm) &&
      hasValue(this.activeForm.widgetObject) // &&
    ) {
      this.setActiveTab();
      this.showGroupOptions = hasValue(this.vacationsTabOptions)
        ? this.vacationsTabOptions.nativeElement.parentElement === this.activeForm.widgetObject.nativeElement.parentElement
        : hasValue(this.activitiesTourOptions)
        ? this.activitiesTourOptions.nativeElement.parentElement === this.activeForm.widgetObject.nativeElement.parentElement
        : hasValue(this.dynamicOptions)
        ? this.dynamicOptions.nativeElement.parentElement === this.activeForm.widgetObject.nativeElement.parentElement
        : false;
    } else {
      this.setActiveTabTimeout = this.usingTimeout(window.setTimeout(() => this.setActiveForm(label), 200));
    }
  }

  private setActiveTab() {
    this.clearDate();

    // if activeTab is grouped, match group with type in bannerConfig
    if (this.activeForm.group) {
      this.activeTab = _.find(this.bannerConfig, (item) => {
        return item.type == this.activeForm.group;
      });
    } else {
      this.activeTab = this.activeForm;
    }
    if (hasValue(this.activeTab)) {
      this.activeTypeState = this.activeTab.type;
      this.determineMobileForm();
      this.currentTab.emit([
        this.activeTab,
        (this.defaultTab ? this.defaultTab : this.bannerOptions && this.bannerOptions.defaultTSTTab) ||
          this.cmsTab ||
          this.getProductType(),
        false,
        this.featuredImage,
      ]);
    }
  }

  private determineMobileForm() {
    // current mobile form to display differs from desktop due
    // different states the use might choose
    if (this.tabClickedState) {
      if (this.activeTypeState == TST_WIDGET_LABELS.PACKAGE_TAB) {
        this.mobileActiveForm = this.currentVacationOption;
      } else {
        this.mobileActiveForm = this.activeForm.type;
      }
    } else {
      this.mobileActiveForm = '';
    }
  }

  /**
   * The product type is determine the current url in product search result
   * otherwise from cms
   * //called from trySetActiveTab()
   */
  private getProductType() {
    const url = this.locationService.path();
    const urlSections = url.split('/');

    // if url is at /products-search-result/, set selected tab to this value
    if (url.indexOf('/products-search-result/') > -1) {
      // Helper for checking if a pattern is present in the url
      const contains = (...patterns) => _.some(patterns, (p) => urlSections[2].indexOf(p) > -1);
      if (contains('flights')) return this.TST_WIDGET_LABELS.FLIGHT_TAB;
      if (contains('hotels')) return this.TST_WIDGET_LABELS.HOTEL_TAB;
      if (contains('rentals')) return this.TST_WIDGET_LABELS.VACATION_RENTALS;
      if (contains('packages')) return this.TST_WIDGET_LABELS.PACKAGE_TAB;
      if (contains('car-rentals', 'cars')) return this.TST_WIDGET_LABELS.CAR_TAB;
      if (contains('activities')) return this.TST_WIDGET_LABELS.ACTIVITY_TAB;
      if (contains('cruises', 'suppliers')) return this.TST_WIDGET_LABELS.CRUISE_TAB;
    }
    const product = (this.bannerOptions && this.bannerOptions.defaultTSTTab) || this.cmsTab;
    return hasValue(product) ? product : this.TST_WIDGET_LABELS.FLIGHT_TAB;
  }

  private groupBannerConfig(bc, key) {
    const list = [];
    const grouped = bc.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    _.each(grouped, (i) => {
      list.push(i);
    });
    return list;
  }

  public isInGroup(item) {
    /** determine if selected location types match a grouped tab form
     * i.e if vacation or tour show vacations tab */
    const children = item.tabChildren ? item.tabChildren.split(',') : [];
    const matches = children.length && _.intersection(children, this.selectedLocationTypes);
    return matches.length ? true : false;
  }

  public groupMatches(item) {
    /** same as isInGroup() but returns item object rather than true or false */
    const children = item.tabChildren ? item.tabChildren.split(',') : [];
    const matches = children.length && _.intersection(children, this.selectedLocationTypes);
    return matches.length ? matches : null;
  }

  /**
   * Will be called after each widget type is created by TST api.
   * called from tryInitWidget()
   */
  private initWidget(target: any, item: AdvancedTstTabConfig) {
    if (item.type === 'rentals') {
      item.tstForm = item.widgetObject.nativeElement.querySelector(
        TST_MAP_FIELDS.find((x) => x.type === TST_WIDGET_LABELS.VACATION_RENTALS).formSelector
      );
    } else {
      item.tstForm = item.widgetObject.nativeElement.querySelector('form');
    }

    /**
     * TST_MAP_FIELDS holds all the form info what TST creates in Dom
     * and our custom config.
     */

    const _form = item.tstForm
      ? _.find(TST_MAP_FIELDS, (field: any) => {
          return field.type == item.type; //  tslint:disable-next-line:indent
        })
      : null;
    if (_form) {
      _form.form = item.tstForm;
      this.setDefaultDestination(item.type);
      item.submitButton = _form.form.querySelector("button[type='submit']");
    }

    // get datepicker view ref ready
    if (hasValue(this.datePickerViewRef) && !hasValue(this.dpRootNodes)) {
      // filter out results that are injected datepickers nodes from datepickers viewref
      this.dpRootNodes = this.datePickerViewRef.rootNodes;
      if (this.dpRootNodes.length) {
        this.divRootNodes = this.tstService.getRootNodesFromView(this.dpRootNodes, 'classList', 'datePickerNode', 'array');
      }
    }

    if (hasValue(_form)) {
      /** replace submit button label */
      const btn = item.tstForm.querySelector('button.btn');
      if (btn) btn.innerText = 'Search Deals';
      /** add angular-material datepickers */
      if (!hasValue(item.datePickers)) {
        item.datePickers = this.tstService.getRootNodesFromView(this.datePickers, 'tstType', item.type);

        if (item.datePickers.length) {
          _.each(
            item.datePickers,
            (dp) => {
              this.replaceDatePicker(dp, item, this.divRootNodes);
            },
            this
          );
        }
      }

      /**
       * for some tst types,
       * do some custom DOM manipulation */

      /**
       * inject prepackaged, dynamic tab, hotel incrementors (guest/traveller counters) (ng component -> formstepper)
       */
      let targetSelector;
      let context;
      let removeSelectors = [];

      if (item.type == this.TST_WIDGET_LABELS.PACKAGE_TAB) {
        if (this.mq.mobile) {
          this.allInclusiveCheckboxLabel = 'Search All-Inclusive Deals Only';
        }

        GUEST_INCREMENTORS[item.type].$implicit.form = this.formGuestOptionsPackages;

        // get incrementor view ref ready for all form items
        const vr = this.insertIncrementor(this.allInclusiveOptions, context);
        const rn =
          hasValue(vr) &&
          hasValue(vr.rootNodes) &&
          this.tstService.getRootNodesFromView(vr.rootNodes, 'classList', 'tst-search__select-all-inclusive-checkbox', 'array');

        const div = item.tstForm.querySelector('.children-ages');
        const _placed = hasValue(div) && this.tstService.placeRootNodes(rn, div, 'insertAdjacentElement', 'afterend');
        //  _placed &&
        //  	this.placedIncrementors.push({ element: _placed, name: item.type });

        // place hidden fields
        const hiddenField = _placed.querySelector('.tst-search__all-inclusive-checkbox-hidden');

        const allInclusiveCheckbox = document.getElementById('tst-search__all-inclusive-checkbox');

        if (allInclusiveCheckbox) allInclusiveCheckbox.style.visibility = 'hidden';

        const d = item.tstForm.querySelector('.tst-prepackaged-arrival');
        const c = item.tstForm.querySelector('#inclusive');
        const featuresValue = item.tstForm.querySelector('[name=features]');

        d.addEventListener('change', function () {
          if (TST_ALL_INCLUSIVE_DESTINATIONS.includes(this.value)) {
            // Show the all inclusive textbox
            allInclusiveCheckbox.style.visibility = 'visible';
            c.style.visibility = 'visible';
            c.checked = true;
            featuresValue.value = 'ALL_INCLUSIVE';
          } else {
            allInclusiveCheckbox.style.visibility = 'hidden';
            c.style.visibility = 'hidden';
            c.checked = false;
            featuresValue.value = '';
          }
        });

        c.addEventListener('change', function () {
          if (c.checked) {
            featuresValue.value = 'ALL_INCLUSIVE';
          } else {
            featuresValue.value = '';
          }
        });
      }
      if (item.type == this.TST_WIDGET_LABELS.DYNAMIC_TAB) {
        // replace numAdults,numChildren with incrementor
        GUEST_INCREMENTORS[item.type].$implicit.form = this.formGuestOptionsDynamic;
      }

      if (item.type == this.TST_WIDGET_LABELS.VACATION_RENTALS || item.type == this.TST_WIDGET_LABELS.HOTEL_TAB) {
        /*
				tst groups hotel and rentals <forms>'s adjacent to each other under same tab. therefore having 2 seperate tabs causes
				duplicate form id's, so need to remove the other one depending which tab you are on
				*/
        $(item.tstForm).show(); // vrentals form hidden by tst by default

        if (item.type === this.TST_WIDGET_LABELS.VACATION_RENTALS) {
          const hotelForm = item.tstForm
            ? item.tstForm.parentNode.querySelector(TST_MAP_FIELDS.find((x) => x.type === TST_WIDGET_LABELS.HOTEL_TAB).formSelector)
            : null;
          if (hotelForm) hotelForm.parentElement.removeChild(hotelForm);
        } else if (item.type === this.TST_WIDGET_LABELS.HOTEL_TAB) {
          const rentalForm = item.tstForm
            ? item.tstForm.parentNode.querySelector(TST_MAP_FIELDS.find((x) => x.type === TST_WIDGET_LABELS.VACATION_RENTALS).formSelector)
            : null;
          if (rentalForm) rentalForm.parentElement.removeChild(rentalForm);
        }
      }

      if (item.type == this.TST_WIDGET_LABELS.HOTEL_TAB) {
        // replace numAdults,numChildren with incrementor
        GUEST_INCREMENTORS[item.type].$implicit.form = this.formGuestOptionsHotels;
      }
      if (item.type == this.TST_WIDGET_LABELS.VACATION_RENTALS) {
        // replace numAdults,numChildren with incrementor
        GUEST_INCREMENTORS[item.type].$implicit.form = this.formRentalGuestOptionsHotels;

        //  hidden field to send to vacation rentals tstllc tab on submit
        const hiddenNode = document.createElement('input');
        hiddenNode.setAttribute('type', 'hidden');
        hiddenNode.setAttribute('name', 'vacaHome');
        hiddenNode.setAttribute('id', 'vacaHome');
        hiddenNode.setAttribute('value', 'true');
        item.tstForm.appendChild(hiddenNode);

        // inject advanced search link
        this.insertAdvancedSearch('vacationrental');
        if (hasValue(this.advancedSearchLinkViewRef) && this.advancedSearchLinkViewRef.rootNodes.length) {
          const advSearchRootNode: any = this.tstService.getRootNodesFromView(
            this.advancedSearchLinkViewRef.rootNodes,
            'classList',
            'tst-form__advanced-search-link',
            'array'
          );

          this.tstService.placeRootNodes(advSearchRootNode, item.tstForm, 'insertAdjacentElement', 'afterend');
        }
      }
      if (
        item.type == this.TST_WIDGET_LABELS.PACKAGE_TAB ||
        item.type == this.TST_WIDGET_LABELS.DYNAMIC_TAB ||
        item.type == this.TST_WIDGET_LABELS.HOTEL_TAB ||
        item.type == this.TST_WIDGET_LABELS.VACATION_RENTALS
      ) {
        context = GUEST_INCREMENTORS[item.type];
        targetSelector = GUEST_INCREMENTORS[item.type].$implicit.targetSelector;
        removeSelectors = GUEST_INCREMENTORS[item.type].$implicit.removeSelectors;
        _.each(GUEST_INCREMENTORS[item.type].$implicit.include, (inc: any) => {
          inc.tstFieldForm = item.tstForm;
        });
        // get incrementor view ref ready for all form items
        const vr = this.insertIncrementor(this.packagesGuests, context);
        const rn =
          hasValue(vr) &&
          hasValue(vr.rootNodes) &&
          this.tstService.getRootNodesFromView(vr.rootNodes, 'classList', 'tst-search__select-incrementor', 'array');

        const travellers = item.tstForm.querySelector(targetSelector);
        const _placed = hasValue(travellers) && this.tstService.placeRootNodes(rn, travellers, 'insertAdjacentElement', 'afterend');
        _placed && this.placedIncrementors.push({ element: _placed, name: item.type });
        removeSelectors.length &&
          _.each(removeSelectors, (sel) => {
            const e = item.tstForm.querySelector(sel);
            e.parentElement.removeChild(e);
          });
        // place hidden fields
        const hiddenField = _placed.querySelector('.tst-search__incrementor-hidden');

        if (context.$implicit.include.length) {
          _.each(
            context.$implicit.include,
            (inc: any) => {
              if (inc.tstField) {
                const node = document.createElement('input');
                const val = inc.control.value ? inc.control.value : inc.defaultVal !== null ? inc.defaultVal : 0;
                node.setAttribute('type', 'hidden');
                node.setAttribute('name', inc.tstField);
                node.setAttribute('value', val);
                (hiddenField && hiddenField.appendChild(node)) || _placed.appendChild(node);
              }
            },
            this
          );
        }
      }

      // hotel tab
      if (item.type == this.TST_WIDGET_LABELS.HOTEL_TAB || item.type == this.TST_WIDGET_LABELS.VACATION_RENTALS) {
        /** injectedIcons */
        this.injectedIconsConfig(this.icons.hotel, item.tstForm);

        /** Currently, in prod also, number of children don't
         * get carried through if its less than 2 using current action url,
         * https:// albertateachers.tstllc.net/results/hotel seems to fix the issue
         */
        _form.form && _form.action && _form.form.setAttribute('action', _form.action);
      }

      // prepackaged tab
      if (item.type == this.TST_WIDGET_LABELS.PACKAGE_TAB) {
        /**
         * injectedIcons */
        this.injectedIconsConfig(this.icons.prepackaged, item.tstForm);

        // change some text
        const travelersRoom = item.tstForm.querySelector('.travelers-room label');
        if (hasValue(travelersRoom)) travelersRoom.innerText = 'Occupancy';

        // order cities, departure date label
        this.orderDepartureCities(target);
        const departureDateLabel = item.tstForm.querySelector('.tst-search__date-picker .tst-search__control-label');
        if (hasValue(departureDateLabel)) departureDateLabel.innerText = 'Leave Date';
      }

      // dynamic packaging
      if (item.type == this.TST_WIDGET_LABELS.DYNAMIC_TAB) {
        // placeholders for dynamic
        if (hasValue(item.tstForm)) {
          const _locations = item.tstForm.querySelectorAll('.pickup-location');
          if (hasValue(_locations && hasValue(_locations[0]))) {
            const _input = _locations[0].querySelector('input');
            if (hasValue(_input)) _input.setAttribute('placeholder', 'start typing...');
          }
          if (hasValue(_locations && hasValue(_locations[1]))) {
            const _input = _locations[1].querySelector('input');
            if (hasValue(_input)) {
              _input.setAttribute('placeholder', 'start typing...');
              if (hasValue(this.defaultDestinationAirport)) _input.value = this.defaultDestinationAirport;
            }
          }
        }
        /** injectedIcons */
        this.injectedIconsConfig(this.icons.dynamic, item.tstForm);

        /** filter out non-aiport autocomplete results */
        _form && this.removeNonAirportACResults();

        /** prevent form submission if st input is blank otherwise will fail in TST funnel */
        const callSetDynamicOptionValidation = (e, b) => {
          this.setDynamicOptionValidation(e, b);
        };
        _form &&
          _form.form.addEventListener('submit', function (e) {
            if (!_form.form['st'].value) {
              callSetDynamicOptionValidation(e, false);
            } else {
              callSetDynamicOptionValidation(e, true);
            }
          });
      }
      // tours tab
      if (item.type == this.TST_WIDGET_LABELS.TOURS_TAB) {
        /** injectedIcons */
        this.injectedIconsConfig(this.icons.tour, item.tstForm);
        const durationField = item.tstForm.querySelector('.duration');
        const keywordField = item.tstForm.querySelector('.keyword');
        //  Get rid of duration field
        if (durationField) durationField.style.display = 'none';
        if (keywordField) keywordField.style.display = 'none';
      }

      // flights tab
      if (item.type == this.TST_WIDGET_LABELS.FLIGHT_TAB) {
        const flightFieldToReplace = item.widgetObject.nativeElement.querySelector('.flight-section');
        if (hasValue(flightFieldToReplace)) {
          this.renderer.removeChild(item.widgetObject.nativeElement, flightFieldToReplace);
        }

        const customFlightRootNodes = this.flightCustomFormViewRef.rootNodes;
        const widgetObject: any = item.widgetObject;

        if (customFlightRootNodes.length && !hasValue(widgetObject.children)) {
          const customFlightRootNode: any = this.tstService.getRootNodesFromView(customFlightRootNodes, 'tagName', 'TST-CUSTOM-FORM');

          hasValue(customFlightRootNode)
            ? item.widgetObject.nativeElement.appendChild(
                customFlightRootNode[0]
                //  tslint:disable-next-line:indent
              )
            : null;

          const destinationField = item.widgetObject.nativeElement.querySelector('input[name="toCity"]');
          if (hasValue(this.defaultDestinationAirport) && destinationField) destinationField.value = this.defaultDestinationAirport;
        }
      }
      // cars tab
      if (item.type == this.TST_WIDGET_LABELS.CAR_TAB) {
        /** injectedIcons */
        this.injectedIconsConfig(this.icons.car, item.tstForm);
        this.matchMediaService
          .onChange()
          .subscribe(() => {
            this.moveCarPickupTime(this.matchMediaService.matches(this.matchMediaService.MEDIA_TYPE.xs_max));
          })
          .attach(this);

        this.moveCarPickupTime(this.matchMediaService.matches(this.matchMediaService.MEDIA_TYPE.xs_max));

        const picker = _.find(this.datePickers, (a) => a.tstType == TST_WIDGET_LABELS.CAR_TAB);
        const VerifyDateRestriction = () => {
          if (picker.value != null && picker.value.begin != null) {
            const date = new Date(picker.value.begin);
            date.setHours(0, 0, 0, 0);

            let showError = false;
            if (isEqual(date, this.todaysDate)) {
              const now = new Date();
              const totalMinutes = now.getHours() * 60 + now.getMinutes();

              const selected = $(_form.form).find('.pickup-time select option:selected');
              const selectedIndex = selected.index();
              const nowIndex = totalMinutes / 30; //  dropdown has an option for every half-hour

              showError = selectedIndex < Math.ceil(nowIndex);
            }

            $(_form.form).find('.pickup-time select').toggleClass('red-border', showError);
          }
        };

        $(_form.form).find("button[type='submit']").on('mousedown', VerifyDateRestriction);
        $(_form.form).find('.pickup-time select').on('change', VerifyDateRestriction);
        picker.onChange = VerifyDateRestriction;
      }
      // Cruise tab
      if (item.type == this.TST_WIDGET_LABELS.CRUISE_TAB) {
        // hide some cruise fields based on design and update array to place as advanced hidden fields

        /** Also adding labels since we're here */

        const cruiseFields = _.find(this.mappedFields, (field: any) => {
          return field.type == TST_WIDGET_LABELS.CRUISE_TAB;
        });

        // add relevant classes to cruise fields as they have no classes or ids at correct level
        const cruiseDivs = item.tstForm.querySelectorAll('div');
        _.each(cruiseDivs, (element: any) => {
          if (element.children.length) {
            _.each(element.children, (child) => {
              const cls = this.addSelectClasses(child);
              this.addCruiseLabel(cruiseFields, cls);
            });
          }
        });

        this.cruiseTravelerSpelling(cruiseFields, _form.form);
        $(_form.formSelector + ' select').on('change', { cruiseFields: cruiseFields, form: _form.form }, (e) => {
          this.cruiseTravelerSpelling(e.data.cruiseFields, e.data.form);
        });

        /* replace cruise toggle buttons with toggle component (formCruiseOptions) */
        if (this.cruiseOptionsViewRef) {
          // get rootnodes of injected template from viewref
          const cruiseOptionsRNs = this.cruiseOptionsViewRef.rootNodes;
          if (cruiseOptionsRNs.length) {
            const cruiseOptionsRN: any = this.tstService.getRootNodesFromView(
              cruiseOptionsRNs,
              'classList',
              'tst-form__options--cruise',
              'array'
            );
            if (hasValue(cruiseOptionsRN)) {
              // target element to replace with rootnode
              const targetEl = item.tstForm.querySelector('.cruise-type');
              this.insertedCruiseOptions =
                targetEl && this.tstService.placeRootNodes(cruiseOptionsRN[0], targetEl, 'insertAdjacentElement', 'afterend');
              this.insertedCruiseOptions && $(targetEl).remove();
            }
          }
        }

        /* if matpanel has been inserted in to its viewcontainer, get the relevent rootnode. */
        if (hasValue(this.cruiseMatPanelViewRef)) {
          this.cruiseMatPanelRootNode = this.getCruisePanel();
        }

        /* get cruise advanced rootnode, insert into matpanel and then move the hidden cruise fields into advanced field node  */
        if (hasValue(this.cruiseAdvancedFieldsViewRef) && hasValue(this.cruiseMatPanelRootNode)) {
          const cruiseAdvRootNodes = this.cruiseAdvancedFieldsViewRef.rootNodes;
          if (cruiseAdvRootNodes.length) {
            const cruiseAdvRootNode: any = this.tstService.getRootNodesFromView(
              cruiseAdvRootNodes,
              'classList',
              'tst-search__cruise-advanced-fields',
              'array'
            );
            const targetEl = item.tstForm.querySelector('.search-btn');
            if (hasValue(cruiseAdvRootNode) && hasValue(targetEl)) {
              const appendedChild = this.cruiseMatPanelRootNode[0].appendChild(cruiseAdvRootNode[0]);
              if (appendedChild) {
                _.each(
                  this.cruiseFieldsToHide,
                  (cf) => {
                    const field = document.getElementById(cf.id);
                    cruiseAdvRootNode[0].appendChild(field.parentElement);
                  },
                  this
                );
                const panelBody = this.cruiseMatPanelRootNode[0].querySelector('.mat-expansion-panel-body');
                panelBody.appendChild(cruiseAdvRootNode[0]);
                this.insertElementAfter(targetEl, this.cruiseMatPanelRootNode[0], 'afterend');
              }
            }
          }
        }

        /** injectedIcons */
        this.injectedIconsConfig(this.icons.cruise, item.tstForm);
      }

      // activity tab
      if (item.type == this.TST_WIDGET_LABELS.ACTIVITY_TAB) {
        /** injectedIcons */
        this.injectedIconsConfig(this.icons.activity, item.tstForm);
        _form && this.getAutoCompleteList(item.type);
      }

      /**
       * Add select arrows for this tst type
       */
      const selects = item.tstForm.querySelectorAll('select, .rooms-grp .roomsSummary,.form-stepper__control');

      const guestContext = {
        $implicit: {
          type: item.type,
          dialogName:
            item.type == this.TST_WIDGET_LABELS.PACKAGE_TAB ||
            item.type == this.TST_WIDGET_LABELS.DYNAMIC_TAB ||
            item.type == this.TST_WIDGET_LABELS.HOTEL_TAB ||
            item.type == this.TST_WIDGET_LABELS.VACATION_RENTALS
              ? this.GUEST_INCREMENTORS[item.type].$implicit.dialogName
              : null,
        },
      };

      if (selects.length) {
        _.each(selects, (sel) => {
          const view = this.selectArrow.createEmbeddedView(guestContext);
          this.arrowViewRef = this.selectArrowContainer.insert(view);
          if (hasValue(this.arrowViewRef)) {
            this.placeSelectArrow(this.arrowViewRef, sel);
          }
        });
      }
    }

    // misc.
    {
      const tabsToTrack = [this.TST_WIDGET_LABELS.HOTEL_TAB, this.TST_WIDGET_LABELS.CRUISE_TAB, this.TST_WIDGET_LABELS.CAR_TAB];
      if (tabsToTrack.includes(item.type)) {
        this.tstService.gtmInitTracking(item.tstForm, this);
      }
      const root = $(target);

      /**
       * Update all "datepicker" input fields to display their placeholders with the
       * same data format: MM-DD-YYYY
       * Re. BUG 1495
       **/
      root.find('input[data-tst-datepicker="true"]').attr('placeholder', 'MM-DD-YYYY');

      //  Activity tab
      //  tst's markup has month and label in wrong place - this is a hack.
      const month_target = root.find('.activity-section form > .monthYear');
      root.find('.activity-section form > label').remove();
      root.find('.activity-section form > select[name=monthYear]').remove();
      root.find('.activity-section form > select[name=monthYear]').prependTo(month_target);
      root.find('.activity-section form > label').prependTo(month_target);
    }

    /**
     * ...and finally, complete initWidget()
     * 1. Change some Labels and placeholders
     * 2. Autofill departure city to default to default departure city
     * 3. Input validation and re-arrange tst-error element hidden fields so they are place in areas that can be styled
     * 4. add event listeners on elements we want to validate if needed
     * 5. fade widget in...
     */
    _form && this.changeFormLabels(_form);
    _form && this.defaultDepartureAirport && this.autoFillDestinations();
    _form && this.arrangeFormErrorFields(item);
    _form && this.addInputValidation(item.tstForm);
    this.tstLoaded = true;
  }

  /** _____________________________ Dom Manipulation functions
   * After TST loaded we need to perform various changes and injections to the TST form
   * during or after initWidget() */

  // called from initWidget(), optionsFormControl()
  public changeFormLabels(formModel = null) {
    if (formModel) {
      _.each(formModel.fields, (field: any) => {
        let el;
        // set label
        el = field.label ? formModel.form.querySelector(field.outerClass) : null;
        const label = el ? el.querySelector('label') : null;
        if (label) label.innerHTML = field.label;
        // set placeholder
        el = field.control.placeholder ? formModel.form.querySelector(field.outerClass) : null;
        const ph = el ? el.querySelector('input') : null;
        if (ph) ph.setAttribute('placeholder', field.control.placeholder);
      });
    }
  }

  private setDynamicOptionValidation(event = null, valid: boolean) {
    const options = document.querySelector('.tst-form__option-group.dynamic');
    if (!valid) {
      event && event.preventDefault();
      this.dynamicOptionsValid = false;
    } else {
      this.dynamicOptionsValid = true;
    }
  }

  /** Cruise related manipulation */
  private addSelectClasses(child: any) {
    /**
     * adds relevent classes to cruise form elements by using the select's name attr
     * as TST didnt put any classes (thanks, tst)
     * */
    let id;
    if (hasValue(child.nodeName)) {
      id = child.nodeName == 'SELECT' && hasValue(child.id) ? child.id : null;
      if (hasValue(child.parentElement) && hasValue(id)) {
        child.parentElement.classList.add(id);
        child.parentElement.classList.add('tst-search__cruise-select');
      }
    }
    return id;
  }

  private addCruiseLabel(cruiseFields: any = null, cls: string = '') {
    let control;
    if (cruiseFields && hasValue(cls)) {
      control = _.find(
        cruiseFields.fields,
        (cf: any) => {
          return cf.outerClass == '.' + cls;
        },
        this
      );
      const parent = control ? cruiseFields.form.querySelector(control.outerClass) : null;
      const label = document.createElement('label');
      label.setAttribute('class', 'cruise-label');
      label.innerText = control.label;
      parent && parent.insertBefore(label, parent.firstChild);
    }
  }

  private cruiseTravelerSpelling(cruiseFields = null, form = null) {
    /**
     * Need to change to Canadian spelling after select options are loaded.
     * On any select change, the api results for travellers field come back from tst with
     * american spelling again therefore need start timeout again
     */
    clearTimeout(this.cruiseTravelerOptionsTimeout);
    const rgx = /[/s]*(traveler)+/;
    const elem = cruiseFields && form ? form[cruiseFields.fields.cruiseTravelers.control.id] : null;
    if (elem) {
      elem.style.color = 'rgba(35,35,35,.25)';
      elem.style.fontStyle = 'italic';
    }
    if (elem.children.length > 1 && rgx.test(elem.children[1].innerText)) {
      _.each(elem.children, (opt: any) => {
        if (opt.innerHTML) opt.innerHTML = opt.innerHTML.replace(rgx, 'Traveller');
      });
      elem.style.color = 'rgba(35,35,35,1)';
      elem.style.fontStyle = 'normal';
    } else {
      this.cruiseTravelerOptionsTimeout = this.usingTimeout(window.setTimeout(() => this.cruiseTravelerSpelling(cruiseFields, form), 20));
    }
  }

  // called from initWidget()
  private orderDepartureCities(item) {
    clearTimeout(this.orderDepartureCitiesTimeout);
    //  What order the cities should appear in
    const cities = ['Edmonton', 'Calgary', 'Grande Prairie', 'Fort McMurray'];
    const from = $(item).find('.prepackaged-section select[name=departureLocation]');
    const to = $(item).find('.prepackaged-section select[name=destination]');
    const fromOptions = _.map(from.children('option'), (el) => $(el));
    const toOptions = _.map(to.children('optgroup'), (el) => $(el));

    //  We can reorder the children
    if (fromOptions.length > 0 && toOptions.length > 0) {
      let matches = 0;
      _.each(cities, (city, index) => {
        const option = _.find(fromOptions, (c) => c.val() == city);
        if (hasValue(option)) {
          option.detach().insertBefore(from.children().eq(matches++));
        }
      });
      this.updateAirport();
    } else {
      //  Try again
      this.orderDepartureCitiesTimeout = this.usingTimeout(window.setTimeout(() => this.orderDepartureCities(item), 200));
    }
  }

  // called from initWidget()
  private updateAirport() {
    if (hasValue(this.container) && hasValue(this.container.nativeElement)) {
      const select = $(this.container.nativeElement).find('.prepackaged-section select[name=departureLocation]');

      if (this.defaultDepartureAirport) {
        select.val(
          ALBERTA_AIRPORTS.find((airport: any) => {
            return airport.airportCode == this.defaultDepartureAirport;
          }).city
        );
      } else {
        select.val('Edmonton');
      }
    }
  }

  /** _____________________________ Inject and/or replace new elements/controls */

  private insertIncrementor(tr: TemplateRef<ElementRef> = this.packagesGuests, context: any = { $implicit: { type: 'prepackaged' } }) {
    const vr = this.tstService.insertTemplateRef(this.guestIncrementorViewContainer, tr, context);
    return vr;
  }

  /**
   * Inject the hidden "advanced" cruise fields
   */
  private insertAdvancedCruiseFields() {
    this.cruiseOptionsViewRef = this.tstService.insertTemplateRef(this.cruiseOptionsContainer, this.cruiseOptions);

    this.cruiseAdvancedFieldsViewRef = this.tstService.insertTemplateRef(
      this.cruiseAdvancedFieldsContainer,
      this.cruiseAdvancedFieldsTemplate
    );

    this.cruiseMatPanelViewRef = this.tstService.insertTemplateRef(this.cruiseMatPanelContainer, this.cruiseMatPanel);
  }

  private insertAdvancedSearch(type: string) {
    this.advancedSearchLinkViewRef = this.tstService.insertTemplateRef(this.advancedSearchContainer, this.advancedSearchLink, {
      $implicit: type,
    });
  }

  /**
   * After "advanced" cruise fields injected, place the rootnodes into the form
   */
  private getCruisePanel() {
    const cruiseMatPanelRootNodes = this.cruiseMatPanelViewRef.rootNodes;
    if (cruiseMatPanelRootNodes.length) {
      const cruiseMatPanelRootNode: any = this.tstService.getRootNodesFromView(
        cruiseMatPanelRootNodes,
        'classList',
        'tst-search__cruise-reveal-advanced',
        'array'
      );
      if (hasValue(cruiseMatPanelRootNode)) {
        return cruiseMatPanelRootNode;
      }
    }
    return false;
  }

  /**
   * To place arrow for selects and add events for animations
   */
  private placeSelectArrow(vr, sel) {
    const arrowRootNodes = vr.rootNodes;
    if (arrowRootNodes.length) {
      const svgArrowNode: any = this.tstService.getRootNodesFromView(arrowRootNodes, 'classList', 'tst-search__select-arrow', 'array');

      let open: boolean = false;
      if (svgArrowNode.length) {
        sel.addEventListener('focus', (e) => {
          open = true;
          this.setSelectClass(open, sel);
        });
        sel.addEventListener('change', (e) => {
          open = false;
          this.setSelectClass(open, sel);
        });
        sel.addEventListener('blur', (e) => {
          open = false;
          this.setSelectClass(open, sel);
        });

        if (window.getComputedStyle(sel).display == 'none') {
          svgArrowNode[0].style.setProperty('display', 'none');
        }
        const elementInserted = this.insertElementAfter(sel, svgArrowNode[0], 'afterend');
      }
    }
  }

  /** called from placeSelectArrow() */
  private setSelectClass(open, sel) {
    if (open) {
      sel.classList.add('open');
    } else {
      sel.classList.remove('open');
    }
  }

  /**
   * This is for the flight form only
   */
  private customForms() {
    this.flightCustomFormViewRef = this.tstService.insertTemplateRef(this.customFormContainer, this.customFlightFormTemplateRef);
  }

  private injectedIconsConfig(type: any, form) {
    /**
     * loop through ICON_INJECTIONS for passed tst type
     * inject misc icons into tst form
     * except datepickers and arrows */
    _.each(type, (icon: any) => {
      const context = { $implicit: icon };
      this.iconViewRef = this.tstService.insertTemplateRef(this.iconInjectViewContainer, this.injectedIcons, context);
      if (hasValue(this.iconViewRef)) {
        let _targetEl;
        let _el;
        if (hasValue(icon.selectorType) && icon.selectorType == 'id') {
          _el = document.getElementById(icon.selector);
          if (_el) _targetEl = _el.querySelector('input');
        } else {
          _targetEl = form.querySelector(icon.selector);
        }
        if (hasValue(_targetEl)) {
          const _iconRootNodes: any = this.iconViewRef.rootNodes;
          if (hasValue(_iconRootNodes) && _iconRootNodes.length) {
            const _iconRootNode = this.tstService.getRootNodesFromView(_iconRootNodes, 'classList', 'tst-icon', 'array');

            const _placed = this.tstService.placeRootNodes(_iconRootNode[0], _targetEl, 'insertAdjacentElement', 'beforebegin');

            if (hasValue(_placed)) {
              _placed.classList.add(...icon.classes);
            }
          }
        }
      }
    });
  }

  private moveCarPickupTime(isMobile: boolean) {
    if (isMobile) {
      const carPickupTime = $('#car-form .pickup-time').html();
      if (!carPickupTime) return;

      $('#car-form .pickup-time').remove();
      $('#car-form .car-return-time').before('<div class="car-pickup-time">' + carPickupTime + '</div>');
      $('#car-form .car-pickup-time .tst-icon--clock').append(
        '<fa-icon class="ng-fa-icon"><svg aria-hidden="true" data-prefix="fal" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http:// www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg></fa-icon>'
      );
    } else {
      const carPickupTime = $('#car-form .car-pickup-time').html();
      if (!carPickupTime) return;

      $('#car-form .car-pickup-time').remove();
      $('#car-form .carPickupDatePicker').after('<div class="pickup-time">' + carPickupTime + '</div>');
    }
  }

  /** _____________________________ Template functions (output events from template) */

  /**
   * @param e event
   * @param label tst product type string
   */
  public tabClicked(e, label: string = '') {
    if (label && label == 'tmi' && !this.enableTmi) {
      this.router.navigateByUrl('travel-medical-insurance');
    } else {
      const cb = () => {
        this.clickedTab.emit(label);
        if (this.mq.mobile) {
          if (
            this.activeTab &&
            this.activeTab.type == label &&
            this.tabClickedState &&
            _.indexOf(['begin', 'removed', 'selected'], this.locationState) < 0
          ) {
            this.activeTab = null;
            this.activeForm = null;
            this.tabClickedState = false;
          } else {
            this.tabClickedState = true;
            this.trySetActiveTab(label);
            this.tabClickedStateEmit.emit(this.tabClickedState);
            /**
             * conditions to change form option value if:
             * current option isnt already "vacation" and,
             * the selected tab has tab children and,
             * a location as been selected and,
             * selected location type matches children but,
             * activeTab is not a selected location type
             * otherwise set option value form activeTab type's value (prepackaged | vacation)
             */
            if (this.activeTab.optionGroup) {
              const currentItems: any = this.groupMatches(this.activeTab);
              const currentItem = currentItems && this.getBannerItem(currentItems[0]);
              // theres no way to know which to default to if multiple children matches .·. default to first
              const control = this.formVacationOptions.get(this.activeTab.optionGroup);
              // merge selectedlocationtypes and suggestedtypes to compare.
              if (
                this.currentVacationOption != TST_WIDGET_LABELS.DYNAMIC_TAB &&
                control &&
                currentItem &&
                this.selectedLocation &&
                _.indexOf(this.selectedLocationTypes, TST_WIDGET_LABELS.PACKAGE_TAB) < 0
              ) {
                this.setVacationFormControl(currentItem.optionValue, control, TST_WIDGET_LABELS.DYNAMIC_TAB);
                this.currentVacationOption = TST_WIDGET_LABELS.DYNAMIC_TAB;
              } else if (this.currentVacationOption != TST_WIDGET_LABELS.PACKAGE_TAB) {
                const ov = _.find(
                  this.bannerConfig,
                  (item: any) => {
                    return item.type == this.currentVacationOption;
                  },
                  this
                );
                this.currentVacationOption = ov ? ov.type : TST_WIDGET_LABELS.PACKAGE_TAB;
              } else {
                this.currentVacationOption = TST_WIDGET_LABELS.PACKAGE_TAB;
              }
            }
            // this.portLocationfieldValueToTST(this.selectedLocation, true);
          }
        }
      };

      if (!this.mq.mobile) this.trySetActiveTab(label, cb);
      else cb();
    }
  }

  /**
   * Called from tabClicked()
   */
  private getBannerItem(item: any = null) {
    const type: string = item && item.type ? item.type : item.toString();
    return _.find(this.bannerConfig, (bc: any) => {
      return type == bc.type;
    });
  }

  /**
   * called for when tabs at the end (activities) on mobile are touched to add class
   */
  public scrollStart(e) {
    this.mq.mobile && $(this.tabTrack.nativeElement).addClass('scrolling');
  }
  public scrollEnd(e) {
    this.mq.mobile && $(this.tabTrack.nativeElement).removeClass('scrolling');
  }

  /** _____________________________ Datepicker functions */

  private insertDatePickers() {
    // insert templateRef into viewContainerRef
    this.datePickerViewRef = this.tstService.insertTemplateRef(this.datePickerViewContainer, this.datePickerTemplateRef);
  }

  private replaceDatePicker(dp, item, newDps) {
    if (item.tstForm) {
      const currentDp = item.tstForm.querySelector('.' + dp.replaceClass);
      const currentReturnDp = dp.replaceClassReturn ? item.tstForm.querySelector('.' + dp.replaceClassReturn) : null;
      const newDp = this.tstService.getRootNodesFromView(newDps, 'classList', dp.name, 'array');
      if (hasValue(currentDp) && newDp && newDp.length) {
        const insertedElement = this.insertElementAfter(currentDp, newDp[0], 'afterend');
        // we need to set this so we can reference it for date-range picker
        dp.matDateElement = newDp[0].querySelector('.mat-mdc-input-element');
        // hide old date field container, and add reference to update its value on date change
        if (hasValue(insertedElement)) {
          currentDp.classList.add('hide');
          currentReturnDp && currentReturnDp.classList.add('hide');
          const oldDateInput = currentDp.querySelector("input[type='text']");
          const oldReturnDateInput = currentReturnDp && currentReturnDp.querySelector("input[type='text']");
          if (oldDateInput) {
            dp.inputToUpdate = oldDateInput;
          }
          if (oldReturnDateInput) {
            dp.returnInputToUpdate = oldReturnDateInput;
          }

          // we need a reference to the related date field for date-range pickers
          dp.relatedDatePickerName &&
            _.each(this.bannerConfig, (bItem: any) => {
              if (bItem.datePickers && bItem.datePickers.length) {
                const relatedDp = _.filter(bItem.datePickers, (_dp: any) => {
                  return _dp.name == dp.relatedDatePickerName;
                });
                dp.relatedDatePicker = relatedDp.length ? relatedDp[0] : null;
                relatedDp.length;
              }
            });
        }
      }
    }
  }
  /**
   * Handles when datepicker date has been chosen to update widget form values
   * @param e event object if called from matDatePicker
   * @param dp datepicker config object defined in tst models
   * @param data object if called from ngbDatePicker
   */
  public datePickerChanged(e, dp, data: any = null) {
    const isMatDatePicker: boolean = e ? true : false;
    let val = isMatDatePicker && e.value ? e.value : data ? this.dates.fromDateLong : null;
    const targetEl = isMatDatePicker ? e.targetElement : data && data.input ? document.getElementById(data.input) : null;

    let isValid = false;
    let lastValue = dp.inputToUpdate != null ? dp.inputToUpdate.value : null;
    let beginDate;
    let endDate;

    if (lastValue == '') lastValue = null;
    this.minDepartDate = val;
    targetEl && this.validateForm(targetEl);

    // not a daterange and valid date
    if (!dp.dateRange && val >= this.todaysDate) {
      dp.formControl.setValue(val);
      // set hidden datefields to send to tst deeplink api
      hasValue(dp.inputToUpdate) ? (dp.inputToUpdate.value = dateFormat(val, TST_DATE_FORMAT)) : false;

      isValid = true;
      // if matdatepicker and daterange, update leave and return dates
    } else if (isMatDatePicker && val.begin >= this.todaysDate) {
      beginDate = dateFormat(e.value.begin, TST_DATE_FORMAT);
      endDate = dateFormat(e.value.end, TST_DATE_FORMAT);
      // set hidden datefields to send to tst deeplink api for leaving and returning
      if (dp.inputToUpdate) dp.inputToUpdate.value = beginDate;
      if (dp.returnInputToUpdate) dp.returnInputToUpdate.value = endDate;

      isValid = true;
      // if not matdatepicker and daterange, update leave and return dates (must be ngbDatePicker)
    } else if (!isMatDatePicker) {
      if (val) beginDate = dateFormat(val, TST_DATE_FORMAT);
      if (this.dates.toDateLong) endDate = dateFormat(this.dates.toDateLong, TST_DATE_FORMAT);
      if (dp.inputToUpdate) dp.inputToUpdate.value = beginDate;
      if (dp.returnInputToUpdate) dp.returnInputToUpdate.value = endDate;
    } else {
      // date picked was before today so invalid
      val = this.todaysDate;
      // set hidden datefields to send to tst deeplink api
      hasValue(dp.inputToUpdate) ? (dp.inputToUpdate.value = this.todaysDate) : false;
    }

    if (dp.onChange && isValid && lastValue != null) dp.onChange();
  }

  public datePickerSelection(date: NgbDate, data: any = null) {
    const ngbd = this.tstService.convertToNgbDate(date, this.dates.dateRngCalendar);

    $('.departureDateErrorMessage').remove();

    data.dp.formControl.setValue(ngbd);
    this.dates.dateRngFromDate = ngbd;
    this.dates.dateRngToDate = this.dates.endDate = null;
    const theDate = this.tstService.convertNgDateToLongDate(this.dates.dateRngFromDate);

    this.dates.fromDateLong = this.dates.startDate = theDate;

    if (theDate && data && data.dp && data.dp.inputToUpdate) {
      data.dp.inputToUpdate.value = dateFormat(theDate, TST_DATE_FORMAT);
    }

    data.element.close();
  }
  public dateRangeSelection(date: any, data: any) {
    data.closeCurrent = false;
    data.ngbd = this.tstService.convertToNgbDate(date, this.dates.dateRngCalendar);
    data.dp.formControl.setValue(data.ngbd);
    /**
     * If depart datepicker picked
     */
    if (data.flightDirection == 'depart') {
      this.tstService.doDepart(this.dates, data);
    } else {
      /**
       * If return datepicker picked
       */
      this.tstService.doReturn(this.dates, data);
    }

    // data.element && data.element.close();
    data.closeCurrent && data.element && data.element.close();
    data.elementToTrigger && data.elementToTrigger.open();

    // create object to look like event to call datePickerChanged()
    this.datePickerChanged(null, data.dp, data);

    const datePickerFrom = this.activeForm.tstForm.querySelectorAll(
      'input#rangeDatePickerFrom-' + this.activeForm.type + '.tst-form__field.tst-form__field--datepicker.dateEmpty.showErrorMessage'
    );

    const datePickerTo = this.activeForm.tstForm.querySelectorAll(
      'input#rangeDatePickerTo-' + this.activeForm.type + '.tst-form__field.tst-form__field--datepicker.dateEmpty.showErrorMessage'
    );

    if (datePickerFrom.length > 0 && datePickerTo.length > 0) {
      if (datePickerFrom[0].className.includes('showErrorMessage') && datePickerTo[0].className.includes('showErrorMessage')) {
        if (datePickerFrom[0].id === 'rangeDatePickerFrom-' + this.activeForm.type) {
          $('.departureDateErrorMessage').remove();
          $('.returnDateErrorMessage').remove();

          if (!this.mq.mobile) {
            if (this.mq.tablet) {
              $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.35rem');
            } else if (this.mq.desktop) {
              $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');

              if (
                this.activeForm.type === TST_WIDGET_LABELS.HOTEL_TAB ||
                this.activeForm.type === TST_WIDGET_LABELS.VACATION_RENTALS ||
                this.activeForm.type === TST_WIDGET_LABELS.CAR_TAB
              ) {
                $('.' + this.activeForm.type + '-section div.search-btn').css('margin', 'auto 0.5rem 0.0rem 0');
              }
            }
          }
        }
      } else if (datePickerTo[0].className.includes('showErrorMessage')) {
        $('.returnDateErrorMessage').remove();

        if (!this.mq.mobile) {
          if (this.mq.tablet) {
            $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.35rem');
          } else if (this.mq.desktop) {
            $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');
            if (
              this.activeForm.type === TST_WIDGET_LABELS.HOTEL_TAB ||
              this.activeForm.type === TST_WIDGET_LABELS.VACATION_RENTALS ||
              this.activeForm.type === TST_WIDGET_LABELS.CAR_TAB
            ) {
              $('.' + this.activeForm.type + '-section div.search-btn').css('margin', 'auto 0.5rem 0.0rem 0');
            }
          }
        }
      }
    }
  }

  public initialDateRangeClick(e) {
    this.dateRangeClickCount++;
    this.showDateRangeMsg = this.dateRangeClickCount == 1 ? true : false;
  }

  public formStepperDialogState(event) {
    this.dialogRefFormStepper = event;
  }

  /**
   *
   * @param event @Output emit -> formStepper component
   */
  public formStepperCallback(event) {
    /**
     * Need to create hidden fields for multiple rooms or children after formstepper changed and when initalized (to default values)
     * event params {type:<tst-type>, values:<form.valueChanges.subscribe()> ,data:<$implicit object>, control:lastChangedControl<{formControl,props}>}
     * data defined in  app/models/tst/GUEST_INCREMENTORS
     */
    let element = null;

    element = _.filter(this.placedIncrementors, (i: any) => {
      return i.name == event.type;
    });

    const hiddenField = element ? element[0].element.querySelector('.tst-search__incrementor-hidden') : null;

    if (hiddenField) {
      const trigger = event.control.props.trigger ? event.control.props.trigger : null;
      const hasTrigger: boolean = trigger && trigger.addItems.length ? true : false;

      if (event.type == TST_WIDGET_LABELS.PACKAGE_TAB) {
        const numChildren = _.filter(hiddenField.children, (c: any) => {
          return c.name == 'numberOfChildren';
        });
        numChildren[0] && event.values.numChildren && numChildren[0].setAttribute('value', event.values.numChildren);
        const childAges = hiddenField.querySelectorAll("input[name^='childAges']");
        if (event.values) {
          childAges.length &&
            _.each(childAges, (c: any) => {
              c.parentElement.removeChild(c);
            });

          for (let i = 0; i < event.values.numChildren; i++) {
            const node = document.createElement('input');
            node.setAttribute('type', 'hidden');
            node.setAttribute('name', 'childAges[' + i + ']');
            node.setAttribute('value', hasTrigger ? event.values[trigger.namePrefix + i] : '2'); // control.props.trigger
            hiddenField.appendChild(node);
          }
        }
      }

      if (event.type == TST_WIDGET_LABELS.DYNAMIC_TAB) {
        const childAges = hiddenField.querySelectorAll("input[name^='m']");
        if (event.values) {
          childAges.length &&
            _.each(childAges, (c: any) => {
              c.parentElement.removeChild(c);
            });
          if (event.values && event.values.numChildren > 0) {
            for (let i = 0; i < event.values.numChildren; i++) {
              const node = document.createElement('input');
              node.setAttribute('type', 'hidden');
              node.setAttribute('name', 'm' + i);
              node.setAttribute('value', hasTrigger ? event.values[trigger.namePrefix + i] : '2');
              hiddenField.appendChild(node);
            }
          }
        }
      }

      if (event.type == TST_WIDGET_LABELS.HOTEL_TAB) {
        const roomItems = hiddenField.querySelectorAll("input[name^='rooms[']");
        roomItems.length &&
          _.each(roomItems, (c: any) => {
            c.parentElement && c.parentElement.removeChild(c);
          });
        if (event.values && event.values.hotelRoomsCount > 1) {
          for (let i = 0; i < event.values.hotelRoomsCount; i++) {
            const node = document.createElement('input');
            node.setAttribute('type', 'hidden');
            node.setAttribute('name', 'rooms[' + i + '].numOfAdults');
            node.setAttribute('value', '2');
            hiddenField.appendChild(node);
          }
        } else {
          const node = document.createElement('input');
          node.setAttribute('type', 'hidden');
          node.setAttribute('name', 'rooms[0].numOfAdults');
          node.setAttribute(
            'value',
            event.type == TST_WIDGET_LABELS.HOTEL_TAB ? event.values.hotelAdultsCount : event.values.vacationRentalsAdultsCount
          );
          hiddenField.appendChild(node);
          for (let i = 0; i < event.values.hotelChildrenCount; i++) {
            const nod = document.createElement('input');
            nod.setAttribute('type', 'hidden');
            nod.setAttribute('name', 'rooms[0].children[' + i + ']');
            nod.setAttribute('value', hasTrigger ? event.values[trigger.namePrefix + i] : '2');
            hiddenField.appendChild(nod);
          }
        }
      }

      if (event.type == TST_WIDGET_LABELS.VACATION_RENTALS) {
        // override children values
        const childrenField = hiddenField.querySelector('input[name=children]');
        const childValues = [];
        if (childrenField) {
          // eslint-disable-next-line guard-for-in, prefer-const
          for (let key in event.values) {
            key.match(/children[\d]/g) && childValues.push(event.values[key]);
          }
          childrenField.value = childValues.length > 0 ? childValues.join(',') : '';
        }
      }
    }
  }

  /** _____________________________ Form control/change events--- */

  /**
   * subscribe to custom form control changes
   */
  private optionsFormControl() {
    if (hasValue(this.formVacationOptions))
      this.formVacationOptions.valueChanges.subscribe((change) => {
        if (hasValue(change.formType) && change.formType.constructor === Array) {
          switch (change.formType[0]) {
            case TST_WIDGET_LABELS.DYNAMIC_TAB: {
              // update TST select value if vacation option and save vacation option if last selected
              const _form = _.find(TST_MAP_FIELDS, (field: any) => {
                return field.type == TST_WIDGET_LABELS.DYNAMIC_TAB;
              });
              this.changeVacationSelect(change, _form);
              this.currentVacationOption = TST_WIDGET_LABELS.DYNAMIC_TAB;
              // even though already run, changing to dynamic packaging form resets the changed labels.
              _form && this.changeFormLabels(_form);

              const vacForm = document.getElementById('vacationForm');
              if (vacForm && !vacForm['st'].value) {
                this.setDynamicOptionValidation(null, false);
              } else {
                this.setDynamicOptionValidation(null, true);
              }

              break;
            }

            case TST_WIDGET_LABELS.TOURS_TAB: {
              this.currentVacationOption = TST_WIDGET_LABELS.TOURS_TAB;
              break;
            }

            case TST_WIDGET_LABELS.PACKAGE_TAB: {
              this.currentVacationOption = TST_WIDGET_LABELS.PACKAGE_TAB;
              break;
            }

            default: {
              this.currentVacationOption = false;
              break;
            }
          }
        } else {
          this.currentVacationOption = change.formType ? change.formType : false;
        }

        this.mobileActiveForm = this.currentVacationOption;

        if (change.formType[0] != TST_WIDGET_LABELS.DYNAMIC_TAB && change.formType[0] != TST_WIDGET_LABELS.TOURS_TAB) {
          this.searchisAllInclusive = true;
        } else {
          this.searchisAllInclusive = false;
        }

        this.setActiveForm(change.formType[0]);
      }, this);
    if (hasValue(this.formCruiseOptions))
      this.formCruiseOptions.valueChanges.subscribe((change) => {
        this.oceanSelected = hasValue(change.ocean) ? change.ocean : this.oceanSelected;
        this.riverSelected = hasValue(change.river) ? change.river : this.riverSelected;
        // both cant be false
        this.oceanSelected = !this.oceanSelected && !this.riverSelected ? true : this.oceanSelected;

        // we need to call cruiseTravelerSpelling() to again, change the spelling of 'travelers'
        const cruiseFields = _.find(this.mappedFields, (field: any) => {
          return field.type == TST_WIDGET_LABELS.CRUISE_TAB;
        });
        cruiseFields && cruiseFields.form && this.cruiseTravelerSpelling(cruiseFields, cruiseFields.form);
      }, this);

    if (hasValue(this.activityFormOptions))
      this.activityFormOptions.valueChanges.subscribe((change) => {
        if (hasValue(change.formType) && change.formType.constructor === Array) {
          switch (change.formType[0]) {
            case TST_WIDGET_LABELS.ACTIVITY_TAB: {
              this.currentActivityOption = TST_WIDGET_LABELS.TOURS_TAB;
              this.tabTitle = 'Plan your activities before you travel and discover amazing things to do at your destination.';
              break;
            }

            case TST_WIDGET_LABELS.TOURS_TAB: {
              this.currentActivityOption = TST_WIDGET_LABELS.TOURS_TAB;
              this.tabTitle =
                'Search hundreds of carefully crafted tour itineraries across Europe, Africa, South America and the rest of the world.';
              break;
            }

            default: {
              this.currentActivityOption = false;
              break;
            }
          }
        } else {
          this.currentActivityOption = change.formType ? change.formType : false;
        }

        this.mobileActiveForm = this.currentActivityOption;
        this.setActiveForm(change.formType[0]);
      }, this);
  }

  /**
   * when custom radio options for dynamic packages changed, changed hidden select value in TST form
   * this.bannerConfig[1] is dynamic vacations object
   */
  private changeVacationSelect(change: any, form: any, vacationCurrent: any = null) {
    if (hasValue(change.formType[2]) || vacationCurrent) {
      if (hasValue(this.vacationPackageSelect) && hasValue(this.bannerConfig[1].tstForm)) {
        this.vacationPackageSelect.value = hasValue(change.formType[2])
          ? change.formType[2]
          : hasValue(vacationCurrent.nativeElement.value)
          ? vacationCurrent.nativeElement.value
          : '';
      } else {
        if (hasValue(this.bannerConfig[1].tstForm)) {
          this.vacationPackageSelect = this.bannerConfig[1].tstForm.elements.namedItem('st');
          this.vacationPackageSelect.value = change.formType[2];
        }
      }

      /**
       * hide/show flight+hotel+car form elements
       */
      this.hideShowVacationControls(change.formType[2]);

      /* #9395 - This is to fix the bug where search deal is not firing
       * when "Hotel + Car" is selected because of "Departing from" not provided.
       * "Departing from" is shown only when "Flight + Hotel", "Flight + Hotel + Car" or
       * "Flight + Car" is selected. Set the hidden "Departing from" to disabled to avoid
       * this field being validate when submitting the form
       */
      $(form.formSelector)
        .find("[name='f']")
        .prop('disabled', this.vacationPackageSelect.value === 'hc');
    }
  }

  /**
   * wasnt triggering - extracted from https:// albertateachers.tstllc.net/web-services/searchWidget
   * this turns on/off certain fields base on type of dynamic vacation
   */
  private hideShowVacationControls(selection) {
    //  Only hc has pickup/dropoff times and doesn't have departure
    if (selection === 'hc') {
      document.getElementById('fromLocation').style.display = 'none';
      document.getElementById('toLocation').className = 'location';
      document.getElementById('pickupTime').style.display = '';
      document.getElementById('dropoffTime').style.display = '';
    } else {
      document.getElementById('fromLocation').style.display = '';
      document.getElementById('toLocation').className = 'pickup-location';
      document.getElementById('pickupTime').style.display = 'none';
      document.getElementById('dropoffTime').style.display = 'none';
    }
  }

  private setVacationFormControl(optionVal, control, type: string = TST_WIDGET_LABELS.DYNAMIC_TAB) {
    const option = optionVal.replace(/[\s]+/g, '').split(',');
    option.length && control.setValue([option[0], option[1] ? option[1] : '', option[2] ? option[2] : '']);
    this.currentVacationOption = type;
  }

  /** _____________________________ Validation Functions && related functions:
   * arrangeFormErrorFields(), portLocationfieldValueToTST(), validateLocation(),
   * checkValidateLocation(), setLocationValidationField(), validateForm(),
   * addInputValidation(), autoFillDestinations()
   */

  private arrangeFormErrorFields(item) {
    // each tst form as different layouts and structure
    switch (item.type) {
      case TST_WIDGET_LABELS.PACKAGE_TAB: {
        /** packages doesnt have error validation fields
         ** so we will create one and append to datepicker container. */
        const target = $(item.tstForm).find('.tst-search__date-picker-form');
        const field = document.createElement('span');
        field.setAttribute('hidden', 'true');
        field.setAttribute('class', 'tst-error-message tst-hidden');
        field.innerHTML = 'Please select a departure date';
        target.length && target[0].appendChild(field);
        break;
      }
    }
  }

  private addInputValidation(form: HTMLFormElement = null) {
    const controls = form ? form.elements : null;
    // form && $(form).validate();
    controls.length &&
      _.each(
        controls,
        (i: any) => {
          if (i.type == 'text') {
            // only validate 2 types of fields that are "text" (tstAutocomplete or matDatePickr)
            const targetControl: boolean =
              i.dataset.tstAutocomplete || i.dataset.tstActivityAutocomplete || i.classList.contains('tst-form__field--datepicker')
                ? true
                : false;
            if (targetControl) {
              if (i.name != 'dropOffLocation' && (i.dataset.tstAutocomplete || i.dataset.tstActivityAutocomplete))
                i.setAttribute('required', true);

              i.addEventListener('change', (e) => {
                this.validateForm(e.target);
              });
              i.addEventListener('invalid', function (event) {
                // prevent browser validation tooltips
                event.preventDefault();
              });
            }
          }

          if (i.type == 'submit') {
            // if form has invalid elements, add a class
            i.addEventListener('click', (e) => {
              if (!this.validateForm(e.target)) {
                e.stopImmediatePropagation();

                // Grab the invalid input fields
                const context = this;
                const invalidFields = $(context.activeForm.tstForm).find('.showErrorMessage');

                const tabLabel = context.activeForm.type;

                // Loop through each field
                $.each(invalidFields, function (index, value) {
                  const goingToErrorMessage = context.activeForm.tstForm.querySelectorAll('.goingToErrorMessage');
                  const departureDateErrorMessage = context.activeForm.tstForm.querySelectorAll('.departureDateErrorMessage');
                  const returnDateErrorMessage = context.activeForm.tstForm.querySelectorAll('.returnDateErrorMessage');
                  // Insert tooltip above invalid input/button
                  // Insert reference to tooltip on input/button
                  switch (tabLabel) {
                    case TST_WIDGET_LABELS.PACKAGE_TAB: {
                      if (value.id === 'rangeDatePickerFrom-prepackaged') {
                        if (departureDateErrorMessage.length === 1) {
                          break;
                        } else {
                          // Listen to the departure date change
                          $('.prepackaged-section .tst-search__date-picker-form').append(
                            '<div class="departureDateErrorMessage"><strong>Select a Departure date</strong></div>'
                          );

                          $('.prepackaged-section .departureDateErrorMessage').addClass(
                            'tst-search__form--error-message--prepackaged-tab color-red text-white'
                          );

                          if (!context.mq.mobile) {
                            if (context.mq.tablet) {
                              $('.prepackaged-section .tst-icon.tst-icon--clock').css('bottom', '2.5rem');
                              $('.prepackaged-section .duration .tst-icon.tst-icon--chevron-down').css('bottom', '2.5rem');
                            }
                          }
                        }
                      }
                      break;
                    }
                    case TST_WIDGET_LABELS.DYNAMIC_TAB: {
                      if (value.id === '') {
                        // inject going to error message
                        if (goingToErrorMessage.length === 1) {
                          break;
                        } else {
                          $('.vacation-section div#toLocation.pickup-location').append(
                            '<div class="goingToErrorMessage"><strong>Select a Destination</strong></div>'
                          );

                          $('.vacation-section .goingToErrorMessage').addClass('tst-search__form--error-message color-red text-white');
                        }
                      } else if (value.id === 'rangeDatePickerFrom-vacation') {
                        if (departureDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            '.vacation-section div.tst-search__date-picker.tst-search__date-picker--from.dateRange.tst-search__date-picker--ngbDropdown.bottom-left'
                          ).append('<div class="departureDateErrorMessage"><strong>Select a Departure Date</strong></div>');

                          $('.vacation-section .departureDateErrorMessage').addClass(
                            'tst-search__form--error-message--fh-departure-date color-red text-white'
                          );
                        }
                      } else {
                        if (returnDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            'div.tst-search__date-picker.tst-search__date-picker--to.tst-search__date-picker--ngbDropdown.vacationLeaveDatePicker.dateRange.bottom-left'
                          ).append('<div class="returnDateErrorMessage"><strong>Select a Return Date</strong></div>');

                          $('.vacation-section .returnDateErrorMessage').addClass('tst-search__form--error-message color-red text-white');
                        }
                      }

                      if (!context.mq.mobile) {
                        if (context.mq.tablet) {
                          $('.vacation-section .tst-icon.tst-icon--map-marker').css('bottom', '2.8rem');
                          $('.vacation-section div.search-btn').css('margin', '0.5rem 0 0.5rem 0.5rem');
                        }
                      } else {
                        $('#toLocation .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css(
                          'bottom',
                          '2.8rem'
                        );
                      }

                      break;
                    }
                    case TST_WIDGET_LABELS.HOTEL_TAB:
                    case TST_WIDGET_LABELS.VACATION_RENTALS: {
                      if (value.id === 'rangeDatePickerFrom-hotel') {
                        if (departureDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            '.hotel-section div.tst-search__date-picker.tst-search__date-picker--from.dateRange.tst-search__date-picker--ngbDropdown.bottom-left'
                          ).append('<div class="departureDateErrorMessage"><strong>Select a Check-In Date</strong></div>');

                          $('.hotel-section .departureDateErrorMessage').addClass(
                            'tst-search__form--error-message--fh-departure-date color-red text-white'
                          );
                        }
                      } else {
                        if (returnDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            'div.tst-search__date-picker.tst-search__date-picker--to.tst-search__date-picker--ngbDropdown.hotelCheckinDatePicker.dateRange.bottom-left'
                          ).append('<div class="returnDateErrorMessage"><strong>Select a Check-Out Date</strong></div>');

                          $('.hotel-section .returnDateErrorMessage').addClass('tst-search__form--error-message color-red text-white');
                        }
                      }
                      break;
                    }
                    case TST_WIDGET_LABELS.CAR_TAB: {
                      if (value.id === 'rangeDatePickerFrom-car') {
                        if (departureDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            '.car-section div.tst-search__date-picker.tst-search__date-picker--from.dateRange.tst-search__date-picker--ngbDropdown.bottom-left'
                          ).append('<div class="departureDateErrorMessage"><strong>Select a Pick-up Date</strong></div>');

                          $('.car-section .departureDateErrorMessage').addClass(
                            'tst-search__form--error-message--c-departure-date color-red text-white'
                          );
                        }
                      } else {
                        if (returnDateErrorMessage.length === 1) {
                          break;
                        } else {
                          $(
                            'div.tst-search__date-picker.tst-search__date-picker--to.tst-search__date-picker--ngbDropdown.carPickupDatePicker.dateRange.bottom-left'
                          ).append('<div class="returnDateErrorMessage"><strong>Select a Drop-off Date</strong></div>');

                          $('.car-section .returnDateErrorMessage').addClass('tst-search__form--error-message--c color-red text-white');
                        }
                      }

                      if (!context.mq.mobile) {
                        if (context.mq.desktop) {
                          $('.activity-section div.search-btn').css('margin', '0.5rem 0 0rem 0.5rem');
                          $('.activity-section div.search-btn').css('margin', '0.5rem 0 0rem 0.5rem');
                        }
                      }
                      break;
                    }
                    case TST_WIDGET_LABELS.ACTIVITY_TAB: {
                      if (value.id === '') {
                        // inject going to error message
                        if (goingToErrorMessage.length === 1) {
                          break;
                        } else {
                          $('.activity-section div.location').append(
                            '<div class="goingToErrorMessage"><strong>Select a Destination or Activity</strong></div>'
                          );

                          $('.activity-section .goingToErrorMessage').addClass('tst-search__form--error-message color-red text-white');
                        }
                      }

                      if (!context.mq.mobile) {
                        if (context.mq.tablet) {
                          $('.activity-section div.search-btn').css('margin', '0.5rem 0 2.5rem 0.5rem');
                          $('.location .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css(
                            'bottom',
                            '2.8rem'
                          );
                          $('.monthYear .tst-icon.tst-icon--calendar.tst-icon--suffix.calendar.ng-star-inserted').css('bottom', '3rem');
                          $('.monthYear .tst-icon.tst-icon--chevron-down').css('bottom', '3rem');
                        } else if (context.mq.desktop) {
                          $('.activity-section div.search-btn').css('margin', '0.5rem 0 0rem 0.5rem');
                          $('.location .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css(
                            'bottom',
                            '0.5rem'
                          );
                          $('.monthYear .tst-icon.tst-icon--calendar.tst-icon--suffix.calendar.ng-star-inserted').css('bottom', '0.5rem');
                          $('.monthYear .tst-icon.tst-icon--chevron-down').css('bottom', '0.5rem');
                        }
                      } else {
                        $('.location .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css('bottom', '2.5rem');
                      }
                      break;
                    }
                  }
                });

                // set the listeners

                return false;
              }
              return true;
            });
          }
        },
        this
      );
  }

  /**
   * add/remove classes if form is valid/invalid
   */
  public validateForm(targetElement: HTMLFormElement): boolean {
    // Set the class for all form elements
    // which are not being watched by angular.
    Array.from<HTMLFormElement>(targetElement.form.elements as any)
      .filter((el) => {
        return !Array.from(el.attributes).some((a) => a.name.startsWith('_ngcontent'));
      })
      .forEach((el) => {
        if (el.checkValidity()) {
          el.classList.remove('invalid');
          el.classList.remove('showErrorMessage');
        } else {
          el.classList.add('invalid');
          el.classList.add('showErrorMessage');
        }
      });

    const rangeDatePickerFrom = this.activeForm.tstForm.querySelectorAll('#rangeDatePickerFrom-' + this.activeForm.type);

    const rangeDatePickerTo = this.activeForm.tstForm.querySelectorAll('#rangeDatePickerTo-' + this.activeForm.type);

    if (this.activeForm.type !== TST_WIDGET_LABELS.CRUISE_TAB) {
      if (!targetElement.className.includes('showErrorMessage')) {
        if (targetElement.id === '') {
          $('.goingToErrorMessage').remove();
          if (this.activeForm.type !== TST_WIDGET_LABELS.ACTIVITY_TAB) {
            if (!this.mq.mobile && rangeDatePickerFrom.length == 0) {
              if (this.mq.tablet) {
                $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');
              } else if (this.mq.desktop) {
                $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');
                $('.' + this.activeForm.type + '-section div.search-btn').css('margin', '1rem 0 1rem auto');
              }
            } else if (!this.mq.desktop && rangeDatePickerFrom.length == 1) {
              $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');
              $('.' + this.activeForm.type + '-section div.search-btn').css('margin', '1rem 0 1rem auto');
            } else if (this.mq.tablet) {
              $('.' + this.activeForm.type + '-section .tst-icon.tst-icon--map-marker').css('bottom', '0.4rem');
            }
          } else if (this.mq.tablet && this.activeForm.type === TST_WIDGET_LABELS.ACTIVITY_TAB) {
            $('.activity-section div.search-btn').css('margin', '0.5rem 0 0rem 0.5rem');
            $('.location .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css('bottom', '0.4rem');
            $('.monthYear .tst-icon.tst-icon--calendar.tst-icon--suffix.calendar.ng-star-inserted').css('bottom', '0.5rem');
            $('.monthYear .tst-icon.tst-icon--chevron-down').css('bottom', '0.5rem');
          } else if (this.mq.mobile && this.activeForm.type === TST_WIDGET_LABELS.ACTIVITY_TAB) {
            $('.location .tst-icon.tst-icon--map-marker.tst-icon--prefix.map-marker.ng-star-inserted').css('bottom', '0.5rem');
          }
        } else if (targetElement.id === 'rangeDatePickerFrom-' + this.activeForm.type) {
          $('.departureDateErrorMessage').remove();
        } else if (targetElement.id === 'rangeDatePickerTo-' + this.activeForm.type) {
          $('.returnDateErrorMessage').remove();
        }
      }
    }

    // Check if one of the date pickers is empty
    // if yes, inject tooltip and dateempty classes

    if (this.activeForm.type !== TST_WIDGET_LABELS.PACKAGE_TAB && this.activeForm.type !== TST_WIDGET_LABELS.ACTIVITY_TAB) {
      if (rangeDatePickerFrom[0].value == '' && rangeDatePickerTo[0].value != '') {
        rangeDatePickerFrom[0].classList.add('dateEmpty');
        rangeDatePickerFrom[0].classList.add('showErrorMessage');
      } else if (rangeDatePickerFrom[0].value != '' && rangeDatePickerTo[0].value == '') {
        rangeDatePickerTo[0].classList.add('dateEmpty');
        rangeDatePickerTo[0].classList.add('showErrorMessage');
      }
    }

    // Set the form class
    if (targetElement.form.checkValidity()) {
      targetElement.form.classList.remove('invalid');
      targetElement.form.classList.remove('showErrorMessage');
      return true;
    } else {
      targetElement.form.classList.add('invalid');
      targetElement.form.classList.add('showErrorMessage');
      return false;
    }
  }

  /**
   * Autofills all destination fields of tst widget forms of autocomplete selection's types
   * or removes the autofill if autocomplete if cleared
   * and calls to validate suggestedTypes of selected location
   */
  private portLocationfieldValueToTST(location, validate: boolean = false) {
    let destinationField = null;
    const apRegex = REGEX.extractAirportCode;
    const remove: boolean = location && location.name ? false : true;
    const types = !remove && location.types.length ? _.union(location.types, TST_AC_DEFAULT_TYPES) : this.selectedLocationTypes;
    const name = !remove && location.name ? location.name : this.selectedLocationName;
    _.each(
      types,
      (type: any) => {
        const selectorPrefix = '.' + type + '-section ';
        destinationField = this.tstService.getDestinationFieldElement(type, this.container);
        switch (type) {
          case TST_WIDGET_LABELS.PACKAGE_TAB: {
            _.each(
              destinationField,
              (d: any) => {
                if (d.value == name) d.selected = !remove ? true : false;
              },
              this
            );
            break;
          }

          case TST_WIDGET_LABELS.DYNAMIC_TAB:
          case TST_WIDGET_LABELS.HOTEL_TAB:
          case TST_WIDGET_LABELS.VACATION_RENTALS:
          case TST_WIDGET_LABELS.CAR_TAB: {
            if (hasValue(destinationField)) {
              destinationField.value = !remove ? name : '';
            }
            break;
          }
          case TST_WIDGET_LABELS.CRUISE_TAB: {
            if (hasValue(location && hasValue(location.secondaryType))) {
              destinationField = this.tstService.getDestinationFieldElement(type, this.container);
            } else {
              destinationField = this.tstService.getDestinationFieldElement(type, this.container, true);
            }
            _.each(
              destinationField,
              (d: any) => {
                if (d.value == name) d.selected = !remove ? true : false;
              },
              this
            );
            break;
          }
          case TST_WIDGET_LABELS.FLIGHT_TAB: {
            let code;
            if (hasValue(destinationField)) {
              destinationField.value = !remove ? name : '';
              if (apRegex.test(name)) {
                code = destinationField.value.match(apRegex);
              }
              this.airportCode = code;
            }
            break;
          }
          case TST_WIDGET_LABELS.ACTIVITY_TAB: {
            const form = this.container.nativeElement.querySelector(selectorPrefix + ' form');

            if (hasValue(form) && hasValue(this.selectedLocation))
              form.setAttribute(
                'action',
                '//albertateachers.tstllc.net/activity/' +
                  this.selectedLocation.destinationID +
                  '/' +
                  this.selectedLocation.productID +
                  '/price'
              );
            if (hasValue(destinationField) && destinationField.value) destinationField.value = !remove ? name : '';
            break;
          }
        }
        /* destinationField && destinationField.addEventListener('blur', e => {
					this.destinationFieldChange(e, remove);
				}); */
      },
      this
    );
    validate && this.mq.mobile && this.validateLocation(location);
  }

  private validateLocation(location) {
    /**
     * For UX purposes we have default product types for any location (TST_AC_DEFAULT_TYPES).
     * location.suggestedTypes that arent the actual location.types recieved from api call, are assumed to be invalid.
     * We might have to warn customer that the location chosen will be invalid for those defaults types and they have to choose a valid one.
     * Without doing another api call to validate the field they are warned to update, and since we can't hook into the already created
     * 'selected' event (via TST's jquery-ui autocomplete), to validate assumptions are made:
     * - if they are typing, ignoring backspaces, and chars more than 2, we listen for the "enter" keyup or
     * - they click an autocomplete choice (ul.ui-autocomplete) and the field has at least one word
     * After, we have to update the universal autoComplete in tstAutoComplete component...
     */
    let destinationField;
    const invalidate = location ? _.difference(location.suggestedTypes, location.types) : null;
    const regex = REGEX.oneOrMoreWord;
    if (invalidate && invalidate.length) {
      _.each(invalidate, (item: any) => {
        destinationField = this.tstService.getDestinationFieldElement(item, this.container);
        const endpoint = _.find(AUTOCOMPLETE_ENDPOINTS, (ae) => {
          return ae.name != 'flight' && ae.name == this.activeForm.type;
        });

        if (item != 'flight') {
          destinationField.classList.add('invalidLocation');
          destinationField.setAttribute('data-location-valid', false);
          destinationField.setCustomValidity('Please select a valid location');

          if (destinationField) {
            const eventData = {
              endpoint: endpoint,
              field: destinationField,
              component: this,
              regex: regex,
              select: false,
              location: location,
              acElems: document.querySelectorAll('body > ul.ui-autocomplete'),
            };
            // these 2 event listeners will determine if the tst widget's autocomplete reselection are valid
            $(destinationField).on('keyup', eventData, (e) => {
              e.preventDefault();
              const dis = e.data.component;
              dis.liveLocationField = e.currentTarget;
              dis.liveAutoCompleteList = _.filter(e.data.acElems, (el: any) => {
                return el.children.length;
              });
              if (e.key == 'ArrowDown') {
                dis.liveAutoCompleteLocation = dis.liveAutoCompleteList[0].querySelector('#ui-active-menuitem');
              }
              if (
                e.data.field.value.length > 2 &&
                e.key == 'Enter' // &&
                //  e.key != 'Backspace'
              ) {
                dis.checkValidateLocation(e);
              }
            });

            _.each($(eventData.acElems), (ul) => {
              $(ul).on('mouseup', eventData, (e) => {
                e.preventDefault();
                const dis = e.data.component;
                dis.liveAutoCompleteLocation = e.target;
                dis.liveAutoCompleteList = e.target.parentElement;
                dis.checkValidateLocation(e);
              });
            });
          }
        }
      });
    } else {
      !invalidate && location ? this.resetLocationValidation(location.suggestedTypes) : this.resetLocationValidation(TST_PRODUCT_TYPES);
    }
  }

  public checkValidateLocation(event) {
    const field = event.data.field;
    const regex = event.data.regex;
    // let text = field.value;
    let select = false;
    this.chosenTerm = regex.test(this.liveAutoCompleteLocation.innerText) ? this.liveAutoCompleteLocation.innerText : false;
    select = this.chosenTerm ? true : false;
    select && this.setLocationValidationField(this.liveLocationField, event);
  }

  public setLocationValidationField(field, event = null) {
    const fld = event ? event.data.field : field;
    if (fld) {
      fld.setAttribute('data-location-valid', true);
      fld.classList.contains('invalidLocation') && fld.classList.remove('invalidLocation');
      this.validateForm(fld);
      fld.setCustomValidity('');
    }
    let location =
      this.chosenTerm &&
      _.find(
        this.cachedLocations.data,
        (loc: any) => {
          return this.chosenTerm == loc.name;
        },
        this
      );

    location =
      !location && event
        ? { name: this.chosenTerm, types: event.data.location.suggestedTypes }
        : location
        ? Object.values({ name: location.name, types: location.types })
        : this.selectedLocation;

    this.updatedLocation.emit(location);
    this.portLocationfieldValueToTST(location);
  }

  private resetLocationValidation(types) {
    /** if they go back, or clear universal autocomplete */
    let destinationField;
    types.length &&
      _.each(types, (t: any) => {
        if (t != 'flight') {
          destinationField = this.tstService.getDestinationFieldElement(t, this.container);
          this.setLocationValidationField(destinationField);
        }
      });
  }

  public destinationFieldChange(event, remove) {
    if (event.target) {
      if (event.target.value) {
        this.destinationFieldCleared.emit(false);
      } else {
        this.destinationFieldCleared.emit(true);
      }
    }
  }

  public autoFillDestinations() {
    if (!this.defaultDepartureAirport) return;

    // autopopulate departure fields with nearest city if applicable to form
    // if (this.nearestCentre && this.nearestCentre.airportCity) {
    const airport = _.find(
      this.airports,
      (a: any) => {
        return this.defaultDepartureAirport ? a.airportCode == this.defaultDepartureAirport : false;
      },
      this
    );

    airport &&
      _.each(
        this.mappedFields,
        (a: any) => {
          let targetField;
          switch (a.type) {
            case TST_WIDGET_LABELS.PACKAGE_TAB:
              targetField = a.form
                ? a.form.querySelector(
                    '[name=' + a.fields.depart.control.name + ']' // tslint:disable-next-line:indent
                  )
                : null;

              if (targetField) {
                targetField.value = airport.city;
              }
              break;
            case TST_WIDGET_LABELS.HOTEL_TAB:
            case TST_WIDGET_LABELS.VACATION_RENTALS:
            case TST_WIDGET_LABELS.DYNAMIC_TAB:
            case TST_WIDGET_LABELS.CAR_TAB:
              targetField = a.form
                ? a.form.querySelector(
                    '[name=' + a.fields.depart.control.name + ']' // tslint:disable-next-line:indent
                  )
                : null;

              if (targetField && !targetField.value)
                // hotel deeplink api doesnt port over occupancy if location is airport location
                targetField.value =
                  a.type == TST_WIDGET_LABELS.DYNAMIC_TAB || a.type == TST_WIDGET_LABELS.CAR_TAB ? airport.airport : airport.hotelLocation;
              break;
          }
        },
        this
      );
  }

  /**
   * TST bug hack: Modify data attribute of departure to only lookup airports for autocomplete.
   * Using values set in TST_MAP_FIELDS in models/tst.ts
   */
  public removeNonAirportACResults() {
    let targetField; // input field to change
    let target; // selector input field to change
    let form; // selector of form
    let dataAttr; // data attribute name or targetField
    let data; // value of dataAttr
    const val: string = '["airports"]';
    const vacFields = _.find(this.mappedFields, (field) => {
      return field.type == TST_WIDGET_LABELS.DYNAMIC_TAB;
    });

    if (vacFields) {
      form = vacFields.formSelector;
      target = form + ' input[name=' + vacFields.fields.depart.control.name + ']';
      targetField = target ? $(target) : null;
      dataAttr = vacFields.fields.depart.dataAttr;
    }

    if (targetField) {
      data = dataAttr ? $(targetField).data(dataAttr) : null;
      data && $(targetField).attr('data-tst-autocomplete-types', val);
    }
  }

  /**
   * Similiar to validateLocation() but validation is a bit different
   */
  public activityValidation() {
    const regex = REGEX.twoOrMoreWord;
    let targetField;
    const formConfig = _.find(this.bannerConfig, (item) => {
      return item.type == TST_WIDGET_LABELS.ACTIVITY_TAB || item.type == 'activity';
    });
    /**
     * add event listener to disable button if invalid activity choice
     */
    const btn =
      formConfig && formConfig.submitButton && formConfig.tstForm
        ? formConfig.submitButton
        : formConfig.tstForm.querySelector("button[type='submit']");
    $(btn).on('click', { component: this }, (e) => {
      const dis = e.data.component;
      let valid = true;
      if (dis.disableSubmit) {
        e.preventDefault();
        valid = false;
      } else {
        e.currentTarget.disabled = false;
        valid = true;
      }

      return valid;
    });
    /**
     * get meta data object for activities
     */
    const actvty = _.find(this.mappedFields, (field) => {
      return field.type == TST_WIDGET_LABELS.ACTIVITY_TAB || field.type == 'activity';
    });

    if (actvty) {
      targetField = actvty.form ? actvty.form.querySelector(actvty.fields.depart.control.class) : null;

      if (targetField) {
        this.liveLocationField = targetField;
        /** first input to know they've typed something,
         * the live autocomplete list, which comes from typing,
         * and add invalid css classes */
        $(this.liveLocationField).on('input', { component: this }, (e) => {
          const component = e.data.component;
          this.setActivityValidity(e.target);
          /** after typing all autocomplete lists should be available,
           * set the live highlighted item,
           * capture mouseup to lock-in the selection */
          _.each(
            $(component.liveAutoCompleteList),
            (ul: any, i) => {
              this.addListeners(
                'mouseup mouseover',
                ul,
                'mouseupAddListenerActivity',
                {
                  regex: regex,
                  component: component,
                  targetElement: e.target,
                  index: i,
                },
                'ACMouseupActivity'
              );
            },
            this
          );

          /** If there is an autocomplete list,
           * and they use the keyboard to select
           * capture downarrow to get live selected item
           * and enter key to lock-in selection (but not backspace) */
          this.addListeners('keyup', e.target, 'keyupAddListenerActivity', { regex: regex, submitBtn: btn }, 'ACKeyupActivity');
          this.addListeners(
            'blur',
            e.target,
            'blurAddListenerActivity',
            {
              regex: regex,
              component: component,
              targetElement: e.target,
              submitBtn: btn,
            },
            'ACBlurActivity'
          );
        });
        $(this.liveLocationField).on('focus', { component: this, submitBtn: btn }, (e) => {
          this.liveAutoCompleteFocus = true;
          this.getAutoCompleteHighlight(e);
        });
      }
    }
  }

  /**
   * Any user interaction event check if the autocomplete input is a valid item from TST's ac api
   * validation is true if the highlighted ac item matches the ac input field. Timeout delay was
   * neccessary because if user blurs input it sometimes is too fast.
   * @param target current autoComplete input
   */
  private setActivityValidity(target = null) {
    clearTimeout(this.activityAutoCompleteSetValidityTimeout);

    const formConfig = _.find(this.bannerConfig, (item) => {
      return item.type == TST_WIDGET_LABELS.ACTIVITY_TAB;
    });
    /**
     * add event listener to disable button if invalid activity choice
     */
    const btn =
      formConfig && formConfig.submitButton && formConfig.tstForm
        ? formConfig.submitButton
        : formConfig.tstForm.querySelector("button[type='submit']");

    target = target ? target : this.liveLocationField ? this.liveLocationField : null;

    /**
     * if the highlighted autocomplete item matches the input, then valid
     */
    if (target) {
      if (
        this.liveAutoCompleteHighlighted &&
        this.liveLocationField &&
        this.liveAutoCompleteHighlighted.innerText == this.liveLocationField.value
      ) {
        target.classList.remove('invalidActivity');
        this.disableSubmit = false;
        btn.disabled = false;
      } else {
        target.classList.add('invalidActivity');
        this.disableSubmit = true;
        btn.disabled = true;
        this.activityAutoCompleteSetValidityTimeout = this.usingTimeout(
          window.setTimeout(() => {
            this.setActivityValidity(target);
          }, 60)
        );
      }
    }
  }

  /**
   * Look in the DOM for the ui-autocomplete that has children
   */
  public getAutoCompleteList(type: string = null) {
    clearTimeout(this.autoCompleteUIListTimeout);

    // #9683 - Please note that this only works for getting initial auto complete dropdonw list elements before it's being disposed
    const acList = document.querySelectorAll('body > ul.ui-autocomplete');
    if (acList && acList.length >= 8) {
      this.liveAutoCompleteList = acList;

      if (type == TST_WIDGET_LABELS.ACTIVITY_TAB) this.activityValidation();
    } else {
      this.autoCompleteUIListTimeout = this.usingTimeout(window.setTimeout(() => this.getAutoCompleteList(type), 250));
    }
  }

  /**
   * When user is typing in the field (in focus) keep record of the highlighted autocomplete item
   * If the user clicks the down or up arrow, force the highlighted ac item to be the input value
   */
  public getAutoCompleteHighlight(e) {
    const dis = e.data.component;
    clearTimeout(dis.autoCompleteHighlightTimeout);
    let hlgt;
    let liveList;
    const doNotSet = ['Delete', 'Backspace', 'Escape'];
    if (dis.liveAutoCompleteList) {
      liveList = _.find(dis.liveAutoCompleteList, (sf: any) => {
        return sf.childNodes.length;
      });
      if (liveList) hlgt = liveList.querySelector('#ui-active-menuitem');
    }

    if (hlgt) {
      dis.liveAutoCompleteHighlighted = hlgt;
      if (doNotSet.indexOf(dis.liveCurrentKey) < 0) {
        const _hlgt = Object.assign(hlgt, hlgt);
        if (_hlgt.innerText.length > 2 && dis.liveAutoCompleteDownKeyClicked == true) {
          e.target.value = _hlgt.innerText;
          dis.setActivityValidity(e.target);
        }
      }
    }

    if (dis.liveAutoCompleteFocus) {
      dis.autoCompleteHighlightTimeout = dis.usingTimeout(window.setTimeout(() => dis.getAutoCompleteHighlight(e), 20));
    }
  }

  /** _____________________________ Event Listeners && related functions
   * for manipulating TST's AutoComplete Api fields
   */

  /**
   * function for adding jQuery eventListeners without adding same listener it multiple times
   * original use-case was for adding listener on keyboard input
   * @param type event type
   * @param target element
   * @param check boolean
   * @param data data to pass to event {component:this, targetElement, index:if called from loop}
   * @param callback function to call when event occurs
   */
  public addListeners(type: string = null, target: any = null, check: string = null, data: any = {}, callback: string) {
    const idx = data.index ? data.index : '';
    if (!this.addListenerChecks[check + idx]) this.addListenerChecks[check + idx] = false;
    data.component = this;
    data.callback = callback ? callback : null;
    data.targetElement = data.targetElement ? data.targetElement : null;
    if (target && type) {
      if (!this.addListenerChecks[check + idx]) {
        this.addListenerChecks[check + idx] = true;
        $(target).on(type, { data }, data.component[data.callback]);
      }
    }
  }

  /**
   * AutoComplete mouseup for Activities
   * @param e event
   * @param data custom data
   */
  public ACMouseupActivity(e) {
    e.preventDefault();
    const data = e.data.data;
    data.component.liveAutoCompleteFocus = false;
    const liveItem = e.currentTarget.querySelector('#ui-active-menuitem');
    if (e.type == 'mouseup' && liveItem && data.component.liveAutoCompleteHighlighted && data.regex.test(liveItem.innerText))
      data.component.liveAutoCompleteHighlighted.innerText = liveItem.innerText;
    data.component.setActivityValidity();
  }
  /**
   * AutoComplete keyup for Activities
   * @param e event
   * @param data custom data
   */
  public ACKeyupActivity(e) {
    const data = e.data.data;
    data.component.liveCurrentKey = e.key;
    let liveList;
    let highlighted;
    data.component.liveAutoCompleteDownKeyClicked = false;

    /************************************************************************************************************************
		#9683 - https://amatravelonline.visualstudio.com/AMA%20Web%20Dev%20Project/_workitems/edit/9683/
		Created By: Dannie Yang
		Created On: May 24, 2019
		Description: The root cause of this issue is that once you leave the initial page where there is a tst widget the previous
					 auto complete elements are not being disposed. It creates a new set of auto complete elements and our code
					 is still looking for the previous/initial set of auto complete elements.
					 Therefore, the highlighted value (null) is not equal to what we selected in the auto complete dropdown list.
		Resolution: Dispose auto complete dropdown elements in NavigationEnd and retrieve auto complete dropdown list again in this
					method.
		*************************************************************************************************************************/

    // #9683 - We need to get auto complete dropdown elements at this point and assign to the component liveAutoCompleteList property
    const acList = document.querySelectorAll('body > ul.ui-autocomplete');
    if (acList && acList.length >= 8) {
      data.component.liveAutoCompleteList = acList;
    }

    if (data.component.liveAutoCompleteList.length && e.target.value.length > 2) {
      liveList = _.find(data.component.liveAutoCompleteList, (sf: any) => {
        return sf.childNodes.length;
      });

      if (liveList) highlighted = liveList.querySelector('#ui-active-menuitem');
      if (highlighted) data.component.liveAutoCompleteHighlighted = highlighted;

      if (e.key != 'Backspace') {
        e.preventDefault();
        if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
          // if arrow key up but arrowdown already true or just arrowdown
          if ((e.key == 'ArrowUp' && data.component.liveAutoCompleteDownKeyClicked) || e.key == 'ArrowDown') {
            data.component.liveAutoCompleteDownKeyClicked = true;
            if (liveList) highlighted = liveList.querySelector('#ui-active-menuitem');
            if (highlighted) data.component.liveAutoCompleteHighlighted = highlighted;
          }
        }

        if (e.key == 'Enter') {
          data.component.liveAutoCompleteLocation = e.target.value;
          data.component.setActivityValidity(e.target);
        }
      }
      if (e.key == 'Tab') {
        e.data.lastValue = e.target.value;
        const liveItem = liveList ? liveList.querySelector('#ui-active-menuitem') : null;
        if (liveItem) data.component.liveAutoCompleteHighlighted.innerText = liveItem.innerText;
        data.component.ACBlurActivity(e);
      }

      if (data.component.liveAutoCompleteHighlighted && e.target.value == data.component.liveAutoCompleteHighlighted.innerText) {
        data.component.liveAutoCompleteLocation = data.component.liveAutoCompleteHighlighted.innerText;
      }

      data.component.setActivityValidity(e.target);
    }
  }

  /**
   * AutoComplete blur for Activities
   * @param e event
   */
  public ACBlurActivity(e) {
    const data = e.data.data;
    data.component.setActivityValidity(e.target);
    data.component.liveAutoCompleteFocus = false;
  }

  /**
   * -- setupAutoFillTriggers(), triggerAutoFillEvents() triggerEvent() --
   * When we autofill departure cities based on nearest centre, we need to trigger the change
   * event on the autocomplete input so TST can update the relevent form elements
   * i.e. destinations are based on departure cities, default depart is calgary, but if nearest center is edmonton,
   * without the change event, some destinations might be invalid.
   * Need to get prepackaged field which isnt available
   * until TST loads completly
   * @param ppkg -> from TST_MAP_FIELDS
   */
  private setupAutoFillTriggers(ppkg) {
    clearTimeout(this.prepackageDepartOptionsSetupTimeout);

    const targetField = ppkg.form ? ppkg.form.querySelector(ppkg.fields.depart.control.class) : null;

    if (targetField) {
      this.triggerAutoFillEvents(targetField);
    } else {
      this.prepackageDepartOptionsSetupTimeout = this.usingTimeout(window.setTimeout(() => this.setupAutoFillTriggers(ppkg), 250));
    }
  }

  private triggerAutoFillEvents(elem) {
    clearTimeout(this.prepackageDepartOptionsTimeout);
    /**
     * when the select has options we know we can set the value and manually trigger change event
     */
    if (elem.children.length > 5 && this.ppkgDestEventCounter > 3) {
      this.triggerEvent(elem, 'change');
    } else {
      // yes, counter is neccessary
      this.ppkgDestEventCounter++;
      this.prepackageDepartOptionsTimeout = this.usingTimeout(window.setTimeout(() => this.triggerAutoFillEvents(elem), 300));
    }
  }

  private triggerEvent(elem, eventName: string = 'change') {
    /**
     * example taken from:
     * https://stackoverflow.com/questions/2856513/how-can-i-trigger-an-onchange-event-manually#answer-2856602
     */
    if ('createEvent' in document) {
      const evt = new Event(eventName, { bubbles: false, cancelable: true });
      elem.dispatchEvent(evt);
    } else elem.fireEvent('on' + eventName);
  }

  private autoXScroll() {
    const offsetLeft = document.getElementById('tab-' + this.activeTab.type);
    const container = document.getElementsByClassName('tst-search__track');

    if (offsetLeft && container) {
      container[0].scrollLeft = offsetLeft.offsetLeft - 120;
    }
  }

  /** _____________________________ Utility/Helpers */

  private updateMQ() {
    this.mq = {
      mobile: this.matchMediaService.matches(MEDIA_TYPE.TravelPhone),
      tablet: this.matchMediaService.matches(MEDIA_TYPE.TravelTablet),
      desktop: this.matchMediaService.matches(MEDIA_TYPE.Desktop),
    };
    this.isDatePickerTouchUi = !this.mq.mobile ? false : true;
  }

  private insertElementAfter(insertAftElement, elementToInsert, option: string = 'afterend') {
    let insertedElement: any = false;
    if (hasValue(insertAftElement) && hasValue(elementToInsert)) {
      insertedElement = insertAftElement.insertAdjacentElement(option, elementToInsert);
    }
    return insertedElement;
  }

  // addEventListener looks for function called "handleEvent" if passed an obj
  public handleEvent(e) {
    // cars needs a slight delay for some reason.
    if (e.target.form.id == 'car-form') {
      clearTimeout(this.gtmCarsTimout);
      this.gtmCarsTimout = window.setTimeout(
        // eslint-disable-next-line @typescript-eslint/unbound-method
        () => this.tstService.gtmTrackingCallback,
        20
      );
    }
    this.tstService.gtmTrackingCallback(e);
  }

  /**
   * End GTM
   */
}
