<ng-container *injector="{
  quoteOptions: quoteOptions$,
  productGroupFlow: productGroupFlow$,
  columnOptionState: { observable: columnOptionState$, initialValue: {} },
  isClubMember: isClubMember$,
  totalColumns: totalColumns$,
  comparisonTables: comparisonTables$,
  sumInsured: sumInsured$,
  policyProductSelected: policyProductSelected$
};
let quoteOptions = quoteOptions;
let productGroupFlow = productGroupFlow;
let columnOptionState = columnOptionState;
let isClubMember = isClubMember;
let totalColumns = totalColumns;
let comparisonTables = comparisonTables;
let matchMedia = matchMedia;
let sumInsured = sumInsured;
let policyProductSelected = policyProductSelected;
">
  <div [hidden]="quoteOptions?.length === 0 || (renew && policyProductSelected !== InsuranceProduct.MultiTripMedicalPlan)">
    <div class="row c-policy-matrix-header">
      <div class="col-12 mb-2">
        <h3 class="d-inline-block">
          <span class="mr-2">{{ 'policy_matrix.lead' | translate }}</span>
        </h3>
        <div class="d-flex flex-column flex-md-row">
          <a tabindex="-1"
             href="javascript:void(0)"
             (click)="showEligibilityRequirementsModal()">{{ 'policy_matrix.view_eligibility_requirements' | translate }}</a>
          <a class="ml-0 ml-md-2"
             href="{{ 'review_quote.policy_booklet_url' | translate }}"
             target="_blank">{{ 'policy_matrix.view_policy_book' | translate }}</a>
        </div>
        <span *ngIf="productGroupFlow === productGroup.VisitorsToCanadaFlow">
          {{ 'policy_matrix.vistors_canada_description' | translate }}
        </span>
      </div>
    </div>
    <section class="c-policy-matrix
           c-policy-matrix--{{comparisonTables?.tables?.length}}tbl-layout-{{totalColumns}}cols-total"
             [matchHeight]="['js-match-foot-height', 'js-match-title-height', 'js-match-foot-tooltip-height']"
             [matchHeightDesktopOnly]="true">
      <div class="c-policy-matrix__table c-policy-matrix__table--{{tbl.columnCount}}cols c-policy-matrix__table--ct-{{tbl.tableClass}}"
           [ngClass]="{
          'c-policy-matrix__table--blue': tbl.tableClass === 'single-trip',
          'c-policy-matrix__table--orange': tbl.tableClass === 'trip-cancellation',
          'c-policy-matrix__table--green': tbl.tableClass === 'multi-trip'
                            || tbl.tableClass ==='canada-only'
                            || tbl.tableClass === 'emergency-medical'
                            || tbl.tableClass === 'single-trip-premium'
        }"
           *ngFor="let tbl of comparisonTables?.tables; let i = index">
        <!-- Table header -->
        <header class="c-policy-matrix__table-head">
          <div class="c-policy-matrix__table-head-content">
            <h4 class="c-policy-matrix__table-head-title">{{ tbl.tableTitle | translate }}</h4>
          </div>
        </header>
        <div class="d-md-none">
          <ng-container *ngTemplateOutlet="footContent" />
        </div>

        <!-- Accordion -->
        <button type="button"
                class="c-policy-matrix__table-accordion"
                btnCheckbox
                btnCheckboxTrue="1"
                btnCheckboxFalse="0">
          <span class="h6">{{ 'policy_matrix.compare_these_plans' | translate }}</span>
          <svg-icon name="caret" />
        </button>

        <!-- Current Coverage Indicator -->
        <div *ngIf="productGroupFlow === productGroup.RepurchaseFlow && matchMedia.md">
          <div class="c-policy-matrix__table-row c-policy-matrix__table-row--current-coverage">
            <div class="c-policy-matrix__table-cell invisible">
            </div>
            <ng-container *ngFor="let col of tbl.columnDetails">
              <div class="c-policy-matrix__table-cell"
                   [class.invisible]="col.colProductCode !== policyProductSelected">
                <h4 class="h6">{{ 'policy_matrix.current_coverage' | translate }}</h4>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Table body -->
        <main class="c-policy-matrix__table-body">
          <!-- Row headings -->
          <div
               class="c-policy-matrix__table-row c-policy-matrix__table-row--title c-policy-matrix__table-row--{{tbl.columnCount}}cols js-match-title-height">
            <div class="c-policy-matrix__table-cell d-flex flex-column text-center">
              <h3 class="h4">{{ 'policy_matrix.policy_includes' | translate }}</h3>
              <small class="d-block d-md-none pt-2">{{ 'policy_matrix.mobile_info_text' | translate }}</small>
            </div>
            <div class="c-policy-matrix__table-cell"
                 *ngFor="let col of tbl.columnDetails">
              <h3 class="h4">{{ col.colTitle | translate }}</h3>
            </div>
          </div>
          <!-- Row content -->
          <div class="c-policy-matrix__table-row c-policy-matrix__table-row--feature c-policy-matrix__table-row--{{tbl.columnCount}}cols"
               *ngFor="let row of tbl.tableBodyRows; let k = index"
               [ngClass]="{'c-policy-matrix__table-row--hide-mobile': !row.rowData[1] && !row.rowData[2],
                           'c-policy-matrix__table-row--hide-desktop': (!comparisonTables?.tables[0]?.tableBodyRows[k].rowData[1] && !comparisonTables?.tables[0]?.tableBodyRows[k].rowData[2] && !comparisonTables?.tables[1]?.tableBodyRows[k].rowData[1] && !comparisonTables?.tables[1]?.tableBodyRows[k].rowData[2])}">
            <div class="c-policy-matrix__table-cell">
              <button type="button"
                      class="btn btn-link c-policy-matrix__table-desktop-help-icon"
                      [tooltip]="rowDataTooltip"
                      container="body"
                      (click)="row.rowData[0]?.modalText ? showToolTipModal(row.rowData[0].modalText) : null"
                      placement="right"
                      tabindex="-1">
                <svg-icon name="question_mark_circle" />
              </button>
              <span class="c-policy-matrix__table-desktop-label">{{ row.rowData[0].title }}</span>
              <button type="button"
                      class="btn btn-link c-policy-matrix__table-mobile-label"
                      [tooltip]="rowDataTooltip"
                      container="body"
                      (click)="row.rowData[0]?.modalText ? showToolTipModal(row.rowData[0].modalText) : null"
                      placement="right"
                      tabindex="-1">{{ row.rowData[0].title }}</button>
              <ng-template #rowDataTooltip>
                <div [innerHtml]="row.rowData[0].details | safeHtml"></div>
              </ng-template>
            </div>
            <div class="c-policy-matrix__table-cell"
                 *ngFor="let cellData of (row.rowData | slice: 1)">
              <ng-container *ngIf="cellData.applies && cellData.text else displayCheck">
                <div class="text-center"
                     (click)="cellData.focusOnClick ? focusOnAmountInput() : null"
                     [innerHtml]="cellData.text | safeHtml"></div>
                <ng-container
                              *ngIf="cellData.tooltip && !(cellData.product === InsuranceProduct.AnnualPremiumPackage && sumInsured <= 2500)">
                  <span>&nbsp;</span>
                  <i class="c-policy-matrix__table-cell-tooltip">
                    <button type="button"
                            class="btn btn-link m-0 p-0"
                            [tooltip]="cellDataTooltip"
                            container="body"
                            [placement]="'left'"
                            tabindex="-1">
                      <svg-icon name="question_mark_circle" />
                    </button>
                    <ng-template #cellDataTooltip>
                      <div [innerHtml]="cellData.tooltip | safeHtml"></div>
                    </ng-template>
                  </i>
                </ng-container>
              </ng-container>
              <ng-template #displayCheck>
                <svg-icon [hidden]="!cellData.applies"
                          name="checkmark" />
              </ng-template>
            </div>
          </div>
        </main>

        <ng-container *ngTemplateOutlet="footContent" />
        <!-- Table footer -->
        <ng-template #footContent>
          <footer class="c-policy-matrix__table-foot"
                  *ngIf="quoteOptions?.length">
            <!-- Current Coverage Indicator -->
            <div *ngIf="productGroupFlow === productGroup.RepurchaseFlow"
                 class="c-policy-matrix__table-row c-policy-matrix__table-row--{{tbl.columnCount}}cols c-policy-matrix__table-row--current-coverage">
              <div class="c-policy-matrix__table-cell invisible"></div>
              <ng-container *ngFor="let col of tbl.columnDetails">
                <div class="c-policy-matrix__table-cell"
                     [class.invisible]="col.colProductCode !== policyProductSelected">
                  <h4 class="h6">{{ 'policy_matrix.current_coverage' | translate }}</h4>
                </div>
              </ng-container>
            </div>
            <!-- Plans -->
            <div class="c-policy-matrix__table-row c-policy-matrix__table-row--{{tbl.columnCount}}cols js-match-foot-height">
              <div class="c-policy-matrix__table-cell position-relative">
                <p *ngIf="i === 0 && !columnOptionState[tbl.columnDetails[0]?.colProductCode]">
                  <b class="c-policy-matrix__table-cell-label--not-eligible">
                    <label>
                      {{ 'policy_matrix.total_cost' | translate }}:</label>
                  </b>
                </p>
              </div>
              <div class="c-policy-matrix__table-cell"
                   *ngFor="let col of tbl.columnDetails; let j = index">
                <!-- DEBUG: <button class="btn btn-sm"
                        *ngIf="columnOptionState[col.colProductCode]"
                        title="{{ columnOptionState[col.colProductCode] | json }}"><small>Quote Options (hover)</small></button> -->
                <div class="c-policy-matrix__table-price-info"
                     [ngClass]="{'c-policy-matrix__table-price-info--not-eligible': !columnOptionState[col.colProductCode]}">
                  <ng-container *ngIf="columnOptionState[col.colProductCode]; else not_eligible">
                    <ng-container *ngIf="columnOptionState[col.colProductCode]?.hasMemberDiscount">
                      <p>
                        <strong>{{ col.colSummaryTitle | translate }}</strong>
                        <span
                              *ngIf="isClubMember"><s><b>{{ columnOptionState[col.colProductCode]?.nonMemberPrice | currency }}</b></s></span>
                      </p>
                      <p>
                        <strong *ngIf="isClubMember">{{insertTenant(isClubMember, col)}} {{ col.colSummarySubtitle | translate }}</strong>
                        <b><label *ngIf="i === 0 && j === 0">{{ 'policy_matrix.total_cost' | translate }}:</label>
                          {{ columnOptionState[col.colProductCode]?.totalPrice | currency }}
                        </b>
                      </p>
                    </ng-container>
                    <ng-container
                                  *ngIf="!columnOptionState[col.colProductCode]?.hasMemberDiscount && columnOptionState[col.colProductCode]?.hasPromoDiscount">
                      <p>
                        <strong>{{ col.colSummaryTitle | translate }}</strong>
                        <span
                              *ngIf="!isClubMember"><s><b>{{ columnOptionState[col.colProductCode]?.nonPromoPrice | currency }}</b></s></span>
                      </p>
                      <p>
                        <strong *ngIf="!isClubMember">{{insertTenant(isClubMember, col)}} {{ col.colSummarySubtitle | translate }}</strong>
                        <b><label *ngIf="i === 0 && j === 0">{{ 'policy_matrix.total_cost' | translate }}:</label>
                          {{ columnOptionState[col.colProductCode]?.totalPrice | currency }}
                        </b>
                      </p>
                    </ng-container>
                    <ng-container
                                  *ngIf="!columnOptionState[col.colProductCode]?.hasPromoDiscount && !columnOptionState[col.colProductCode]?.hasMemberDiscount">
                      <p>
                        <strong>{{ col.colSummaryTitle | translate }}</strong>
                        <b><label *ngIf="i === 0 && j === 0">{{ 'policy_matrix.total_cost' | translate }}:</label>
                          {{ columnOptionState[col.colProductCode]?.totalPrice | currency }}
                        </b>
                      </p>
                    </ng-container>
                  </ng-container>
                  <ng-template #not_eligible>
                    <p innerHTML="{{ 'policy_matrix.not_eligible' | translate }}">
                    </p>
                  </ng-template>
                  <button type="button"
                          analytic-ama="link"
                          class="btn btn-sm"
                          id="plan-{{col.colProductCode}}-submit"
                          (click)="openEligibilityDialog(col.colProductCode);"
                          [disabled]="!columnOptionState[col.colProductCode]">{{ col.colButtonText | translate }}
                  </button>
                  <!-- {{columnOptionState[col.colProductCode] | json }} -->
                </div>
              </div>
            </div>
          </footer>
          <footer class="c-policy-matrix__table-foot c-policy-matrix__table-foot--hidden "
                  *ngIf="quoteOptions?.length">
            <div class="c-policy-matrix__table-row c-policy-matrix__table-row--{{tbl.columnCount}}cols js-match-foot-tooltip-height">
              <div class="c-policy-matrix__table-cell c-policy-matrix__table-cell--hidden"></div>
              <div class="c-policy-matrix__table-cell"
                   [ngClass]="{'c-policy-matrix__table-cell--hidden': !(col.colProductCode === InsuranceProduct.AnnualPremiumPackage && sumInsured > 2500)}"
                   *ngFor="let col of tbl.columnDetails; let j = index">
                <div *ngIf="col.colProductCode === InsuranceProduct.AnnualPremiumPackage && sumInsured > 2500"
                     role="tooltip"
                     class="c-policy-matrix__custom-tooltip tooltip in tooltip-bottom bs-tooltip-bottom bottom show">
                  <div class="c-policy-matrix__custom-tooltip--arrow tooltip-arrow arrow"></div>
                  <div class="tooltip-inner"
                       [innerHtml]="'trip_details.annual_premium.sum_insured_tooltip' | translate | safeHtml">
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </ng-template>
      </div>
    </section>
  </div>
  <a id="planMatrix"></a>
</ng-container>
