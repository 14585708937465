/* eslint-disable max-lines */
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { select, NgRedux, dispatch } from '@angular-redux-ivy/store';
import { Observable, combineLatest } from 'rxjs';
import { map, tap, distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { isEqual } from 'underscore';
import { IQuoteOption, ITripDetails, IAppState, ICurrentQuoteDetails, ICountry, ISubRegion } from 'src/app/models/data.interfaces';
import {
  InsuranceProduct,
  ProductGroup,
  multiTripProductIsSelected,
  productGrouping,
  medicalOnlyProductIsSelected,
  CANADA_ONLY_CODE,
} from 'src/app/models/products';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { ProductsService } from 'src/app/core/services/products.service';
import { IProduct } from 'src/app/models/app-config.model';
import { fetchQuoteOptions, setQuoteOptions } from 'src/app/store/trip-details-quote-option-retrieval.middleware';
import { updateCurrentQuoteDetails, updateCurrentQuoteDeductible } from 'src/app/store/current-quote-details.reducer';
import { addYears, addDays } from 'date-fns';
import { TravellerConditionsComponent } from '../traveller-conditions/traveller-conditions.component';
import { BaseComponent } from '../base-component.component';
import { CountriesService } from 'src/app/core/services/countries.service';
import { SalesFunnelActionType, IUpdateQuoteIdAction } from 'src/app/store/reducer';
import { EligibilityRequirementsModalComponent } from '../policy-matrix/eligibility-requirements-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SingleTripComponent } from '../coverage-type/single-trip/single-trip.component';
import { NgForm, NgModel } from '@angular/forms';
import { Track } from 'src/app/models/track.enum';
import { QuoteService } from 'src/app/core/services/quote.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';
import { firstValueFrom } from 'rxjs';

@Unsubscribe()
@Component({
  selector: 'review-quote',
  templateUrl: './review-quote.component.html',
  styleUrls: ['./review-quote.component.scss'],
})
export class ReviewQuoteComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('travellerConditions') travellerConditions: TravellerConditionsComponent;
  @ViewChild('rvdSingleTrip') rvdSingleTrip: SingleTripComponent;
  @ViewChild('f') form: NgForm;
  @ViewChild('countrySelectedModel') countrySelectedModel: NgModel;

  @select(['tripDetails']) readonly tripDetails$: Observable<ITripDetails>;
  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<string>;
  @select(['quoteOptions']) readonly quoteOptions$: Observable<[IQuoteOption]>;
  @select(['currentQuoteDetails', 'planDays']) readonly planDays$: Observable<number>;
  @select(['currentQuoteDetails']) readonly currentQuoteDetails$: Observable<ICurrentQuoteDetails>;
  minDepartureDate: Date = new Date();
  maxDepartureDate: Date = addYears(this.minDepartureDate, 2);

  highlights: { title: string; desc: string }[];

  insuranceProduct = InsuranceProduct;
  productGroup = ProductGroup;

  public countrySelected: ICountry;
  public subRegionSelected: ISubRegion;

  destinationSelected$: Observable<string>;
  provinceSelected$: Observable<string>;

  quoteSelected: IQuoteOption;
  dateFormat: string;
  deductibleAmounts: number[];
  coverageDurations: number[];
  product: IProduct;
  multiTripProductIsSelected: boolean;
  medicalOnlyProductIsSelected: boolean;
  preExistingConditionsSupported: boolean;
  countries: ICountry[] = [];

  // Current Quote Optioos
  planDays = null;
  topUpDays = null;
  deductibleAmount?: number;
  // tirpDetails Loaded On Load
  durationOfFirstTrip: number;
  selectedProduct: string;
  multiTripEndDate: Date;
  showLoading: boolean;
  showDeductableText: boolean;

  renew: boolean;
  skipTravellerInfo: boolean;

  modalRef: BsModalRef;
  constructor(
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private configService: ClubConfigurationService,
    private productService: ProductsService,
    private quoteService: QuoteService,
    private modalService: BsModalService,
    private countriesService: CountriesService
  ) {
    super();
    this.destinationSelected$ = this.tripDetails$.pipe(
      map((a) => {
        let code = a && a.countrySelected && a.countrySelected.code;

        if (code == null) {
          return '';
        }

        if (a.subRegionSelected != null) {
          code = code + '|' + a.subRegionSelected.code;
        }
        return code;
      })
    );
    this.provinceSelected$ = this.tripDetails$.pipe(
      map((a) => {
        const code = a && a.subRegionSelected && a.subRegionSelected.code;

        return code || '';
      })
    );

    this.using(this.activatedRoute.data.subscribe((d) => (this.renew = d.renew))).attach(this);

    this.countries = this.countriesService.getCountries();

    this.dateFormat = this.configService.appConfig.i18n.en.calendar_date_display_format || 'MM/dd/yyyy';

    const unfilteredHighlights: { title: string; desc: string; flows: string[] }[] =
      (this.configService.appConfig.i18n.en.review_quote || {}).coverage || [];

    this.using(
      this.productGroupFlow$.subscribe((flow) => {
        this.highlights = unfilteredHighlights.filter((a) => a.flows.indexOf(flow) !== -1);
      })
    ).attach(this);

    this.using(
      this.tripDetails$.subscribe((tripDetails) => {
        this.durationOfFirstTrip = tripDetails.durationOfFirstTrip;
        this.selectedProduct = tripDetails.productSelected;
        if (tripDetails.policyStartDate) {
          this.multiTripEndDate = addDays(addYears(tripDetails.policyStartDate, 1), -1);
        }
      })
    ).attach(this);

    const policyInfo = this.reduxStore.get((x) => x.policyInfo);
    this.skipTravellerInfo = policyInfo.track === Track.FastTrack && !policyInfo.policyModified;

    this.using(
      combineLatest([this.quoteOptions$, this.planDays$]).subscribe(([quotesOptions, planDays]) => {
        this.updateCoverageDurations(quotesOptions);
        if (!this.selectedProduct) {
          return;
        }

        this.product = this.configService.appConfig.products[this.selectedProduct];
        this.multiTripProductIsSelected = multiTripProductIsSelected(this.product.code);
        this.medicalOnlyProductIsSelected = medicalOnlyProductIsSelected(this.product.code);

        this.preExistingConditionsSupported = this.productService.doesProductSupportPreExistingConditions(this.product.code);

        if (this.product.code === InsuranceProduct.VisitorsToCanadaMedicalPlan) {
          const sumInsured = this.reduxStore.getState().currentQuoteDetails.sumInsured;
          if (sumInsured) {
            this.quoteSelected = quotesOptions.find((x) => x.sumInsured === sumInsured);
          }
        } else if (productGrouping(this.product.code) === ProductGroup.MultiTripFlow) {
          const reverseSorted = quotesOptions.filter((o) => o.productCode === this.product.code).sort((a, b) => b.planDays - a.planDays);
          for (let i = 0; i < reverseSorted.length; i++) {
            if (!planDays) {
              if (reverseSorted[i].planDays <= this.durationOfFirstTrip || i === reverseSorted.length - 1) {
                this.quoteSelected = reverseSorted[i];
                this.planDays = this.quoteSelected.planDays;
                this.topUpDays = this.durationOfFirstTrip - this.quoteSelected.planDays;
                if (this.topUpDays < 0) {
                  this.topUpDays = 0;
                }
                break;
              }
            } else if (reverseSorted[i].planDays === Number(planDays)) {
              this.quoteSelected = reverseSorted[i];
              this.planDays = planDays;
              this.topUpDays = this.durationOfFirstTrip - this.planDays;
              if (this.topUpDays < 0) {
                this.topUpDays = 0;
              }
              break;
            }
          }
        } else {
          this.quoteSelected = quotesOptions.find((x) => x.productCode === this.product.code);
          // overwrite TMI product name with our product name
          this.quoteSelected.productName = this.product.name;
        }

        this.showDeductableText = this.product ? this.productService.doesProductCodeHaveDeductibleDiscount(this.product.code) : false;
        this.selectedOption_OnChange(this.planDays, this.topUpDays, this.quoteSelected);
      })
    ).attach(this);
  }

  requestCompleted() {
    this.showLoading = false;
  }

  ngOnInit() {
    // Make sure to shallow copy the trip details otherwise the store will be updated.
    let cachedTripDetails = { ...this.reduxStore.getState().tripDetails };
    // any changes should destroy existing matrix.
    this.using(
      this.tripDetails$
        .pipe(
          distinctUntilChanged(),
          map((newTripDetails) => {
            // Shallow copy the trip details and ignore the productSelected
            newTripDetails = { ...newTripDetails };
            cachedTripDetails.productSelected = null;
            newTripDetails.productSelected = null;

            // Determines if quote options should be fetched.
            let shouldFetch = false;

            // Helper function for clearing options
            const clearOptions = () => {
              // RVD should never have a deductible set
              this.reduxStore.dispatch(updateCurrentQuoteDeductible(null));
              // Reset the quote options if the state is invalid
              this.reduxStore.dispatch(setQuoteOptions([]));
            };

            // reset fetched quote options whenever tripDetails criteria changes.
            if (newTripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow && this.rvdViewLoaded()) {
              // Get the current options
              const quoteOptions = this.reduxStore.getState().quoteOptions;
              const hasOptions = quoteOptions && quoteOptions.length >= 1;

              if (this.rvdStateValid() && (newTripDetails.countrySelected || newTripDetails.subRegionSelected)) {
                // Fetch new quote options when the state has changed or the quote options are missing
                if (!isEqual(newTripDetails, cachedTripDetails) || !hasOptions) {
                  clearOptions();
                  shouldFetch = true;
                }
              } else if (hasOptions) {
                clearOptions();
              }
            }

            cachedTripDetails = newTripDetails;
            return shouldFetch;
          }),
          // We only want to propagate the remaining chain during RVD.
          filter(() => cachedTripDetails.productGroupFlow === ProductGroup.RentalVehicleDamageFlow),
          tap((shouldFetch) => {
            // Show the loading indicator right away
            if (shouldFetch) {
              this.showLoading = true;
            } else {
              this.showLoading = false;
            }
          }),
          debounceTime(50),
          tap((shouldFetch) => {
            // Wait before fetching quote options
            if (shouldFetch) {
              this.reduxStore.dispatch(fetchQuoteOptions(true));
            }
          })
        )
        .subscribe()
    ).attach(this);

    // Load the deductible amount
    const currentQuote = this.reduxStore.getState().currentQuoteDetails;
    if (currentQuote) {
      this.deductibleAmount = currentQuote.deductibleAmount;
    }
  }

  ngAfterViewInit(): void {
    if (this.travellerConditions != null) {
      this.using(
        this.travellerConditions.newQuoteOptionsNeeded.subscribe(() => {
          // Fetch a new set of options
          this.showLoading = true;
          this.reduxStore.dispatch(fetchQuoteOptions(true));
        })
      ).attach(this);
    }
  }

  @dispatch() travellingDestinationSelected = (destinationCode: string) => {
    let tripDetailsState: any = {};

    const destination = destinationCode.split('|');
    const countryCode = destination[0];
    const subRegionCode = destination.length > 1 ? destination[1] : null;

    if (countryCode) {
      const countrySelected = this.countriesService.getCountryByCode(countryCode);
      tripDetailsState = {
        countrySelected: { code: countryCode, name: countrySelected.name },
        subRegionSelected: subRegionCode
          ? { code: subRegionCode, name: this.countriesService.getSubRegionByCode(countrySelected, subRegionCode).name }
          : null,
      };

      // When not in canada clear out the state of the canada only checkbox
      if (countryCode !== CANADA_ONLY_CODE) {
        tripDetailsState = {
          ...tripDetailsState,
          isTravellingOnlyInCanada: null,
        };
      }
    } else {
      tripDetailsState = {
        countrySelected: null,
        subRegionSelected: null,
        isTravellingOnlyInCanada: null,
      };
    }

    // Send the final payload
    return {
      type: SalesFunnelActionType.UPDATE_TRAVELLING_COUNTRY,
      tripDetailsState,
    };
  };

  updateCoverageDurations(quoteOptions: IQuoteOption[]) {
    if (this.selectedProduct) {
      const productConfig = this.configService.appConfig.products[this.selectedProduct];
      this.coverageDurations = productConfig.coverage_durations;

      // After loading a quote we will be limited to exactly 1 quote option.
      // The coverage durations should not show any additional options.
      if (this.coverageDurations && quoteOptions && quoteOptions.length > 0) {
        this.coverageDurations = this.coverageDurations.filter((duration) =>
          quoteOptions.find((option) => {
            return option.productCode === this.selectedProduct && option.planDays === duration;
          })
        );
      }
    }
  }

  @dispatch() selectedOption_OnChange(planDays: number, topUpDays: number, selectedOption: IQuoteOption) {
    this.requestCompleted();

    // Get the deductible amounts for the selected option
    if (selectedOption) {
      const productConfig = this.configService.appConfig.products[selectedOption.productCode];
      if (productConfig) {
        this.deductibleAmounts = productConfig.deductible_amounts || [];
        // Fallback to the top up if the main option doesn't have deductibles.
        // At the current moment this only happens to the Annual Premium Package plan.
        if (this.deductibleAmounts.length === 0 && selectedOption.linkedTopUp) {
          const topUpConfig = this.configService.appConfig.products[selectedOption.linkedTopUp.productCode];
          if (topUpConfig) {
            this.deductibleAmounts = topUpConfig.deductible_amounts || [];
          }
        }
      }
    }

    // If this option has already been saved we need to keep the flag so
    // further updates will not issue new quotes.
    const cur = this.reduxStore.getState().currentQuoteDetails;
    let saved = false;
    if (cur && cur.quoteOption && selectedOption && cur.quoteOption.id === selectedOption.id) {
      saved = cur.quoteOption.saved;
    }

    return updateCurrentQuoteDetails({
      planDays: planDays,
      topUpDays: topUpDays,
      quoteOption: {
        ...selectedOption,
        saved: saved,
      },
    });
  }

  onDeductibleChange(newDeductible: number) {
    this.deductibleAmount = newDeductible;

    // Update the deductible in the store
    this.reduxStore.dispatch(updateCurrentQuoteDeductible(newDeductible));

    // Fetch a new set of options
    this.showLoading = true;
    this.reduxStore.dispatch(fetchQuoteOptions(true));
  }

  onTravellerConditionsCheckChanging(): void {
    if (this.showLoading === false) {
      this.showLoading = true;
    }
  }

  setQuoteId = (quoteId: string): IUpdateQuoteIdAction => {
    return {
      type: SalesFunnelActionType.UPDATE_QUOTE_ID,
      payload: quoteId,
    } as IUpdateQuoteIdAction;
  };

  showEligibilityRequirementsModal() {
    this.modalRef = this.modalService.show(EligibilityRequirementsModalComponent);
  }
  openEligibilityDialog(event: any) {
    if (this.renew) {
      this.modalRef = this.modalService.show(
        // tslint:disable-next-line: no-use-before-declare
        EligibilityRequirementsModalComponent,
        {
          class: 'eligibility-dialog-modal',
          initialState: {
            afterFunction: () => {
              this.onSubmit(event);
            },
            forceDialog: true,
          },
          ignoreBackdropClick: true,
          backdrop: true,
        }
      );
    } else {
      this.onSubmit(event);
    }
  }
  onSubmit(event: any) {
    if (this.rvdViewLoaded()) {
      // Submit the parent form so the destination error will be shown
      this.form.onSubmit(event);

      // Submit the rvd single trip component so the date validation shows
      this.rvdSingleTrip.submit(event);

      // Check if the state is valid
      if (!this.rvdStateValid()) {
        return;
      }
    }

    if (!this.renew) {
      this.router.navigate(['traveller-info']);
      return;
    }

    if (!this.skipTravellerInfo) {
      this.router.navigate(['renew', 'traveller-info']);
      return;
    }

    this.showLoading = true;
    firstValueFrom(this.quoteService.saveQuote(this.reduxStore.getState(), true), undefined).then(
      (response: IAppState) => {
        this.reduxStore.dispatch(this.setQuoteId(response.quoteID));
        this.router.navigate(this.renew ? ['renew', 'payment'] : ['payment']);
        this.showLoading = false;
      },
      (reason) => {
        this.showLoading = false;
      }
    );
  }

  rvdStateValid() {
    return this.rvdViewLoaded() && this.rvdSingleTrip.isValid() && this.form.valid && this.countrySelectedModel.valid;
  }

  rvdViewLoaded() {
    return this.rvdSingleTrip && this.form && this.countrySelectedModel;
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }  
}
