<div class="full-modal-content">
  <div class="text-right u-ama-color--royal-blue d-flex justify-content-end mb-3 pr-4 pt-4">
    <a (click)="bsModalRef.hide()"
       href="javascript:void(0)"
       class="text-decoration-none font-weight-bold d-flex align-items-center">
      Close<span class="font-weight-normal times-icon ml-1">&times;</span>
    </a>
  </div>

  <div class="discount-modal-content">
    <div class="d-flex">
      <div class="row">
        <div class="col-9 text-center mx-auto">
          <img class="icon"
               [src]="iconSrc"
               alt="Icon" />
          <h2 class="mt-3 mb-2">{{title}}</h2>
          <p class="h6">{{subtitle}}</p>
          <div class="text-container"
               [innerHtml]="description | safeHtml">
          </div>
          <div class="text-container footnote font-italic mx-auto"
               [innerHtml]="footnote | safeHtml">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>