import { Component, ViewChildren, QueryList } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FunnelRoutes } from 'src/app/models/funnel-routes.enum';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, IPolicyInfo } from 'src/app/models/data.interfaces';
import { Track } from 'src/app/models/track.enum';
import { BaseComponent } from '../base-component.component';
import { ProductGroup } from 'src/app/models/products';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { Unsubscribe } from 'amaweb-tsutils';

interface ITabData {
  translation_key: string;
  path: string;
  analyticsClass: string;
  step: FunnelRoutes;
  active: boolean;
  /**
   * Some steps will hide the wizard if they are the active route.
   */
  hideWizardWhenActive?: boolean;
}

@Unsubscribe()
@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
})
export class WizardComponent extends BaseComponent {
  @ViewChildren(TabDirective) tabs: QueryList<TabDirective>;
  public tabData: ITabData[];
  public activeTabIndex: number;

  public visible: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private readonly reduxStore: NgRedux<IAppState>) {
    super();

    this.using(
      combineLatest([
        this.activatedRoute.data,
        this.reduxStore.select((x) => x.policyInfo),
        this.reduxStore.select((x) => x.tripDetails.productGroupFlow),
      ]).subscribe(([data, policyInfo, productGroupFlow]) => {
        this.tabData = this.generateTabData(data.renew, policyInfo, productGroupFlow);

        const currentTab = this.tabData.find((t: ITabData) => t.step === data.step);
        if (currentTab) {
          currentTab.active = true;
          this.visible = !currentTab.hideWizardWhenActive;
        } else {
          this.visible = false;
        }

        // What is the current active tab index?
        this.activeTabIndex = this.tabData.indexOf(currentTab);
      })
    ).attach(this);
  }

  navigate(target: string) {
    this.router.navigateByUrl(target);
  }

  private generateTabData(renew: boolean, policyInfo: IPolicyInfo, productGroupFlow: string): ITabData[] {
    const renewTrack = policyInfo.track === Track.FastTrack && !policyInfo.policyModified ? 'fast_track' : 'slow_track';
    const pathPrefix = renew ? '/renew/' : '/';
    const analyticsPrefix = renew ? 'renew-' : '';
    const rvd = productGroupFlow === ProductGroup.RentalVehicleDamageFlow;

    const tripDetails: ITabData = {
      translation_key: renew ? `renew.trip_details.${renewTrack}` : 'default.trip_details',
      path: `${pathPrefix}trip-details`,
      analyticsClass: `${analyticsPrefix}trip-details`,
      step: FunnelRoutes.tripDetails,
      active: false,
    };

    const reviewQuote: ITabData = {
      translation_key: 'default.review_quote',
      path: `${pathPrefix}review-quote`,
      analyticsClass: `${analyticsPrefix}review-quote`,
      step: FunnelRoutes.reviewQuote,
      active: false,
    };
    if (renew) {
      reviewQuote.translation_key = `renew.review_quote.${renewTrack}`;
    } else if (rvd) {
      reviewQuote.translation_key = 'rvd.review_quote';
    }

    const travellerInfo: ITabData = {
      translation_key: 'default.traveller_info',
      path: `${pathPrefix}traveller-info`,
      analyticsClass: `${analyticsPrefix}traveller-info`,
      step: FunnelRoutes.travellerInfo,
      active: false,
    };

    const payment: ITabData = {
      translation_key: 'default.payment',
      path: `${pathPrefix}payment`,
      analyticsClass: `${analyticsPrefix}payment`,
      step: FunnelRoutes.payment,
      active: false,
    };

    // Currently only used for renewal
    const retrievePolicy: ITabData = {
      translation_key: 'renew.retrieve_policy',
      path: '/renew/policy-details',
      analyticsClass: `renew-policy-details`,
      step: FunnelRoutes.retrievePolicy,
      hideWizardWhenActive: true,
      active: false,
    };

    // Finally generate the number of steps and in which order they show up.
    if (renew && policyInfo.track === Track.FastTrack && !policyInfo.policyModified) {
      return [retrievePolicy, reviewQuote, payment];
    } else if (rvd) {
      return [reviewQuote, travellerInfo, payment];
    } else {
      return [tripDetails, reviewQuote, travellerInfo, payment];
    }
  }
}
