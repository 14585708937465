import { ICountry } from './country.model';
import { ITripDetails } from './trip-details.model';

export enum InsuranceProduct {
  AnnualPremiumPackage = '1VPA',
  BounceBackInsurance = '1BBI',
  CanadaMedicalPlan = '1CPD',
  CanadaPackagePlan = '1CVD',
  MultiTripMedicalPlan = '1EMA',
  NonMedicalPremiumPackage = '1NVD',
  RentalVehicleDamageInsurance = '1RVD',
  SingleTripMedicalPlan = '1EMD',
  SingleTripPremiumPackage = '1VPD',
  TripCancellationAndTripInterruptionInsurance = '1TCI',
  VisitorsToCanadaMedicalPlan = '1VCD',
  TopUp = '1APT',
}

// Product Groups
/**
 * These ProductGroup enums are tied to TranslateUIFlowToProductGroup
 * ANY additions or changes WILL need to be changed on the server side as well.
 */
export enum ProductGroup {
  SingleTripFlow = 'SingleTripFlow',
  MultiTripFlow = 'MultiTripFlow',
  TCIFlow = 'TCIFlow',
  VisitorsToCanadaFlow = 'VisitorsToCanadaFlow',
  RentalVehicleDamageFlow = 'RentalVehicleDamageFlow',
  RepurchaseFlow = 'RepurchaseFlow',
}

export function requireMedicalQuestionnaire(tripDetails: ITripDetails): boolean {
  const flow = tripDetails.productGroupFlow;
  // During repurchase we should be looking at the previous policy selected
  let productSelected = tripDetails.productSelected;
  if (flow === ProductGroup.RepurchaseFlow && tripDetails.prevPolicyProductSelected) {
    productSelected = tripDetails.prevPolicyProductSelected;
  }

  return !(
    flow === ProductGroup.TCIFlow ||
    flow === ProductGroup.VisitorsToCanadaFlow ||
    flow === ProductGroup.RentalVehicleDamageFlow ||
    (flow === ProductGroup.RepurchaseFlow && productSelected === InsuranceProduct.AnnualPremiumPackage)
  );
}

// Used in the UI to trigger elements based on destination country selected.
export const CANADA_ONLY_CODE = 'CA';
export const CANADA_COUNTRY: ICountry = { code: CANADA_ONLY_CODE, name: 'Canada' };
/**
 * This Function returns the product group
 * In the policy matrix we need this to determin if we use planed days to filter the quotes
 * In trip details summary component this method is used to determin if we show departure/return date or policy start date and plan days
 * @param selectedProduct the currently selected product.
 */
export function productGrouping(selectedProduct: string): ProductGroup {
  switch (selectedProduct) {
    case InsuranceProduct.MultiTripMedicalPlan:
    case InsuranceProduct.AnnualPremiumPackage:
      return ProductGroup.MultiTripFlow;

    case InsuranceProduct.TripCancellationAndTripInterruptionInsurance:
      return ProductGroup.TCIFlow;

    case InsuranceProduct.SingleTripMedicalPlan:
    case InsuranceProduct.SingleTripPremiumPackage:
    case InsuranceProduct.CanadaMedicalPlan:
    case InsuranceProduct.CanadaPackagePlan:
      return ProductGroup.SingleTripFlow;

    default:
      return null;
  }
}
/**
 * Is Used to cue this UI to show the correct trip details fields
 * selectedProductGroup can not be used as the UI details are very current selected product specific.
 *
 * TODO: Review for supported products that are not yet defined.
 */
export function multiTripProductIsSelected(selectedProductCode: string): boolean {
  if (selectedProductCode) {
    return selectedProductCode === InsuranceProduct.MultiTripMedicalPlan || selectedProductCode === InsuranceProduct.AnnualPremiumPackage;
  }
  return false;
}

export function singleTripProductIsSelected(selectedProductCode: string): boolean {
  if (selectedProductCode) {
    return (
      selectedProductCode === InsuranceProduct.SingleTripMedicalPlan || selectedProductCode === InsuranceProduct.SingleTripPremiumPackage
    );
  }
  return false;
}

export function medicalOnlyProductIsSelected(selectedProductCode: string): boolean {
  if (selectedProductCode) {
    return selectedProductCode === InsuranceProduct.SingleTripMedicalPlan || selectedProductCode === InsuranceProduct.MultiTripMedicalPlan;
  }
  return false;
}
