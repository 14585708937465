import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { TooltipHelpers } from 'src/app/core/helpers/tooltip-helpers';
declare global {
  interface Window {
    MyNamespace?: any;
    opera?: any;
    MSStream?: any;
  }
}

@Directive({
  selector: '[checkMobile]',
  providers: [TooltipHelpers],
})
export class CheckMobileDirective implements OnInit {
  public tooltipHelpers: TooltipHelpers = new TooltipHelpers(this.renderer);

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.getMobileOS() === 'iOS') {
      // Add style to body to resolve potential issues with click events:
      // https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
      this.renderer.setStyle(document.body, 'cursor', 'pointer');
    }
  }

  @HostListener('window:touchstart', ['$event'])
  onTouchStart(event) {
    if (this.getMobileOS() === 'iOS') {
      // Dismiss ngx-bootstrap tooltips on iOS
      this.tooltipHelpers.hideTooltip(event);
    }
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   */
  getMobileOS(): string {
    // vendor may be deleted in future versions, but our logic is based on userAgent what is fine
    // eslint-disable-next-line deprecation/deprecation
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }
}
