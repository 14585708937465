<ng-container *injector="{quoteOptions: quoteOptions$}; let quoteOptions = quoteOptions;">
  <ng-container *ngIf="quoteOptions?.length">


    <div class="row c-visitors-matrix">
      <div class=" col-lg-6  col-md-12">
        <table class="table table-bordered c-visitors-matrix__table-benefits">
          <thead class="thead-light text-uppercase">
            <tr>

              <th class=" align-middle">
                <strong> {{ 'trip_details.visitors_to_canada.upsell_matrix.coverage_options' | translate }} </strong>
              </th>
              <th class="text-center  align-middle">
                <strong> {{ 'trip_details.visitors_to_canada.upsell_matrix.total_cost_for_all_travellers' | translate }} </strong>
              </th>
            </tr>
          </thead>
          <tbody>


            <tr *ngFor="let key of coverageOptionKeys"
                [ngClass]="{'c-visitors-matrix__recommended' : coverageOptions[key].recommended}">
              <ng-container *ngIf="coverageOptions[key].quotePrice">


                <td>
                  <div class="card-title  ">
                    <span class="lead font-weight-bold"> {{ coverageOptions[key].coverageAmount | currency:'CAD':'symbol-narrow':'1.0-0' }}
                    </span>

                    <span *ngIf="coverageOptions[key].recommended"
                          class="small text-success text-uppercase  font-weight-bold"> &nbsp;
                      {{ 'trip_details.visitors_to_canada.upsell_matrix.recommended_for_you' | translate }} </span>
                  </div>

                  <p class="card-text">{{ 'trip_details.visitors_to_canada.upsell_matrix.example_coverage' | translate }}
                    {{ coverageOptions[key].exampleCoverage }}
                  </p>
                  <div class=" font-weight-bold"> {{ 'trip_details.visitors_to_canada.upsell_matrix.covers' | translate }}
                    {{ coverageOptions[key].coverPercentage }}%
                    {{ 'trip_details.visitors_to_canada.upsell_matrix.of_typical_claims' | translate }}
                  </div>
                </td>

                <td class="text-center align-middle">
                  <ng-container *ngIf="coverageOptions[key].quotePrice > 0; else not_eligible">
                    <h4><strong> {{ coverageOptions[key].quotePrice | currency:'CAD':'symbol-narrow':'1.2-2' }}</strong></h4>
                    <button type="button"
                            analytic-ama="link"
                            class="btn btn-info"
                            id="plan-{{insuranceProduct.VisitorsToCanadaMedicalPlan}}-{{coverageOptions[key].coverageAmount}}-submit"
                            container="body"
                            (click)="openEligibilityDialog(coverageOptions[key].coverageAmount);"
                            placement="top"> {{ 'trip_details.visitors_to_canada.upsell_matrix.select' | translate }} </button>
                  </ng-container>
                  <ng-template #not_eligible>
                    <div class="h1 mb-1">&#9888;</div>
                    <p innerHTML="{{ 'policy_matrix.not_eligible' | translate }}">
                    </p>
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </tbody>

        </table>


      </div>

      <div class="  col-lg-6 col-md-12 ">

        <table class="table table-bordered c-visitors-matrix__table-fees">
          <thead class=" table-info ">

            <tr>
              <th>
                <h6 class=" text-uppercase">
                  {{ 'trip_details.visitors_to_canada.upsell_matrix.typical_medical_fees_in_Canada' | translate }}
                </h6>
                <span innerHtml="{{ 'trip_details.visitors_to_canada.upsell_matrix.estimated_costs_from' | translate  }}"> </span>
              </th>
              <th class="align-middle text-uppercase">
                <strong> {{ 'trip_details.visitors_to_canada.upsell_matrix.cost' | translate }} </strong>
              </th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let fee of medicalFees">
              <td>
                {{ fee.name}}
              </td>

              <td class="font-weight-bold">
                {{ fee.amount }}
              </td>
            </tr>

          </tbody>

        </table>

      </div>
    </div>
  </ng-container>
</ng-container>