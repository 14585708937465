import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
// Normally we use the app helper version of observable for consistency
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventModalComponent } from './components/event-modal/event-modal';
import { ClubConfigurationService } from './core/services/club-configuration.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';

// https://scotch.io/@kashyapmukkamala/using-http-interceptor-with-angular2
@Unsubscribe()
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private eventsShown: string[] = [];

  constructor(private modalService: BsModalService, private clubConfigurationService: ClubConfigurationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (e: HttpEvent<any>) => {
          if (e instanceof HttpResponse) {
            this.checkHeaders((e as HttpResponse<any>).headers);
          }
        },
        error: (e: any) => {
          if (e instanceof HttpErrorResponse) {
            this.checkHeaders((e as HttpErrorResponse).headers);
          }
        },
      })
    );
  }

  private checkHeaders(headers: HttpHeaders) {
    if (headers && headers.has('OSF-Web-Event')) {
      const eventID = headers.get('OSF-Web-Event');
      if (this.eventsShown.indexOf(eventID) === -1) {
        this.eventsShown.push(eventID);

        // Get the event configuration
        this.clubConfigurationService
          .getEventConfig()
          .subscribe((eventConfig) => {
            if (eventConfig) {
              this.modalService.show(EventModalComponent, {
                backdrop: true,
                ignoreBackdropClick: true,
                class: 'modal-dialog-centered',
                initialState: {
                  eventConfig,
                },
              });
            }
          })
          .attach(this);
      }
    }
  }
}
