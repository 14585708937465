import { Component, Input, OnInit } from '@angular/core';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'travel-deal-card',
  templateUrl: './travel-deal-card.component.html',
})
export class TravelDealCardComponent implements OnInit {
  @Input() travelDeal: any;
  constructor() {}
  ngOnInit() {}
}
