import { Renderer2, Injectable } from '@angular/core';

@Injectable()
export class TooltipHelpers {
  constructor(private renderer: Renderer2) {}

  /**
   * Created to assist in resolving iOS specific bug that
   * prevents tooltips from being closed:
   * https://github.com/twbs/bootstrap/issues/16028
   *
   * NOTE: This hack doesn't allow you to toggle the same tooltip, it only
   * allows you to hide the tooltip when the document is touched. You must
   * also implement the manual triggering of the ngx "toggle" method for
   * toggling to work:
   * https://valor-software.com/ngx-bootstrap/#/tooltip#triggers-manual
   */
  public hideTooltip(e: Event) {
    const tooltip = document.getElementsByClassName('tooltip')[0];
    // Make sure to ignore the custom tooltip in the policy matrix
    if (tooltip !== undefined && tooltip.classList.contains('show') && !tooltip.classList.contains('c-policy-matrix__custom-tooltip')) {
      this.renderer.addClass(tooltip, 'd-none');
    }
  }
}
