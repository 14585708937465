import _ from 'underscore';

/**
 * Determine if this object has a value. It cannot be undefined or null.
 */
export const hasValue = (value) => !_.isUndefined(value) && !_.isNull(value);

/**
 * Get a random element from the provided list.
 * Will return undefined if no element can be found.
 */
export const getRandomElement = (list) => {
  if (hasValue(list) && list.length > 0) {
    return list[Math.floor(Math.random() * list.length)];
  } else {
    return undefined;
  }
};

/**
 * Randomize the provided list.
 */
export const randomize = (list) => {
  if (hasValue(list) && list.length > 0) {
    return _.shuffle(list);
  } else {
    return list;
  }
};

/**
 * Get all names for a given enum type.
 */
export const getEnumValues = <TEnum>(enumType: any): TEnum[] => {
  return Object.keys(enumType)
    .filter((k: any) => isNaN(k))
    .map((k) => enumType[k] as TEnum);
};

import { Params } from '@angular/router';
/**
 * Get a params object from a query string. The new [routerLink] attribute
 * also uses [queryParams] which is expecting an object not a string.
 * @param query
 */
export const getQueryParams = (query: string): Params => {
  if (!query) return {};
  return _.extend(
    {},
    ..._.map(query.replace(/^\s*\?{0,1}/, '').split('&'), (kvp) => {
      const pair = kvp.split('=');
      const k = pair.length > 0 ? pair[0] : undefined;
      const v = pair.length > 1 ? pair[1] : undefined;
      return k ? { [k]: v } : {};
    })
  );
};

/**
 * Serialize the params into a query string. Will not perform any encoding
 */
export const serializeQueryParams = (params: Params): string => (params ? _.map(params, (k, v) => `${v}=${k}`).join('&') : '');

/**
 * The default date format used by date-fns/format function.
 */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
/**
 * Execute the function after it has been queued for longer than the timeout.
 * The timeout is restarted everytime the function is requeued.
 */
export const debounce = (func, timeout: number) => {
  let timeoutId: number = null;
  const cancel = () => {
    if (hasValue(timeoutId)) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
  return {
    /** Restart the timeout. */
    queue: () => {
      cancel();
      if (hasValue(func)) {
        timeoutId = window.setTimeout(func, timeout);
      }
    },
    /** Cancel the current timeout. */
    cancel: () => cancel(),
  };
};

import { DefaultUrlSerializer, UrlTree } from '@angular/router';

/**
 * parse the url to lowercase
 *
 * @export
 * @class LowerCaseUrlSerializer
 * @extends {DefaultUrlSerializer}
 */
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  public parse(url: string): UrlTree {
    const urlParts = url.split('?');
    if (urlParts.length > 0) {
      urlParts[0] = urlParts[0].toLocaleLowerCase();
      // Trim ending slashes
      if (urlParts[0].length > 1 && urlParts[0].endsWith('/')) {
        urlParts[0] = urlParts[0].slice(0, -1);
      }
    }
    return super.parse(urlParts.join('?'));
  }
}
