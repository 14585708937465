<!-- AIRPLANE FLYING COMPONENT -->
<airplane-flying />

<section class="c-loading-superfly"
         [ngClass]="{'c-loading-superfly--animate-window-shades' : animateWindowShades,
         'c-loading-superfly--infinite-animation' : doEasterEgg}"
         loadingSuperflyOverlay>
  <!-- WINDOW FRAMES -->
  <div class="c-loading-superfly__windows-wrap">
    <!-- WINDOW 1-->
    <div class="c-loading-superfly__window c-loading-superfly__window-1">
      <ng-container *ngTemplateOutlet="svgWnidowInterior" />
      <figure class="c-loading-superfly__window-shade"
              [ngClass]="{'c-loading-superfly__window-shade--slide-up' : !win1Position}"
              [ngStyle]="{'transform': win1Position}"
              (click)="win1Position = null">
        <ng-container *ngTemplateOutlet="svgWindowShade" />
      </figure>
      <ng-container *ngTemplateOutlet="svgWnidowExterior" />
      <div class="c-loading-superfly__drag-bounds"
           [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
           #win1Bounds>
        <div class="c-loading-superfly__drag-block"
             ngDraggable
             ngResizable
             (movingOffset)="setWindowShadePosition($event, 'window1')"
             [bounds]="win1Bounds"
             [inBounds]="true">
        </div>
        <div class="c-loading-superfly__shade-conceal">
          <div class="c-loading-superfly__shade-conceal-inner">
            <ng-container *ngTemplateOutlet="svgWnidowInterior" />
            <ng-container *ngTemplateOutlet="svgWnidowExterior" />
          </div>
        </div>
      </div>
    </div>

    <!-- WINDOW 2-->
    <div class="c-loading-superfly__window c-loading-superfly__window-2">
      <ng-container *ngTemplateOutlet="svgWnidowInterior" />
      <figure class="c-loading-superfly__window-shade"
              [ngClass]="{'c-loading-superfly__window-shade--slide-up' : !win2Position}"
              [ngStyle]="{'transform': win2Position}"
              (click)="win2Position = null">
        <ng-container *ngTemplateOutlet="svgWindowShade" />
      </figure>
      <ng-container *ngTemplateOutlet="svgWnidowExterior" />
      <div class="c-loading-superfly__drag-bounds"
           [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
           #win2Bounds>
        <div class="c-loading-superfly__drag-block"
             ngDraggable
             ngResizable
             (movingOffset)="setWindowShadePosition($event, 'window2')"
             [bounds]="win2Bounds"
             [inBounds]="true">
        </div>
        <div class="c-loading-superfly__shade-conceal">
          <div class="c-loading-superfly__shade-conceal-inner">
            <ng-container *ngTemplateOutlet="svgWnidowInterior" />
            <ng-container *ngTemplateOutlet="svgWnidowExterior" />
          </div>
        </div>
      </div>
    </div>

    <!-- WINDOW 3-->
    <div class="c-loading-superfly__window c-loading-superfly__window-3">
      <ng-container *ngTemplateOutlet="svgWnidowInterior" />
      <figure class="c-loading-superfly__window-shade"
              [ngClass]="{'c-loading-superfly__window-shade--slide-up' : !win3Position}"
              [ngStyle]="{'transform': win3Position}"
              (click)="win3Position = null">
        <ng-container *ngTemplateOutlet="svgWindowShade" />
      </figure>
      <ng-container *ngTemplateOutlet="svgWnidowExterior" />
      <div class="c-loading-superfly__drag-bounds"
           [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
           #win3Bounds>
        <div class="c-loading-superfly__drag-block"
             ngDraggable
             ngResizable
             (movingOffset)="setWindowShadePosition($event, 'window3')"
             [bounds]="win3Bounds"
             [inBounds]="true">
        </div>
        <div class="c-loading-superfly__shade-conceal">
          <div class="c-loading-superfly__shade-conceal-inner">
            <ng-container *ngTemplateOutlet="svgWnidowInterior" />
            <ng-container *ngTemplateOutlet="svgWnidowExterior" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- RIBBON COMPONENT -->
<section class="c-loading-superfly-ribbon u-user-select--none"
         (click)="toggleEasterEgg()"
         [ngClass]="{'c-loading-superfly-ribbon--pulse-animation' : animateRibbon}">
  <div class="c-ribbon c-ribbon--do-zoom-in">
    <div class="c-ribbon__arrow c-ribbon__arrow-left">
      <div class="c-ribbon__arrow-tip c-ribbon__arrow-tip-top"></div>
      <div class="c-ribbon__arrow-tip c-ribbon__arrow-tip-bottom"></div>
    </div>

    <div class="c-ribbon__shadow c-ribbon__shadow-left"></div>

    <div class="c-ribbon__main">
      <div class="c-ribbon__main-text">
        <p class="h3 mb-1">Loading your quote</p>
        <small class="h6 font-weight-normal">{{ displayMessage }}</small>
      </div>
    </div>

    <div class="c-ribbon__shadow c-ribbon__shadow-right"></div>

    <div class="c-ribbon__arrow c-ribbon__arrow-right">
      <div class="c-ribbon__arrow-tip c-ribbon__arrow-tip-top"></div>
      <div class="c-ribbon__arrow-tip c-ribbon__arrow-tip-bottom"></div>
    </div>
  </div>
</section>

<!-- Window SVG Images -->
<ng-template #svgWnidowExterior>
  <figure class="c-loading-superfly__window-exterior">
    <svg viewBox="0 0 247.137 334.97967">
      <defs>
        <style>
          .win-ext-1 {
            fill: url(#win-ext-linear-gradient);
          }
    
          .win-ext-2 {
            fill: url(#win-ext-linear-gradient-2);
          }
    
          .win-ext-3 {
            opacity: 0.55;
          }
    
          .win-ext-4 {
            fill: #869389;
          }
        </style>
        <linearGradient id="win-ext-linear-gradient" x1="266.54945" y1="90.27177" x2="-9.38569" y2="249.583" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#b0c1bc"/>
          <stop offset="1" stop-color="#e3ebe5"/>
        </linearGradient>
        <linearGradient id="win-ext-linear-gradient-2" x1="128.58253" y1="4.54314" x2="128.58253" y2="335.3297" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#e3ebe5"/>
          <stop offset="1" stop-color="#b0c1bc"/>
        </linearGradient>
      </defs>
      <g id="Window">
        <g>
          <path class="win-ext-1"
                d="M249.67772,83.29423C242.26515,9.94585,197.78135,2.43744,128.582,2.43744c-69.19832,0-113.68436,7.50841-121.09562,80.85679C5.014,108.70706,5.014,151.44473,5.014,169.92656c0,18.48276,0,61.2208,2.47238,86.63376,7.41126,73.34868,51.8973,80.85679,121.09562,80.85679,69.19932,0,113.68312-7.50811,121.09569-80.85679,2.47331-25.413,2.47331-68.151,2.47331-86.63376C252.151,151.44473,252.151,108.70706,249.67772,83.29423ZM229.279,227.59873c-.264,4.50284-.51948,9.03361-1.16588,13.5022-2.046,14.14378-7.04718,30.3081-17.95924,42.20692-17.60814,19.20036-48.693,24.19421-73.37163,25.01286-31.42687,1.04253-68.20682-1.60307-90.55618-26.48707-21.55613-24.00087-18.78978-58.94475-20.01886-89.00109,0,0,.03592-50.289.49056-58.82014s.30016-21.35564,2.62528-38.721c1.61587-12.06793,7.01151-28.48919,20.56249-41.20252C77.16521,28.49543,128.08747,31.17,128.08747,31.17s48.19406-1.32759,71.60991,17.30678a77.85826,77.85826,0,0,1,20.31415,24.19c5.75574,10.86861,7.56481,23.16844,8.52885,35.25035,1.01667,12.74152,1.68971,25.51462,2.09354,38.28888q.03965,1.25439.07506,2.5089C231.45133,175.01345,230.81855,201.33816,229.279,227.59873Z"
                transform="translate(-5.01403 -2.43744)" />
          <path class="win-ext-2"
                d="M246.67494,84.3878c-7.22876-72.43023-50.6095-79.84466-118.09289-79.84466-67.48243,0-110.86536,7.41443-118.09284,79.84466-2.41107,25.09473-2.41107,67.29743-2.41107,85.54792,0,18.2514,0,60.45446,2.41107,85.54931,7.22748,72.43055,50.61041,79.84467,118.09284,79.84467,67.48339,0,110.86413-7.41412,118.09289-79.84467,2.412-25.09485,2.412-67.29791,2.412-85.54931C249.08693,151.68523,249.08693,109.48253,246.67494,84.3878ZM226.782,226.886c-.25745,4.44647-.5066,8.92053-1.137,13.33318-1.99524,13.96674-6.87243,29.92872-17.51391,41.67859-17.17151,18.96-47.4856,23.89136-71.55225,24.69977-30.64758,1.02948-66.5155-1.583-88.31067-26.15552-21.0216-23.70044-18.32385-58.20691-19.52246-87.887,0,0,.035-49.65949.4784-58.08386s.29272-21.08832,2.56018-38.23633C33.36012,84.31792,38.622,68.10222,51.837,55.548,63.31762,44.6414,78.68515,38.97652,93.95277,35.83073c15.753-3.24578,31.97281-3.71572,47.97975-2.55393,15.92529,1.15824,32.29325,3.46485,46.6961,10.76765,12.46928,6.32241,22.68182,17.54672,29.11576,29.84893,5.613,10.73256,7.37723,22.87843,8.31737,34.8091.99146,12.582,1.6478,25.19524,2.04162,37.8096q.03867,1.23869.0732,2.47749C228.90051,174.95893,228.28342,200.95413,226.782,226.886Z"
                transform="translate(-5.01403 -2.43744)" />
        </g>
        <g class="win-ext-3">
          <path class="win-ext-4"
                d="M126.52207,35.70025c4.88244,0,9.99426.19135,15.19287.56864,14.40057,1.04736,31.07458,3.10812,45.557,10.45129,11.1355,5.64612,21.27332,16.0571,27.814,28.56351,5.5332,10.57983,7.14154,22.94958,7.98505,33.65445.94275,11.96414,1.627,24.63732,2.03381,37.66745q.03845,1.23361.07288,2.46759c.67889,24.35441.22406,49.75045-1.39069,77.63942l-.01929.333c-.24371,4.21412-.49572,8.57174-1.09253,12.74933-1.66931,11.68557-5.93347,28.12632-16.76764,40.089-13.2536,14.634-36.613,22.613-69.42938,23.71528-3.63544.12208-6.98236.18146-10.23211.18146-22.8667,0-55.59595-2.62571-75.73346-25.32933-17.28229-19.48467-17.85633-46.67488-18.41144-72.96988-.09064-4.29192-.18427-8.72893-.35535-12.98532.00165-1.99753.04688-49.74826.47418-57.86731.10913-2.07388.18409-4.43823.26337-6.94141a292.29591,292.29591,0,0,1,2.27509-31.05932c1.1701-8.849,5.21325-25.67017,19.14477-38.90509,9.567-9.08872,22.86542-15.28858,40.655-18.95395a159.02442,159.02442,0,0,1,31.96265-3.06882h.00122m-.00318-3a161.32216,161.32216,0,0,0-32.5661,3.13052C78.68515,38.97653,63.3176,44.64144,51.837,55.548,38.622,68.10223,33.36014,84.31793,31.78433,96.2348c-2.26745,17.148-2.11669,29.81195-2.56018,38.23633s-.47839,58.08384-.47839,58.08384c1.19861,29.68013-1.49915,64.18659,19.52246,87.887,19.386,21.85639,49.90106,26.33866,77.97785,26.33866,3.48913,0,6.94519-.06936,10.33282-.18316,24.06665-.80841,54.38074-5.73975,71.55225-24.69975,10.64148-11.74988,15.51867-27.71187,17.51391-41.67862.63037-4.41263.87952-8.88667,1.137-13.33317,1.50141-25.93183,2.11847-51.927,1.39454-77.89638q-.03451-1.23885-.07319-2.47748c-.39379-12.61438-1.05017-25.2276-2.04162-37.80963-.94013-11.93067-2.70435-24.07654-8.31738-34.80914-6.4339-12.30216-16.64649-23.52649-29.11573-29.84888-14.40283-7.30283-30.77081-9.60941-46.6961-10.76767-5.12427-.37192-10.268-.5766-15.41364-.57654Z"
                transform="translate(-5.01403 -2.43744)" />
        </g>
      </g>
    </svg>
  </figure>
</ng-template>
<ng-template #svgWnidowInterior>
  <figure class="c-loading-superfly__window-interior">
    <svg viewBox="0 0 205.051 281.1093">
      <defs>
        <style>
          .win-int-1 {
            fill: #ced7d3;
          }
        </style>
      </defs>
      <g id="Window">
        <path class="win-int-1"
              d="M228.5259,96.97766c-6.271-62.623-63.507-69.58077-99.91421-69.58077-36.405,0-93.64,6.95776-99.91,69.58077-2.61394,26.61593-2.61394,55.83869-2.61394,70.97443,0,15.13412,0,44.35837,2.61394,70.97333,6.26994,62.626,63.50493,69.58077,99.91,69.58077,36.40722,0,93.64324-6.95473,99.91421-69.58077,2.61291-26.615,2.61291-55.83921,2.61291-70.97333C231.13881,152.81635,231.13881,123.59359,228.5259,96.97766Zm-8.74609,111.40109c-3.67578,84.71149-60.16022,83.3794-91.24323,83.3794-31.082,0-87.56537,1.33209-91.24218-83.3794-1.0019-16.81-1.16889-24.63232-1.16889-40.4414,0-15.811.167-23.63379,1.16889-40.44294,3.67681-84.71185,60.16021-83.37982,91.24218-83.37982,31.083,0,87.56745-1.332,91.24323,83.37982,1.0039,16.80915,1.16986,24.6319,1.16986,40.44294C220.94967,183.74643,220.78371,191.56876,219.77981,208.37875Z"
              transform="translate(-26.0878 -27.39689)" />
      </g>
    </svg>
  </figure>
</ng-template>
<ng-template #svgWindowShade>
  <svg viewBox="0 0 189.813 259.50989">
    <defs>
          <style>
            .win-shd-1 {
              fill: #bfccc7;
            }
      
            .win-shd-2 {
              fill: #c1cec9;
            }
      
            .win-shd-3 {
              fill: #c4cfcb;
            }
      
            .win-shd-4 {
              fill: #c6d1cd;
            }
      
            .win-shd-5 {
              fill: #c9d3cf;
            }
      
            .win-shd-6 {
              fill: #cbd5d1;
            }
      
            .win-shd-7 {
              fill: #ced7d3;
            }
      
            .win-shd-8 {
              fill: #e3ebe5;
            }
      
            .win-shd-9 {
              fill: url(#win-shd-linear-gradient);
            }
          </style>
          <linearGradient id="win-shd-linear-gradient" x1="124.48434" y1="446.99659" x2="124.48434" y2="452.87738" gradientTransform="matrix(1, 0, 0, -1, 0, 710.77493)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#ced7d3"/>
            <stop offset="1" stop-color="#b0c1bc"/>
          </linearGradient>
        </defs>
    <g id="Window">
      <g>
        <g>
          <path class="win-shd-1"
                d="M128.53689,279.0799c31.9218,0,89.9297,1.3955,93.70551-87.374,1.02929-17.6157,1.20169-25.8115,1.20169-42.3794,0-16.56881-.1724-24.7656-1.20169-42.37989-3.77581-88.7695-61.78371-87.374-93.70551-87.374s-89.9297-1.3955-93.7051,87.374c-1.0298,17.61429-1.2007,25.81108-1.2007,42.37989,0,16.5679.1709,24.7637,1.2007,42.3794C38.60719,280.4754,96.615,279.0799,128.53689,279.0799Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-2"
                d="M128.54186,277.91992c31.74838,0,89.44116,1.38785,93.19647-86.8994,1.02384-17.52,1.19509-25.67137,1.19509-42.14917,0-16.47889-.17125-24.63125-1.19509-42.14984C217.983,18.43411,160.29024,19.822,128.54186,19.822s-89.44127-1.38793-93.19623,86.89947c-1.024,17.51859-1.1941,25.671-1.1941,42.14984,0,16.4778.17008,24.62917,1.1941,42.14917C39.10059,279.30777,96.79337,277.91992,128.54186,277.91992Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-3"
                d="M128.54682,276.75993c31.575,0,88.95264,1.3802,92.68744-86.42479,1.01839-17.4243,1.18849-25.53124,1.18849-41.91894,0-16.389-.1701-24.4969-1.18849-41.91979-3.7348-87.8053-61.11247-86.42494-92.68744-86.42494S39.594,18.69111,35.85946,106.49641c-1.01823,17.42289-1.1875,25.53082-1.1875,41.91979,0,16.3877.16927,24.49464,1.1875,41.91894C39.594,278.14013,96.97176,276.75993,128.54682,276.75993Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-4"
                d="M128.55179,275.6c31.40155,0,88.4641,1.37255,92.1784-85.9502,1.013-17.3286,1.1819-25.3911,1.1819-41.6887,0-16.299-.16895-24.36255-1.1819-41.68974-3.7143-87.3232-60.77685-85.9504-92.1784-85.9504s-88.4644-1.3728-92.1785,85.9504c-1.01245,17.32719-1.1809,25.39069-1.1809,41.68974,0,16.2976.16845,24.3601,1.1809,41.6887C40.08739,276.9725,97.15014,275.6,128.55179,275.6Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-5"
                d="M128.55676,274.44c31.22813,0,87.97556,1.3649,91.66936-85.4756,1.0075-17.2329,1.1753-25.251,1.1753-41.45847,0-16.20913-.1678-24.2282-1.1753-41.45969-3.6938-86.8411-60.44123-85.47587-91.66936-85.47587s-87.976-1.36523-91.66963,85.47587c-1.00667,17.23149-1.1743,25.25056-1.1743,41.45969,0,16.2075.16763,24.22557,1.1743,41.45847C40.58079,275.80487,97.32852,274.44,128.55676,274.44Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-6"
                d="M128.56172,273.28c31.05472,0,87.487,1.35725,91.16034-85.001,1.002-17.1372,1.1687-25.11084,1.1687-41.22824,0-16.11921-.16665-24.09385-1.1687-41.22964-3.6733-86.359-60.10562-85.00134-91.16034-85.00134S41.07419,19.46211,37.401,105.82111c-1.00088,17.13579-1.1677,25.11043-1.1677,41.22964,0,16.1174.16682,24.091,1.1677,41.22824C41.07419,274.63723,97.50691,273.28,128.56172,273.28Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-7"
                d="M128.56669,272.12c30.8813,0,86.9985,1.3496,90.6513-84.5264.9966-17.0415,1.1621-24.9707,1.1621-40.998,0-16.0293-.1655-23.9595-1.1621-40.99959-3.6528-85.8769-59.77-84.5268-90.6513-84.5268s-86.9991-1.3501-90.6519,84.5268c-.9951,17.04009-1.1611,24.97029-1.1611,40.99959,0,16.0273.166,23.9565,1.1611,40.998C41.56759,273.4696,97.68529,272.12,128.56669,272.12Z"
                transform="translate(-33.63109 -19.57131)" />
        </g>
        <g>
          <path class="win-shd-8"
                d="M124.48409,257.89755H158.633c7.2862,0,8.7627,3.0322,4.9004,4.2627-6.9663,2.1494-22.85889,4.8134-39.0493,4.8134-16.1899,0-32.0825-2.664-39.0498-4.8134-3.8584-1.2305-2.3857-4.2627,4.8999-4.2627Z"
                transform="translate(-33.63109 -19.57131)" />
          <path class="win-shd-9"
                d="M124.48409,257.89755H158.633a13.76972,13.76972,0,0,1,5.4258.8686c-.1655.0698-.3335.1367-.5254.19919-6.9663,2.149-22.85889,4.813-39.0493,4.813-16.1899,0-32.0825-2.664-39.0498-4.813-.1909-.06149-.3599-.12939-.5244-.19919a13.76119,13.76119,0,0,1,5.4243-.8686Z"
                transform="translate(-33.63109 -19.57131)" />
        </g>
      </g>
    </g>
  </svg>
</ng-template>