import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TSTComponent } from './tst';
import { TstSearchComponent } from './tstSearch/tstSearch';
import { TSTAutoCompleteComponent } from './tstAutocomplete/tstAutocomplete';
import { TSTCustomFormComponent } from './tstCustomForm/tstCustomForm';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faShieldAlt } from '@fortawesome/pro-light-svg-icons/faShieldAlt';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faMale } from '@fortawesome/pro-light-svg-icons/faMale';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faBed } from '@fortawesome/pro-light-svg-icons/faBed';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlightFormDefs, TSTDatePicker } from 'src/app/models/tst/tst';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceModule } from 'src/app/core/services/services.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BGImageFallbackComponent } from './bgImageFallback/bgImageFallback';
import { FormStepperComponent } from './formStepper/formStepper';

library.reset();
library.add(
  faChevronDown,
  faCalendarAlt,
  faMapMarkerAlt,
  faShieldAlt,
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
  faMale,
  faTimes,
  faClock,
  faBed
);

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatIconModule,
    MatAutocompleteModule,
    MatExpansionModule,
    SharedModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgSelectModule,
    ServiceModule,
  ],
  declarations: [
    TSTComponent,
    TstSearchComponent,
    TSTCustomFormComponent,
    TSTAutoCompleteComponent,
    BGImageFallbackComponent,
    FormStepperComponent,
  ],
  entryComponents: [
    TSTComponent,
    TstSearchComponent,
    TSTCustomFormComponent,
    TSTAutoCompleteComponent,
    BGImageFallbackComponent,
    FormStepperComponent,
  ],
  exports: [
    TSTComponent,
    TstSearchComponent,
    TSTCustomFormComponent,
    TSTAutoCompleteComponent,
    BGImageFallbackComponent,
    FormStepperComponent,
  ],
  providers: [
    { provide: TSTDatePicker, useValue: new TSTDatePicker() },
    DatePipe,
    {
      provide: FlightFormDefs,
      useValue: new FlightFormDefs([
        {
          name: 'fromCity',
          label: 'From',
          control: null,
          type: 'text',
          sessionKey: 'fromCity',
          model: null,
          formGroup: null,
          required: true,
          open: false,
          elRef: null,
          hiddenElRef: null,
          hiddenField: null,
          placeHolder: 'Where are you leaving from?',
          autocomplete: true,
          airport: { label: null, code: null },
          datePicker: null,
          statusControl: null,
        },
        {
          name: 'datePickerLeave',
          label: 'Depart Date',
          dropdownTitleStart: 'Select Depart Date',
          control: null,
          type: 'date',
          sessionKey: 'departDate',

          model: null,
          formGroup: null,
          required: true,
          open: false,
          elRef: null,
          hiddenField: null,
          placeHolder: 'Choose Departure Date',
          airport: null,
          datePicker: {
            name: 'leaveDatePicker',
            matDatePicker: null,
            templateRef: null,
            replaceClass: 'leave-date',
            inputToUpdate: null,
            formControl: null,
          },
        },
        {
          name: 'datePickerRange',
          label: 'Depart & Return Date',
          dropdownTitleStart: 'Select Depart Date',
          dropdownTitleEnd: 'Select Return Date',
          control: null,
          type: 'date',
          sessionKey: 'returnDatePicker',
          model: null,
          formGroup: null,
          required: true,
          open: false,
          elRef: null,
          hiddenField: null,
          placeHolder: 'Choose departure and return dates',
          airport: null,
          datePicker: {
            name: 'rangeDatePicker',
            matDatePicker: null,
            templateRef: null,
            replaceClass: 'leave-date',
            replaceClassReturn: 'return-date',
            inputToUpdate: null,
            formControl: null,
          },
        },
        {
          name: 'toCity',
          label: 'To',
          control: null,
          model: null,
          type: 'text',
          sessionKey: 'toCity',
          formGroup: null,
          required: true,
          open: false,
          elRef: null,
          hiddenElRef: null,
          hiddenField: null,
          placeHolder: 'Where do you want to go?',
          autocomplete: true,
          airport: { label: null, code: null },
          datePicker: null,
          statusControl: null,
        },
        {
          name: 'numGuests',
          label: 'Travellers',
          control: null,
          type: 'select',
          formGroup: null,
          required: false,
          open: false,
          elRef: null,
          hiddenField: null,
          airport: null,
          datePicker: null,
          viewRef: null,
          moved: false,
          guests: {
            numAdultsControl: null,
            numChildrenControl: null,
            numSeniorsControl: null,
          },
        },
        {
          name: 'flightClass',
          label: '',
          control: null,
          type: 'select',
          formGroup: null,
          required: false,
          open: false,
          elRef: null,
          hiddenField: null,
          airport: null,
          datePicker: null,
        },
        {
          name: 'flightSubmit',
          label: 'Search Deals',
          control: null,
          type: 'submit',
          formGroup: null,
          required: false,
          open: false,
          elRef: null,
          hiddenField: null,
          airport: null,
          datePicker: null,
        },
        {
          name: 'formOptions',
          formControls: [
            {
              name: 'optionFlightRT',
              label: 'Round Trip',
              control: null,
              type: 'radio',
              formGroup: null,
              required: false,
              value: ['st', 'rt', 'Round+Trip'],
              selected: true,
              analytics: 'tst-widget-button*round-trip',
            },
            {
              name: 'optionFlightOW',
              label: 'One Way',
              control: null,
              type: 'radio',
              formGroup: null,
              required: false,
              value: ['st', 'ow', 'One+Way'],
              selected: false,
              analytics: 'tst-widget-button*one-way',
            },
            {
              name: 'optionFlightMC',
              label: 'Multi City',
              control: null,
              type: 'radio',
              formGroup: null,
              required: false,
              value: ['st', 'mc', 'Multi+City'],
              selected: false,
              analytics: 'tst-widget-button*multi-city',
            },
          ],
        },
        {
          name: 'addedFlights',
          control: null,
          type: 'formGroup',
          formGroup: null,
          required: true,
          open: false,
          elRef: null,
          hiddenField: null,
          airport: null,
          datePicker: null,
          flights: null,
        },
      ]),
    },
  ],
})
export class TSTModule {}
