import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StencilContentSidebarComponent } from './stencil-content-sidebar.component';

@NgModule({
  declarations: [StencilContentSidebarComponent],
  imports: [CommonModule],
  exports: [StencilContentSidebarComponent],
})
export class StencilContentSidebarModule {}
