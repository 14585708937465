<form #f="ngForm"
	  class="c-policy-details mx-auto"
	  (ngSubmit)="submit(f)"
	  id="policy-details"
	  analytic-ama="form"
	  connect="tripDetails"
	  *injector="{ 
		 info: travellerInfo$, 
		 details: tripDetails$
	};
  let info = info;
  let details = details;
  let matchMedia = matchMedia;
  ">
	<p class="h6 my-2">{{'renew_policy_details.page.title' | translate}}</p>

	<div class="border border-dark p-3 mt-3 mb-4 container">
		<div *ngIf="matchMedia.md"
			 class="policy-edit-section">
			<a id="edit-policy-submit"
			   (click)="editPolicy()"
			   href="javascript:void(0)">
				<i class="icon mr-1">
					<svg-icon name="edit" />
				</i>{{ 'renew_policy_details.page.edit_link' | translate }}
			</a>
		</div>

		<div class="mb-4">
			<h2 class="h3 mb-3">{{'renew_policy_details.page.header' | translate}}</h2>
			<span class="h5 c-form-label-tooltip">
				{{ 'renew_policy_details.page.plan_days_format' | translate | format:details?.durationOfFirstTrip:('product_names.' + details.productSelected | translate)}}
				<i class="c-form-label-tooltip__icon">
					<button type="button"
							class="btn btn-link m-0 p-0"
							[tooltip]="renewTooltip"
							auto-placement="right"
							container="body"
							tabindex="-1">
						<svg-icon name="question_mark_circle" />
					</button>
					<ng-template #renewTooltip>
						<div [innerHtml]="'renew_policy_details.tooltip.' + details.productSelected | translate | safeHtml"></div>
					</ng-template>
				</i>
			</span>
		</div>

		<div class="mb-4">
			<h3 class="mb-3">{{'renew_policy_details.page.travellers.header' | translate}}</h3>
			<div>
				<div class="row mb-3 mb-md-2">
					<div class="traveller-name">
						<span class="font-weight-bold">{{'renew_policy_details.page.travellers.primary' | translate}}</span>
						{{info.firstName}} {{info.lastName}}
					</div>
					<div class="traveller-dob">
						<span class="font-weight-bold">{{'renew_policy_details.page.travellers.dob' | translate}}</span>
						{{info.dateOfBirth | date:'mediumDate':'UTC'}}
					</div>
				</div>
				<div *ngFor="let other of info.otherTravellers; let i = index"
					 class="row mb-3 mb-md-2">
					<div class="traveller-name">
						<span class="font-weight-bold">
							{{'renew_policy_details.page.travellers.other_format' | translate | format:(i + 2 | ordinal)}}
						</span>
						{{other.firstName}} {{other.lastName}}
					</div>
					<div class="traveller-dob">
						<span class="font-weight-bold">
							{{'renew_policy_details.page.travellers.dob' | translate}}
						</span>
						{{other.dateOfBirth | date:'mediumDate':'UTC'}}
					</div>
				</div>
			</div>
		</div>

		<div class="traveller-details-section mb-4">
			<h3 class="mb-3">{{'renew_policy_details.page.primary_traveller.header' | translate}}</h3>
			<div class="mt-3 mt-md-0">
				<div class="col mb-3 mb-md-0">
					<label class="h6 d-block">{{'renew_policy_details.page.primary_traveller.address' | translate}}</label>
					{{info.addressLineOne}}<br />
					{{info.city}} {{info.provinceCode}} {{ info.postalCode | postalCodeFormat }}
				</div>
				<div class="col mb-3 mb-md-0">
					<label class="h6 d-block">{{'renew_policy_details.page.primary_traveller.phone_number' | translate}}</label>
					{{ info.phoneNumber | phoneNumberFormat }}
				</div>
				<div class="col">
					<label class="h6 d-block">{{'renew_policy_details.page.primary_traveller.email' | translate}}</label>
					{{info.emailAddress}}
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-4">
				<label class="h6">{{'renew_policy_details.page.renew_on' | translate}}</label>
				<div class="input-group">
					<div class="input-group__overlay-input">
						<button class="form-control border-0"
								type="button"
								id="btnStartDate"
								(click)="startDateIsOpen = !startDateIsOpen"
								[attr.aria-expanded]="startDateInput.isOpen">
							<svg-icon name="calendar" />
						</button>
					</div>
					<date-input #startDateInput="ngModel"
								[inputClass]="{ 'is-invalid': (f.submitted && startDateInput.invalid) }"
								[required]="true"
								ngControl
								[(ngModel)]="startDate"
								name="policyStartDate"
								[isOpen]="startDateIsOpen"
								(onHidden)="startDateIsOpen = false"
								[minDate]="details?.prevPolicyExpireDate | map:DateHelpers.repurchaseMinDate"
								tabindex="-1" />
					<div *ngIf="(f.submitted && startDateInput.invalid)"
						 class="invalid-feedback">
						<span *ngIf="startDateInput.errors?.required">{{ 'multi_trip.policy_start_date.required' | translate }}</span>
						<span *ngIf="startDateInput.errors?.minDate">{{ 'multi_trip.policy_start_date.min_date' | translate }}</span>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="matchMedia.sm_max"
			 class="mt-5">
			<a id="edit-policy-submit"
			   (click)="editPolicy()"
			   href="javascript:void(0)">
				<i class="icon mr-1">
					<svg-icon name="edit" />
				</i>{{ 'renew_policy_details.page.edit_link' | translate }}
			</a>
		</div>
	</div>

	<div *ngIf="premiumPlanUnavailable"
		 class="invalid-feedback d-block mb-3">
		<span>{{ 'renew_trip_details.over_60_premium_not_valid' | translate }}</span>
	</div>
	<button type="submit"
			id="renew-policy-submit"
			class="btn btn-block btn-lg btn-secondary col-md-5 mx-auto"
			[ngClass]="{'btn--loading': isLoading}">
		{{ 'renew_policy_details.page.renew_btn' | translate }}
	</button>
</form>