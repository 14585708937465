<form *injector="{
  tripDetails: tripDetails$,
  travellerInfo: travellerInfo$,
  validationState: { observable: validationState$, initialValue: {} }
};
let tripDetails = tripDetails;
let travellerInfo = travellerInfo;
let validationState = validationState;
let matchMedia = matchMedia;
"
      connect="tripDetails"
      #form="ngForm">
  <ul class="c-traveller-list c-traveller--med-survey-btn-right-desktopZZZ">

    <ng-template #agesOfTravellers="connectArrayFix"
                 connectArrayFix
                 let-index
                 connectArrayOf="agesOfTravellers">
      <li class="list-group-item c-traveller-list__item">
        <div class="row no-gutter c-traveller-list__item-inner">
          <div class="col c-traveller-list__age c-form-label-tooltip">

            <label class="c-traveller-list__primary-title h6 u-text-no-wrap font-weight-bold"
                   *ngIf="index === 0"><span>{{ 'trip_details.traveller_ages.primary_policy_holder.title' | translate }}</span>
              <i class="c-form-label-tooltip__icon">
                <button type="button"
                        class="btn btn-link m-0 p-0"
                        tooltip="{{ 'trip_details.traveller_ages.primary_policy_holder.tooltip' | translate }}"
                        [placement]="matchMedia.lg ? 'left' : 'top'"
                        container="body"
                        placement="top"
                        tabindex="-1">
                  <svg-icon name="question_mark_circle" />
                </button>
              </i>
            </label>

            <div *ngIf="data[index] && data[index].id && !existingTravellersAreEditable">
              <label class="h6 u-text-no-wrap font-weight-bold"
                     *ngIf="index > 0">
                <span>{{index + 1 | namedOrdinal | titlecase }} Traveller:</span>
              </label>
              <div class="mb-3">
                {{ data[index].firstName }} {{ data[index].lastName }}
              </div>
              <div>
                <strong>DOB: </strong>{{ data[index].dob | date:'mediumDate':'UTC' }}
              </div>
            </div>
            <div [hidden]="data[index] && data[index].id && !existingTravellersAreEditable">
              <label class="u-text-no-wrap">
                <span>{{ 'trip_details.traveller_ages.list.traveller_label' | translate }} </span>
                <span>{{ index + 1 }} </span>
                <span>({{ 'trip_details.traveller_ages.list.age_label' | translate }}):</span>
              </label>

              <input class="form-control"
                     id="travellerAge{{index}}"
                     [ngClass]="{ 'is-invalid': (travellersAge.invalid && form.submitted) }"
                     ngControl
                     ngModel
                     [connectArrayFixModel]="agesOfTravellers"
                     [connectArrayFixIndex]="index"
                     required
                     digitOnly
                     inputmode="numeric"
                     pattern="[0-9]*"
                     [min]="0"
                     [max]="tripDetails?.productGroupFlow === ProductGroup.VisitorsToCanadaFlow ? maxAgeForVisitors : maxAgeForTraveller"
                     name="age"
                     #travellersAge="ngModel"
                     type="number" />
            </div>
          </div>
          <div class="col-3 c-traveller-list__med-survey"
               [ngClass]="{'c-traveller-list__med-survey--show': validationState[index]?.questionnaireRequired}"
               *ngIf="validationState[index]?.questionnaireRequired">
            <button type="button"
                    [title]="validationState[index]?.buttonTitle | translate"
                    class="btn btn-block btn--icon-svg"
                    [ngClass]="validationState[index]?.buttonClass"
                    (click)="openModalWithComponent(index)"
                    tabindex="-1">
              <i>
                <svg-icon name="survey" />
              </i>
              <label>{{ validationState[index]?.buttonLabel | translate }}</label>
            </button>
          </div>
          <ng-container *ngIf="tripDetails?.numberOfTravellers > 1 && !(index == 0 && travellerInfo?.id && !existingTravellersAreEditable)">
            <div class="
             col-2
             c-traveller-list__remove">
              <button type="button"
                      class="btn btn-link trip-details-remove-traveller"
                      analytic-ama="form"
                      title="{{ 'trip_details.traveller_ages.list.remove_traveller_label' | translate }} {{ 'trip_details.traveller_ages.list.traveller_label' | translate }} {{index + 1}}"
                      (click)="removeTraveller(index)"
                      tabindex="-1">
                <i>
                  <svg-icon name="minus" />
                </i>
                <label class="pl-1">{{ 'trip_details.traveller_ages.list.remove_traveller_label' | translate }}</label>
              </button>
            </div>
          </ng-container>
        </div>
        <div class="row c-traveller-list__errors">
          <div class="col">
            <div *ngIf="(travellersAge.invalid && form.submitted)"
                 class="invalid-feedback">
              <span *ngIf="travellersAge.errors?.required">{{ 'trip_details.traveller_age.required' | translate }}</span>
              <span *ngIf="travellersAge.errors?.min">{{ 'trip_details.traveller_age.less_than_zero' | translate }}</span>
              <span
                    *ngIf="travellersAge.errors?.max">{{ (tripDetails?.productGroupFlow === ProductGroup.VisitorsToCanadaFlow ? 'trip_details.traveller_age.visitor_exceed_max_age' : 'trip_details.traveller_age.exceed_max_age') | translate }}</span>
              <span
                    *ngIf="travellersAge.errors?.questionnaire_needed">{{ 'trip_details.traveller_age.questionnaire_needed' | translate }}</span>
              <span *ngIf="travellersAge.errors?.not_eligible">{{ 'trip_details.traveller_age.not_eligible' | translate }}</span>
              <span *ngIf="travellersAge.errors?.pattern">{{ 'trip_details.traveller_age.pattern' | translate }}</span>
            </div>
          </div>
        </div>
      </li>
    </ng-template>
    <ng-container *ngIf="tripDetails?.numberOfTravellers < maxNumberOfTravellers">
      <li class="list-group-item c-traveller-list__item c-traveller-list__item--add-new">

        <button type="button"
                class="btn btn-link btn-block trip-details-add-traveler"
                analytic-ama="form"
                (click)="addTraveller()"
                tabindex="-1">
          <i>
            <svg-icon name="plus" />
          </i>
          <label>{{ 'trip_details.traveller_ages.list.add_traveller_label' | translate }}</label>
        </button>
      </li>
    </ng-container>
  </ul>
</form>