import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'discount' })
export class DiscountPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private translate: TranslateService) {}

  transform(v) {
    const currency = new CurrencyPipe(this.translate.currentLang);
    if (v > 0) {
      return `(-${currency.transform(v)})`;
    } else {
      return currency.transform(v * -1);
    }
  }
}
