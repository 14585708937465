/* eslint-disable max-lines */
import _ from 'underscore';
import { FormControl } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
export class TstTabConfig {
  public type: string;
  public label: string;
  public iconClass: string;
  public bannerImageURL: string;
  public valuePropHTML: any;
  public promoHTML: any;
  public group: any;
  public groupIndex: boolean;
  public optionGroup: string;
  public optionValue: string;
  public tabChildren: any;
}

export class TstConfig {
  public tabs: TstTabConfig[];
  constructor(tabs: TstTabConfig[]) {
    this.tabs = tabs;
  }
}

export class FlightFormDefs {
  public formDefs: any[];
  constructor(formDefs: any[]) {
    this.formDefs = formDefs;
  }
}

export class AirDestination {
  public destination: any;
  public toCode: any;
  public fromCode: any;
  public departDate: any;
  public returnDate: any;
  constructor(toCode: any, fromCode: any, departDate: any, returnDate: any = null) {
    this.toCode = toCode;
    this.fromCode = fromCode;
    this.departDate = departDate;
    this.returnDate = returnDate;

    this.destination = {
      to: { code: toCode, type: 'METRO_CODE' },
      from: { code: fromCode, type: 'METRO_CODE' },
      depart: {
        time: 'AnyTime',
        date: departDate,
        flex: 'Exact',
      },
      return: {
        time: 'AnyTime',
        date: returnDate,
        flex: 'Exact',
        // tslint:disable-next-line:indent
      },
    };
  }
}

export class AirDestinationList {
  public destinations: AirDestination[];
  constructor(destination: AirDestination) {
    this.destinations = [];
    this.destinations[0] = destination;
  }
  public push(dest) {
    this.destinations.push(dest);
  }
  public pop(dest) {
    this.destinations.pop();
  }
}

/**
 * Children classifications
 * Infants (under age 2) Children (age 2 to under age 12) Youths (12 to 17) Adults (18+)
 */
export class AirPassengers {
  public adults: number;
  public seniors: number;
  public children: number;
  public minor: any[];
  public infants: number;
  public youths: number;
  constructor(adults = 1, seniors = 0, children = 0, minor = [{ age: 0 }], infants = 0, youths = 0) {
    this.adults = adults;
    this.seniors = seniors;
    this.children = children;
    this.minor = minor;
    this.infants = infants;
    this.youths = youths;
  }
}

export class AirPreferences {
  public preferences: any;
  constructor(
    preferences: any = {
      nonstop: false,
      flexDate: false,
      classOfService: '',
      airline: '',
    }
  ) {
    const cos = ['', 'Econ', 'Economy', 'Business', 'First', 'ExcludeBasicEconomy'];
    if (!cos.indexOf(preferences.classOfService)) preferences.classOfService = cos[0];
    this.preferences = preferences;
  }
}

export class AirSearch {
  public destinations: any;
  public preferences: AirPreferences;
  public passengers: AirPassengers;
  public search_id: string;
  constructor(destinations: AirDestinationList, preferences: AirPreferences, passengers: AirPassengers, search_id: string) {
    this.destinations = destinations;
    this.preferences = preferences;
    this.passengers = passengers;
    this.search_id = search_id;
  }
}

export class Location {
  public types: string[] = [];
  public name;
  public destinationID;
  public productID;
  public secondaryType;
  public suggestedTypes;
  constructor(name: string, types: string[], destID: number = 0, prodID: string = '', suggestedTypes: string[] = TST_AC_DEFAULT_TYPES) {
    this.name = name;
    _.each(types, (i: any) => {
      if (_.indexOf(this.types, i) < 0) this.types.push(i);
    });
    if (destID) {
      this.destinationID = destID;
    }
    if (prodID != '') {
      this.productID = prodID;
    }
    this.suggestedTypes = suggestedTypes;
  }
  public push(items: string[]) {
    _.each(items, (i: any) => {
      if (_.indexOf(this.types, i) < 0) this.types.push(i);
    });
  }
  public addSecondaryType(type: string = null) {
    if (type) this.secondaryType = type;
  }
  public pushSuggestedTypes(items: string[]) {
    _.each(items, (i: any) => {
      if (this.suggestedTypes && _.indexOf(this.suggestedTypes, i) < 0) this.suggestedTypes.push(i);
    });
  }
}

/**
 * Object to hold dates for ng-bootstrap datepicker and daterange pickers
 * Why have matDatePicker, satDatePicker and ngbDatePicker?
 * matD was original dp, then we implemented the satD dp with range option, but ux was bad,
 * so implemented a better range datepicker with ngbDatePicker for flights and hotels.
 */
export class TSTDatePicker {
  public dateRngHoveredDate: NgbDate | null;
  public dateRngFromDate: NgbDate | null;
  public dateRngToDate: NgbDate | null;
  public dateRngMin: NgbDate | null;
  public dateRngMax: NgbDate | null;
  public dateRngToday: NgbDate | null;
  public dateRngCalendar: NgbCalendar | null;
  public fromDateLong: Date | null;
  public toDateLong: Date | null;
  public startDate: Date | null;
  public endDate: Date | null;
  public msg: string | null;
  constructor() {}
}

/**
 * Export TST Vars
 * TST_WIDGET_LABELS: These labels are consistant with TST widget's product types
 * THEMES: define themes for widget
 */
export const TST_WIDGET_LABELS = {
  HOTEL_TAB: 'hotel',
  FLIGHT_TAB: 'flight',
  CAR_TAB: 'car',
  CRUISE_TAB: 'cruise',
  PACKAGE_TAB: 'prepackaged',
  ACTIVITY_TAB: 'activity',
  TOURS_TAB: 'tour',
  DYNAMIC_TAB: 'vacation', // dynamic packaging in our terms
  VACATION_RENTALS: 'rentals',
};

/**
 * array of possible products that can be searched with tst widget
 */
export const TST_PRODUCT_TYPES = [
  TST_WIDGET_LABELS.PACKAGE_TAB,
  TST_WIDGET_LABELS.DYNAMIC_TAB,
  /* TST_WIDGET_LABELS.TOURS_TAB, */
  TST_WIDGET_LABELS.VACATION_RENTALS,
  TST_WIDGET_LABELS.HOTEL_TAB,
  TST_WIDGET_LABELS.CAR_TAB,
  TST_WIDGET_LABELS.CRUISE_TAB,
  TST_WIDGET_LABELS.FLIGHT_TAB,
  TST_WIDGET_LABELS.ACTIVITY_TAB,
];

export const TST_AC_DEFAULT_TYPES = [
  TST_WIDGET_LABELS.DYNAMIC_TAB,
  TST_WIDGET_LABELS.HOTEL_TAB,
  TST_WIDGET_LABELS.CAR_TAB,
  TST_WIDGET_LABELS.FLIGHT_TAB,
];

export const TST_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

/*
 * For changing TSTs default labels and placeholders
 **/
export const TST_MAP_FIELDS = [
  {
    type: TST_WIDGET_LABELS.PACKAGE_TAB,
    formSelector: '.prepackaged-section',
    form: null,
    fields: {
      depart: {
        outerClass: '.departure',
        control: {
          class: '.tst-prepackaged-departure',
          name: 'departureLocation',
          id: null,
          type: 'select',
        },
      },
      destination: {
        outerClass: '.arrival',
        control: {
          class: 'tst-prepackaged-arrival',
          name: 'destination',
          id: null,
          type: 'select',
        },
      },
      dates: {
        outerClass: '.prePackageDatePicker',
        label: 'Departure Date',
        control: {
          class: 'mat-mdc-input-element',
          name: null,
          id: null,
          type: 'text',
        },
      },
      duration: {
        outerClass: '.duration',
        control: {
          class: '.selectpicker',
          name: 'duration',
          id: 'duration',
          type: 'select',
        },
      },
      occupancy: {
        outerClass: '.travelers',
        control: {
          class: null,
          name: 'roomOccupancy',
          id: null,
          type: 'select',
        },
      },
      guests: {
        outerClass: '.tst-search__select-incrementor',
        control: {
          class: '.form-stepper__control',
          name: null,
          id: null,
          type: 'formStepper',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.DYNAMIC_TAB,
    formSelector: '#vacationForm',
    form: null,
    fields: {
      depart: {
        outerClass: '.pickup-location#fromLocation',
        dataAttr: 'tst-autocomplete-types',
        label: 'Departing From',
        control: {
          class: '',
          name: 'f',
          id: null,
          type: 'text',
          placeholder: 'Departure city or airport',
        },
      },
      destination: {
        outerClass: '.pickup-location#toLocation',
        dataAttr: 'tst-autocomplete-types',
        label: 'Going To',
        control: {
          class: '',
          name: 't',
          id: null,
          type: 'text',
          placeholder: 'Where do you want to go?',
        },
      },
      hotelCarLocation: {
        outerClass: '.location#toLocation',
        label: 'Going To',
        control: {
          class: '',
          name: 't',
          id: null,
          type: 'text',
          placeholder: 'Where do you want to go?',
        },
      },
      dates: {
        outerClass: '.vacationLeaveDatePicker',
        type: 'dateRange',
        control: {
          class: 'mat-mdc-input-element',
          name: null,
          id: null,
          type: 'text',
        },
      },
      guests: {
        outerClass: '.tst-search__select-incrementor',
        control: {
          class: '.form-stepper__control',
          name: null,
          id: null,
          type: 'formStepper',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.TOURS_TAB,
    formSelector: 'form[action="//albertateachers.tstllc.net/tour/results"]',
    form: null,
    fields: {
      depart: {
        outerClass: '.location',
        control: {
          class: '',
          name: 'destination',
          id: null,
          type: 'select',
        },
      },

      dates: {
        outerClass: '.month',
        type: 'select',
        control: {
          class: null,
          name: 'month',
          id: null,
          type: 'select',
        },
      },
      duration: {
        outerClass: '.duration',
        control: {
          class: null,
          name: 'duration',
          id: null,
          type: 'select',
        },
      },
      keyword: {
        outerClass: '.keyword',
        control: {
          class: null,
          name: 'keyword',
          id: null,
          type: 'text',
          placeholder: 'Exotic, Adventure, Luxury, etc.',
        },
      },
    },
  },
  { type: TST_WIDGET_LABELS.FLIGHT_TAB },
  {
    type: TST_WIDGET_LABELS.HOTEL_TAB,
    action: '//albertateachers.tstllc.net/results/hotel',
    formSelector: '#hotel-form',
    form: null,
    fields: {
      depart: {
        outerClass: '.location',
        label: 'Destination',
        control: {
          class: '',
          name: 'location',
          id: null,
          type: 'text',
          placeholder: 'Where do you want to go?',
        },
      },

      dates: {
        outerClass: '.hotelCheckinDatePicker',
        type: 'dateRange',
        control: {
          class: 'mat-mdc-input-element',
          name: null,
          id: null,
          type: 'text',
        },
      },
      guests: {
        outerClass: '.tst-search__select-incrementor',
        control: {
          class: '.form-stepper__control',
          name: null,
          id: null,
          type: 'formStepper',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.CRUISE_TAB,
    formSelector: 'form[action="//albertateachers.tstllc.net/cruise/cruises"]',
    form: null,
    fields: {
      destination: {
        outerClass: '.cruiseDestination',
        label: 'Destination',
        control: {
          class: '',
          name: 'destination',
          id: 'cruiseDestination',
          type: 'select',
        },
      },
      cruiseMonth: {
        outerClass: '.cruiseMonth',
        label: 'Month',
        control: {
          class: '',
          name: 'departureMonth',
          id: 'cruiseMonth',
          type: 'select',
        },
      },
      cruiseDays: {
        outerClass: '.cruiseDays',
        label: 'Cruise Length',
        control: {
          class: '',
          name: 'fullDays',
          id: 'cruiseDays',
          type: 'select',
        },
      },
      cruiseTravelers: {
        outerClass: '.cruiseTravelers',
        label: 'Traveller Count',
        control: {
          class: '',
          name: 'passengerCount',
          id: 'cruiseTravelers',
          type: 'select',
        },
      },
      cruiseDeparturePort: {
        outerClass: '.cruiseDeparturePort',
        label: 'Departure Port',
        control: {
          class: null,
          name: 'departurePort',
          id: null,
          type: 'select',
        },
      },
      cruiseLine: {
        outerClass: '.cruiseLine',
        label: 'Cruise Line',
        control: {
          class: null,
          name: 'cruiseLine',
          id: null,
          type: 'select',
        },
      },
      cruiseShip: {
        outerClass: '.cruiseShip',
        label: 'Cruise Name',
        control: {
          class: null,
          name: 'ship',
          id: 'cruiseShip',
          type: 'select',
        },
      },
      cruisePromotion: {
        outerClass: '.cruisePromotion',
        label: 'Choose Promotion',
        control: {
          class: null,
          name: 'promotion',
          id: 'cruisePromotion',
          type: 'select',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.CAR_TAB,
    formSelector: '#car-form',
    form: null,
    fields: {
      depart: {
        outerClass: '.pickup-location-input',
        control: {
          class: null,
          name: 'pickUpLocation',
          id: null,
          type: 'text',
        },
      },
      destination: {
        outerClass: '.dropoff-location',
        control: {
          class: null,
          name: 'dropOffLocation',
          id: null,
          type: 'select',
        },
      },
      dates: {
        outerClass: '.tst-search__date-picker',
        type: 'dateRange',
        control: {
          class: 'mat-mdc-input-element',
          name: null,
          id: null,
          type: 'text',
        },
      },
      pickupTime: {
        outerClass: '.pickup-time',
        control: {
          class: null,
          name: 'pickUpTime',
          id: null,
          type: 'select',
        },
      },
      dropoffTime: {
        outerClass: '.car-return-time',
        control: {
          class: null,
          name: 'dropOffTime',
          id: null,
          type: 'select',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.ACTIVITY_TAB,
    formSelector: 'action="//albertateachers.tstllc.net/activity/search"',
    form: null,
    fields: {
      depart: {
        outerClass: '.location',
        label: 'Destination or Activity',
        control: {
          class: '[data-tst-activity-autocomplete]',
          name: null,
          id: null,
          type: 'text',
        },
      },
      dates: {
        outerClass: '.monthYear',
        label: 'Month',
        control: {
          class: null,
          name: 'monthYear',
          id: null,
          type: 'select',
        },
      },
    },
  },
  {
    type: TST_WIDGET_LABELS.VACATION_RENTALS,
    action: '//albertateachers.tstllc.net/hotel/search',
    formSelector: '#vacation-rentals-form',
    form: null,
    fields: {
      depart: {
        outerClass: '.location',
        label: 'Destination',
        control: {
          class: '',
          name: 'location',
          id: null,
          type: 'text',
          placeholder: 'Where do you want to go?',
        },
      },

      dates: {
        outerClass: '.rentalCheckinDatePicker',
        type: 'dateRange',
        control: {
          class: 'mat-mdc-input-element',
          name: null,
          id: null,
          type: 'text',
        },
      },
      guests: {
        outerClass: '.tst-search__select-incrementor',
        control: {
          class: '.form-stepper__control',
          name: null,
          id: null,
          type: 'formStepper',
        },
      },
    },
  },
];

/*
 * list of airports for autofilling departure fields in alberta based on geolocation
 **/
export const ALBERTA_AIRPORTS = [
  {
    city: 'Edmonton',
    airport: 'Edmonton International Airport (YEG), Edmonton, AB',
    airportCode: 'YEG',
    hotelLocation: 'Edmonton, AB, Canada',
  },
  {
    city: 'Calgary',
    airport: 'Calgary International Airport (YYC), Calgary, AB',
    airportCode: 'YYC',
    hotelLocation: 'Calgary, AB, Canada',
  },
  {
    city: 'Lethbridge',
    airport: 'Lethbridge County Airport (YQL), Lethbridge, AB',
    airportCode: 'YQL',
    hotelLocation: 'Lethbridge, AB, Canada',
  },
  {
    city: 'Fort McMurray',
    airport: 'Fort McMurray Airport (YMM), Fort McMurray, AB',
    airportCode: 'YMM',
    hotelLocation: 'Fort McMurray, AB, Canada',
  },
  {
    city: 'Medicine Hat',
    airport: 'Medicine Hat Airport (YXH), Medicine Hat, AB',
    airportCode: 'YXH',
    hotelLocation: 'Medicine Hat, AB, Canada',
  },
  {
    city: 'Grande Prairie',
    airport: 'Grande Prairie Airport (YQU), Grande Prairie, AB',
    airportCode: 'YQU',
    hotelLocation: 'Grande Prairie, AB, Canada',
  },
];

/*
 * Autocomplete Endpoints + data
 **/
export const AUTOCOMPLETE_ENDPOINTS = [
  {
    // 0 prepackaged
    url: 'https://albertateachers.tstllc.net/prepackaged/departureLocationToDestinationViews',
    params: ['departureLocation'],
    type: [TST_WIDGET_LABELS.PACKAGE_TAB],
    suggestedTypes: TST_AC_DEFAULT_TYPES,
    name: TST_WIDGET_LABELS.PACKAGE_TAB,
  },
  {
    // 1 flights
    url: 'https://albertateachers.tstllc.net/web-services/common/autocomplete',
    params: [{ options: '["airports"]' }, 'term', 'lat', 'long'],
    secondaryParams: [{ options: '["airports","cities"]' }],
    type: [TST_WIDGET_LABELS.FLIGHT_TAB, TST_WIDGET_LABELS.DYNAMIC_TAB, TST_WIDGET_LABELS.HOTEL_TAB, TST_WIDGET_LABELS.CAR_TAB],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.FLIGHT_TAB,
  },
  {
    // 2 hotels
    url: 'https://albertateachers.tstllc.net/web-services/common/autocomplete',
    params: [{ options: '["cities"]' }, 'term', 'lat', 'long'],
    secondaryParams: [{ options: '["hotels","cities"]' }],
    type: [TST_WIDGET_LABELS.FLIGHT_TAB, TST_WIDGET_LABELS.DYNAMIC_TAB, TST_WIDGET_LABELS.HOTEL_TAB, TST_WIDGET_LABELS.CAR_TAB],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.HOTEL_TAB,
  },
  {
    // 3 cars
    url: 'https://albertateachers.tstllc.net/web-services/common/autocomplete',
    params: [{ options: '["airports", "cities", "excludeMetroAreas"]' }, 'term', 'lat', 'long'],
    secondaryParams: [{ options: '["airports","cities","excludeMetroAreas"]' }],
    type: [TST_WIDGET_LABELS.FLIGHT_TAB, TST_WIDGET_LABELS.DYNAMIC_TAB, TST_WIDGET_LABELS.HOTEL_TAB, TST_WIDGET_LABELS.CAR_TAB],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.CAR_TAB,
  },
  {
    // 4 cruise
    url: 'https://albertateachers.tstllc.net/cruise/filters.json',
    params: ['passengerCount', 'term'],
    type: [TST_WIDGET_LABELS.CRUISE_TAB],
    secondaryType: 'departurePort',
    suggestedTypes: TST_AC_DEFAULT_TYPES,
    name: TST_WIDGET_LABELS.CRUISE_TAB,
  },
  {
    // 5 activity 1
    url: 'https://api.blue.infra.tstllc.net/v2/activity/destinations',
    params: ['term', 'licensee'],
    type: [TST_WIDGET_LABELS.ACTIVITY_TAB],
    resultsKey: ['activities', 'locations2'],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.ACTIVITY_TAB,
  },
  {
    // 6 activity 2
    url: 'https://api.tstllc.net/v2/activity/destinations',
    /*
		https://api.green.kube.tstllc.net/v2/activity/destinations?licensee=3&term=calga works
		https://api.green.kube.tstllc.net/v2/activity/destinations/?term=calg&licensee=3 fails
		*/
    params: ['licensee', 'term'],
    type: [TST_WIDGET_LABELS.ACTIVITY_TAB],
    resultsKey: ['suggestions'],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.ACTIVITY_TAB,
  },
  {
    // 7 tours
    url: 'https://api.client.kube.tstllc.net/v1/tour/regions?licenseeId=3',
    params: ['term', 'licensee'],
    type: [TST_WIDGET_LABELS.TOURS_TAB],
    suggestedTypes: [],
    name: TST_WIDGET_LABELS.TOURS_TAB,
  },
];

export const TODAYS_DATE = new Date();

/*
 * List of date pickers mapped to form fields
 * to inject/replace with matCalendar
 **/
export const DATEPICKERS: any[] = [
  {
    name: 'prePackageDatePicker',
    matDateElement: null,
    tstType: TST_WIDGET_LABELS.PACKAGE_TAB,
    label: 'Departure Date',
    dropdownTitleStart: 'Select Departure Date',
    replaceClass: 'departure-date',
    dateRange: false,
    datePickerType: 'ngbDatePicker',
    minDateOffset: 2,
    minDepartDate: new Date(),
    inputToUpdate: null,
    formControl: new FormControl(
      // TODAYS_DATE.setDate(TODAYS_DATE.getDate() + 1)
      null
    ),
  },
  {
    name: 'vacationLeaveDatePicker',
    matDateElement: null,
    tstType: TST_WIDGET_LABELS.DYNAMIC_TAB,
    label: ['Leave/Check-in', 'Return/Check-out'],
    dropdownTitleStart: 'Select Leave/Check-in Date',
    dropdownTitleEnd: 'Select Return/Check-out Date',
    replaceClass: 'pickup-date',
    replaceClassReturn: 'car-return-date',
    dateRange: true,
    datePickerType: 'ngbDatePicker',
    minDateOffset: 1,
    minDepartDate: new Date(),
    inputToUpdate: null,
    returnInputToUpdate: null,
    startDate: true,
    /* relatedDatePickerName: 'vacationReturnDatePicker',
		relatedDatePicker: null, */
    formControl: new FormControl(
      // TODAYS_DATE.setDate(TODAYS_DATE.getDate() + 1)
      null
    ),
  },
  {
    name: 'hotelCheckinDatePicker',
    matDateElement: null,
    tstType: TST_WIDGET_LABELS.HOTEL_TAB,
    label: ['Check-In', 'Check-Out'],
    dropdownTitleStart: 'Select Check-in Date',
    dropdownTitleEnd: 'Select Check-out Date',
    replaceClass: 'checkIn-date',
    replaceClassReturn: 'checkOut-date',
    dateRange: true,
    datePickerType: 'ngbDatePicker',
    minDateOffset: 0,
    minDepartDate: new Date(),
    placeHolder: null,
    inputToUpdate: null,
    returnInputToUpdate: null,
    startDate: true,
    relatedDatePickerName: 'hotelCheckoutDatePicker',
    relatedDatePicker: null,
    formControl: new FormControl(
      // TODAYS_DATE.setDate(TODAYS_DATE.getDate() + 1)
      null
    ),
  },
  {
    name: 'rentalCheckinDatePicker',
    matDateElement: null,
    tstType: TST_WIDGET_LABELS.VACATION_RENTALS,
    label: ['Check-In', 'Check-Out'],
    dropdownTitleStart: 'Select Check-in Date',
    dropdownTitleEnd: 'Select Check-out Date',
    replaceClass: 'checkIn-date',
    replaceClassReturn: 'checkOut-date',
    dateRange: true,
    datePickerType: 'ngbDatePicker',
    minDateOffset: 0,
    minDepartDate: new Date(),
    placeHolder: null,
    inputToUpdate: null,
    returnInputToUpdate: null,
    startDate: true,
    relatedDatePickerName: 'rentalCheckoutDatePicker',
    relatedDatePicker: null,
    formControl: new FormControl(
      // TODAYS_DATE.setDate(TODAYS_DATE.getDate() + 1)
      null
    ),
  },
  {
    name: 'carPickupDatePicker',
    matDateElement: null,
    tstType: TST_WIDGET_LABELS.CAR_TAB,
    label: ['Pickup Date', 'Dropoff Date'],
    dropdownTitleStart: 'Select Pick-up Date',
    dropdownTitleEnd: 'Select Drop-off Date',
    replaceClass: 'pickup-date',
    replaceClassReturn: 'car-return-date',
    dateRange: true,
    datePickerType: 'ngbDatePicker',
    minDateOffset: 0,
    minDepartDate: new Date(),
    placeHolder: null,
    inputToUpdate: null,
    returnInputToUpdate: null,
    startDate: true,
    relatedDatePickerName: 'carDropoffDatePicker',
    relatedDatePicker: null,
    formControl: new FormControl(
      // TODAYS_DATE.setDate(TODAYS_DATE.getDate() + 1)
      null
    ),
  },
];

export const FORM_VALIDATION_FIELDS: any[] = [
  {
    type: TST_WIDGET_LABELS.PACKAGE_TAB,
    selector: '.prepackaged-section',
    fields: ['.prePackageDatePicker input'],
    submit: '.search-btn button',
  },
  {
    type: TST_WIDGET_LABELS.DYNAMIC_TAB,
    selector: '.vacation-section',
    fields: [
      '.pickup-location#fromLocation input',
      '.pickup-location#toLocation input',
      '.tst-search__date-picker--from input',
      '.tst-search__date-picker--to input',
    ],
    submit: '.search-btn button',
  },
  {
    type: TST_WIDGET_LABELS.HOTEL_TAB,
    selector: '.hotel-section',
    fields: ['.location-input input', '.tst-search__date-picker--from input', '.tst-search__date-picker--to input'],
    submit: '.search-btn button',
  },
  {
    type: TST_WIDGET_LABELS.FLIGHT_TAB,
    selector: '.flight-section',
    fields: [
      {
        st: 'return',
        selectors: [
          '.tst-form__item--fromCity input',
          '.tst-form__item--toCity input',
          '.tst-form__control--dropDownElDepart input',
          '.tst-form__control--dropDownElReturn input',
        ],
      },
      {
        st: 'oneway',
        selectors: ['.tst-form__item--fromCity input', '.tst-form__item--toCity input', '.tst-form__control--dropDownElDepartSingle input'],
      },
      {
        st: 'multicity',
        selectors: [
          '.tst-form__item--fromCity input',
          '.tst-form__item--toCity input',
          '.tst-form__control--dropDownElDepartSingle input',
          '.isMultiCity .tst-form__input-autocomplete input',
        ],
      },
    ],
    submit: 'button.tst-form__button--submit',
  },
  {
    type: TST_WIDGET_LABELS.CAR_TAB,
    selector: '.car-section',
    fields: ['.pickup-location-input input', '.tst-search__date-picker--from input', '.tst-search__date-picker--to input'],
    submit: '.search-btn button',
  },
];

/* icons */
export const ICON_INJECTIONS = {
  prepackaged: [
    {
      selector: '.' + TST_WIDGET_LABELS.PACKAGE_TAB + '-section ' + '.departure select',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.PACKAGE_TAB + '-section ' + '.arrival select',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.PACKAGE_TAB + '-section ' + '.duration select',
      type: 'fal',
      name: 'clock',
      classes: ['tst-icon--clock', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.PACKAGE_TAB + '-section ' + '.travelers .travelers-room select',
      type: 'fal',
      name: 'bed',
      classes: ['tst-icon--bed', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.PACKAGE_TAB + '-section ' + '.tst-search__select-incrementor .form-stepper__control ',
      type: 'fal',
      name: 'person',
      classes: ['tst-icon--male', 'tst-icon--prefix'],
    },
  ],
  tour: [
    {
      selector: '.' + TST_WIDGET_LABELS.TOURS_TAB + '-section ' + '.location select',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.TOURS_TAB + '-section ' + '.grouped .month select',
      type: 'svg',
      name: 'calendar',
      classes: ['tst-icon--calendar', 'tst-icon--suffix'],
      template: '#iconTemplates',
    },
    {
      selector: '.' + TST_WIDGET_LABELS.TOURS_TAB + '-section ' + '.duration select',
      type: 'fal',
      name: 'clock',
      classes: ['tst-icon--clock', 'tst-icon--prefix'],
    },
  ],
  dynamic: [
    {
      selector: 'fromLocation',
      selectorType: 'id',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: 'toLocation',
      selectorType: 'id',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.DYNAMIC_TAB + '-section ' + '.tst-search__select-incrementor .form-stepper__control ',
      type: 'fal',
      name: 'person',
      classes: ['tst-icon--male', 'tst-icon--prefix'],
    },
  ],
  hotel: [
    {
      selector: '.' + TST_WIDGET_LABELS.HOTEL_TAB + '-section ' + '.location input',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.HOTEL_TAB + '-section ' + '.tst-search__select-incrementor .form-stepper__control ',
      type: 'fal',
      name: 'person',
      classes: ['tst-icon--male', 'tst-icon--prefix'],
    },
  ],
  rentals: [
    {
      selector: '.' + TST_WIDGET_LABELS.VACATION_RENTALS + '-section ' + '.location input',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.VACATION_RENTALS + '-section ' + '.tst-search__select-incrementor .form-stepper__control ',
      type: 'fal',
      name: 'person',
      classes: ['tst-icon--male', 'tst-icon--prefix'],
    },
  ],
  car: [
    {
      selector: '.' + TST_WIDGET_LABELS.CAR_TAB + '-section ' + '.pickup-location-input input',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CAR_TAB + '-section ' + '.dropoff-location-input input',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CAR_TAB + '-section ' + '.pickup-time select',
      type: 'fal',
      name: 'clock',
      classes: ['tst-icon--clock', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CAR_TAB + '-section ' + '.car-return-time select',
      type: 'fal',
      name: 'clock',
      classes: ['tst-icon--clock', 'tst-icon--prefix'],
    },
  ],
  cruise: [
    {
      selector: '.' + TST_WIDGET_LABELS.CRUISE_TAB + '-section ' + '.cruiseDestination select',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CRUISE_TAB + '-section ' + '.cruiseMonth  select',
      type: 'svg',
      name: 'calendar',
      classes: ['tst-icon--calendar', 'tst-icon--suffix'],
      template: '#iconTemplates',
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CRUISE_TAB + '-section ' + '.cruiseDays  select',
      type: 'fal',
      name: 'clock',
      classes: ['tst-icon--clock', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.CRUISE_TAB + '-section ' + '.cruiseTravelers select',
      type: 'fal',
      name: 'person',
      classes: ['tst-icon--male', 'tst-icon--prefix'],
    },
  ],
  activity: [
    {
      selector: '.' + TST_WIDGET_LABELS.ACTIVITY_TAB + '-section ' + '.location input',
      type: 'fal',
      name: 'map-marker',
      classes: ['tst-icon--map-marker', 'tst-icon--prefix'],
    },
    {
      selector: '.' + TST_WIDGET_LABELS.ACTIVITY_TAB + '-section ' + '.monthYear  select',
      type: 'svg',
      name: 'calendar',
      classes: ['tst-icon--calendar', 'tst-icon--suffix'],
      template: '#iconTemplates',
    },
  ],
};

/* guest incrementor components */
export const GUEST_INCREMENTORS = {
  prepackaged: {
    $implicit: {
      type: TST_WIDGET_LABELS.PACKAGE_TAB,
      title: 'Travellers',
      form: null,
      callback: true,
      targetSelector: '.travelers',
      removeSelectors: ['.travelers-adults', '.travelers-children'],
      config: {
        totalMax: 6,
        total: 0,
        totalMaxError: `A maximum of 6 passengers can be booked online.`,
      },
      include: [
        {
          control: 'numAdults',
          labels: ['Adults', 'Adult'],
          config: { max: 6, min: 1 },
          tstField: 'adultCount',
          tstFieldForm: null,
          defaultVal: 2,
        },
        {
          control: 'numChildren',
          labels: ['Children', 'Child'],
          config: { max: 4, min: 0 },
          tstField: 'numberOfChildren',
          tstFieldForm: null,
          defaultVal: 0,
          trigger: {
            callback: null,
            namePrefix: 'ages',
            control: null,
            controlType: 'select',
            label: 'Children Ages',
            initialValue: 0,
            addItems: [],
            options: [
              { label: '< 1', value: 0 },
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
              { label: '13', value: 13 },
              { label: '14', value: 14 },
              { label: '15', value: 15 },
              { label: '16', value: 16 },
              { label: '17', value: 17 },
            ],
          },
        },
      ],
      dialogName: 'prepackagedDialog',
      coords: [0, 8],
    },
  },
  vacation: {
    $implicit: {
      type: TST_WIDGET_LABELS.DYNAMIC_TAB,
      title: 'Travellers',
      form: null,
      callback: true,
      targetSelector: '.num-people-grp',
      removeSelectors: ['.num-people-grp'],
      config: {
        totalMax: 6,
        total: 0,
        totalMaxError: `A maximum of 6 passengers can be booked online.`,
        combinedTotals: {
          items: ['numAdults', 'numSeniors'],
          compareValue: 1,
          warning: 'Must have at least 1 adult or 1 senior.',
          compareType: 'min',
        },
      },
      include: [
        {
          control: 'numAdults',
          labels: ['Adults', 'Adult'],
          config: {
            max: 4,
            min: 0,
          },
          tstField: 'a',
          tstFieldForm: null,
          defaultVal: 2,
        },
        {
          control: 'numSeniors',
          labels: ['Seniors', 'Senior'],
          config: {
            max: 4,
            min: 0,
          },
          tstField: 's',
          tstFieldForm: null,
          defaultVal: 0,
        },
        {
          control: 'numChildren',
          labels: ['Children', 'Child'],
          config: { max: 3, min: 0 },
          tstField: 'm',
          tstFieldForm: null,
          defaultVal: 0,
          trigger: {
            callback: null,
            namePrefix: 'ages',
            control: null,
            controlType: 'select',
            label: 'Children Ages',
            initialValue: 0,
            addItems: [],
            options: [
              { label: '< 1', value: 0 },
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
              { label: '13', value: 13 },
              { label: '14', value: 14 },
              { label: '15', value: 15 },
              { label: '16', value: 16 },
              { label: '17', value: 17 },
            ],
          },
        },
      ],
      dialogName: 'dynamicDialog',
      coords: [0, 8],
    },
  },
  hotel: {
    $implicit: {
      type: TST_WIDGET_LABELS.HOTEL_TAB,
      title: 'Guests',
      form: null,
      callback: true,
      targetSelector: '.rooms-grp',
      removeSelectors: ['.rooms-grp'],
      config: {
        totalMax: 13,
        total: 0,
        totalMaxError: `A maximum of 13 guests per room can be booked online.`,
      },
      include: [
        {
          control: 'hotelRoomsCount',
          labels: ['Rooms', 'Room'],
          config: { max: 3, min: 1, threshold: 2 },
          tstField: 'hotelRoomsCount',
          tstFieldForm: null,
          defaultVal: 1,
          thresholdWarning: 'When booking two or more rooms, pricing is based on double occupancy.',
        },
        {
          control: 'hotelAdultsCount',
          labels: ['Adults', 'Adult'],
          config: { max: 8, min: 1 },
          tstField: 'hotelAdultsCount',
          defaultVal: 1,
          tstFieldForm: null,
        },
        {
          control: 'hotelChildrenCount',
          labels: ['Children', 'Child'],
          config: { max: 5, min: 0 },
          tstField: 'hotelChildrenCount',
          tstFieldForm: null,
          defaultVal: 0,
          trigger: {
            callback: null,
            namePrefix: 'children',
            control: null,
            controlType: 'select',
            label: 'Children Ages',
            initialValue: 0,
            addItems: [],
            options: [
              { label: '< 1', value: 0 },
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
              { label: '13', value: 13 },
              { label: '14', value: 14 },
              { label: '15', value: 15 },
              { label: '16', value: 16 },
              { label: '17', value: 17 },
            ],
          },
        },
      ],
      dialogName: 'hotelDialog',
      coords: [0, 8],
    },
  },
  rentals: {
    $implicit: {
      type: TST_WIDGET_LABELS.VACATION_RENTALS,
      title: 'Guests',
      form: null,
      callback: true,
      targetSelector: '.rooms-grp',
      removeSelectors: ['.rooms-grp'],
      config: {
        totalMax: 13,
        total: 0,
        totalMaxError: `A maximum of 13 guests per room can be booked online.`,
      },
      include: [
        {
          control: 'vacationRentalsAdultsCount',
          labels: ['Adults', 'Adult'],
          config: { max: 8, min: 1 },
          tstField: 'adults',
          defaultVal: 1,
          tstFieldForm: null,
        },
        {
          control: 'vacationRentalsChildrenCount',
          labels: ['Children', 'Child'],
          config: { max: 5, min: 0 },
          tstField: 'children',
          tstFieldForm: null,
          defaultVal: '',
          ignoreUpdate: true,
          trigger: {
            callback: null,
            namePrefix: 'children',
            control: null,
            controlType: 'select',
            label: 'Children Ages',
            initialValue: 0,
            addItems: [],
            options: [
              { label: '< 1', value: 0 },
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
              { label: '13', value: 13 },
              { label: '14', value: 14 },
              { label: '15', value: 15 },
              { label: '16', value: 16 },
              { label: '17', value: 17 },
            ],
          },
        },
      ],
      dialogName: 'rentalDialog',
      coords: [0, 8],
    },
  },
  flight: {
    type: TST_WIDGET_LABELS.FLIGHT_TAB,
    title: 'Travellers',
    form: null,
    callback: true,
    targetSelector: null,
    removeSelectors: null,
    config: {
      totalMax: 6,
      total: 0,
      totalMaxError: `A maximum of 6 passengers can be booked online.`,
      combinedTotals: {
        items: ['numAdults', 'numSeniors'],
        compareValue: 1,
        warning: 'Must have at least 1 adult or 1 senior.',
        compareType: 'min',
      },
    },
    include: [
      {
        control: 'numAdults',
        labels: ['Adults', 'Adult'],
        config: {
          max: 6,
          min: 0,
        },
        tstField: null,
        tstFieldForm: null,
        defaultVal: 1,
      },
      {
        control: 'numSeniors',
        labels: ['Seniors', 'Senior'],
        config: {
          max: 6,
          min: 0,
        },
        tstField: null,
        tstFieldForm: null,
        defaultVal: 0,
      },
      {
        control: 'numChildren',
        labels: ['Children', 'Child'],
        config: { max: 4, min: 0 },
        tstField: null,
        tstFieldForm: null,
        defaultVal: 0,
        trigger: {
          callback: null,
          namePrefix: 'childAges',
          control: null,
          controlType: 'select',
          label: 'Children Ages',
          initialValue: 0,
          addItems: [],
          options: [
            { label: '< 1', value: 0 },
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 },
            { label: '11', value: 11 },
            { label: '12', value: 12 },
            { label: '13', value: 13 },
            { label: '14', value: 14 },
            { label: '15', value: 15 },
            { label: '16', value: 16 },
            { label: '17', value: 17 },
          ],
        },
      },
    ],
    dialogName: 'flightDialog',
    coords: [0, 8],
  },
};

export const TST_DATE_FORMAT = 'MM/DD/YYYY';

export const ORDINAL_NUMBER_STRING = (num: number): string => {
  num = Math.round(num);
  const numString = num.toString();

  // If the ten's place is 1, the suffix is always "th"
  // (10th, 11th, 12th, 13th, 14th, 111th, 112th, etc.)
  if (Math.floor(num / 10) % 10 === 1) {
    return numString + 'th';
  }

  // Otherwise, the suffix depends on the one's place as follows
  // (1st, 2nd, 3rd, 4th, 21st, 22nd, etc.)
  switch (num % 10) {
    case 1:
      return numString + 'st';
    case 2:
      return numString + 'nd';
    case 3:
      return numString + 'rd';
    default:
      return numString + 'th';
  }
};

/*
 * In order to override, within CMS page, the promo/valueprop/bgBannerImages from tab to tab,
 * we need all other tab info based
 * on their page data

export const PAGES_WITH_TST = [
  {
    name: 'coverage',
    title: 'home',
    pageId: 361,
    defaultTab: 5,
    tab: 'Prepackaged',
    hasOwnTab: false,
  },
  {
    name: 'westjet/deals',
    title: 'WestJet Deals',
    pageId: 857,
    defaultTab: 5,
    tab: 'Prepackaged',
    hasOwnTab: false,
  },
  {
    name: 'last-minute-vacation-deals',
    title: 'Last Minute Deals',
    pageId: 371,
    defaultTab: 5,
    tab: 'Prepackaged',
    hasOwnTab: false,
  },
  {
    name: 'cruises/luxury-small-ship',
    title: 'Small Ship Luxury Cruises',
    pageId: 400,
    defaultTab: 4,
    tab: 'Cruise',
    hasOwnTab: false,
  },
  {
    name: 'cruises/river',
    title: 'River Cruises',
    pageId: 396,
    defaultTab: 4,
    tab: 'Cruise',
    hasOwnTab: false,
  },
  {
    name: 'cruises/ocean',
    title: 'Ocean Cruises',
    pageId: 395,
    defaultTab: 4,
    tab: 'Cruise',
    hasOwnTab: false,
  },
  {
    name: 'vacation-packages',
    title: 'Vacations',
    pageId: 357,
    defaultTab: 5,
    tab: 'Prepackaged',
    hasOwnTab: true,
  },
  {
    name: 'cars',
    title: 'Car Rental',
    pageId: 355,
    defaultTab: 3,
    tab: 'Car',
    hasOwnTab: true,
  },
  {
    name: 'cruises',
    title: 'cruises',
    pageId: 19,
    defaultTab: 4,
    tab: 'Cruise',
    hasOwnTab: true,
  },
  {
    name: 'activities',
    title: 'activities',
    pageId: 18,
    defaultTab: 6,
    tab: 'Activity',
    hasOwnTab: true,
  },
  {
    name: 'flights',
    title: 'flights',
    pageId: 16,
    defaultTab: 2,
    tab: 'Flight',
    hasOwnTab: true,
  },
  {
    name: 'hotels',
    title: 'hotel',
    pageId: 13,
    defaultTab: 1,
    tab: 'Hotel',
    hasOwnTab: true,
  },
  {
    name: 'hotels/vacation-rentals',
    title: 'Vacation Rentals with AMA Travel',
    pageId: 1765,
    defaultTab: 8,
    tab: 'Vacation Rentals',
    hasOwnTab: true,
  },
];
**/

export const REGEX = {
  vpRegex: /(<section *id=["']?vpContent['"]? +[A-z0-9-]+?=[A-z0-9-_'" \w\d]*?>[ \w\t\d\S\/\r?\n|\r/]*?<\/section>)+/gm,
  promoRegex: /(<section *id=["']?promoContent['"]? +[A-z0-9-]+?=[A-z0-9-_'" \w\d]*?>[ \w\t\d\S\/\r?\n|\r/]*?<\/section>)+/gm,
  bgImageRegex: /data-tst-bg=['"]https:\/\/[A-z0-9\/\-\_.]*\.(jpg|png|gif)["']/,
  bgCaptionRegex: /(data-caption=)+['"]+[\w\s]*['"]+/,
  captionRegex: /<section id=["']captionContent["'][\w\W]*[^\>]*<\/section>/,
  validImgUrlRegex: /(https:)\/\/*[\w\.\/\-\_]*(?=\.)\.(jpg|gif|png)/,
  htmlCommentsRegex: /(<!--[\w\W\s]*?-->)*/gm,
  extractAirportCode: /(?:\()+?[A-Z]+(?:\))+?/,
  oneOrMoreWord: /[\w]+/g,
  twoOrMoreWord: /(\w)+[\s]?(\w)+/g,
  validDateFormat: /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})/,
};
