import { Component, ElementRef, OnInit } from '@angular/core';
import { Gender, IAppState, IQuoteOption, ICurrentUserInformation } from '../../models/data.interfaces';
import { NgForm, AbstractControl, FormArray, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { select, NgRedux } from '@angular-redux-ivy/store';
import { getYear, addYears, startOfDay, isAfter } from 'date-fns';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { InsuranceProduct, CANADA_ONLY_CODE, ProductGroup } from 'src/app/models/products';
import { CountriesService } from 'src/app/core/services/countries.service';
import { ICountry, ISubRegion } from 'src/app/models/country.model';
import { SalesFunnelActionType, IUpdateQuoteIdAction } from 'src/app/store/reducer';
import { BaseComponent } from 'src/app/components/base-component.component';
import { ErrorModalComponent } from 'src/app/components/error-modal/error-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { QuoteService } from 'src/app/core/services/quote.service';
import { KeyValue } from '@angular/common';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { UserService } from 'src/app/core/services/user.service';
import { updateAgePrimaryPolicyHolder } from 'src/app/store/trip-details-common-reductions';
import { dispatch } from '@angular-redux-ivy/store';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { Unsubscribe } from 'amaweb-tsutils';

interface IInvalidControl {
  name: string;
  value: string;
  errors: Record<string, unknown>;
}

@Unsubscribe()
@Component({
  selector: 'traveller-information',
  templateUrl: './traveller-information.component.html',
  styleUrls: ['./traveller-information.component.scss'],
})
export class TravellerInformationComponent extends BaseComponent implements OnInit {
  @select(['currentQuoteDetails', 'quoteOption']) readonly currentQuoteOption$: Observable<IQuoteOption>;

  loggedIn: boolean;

  isVisitorToCanada = false;
  genderOptions = Gender;
  departureDate: Date;
  primaryTravellerAge: number;
  primaryDateOfBirthIsOpen: boolean;
  primaryTravellerActualAge: number;
  otherTravellers: {
    age: number;
    datepickerIsOpen: boolean;
    actualAge: number;
  }[] = [];
  isEligibleForFamilyRate: boolean;
  modalRef: BsModalRef;

  noCountryFound = false;
  countries: ICountry[] = [];
  clubSupportedProvinces: ISubRegion[] = new Array<ISubRegion>();
  isLoading = false;
  xsMax: boolean;
  maxDateOfBirth = new Date();
  ProductGroup = ProductGroup;
  productGroupFlow: string;

  renew: boolean;

  constructor(
    private readonly el: ElementRef,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly clubConfigService: ClubConfigurationService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private countriesService: CountriesService,
    private modalService: BsModalService,
    private readonly quoteService: QuoteService,
    private readonly scrollToService: ScrollToService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private readonly matchMediaService: MatchMediaService,
    private readonly userService: UserService
  ) {
    super();

    this.using(this.activatedRoute.data.subscribe((d) => (this.renew = d.renew))).attach(this);
  }

  ngOnInit() {
    // Load the countries from the API, Filter out Canada for visitors.
    this.countries = this.countriesService.getCountries() || [];

    this.setClubSupportedProvinces();

    // Trip details will not change at this point
    const tripDetails = this.reduxStore.getState().tripDetails;
    this.productGroupFlow = tripDetails.productGroupFlow;
    this.departureDate = DateHelpers.parseDate(tripDetails.departureDate, true);
    this.isEligibleForFamilyRate = tripDetails.isEligibleForFamilyRate;

    // set primary traveller data
    const agesOfTravellers = Object.values(tripDetails.agesOfTravellers || []).map((a) => a.age);
    this.primaryTravellerAge = agesOfTravellers.shift();
    this.primaryDateOfBirthIsOpen = false;

    // set other traveller data
    this.otherTravellers = agesOfTravellers.map((age) => ({
      age,
      datepickerIsOpen: false,
      actualAge: null,
    }));

    this.isVisitorToCanada = tripDetails && tripDetails.productSelected === InsuranceProduct.VisitorsToCanadaMedicalPlan ? true : false;

    // Check for dob changes in the traveller info
    this.using(
      this.reduxStore.select(['travellerInfo', 'dateOfBirth']).subscribe((dob: Date) => {
        if (dob) {
          this.primaryTravellerActualAge = this.getAge(dob);
          // There is no age validation for the RVD flow.
          if (this.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
            this.syncPrimaryPolicyHolderAge(this.primaryTravellerActualAge);
            this.primaryTravellerAge = this.primaryTravellerActualAge;
          }
        }
      })
    ).attach(this);

    // For each other traveller we need to setup a subscription.
    // This way when the form changes we will only calculate the new age if the dob changed.
    const otherTravellersState = this.reduxStore.getState().travellerInfo.otherTravellers;
    if (otherTravellersState) {
      otherTravellersState.forEach((otherTraveller, index) => {
        this.using(
          this.reduxStore.select(['travellerInfo', 'otherTravellers', index, 'dateOfBirth']).subscribe((dob: Date) => {
            if (this.otherTravellers[index]) {
              this.otherTravellers[index].actualAge = this.getAge(dob);
            }
          })
        );
      });
    }

    this.loggedIn = this.userService.currentUser && this.userService.currentUser.isAuthenticated;

    this.using(
      this.userService.currentUserChanged.subscribe((currentUser: ICurrentUserInformation) => {
        this.loggedIn = currentUser.isAuthenticated;
      })
    ).attach(this);

    // Set the xsMax attribute
    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.xsMax = this.matchMediaService.matches(MEDIA_TYPE.xs_max);
      })
    ).attach(this);
    this.xsMax = this.matchMediaService.matches(MEDIA_TYPE.xs_max);
  }

  @dispatch() syncPrimaryPolicyHolderAge(age: number) {
    return updateAgePrimaryPolicyHolder(age);
  }

  private setClubSupportedProvinces(): void {
    const canada = this.countriesService.getCanada();

    this.clubConfigService.appConfig.settings.supported_province_codes.forEach((provinceCode: string) => {
      this.clubSupportedProvinces.push(canada.subRegions.find((region) => region.code.toUpperCase() === provinceCode.toUpperCase()));
    });
  }

  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      const invalidControls = this.findInvalidControls(f.form);
      for (let i = 0; i < invalidControls.length; i++) {
        const invalidControl = invalidControls[i];
        // The DOB modal should show when the value is populated and doesn't match the traveller age
        if (
          invalidControl.name === 'dateOfBirth' &&
          !invalidControl.errors.hasOwnProperty('required') &&
          invalidControl.errors.hasOwnProperty('age')
        ) {
          const initialState = {
            title: 'traveller_info.error_modal.age_mismatch.title',
            message: 'traveller_info.error_modal.age_mismatch.message',
            primaryButtonText: 'traveller_info.error_modal.age_mismatch.primary_button',
            primaryButtonRoute: 'trip-details',
          };
          this.showErrorModal(initialState);
          break;
        } else if (invalidControl.name === 'membershipNumber' && invalidControl.errors.hasOwnProperty('isActiveMembership')) {
          const initialState = {
            title: 'traveller_info.error_modal.invalid_membership_number.title',
            message: 'traveller_info.error_modal.invalid_membership_number.message',
            primaryButtonText: 'traveller_info.error_modal.invalid_membership_number.primary_button',
            primaryButtonRoute: 'trip-details',
          };
          this.showErrorModal(initialState);
          break;
        }
      }

      if (invalidControls.length > 0) {
        this.scrollToOnMobile(invalidControls[0]);
      }
    } else if (f.form.valid && !this.noCountryFound && !this.isLoading) {
      this.isLoading = true;
      // only visitors to canada has country field as required.
      lastValueFrom(this.quoteService.saveQuote(this.reduxStore.getState(), true), undefined).then(
        (response: IAppState) => {
          this.reduxStore.dispatch(this.setQuoteId(response.quoteID));
          this.router.navigate(this.renew ? ['renew', 'payment'] : ['payment']);
          this.isLoading = false;
        },
        (reason) => {
          this.isLoading = false;
        }
      );
    }
  }

  scrollToOnMobile(control: IInvalidControl) {
    if (this.matchMediaService.matches(MEDIA_TYPE.Phone)) {
      const element = this.el.nativeElement.querySelector(`[name='${control.name}']`);
      const offset = element.getBoundingClientRect().top - 70;

      const config: ScrollToConfigOptions = {
        offset: offset,
      };
      this.scrollToService.scrollTo(config);
    }
  }

  setQuoteId = (quoteId: string): IUpdateQuoteIdAction => {
    return {
      type: SalesFunnelActionType.UPDATE_QUOTE_ID,
      payload: quoteId,
    } as IUpdateQuoteIdAction;
  };

  /**
   * Displays a modal dialog that gives the user the ability navigate
   * to a page route.
   * @param initialState The content to display in the dialog
   */
  showErrorModal(initialState: Record<string, unknown>) {
    const config: ModalOptions = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: initialState,
    };
    this.modalRef = this.modalService.show(ErrorModalComponent, config);
  }

  /**
   * Find all controls recursively which are invalid.
   * @param control The starting FormGroup.
   * @param controlName The last key that was used to access this control. Only required when looping through a FormGroup.
   */
  findInvalidControls(control: AbstractControl, controlName: any = null): IInvalidControl[] {
    let invalidControls: IInvalidControl[] = [];
    // Helper function for combining results
    const recurse = (childControl: AbstractControl, childControlName: any) => {
      invalidControls = invalidControls.concat(this.findInvalidControls(childControl, childControlName));
    };

    if (control instanceof FormArray) {
      for (let i = 0; i < control.length; i++) {
        recurse(control.at(i), i);
      }
    } else if (control instanceof FormGroup) {
      // eslint-disable-next-line guard-for-in
      for (const name in control.controls) {
        recurse(control.controls[name], name);
      }
    } else if (control instanceof FormControl && control.invalid) {
      invalidControls.push({
        name: controlName,
        value: control.value,
        errors: control.errors,
      });
    }
    return invalidControls;
  }

  onShowPicker(container) {
    const dayHoverHandler = container.dayHoverHandler;
    const hoverWrapper = function ($event) {
      const { cell, isHovered } = $event;
      // Set 'cell.isHovered = true' to resolve double tap issue on mobile
      cell.isHovered = isHovered;
      return dayHoverHandler($event);
    };
    container.dayHoverHandler = hoverWrapper;
  }

  public genderOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>) => {
    const akey = a.key === Gender.Other.toString() ? '3' : a.key;
    const bkey = b.key === Gender.Other.toString() ? '3' : b.key;
    return akey === bkey ? 0 : akey < bkey ? -1 : 1;
  };

  public validateAge = (selectedDate: Date, selectedAge: number) => {
    if (this.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
      return true;
    }
    return this.getAge(selectedDate) !== selectedAge ? { age: true } : null;
  };

  private getAge = (selectedDate: Date) => {
    const now = startOfDay(new Date());
    let computedAge = getYear(now) - getYear(selectedDate);
    if (isAfter(addYears(selectedDate, computedAge), now)) {
      computedAge--;
    }
    return computedAge;
  };
}
