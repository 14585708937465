import { addDays, differenceInCalendarDays } from 'date-fns';
import { SalesFunnelActionType } from 'src/app/store/reducer';

export function updatePolicyStartDateAndPlanDays(departureDate: Date, returnDate: Date) {
  let durationOfFirstTrip = null;
  if (departureDate && returnDate) {
    durationOfFirstTrip = differenceInCalendarDays(returnDate, departureDate) + 1;
  }
  return {
    type: SalesFunnelActionType.UPDATE_TRIP_DETAILS,
    tripDetailsState: {
      policyStartDate: departureDate,
      durationOfFirstTrip,
    },
  };
}

export function updateDepartureDateAndReturnDate(policyStartDate: Date, planDays: number) {
  return {
    type: SalesFunnelActionType.UPDATE_TRIP_DETAILS,
    tripDetailsState: {
      departureDate: policyStartDate,
      returnDate: addDays(policyStartDate, planDays - 1),
    },
  };
}

export function updateAgePrimaryPolicyHolder(age: number) {
  return {
    type: SalesFunnelActionType.UPDATE_TRIP_DETAILS,
    tripDetailsState: {
      agesOfTravellers: [
        {
          age: age,
          hasPreExistingCondition: false,
        },
      ],
    },
  };
}
