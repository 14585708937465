export interface IMedicalQuestionnaire {
  isAtStart: boolean;
  isCompleted: boolean;
  isNotEligible: boolean;
  relatesToTravellerIndex: number;
  currentSection: IMedicalQuestionnaireSection;
  answers?: IMedicalQuestionnaireAnswer[];
}

export interface IMedicalQuestionnaireSection {
  id: string;
  title?: string;
  footer?: string;
  description?: string;
  elements?: IMedicalQuestionnaireElement[];
  numberOfQuestions?: number;
}

export interface IMedicalQuestionnaireElement {
  canSelectMultiple: boolean;
  id?: string;
  maxLength: number;
  minLength: number;
  name?: string;
  sectionID: string;
  text?: string;
  elementType: MedicalQuestionnareElementType;
  validationExpression?: string;
  options?: IMedicalQuestionnaireElementOption[];
}

export interface IMedicalQuestionnaireElementOption {
  id: string;
  text: string;
}

export interface IMedicalQuestionnaireAnswer {
  questionID?: string;
  answer?: string;
  sectionID?: string;
}

export enum MedicalQuestionnaireMoveActionType {
  None = 'None',
  MoveNext = 'MoveNext',
  MovePrevious = 'MovePrevious',
  Moved = 'Moved',
  MoveToEnd = 'MoveToEnd',
  NoAction = 'NoAction',
  ValidationError = 'ValidationError',
}

export enum MedicalQuestionnareElementType {
  Header = 'Header',
  Note = 'Note',
  Separator = 'Separator',
  QuestionYesNo = 'QuestionYesNo',
  QuestionFreeText = 'QuestionFreeText',
  QuestionMultipleChoice = 'QuestionMultipleChoice',
}
