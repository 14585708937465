<div class="c-sign-in-banner">
	<div class="container">
		<div class="c-sign-in-banner__inner flex-wrap flex-sm-nowrap justify-content-center align-items-center">
			<div class="c-sign-in-banner__icon">
				<svg-icon name="member-save" />
			</div>
			<div class="c-sign-in-banner__text">
				<ng-container><span class="d-sm-none"
						  [innerHTML]="'signin_bar.text.mobile' | translate | safeHtml"></span></ng-container>
				<ng-container><span class="d-none d-sm-inline"
						  [innerHTML]="'signin_bar.text.desktop' | translate | safeHtml"></span></ng-container>
			</div>
			<div class="c-sign-in-banner__btn">
				<a class="d-sm-none"
				   linkLogin>{{'signin_bar.cta.mobile' | translate}}
				</a>
				<button linkLogin
						type="button"
						class="btn d-none d-sm-inline">{{'signin_bar.cta.desktop' | translate}}
				</button>
			</div>
			<div class="c-sign-in-banner__close">
				<button type="button"
						class="u-close u-close--light-hover"
						aria-label="Close"
						(click)="close($event)">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>
</div>