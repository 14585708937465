import { Component } from '@angular/core';
import { BaseComponent } from '../../base-component.component';

@Component({
  selector: 'ata-footer',
  templateUrl: './ata-footer.component.html',
})
export class AtaFooterComponent extends BaseComponent {
  public currentYear = new Date().getFullYear();

  constructor() {
    super();
  }
}
