import { Component, AfterViewInit } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { BaseComponent } from '../base-component.component';
import { IEventConfig } from 'src/app/models/event-config-model';
import { WindowRefService } from 'src/app/core/services/window-ref.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'event-modal',
  templateUrl: './event-modal.html',
})
export class EventModalComponent extends BaseComponent implements AfterViewInit {
  public eventConfig: IEventConfig;

  constructor(
    public bsModalRef: BsModalRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private readonly windowService: WindowRefService,
    private readonly translateService: TranslateService
  ) {
    super();
    this.googleAnalyticsService.setAndSendModalView('event-modal', 'Event Modal');
  }

  close() {
    if (this.eventConfig.canDismiss) {
      this.bsModalRef.hide();
    } else {
      this.translateService
        .get(this.eventConfig.translationKey + '.cta_link')
        .subscribe((link) => {
          this.windowService.navigateTo(link);
        })
        .attach(this);
    }
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }
}
