import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLoaderFactory } from '../app.translate';

import { ClubConfigurationService } from '../core/services/club-configuration.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgReduxFormModule } from '@angular-redux-ivy/form';
import { NgReduxModule } from '@angular-redux-ivy/store';
import { DigitOnlyModule } from '@uiowa/digit-only';

import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { SprintfPipe } from './pipes/string-format.pipe';
import { OrdinalPipe, NamedOrdinalPipe } from './pipes/ordinal.pipe';
import { ConnectArrayFixDirective } from './directives/connect-array-fix.directive';
import { MinDirective } from './directives/min-value-validator.directive';
import { MaxDirective } from './directives/max-value-validator.directive';
import { EmailPatternDirective } from './directives/email-pattern-validator';
import { MatchHeightDirective } from './directives/match-height.directive';
import { CheckMobileDirective } from './directives/check-mobile.directive';
import { SetHeightDirective } from './directives/set-height.directive';
import { LinkLoginDirective } from './directives/link-login.directive';
import { ClubSupportedProvincesDirective } from './directives/club-supported-provinces.directive';
import { DateInputComponent } from './components/date-input/date-input.component';
import { InjectorDirective } from './directives/injector.directive';
import { DiscountPipe } from './pipes/discount.pipe';
import { MapPipe } from './pipes/map.pipe';
import { FormatPipesModule } from './pipes/format.pipes';
import { AutoPlacementDirective } from './directives/auto-placement.directive';
import { TripDetailsActionCreatorService } from '../store/trip-details-action-creator.service';
import { ConnectArrayFixModelDirective } from './directives/connect-array-fix-model.directive';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConnectDirective } from './directives/connect';
import { CentreFragmentPipe } from './pipes/centreFragment.pipe';

@NgModule({
  providers: [TripDetailsActionCreatorService],
  declarations: [
    CentreFragmentPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeResourceUrlPipe,
    SprintfPipe,
    OrdinalPipe,
    NamedOrdinalPipe,
    DiscountPipe,
    MapPipe,
    ConnectDirective,
    ConnectArrayFixDirective,
    ConnectArrayFixModelDirective,
    MinDirective,
    MaxDirective,
    EmailPatternDirective,
    MatchHeightDirective,
    CheckMobileDirective,
    SetHeightDirective,
    LinkLoginDirective,
    ClubSupportedProvincesDirective,
    DateInputComponent,
    InjectorDirective,
    AutoPlacementDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgReduxModule,
    NgReduxFormModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [ClubConfigurationService],
      },
    }),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    AccordionModule.forRoot(),
    DigitOnlyModule,
    FormatPipesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgReduxModule,
    NgReduxFormModule,
    TabsModule,
    BsDatepickerModule,
    BsDropdownModule,
    ButtonsModule,
    TranslateModule,
    ModalModule,
    TooltipModule,
    NgxMaskModule,
    ScrollToModule,
    AccordionModule,
    DigitOnlyModule,
    FormatPipesModule,

    SafeHtmlPipe,
    SafeStylePipe,
    SafeResourceUrlPipe,
    SprintfPipe,
    OrdinalPipe,
    NamedOrdinalPipe,
    DiscountPipe,
    MapPipe,
    CentreFragmentPipe,

    ConnectDirective,
    ConnectArrayFixDirective,
    ConnectArrayFixModelDirective,
    MinDirective,
    MaxDirective,
    EmailPatternDirective,
    MatchHeightDirective,
    CheckMobileDirective,
    SetHeightDirective,
    LinkLoginDirective,
    ClubSupportedProvincesDirective,
    DateInputComponent,
    InjectorDirective,
    AutoPlacementDirective,
  ],
})
export class SharedModule {}
