<form #f="ngForm"
      id="coverage"
      connect="tripDetails"
      (ngSubmit)="onSubmit(f)"
      analytic-ama="form"
      [attr.analytic-id]="analyticSubmitId">
  <div class="row justify-content-lg-start"
       [ngClass]="{'justify-content-sm-center': !hideCTA}">
    <div *ngIf="productGroupFlow !== productGroup.RentalVehicleDamageFlow"
         class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-4 col-lg-3">
      <div class="form-group">
        <label class="h6 mt-3"
               for="numberOfTravellers">
          {{ 'single_trip.number_of_travellers.prompt' | translate }}
        </label>
        <number-of-travellers [analyticsPrefix]="analyticsPrefix" />
      </div>
    </div>

    <div [ngClass]="{
      'col-sm-6 col-lg-4': hideCTA,
      'col-sm-8 col-lg-3 col-md-4 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3': !hideCTA
    }"
         class="col-12">
      <div class="form-group">
        <label class="h6"
               for="btnDepartureDate"
               [ngClass]="{'mt-3': !hideCTA}">{{ translatePrefix + '.departure_date.prompt' | translate }}</label>
        <div class="input-group">
          <div class="input-group__overlay-input">
            <button class="form-control {{analyticClassDeparture}}"
                    [ngClass]="{ 'is-invalid': (f.submitted && departureDate.invalid) }"
                    type="button"
                    id="btnDepartureDate"
                    (click)="departureDateIsOpen = !departureDateIsOpen"
                    [attr.aria-expanded]="departureDate.isOpen"
                    analytic-ama="form">
              <svg-icon name="calendar" />
            </button>
          </div>
          <date-input #departureDate="ngModel"
                      [inputClass]="{ 'is-invalid': (f.submitted && departureDate.invalid) }"
                      placeholder="{{ translatePrefix + '.departure_date.placeholder' | translate }}"
                      [required]="true"
                      [minDate]="minDepartureDate"
                      [maxDate]="maxDepartureDate"
                      ngControl
                      ngModel
                      name="departureDate"
                      [isOpen]="departureDateIsOpen"
                      (onHidden)="this.departureDateIsOpen = false"
                      (onShown)="onShowPicker($event)"
                      tabindex="-1" />
          <div *ngIf="f.submitted && departureDate.invalid"
               class="invalid-feedback">
            <span *ngIf="departureDate.errors?.required">{{ translatePrefix + '.departure_date.required' | translate }}</span>
            <span *ngIf="departureDate.errors?.minDate">{{ translatePrefix + '.departure_date.min_date' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{
      'col-sm-6 col-lg-4': hideCTA,
      'col-sm-8 col-lg-3 col-md-4 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3': !hideCTA
    }"
         class="col-12">
      <div class="form-group">
        <label class="h6"
               for="btnReturnDate"
               [ngClass]="{'mt-3': !hideCTA}">{{ translatePrefix + '.return_date.prompt' | translate }}</label>
        <div class="input-group">
          <div class="input-group__overlay-input">
            <button class="form-control {{analyticClassReturn}}"
                    [ngClass]="{ 'is-invalid': (f.submitted && returnDate.invalid) }"
                    type="button"
                    id="btnReturnDate"
                    (click)="returnDateIsOpen = !returnDateIsOpen"
                    [attr.aria-expanded]="returnDate.isOpen"
                    analytic-ama="form">
              <svg-icon name="calendar" />
            </button>
          </div>
          <date-input #returnDate="ngModel"
                      [inputClass]="{ 'is-invalid': (f.submitted && returnDate.invalid) }"
                      placeholder="{{ translatePrefix + '.return_date.placeholder' | translate }}"
                      [required]="true"
                      [minDate]="minReturnDate"
                      [maxDate]="maxReturnDate"
                      ngControl
                      ngModel
                      name="returnDate"
                      [isOpen]="returnDateIsOpen"
                      (onHidden)="this.returnDateIsOpen = false"
                      (onShown)="onShowPicker($event)"
                      tabindex="-1" />
          <div *ngIf="f.submitted && returnDate.invalid"
               class="invalid-feedback">
            <span *ngIf="returnDate.errors?.required">{{ translatePrefix + '.return_date.required' | translate }}</span>
            <span *ngIf="returnDate.errors?.minDate">{{ translatePrefix + '.return_date.min_date' | translate }}</span>
            <span *ngIf="returnDate.errors?.maxDate">{{ translatePrefix + '.return_date.max_date' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-sm-center"
       *ngIf="!hideCTA">
    <div class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-5 col-lg-6">
      <button class="btn btn-secondary btn-block btn-lg mt-3 mt-sm-4 mt-md-5 mb-4 {{analyticClassSubmit}}"
              type="submit"
              (click)="syncPolicyStartDate(departureDate.value, returnDate.value);">
        {{ submitButtonText || (translatePrefix + '.submit' | translate) }}
      </button>
    </div>
  </div>
</form>