<ng-container *injector="
{
    tripDetails: tripDetails$,
    productGroupFlow: productGroupFlow$,
    destinationSelected: destinationSelected$,
    provinceSelected: provinceSelected$,
    quoteOptions: quoteOptions$,
    lastServerError: lastServerError$
};
let tripDetails = tripDetails;
let productGroupFlow = productGroupFlow;
let destinationSelected = destinationSelected;
let provinceSelected = provinceSelected;
let quoteOptions = quoteOptions;
let lastServerError = lastServerError;
let matchMedia = matchMedia;">
  <form #f="ngForm"
        connect="tripDetails"
        (ngSubmit)="onSubmit(f, travellerAges)"
        *ngIf="productGroupFlow !== productGroup.VisitorsToCanadaFlow; else visitors_to_canada"
        class="o-trip-details"
        id="trip-details">
    <div class="form-row">
      <div class="form-group col col-lg-10">
        <h2>{{ 'trip_details.title' | translate }}</h2>
        <p class="mb-0">{{ 'trip_details.lead' | translate }}</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <h3 class="mt-4 mb-3 h5"> {{ 'trip_details.club_member.question' | translate }}</h3>
        <div class="form-check form-check--border rounded-top col-lg-10 col-xl-9">
          <input type="radio"
                 class="form-check-input"
                 id="memberRadios1"
                 ngControl
                 ngModel
                 name="isClubMember"
                 #isClubMember="ngModel"
                 [value]="true" />
          <label class="form-check-label"
                 for="memberRadios1">
            <span>
              <span innerHtml="{{ 'trip_details.club_member.yes' | translate }}"></span>

              <!-- Some flows do not support discounts. Because of this we need to piece together the appropriate message.-->
              <ng-container *ngIf="productGroupFlow !== productGroup.TCIFlow">
                <span *ngIf="loggedIn"
                      innerHtml="{{ 'trip_details.club_member.logged_in_with_discount' | translate }}"></span>
                <span *ngIf="!loggedIn"
                      innerHtml="{{ 'trip_details.club_member.logged_out_with_discount' | translate }}"></span>
              </ng-container>

              <ng-container *ngIf="productGroupFlow === productGroup.TCIFlow">
                <span *ngIf="loggedIn"
                      innerHtml="{{ 'trip_details.club_member.logged_in_without_discount' | translate }}"></span>
                <span *ngIf="!loggedIn"
                      innerHtml="{{ 'trip_details.club_member.logged_out_without_discount' | translate }}"></span>
              </ng-container>

              <span *ngIf="!loggedIn">
                <a href="javascript:void(0)"
                   linkLogin
                   innerHtml="{{ 'trip_details.club_member.login_link_text' | translate }}"></a>
                {{ 'trip_details.club_member.login_link_text_after' | translate }}
              </span>
            </span>
          </label>
        </div>
        <div class="form-check form-check--border rounded-bottom col-lg-10 col-xl-9">
          <input type="radio"
                 class="form-check-input"
                 id="memberRadios2"
                 ngControl
                 ngModel
                 name="isClubMember"
                 #isClubMember="ngModel"
                 [value]="false" />
          <label class="form-check-label"
                 for="memberRadios2"
                 innerHTML="{{ (tenant === TenantEnum.ATA && isTCIFlow ? 'trip_details.club_member.TCIno' : 'trip_details.club_member.no') | translate }}">
          </label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="c-form-label-tooltip form-group col o-trip-details__destination">
        <h3 class="d-inline-block mt-4 mr-5 mr-sm-0 h5">
          {{ 'trip_details.destination.title' | translate }}
        </h3>
        <i class="mt-2 c-form-label-tooltip__icon">
          <button type="button"
                  class="btn btn-link m-0 p-0"
                  tooltip="{{ 'trip_details.destination.tooltip' | translate }} "
                  container="body"
                  [placement]="'left'"
                  tabindex="-1">
            <svg-icon name="question_mark_circle" />
          </button>
        </i>
        <div class="row">
          <p class="col-lg-10">{{ 'trip_details.destination.description' | translate }}</p>
        </div>


        <select id="selectCountry"
                ngControl
                [ngModel]="destinationSelected"
                required
                #countrySelectedModel="ngModel"
                [ngModelOptions]="{standalone: true}"
                (change)="travellingDestinationSelected($event.target.value)"
                class="form-control o-trip-details__country-select"
                [ngClass]="{ 'is-invalid': f.submitted && countrySelectedModel.invalid }">
          <option selected
                  value="">{{ 'trip_details.destination.drop_down_placeholder' | translate }}</option>
          <ng-container *ngFor="let country of countries">
            <ng-container *ngIf="country.subRegions.length > 0; else otherCountries">
              <optgroup label="{{ country.name }}">
                <option *ngFor="let subRegion of country.subRegions"
                        [value]=" country.code + '|' + subRegion.code ">{{subRegion.name }}</option>

              </optgroup>
            </ng-container>

            <option *ngIf="country.subRegions.length === 0"
                    [value]="country.code">{{ country.name }}</option>

          </ng-container>

        </select>
        <div *ngIf="f.submitted && countrySelectedModel.invalid"
             class="invalid-feedback">
          <span>{{ 'trip_details.destination.required' | translate }}</span>
        </div>

        <div class="form-check o-trip-details__canada-only-checkbox"
             name="canadaOnly"
             [hidden]="countrySelected?.code !== 'CA'">
          <input type="checkbox"
                 class="form-check-input"
                 ngControl
                 ngModel
                 id="isTravellingOnlyInCanada"
                 name="isTravellingOnlyInCanada"
                 #isTravellingOnlyInCanada="ngModel"
                 value="true" />
          <label class="form-check-label"
                 for="isTravellingOnlyInCanada"
                 innerHTML="{{ 'trip_details.destination.travelling_only_in_canada' | translate }}">
          </label>
        </div>

      </div>
    </div>
    <div class="form-row">
      <div class="c-form-label-tooltip form-group col col-lg-10">
        <h3 class="d-inline-block mt-4 mr-5 mr-sm-0 h5">{{ 'trip_details.traveller_ages.title' | translate }}</h3>
        <i class="mt-2 c-form-label-tooltip__icon">
          <button type="button"
                  class="btn btn-link m-0 p-0"
                  tooltip="{{ ('trip_details.traveller_ages.tooltip' | translate) + ' ' + ('trip_details.traveller_ages.tooltip_extra' | translate) }} "
                  container="body"
                  [placement]="'left'"
                  tabindex="-1">
            <svg-icon name="question_mark_circle" />
          </button>
        </i>
        <p>{{ 'trip_details.traveller_ages.description' | translate }}</p>
        <div class="row">
          <div class="col-12">
            <traveller-ages #travellerAges="travellerAges"
                            [existingTravellersAreEditable]="true" />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="tripDetails?.numberOfTravellers >= familyRatePromptActivationNumber"
         class="form-row">
      <div class="form-group col">
        <div class="alert alert-info rounded-0 text-body"
             role="alert">
          <h4 class="alert-heading">{{ 'trip_details.family_rate.prompt' | translate }}</h4>
          <p class="mb-0"
             innerHTML="{{ 'trip_details.family_rate.description' | translate}}"></p>
          <div class="form-check form-check--border d-inline-block mt-3 rounded bg-white">
            <input class="form-check-input"
                   type="checkbox"
                   value="false"
                   id="familyRate"
                   ngControl
                   ngModel
                   name='isEligibleForFamilyRate'
                   #isEligibleForFamilyRate="ngModel" />
            <label class="form-check-label"
                   for="familyRate">{{ 'trip_details.family_rate.acceptance_text' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row"
         *ngIf="productGroupFlow !== productGroup.VisitorsToCanadaFlow">
      <div class="c-form-label-tooltip form-group col o-trip-details__sum-insured">
        <div>
          <h3 class="d-inline-block h5 mt-1 mr-5 mr-sm-0">{{ 'trip_details.sum_insured.title' | translate }}</h3>
          <i class="c-form-label-tooltip__icon">
            <button type="button"
                    class="btn btn-link m-0 p-0"
                    tooltip="{{ 'trip_details.sum_insured.tooltip' | translate }}"
                    container="body"
                    [placement]="'left'"
                    tabindex="-1">
              <svg-icon name="question_mark_circle" />
            </button>
          </i>
          <p>{{ 'trip_details.sum_insured.description' | translate }}</p>
        </div>
        <div>
          <div class="d-flex align-items-center">
            <div class="input-group insured-amount"  [ngClass]="{ 'is-invalid': (f.submitted && sumInsured.invalid) }">
              <div class="input-group-prepend">
                <span class="input-group-text" [ngClass]="{ 'is-invalid': (f.submitted && sumInsured.invalid) }">$</span>
              </div>
              <input type="number"
                    id="insuredAmount"
                    class="form-control d-inline-block"
                    [ngClass]="{ 'is-invalid': (f.submitted && sumInsured.invalid) }"
                    ngModel
                    ngControl
                    #sumInsured="ngModel"
                    required
                    digitOnly
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [min]="'trip_details.sum_insured.min_value' | translate"
                    [max]="'trip_details.sum_insured.max_value' | translate"
                    name="sumInsured" />
            </div>
            <span class="h5  d-inline-block ml-2 mb-0">{{ 'trip_details.sum_insured.monetary_unit' | translate }}</span>
          </div>
          <div *ngIf="f.submitted && sumInsured.invalid" class="invalid-feedback" [ngClass]="{'d-block': f.submitted && sumInsured.invalid}">
            <span *ngIf="sumInsured.errors?.min">{{ 'trip_details.sum_insured.min_value_message' | translate }}</span>
            <span *ngIf="sumInsured.errors?.max"
                  [innerHTML]="'trip_details.sum_insured.max_value_message' | translate | safeHtml"></span>
            <span *ngIf="sumInsured.errors?.required">{{ 'trip_details.sum_insured.required' | translate }}</span>
            <span *ngIf="sumInsured.errors?.pattern">{{ 'trip_details.sum_insured.pattern' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <div class="btn-group d-block d-md-inline-block"
             role="group">
          <button id="trip-details-submit"
                  class="btn btn-secondary btn-lg d-block d-sm-inline-block col-sm-6 col-md-auto px-5 mt-3"
                  [ngClass]="{'btn--loading': isLoading}"
                  type="submit"
                  *ngIf="quoteOptions?.length === 0">
            <span class="px-1">{{ 'trip_details.submit' | translate }}</span>
          </button>
          <div class="alert alert-danger"
               *ngIf="lastServerError?.errors?.length ">
            {{ lastServerError?.errors[0].clientMessage }}
          </div>
        </div>
      </div>
    </div>

  </form>

  <ng-template #visitors_to_canada>
    <form #f="ngForm"
          connect="tripDetails"
          (ngSubmit)="onSubmit(f, travellerAges)"
          class="o-trip-details">
      <div class="form-row">
        <div class="form-group col col-lg-10">
          <h2>{{ 'trip_details.title' | translate }}</h2>
          <p class="mb-0">{{ 'trip_details.visitors_to_canada.lead' | translate }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col col-lg-10 mb-2">
          <p class="font-weight-bold">{{ 'trip_details.visitors_to_canada.qualification_lead' | translate }}</p>
          <ul class="c-checklist c-checklist--icon-secondary mb-0">
            <li>{{ 'trip_details.visitors_to_canada.qualification_1' | translate }}</li>
            <li>{{ 'trip_details.visitors_to_canada.qualification_2' | translate }}</li>
            <li>{{ 'trip_details.visitors_to_canada.qualification_3' | translate }}</li>
            <li>{{ 'trip_details.visitors_to_canada.qualification_4' | translate }}</li>
            <li>{{ 'trip_details.visitors_to_canada.qualification_5' | translate }}</li>
          </ul>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col o-trip-details__destination">
          <h3 class="mt-4 h5">
            {{ 'trip_details.visitors_to_canada.destination.title' | translate }}
          </h3>

          <div class="row">
            <p class="col-lg-10">{{ 'trip_details.destination.description' | translate }}</p>
          </div>
          <select id="selectProvince"
                  ngControl
                  [ngModel]="provinceSelected"
                  required
                  #subRegionSelected="ngModel"
                  [ngModelOptions]="{standalone: true}"
                  (change)="travellingSubRegionSelected($event.target.value)"
                  class="form-control o-trip-details__country-select"
                  [ngClass]="{ 'is-invalid': f.submitted && subRegionSelected.invalid }">
            >
            <option selected
                    value="">{{ 'trip_details.visitors_to_canada.destination.drop_down_placeholder' | translate }}</option>
            <option *ngFor="let province of countrySelected?.subRegions"
                    [value]="province.code">{{ province.name }}</option>
          </select>
          <div *ngIf="f.submitted && subRegionSelected.invalid"
               class="invalid-feedback">
            <span>{{ 'trip_details.destination.required' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="c-form-label-tooltip form-group col col-lg-10">
          <h3 class="d-inline-block mt-4 mr-5 mr-sm-0 h5">{{ 'trip_details.traveller_ages.title' | translate }}</h3>
          <i class="mt-2 c-form-label-tooltip__icon">
            <button type="button"
                    class="btn btn-link m-0 p-0"
                    tooltip="{{ 'trip_details.traveller_ages.tooltip' | translate }} "
                    container="body"
                    [placement]="'left'"
                    tabindex="-1">
              <svg-icon name="question_mark_circle" />
            </button>
          </i>
          <p>{{ 'trip_details.traveller_ages.description' | translate }}</p>

          <div class="row">
            <div class="col-12">
              <traveller-ages #travellerAges="travellerAges"
                              [existingTravellersAreEditable]="true" />
            </div>
          </div>
        </div>


      </div>
      <div class="form-row">
        <div class="form-group col">
          <div class="btn-group d-block d-md-inline-block"
               role="group">
            <button id="trip-details-submit"
                    class="btn btn-secondary btn-lg d-block d-sm-inline-block col-sm-6 col-md-auto px-5 mt-3"
                    [ngClass]="{'btn--loading': isLoading}"
                    type="submit"
                    *ngIf="quoteOptions?.length === 0">
              <span class="px-1">{{ 'trip_details.submit' | translate }}</span>
            </button>
            <div class="alert alert-danger"
                 *ngIf="lastServerError?.errors?.length ">
              {{ lastServerError?.errors[0].clientMessage }}
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="alert alert-danger"
         *ngIf="lastServerError?.errors?.length ">
      {{ lastServerError?.errors[0].clientMessage }}
    </div>

  </ng-template>
</ng-container>