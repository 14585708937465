export enum InstrumentationEventNames {
  OptionsRequested = 'CLIENT: Quote Options Requested',
  OptionsProvided = 'CLIENT: Quote Options Provided',
  MedicalQuestionnaireStarted = 'CLIENT: Medical Questionnaire Started',
  MedicalQuestionnaireSectionProvided = 'CLIENT: Medical Questionnaire Section Provided',
  MedicalQuestionnaireCompleted = 'CLIENT: Medical Questionnaire Completed',
  MedicalQuestionnaireIneligible = 'CLIENT: Medical Questionnaire Completed with Insured Ineligible Result',
  PaymentAttempted = 'CLIENT: Payment Attempted',
  PaymentDeclined = 'CLIENT: Payment Declined',
  PaymentApproved = 'CLIENT: Payment Approved',
  QuoteExplicitlySaved = 'CLIENT: Quote explicitly saved',
  QuoteLoaded = 'CLIENT: Quote Loaded',
  SaveQuoteEmailSent = 'CLIENT: Save Quote email sent',
  SignInAttempted = 'CLIENT: Sign-In has been attempted',
  SignInFailed = 'CLIENT: Sign-In failed',
  SignInSucceeded = 'CLIENT: Sign-In succeeded',
}
