import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/components/base-component.component';
import { MatchMediaService, MEDIA_TYPE_NAMES } from 'src/app/core/services/match-media.service';
import { isObservable, Subscription, Observable } from 'rxjs';
import { Unsubscribe } from 'amaweb-tsutils';

/**
 * In some scenarios we want to define the default observable value.
 * This interface is used to define that.
 *
 * The exact use case comes down to null checks on special observable values.
 *
 * Example:
 * stateMap[index]?.value?.otherValue
 *
 * If the stateMap is null we get an error. We are unable to use the
 * following syntax in angular because it's invalid.
 *
 * stateMap?[index]?.value?.otherValue.
 *
 */
export interface IObservableContext {
  observable: Observable<any>;
  initialValue: any;
}

@Unsubscribe()
@Directive({
  selector: '[injector]',
})
export class InjectorDirective extends BaseComponent implements OnDestroy {
  private context: { [key: string]: any };
  private contextSubscriptions: { [key: string]: Subscription };
  /*
   * The OnChanges lifecycle hook is triggered when the @Input property value changes.
   * In the case of an object, that value is the object reference.
   * If the object reference does not change, like in case with matchMedia, OnChanges is not triggered.
   * So this @Input setter will not trigger as well.
   * Be sure that you are passing {...}; and only after that declarations
   */
  @Input()
  public set injector(newContext: any) {
    newContext = newContext || {};
    // Find all keys that need to be set, ignore the matchMedia variable.
    const newKeys = Object.keys(newContext).filter((k) => k !== 'matchMedia');
    const oldKeys = Object.keys(this.context).filter((k) => k !== 'matchMedia');

    // Remove the old variables which are no longer part of the context
    oldKeys.forEach((oldKey) => {
      if (newKeys.indexOf(oldKey) === -1) {
        // Delete any subscriptions that were made for this variable
        const subscription = this.contextSubscriptions[oldKey];
        if (subscription) {
          subscription.unsubscribe();
          delete this.contextSubscriptions[oldKey];
        }
      }

      // Delete the old key
      if (oldKey in this.context) {
        delete this.context[oldKey];
      }
    });

    // Now update/insert the value for all the new variables
    newKeys.forEach((newKey) => {
      // Delete the key before changing it
      if (newKey in this.context) {
        delete this.context[newKey];
      }

      // Always delete old subscriptions
      const subscription = this.contextSubscriptions[newKey];
      if (subscription) {
        subscription.unsubscribe();
        delete this.contextSubscriptions[newKey];
      }

      // A helper function for binding the observable context.
      const subscribeToObservable = (obsContext: IObservableContext) => {
        // Create a subscription which will automatically assign the value
        this.contextSubscriptions[newKey] = obsContext.observable.subscribe((value) => {
          this.context[newKey] = value;
        });

        // Only assign the default value if the observable hasn't fired yet.
        if (!(newKey in this.context)) {
          this.context[newKey] = obsContext.initialValue;
        }
      };

      // Does the object implement the observable context interface?
      const newValue = newContext[newKey];
      if (
        newValue &&
        newValue.hasOwnProperty('observable') &&
        isObservable(newValue['observable']) &&
        newValue.hasOwnProperty('initialValue')
      ) {
        subscribeToObservable(newValue);
      } else if (newValue && isObservable(newValue)) {
        // Is this an observable?
        subscribeToObservable({ observable: newValue, initialValue: undefined });
      } else {
        this.context[newKey] = newValue;
      }
    });

    // Now update the view
    this.updateView();
  }

  public constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    // Services that can be injected
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService
  ) {
    super();

    // Initialize the context
    this.context = {
      matchMedia: {} as { MEDIA_TYPE: boolean },
    };
    // Used for observables
    this.contextSubscriptions = {};

    this.updateMatchMedia();
    this.using(
      matchMediaService.onChange().subscribe(() => {
        this.updateMatchMedia();
      })
    ).attach(this);
  }

  private updateMatchMedia() {
    MEDIA_TYPE_NAMES.forEach((type) => {
      this.context.matchMedia[type] = this.matchMediaService.matches(type);
    });
  }

  private updateView() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    Object.keys(this.contextSubscriptions).forEach((key) => {
      const subscription = this.contextSubscriptions[key];
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
