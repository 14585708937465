import {
  IPolicyInfo,
  IMedicalQuestionnaire,
  ITravellerInfo,
  ITripDetails,
  ICurrentQuoteDetails,
  IAppState,
  IQuoteOption,
  ITravellerAge,
  ITraveller,
} from '../models/data.interfaces';

export const GetInitialPolicyInfo = (): IPolicyInfo => ({
  dateQuoteInitiated: new Date(),
  dateLastEdited: new Date(),
  policyNumber: null,
  currentQuoteDetails: null,
  tripDetails: null,
});

export const GetInitialTravellerInfo = (): ITravellerInfo => ({
  membershipNumber: null,
  otherTravellers: [],
});

export const GetInitialTripDetails = (): ITripDetails => ({
  numberOfTravellers: 1,
  countrySelected: null,
  subRegionSelected: null,
  agesOfTravellers: [{ age: null, hasPreExistingCondition: false }],
  sumInsured: 0,
});

export const GetInitialCurrentQuoteDetails = (): ICurrentQuoteDetails => ({
  planDays: null,
  topUpDays: null,
  deductibleAmount: null,
  quoteOption: null,
});

export const GetAppInitialState = (): IAppState => ({
  quoteID: null,
  policyInfo: GetInitialPolicyInfo(),
  tripDetails: GetInitialTripDetails(),
  travellerInfo: GetInitialTravellerInfo(),
  quoteOptions: new Array<IQuoteOption>(),
  medicalQuestionnaires: [],
  currentQuoteDetails: GetInitialCurrentQuoteDetails(),
  lastServerError: null,
});

export const GetTravellerAgeInitialState = (): ITravellerAge => ({
  age: null,
  hasPreExistingCondition: false,
});

export const GetTravellerAgeInitialRVDState = (): ITravellerAge => ({
  age: 25,
  hasPreExistingCondition: false,
});

export const GetTravellerInitialState = (): ITraveller => ({
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  gender: null,
  addressLineOne: null,
  addressLineTwo: null,
  postalCode: null,
  provinceCode: null,
  country: null,
  city: null,
  addressSameAsPrimary: true,
});
