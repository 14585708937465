<section *ngIf="showBanner"
		 class="c-banner"
		 [ngClass]="classes">
	<div [ngClass]="{'c-banner__wrapper': options?.isFeaturedImageFullWidth, 'container': !options?.isFeaturedImageFullWidth}">
		<div class="c-banner__image"
			 [ngStyle]="imageStyles"
			 [ngClass]="{isTSTBanner: isTstBanner}">
			<bg-image-fallback *ngIf="!showTST"
							   [hidden]="hideBannerImage"
							   [src]="options?.featuredImage"
							   [fallbackSrc]="options?.fallbackImage"
							   [fallbackColor]="options?.fallbackColor" />
			<div class="c-banner__tst-wrapper"
				 *ngIf="showTST">
				<tst class="c-banner__tst-container"
					 (isTSTBannerPage)="isTSTBannerPage($event)"
					 [isBanner]="true"
					 [theme]="tstThemes.LIGHT"
					 [bannerOptions]="options"
					 [defaultTab]="options?.defaultTSTTabString"
					 [defaultDepartureAirport]="options?.defaultTSTDepartureAirportCode"
					 [showValueProp]="true"
					 [showPromo]="false" />
			</div>
			<div *ngIf="!showTST"
				 class="c-banner__content-wrapper"
				 [ngClass]="{'c-banner__content-wrapper--hasTST': showTST}">
				<div class="c-banner__content"
					 *ngIf="showFeaturedImageContent"
					 [innerHTML]="options?.featuredImageContent | safeHtml">
				</div>
			</div>
		</div>
	</div>
</section>