import { Injectable } from '@angular/core';
import { IAppState, IGaPurchase, IGaProduct } from '../../models/data.interfaces';
import { multiTripProductIsSelected, ProductGroup, InsuranceProduct } from '../../models/products';
import { find } from 'underscore';

@Injectable({
  providedIn: 'root',
})
export class PurchaseMapper {
  public static GetPurchaseInfo(appState: IAppState, policyNumber: string): IGaPurchase {
    return {
      id: policyNumber,
      affiliation: 'orion',
      revenue: appState.currentQuoteDetails.quoteOption.totalPrice,
      tax: appState.currentQuoteDetails.quoteOption.tax,
      shipping: '', // as per design empty string
      // coupon:  one of “Member, Family, Deductible, Companion, PromoCode, Price”
      coupon: this.mapCoupon(appState),
    } as IGaPurchase;
  }
  public static GetProductInfo(appState: IAppState, policyNumber: string, autoRenewal: boolean, renew: boolean): IGaProduct {
    const flow = appState.tripDetails.productGroupFlow as ProductGroup;
    const product = appState.tripDetails.productSelected as InsuranceProduct;

    // Calculate dimension6
    let dimension6 = `deductible:$${appState.currentQuoteDetails.deductibleAmount}`;
    if (appState.currentQuoteDetails.quoteOption.hasTopUp) {
      dimension6 += `*topup:$${appState.currentQuoteDetails.quoteOption.linkedTopUp.totalPrice}`;
    }
    if (find(appState.tripDetails.agesOfTravellers, (x) => x.hasPreExistingCondition)) {
      dimension6 += `*pre-ex`;
    }

    return {
      id: policyNumber,
      name: (renew ? 'renew ' : '') + `TMI ${appState.currentQuoteDetails.quoteOption.productName}`,
      category: (renew ? 'renew travel insurance' : 'travel insurance') + `/${this.mapProduct(product)}`,
      brand: 'travel',
      quantity: 1,
      variant: this.mapVariant(appState, autoRenewal),
      price: appState.currentQuoteDetails.quoteOption.totalPrice,
      coupon: '', // as per design empty string
      dimension5: appState.tripDetails.isClubMember ? 'member' : 'non-member',
      dimension6: dimension6,
    } as IGaProduct;
  }

  private static mapVariant(appState: IAppState, autoRenewal: boolean): string {
    if (autoRenewal) {
      return 'Renewal';
    }
    if (multiTripProductIsSelected(appState.tripDetails.productSelected)) {
      return 'Annual';
    }
    return 'OneTime';
  }
  private static mapCoupon(appState: IAppState) {
    if (appState.tripDetails.isClubMember) {
      return 'Member';
    }
    if (appState.tripDetails.isEligibleForFamilyRate) {
      return 'Family';
    }
    if (appState.currentQuoteDetails.quoteOption.hasDeductibleDiscount) {
      return 'Deductible';
    }
    return '';
  }

  private static mapProduct(product: InsuranceProduct): string {
    switch (product) {
      case InsuranceProduct.AnnualPremiumPackage:
        return 'multi-trip/premium';
      case InsuranceProduct.CanadaMedicalPlan:
        return 'single-trip/medical-only-canada';
      case InsuranceProduct.CanadaPackagePlan:
        return 'single-trip/premium-canada';
      case InsuranceProduct.MultiTripMedicalPlan:
        return 'multi-trip/medical-only';
      case InsuranceProduct.SingleTripMedicalPlan:
        return 'single-trip/medical-only';
      case InsuranceProduct.SingleTripPremiumPackage:
        return 'single-trip/premium';
      case InsuranceProduct.TripCancellationAndTripInterruptionInsurance:
        return 'single-trip/trip-cancellation-interruption';
      case InsuranceProduct.NonMedicalPremiumPackage:
        return 'single-trip/trip-cancellation-interruption-non-medical-premium';
      case InsuranceProduct.VisitorsToCanadaMedicalPlan:
        return 'vtc/visitors-to-canada';
      case InsuranceProduct.RentalVehicleDamageInsurance:
        return 'cdw/rental-vehicle-damage';
    }
  }
}
