import { Injectable } from '@angular/core';
import { ClubConfigurationService } from './club-configuration.service';
import { IProduct } from 'src/app/models/data.interfaces';
import * as productHelpers from '../../models/products';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly products: {
    [code: string]: IProduct;
  };

  constructor(private readonly configService: ClubConfigurationService) {
    this.products = this.configService.appConfig.products;
  }

  public getProduct(productCode: string): IProduct | null {
    return this.products[productCode] || null;
  }

  public doesProductCodeHaveDeductibleDiscount(productCode: string): boolean {
    const product = this.getProduct(productCode);

    if (product == null) {
      return false;
    } else {
      return product.deductible_amounts && product.deductible_amounts.length > 0;
    }
  }

  public doesProductSupportPreExistingConditions(productCode: string): boolean {
    const product = this.getProduct(productCode);

    if (product == null) {
      return false;
    } else {
      return product.preExEnabled;
    }
  }

  // These functions are imported from the products.ts file and exposed here so that
  // all product related functionality can be brought under one service.
  // Eventually, it would be good if the products.ts file can be refactored so that
  // all of the methods live here.
  public productGrouping(selectedProduct: string): productHelpers.ProductGroup {
    return productHelpers.productGrouping(selectedProduct);
  }

  public multiTripProductIsSelected(selectedProductCode: string): boolean {
    return productHelpers.multiTripProductIsSelected(selectedProductCode);
  }

  public singleTripProductIsSelected(selectedProductCode: string): boolean {
    return productHelpers.singleTripProductIsSelected(selectedProductCode);
  }
}
