<div class="form-group">
    <label class="h6 font-weight-bold mt-3"
           [for]="name">
        <span>
            {{ 'traveller_info.membership.prompt' | translate }}
            <b class="text-success font-weight-bold">{{ 'traveller_info.membership.prompt_highlighted' | translate }}</b>
            {{ 'traveller_info.membership.prompt_after' | translate }}
        </span>
        <span class="mt-1 d-block font-weight-normal"
              *ngIf="!isLoggedIn">
            {{ 'traveller_info.membership.sub_prompt' | translate }}
            <a href="javascript:void(0)"
               linkLogin
               class="font-weight-bold">{{ 'traveller_info.membership.login_text' | translate }}</a>
        </span>
    </label>
    <div class="row">
        <div class="input-group col-12 col-sm-9 col-lg-5">
            <div class="input-group-prepend">
                <span class="input-group-text pl-0 bg-white border-0">{{ 'traveller_info.membership.number_prefix' | translate }}</span>
            </div>
            <input type="text"
                   id="{{name}}"
                   name="{{name}}"
                   autocomplete="off"
                   placeholder="{{'traveller_info.membership.placeholder' | translate}}"
                   class="form-control rounded"
                   [ngModel]="value"
                   ngControl
                   [mask]="'traveller_info.membership.mask' | translate"
                   [disabled]="isDisabled"
                   (paste)="onPaste($event)"
                   [class.is-invalid]="(showValidation || hasValidated) && errors && !isValidating"
                   [class.is-valid]="!isValidating && isActiveMembership"
                   (ngModelChange)="onNgModelChange($event)" />
            <div *ngIf="(showValidation || hasValidated) && errors"
                 class="invalid-feedback">
                <span *ngIf="!serverError && errors?.required">
                    {{ 'traveller_info.membership.required' | translate }}
                </span>
                <span *ngIf="!serverError && errors?.minlength">
                    {{ 'traveller_info.membership.min_length' | translate }}
                </span>
                <span *ngIf="!serverError && errors?.isActiveMembership">
                    {{ 'traveller_info.membership.invalid' | translate }}
                </span>
                <span *ngIf="serverError">
                    {{ 'traveller_info.membership.server_error' | translate }}
                </span>
            </div>

            <loading-overlay *ngIf="isValidating" />
        </div>
    </div>
</div>