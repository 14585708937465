import { Directive, Input, OnInit, OnChanges, Inject, Self } from '@angular/core';
import { ConnectArrayFixDirective } from './connect-array-fix.directive';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[connectArrayFixModel]',
})
export class ConnectArrayFixModelDirective implements OnInit, OnChanges {
  @Input('connectArrayFixModel') connectArray: ConnectArrayFixDirective;
  @Input() connectArrayFixIndex: number;

  constructor(@Self() @Inject(NgModel) private model: NgModel) {}
  ngOnInit() {
    this.patchModel();
  }
  ngOnChanges() {
    this.patchModel();
  }
  /**
   * We originally made use of NgModelGroup in our connect array template
   * so that the NgModels defined in the template were properly connected to the store.
   *
   * Example:
   * <ng-container [ngModelGroup]="'agesOfTravellers'">
   * <ng-container [ngModelGroup]="index">
   * <input name="age" ngControl ngModel/>
   * ...
   *
   * This leads to a serious issue when removing an embedded view. Basically the embedded view
   * will delete all form groups, form controls, and other elements when OnDestroy is called.
   * Since the top level NgModelGroup (agesOfTravellers) points to the FormArray this would cause
   * the entire array to be removed from the state.
   *
   * To get around this problem we need to manually patch the path method to properly
   * scope the NgModel to the array and index. We do this by accessing the _view.nodes property
   * of the embedded view. If you look at the original connect-array code they try to do something
   * very similar but by using the patchDescendantControls which is only looking for NgModelGroups
   * and not NgModels.
   */
  private patchModel() {
    const _this = this;
    Object.defineProperties(_this.model, {
      path: {
        get: function () {
          return _this.connectArray.path.concat([_this.connectArrayFixIndex, this.name]);
        },
        configurable: true,
      },
    });
  }
}
