import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'centreFragment' })
export class CentreFragmentPipe implements PipeTransform {
  constructor() {}
  public transform(s: string) {
    if (!s) return s;
    return s.replace(' Southpointe', '').replace(' ', '-').replace('.', '').toLowerCase();
  }
}
