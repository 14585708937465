import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'other-header',
  templateUrl: './other-header.component.html',
})
export class OtherHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
