import { Component, OnInit, HostBinding } from '@angular/core';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { ServerLogin, ProductGroup } from 'src/app/models/data.interfaces';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base-component.component';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'sign-in-banner',
  templateUrl: './sign-in-banner.component.html',
})
export class SignInBannerComponent extends BaseComponent implements OnInit {
  @HostBinding('attr.hidden') hidden = sessionStorage.getItem('signInPromptClosed') !== 'true' ? null : 'hidden';
  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<string>;

  constructor(private clubConfigurationService: ClubConfigurationService) {
    super();
  }

  ngOnInit() {
    if (this.clubConfigurationService.serverConfig.allowLogin === ServerLogin.No) {
      this.hidden = ''; // sets it as "hidden"
    }

    this.using(
      this.productGroupFlow$.subscribe((productGroupFlow) => {
        if (productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
          this.hidden = 'hidden';
        }
      })
    ).attach(this);
  }

  close(e = null) {
    this.hidden = 'hidden';
    sessionStorage.setItem('signInPromptClosed', 'true');
  }
}
