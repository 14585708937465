import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ICurrentUserInformation } from 'src/app/models/user.model';
import { IGaUserCookie, IGaUserCookieMemberStatus } from 'src/app/models/data.interfaces';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GoogleUserCookieService {
  // AMA Innovation Center or AMA Information Cookie? Take your pick!
  private readonly cookieName = 'AMAIC';

  constructor(private readonly cookieService: CookieService) {}

  updateFromCurrentUser(user: ICurrentUserInformation): void {
    const cookie = this.getCookie();
    // Make sure to set the value to a boolean
    cookie.isLoggedIn = user != null && user.isAuthenticated ? true : false;

    // Update the cookie based on the user information
    if (user != null && user.profile != null) {
      cookie.memberStatus = user.profile.membershipNumber != null ? IGaUserCookieMemberStatus.member : IGaUserCookieMemberStatus.notMember;
    } else {
      // Check if the member status is accurate
      const validValues = [
        IGaUserCookieMemberStatus.enteredNumberManually,
        IGaUserCookieMemberStatus.member,
        IGaUserCookieMemberStatus.notMember,
      ];
      if (validValues.indexOf(cookie.memberStatus) === -1) {
        cookie.memberStatus = IGaUserCookieMemberStatus.unknown;
      }
    }
    this.setCookie(cookie);
  }

  updateMembershipStatus(status: IGaUserCookieMemberStatus): void {
    const cookie = this.getCookie();
    cookie.memberStatus = status;
    this.setCookie(cookie);
  }

  private setCookie(cookieValue: IGaUserCookie): void {
    this.cookieService.set(this.cookieName, JSON.stringify(cookieValue));
  }

  public getCookie(): IGaUserCookie | null {
    if (this.cookieService.check(this.cookieName)) {
      try {
        return JSON.parse(this.cookieService.get(this.cookieName));
      } catch (e) {
        // Ignore parsing errors
      }
    }

    return {
      isLoggedIn: false,
      memberStatus: IGaUserCookieMemberStatus.unknown,
    };
  }
}
