import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../base-component.component';
import { select, NgRedux, dispatch } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { IMedicalQuestionnaire, ITravellerAge } from 'src/app/models/data.interfaces';
import { IAppState } from 'src/app/models/app-state.model';
import { MedicalQuestionnaireService } from 'src/app/core/services/medical-questionnaire.service';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import {
  IMedicalQuestionnaireAction,
  IMedicalQuestionnaireAnswerAction,
  IMedicalQuestionnaireAnswerItem,
} from 'src/app/store/medical-questionnaire.reducer';
import { map } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';

interface IAnswerState {
  [itemId: number]: string;
}

interface IElementState {
  [index: number]: { title: string; isQuestion: boolean };
}

interface IStringsToFormat {
  question: string;
  text: string;
  replaceText: string;
}

@Unsubscribe()
@Component({
  selector: 'questionnaire-modal',
  templateUrl: './questionnaire-modal.component.html',
  styleUrls: ['./questionnaire-modal.component.scss'],
})
export class QuestionnaireModalContentComponent extends BaseComponent implements OnInit, AfterViewInit {
  public travellerIndex: number;
  public isLoading = false;
  public isGoingNext = false;
  public isGoingBack = false;

  @select(['tripDetails', 'agesOfTravellers']) readonly agesOfTravellers$: Observable<ITravellerAge[]>;
  public age$: Observable<number>;
  @select('medicalQuestionnaires') readonly medicalQuestionnaires$: Observable<IMedicalQuestionnaire[]>;
  public questionnaire$: Observable<IMedicalQuestionnaire>;

  public answerState$: Observable<IAnswerState>;

  public elementState$: Observable<IElementState>;

  public stringsToFormat: IStringsToFormat[] = [
    { question: '6', text: '12 months', replaceText: '<i><strong>12 months</strong></i>' },
    // Medical Condition already has bolt and italic styling, need to remove font to make it display correctly
    { question: '6', text: '<font color="#0000ff">', replaceText: '' },
    {
      question: '6',
      text: 'ANY other prescription medications',
      replaceText: '<i><strong>ANY other prescription medications</strong></i>',
    },
  ];

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly reduxStore: NgRedux<IAppState>,
    public readonly medicalQuestionnaireService: MedicalQuestionnaireService,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
    this.age$ = this.agesOfTravellers$.pipe(map((x) => x && x[this.travellerIndex] && x[this.travellerIndex].age));
    this.questionnaire$ = this.medicalQuestionnaires$.pipe(
      map((questionnaires) => {
        return questionnaires != null && questionnaires.find((q) => q.relatesToTravellerIndex === this.travellerIndex);
      })
    );
    this.answerState$ = this.questionnaire$.pipe(
      map((q) => {
        const answerState = {} as IAnswerState;
        if (q && q.answers) {
          q.answers.forEach((answer) => {
            answerState[answer.questionID] = answer && answer.answer;
          });
        }
        return answerState;
      })
    );
    this.elementState$ = this.questionnaire$.pipe(
      map((q) => {
        const elementState = {} as IElementState;
        if (q && q.currentSection && q.currentSection.elements) {
          q.currentSection.elements.forEach((item, index) => {
            // Get title attribute text to display as tooltip
            const el: HTMLDivElement = document.createElement('div');
            el.innerHTML = item.text;
            let title;
            let term;
            let termCapitalized;
            if (el.getElementsByTagName('span')[0]) {
              term = el.getElementsByTagName('strong')[0].innerText.slice(0, -1);
              termCapitalized = term.charAt(0).toUpperCase() + term.slice(1);
              title = termCapitalized + ': ' + el.getElementsByTagName('span')[0].getAttribute('title');
            }

            // Checks for a string that starts with a number followed by a dot (.)
            // e.g. "1. Lorem ipsum"
            elementState[index] = {
              title,
              // regex explanation: https://regex101.com/r/tZ9eY4/1
              isQuestion: new RegExp(/^(\d+)\.\s/).test(item.text),
            };
          });
        }
        return elementState;
      })
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.using(
      this.questionnaire$.subscribe((medQ) => {
        if (medQ) {
          this.isLoading = false;
        } else {
          this.loadInitialQuestionnaire();
        }
      })
    ).attach(this);

    this.googleAnalyticsService.setAndSendModalView('medical-questionnaire', 'Medical Questionnaire');
  }

  @dispatch() public setAnswer(questionID: string, answer: string) {
    return {
      type: SalesFunnelActionType.UPDATE_MEDICAL_QUESTIONNAIRE_ANSWER,
      payload: { travellerIndex: this.travellerIndex, answer: answer, questionID: questionID } as IMedicalQuestionnaireAnswerItem,
    } as IMedicalQuestionnaireAnswerAction;
  }

  private loadInitialQuestionnaire() {
    this.isLoading = true;
    this.using(
      this.medicalQuestionnaireService.loadInitial(this.reduxStore.getState(), this.travellerIndex).subscribe({
        next: (response) => {
          if (response) {
            // Put the med q in the store
            this.reduxStore.dispatch({
              type: SalesFunnelActionType.CREATE_MEDICAL_QUESTIONNAIRE,
              payload: response,
            } as IMedicalQuestionnaireAction);
          } else {
            this.hideModal();
          }
          this.isLoading = false;
        },
        error: () => {
          // We were unable to get the initial questionnaire
          this.isLoading = false;
          this.hideModal();
        },
      })
    ).attach(this);
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.isGoingNext = true;
    this.using(
      this.medicalQuestionnaireService.moveToNextSection(this.reduxStore.getState(), this.travellerIndex).subscribe({
        next: (response) => {
          // Put the med q in the store
          this.reduxStore.dispatch({
            type: SalesFunnelActionType.UPDATE_MEDICAL_QUESTIONNAIRE,
            payload: response,
          } as IMedicalQuestionnaireAction);
          this.isGoingNext = false;

          // Did we already complete the questionnaire.
          if (response.isCompleted) {
            this.googleAnalyticsService.sendMedicalQuestionnaireCompletedEvent();
            this.hideModal();
          } else {
            this.scrollToTop();
          }
        },
        error: () => {
          this.isGoingNext = false;
        },
      })
    ).attach(this);
  }

  onGoBack() {
    this.isGoingBack = true;
    this.using(
      this.medicalQuestionnaireService.moveToPreviousSection(this.reduxStore.getState(), this.travellerIndex).subscribe({
        next: (response) => {
          // Put the med q in the store
          this.reduxStore.dispatch({
            type: SalesFunnelActionType.UPDATE_MEDICAL_QUESTIONNAIRE,
            payload: response,
          } as IMedicalQuestionnaireAction);
          this.isGoingBack = false;
          this.scrollToTop();
        },
        error: () => {
          this.isGoingBack = false;
        },
      })
    ).attach(this);
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }

  scrollToTop() {
    // IE and Edge have issues scrolling when using the scroll service.
    // To get around this problem we manually scroll to the top of the modal container.
    const containers = document.getElementsByTagName('modal-container');
    if (containers && containers.length > 0) {
      containers[0].scrollTop = 0;
    }
  }

  modifyTMIText(htmlString: string, targetTexts: IStringsToFormat[]): string {
    const targetTextsToBold = targetTexts.filter(
      (targetText) => htmlString?.includes(targetText.question) && htmlString?.includes(targetText.text)
    );

    if (targetTextsToBold.length === 0) {
      return htmlString;
    }

    let updatedHtmlString = htmlString;

    targetTextsToBold.forEach((targetText) => {
      const regex = new RegExp(targetText.text, 'g');
      updatedHtmlString = updatedHtmlString.replace(regex, targetText.replaceText);
    });

    return updatedHtmlString;
  }
}
