import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { dispatch } from '@angular-redux-ivy/store';
import { updateDepartureDateAndReturnDate } from 'src/app/store/trip-details-common-reductions';
import { Router } from '@angular/router';
import { MatchMediaService } from 'src/app/core/services/match-media.service';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';

@Component({
  selector: 'multi-trip',
  templateUrl: './multi-trip.component.html',
  styleUrls: ['./multi-trip.component.scss'],
})
export class MultiTripComponent {
  @ViewChild('f') ngForm: NgForm;
  @Input() minDepartureDate: Date;
  @Input() maxDepartureDate: Date;
  @Input() analyticsPrefix: string;

  public policyStartDateIsOpen = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private router: Router, private matchMediaService: MatchMediaService) {}

  @dispatch() syncDepartureDateReturnDate(policyStartDate: Date, durationOfFirstTrip: number) {
    const dateParsed = DateHelpers.parseDate(policyStartDate);
    return updateDepartureDateAndReturnDate(dateParsed, durationOfFirstTrip);
  }

  onSubmit(f: NgForm) {
    if (f.form.valid) {
      this.router.navigateByUrl('/trip-details');
    }
  }

  onShowPicker(container) {
    const dayHoverHandler = container.dayHoverHandler;
    const hoverWrapper = function ($event) {
      const { cell, isHovered } = $event;
      // Set 'cell.isHovered = true' to resolve double tap issue on mobile
      cell.isHovered = isHovered;
      return dayHoverHandler($event);
    };
    container.dayHoverHandler = hoverWrapper;
  }
}
