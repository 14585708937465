<div class="modal-header">
  <i class="modal-header__icon-warning"></i>
  <h4 class="modal-title"
      [innerText]="(title | translate)"></h4>
  <button type="button"
          class="u-close u-close--light-hover"
          data-dismiss="modal"
          aria-label="Close"
          (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <p [innerText]="(message | translate)"></p>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-primary btn-lg"
          routerLink="/{{ primaryButtonRoute }}"
          (click)="bsModalRef.hide()"
          [innerText]="(primaryButtonText | translate)"></button>
</div>