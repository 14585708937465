import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[loadingSuperflyOverlay]',
})
export class LoadingSuperflyOverlayDirective {
  constructor(private el: ElementRef) {}

  @HostListener('window:resize', ['$event'])
  onResize(e: Event) {
    this.resetWindowShades(this.el.nativeElement);
  }

  resetWindowShades(elRef: HTMLElement) {
    if (!elRef) {
      return;
    }
    elRef.style.transform = 'translate(0, 0)';

    // Find all child elements for each window
    const children = elRef.getElementsByClassName('c-loading-superfly__window');

    if (!children) {
      return;
    }

    // Reset transforms for elements effected by ng draggable
    Array.from(children).forEach((x: HTMLElement) => {
      const winShades = x.getElementsByClassName('c-loading-superfly__window-shade');
      Array.from(winShades).forEach((y: HTMLElement) => {
        y.style.transform = 'translateY(-80%)';
      });

      const dragBlocks = x.getElementsByClassName('c-loading-superfly__drag-block');
      Array.from(dragBlocks).forEach((z: HTMLElement) => {
        z.style.transform = 'none';
      });
    });
  }
}
