import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

// Renamed to avoid collision with the window.location interface
import { Location as CommonLocation } from '@angular/common';

@Injectable()
export class LocationService {
  private urlSubject: ReplaySubject<any>;
  constructor(
    private commonLocation: CommonLocation // private activatedRoute: ActivatedRoute
  ) {
    this.urlSubject = new ReplaySubject<any>(1);
  }

  public path(includeHash?: boolean): string {
    return this.commonLocation.prepareExternalUrl(this.commonLocation.path(includeHash));
  }

  public replaceState(path: string, query?: string): void {
    this.commonLocation.replaceState(path, query);
  }

  public navigate(url: string): void {
    window.location.href = url;
  }

  public replace(url: string): void {
    window.location.replace(url);
  }

  public getQueryParameter(param: string): any {
    // TODO: Add activated route back in
    // this.activatedRoute.params.subscribe((params: Params) => {
    // 	return params[param];
    // });
    // https://stackoverflow.com/questions/38488568/get-route-query-params
    // we'll hack it for now:
    const str = this.path();
    const pieces = str.split('?');
    if (pieces.length > 1) {
      const piece = pieces[pieces.length - 1];
      const paramValue = piece.split('=');
      return paramValue[paramValue.length - 1];
    } else {
      return undefined;
    }
  }

  public getUrl(): Observable<string> {
    return this.urlSubject;
  }

  public setUrl(url: string) {
    url = url || '';
    url = url.trim();
    if (url.startsWith('/')) {
      url = url.substring(1);
    }
    this.urlSubject.next(url);
  }
}
