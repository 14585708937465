import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

import { ClubConfigurationService } from './services/club-configuration.service';
import { CountriesService } from './services/countries.service';
import { ErrorDialogService } from './services/error-dialog.service';
import { GoogleAnalyticsService } from './services/google.analytics.service';
import { MatchMediaService } from './services/match-media.service';
import { MembershipService } from './services/membership.service';
import { MonerisConfigurationService } from './services/moneris-configuration.service';
import { PurchaseMapper } from './services/purchase-mapper.service';
import { QuoteMapper } from './services/quote-mapper.service';
import { QuoteService } from './services/quote.service';
import { WindowRefService } from './services/window-ref.service';
import { UserService } from './services/user.service';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { ReduxStoreStateRetrievalService } from './services/redux-store-state-retrieval.service';
import { MedicalQuestionnaireService } from './services/medical-questionnaire.service';
import { GoogleUserCookieService } from './services/google-user-cookie.service';
import { RenewService } from './services/renew.service';
import { SessionTimeoutService } from '../components/session-timeout-modal/session-timeout-modal';
import { PosternService } from './services/postern.service';
import { UrlSigningService } from './services/url-signing.service';

// Augmentations
import './augmentation/redux-store';
import './augmentation/forms';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, FormsModule],
  // These services are provided in AppModule because they are all provided in root,
  // However, they are also declared here so that any components that make use of them
  // that are declared in this module can receive the same singleton instances that
  // are provided in AppModule
  providers: [
    ClubConfigurationService,
    CountriesService,
    ErrorDialogService,
    GoogleAnalyticsService,
    MatchMediaService,
    MembershipService,
    MonerisConfigurationService,
    PurchaseMapper,
    QuoteMapper,
    QuoteService,
    UrlSigningService,
    RenewService,
    UserService,
    WindowRefService,
    MedicalQuestionnaireService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ReduxStoreStateRetrievalService,
    GlobalErrorHandler,
    GoogleUserCookieService,
    CookieService,
    SessionTimeoutService,
    PosternService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    this.throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  private throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
    if (parentModule) {
      throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
