import { ClubConfigurationService } from './core/services/club-configuration.service';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, config } from 'rxjs';

class TranslateConfigLoader implements TranslateLoader {
  constructor(private configService: ClubConfigurationService) {}
  public getTranslation(lang: string): Observable<Record<string, unknown>> {
    return of(this.configService.appConfig.i18n[lang]);
  }
}

// AoT requires an exported function for factories
export function TranslateLoaderFactory(configService: ClubConfigurationService) {
  return new TranslateConfigLoader(configService);
}
