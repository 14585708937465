// This service may seem redundant to wrap NgRedux<IAppStore> but it is needed
// because calling on Angular's injector.get method doesn't support retrieving
// the NgRedux<IAppState> service in that way, so we created this to wrap it.
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState } from 'src/app/models/app-state.model';

@Injectable({
  providedIn: 'root',
})
export class ReduxStoreStateRetrievalService {
  constructor(private readonly reduxStore: NgRedux<IAppState>) {}

  public getState(): IAppState {
    return this.reduxStore.getState();
  }
}
