import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';

import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDialogService } from './error-dialog.service';
import { ReduxStoreStateRetrievalService } from './redux-store-state-retrieval.service';
import { GoogleAnalyticsService } from './google.analytics.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: Error): void {
    // Check if this error should be ignored before continuing
    if (this.ignoreError(error)) {
      return;
    }

    const reduxStore = this.injector.get<ReduxStoreStateRetrievalService>(ReduxStoreStateRetrievalService);

    const state = reduxStore.getState();

    let productGroupFlow = 'Unknown';
    let productSelected = 'Unknown';

    if (state != null && state.tripDetails != null) {
      productGroupFlow = state.tripDetails.productGroupFlow;
      productSelected = state.tripDetails.productSelected;
    }

    // Don't track the error to AppInsights if it is an HttpErrorResponse
    // The HttpErrorInterceptor look after that.
    if (!(error instanceof HttpErrorResponse)) {
      AppInsights.trackException(error, null, {
        'product-flow': productGroupFlow,
        'product-selected': productSelected,
      });
      AppInsights.flush();

      const googleAnalyticsService = this.injector.get<GoogleAnalyticsService>(GoogleAnalyticsService);
      googleAnalyticsService.sendClientError();
    }

    if (environment.production) {
      const errorDialogService = this.injector.get(ErrorDialogService);

      errorDialogService.showErrorMessage('An unexpected error occurred');
    } else {
      // If we aren't in production, then we want to see the stack trace in the console.
      // NOTE: This is the only time we really ever want to allow console methods to be used.
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  /**
   * There are certain error messages which are generated by internal components
   * that we need to ignore in app insights.
   */
  private ignoreError(e: Error): boolean {
    // This error will be resolved by:
    // https://github.com/angular/angular/pull/30630/commits
    if (e && e.message && e.message.indexOf('Cannot set property scrollRestoration') !== -1) {
      return true;
    }
    return false;
  }
}
