<header class="c-other-header bg-dark py-4">
  <div class="container">
    <div class="row">
      <div class="col px-0">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark header-home-link">
          <a class="navbar-brand"
             routerLink="/coverage"
             routerLinkActive="active"
             analytic-ama="form">
            <img src="{{ 'header_image_url' | translate }}"
                 alt="{{ 'title' | translate }}" />
          </a>
          <button class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse"
               id="navbarCollapse">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <a class="nav-link"
                   routerLink="/coverage"
                   routerLinkActive="active">Home</a>
              </li>
            </ul>
            <form class="form-inline mt-2 mt-md-0">
              <input class="form-control mr-sm-2"
                     type="text"
                     placeholder="Search"
                     aria-label="Search" />
              <button class="btn btn-outline-info my-2 my-sm-0"
                      type="submit">
                Search
              </button>
            </form>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
