import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../base-component.component';

@Component({
  selector: 'loading-superfly-overlay',
  templateUrl: './loading-superfly-overlay.component.html',
})
export class LoadingSuperflyOverlayComponent extends BaseComponent implements OnInit, AfterViewInit {
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true,
  };
  win1Position: string;
  win2Position: string;
  win3Position: string;
  animateWindowShades: boolean;
  animationTimeout: number;
  displayMessage: string;
  allMessages;
  messageTimeout: number;
  doEasterEgg: boolean;
  animateRibbon: boolean;
  ribbonTimeout: number;

  constructor(public bsModalRef: BsModalRef) {
    super();
    this.allMessages = new Array(
      'This may take some time...',
      'Please bear with us...',
      'Thinking really hard...',
      'Would you prefer chicken, steak or tofu?'
    );
  }

  ngOnInit() {
    this.animateWindowShades = false;
    this.animateRibbon = false;
    this.doEasterEgg = false;
    this.cycleMessages();
  }

  ngAfterViewInit() {
    // Start the animation in a setTimeout to get the animation working in IE
    this.animationTimeout = this.usingTimeout(
      window.setTimeout(() => {
        this.animateWindowShades = true;
      }, 1000)
    );
  }

  /**
   * Cycle through the text displayed below the title in the ribbon
   */
  cycleMessages() {
    // get the first message
    this.displayMessage = this.allMessages[0];
    // delete the first value
    this.allMessages.splice(0, 1);
    // add the value at the end of the array
    this.allMessages.push(this.displayMessage);
    // run it again for the next item
    this.messageTimeout = this.usingTimeout(
      window.setTimeout(() => {
        this.cycleMessages();
      }, 4000)
    );
  }

  /**
   * Toggle window shade animation to infinite loop
   */
  toggleEasterEgg() {
    this.doEasterEgg = !this.doEasterEgg;
    this.animateRibbon = true;
    this.ribbonTimeout = this.usingTimeout(
      window.setTimeout(() => {
        this.animateRibbon = false;
      }, 1000)
    );
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  /**
   * Set the position of the window shade
   * @param event The drag position (x and y coordinates)
   * @param windowId Idenfity which window shade is being dragged
   */
  setWindowShadePosition(event, windowId) {
    switch (windowId) {
      case 'window1':
        this.win1Position = this.invertedPositionValue(event.y);
        break;
      case 'window2':
        this.win2Position = this.invertedPositionValue(event.y);
        break;
      case 'window3':
        this.win3Position = this.invertedPositionValue(event.y);
        break;
    }
  }

  /**
   * Invert the value to correctly move the window shade
   * @param val The y coordinate of the element being dragged
   */
  invertedPositionValue(val) {
    return `translateY(${val}px) translateY(-80%)`;
  }
}
