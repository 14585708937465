<div *injector="{
  questionnaire: questionnaire$,
  age: age$,
  answerState: { observable: answerState$, initialValue: {} },
  elementState: { observable: elementState$, initialValue: {} }
};
let questionnaire = questionnaire;
let age = age;
let answerState = answerState;
let elementState = elementState;
"
     class="o-mq">
  <div id="questionnaire-modal-header"
       class="modal-header pb-0 bg-white text-dark border-0">
    <h1 class="modal-title h2 my-2">Medical Questionnaire</h1>
    <p class="h5">Traveller {{ travellerIndex + 1}}: Age {{ age }}</p>
    <button type="button"
            class="close medical-questionnaire-close"
            analytic-ama="form"
            aria-label="Close"
            (click)="bsModalRef.hide()"
            tabindex="-1">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="media o-mq__first-step-message"
         *ngIf="questionnaire?.isAtStart">
      <figure>
        <svg-icon name="bell" />
      </figure>
      <div class="media-body align-self-center">
        <p class="my-0">{{ 'questionnaire.lead' | translate }}</p>
      </div>
    </div>

    <form #answerForm="ngForm"
          analytic-ama="form"
          class="medical-questionnaire-form"
          (ngSubmit)="onSubmit()"
          style="min-height: 5rem;">

      <loading-overlay *ngIf="isLoading" />
      <ng-container *ngIf="questionnaire?.currentSection?.elements?.length">
        <ng-container *ngFor="let item of questionnaire?.currentSection?.elements; let i = index;">
          <ng-container *ngIf="elementState[i].isQuestion">
            <div class="o-mq__separator">
              <!--{{item | json}}-->
            </div>
          </ng-container>
          <div class="o-mq__item {{ item.elementType | lowercase }}"
               [ngClass]="{
                    'note': item.elementType === 'Note',
                    'questionyesno': item.elementType === 'QuestionYesNo'
                  }">
            <div [innerHTML]="modifyTMIText(item.text, stringsToFormat) | safeHtml">
            </div>
            <button *ngIf="elementState[i].title"
                    type="button"
                    class="btn btn-link m-0 p-0"
                    tooltip="{{ elementState[i].title }}"
                    container="body"
                    placement="left"
                    tabindex="-1">
              <svg-icon name="question_mark_circle" />
            </button>

            <div *ngIf="item.elementType === 'QuestionYesNo'"
                 class="form-check form-check-inline form-check--border">
              <input ngControl
                     [ngModel]="answerState[item.id]"
                     id=" {{item.id}}-yes "
                     [name]="item.id"
                     type="radio"
                     value="True"
                     class="form-check-input question-answered"
                     required
                     (ngModelChange)="setAnswer(item.id, $event)"
                     analytic-ama="form" />
              <label for=" {{item.id}}-yes "
                     class="form-check-label rounded">Yes</label>
              <input ngControl
                     [ngModel]="answerState[item.id]"
                     id=" {{item.id}}-no "
                     [name]="item.id"
                     type="radio"
                     value="False"
                     checked
                     class="form-check-input question-answered"
                     required
                     (ngModelChange)="setAnswer(item.id, $event)"
                     analytic-ama="form" />
              <label for=" {{item.id}}-no "
                     class="form-check-label rounded">No</label>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>

  </div>
  <div class="modal-footer justify-content-between">
    <button type="button"
            class="btn btn-link btn-lg btn--flex text-decoration-none submit-back"
            [ngClass]="{'btn--loading': isGoingBack}"
            [hidden]="questionnaire?.isAtStart"
            [disabled]="isLoading"
            (click)="onGoBack()"
            analytic-ama="form">
      <span class="px-1">{{ 'questionnaire.back_button_title' | translate }}</span>
    </button>
    <button type="submit"
            class="btn btn-secondary btn-lg btn--flex ml-auto submit-continue"
            [ngClass]="{'btn--loading': isGoingNext}"
            [disabled]="!answerForm || !answerForm.form.valid || isLoading"
            (click)="onSubmit()"
            analytic-ama="form">
      <span class="px-1">{{ 'questionnaire.next_button_title' | translate }}</span>
    </button>
  </div>
</div>