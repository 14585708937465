import { Component, AfterViewInit, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { BaseComponent } from '../base-component.component';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WindowRefService } from 'src/app/core/services/window-ref.service';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AttachedSubscription, Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'session-timeout-modal',
  templateUrl: './session-timeout-modal.html',
})
export class SessionTimeoutModalComponent extends BaseComponent implements AfterViewInit {
  public countdown: number;
  constructor(
    public bsModalRef: BsModalRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private sessionTimeoutService: SessionTimeoutService,
    private idle: Idle
  ) {
    super();
    this.googleAnalyticsService.setAndSendModalView('session-timeout', 'Session Timeout');
    this.countdown = this.idle.getTimeout();
    this.using(
      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.countdown = countdown;
      })
    ).attach(this);
  }

  close() {
    this.bsModalRef.hide();
    this.sessionTimeoutService.watchForIdle();
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }
}

@Unsubscribe()
@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private onTimeoutWarningSubscription: AttachedSubscription;

  private _hasTimedOut = false;
  public get hasTimedOut() {
    return this._hasTimedOut;
  }

  public constructor(
    private modalService: BsModalService,
    private idle: Idle,
    private windowService: WindowRefService,
    private clubConfigurationService: ClubConfigurationService
  ) {
    this.idle.onTimeout
      .subscribe(() => {
        this._hasTimedOut = true;
        // The initial route guard will redirect to the correct base url
        // based on the current url.
        this.windowService.reload();
      })
      .attach(this);
  }

  public watchForIdle(): void {
    this.idle.stop();
    if (this.onTimeoutWarningSubscription) {
      this.onTimeoutWarningSubscription.unsubscribe();
    }

    // Reset the configuration
    this.idle.setIdle(this.getTimeoutProperty('idle_timeout_in_seconds'));
    this.idle.setTimeout(this.getTimeoutProperty('idle_popup_timeout_in_seconds'));
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Start watching for idle again
    this.onTimeoutWarningSubscription = this.idle.onTimeoutWarning
      .pipe(take(1))
      .subscribe(() => {
        // Only the modal can stop the timeout after the warning has started
        this.idle.clearInterrupts();
        this.modalService.show(SessionTimeoutModalComponent, {
          backdrop: true,
          ignoreBackdropClick: true,
          class: 'modal-dialog-centered',
        });
      })
      .attach(this);
    this.idle.watch();
  }

  private getTimeoutProperty(name: string): number {
    try {
      const value: number = parseInt(localStorage.getItem(name), 10);
      if (!isNaN(value)) {
        return value;
      }
    } catch (e) {}
    return this.clubConfigurationService.appConfig[name];
  }
}
