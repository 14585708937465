import { IMedicalFee } from './medical-fee.model';

export interface IAppConfig {
  /**
   * These are base settings for the application.
   */
  settings: IAppConfigSettings;
  /**
   * The localization object which contains all keys.
   */
  i18n: any;
  /**
   * The products catalog available to the application.
   * The valid values for the key property of this dictionary can be found in
   * the InsuranceProduct enum located in the products.ts file.
   */
  products: { [code: string]: IProduct };
  policy_details: IPolicyDetails[];
  page_details: { [code: string]: IPageDetails };
  /**
   * How long to wait before a session is considered idle.
   */
  idle_timeout_in_seconds: number;
  /**
   * How long to wait before refreshing the page after the
   * session timeout popup is shown.
   */
  idle_popup_timeout_in_seconds: number;
}

export interface IProduct {
  code: string;
  name: string;
  deductible_amounts: Array<number>;
  coverage_durations?: Array<number>;
  coverage_options?: Record<string, any>;
  medical_fees?: IMedicalFee[];
  hasDeductibleDiscount: boolean;
  preExEnabled: boolean;
}

export interface IPolicyDetails {
  id: string;
  description: string;
  sort_order: number;
  display_text?: { [code: string]: string };
  display_tooltip?: { [code: string]: string };
  product_list: string[];
}
export interface IPageDetails {
  title: string;
  description: string;
}
export interface IAppConfigSettings {
  /**
   * The tenant for the app.
   */
  tenant: string;
  /**
   * The tenant name.
   */
  tenant_name: string;
  /**
   * The assets folder path.
   */
  assets: string;
  /**
   * Minimum age to be considered as seniors, e.g. medical questionaire will be asked.
   */
  minimum_age_for_seniors: number;
  /**
   * Maximum age for Visitor for Canada Plan
   */
  maximum_age_for_visitors_to_canada: number;
  /**
   * The maximum age for any traveller.
   */
  maximum_age_for_traveller: number;
  /**
   * Holdes the number that activates the family rate available prompt on the trip details screen.
   */
  family_rate_prompt_activation_number: number;
  /**
   * Maximum number for group travellers.
   */
  maximum_number_of_travellers: number;
  /**
   * Toggle availibilities of each product on home page.
   */
  is_single_trip_enabled: boolean;
  is_multi_trip_enabled: boolean;
  is_trip_cancellation_enabled: boolean;
  is_visitor_to_canada_enabled: boolean;
  is_tst_widget_enabled: boolean;
  is_member_join_widget_enabled: boolean;
  is_rvd_enabled: boolean;
  join_ama_link: string;
  join_ama_link_requires_signing: boolean;
  membership_number_prefix: string;
  membership_number_length: number;
  supported_province_codes: string[];
  maximum_rental_days: number;
}

export enum ServerLogin {
  No = 'No',
  Password = 'Password',
  Token = 'Token',
}

export interface IServerConfig {
  applicationInsightsKey: string;
  clientIpAddress: string;
  allowLogin: ServerLogin;
}
