import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base-component.component';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { select, dispatch, NgRedux } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { InsuranceProduct, IAppState, IQuoteOption, ITripDetails, ICurrentUserInformation } from 'src/app/models/data.interfaces';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { UserService } from 'src/app/core/services/user.service';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { QuoteService } from 'src/app/core/services/quote.service';
import { TravellerAgesComponent } from '../traveller-ages/traveller-ages.component';
import { TripDetailsService } from '../trip-details/trip-details.service';
import { map } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'renew-trip-details',
  templateUrl: './renew-trip-details.component.html',
  styleUrls: ['./renew-trip-details.component.scss'],
  providers: [TripDetailsService],
})
export class RenewTripDetailsComponent extends BaseComponent implements OnInit {
  @select(['tripDetails']) readonly tripDetails$: Observable<ITripDetails>;
  @select('quoteOptions') readonly quoteOptions$: Observable<IQuoteOption[]>;

  public familyRatePromptActivationNumber: number;
  public minimumAgeForSeniors: number;

  public startDateIsOpen = false;
  public startDate: Date;

  public isLoading = false;
  public loggedIn: boolean;

  public isPremiumPlan: boolean;
  public lastPolicyHasSeniors: boolean;
  public premiumPlanUnavailable: boolean;

  public DateHelpers = DateHelpers;

  constructor(
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly clubConfigurationService: ClubConfigurationService,
    private readonly quoteService: QuoteService,
    private readonly tripDetailsService: TripDetailsService
  ) {
    super();
    this.familyRatePromptActivationNumber = this.clubConfigurationService.appConfig.settings.family_rate_prompt_activation_number;
    this.minimumAgeForSeniors = this.clubConfigurationService.appConfig.settings.minimum_age_for_seniors;
  }

  ngOnInit() {
    this.loggedIn = this.userService.currentUser && this.userService.currentUser.isAuthenticated;
    this.using(
      this.userService.currentUserChanged.subscribe((currentUser: ICurrentUserInformation) => {
        this.loggedIn = currentUser.isAuthenticated;
      })
    ).attach(this);
    this.using(
      this.reduxStore
        .select((x) => x.tripDetails)
        .subscribe((tripDetails) => {
          this.startDate = tripDetails.policyStartDate;
          this.isPremiumPlan = tripDetails.prevPolicyProductSelected !== InsuranceProduct.MultiTripMedicalPlan;
        })
    ).attach(this);
    this.using(
      this.reduxStore
        .select((x) => x.lastPolicy.tripDetails.agesOfTravellers)
        .subscribe((ages) => {
          this.lastPolicyHasSeniors = ages && ages.some((x) => x.age >= this.minimumAgeForSeniors);
        })
    ).attach(this);

    // Hide the policy matrix after changes to the trip details.
    this.using(this.tripDetailsService.clearQuoteOptionsOnChange().subscribe()).attach(this);
  }

  onSubmit(form: NgForm, travellerAges: TravellerAgesComponent) {
    if (this.isLoading || !form.form.valid || !travellerAges.onSubmit()) {
      return;
    }

    this.isLoading = true;
    this.using(
      this.quoteService.renewalQuoteOptions().subscribe((quoteOptions) => {
        this.isLoading = false;
        this.premiumPlanUnavailable = false;
        if (quoteOptions.length > 0) {
          // For premium, don't show policy matrix, go to next step
          if (this.isPremiumPlan) {
            this.insuranceProductSelected(this.reduxStore.get((x) => x.tripDetails.productSelected));
            this.router.navigate(['renew', 'review-quote']);
          } else {
            this.tripDetailsService.scrollToPolicyMatrix();
          }
        } else if (this.isPremiumPlan) {
          this.premiumPlanUnavailable = true;
        }
      })
    ).attach(this);
  }

  // Policy Matrix Methods
  @dispatch() insuranceProductSelected = (selected: InsuranceProduct | string) => ({
    type: SalesFunnelActionType.UPDATE_POLICY_PRODUCT,
    tripDetailsState: { productSelected: selected },
  });
}
