import { dispatch, NgRedux, select } from '@angular-redux-ivy/store';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/components/base-component.component';
import { CountriesService } from 'src/app/core/services/countries.service';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { ICountry, ISubRegion } from 'src/app/models/country.model';
import {
  IAppState,
  IMedicalQuestionnaire,
  ITravellerAge,
  ITripDetails,
  IQuoteOption,
  IServerError,
  ICurrentUserInformation,
  IAppConfigSettings,
} from 'src/app/models/data.interfaces';
import { CANADA_ONLY_CODE, ProductGroup } from 'src/app/models/products';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { fetchQuoteOptions } from 'src/app/store/trip-details-quote-option-retrieval.middleware';
import { UserService } from 'src/app/core/services/user.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TravellerAgesComponent } from '../traveller-ages/traveller-ages.component';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { TripDetailsService } from './trip-details.service';
import { Unsubscribe } from 'amaweb-tsutils';
import { TenantEnum } from 'src/app/models/tenant.enum';

@Unsubscribe()
@Component({
  selector: 'trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss'],
  providers: [TripDetailsService],
})
export class TripDetailsComponent extends BaseComponent implements OnInit {
  TenantEnum = TenantEnum;
  familyRatePromptActivationNumber: number;
  public countrySelected: ICountry;
  public subRegionSelected: ISubRegion;

  isLoading: boolean;
  @select('tripDetails') readonly tripDetails$: Observable<ITripDetails>;
  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<string>;
  @select('quoteOptions') readonly quoteOptions$: Observable<IQuoteOption[]>;
  @select('lastServerError') readonly lastServerError$: Observable<IServerError>;
  destinationSelected$: Observable<string>;
  provinceSelected$: Observable<string>;

  appConfigSetting: IAppConfigSettings;
  productGroup = ProductGroup;
  isTCIFlow: boolean;
  tenant: string;
  questionnaires: IMedicalQuestionnaire[] = [];

  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  noCountryFound = false;
  noSubRegionFound = false;
  countries: ICountry[] = [];

  ageOfTravellers: ITravellerAge[] = [];
  loggedIn: boolean;
  modalRef: BsModalRef;

  constructor(
    private readonly reduxStore: NgRedux<IAppState>,
    private clubConfigurationService: ClubConfigurationService,
    private countriesService: CountriesService,
    private scrollToService: ScrollToService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService,
    private userService: UserService,
    private tripDetailsService: TripDetailsService
  ) {
    super();
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;
    this.tenant = this.appConfigSetting.tenant;
    this.destinationSelected$ = this.tripDetails$.pipe(
      map((a) => {
        let code = a && a.countrySelected && a.countrySelected.code;

        if (code == null) {
          return '';
        }

        if (a.subRegionSelected != null) {
          code = code + '|' + a.subRegionSelected.code;
        }
        return code;
      })
    );

    this.provinceSelected$ = this.tripDetails$.pipe(
      map((a) => {
        const code = a && a.subRegionSelected && a.subRegionSelected.code;

        return code || '';
      })
    );

    this.familyRatePromptActivationNumber = this.clubConfigurationService.appConfig.settings.family_rate_prompt_activation_number;
    // Load the countries from the API
    this.countries = this.countriesService.getCountries();

    // Get the redux state value to be used both in the template and component code.
    this.using(
      this.tripDetails$
        .pipe(
          tap((tripDetails) => {
            this.countrySelected = tripDetails.countrySelected;
            this.subRegionSelected = tripDetails.subRegionSelected;

            // Given the redux model, we will replace the selected country with the API data.
            if (this.countries && this.countrySelected) {
              // Get the country from the api results. This is needed for the
              // subregion drop down.
              const apiCountry = this.countries.find((x) => x.code === this.countrySelected.code);
              if (apiCountry) {
                this.countrySelected = apiCountry;

                // Now try and set the correct subRegion

                if (apiCountry.subRegions && this.subRegionSelected) {
                  this.subRegionSelected = apiCountry.subRegions.find((x) => x.code === this.subRegionSelected.code);
                }
              }
            }
          })
        )
        .subscribe()
    ).attach(this);

    this.loggedIn = this.userService.currentUser && this.userService.currentUser.isAuthenticated;

    this.using(
      this.userService.currentUserChanged.subscribe((currentUser: ICurrentUserInformation) => {
        this.loggedIn = currentUser.isAuthenticated;
      })
    ).attach(this);
  }

  ngOnInit() {
    // Hide the policy matrix after changes to the trip details.
    this.using(this.tripDetailsService.clearQuoteOptionsOnChange().subscribe()).attach(this);

    this.using(
      this.quoteOptions$
        .pipe(
          tap((options) => {
            if (this.isLoading) {
              this.isLoading = false;
              // this.modalRef.hide();
            }
            this.isTCIFlow = this.reduxStore.getState().tripDetails.productGroupFlow === ProductGroup.TCIFlow;
            if (options.length > 0 && this.reduxStore.getState().tripDetails.productGroupFlow !== ProductGroup.VisitorsToCanadaFlow) {
              this.tripDetailsService.scrollToPolicyMatrix();
            }
          })
        )
        .subscribe()
    ).attach(this);

    const state = this.reduxStore.getState();

    if (this.reduxStore.getState().tripDetails && state.tripDetails.productGroupFlow === ProductGroup.VisitorsToCanadaFlow) {
      if (state.tripDetails.countrySelected === null || state.tripDetails.countrySelected.code !== CANADA_ONLY_CODE) {
        this.travellingCountrySelected({ code: CANADA_ONLY_CODE } as ICountry);
      }
    }
  }

  public isTripDetailsPopulatedToRetrieveQuoteOptions(tripDetails: ITripDetails): boolean {
    // No trip details at all?
    if (tripDetails == null) {
      return false;
    }

    // No age of travellers array or it is empty?
    if (tripDetails.agesOfTravellers == null || tripDetails.agesOfTravellers.length === 0) {
      return false;
    }

    // Age of travellers array length isn't equal to the number of travellers
    if (tripDetails.agesOfTravellers.length !== tripDetails.numberOfTravellers) {
      return false;
    }

    // Any of the age of travellers array doesn't have the age set or the age is 0?
    if (
      tripDetails.agesOfTravellers.filter((value: ITravellerAge) => {
        return value == null || value.age == null || value.age < 0;
      }).length > 0
    ) {
      return false;
    }

    // No country selected?
    if (tripDetails.countrySelected == null || tripDetails.countrySelected.code == null || tripDetails.countrySelected.code.length === 0) {
      return false;
    }

    // All good, then we are ready!
    return true;
  }

  travellingCountryOnBlur = (country: ICountry) => {
    if (this.countrySelected && country.code === this.countrySelected.code) {
      return; // avoid unnecessory state change which will trigger network call.
    }
    this.travellingCountrySelected(country);
  };

  @dispatch() travellingDestinationSelected = (destinationCode: string) => {
    let tripDetailsState: any = {};

    const destination = destinationCode.split('|');
    const countryCode = destination[0];
    const subRegionCode = destination.length > 1 ? destination[1] : null;

    if (countryCode) {
      const countrySelected = this.countriesService.getCountryByCode(countryCode);
      tripDetailsState = {
        countrySelected: { code: countryCode, name: countrySelected.name },
        subRegionSelected: subRegionCode
          ? { code: subRegionCode, name: this.countriesService.getSubRegionByCode(countrySelected, subRegionCode).name }
          : null,
      };

      // When not in canada clear out the state of the canada only checkbox
      if (countryCode !== CANADA_ONLY_CODE) {
        tripDetailsState = {
          ...tripDetailsState,
          isTravellingOnlyInCanada: null,
        };
      }
    } else {
      tripDetailsState = {
        countrySelected: null,
        subRegionSelected: null,
        isTravellingOnlyInCanada: null,
      };
    }

    // Send the final payload
    return {
      type: SalesFunnelActionType.UPDATE_TRAVELLING_COUNTRY,
      tripDetailsState,
    };
  };

  @dispatch() travellingCountrySelected = (country: ICountry) => {
    let tripDetailsState: any = {};

    // If there is a country then set it
    if (country) {
      tripDetailsState = {
        countrySelected: { code: country.code, name: country.name },
      };

      // When not in canada clear out the state of the canada only checkbox
      if (country.code !== CANADA_ONLY_CODE) {
        tripDetailsState = {
          ...tripDetailsState,
          isTravellingOnlyInCanada: null,
        };
      }

      // If the countries are no longer the same clear out the subRegion
      if (country !== this.countrySelected) {
        tripDetailsState = {
          ...tripDetailsState,
          subRegionSelected: null,
        };
      }
    } else {
      tripDetailsState = {
        countrySelected: null,
        subRegionSelected: null,
        isTravellingOnlyInCanada: null,
      };
    }

    // Send the final payload
    return {
      type: SalesFunnelActionType.UPDATE_TRAVELLING_COUNTRY,
      tripDetailsState,
    };
  };

  @dispatch() travellingSubRegionSelected = (subRegionCode: string) => {
    if (subRegionCode === null) {
      return {
        type: SalesFunnelActionType.UPDATE_TRAVELLING_SUB_REGION,
        tripDetailsState: { subRegionSelected: { code: null, name: null } },
      };
    }

    const subRegion = this.countrySelected.subRegions.find((x) => x.code === subRegionCode);
    return {
      type: SalesFunnelActionType.UPDATE_TRAVELLING_SUB_REGION,
      tripDetailsState: { subRegionSelected: subRegion },
    };
  };

  onSubmit(f: NgForm, travellerAges: TravellerAgesComponent) {
    if (this.countrySelected == null) {
      this.noCountryFound = true;
      this.scrollToElementWithId('selectCountry');
    } else if (this.countrySelected.subRegions && this.countrySelected.subRegions.length > 0 && this.subRegionSelected === null) {
      // subregion is not required to get quote but we want user to pick one if it's US/CA.
      this.noSubRegionFound = true; // show error block under subRegion list.
      this.scrollToElementWithId('selectProvince');
    } else {
      if (f.form.valid && travellerAges.onSubmit()) {
        const tripDetails = this.reduxStore.get((x) => x.tripDetails);
        // age in fact saved as string in redux store
        if (tripDetails.agesOfTravellers.filter((x) => x.age == null || x.age.toString() === '').length > 0) {
          return;
        }

        // TODO: These changes are from Jason's PR for the new loading indicator.
        // // Show superfly loading indicator
        // const config: ModalOptions = {
        // backdrop: true,
        // ignoreBackdropClick: true,
        // class: 'o-loading-superfly modal-dialog-centered',
        // // initialState: initialState,
        // };
        // this.modalRef = this.modalService.show(LoadingSuperflyOverlayComponent, config);

        this.isLoading = true;
        this.reduxStore.dispatch(fetchQuoteOptions(this.isTripDetailsPopulatedToRetrieveQuoteOptions(tripDetails)));
      }
    }
  }

  scrollToElementWithId(controlId: string) {
    const offset = this.matchMediaService.matches(MEDIA_TYPE.sm_max) ? 250 : 180;

    const config: ScrollToConfigOptions = {
      target: controlId,
      offset: offset,
    };
    this.scrollToService.scrollTo(config);
  }
}
