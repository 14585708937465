import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'travel-deals',
  templateUrl: './travel-deals.component.html',
})
export class TravelDealsComponent {
  travel_deals: any;

  constructor(private readonly translateService: TranslateService) {
    this.translateService
      .get('travel_deals.deals')
      .subscribe((translation) => {
        this.travel_deals = translation;
      })
      .attach(this);
  }
}
