<h1 *ngIf="tenant === TenantEnum.ATA"
    class="h2 mt-3 mt-sm-4 mt-md-5 mb-4 mb-xl-5">
  {{ 'coverage.coverage_type_tile' | translate | uppercase }}
</h1>

<h1 class="h5 mt-3 mt-sm-4 mt-md-5 mb-4 mb-xl-5">
  {{ 'coverage.coverage_needed' | translate }}
</h1>

<tabset *injector="{ productGroupFlow: productGroupFlow$ }; let productGroupFlow = productGroupFlow;"
        type="pills"
        class="c-coverage c-coverage__tab--animate-tab-content"
        [justified]="true">
  <tab [active]="productGroupFlow === productGroup.SingleTripFlow"
       customClass="analytic-ama--link--single-trip"
       [disabled]=" !appConfigSetting.is_single_trip_enabled "
       id="singleTripTab">
    <ng-template tabHeading>
      <section class="c-coverage__tab c-coverage__tab--single-trip"
               (click)="setProductGroupFlow(productGroup.SingleTripFlow); triggerScrollTo(); setCovidCondition(true);">
        <div class="c-coverage__tab-inner"
             analytic-ama="link">
          <figure class="c-coverage__tab-icon">
            <svg-icon name="airplane" />
          </figure>
          <div class="c-coverage__tab-text">
            <span class="c-coverage__tab-title">{{ 'coverage.single_trip.title' | translate }}</span>
            <p class="c-coverage__tab-desc d-none d-md-block">{{ 'coverage.single_trip.precis' | translate }}
            </p>
          </div>
        </div>
        <span class="c-coverage__select d-none d-md-blockZZZ">{{ 'coverage.action_word' | translate }}</span>
      </section>
    </ng-template>

    <!-- Single Trip content -->
    <single-trip id="coverageTypeForm"
                 *ngIf="productGroupFlow === productGroup.SingleTripFlow"
                 [productGroupFlow]="productGroup.SingleTripFlow"
                 translatePrefix="single_trip"
                 [minDepartureDate]="minDepartureDate"
                 [maxDepartureDate]="maxDepartureDate"
                 submitButtonText="{{ 'single_trip.submit' | translate }}"
                 analyticsPrefix="single"
                 analyticClassDeparture="single-departure-date"
                 analyticClassReturn="single-return-date"
                 analyticClassSubmit="single-trip-submit"
                 analyticSubmitId="coverage-single-trip" />

  </tab>

  <tab [active]="productGroupFlow === productGroup.MultiTripFlow"
       customClass="analytic-ama--link--multi-trip"
       [disabled]=" !appConfigSetting.is_multi_trip_enabled "
       id="multiTripTab">
    <ng-template tabHeading>
      <section class="c-coverage__tab c-coverage__tab--multi-trip"
               (click)="setProductGroupFlow(productGroup.MultiTripFlow); triggerScrollTo(); setCovidCondition(true);">
        <div class="c-coverage__tab-inner">
          <figure class="c-coverage__tab-icon">
            <svg-icon name="airplane_double" />
          </figure>
          <div class="c-coverage__tab-text">
            <span class="c-coverage__tab-title">{{ 'coverage.multi_trip.title' | translate }}</span>
            <span class="c-coverage__tab-title">{{ 'coverage.multi_trip.title_bold' | translate }}</span>
            <p class="c-coverage__tab-desc d-none d-md-block">{{ 'coverage.multi_trip.precis' | translate }}
            </p>
          </div>
        </div>
        <span class="c-coverage__select d-none d-md-blockZZZ">{{ 'coverage.action_word' | translate }}</span>
      </section>
    </ng-template>
    <!-- Multi Trip content -->
    <multi-trip id="coverageTypeForm"
                *ngIf="productGroupFlow === productGroup.MultiTripFlow"
                [minDepartureDate]="minDepartureDate"
                [maxDepartureDate]="maxDepartureDate"
                analyticsPrefix="multi" />
  </tab>


  <tab [active]="productGroupFlow === productGroup.TCIFlow"
       customClass="analytic-ama--link--trip-cancellation"
       [disabled]=" !appConfigSetting.is_trip_cancellation_enabled "
       id="tripCancellationTab">
    <ng-template tabHeading>
      <section class="c-coverage__tab c-coverage__tab--trip-cancellation"
               (click)="setProductGroupFlow(productGroup.TCIFlow); triggerScrollTo(); setCovidCondition(false);">
        <div class="c-coverage__tab-inner">
          <figure class="c-coverage__tab-icon">
            <svg-icon name="suitcase" />
          </figure>
          <div class="c-coverage__tab-text">
            <span class="c-coverage__tab-title">{{ 'coverage.trip_cancellation.title' | translate }}</span>
            <p class="c-coverage__tab-desc d-none d-md-block">{{ 'coverage.trip_cancellation.precis' | translate }}
            </p>
          </div>
        </div>
        <span class="c-coverage__select d-none d-md-blockZZZ">{{ 'coverage.action_word' | translate }}</span>
      </section>
    </ng-template>
    <!-- Using Single Trip content because we have shared fields-->
    <single-trip id="coverageTypeForm"
                 *ngIf="productGroupFlow === productGroup.TCIFlow"
                 [productGroupFlow]="productGroup.TCIFlow"
                 [minDepartureDate]="minDepartureDate"
                 [maxDepartureDate]="maxDepartureDate"
                 translatePrefix="single_trip"
                 submitButtonText="{{ 'tci.submit' | translate }}"
                 analyticsPrefix="tci"
                 analyticClassDeparture="tci-departure-date"
                 analyticClassReturn="tci-return-date"
                 analyticClassSubmit="tci-submit"
                 analyticSubmitId="coverage-trip-cancellation" />
  </tab>

  <tab [active]="productGroupFlow === productGroup.VisitorsToCanadaFlow"
       customClass="analytic-ama--link--visitors-to-canada"
       [disabled]=" !appConfigSetting.is_visitor_to_canada_enabled "
       id="visitorToCanadaTab">
    <ng-template tabHeading>
      <section class="c-coverage__tab c-coverage__tab--visit-canada"
               (click)="setProductGroupFlow(productGroup.VisitorsToCanadaFlow); triggerScrollTo(); setCovidCondition(false);">
        <div class="c-coverage__tab-inner">
          <figure class="c-coverage__tab-icon">
            <svg-icon name="canada" />
          </figure>
          <div class="c-coverage__tab-text">
            <span class="c-coverage__tab-title">{{ 'coverage.visitor_to_canada.title' | translate }}</span>
            <p class="c-coverage__tab-desc d-none d-md-block">{{ 'coverage.visitor_to_canada.precis' | translate }}
            </p>
          </div>
        </div>
        <span class="c-coverage__select d-none d-md-blockZZZ">{{ 'coverage.action_word' | translate }}</span>
      </section>
    </ng-template>
    <!-- Visitors to Canada content -->
    <single-trip id="coverageTypeForm"
                 *ngIf="productGroupFlow === productGroup.VisitorsToCanadaFlow"
                 [productGroupFlow]="productGroup.VisitorsToCanadaFlow"
                 translatePrefix="visitors_to_canada"
                 [minDepartureDate]="minDepartureDate"
                 [maxDepartureDate]="maxDepartureDate"
                 analyticsPrefix="visitors"
                 analyticClassDeparture="visitors-arrival-date"
                 analyticClassReturn="visitors-return-date"
                 analyticClassSubmit="visitors-submit"
                 analyticSubmitId="coverage-visitors-to-canada" />
  </tab>

</tabset>

<!-- covid messaging -->
<div *ngIf="showCovidMessage"
     class="u-ama-bg-color--baby-blue c-coverage__covid-message text-center">
  <h3>Important Notice:</h3>
  <p>
    AMA Travel emergency medical plans now include up to $2.5 million CAD if partially vaccinated,
    or up to $5 million CAD if fully vaccinated, in coverage for COVID-19 related illnesses that
    may occur if you are travelling at a time when the Canadian government has issued a related
    Level 3 travel advisory to “avoid non-essential travel” for your destination.
  </p>
  <p>
    <strong><a href="https://www.amatravel.ca/travel-medical-insurance/covid19-endorsement"
         target="_blank"
         class="c-coverage__covid-message-link">Please review the details here</a></strong> for the COVID-19 vaccinations requirements that
    must be met before departure to be eligible for this coverage.
  </p>
</div>