import { ICountry, ISubRegion } from '../../models/country.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countries: ICountry[];

  constructor(private httpClient: HttpClient) {}

  public getCountries(): ICountry[] {
    return this.countries;
  }

  /**
   * Should only be called during app initialization.
   */
  public initialize(): Promise<ICountry[]> {
    return lastValueFrom(
      this.httpClient.get<[ICountry]>(`${environment.baseApiUrl}/country`).pipe(
        tap((response) => {
          this.countries = response;
        })
      ),
      undefined
    );
  }

  public getCanada(): ICountry {
    return this.countries.find((country) => country.code === 'CA');
  }

  public getCountryByName(countryName: string): ICountry {
    return this.countries.find((country) => country.name.toLowerCase() === countryName.toLowerCase());
  }

  public getCountryByCode(countryCode: string): ICountry {
    return this.countries.find((country) => country.code.toLowerCase() === countryCode.toLowerCase());
  }

  public getSubRegionByCode(country: ICountry, subRegionCode: string): ISubRegion {
    return country.subRegions.find((x) => x.code === subRegionCode);
  }
}
