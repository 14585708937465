import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { dispatch, NgRedux } from '@angular-redux-ivy/store';
import { updatePolicyStartDateAndPlanDays } from 'src/app/store/trip-details-common-reductions';
import { Router } from '@angular/router';
import { max as maxDate, addDays } from 'date-fns';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../../base-component.component';
import { ProductGroup } from 'src/app/models/products';
import { IAppState } from 'src/app/models/app-state.model';
import { combineLatest } from 'rxjs';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { Unsubscribe } from 'amaweb-tsutils';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';

@Unsubscribe()
@Component({
  selector: 'single-trip',
  templateUrl: './single-trip.component.html',
  styleUrls: ['./single-trip.component.scss'],
})
export class SingleTripComponent extends BaseComponent implements AfterViewInit {
  /**
   * Needed for the unit tests.
   */
  @ViewChild('f') ngForm: NgForm;
  @ViewChild('departureDate') departureDate: NgModel;
  @ViewChild('returnDate') returnDate: NgModel;
  @Input() minDepartureDate: Date;
  @Input() maxDepartureDate: Date;
  @Input() submitButtonText: string;
  @Input() translatePrefix: string;
  @Input() analyticsPrefix: string;
  @Input() analyticClassDeparture: string;
  @Input() analyticClassReturn: string;
  @Input() analyticClassSubmit: string;
  @Input() analyticSubmitId: string;
  @Input() productGroupFlow: string;
  @Input() hideCTA: boolean;

  public minReturnDate: Date;
  public maxReturnDate: Date;
  public departureDateIsOpen = false;
  public returnDateIsOpen = false;
  public productGroup = ProductGroup;
  private maxRentalDays: number;

  constructor(private router: Router, private reduxStore: NgRedux<IAppState>, private readonly clubConfigurationService: ClubConfigurationService) {
    super();
    this.maxRentalDays = this.clubConfigurationService.appConfig.settings.maximum_rental_days
  }

  ngAfterViewInit(): void {
    this.using(
      this.departureDate.control.valueChanges
        .pipe(
          tap((departureDateValue) => {
            this.minReturnDate = this.minDepartureDate;
            if (departureDateValue) {
              this.minReturnDate = maxDate(this.minDepartureDate, DateHelpers.parseDate(departureDateValue));
            }

            if (this.productGroupFlow === ProductGroup.RentalVehicleDamageFlow) {
              this.maxReturnDate = addDays(this.minReturnDate, this.maxRentalDays - 1);
            } else {
              this.maxReturnDate = addDays(this.minReturnDate, 364);
            }
          })
        )
        .subscribe()
    ).attach(this);

    // CDW allows for changes to the departure date and return date within the
    // same view as the plan days. Therefore we need to keep them synced.
    this.using(
      combineLatest([
        this.reduxStore.select((x) => x.tripDetails.departureDate),
        this.reduxStore.select((x) => x.tripDetails.returnDate),
      ]).subscribe(([departureDate, returnDate]) => {
        if (this.hideCTA) {
          this.reduxStore.dispatch(updatePolicyStartDateAndPlanDays(departureDate, returnDate));
        }
      })
    ).attach(this);
  }

  @dispatch() syncPolicyStartDate(departureDate: Date, returnDate: Date) {
    return updatePolicyStartDateAndPlanDays(departureDate, returnDate);
  }

  onSubmit(f: NgForm) {
    if (f.form.valid && !this.hideCTA) {
      this.router.navigateByUrl('/trip-details');
    }
  }

  onShowPicker(container) {
    const dayHoverHandler = container.dayHoverHandler;
    const hoverWrapper = function ($event) {
      const { cell, isHovered } = $event;
      // Set 'cell.isHovered = true' to resolve double tap issue on mobile
      cell.isHovered = isHovered;
      return dayHoverHandler($event);
    };
    container.dayHoverHandler = hoverWrapper;
  }

  public submit(event: any): boolean {
    return this.ngForm.onSubmit(event);
  }

  // A parent component can use this method to determine if the single trip configuration is valid.
  public isValid(): boolean {
    return this.ngForm.form.valid;
  }
}
