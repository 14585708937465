import { IAppState } from '../models/data.interfaces';
import { GetAppInitialState } from './initial-states';

const localStoreKey = 'quote';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(localStoreKey);
    if (serializedState === null) {
      return undefined;
    }
    let appState: IAppState = JSON.parse(serializedState);
    const today = new Date();
    if (new Date(appState.policyInfo.dateQuoteInitiated) < new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)) {
      appState = GetAppInitialState();
    }
    return appState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(localStoreKey, serializedState);
  } catch {
    // ignore write errors
  }
};

/**
 * Is used to set the local quote to blank, after
 */
export const resetState = () => {
  localStorage.setItem(localStoreKey, undefined);
};
