import { ChangeDetectorRef } from '@angular/core';
/* eslint-disable max-lines */
import { Component, Input, OnInit, OnChanges, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import { TstConfig, TstTabConfig, TST_WIDGET_LABELS, TST_THEMES, Location, ALBERTA_AIRPORTS, REGEX } from 'src/app/models/tst/tst';
import _ from 'underscore';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { TSTService } from 'src/app/core/services/tstService';
import { first } from 'rxjs/operators';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { Unsubscribe } from 'amaweb-tsutils';
import { BaseComponent } from 'src/app/components/base-component.component';
import { DomSanitizer } from '@angular/platform-browser';

@Unsubscribe()
@Component({
  selector: 'tst',
  templateUrl: 'tst.template.html',
  styleUrls: [
    './tst.scss',
    '../styles/commonStyles/tstIcons.scss',
    '../styles/commonStyles/tstForm.scss',
    '../styles/commonStyles/dateRangePickerStyles.scss',
  ],
})
export class TSTComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  public enableTmi: boolean = false;

  @Input()
  public bannerOptions;
  @Input()
  public isBanner: boolean;
  @Input()
  public showValueProp: boolean;
  @Input()
  public showPromo: boolean;
  @Input()
  public theme: string;
  @Input()
  public defaultTab: string;
  @Input()
  public defaultDepartureAirport: string;
  @Input()
  public defaultDestinationAirport: string;
  @Input()
  public defaultDestinationCity: string;

  @Output() public isTSTBannerPage = new EventEmitter<boolean>();
  @Output() public currentTabClicked = new EventEmitter<string>();

  public promoContent;
  public vpContent;
  public TST_THEMES = TST_THEMES;
  public bannerBackgroundImage: string;
  public TST_WIDGET_LABELS = TST_WIDGET_LABELS;
  public currentTab: string;
  public currentTSTLabel: string;
  public clickedTab: string;
  public tabClickedState: boolean;
  public activeForm: TstTabConfig;
  public currentUrl;
  public mq = { mobile: false, tablet: false, desktop: false };
  public locationSelected;
  public updatedLocation;
  public locationState: string; // 'begin'|'live'|'bypassed'|'selected'|'removed'
  public destinationFieldWasCleared: boolean;
  public defaultTabImage: string;
  public featureImage: string;
  public tstConfig: TstConfig;
  public valueTyped: string;
  public isHome: boolean;
  public airports: any[];
  public currentPageMatch; // current page data if current page
  public showCaption: boolean;
  public overrideVP: boolean;
  public overridePromo: boolean;
  public overrideBgImage: boolean;
  public bgImageContent;
  public captionContent;
  public bgImageCaption;
  public bgImageCaptionHtml;
  public defaultTSTImageMobile;
  public overrideEvent;
  private bannerContentTimeout: number;
  public checkedOverride: boolean = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private matchMediaService: MatchMediaService,
    private tstService: TSTService,
    private cd: ChangeDetectorRef,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
    super();

    this.tstService
      .getConfig()
      .pipe(first())
      .subscribe((config) => {
        this.tstConfig = config;
      })
      .attach(this);

    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.updateMQ();
      })
    ).attach(this);
    router.events
      .subscribe((val) => {
        if (val instanceof NavigationEnd || (val instanceof Scroll && (val as Scroll).routerEvent instanceof NavigationEnd)) {
          this.locationState = 'begin';
          this.locationSelected = undefined;
          this.isHome = this.router.url === '/coverage' ? true : false;
          this.currentUrl = this.router.url.replace(/^\/|\/$/g, '');
          this.isTSTBannerPage.emit(false);
        }
      })
      .attach(this);

    this.valueTyped = '';
    this.airports = ALBERTA_AIRPORTS;
  }

  public ngOnInit() {
    this.locationState = 'begin';
    this.currentTab = this.defaultTab;
    this.currentTabClicked.emit(this.currentTab);
    this.init();
  }
  public ngOnChanges() {
    this.init();
  }
  public init() {
    this.updateMQ();
    this.defaultTabImage = this.isBanner ? this.bannerOptions.featuredImage : null;
    this.defaultTab = this.isBanner ? this.bannerOptions.defaultTSTTab : this.defaultTab;
    this.defaultTSTImageMobile = this.isBanner ? this.bannerOptions.defaultTSTImageMobile : null;
    this.theme = hasValue(this.theme) ? this.theme : this.TST_THEMES.DARK;
    this.showPromo = hasValue(this.showPromo) ? this.showPromo : false;
    this.showValueProp = hasValue(this.showValueProp) ? this.showValueProp : false;
    this.setActiveTab();
    this.updateStyles();
    this.isBanner ? this.isTSTBannerPage.emit(true) : this.isTSTBannerPage.emit(false);
  }

  public setCurrentTab(event: any[]) {
    this.overrideEvent = event;
    this.currentTab = event[0].type;
    this.defaultTab = event[1];
    this.featureImage = event[3] ? event[3] : null;
    this.setActiveTab();
    this.currentTSTLabel = this.getFriendlyTSTLabel(this.currentTab);
    this.currentTabClicked.emit(this.currentTSTLabel);
    this.updateStyles();
  }

  public backBtnClicked(event) {
    this.tabClickedState = false;
    this.checkLocationState();
    this.cd.detectChanges();
  }

  private setActiveTab() {
    if (hasValue(this.tstConfig)) {
      this.activeForm = _.find(this.tstConfig.tabs, (tab) => {
        return tab.type == this.currentTab;
      });
    }
  }
  private checkLocationState() {
    if (this.locationSelected === undefined && this.tabClickedState) {
      this.locationState = 'bypassed';
    } else if (hasValue(this.locationSelected) && this.locationSelected.name === null) {
      this.locationState = 'removed';
    } else if (hasValue(this.locationSelected) && hasValue(this.locationSelected.name) && !this.tabClickedState) {
      this.locationState = 'selected';
    } else if (hasValue(this.locationSelected) && hasValue(this.locationSelected.name) && this.tabClickedState) {
      this.locationState = 'live';
    } else this.locationState = 'begin';
    this.cd.detectChanges();
  }
  public updateMQ() {
    this.mq = {
      mobile: this.matchMediaService.matches(MEDIA_TYPE.TravelPhone),
      tablet: this.matchMediaService.matches(MEDIA_TYPE.TravelTablet),
      desktop: this.matchMediaService.matches(MEDIA_TYPE.Desktop),
    };
  }

  public updateStyles() {
    if (this.activeForm) {
      if (this.mq.mobile) {
        this.bannerBackgroundImage = `url(${this.defaultTabImage})`;
      } else if (this.defaultTabImage && this.currentTab == this.defaultTab) {
        this.bannerBackgroundImage = `url(${this.defaultTabImage})`;
      } else if (this.overrideBgImage && this.bgImageContent) {
        this.bannerBackgroundImage = `url(${this.bgImageContent})`;
      } else {
        this.bannerBackgroundImage = `url(${this.activeForm.bannerImageURL})`;
      }
    }
  }

  public selectedLocation(event) {
    this.locationSelected = event;
    this.tabClickedState = false;
    this.checkLocationState();
    this.cd.detectChanges();
  }

  public locationUpdated(event) {
    this.updatedLocation = event;
  }

  public clickedTabState(event) {
    /** output event function to set state of autocomplete, current form/tab, selected location */
    this.tabClickedState = event;
    this.checkLocationState();
    if (this.locationState == 'removed') this.locationSelected = undefined;
    this.cd.detectChanges();
  }

  public tabClicked(event) {
    /** output event function to get the label of clicked tab */
    this.clickedTab = event;
    this.currentTSTLabel = this.getFriendlyTSTLabel(this.clickedTab);
    this.currentTabClicked.emit(this.currentTSTLabel);
  }

  public getFriendlyTSTLabel(label) {
    return this.tstService.getFriendlyTSTLabel(label);
  }

  public destinationFieldCleared(event) {
    this.destinationFieldWasCleared = event;
  }
}
