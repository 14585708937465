import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map } from 'rxjs/operators';
import { FunnelRoutes } from 'src/app/models/funnel-routes.enum';
import { Title, Meta } from '@angular/platform-browser';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { BaseComponent } from '../../base-component.component';
import { Unsubscribe } from 'amaweb-tsutils';
import { IAppConfigSettings } from 'src/app/models/app-config.model';
import { TenantEnum } from 'src/app/models/tenant.enum';

@Unsubscribe()
@Component({
  selector: 'site-layout',
  templateUrl: './site-layout.component.html',
})
export class SiteLayoutComponent extends BaseComponent implements AfterViewInit {
  TenantEnum = TenantEnum;
  readonly activeStep$: Observable<number>;
  FunnelRoutes = FunnelRoutes;
  appConfigSetting: IAppConfigSettings;
  isHomePage: boolean;
  tenant: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clubConfigService: ClubConfigurationService,
    private title: Title,
    private meta: Meta,
    private router: Router,
    private readonly googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
    this.appConfigSetting = this.clubConfigService.appConfig.settings;
    this.tenant = this.appConfigSetting.tenant;
    this.activeStep$ = this.activatedRoute.data.pipe(map((data) => data && data.step));
    this.activatedRoute.url
      .subscribe((url) => {
        this.isHomePage = url.length == 1 && url[0].path === 'coverage';
        if (url && url.length >= 1 && url[0]) {
          const pageInfo = this.clubConfigService.appConfig.page_details[url[0].path];
          if (pageInfo) {
            this.title.setTitle(pageInfo.title);
            this.meta.updateTag({ name: 'description', content: pageInfo.description });
          }
        }
      })
      .attach(this);
    // Handles all SPA internal page navigation
    // load-quote is not triggered or captured here
    this.using(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.googleAnalyticsService.setAndSendPageView();
        }
      })
    ).attach(this);
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }
}
