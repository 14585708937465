import { Component, OnDestroy, ChangeDetectionStrategy, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuoteService } from 'src/app/core/services/quote.service';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, IKeyValuePair, IServerQuotePaymentResponse, IPolicyInfo } from 'src/app/models/data.interfaces';
import { IMonerisConfigurationResponse, MonerisConfigurationService } from 'src/app/core/services/moneris-configuration.service';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/components/base-component.component';
import { InsuranceProduct, multiTripProductIsSelected, ProductGroup } from 'src/app/models/products';
import { PurchaseMapper } from 'src/app/core/services/purchase-mapper.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { GetAppInitialState } from 'src/app/store/initial-states';
import { AppInsights } from 'applicationinsights-js';
import { HeapService } from 'src/app/core/services/heap.service';
import { EligibilityRequirementsModalComponent } from '../policy-matrix/eligibility-requirements-modal.component';
import { UserService } from 'src/app/core/services/user.service';
import { Unsubscribe } from 'amaweb-tsutils';
import { lastValueFrom } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

/**
 * Used by some of the helper functions below for creating the moneris iframe URL.
 */
@Unsubscribe()
@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent extends BaseComponent implements OnDestroy, AfterViewInit {
  /**
   * We need direct access to the moneris iframe so we can attach event listeners
   * and post messages.
   */
  @ViewChild('monerisFrame') monerisFrame: ElementRef;
  @ViewChild('autoRenewCheck') autoRenewCheck: ElementRef;

  /*
    Chrome on iOS has a bizarre behaviour where just attempting to get focus into the iFrame
    causes the Moneris page to post a message even though we haven't submitted anything.
    This field is used to guard against that behaviour so that validation doesn't fire
    until the user actually attempts to process payment by submitting the form.
  */
  private formSubmitted = false;

  // Show loading by default
  showLoading = true;
  showSecureCardFrame = false;
  enableSubmit = false;
  isMultiTripSelected = false;
  eligibilityLinkId = 'eligibilityLinkId';
  productGroup = ProductGroup;
  productGroupFlow: string;

  public acceptedTermsAndConditions = false;
  termsAndConditionsErrorMsg: string;

  // Code Appends 'C' due to json translation not working with numbers.
  monerisErrors: string[];
  monerisConfiguration = {} as IMonerisConfigurationResponse;

  // These known errors are stored in the i18n translations
  knownErrors: Record<string, string>;

  // Will be computed and set later
  public monerisURL: string;
  modalRef: BsModalRef;
  public renew: boolean;

  constructor(
    private router: Router,
    private readonly quoteService: QuoteService,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly monerisConfigurationService: MonerisConfigurationService,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly heapService: HeapService,
    private modalService: BsModalService,
    private readonly userService: UserService
  ) {
    super();

    this.using(this.activatedRoute.data.subscribe((d) => (this.renew = d.renew))).attach(this);

    this.translateService
      .get('payment.terms_and_conditions_error')
      .subscribe((s) => (this.termsAndConditionsErrorMsg = s))
      .attach(this);

    // Get the moneris configuration
    this.monerisConfiguration = this.monerisConfigurationService.getMonerisConfig();

    // A helper function to format an object into a css string
    const css = (properties: IKeyValuePair) => {
      return Object.keys(properties)
        .map((k) => `${k}:${properties[k]}`)
        .join(';');
    };

    // Get the translations for the known errors
    this.using(
      this.translateService.get('payment.moneris_errors').subscribe((translation) => {
        this.knownErrors = translation;
      })
    ).attach(this);

    // Get the translations for the moneris labels
    this.using(
      this.translateService.get('payment.moneris_iframe').subscribe((translation) => {
        // Set all the URL parameters
        const params: IKeyValuePair = {
          id: this.monerisConfiguration.monerisProfileId,
          pmmsg: true,
          enable_exp: 1,
          enable_cvd: 1,
          pan_label: translation.pan_label,
          exp_label: translation.exp_label,
          cvd_label: translation.cvd_label,
          display_labels: 1,
          css_body: css({
            background: 'white',
            overflow: 'hidden',
          }),
          css_textbox: css({
            display: 'block',
            width: '100%',
            height: '38px',
            'margin-bottom': '24px',
            'padding-top': '6px',
            'padding-bottom': '6px',
            'padding-left': '12px',
            'padding-right': '12px',
            'font-size': '16px',
            'line-height': '1.5',
            'border-width': '1px',
            'border-style': 'solid',
            'border-color': 'rgb(206,212,218)',
            'border-radius': '4px',
          }),
          css_input_label: css({
            display: 'inline-block',
            position: 'relative',
            left: 0,
            'margin-bottom': '8px',
            'white-space': 'nowrap',
            width: 'auto',
            height: 'auto',
            'letter-spacing': '0.5px',
            'font-family': 'Helvetica,sans-serif,Arial',
            'font-size': '16px',
            'font-weight': 600,
            'line-height': 1.5,
            color: 'rgb(33,37,41)',
          }),
          // css_textbox_pan: css({ width: '140px' }),
          css_textbox_exp: css({ width: '90px' }),
          css_textbox_cvd: css({
            // width needs to match sass variable $css_textbox_cvd-width
            width: '70px',
            'margin-bottom': 0,
          }),
        };

        // Set the moneris URL with the base path and all params URI encoded.
        this.monerisURL =
          this.monerisConfiguration.monerisURL +
          '?' +
          Object.keys(params)
            .map((k) => `${k}=${encodeURIComponent(params[k])}`)
            .join('&');
      })
    ).attach(this);
  }

  ngAfterViewInit(): void {
    const selectedProductCode = this.reduxStore.getState().tripDetails.productSelected;
    this.isMultiTripSelected = multiTripProductIsSelected(selectedProductCode);
    this.productGroupFlow = this.reduxStore.getState().tripDetails.productGroupFlow;
    window.addEventListener('message', this.respMsg, false);
    this.monerisFrame.nativeElement.addEventListener('load', this.monerisSecureFrameLoadCompleted);
    if (document.getElementById(this.eligibilityLinkId)) {
      document.getElementById(this.eligibilityLinkId).addEventListener('click', () => this.showEligibilityRequirementsModal());
    }
  }
  showEligibilityRequirementsModal() {
    this.modalRef = this.modalService.show(
      // tslint:disable-next-line: no-use-before-declare
      EligibilityRequirementsModalComponent
    );
  }
  ngOnDestroy(): void {
    // Make sure the BaseComponent will remove all subscriptions
    super.ngOnDestroy();

    // Remove all event listeners
    window.removeEventListener('message', this.respMsg, false);
    if (this.monerisFrame && this.monerisFrame.nativeElement) {
      this.monerisFrame.nativeElement.removeEventListener('load', this.monerisSecureFrameLoadCompleted);
    }
  }

  doMonerisSubmit($event) {
    this.formSubmitted = true;

    if (this.enableSubmit) {
      if (!this.acceptedTermsAndConditions) {
        this.displayMonerisErrors([this.termsAndConditionsErrorMsg]);
        return;
      }

      this.enableSubmit = false;
      this.detectChanges();
      this.monerisFrame.nativeElement.contentWindow.postMessage('tokenize', this.monerisConfiguration.monerisURL);
    }
  }

  /**
   * This function is used as an event listener.
   */
  monerisSecureFrameLoadCompleted = () => {
    this.showLoading = false;
    this.showSecureCardFrame = true;
    this.enableSubmit = true;
    this.detectChanges();
  };

  /**
   * This function is used as an event listener.
   */
  respMsg = (message: any) => {
    // FILTER messages by message.data.type
    // console.log('message.data', message.data);
    // message.data is an object with type === "Action" when a redux 'message' is popped
    if (typeof message.data === 'string' && this.formSubmitted) {
      try {
        this.processPaymentResponse(JSON.parse(message.data));
      } catch (paymentError) {
        let supportId = null;
        const match = /Support ID: (\d+)/.exec(message.data);
        if (match && match.length >= 2) {
          supportId = match[1];
        }
        AppInsights.trackException(new Error('Payment Error'), null, {
          'support-ticket-id': supportId,
          'original-payload': message && message.data,
          'original-error': paymentError && paymentError.message,
        });
        AppInsights.flush();

        // Show a special error message
        this.displayMonerisErrors([this.knownErrors['Unexpected']], true);
        return false;
      }
    }
    return false;
  };

  processPaymentResponse(monerisResponse: any) {
    // Clear the errors but do not renable the submit button
    this.displayMonerisErrors([], false);

    if (monerisResponse.responseCode.length === 1 && monerisResponse.responseCode[0] === '001') {
      // TODO: Loading bar while payment is being submitted.
      const enableAutoRenewal = this.isMultiTripSelected ? this.autoRenewCheck.nativeElement.checked : false;
      // SEND TOKEN TO PAYMENT API
      const state = this.reduxStore.getState();
      lastValueFrom(
        this.quoteService.sendPaymentInfo({
          quoteID: state.quoteID,
          paymentToken: monerisResponse.dataKey,
          cardBinNumber: monerisResponse.bin,
          enableAutoRenewal: enableAutoRenewal,
          quoteOptions: state.quoteOptions,
        }),
        undefined
      )
        .then((serverResponse: IServerQuotePaymentResponse) => {
          if (serverResponse.isSuccess) {
            const localStore = this.reduxStore.getState();
            const policyNumber = serverResponse.policyNumber;
            const receiptId = serverResponse.receiptId;
            const product = PurchaseMapper.GetProductInfo(localStore, policyNumber, enableAutoRenewal, this.renew);
            const purchase = PurchaseMapper.GetPurchaseInfo(localStore, policyNumber);

            this.googleAnalyticsService.sendPurchaseEvent(purchase, product);
            this.heapService.createPurchaseEvent(
              this.renew,
              localStore.tripDetails.productSelected as InsuranceProduct,
              policyNumber,
              receiptId,
              localStore.currentQuoteDetails.quoteOption,
              this.userService.currentUser ? this.userService.currentUser.profile : null
            );
            this.heapService.createReceiptEvent(
              this.renew,
              receiptId,
              localStore.currentQuoteDetails.quoteOption,
              this.userService.currentUser ? this.userService.currentUser.profile : null
            );

            // Clear all data except for the policy info.
            this.reduxStore.dispatch({
              type: SalesFunnelActionType.LOAD_QUOTE,
              payload: {
                ...GetAppInitialState(),
                ...{
                  policyInfo: {
                    // Clear out the dates since we have finished our quote.
                    dateLastEdited: new Date(),
                    dateQuoteInitiated: new Date(),
                    policyNumber: policyNumber,
                    currentQuoteDetails: localStore.currentQuoteDetails,
                    tripDetails: localStore.tripDetails,
                  } as IPolicyInfo,
                },
              },
            });
            this.router.navigateByUrl('/policy-confirmation');
          } else {
            this.displayMonerisErrors([serverResponse.message]);
          }
        })
        .catch((error) => {
          this.enableSubmit = true;
          this.detectChanges();
        });
    } else {
      this.enableSubmit = true;
      this.displayMonerisErrors(
        monerisResponse.responseCode.map((code) => {
          if ('C' + code in this.knownErrors) {
            return this.knownErrors['C' + code];
          } else {
            // This isn't a known error
            return 'Server Error - ' + code + ' ' + monerisResponse.errorMessage;
          }
        })
      );
    }
  }

  /**
   * Show all errors and re-enable the submit button.
   */
  displayMonerisErrors(errors: string[], enableSubmit: boolean = true) {
    this.monerisErrors = errors;
    this.enableSubmit = enableSubmit;
    this.detectChanges();
  }
  /**
   * In some rare circumstances the view will have been destroyed
   * by the time detect changes is called. We want to ignore the exception if that
   * is the case.
   */
  private detectChanges() {
    try {
      this.changeDetector.detectChanges();
    } catch (e) {}
  }
}
