export interface IIdentifyHeap {
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (props: Record<string, string | number>) => void;
  addEventProperties: (props: Record<string, string | number>) => void;
  track: (eventName: string, props: Record<string, string | number>) => void;
  clearEventProperties: () => void;
}

export enum HeapECommerceEventNames {
  PurchaseTMI = 'trv ins purchase funnel',
  RenewTMI = 'trv ins renew funnel',
}

export enum HeapECommerceBusinessUnit {
  Travel = 'trv',
}
