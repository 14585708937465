/* eslint-disable max-lines */
import { Injectable, ViewRef, ViewContainerRef, TemplateRef, ElementRef, Renderer2 } from '@angular/core';
import { hasValue } from '../helpers/app.helpers';
import _ from 'underscore';
import $ from 'jquery';

import { TstConfig, TST_WIDGET_LABELS, TSTDatePicker } from '../../models/tst/tst';
import { MatDialogConfig } from '@angular/material/dialog';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, from, map, of } from 'rxjs';
import { utmKeys } from '../../models/tst/analytics.model';
import { StorageService } from './storageService';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Injectable()
export class TSTService {
  constructor(private http: HttpClient, private storageService: StorageService) {}

  private getConfigObservable: Observable<TstConfig>;
  private callbackName = 'callback';
  public getConfig(): Observable<TstConfig> {
    if (!this.getConfigObservable) {
      this.getConfigObservable = this.replayResult(of(this.getDefaultConfig()));
    }
    return this.getConfigObservable;
  }
  public getDefaultConfig(): TstConfig {
    const defaultConfig: TstConfig = {
      tabs: [
        {
          type: 'prepackaged',
          label: 'Vacations',
          iconClass: 'iconOutletVacations',
          bannerImageURL:
            'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/la-playa-escondida-islas-marietas-puerto-vallarta-vacations-banner.jpg',
          valuePropHTML:
            "<section id=\"vpContent\" class=\"tst-banner__value-prop light\">\r\n\t<a class='c-bpg light'>\r\n\t\t<span class='c-bpg__icon'><img class='c-bpg__icon' src='https://amatravelinternal.blob.core.windows.net/tstbanners/tst-banners/best-price-white.png'></span>\r\n\t\t<h4 class='c-bpg__heading'>AMA BEST PRICE<br><strong class='c-bpg__subheading'>GUARANTEE</strong></h4>\r\n\t</a>\r\n\t\r\n\t\t<div class=\"c-bpg__details u-rpadding-xs--left text-white\">\r\n\t\t<strong>Guaranteed Lowest Prices on all vacation bookings<br/> including Flight + Hotel Packages</strong>\r\n\t</div>\r\n</section>",
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: 'formType',
          optionValue: 'prepackaged',
          tabChildren: '',
        },
        {
          type: 'tour',
          label: 'Vacations',
          iconClass: 'iconOutletVacations',
          bannerImageURL:
            'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/la-playa-escondida-islas-marietas-puerto-vallarta-vacations-banner.jpg',
          valuePropHTML:
            "<div class='tst-banner__value-prop u-flex u-flex__align-items--center'><a class='c-bpg'><span class='c-bpg__icon'><img class='c-bpg__icon' src='Content/SVG/best-price.svg'></span><h4 class='c-bpg__heading'>AMA BEST PRICE<br><strong class='c-bpg__subheading'>GUARANTEE</strong></h4></a><div class='u-rpadding-xs--left u-text-color-blue-navy'><strong>Guaranteed Lowest Prices on all vacation bookings<br/> including Flight + Hotel Packages</strong></div></div>",
          promoHTML: '',
          group: 'activity',
          groupIndex: false,
          optionGroup: 'formType',
          optionValue: 'tour',
          tabChildren: '',
        },
        {
          type: 'hotel',
          label: 'Hotels',
          iconClass: 'iconOutletHotels',
          bannerImageURL: 'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/peyto-lake-jasper-hotel-banner.jpg',
          valuePropHTML:
            "<section id='vpContent' class='tst-banner__value-prop light'><span class='c-bpg light'><span class='c-bpg__icon'><span class='material-icons-outlined u-text-xxxxl text-white'> supervised_user_circle</span></span></span><div class='c-bpg__details u-rpadding-xs--left text-white'><strong>Local travel experts helping you find the<br>perfect hotel for the best price</strong></div></section>",
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: '',
          optionValue: '',
          tabChildren: '',
        },
        {
          type: 'rentals',
          label: 'Vacation Rentals',
          iconClass: '',
          bannerImageURL: 'https://amatravelinternal.blob.core.windows.net/articles/images/vacation-rental-banners-search-size.jpg',
          valuePropHTML:
            '<section id="vpContent" class="tst-banner__value-prop light"><span class="c-bpg light"><span class="c-bpg__icon"><span\r\n\t\t\t\tclass="material-icons-outlined u-text-xxxxl text-white"> supervised_user_circle </span></span> </span> <div class="c-bpg__details u-rpadding-xs--left text-white"><strong>Local travel experts helping you find the <br>\r\n\t\t\tperfect vacation rental for the best price</strong></div></section>',
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: '',
          optionValue: '',
          tabChildren: '',
        },
        {
          type: 'flight',
          label: 'Flights',
          iconClass: 'iconOutletFlights',
          bannerImageURL: 'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/las-vegas-flights-banner.jpg',
          valuePropHTML:
            '<section id="vpContent" class="tst-banner__value-prop light">\r\n      <div class="tst-banner__value-prop u-flex u-flex__align-items--center">\r\n        <span><img width="85px" src="https://amatravelinternal.blob.core.windows.net/pages/home/flight-vp-icon-light.png">\r\n        </span>\r\n        <div class="u-rpadding-xs--left u-text-color-white">\r\n          <strong>Local travel experts helping you find <br>the perfect flight for the best price</strong>\r\n        </div>\r\n      </div>\r\n</section>',
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: '',
          optionValue: '',
          tabChildren: '',
        },
        {
          type: 'cruise',
          label: 'Cruises',
          iconClass: 'iconOutletCruise',
          bannerImageURL: 'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/tortola-cruise-banner.jpg',
          valuePropHTML:
            '<section id="vpContent" class="tst-banner__value-prop light">\r\n      <div class="tst-banner__value-prop u-flex u-flex__align-items--center">\r\n        <span><img width="85px" src="https://amatravelinternal.blob.core.windows.net/tstbanners/tst-banners/cruise-vp-white.png">\r\n        </span>\r\n        <div class="u-rpadding-xs--left u-text-color-white">\r\n          <strong>Expert cruise specialists helping to <br/>find the right cruise for you</strong>\r\n        </div>\r\n      </div>\r\n</section>',
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: '',
          optionValue: '',
          tabChildren: '',
        },
        {
          type: 'car',
          label: 'Car Rentals',
          iconClass: 'iconOutletCars',
          bannerImageURL: 'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/new-york-car-banner.jpg',
          valuePropHTML:
            '<section id="vpContent" class="tst-banner__value-prop light">\r\n      <div class="tst-banner__value-prop u-flex u-flex__align-items--center">\r\n        <span><img width="85px" src="https://amatravelinternal.blob.core.windows.net/pages/home/car-rentals-vp-icon-white.png">\r\n        </span>\r\n        <div class="u-rpadding-xs--left u-text-color-white">\r\n          <strong>Exclusive member benefits <br/>and discounted rates</strong>\r\n        </div>\r\n      </div>\r\n</section>',
          promoHTML: '',
          group: '',
          groupIndex: true,
          optionGroup: '',
          optionValue: '',
          tabChildren: '',
        },
        {
          type: 'activity',
          label: 'Activities & Tours',
          iconClass: 'iconOutletActivities',
          bannerImageURL:
            'https://amatravelinternal.blob.core.windows.net/tstbanners/widget-banners/disneyland-resort-activities-banner.jpg',
          valuePropHTML:
            '<section id="vpContent" class="tst-banner__value-prop light">\r\n      <div class="tst-banner__value-prop u-flex u-flex__align-items--center">\r\n        <span><img width="85px" src="https://amatravelinternal.blob.core.windows.net/pages/home/activities-vp-icon-light.png">\r\n        </span>\r\n        <div class="u-rpadding-xs--left u-text-color-white">\r\n          <strong>AMA members save on theme park tickets <br/>and activities everywhere you go!</strong>\r\n        </div>\r\n      </div>\r\n</section>',
          promoHTML: '',
          group: 'activity',
          groupIndex: true,
          optionGroup: '',
          optionValue: 'activity',
          tabChildren: '',
        },
      ],
    };
    return defaultConfig;
  }
  private replayResult = <T>(observable: Observable<T>): Observable<T> => {
    // Create a replay subject with buffer size 1
    const subject = new ReplaySubject<T>(1);
    // Subscribe to the original observable, now it will begin executing.
    observable
      .subscribe({
        next: (response: T) => subject.next(response),
        error: (error) => subject.next(null),
      })
      .attach(this);
    // Return the subject as an observable
    return subject;
  };

  /** api calls to tst for autocomplete of locations */

  // call from tst-custom-form
  public getAirports(typedValue, api) {
    return this.http.jsonp<any>(`${api.endpoint}?options=[${api.parameters.options}]&lon=0&lat=0&term=${typedValue}`, this.callbackName);
  }

  // calls from tst-autocomplete
  public getDestinationsForPrepackaged(city, endpoint) {
    return this.http.jsonp<any>(`${endpoint.url}?${endpoint.params[0]}=${city}`, this.callbackName);
  }

  public getDestinationsForCruises(endpoint, numberOfPassengers, term) {
    return this.http.jsonp<any>(
      `${endpoint.url}?${endpoint.params[0]}=${numberOfPassengers}&${endpoint.params[1]}=${term}`,
      this.callbackName
    );
  }

  public getDestinationsForGeneric(endpoint, term, lat = 0, long = 0) {
    const keys = Object.keys(endpoint.params[0]);
    return this.http.jsonp<any>(
      `${endpoint.url}?${keys[0]}=${endpoint.params[0].options}
			&lat=${lat}&long=${long}&${endpoint.params[1]}=${term}`,
      this.callbackName
    );
  }

  public getDestinationsForActivities(endpoint, term, licensee: number = 3) {
    return this.http.get<any>(`${endpoint.url}?${endpoint.params[0]}=${licensee}&${endpoint.params[1]}=${term}`);
  }

  /** end api calls  */

  public insertTemplateRef(cvr: ViewContainerRef, tr: TemplateRef<ElementRef>, context = null): any {
    let viewRef: ViewRef = null;
    if (!hasValue(context)) {
      const incrementorView = tr.createEmbeddedView(context);
      viewRef = cvr.insert(incrementorView);
    } else {
      viewRef = cvr.createEmbeddedView(tr, context);
    }
    return viewRef;
  }

  // opens a dialog for incrementor
  public openIncrementorDialog(templateRef: TemplateRef<ElementRef>, formItem: any, event = null, mq: any, offsets: any[] = null) {
    const rect = event.target.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    if (!mq.tablet && !mq.mobile) {
      dialogConfig.hasBackdrop = true;
      dialogConfig.position = {
        left: `${rect.left + offsets[0]}px`,
        top: `${rect.bottom + offsets[1]}px`,
      };
    }

    if (formItem.dialog) formItem.dialog.open(templateRef, dialogConfig);
    else formItem.open(templateRef, dialogConfig);
    return true;
  }

  public closeDialogs(dialog) {
    dialog.closeAll();
  }

  public getFriendlyTSTLabel(label: string) {
    let _label;
    switch (label) {
      case TST_WIDGET_LABELS.PACKAGE_TAB: {
        _label = 'Vacations';
        break;
      }
      case TST_WIDGET_LABELS.DYNAMIC_TAB: {
        _label = 'Flight + Hotel';
        break;
      }
      case TST_WIDGET_LABELS.TOURS_TAB: {
        _label = 'Tours';
        break;
      }
      case TST_WIDGET_LABELS.FLIGHT_TAB: {
        _label = 'Flights';
        break;
      }
      case TST_WIDGET_LABELS.CAR_TAB: {
        _label = 'Cars';
        break;
      }
      case TST_WIDGET_LABELS.HOTEL_TAB: {
        _label = 'Hotels';
        break;
      }
      case TST_WIDGET_LABELS.CRUISE_TAB: {
        _label = 'Cruises';
        break;
      }
      case TST_WIDGET_LABELS.ACTIVITY_TAB: {
        _label = 'Activities & Tours';
        break;
      }
    }
    return _label;
  }

  public incrementFieldCounter(params) {
    /** to do: convert this incremetor functionality to use the formStepper component. */
    let val = params.control.value;
    const total = params.ref.adults + params.ref.children + params.ref.seniors;

    /** must have at least 1 adult or senior */
    const isAdult = params.control == params.form.formGroup.controls['numAdults'] ? true : false;
    const isSenior = params.control == params.form.formGroup.controls['numSeniors'] ? true : false;
    const isChild = params.control == params.form.formGroup.controls['numChildren'] ? true : false;
    const adultsTotal = params.ref.adults + params.ref.seniors;
    let doChange = false;

    if (params.action == 'add') {
      doChange = isAdult || isSenior ? adultsTotal + 1 > 0 : true;
      if (doChange && total < 6 && (params.ref.seniors >= 1 || params.ref.adults >= 1)) val++;
      if (params.control.value <= params.max) params.control.setValue(val);
    }
    if (params.action == 'minus') {
      doChange = isAdult || isSenior ? adultsTotal - 1 > 0 : true;
      if (doChange && total - 1 > 0 && params.control.value > 0) val--;
      if (params.control.value >= params.min) params.control.setValue(val);
    }
    return val;
  }

  public getRootNodesFromView(nodes: any, childNode: any, valueCompare: any, childNodeType: string = 'string'): any {
    let node: any = null;
    // created because typescript was complaining about param not available
    if (childNodeType == 'string') {
      node = _.filter(nodes, (n) => {
        return n[childNode] == valueCompare;
      });
    } else {
      node = _.filter(nodes, (n) => {
        return hasValue(n[childNode] && hasValue(n[childNode].length)) ? n[childNode].contains(valueCompare) : false;
      });
    }
    return node;
  }

  public placeRootNodes(
    rootNode: any,
    target: Renderer2 | ElementRef | ElementRef['nativeElement'],
    placementMethod: 'appendChild' | 'insertAdjacentElement',
    placementType: 'afterend' | 'beforebegin' | 'afterbegin' | 'beforeend' = null
  ): any {
    let el: any = null;
    const _target = target.nativeElement || target;
    const _rootNode = rootNode[0] || rootNode;

    if (placementMethod == 'insertAdjacentElement') {
      const _placementType = hasValue(placementType) ? placementType : 'afterend';
      el = _target.insertAdjacentElement(_placementType, _rootNode);
    }

    if (placementMethod == 'appendChild') {
      _target.appendChild(_target.parentNode, _rootNode);
    }

    return el;
  }

  public getDestinationFieldElement(type: string = '', container: any, alt: boolean = false): any {
    /** returns the destination control in dom for specified tst type */
    let destinationField;
    const selectorPrefix = '.' + type + '-section ';
    switch (type) {
      case TST_WIDGET_LABELS.PACKAGE_TAB:
        destinationField = container.nativeElement.querySelector(selectorPrefix + '.arrival select');
        break;
      case TST_WIDGET_LABELS.DYNAMIC_TAB:
        destinationField = container.nativeElement.querySelector('.vacation-section #toLocation input');
        break;
      case TST_WIDGET_LABELS.HOTEL_TAB:
        destinationField = container.nativeElement.querySelector('.hotel-section .location input');
        break;
      case TST_WIDGET_LABELS.CAR_TAB:
        destinationField = container.nativeElement.querySelector('.car-section .pickup-location-input input');
        break;
      case TST_WIDGET_LABELS.FLIGHT_TAB:
        destinationField = container.nativeElement.querySelector('.tst-search__form--flight .tst-form__item--toCity input');
        break;
      case TST_WIDGET_LABELS.CRUISE_TAB:
        destinationField = alt
          ? container.nativeElement.querySelector(
              selectorPrefix + ' select#cruiseDeparturePort' // tslint:disable-next-line:indent
            )
          : container.nativeElement.querySelector(
              selectorPrefix + ' .cruiseDestination select' // tslint:disable-next-line:indent
            );
        break;
      case TST_WIDGET_LABELS.ACTIVITY_TAB:
        destinationField = container.nativeElement.querySelector(selectorPrefix + ' .location input');
        break;
    }
    return destinationField;
  }

  /** Datepicker functions for ng-bootstrap date and date-range picker **/

  /**
   * returns boolean if cursor enters datepicker and over a day. Called from #dateRangeDay/#dateDay ng-templates
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeIsHovered(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return (
      dates.dateRngFromDate &&
      !dates.dateRngToDate &&
      dates.dateRngHoveredDate &&
      ngbd.after(dates.dateRngFromDate) &&
      ngbd.before(dates.dateRngHoveredDate)
    );
  }
  /**
   * returns boolean if within range not including from and to dates. Called from #dateRangeDay/#dateDay ng-template
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeIsInside(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return ngbd.after(dates.dateRngFromDate) && ngbd.before(dates.dateRngToDate);
  }

  /**
   * If day on calendar belongs to previous or next month
   * @param date ngbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   * @param month month of the calendar being displayed in datepicker
   */
  public dateCalendarOutsideDay(date: NgbDate, dates: TSTDatePicker, month) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return ngbd.month != month;
  }

  /**
   * returns boolean if in range including from and to dates. Called from #dateRangeDay/#dateDay ng-template
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeInRange(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return (
      ngbd.equals(dates.dateRngFromDate) ||
      ngbd.equals(dates.dateRngToDate) ||
      this.dateRangeIsInside(ngbd, dates) ||
      this.dateRangeIsHovered(ngbd, dates)
    );
  }

  /**
   * returns boolean if date is today. Called from #dateRangeDay/#dateDay ng-template
   * @param date
   * @param dates
   */
  public dateRangeIsToday(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    const today = new Date();
    return date.day == today.getDate() && date.month == today.getMonth() + 1 && date.year == today.getFullYear();
  }

  /**
   * returns boolean if in date is equal to To date. Called from #dateRangeDay/#dateDay ng-template
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeIsEndDate(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return dates.dateRngToDate && ngbd.equals(dates.dateRngToDate);
  }

  /**
   * returns boolean if in date is equal to From date. Called from #dateRangeDay/#dateDay ng-template
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeIsStartDate(date: NgbDate, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return dates.startDate && ngbd.equals(dates.dateRngFromDate);
  }

  /**
   * returns boolean if passed date is valid (>min/<max). Called from #dateRangeDay/#dateDay ng-template
   * @param date NgbDate
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public dateRangeInvalidDate(date, dates: TSTDatePicker) {
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);
    return ngbd.after(dates.dateRngMax) || ngbd.before(dates.dateRngToday) || ngbd.before(dates.dateRngMin);
  }

  /**
   * Called when datepicker month view changes -> function for (navigate) output from ngbDatePicker.
   * @param date - ngbDate
   * @param dp - templateRef of datepicker
   * @param dates Object of date values for ngbDates and js Date()'s
   */
  public datePickerNavigation(date, dp, dates: TSTDatePicker) {
    const ngbdCurrent = this.convertToNgbDate(date.current, dates.dateRngCalendar);
    const ngbdNext = this.convertToNgbDate(date.next, dates.dateRngCalendar);
    // get elementRef of
    let months;
    if (dp) {
      months = _.find(dp._elementRef.nativeElement.childNodes, (e) => {
        return e.className == 'ngb-dp-months';
      });
      months && months.classList.remove('slide');
      if (months && ngbdCurrent && ngbdNext.year >= ngbdCurrent.year && ngbdNext.month > ngbdCurrent.month) {
        months.classList.add('slide-left');
        months.classList.remove('slide-right');
      }
      if (months && ngbdCurrent && ngbdNext.year <= ngbdCurrent.year && ngbdNext.month < ngbdCurrent.month) {
        months.classList.add('slide-right');
        months.classList.remove('slide-left');
      }
    }
  }

  /**
   * Returns NgbDate object
   * @param date Date() to convert
   */
  public convertToNgbDate(date, cal: NgbCalendar) {
    let ngbd: NgbDate;
    if (date) {
      /** Need this function for ng-boostrap@1.x -> angular@5.x; may not be needed when 4.x->7.x respectivly */
      ngbd = cal.getToday();
      ngbd.day = date.day;
      ngbd.month = date.month;
      ngbd.year = date.year;
    }
    return ngbd;
  }

  public convertLongDateToNgbDate(date: Date, cal: NgbCalendar) {
    let ngbd: NgbDate;
    if (date) {
      ngbd = cal.getToday();
      ngbd.day = date.getDate();
      ngbd.month = date.getMonth() + 1;
      ngbd.year = date.getFullYear();
    }
    return ngbd;
  }

  /**
   * Returns a Date object converted from an ngbDate
   * @param date ngbDate to convert
   */
  public convertNgDateToLongDate(date: NgbDate) {
    /** Need to convert ngbDates to normal js dates */
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }

  public setDepartDate(dates) {
    dates.fromDateLong = this.convertNgDateToLongDate(dates.dateRngFromDate);
    dates.startDate = dates.fromDateLong;
  }

  public setReturnDate(dates) {
    dates.toDateLong = this.convertNgDateToLongDate(dates.dateRngToDate);
    dates.endDate = dates.toDateLong;
  }

  public doDepart(dates, data) {
    dates.dateRngFromDate = data.ngbd;
    this.setDepartDate(dates);
    if (!data.elementToTrigger) {
      data.elementToTrigger = null;
    }
    if (dates.dateRngToDate && data.ngbd.after(dates.dateRngToDate)) {
      dates.dateRngToDate = null;
      this.setReturnDate(dates);
    }
    data.closeCurrent = true;
  }
  public doReturn(dates, data) {
    // if depart not set yet
    if (!dates.dateRngFromDate) {
      dates.dateRngToDate = data.ngbd;
      data.elementToTrigger = data.elementToTrigger ? data.elementToTrigger : null;
      this.setReturnDate(dates);
      data.closeCurrent = true;
    }
    // if depart set and chosen date after depart
    else if (dates.dateRngFromDate && data.ngbd.after(dates.dateRngFromDate)) {
      dates.dateRngToDate = data.ngbd;
      data.elementToTrigger = null;
      this.setReturnDate(dates);
      data.closeCurrent = true;
    }
    // if date chosen is before or equal to depart date, set the chosen date to depart date and update. don'
    else if ((dates.dateRngFromDate && data.ngbd.equals(dates.dateRngFromDate)) || data.ngbd.before(dates.dateRngFromDate)) {
      data.elementToTrigger = null;
      dates.dateRngFromDate = data.ngbd;
      dates.dateRngToDate = null;
      this.setDepartDate(dates);
      this.setReturnDate(dates);
    }
    // if depart set but chosen not after depart
    else if (dates.dateRngFromDate && !data.ngbd.after(dates.dateRngFromDate)) {
      dates.dateRngFromDate = data.ngbd;
      data.elementToTrigger = data.elementToTrigger ? data.elementToTrigger : null;
    }
  }

  public dateRangeSelection(date: any, data: any, dates: any) {
    let elementToTrigger = null;
    let closeCurrent = false;
    const ngbd = this.convertToNgbDate(date, dates.dateRngCalendar);

    /**
     * If depart datepicker picked
     */
    if (data.flightDirection == 'depart') {
      dates.dateRngFromDate = ngbd;
      this.setDepartDate(dates);
      elementToTrigger = data.elementToTrigger ? data.elementToTrigger : null;
      if (dates.dateRngToDate && ngbd.after(dates.dateRngToDate)) {
        dates.dateRngToDate = null;
      }
      closeCurrent = true;
    } else {
      /**
       * If return datepicker picked
       */

      if (!dates.dateRngFromDate) {
        dates.dateRngFromDate = ngbd;
        elementToTrigger = data.elementToTrigger ? data.elementToTrigger : null;
        closeCurrent = true;
      }
      // if depart set and chosen date after depart
      else if (dates.dateRngFromDate && ngbd.after(dates.dateRngFromDate)) {
        dates.dateRngToDate = ngbd;
        elementToTrigger = null;
        this.setReturnDate(dates);
        closeCurrent = true;
      }
      // if date chosen is before or equal to depart date, set the chosen date to depart date and update. don'
      else if ((dates.dateRngFromDate && ngbd.equals(dates.dateRngFromDate)) || ngbd.before(dates.dateRngFromDate)) {
        elementToTrigger = null;
        dates.dateRngFromDate = ngbd;
        dates.dateRngToDate = null;
        this.setDepartDate(dates);
        this.setReturnDate(dates);
      }
      // if depart set but chosen not after depart
      else if (dates.dateRngFromDate && !ngbd.after(dates.dateRngFromDate)) {
        dates.dateRngFromDate = ngbd;
        elementToTrigger = data.elementToTrigger ? data.elementToTrigger : null;
      }
    }

    // data.element && data.element.close();
    closeCurrent && data.element && data.element.close();
    elementToTrigger && elementToTrigger.open();
  }

  public datepickerToggle(event, data) {
    /**
     * ngbd output event just before when datepicker is opened or closed
     * 1. set datepicker msg in header
     * 2. set minDates if needed
     * 3. set placement
     */

    // get position of datepicker field
    if (data.el) {
      // ngbDropdown placement not working correctly so going to use css instead
      const pos = data.el._elementRef.nativeElement.getBoundingClientRect();
      const dropElHeight = 324; // hardcoding
      const vpHeight = window.innerHeight;
      const distanceToBottom = vpHeight - pos.bottom;
      data.placement.placement = distanceToBottom >= dropElHeight ? 'bottom-left' : 'top-left';
    }
    if (event) {
      if (
        data.dp.name !== 'hotelCheckinDatePicker' &&
        data.dp.name !== 'carPickupDatePicker' &&
        data.dp.name !== 'rentalCheckinDatePicker'
      ) {
        let longdate;
        if (data.dp.name == 'prePackageDatePicker') {
          longdate = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
        } else {
          longdate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
        }
        let ngbdDate: NgbDate;
        data.dates.dateRngMin = this.convertLongDateToNgbDate(longdate, data.dates.dateRngCalendar);
      } else {
        data.dates.dateRngMin = data.dates.dateRngCalendar.getToday();
      }
      if (data.direction == 'from') {
        data.dates.msg = data.dp.dropdownTitleStart;
      }
      if (data.direction == 'return') {
        data.dates.msg = data.dp.dropdownTitleEnd;
      }
    }
  }

  /** END - Range DatePicker Functions */

  /**
   * GTM - add cross domain tracking to each form in tst widget
   * each component that uses this needs to implement its own handleEvent()
   * **/
  public gtmInitTracking(item, component: any = null, selector: string = '', btn: string = null) {
    const root = $(item);
    btn = btn || 'button[type="submit"]';
    if (selector) {
      _.each(root.find(selector), (e) => {
        _.each($(e).find(btn), (b) => {
          this.gtmAddListener(b, 'click', component);
        });
      });
    } else {
      _.each(root.find(btn), (b) => {
        this.gtmAddListener(b, 'click', component);
      });
    }
  }

  public gtmAddListener(element, type, component) {
    if (element.addEventListener) element.addEventListener(type, component);
    else if (element.attachEvent) element.attachEvent('on' + type, component);
  }

  public gtmTrackingCallback(e: any = null) {
    const linkerParm = this.gtmGetTrackerLinkerParam(window);

    if (e && linkerParm) {
      // _ga
      $(e.target).append(`<input type="hidden" name="_ga" value="${linkerParm}" />`);

      // UTM params
      for (const utmKey of utmKeys) {
        const utmValue = this.storageService.local.get(utmKey);

        if (utmValue) {
          $(e.target).append(`<input type="hidden" name="${utmKey}" value="${utmValue}" />`);
        }
      }
    }

    return linkerParm;
  }

  public gtmGetTrackerLinkerParam(w) {
    // Fetch the GA Library, return tracker code
    let gaLib;
    try {
      gaLib = w.ga;
    } catch {
      gaLib = undefined;
    }

    if (hasValue(gaLib)) {
      const trackers = gaLib.getAll();
      const uaNumber = 'UA-75215602-1'; // This will need to be specific to staging or prod

      for (let i = 0; i < trackers.length; i++) {
        if (trackers[i].get('trackingId') === uaNumber) {
          return trackers[i].get('linkerParam').replace('_ga=', ''); // return the linker with the _ga= removed
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  /**
   * End GTM
   */
}
