import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[setHeight]',
})
export class SetHeightDirective implements AfterViewChecked {
  // class name to set height
  @Input() setHeight: string;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    // call setHeight function
    this.setElHeight(this.el.nativeElement, this.setHeight);
  }

  @HostListener('window:resize')
  onResize() {
    // call seElHeight function here
    this.setElHeight(this.el.nativeElement, this.setHeight);
  }

  setElHeight(elRef: HTMLElement, className: string, ignoreSetHeightClass?: string) {
    elRef.style.height = 'auto';

    // set height logic here
    const height = elRef.getBoundingClientRect().height;

    elRef.style.height = `${height}px`;
  }
}
