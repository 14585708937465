import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IActivity } from 'src/app/models/data.interfaces';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private readonly baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = `${environment.baseApiUrl}/activity`;
  }

  public getActivities(country: string, subRegion: string): Observable<Array<IActivity>> {
    // There are some instances where this argument can wind up null, so if that happens, do nothing and return an empty array.
    if (country == null) {
      return of(new Array<IActivity>());
    }

    if (subRegion != null && subRegion.trim().length === 0) {
      subRegion = null;
    }

    const searchModel = {
      country: country,
      subRegion: subRegion,
    };

    return this.http.post<Array<IActivity>>(this.baseApiUrl, searchModel);
  }
}
