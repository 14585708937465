import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IPolicyMatrix, CoverageType, SingleTripMultiTripDataBuilder } from './policy-matrix.models';
import { select, dispatch } from '@angular-redux-ivy/store';
import { IQuoteOption, ICountry } from 'src/app/models/data.interfaces';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsuranceProduct, ProductGroup, productGrouping, CANADA_ONLY_CODE } from 'src/app/models/products';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EligibilityRequirementsModalComponent } from './eligibility-requirements-modal.component';
import { BaseComponent } from '../base-component.component';
import { Unsubscribe } from 'amaweb-tsutils';
import { IAppConfigSettings } from 'src/app/models/app-config.model';
import { TranslateService } from '@ngx-translate/core';
import { TooltipModalComponent } from './modals/tooltip-modal/tooltip-modal.component';

interface IColumnOptionState {
  [productCode: string]: IQuoteOption;
}

@Unsubscribe()
@Component({
  selector: 'policy-matrix',
  templateUrl: './policy-matrix.component.html',
})
export class PolicyMatrixComponent extends BaseComponent {
  private InsuranceProduct = InsuranceProduct;
  appConfigSetting: IAppConfigSettings;

  @select(['tripDetails', 'isClubMember']) readonly isClubMember$: Observable<string>;
  @select(['tripDetails', 'prevPolicyProductSelected']) readonly policyProductSelected$: Observable<string>;

  // Compute the coverage type
  @select(['tripDetails', 'countrySelected'])
  readonly countrySelected$: Observable<ICountry>;
  @select(['tripDetails', 'isTravellingOnlyInCanada'])
  readonly isTravellingOnlyInCanada$: Observable<boolean>;
  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<string>;
  @select(['tripDetails', 'sumInsured']) readonly sumInsured$: Observable<number>;
  readonly coverageType$: Observable<CoverageType>;

  // Compute the comparison table itself
  readonly comparisonTables$: Observable<IPolicyMatrix>;

  // Compute the total number of columns
  readonly totalColumns$: Observable<number>;

  // Compute the quote option for each column
  @select(['tripDetails', 'durationOfFirstTrip'])
  readonly durationOfFirstTrip$: Observable<number>;
  @select('quoteOptions') readonly quoteOptions$: Observable<IQuoteOption[]>;
  public columnOptionState$: Observable<IColumnOptionState>;

  productGroup = ProductGroup;
  COVERAGE_TYPES: typeof CoverageType = CoverageType;
  modalRef: BsModalRef;

  public singleMultiMatrixData: SingleTripMultiTripDataBuilder;
  public renew: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clubConfigurationService: ClubConfigurationService,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) {
    super();
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.using(this.activatedRoute.data.subscribe((d) => (this.renew = d.renew))).attach(this);

    this.coverageType$ = combineLatest([this.productGroupFlow$, this.countrySelected$, this.isTravellingOnlyInCanada$]).pipe(
      map(([productGroupFlow, countrySelected, isTravellingOnlyInCanada]) => {
        switch (productGroupFlow) {
          case ProductGroup.SingleTripFlow:
            if (countrySelected && countrySelected.code === CANADA_ONLY_CODE && isTravellingOnlyInCanada) {
              return CoverageType.SINGLE_TRIP_CANADA_ONLY;
            } else {
              return CoverageType.SINGLE;
            }
          case ProductGroup.MultiTripFlow:
          case ProductGroup.RepurchaseFlow:
            return CoverageType.MULTI;
          case ProductGroup.TCIFlow:
            return CoverageType.CANCEL;
          default:
            return undefined;
        }
      })
    );

    this.comparisonTables$ = combineLatest([this.coverageType$, this.sumInsured$]).pipe(
      map(([coverageType, insuredValue]) => {
        this.singleMultiMatrixData = new SingleTripMultiTripDataBuilder(
          this.clubConfigurationService.appConfig.i18n.en.policy_details,
          insuredValue
        );

        const policyMatrix: IPolicyMatrix = {
          coverageTypeId: coverageType,
          tables: [],
        };
        this.singleMultiMatrixData.comparisonMappings
          .filter((item) => item.coverageType === coverageType)
          .forEach((item) => {
            item.renderTables.forEach((renderTable, index) => {
              // Get data
              switch (renderTable) {
                case 'single-trip':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmSingleTripData;
                  break;
                case 'multi-trip':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmMultiTripData;
                  break;
                case 'trip-cancellation':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmTripCancelData;
                  break;
                case 'emergency-medical':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmEmergencyMedicalData;
                  break;
                case 'single-trip-premium':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmSingleTripPremium;
                  break;
                case 'canada-only':
                  policyMatrix.tables[index] = this.singleMultiMatrixData.pmCanadaOnlyMedicalData;
                  break;
              }
              // Set column count
              policyMatrix.tables[index].columnCount = policyMatrix.tables[index].tableBodyRows[0].rowData.length;
            });
          });
        return policyMatrix;
      })
    );

    this.totalColumns$ = this.comparisonTables$.pipe(
      map((policyMatrix) => {
        let colTotal = 0;
        policyMatrix.tables.forEach((item) => {
          colTotal += item.columnCount;
        });
        return colTotal;
      })
    );

    this.columnOptionState$ = combineLatest([this.durationOfFirstTrip$, this.quoteOptions$]).pipe(
      map(([durationOfFirstTrip, ops]) => {
        const tripDurationSelected = Number(durationOfFirstTrip);
        const columnState = {} as IColumnOptionState;
        // For each product code find the IQuoteOption.
        Object.keys(InsuranceProduct).forEach((key) => {
          const productCode = InsuranceProduct[key];
          if (ops) {
            // Is this a multi trip?
            if (productGrouping(productCode) === ProductGroup.MultiTripFlow) {
              // Default Logic, pick the plan that is less then the current duration and let topup fill in the rest.
              const reverseSorted = ops.filter((o) => o.productCode === productCode).sort((a, b) => b.planDays - a.planDays);
              for (let i = 0; i < reverseSorted.length; i++) {
                if (reverseSorted[i].planDays <= tripDurationSelected || i === reverseSorted.length - 1) {
                  columnState[productCode] = reverseSorted[i];
                  break;
                }
              }
            } else {
              // Find the first option
              columnState[productCode] = ops.find((o) => o.productCode === productCode);
            }
          } else {
            columnState[productCode] = null;
          }
        });
        return columnState;
      })
    );
  }

  @dispatch() insuranceProductSelected = (selected: InsuranceProduct) => ({
    type: SalesFunnelActionType.UPDATE_POLICY_PRODUCT,
    tripDetailsState: { productSelected: selected },
  });

  advanceToReviewQuote() {
    this.router.navigateByUrl(this.renew ? '/renew/review-quote' : '/review-quote');
  }

  eligibilityAccepted(selected: InsuranceProduct) {
    this.insuranceProductSelected(selected);
    this.advanceToReviewQuote();
  }

  openEligibilityDialog(selected: InsuranceProduct) {
    this.modalRef = this.modalService.show(
      // tslint:disable-next-line: no-use-before-declare
      EligibilityRequirementsModalComponent,
      {
        class: 'eligibility-dialog-modal',
        initialState: {
          forceDialog: true,
          afterFunction: () => {
            this.eligibilityAccepted(selected);
          },
        },
        ignoreBackdropClick: true,
        backdrop: true,
      }
    );
  }
  showEligibilityRequirementsModal() {
    this.modalRef = this.modalService.show(
      // tslint:disable-next-line: no-use-before-declare
      EligibilityRequirementsModalComponent
    );
  }

  showToolTipModal(tooltipHtml: string) {
    this.modalRef = this.modalService.show(TooltipModalComponent, {
      class: 'eligibility-dialog-modal',
      initialState: {
        tooltipHtml,
      },
      backdrop: true,
    });
  }

  focusOnAmountInput() {
    const amount = document.getElementById('insuredAmount') as HTMLInputElement;
    amount.focus();
    amount.value = null;

    const event = new Event('input', { bubbles: true });
    amount.dispatchEvent(event);
  }

  insertTenant(isClubMember, col) {
    if (col.colSummarySubtitle !== 'policy_matrix.best_value') {
      if (isClubMember) {
        return this.translateService.instant('policy_matrix.ama_member_discount');
      } else {
        return this.translateService.instant('policy_matrix.ata_member_discount');
      }
    }
  }
}
