import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base-component.component';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { Observable } from 'rxjs';
import { select, dispatch, NgRedux } from '@angular-redux-ivy/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IAppState } from 'src/app/models/data.interfaces';
import { InsuranceProduct, ProductGroup } from 'src/app/models/products';
import { SalesFunnelActionType } from 'src/app/store/reducer';

@Component({
  selector: 'eligibility-requirements-modal',
  templateUrl: './eligibility-requirements-modal.component.html',
})
export class EligibilityRequirementsModalComponent extends BaseComponent implements AfterViewInit {
  public areEligible = false;
  public afterFunction: any;
  public forceDialog: boolean;
  @select(['tripDetails', 'productGroupFlow']) readonly productGroupFlow$: Observable<string>;
  ProductGroup = ProductGroup;

  constructor(public bsModalRef: BsModalRef, private googleAnalyticsService: GoogleAnalyticsService) {
    super();
    this.googleAnalyticsService.setAndSendModalView('view-eligibility-requirements-modal', 'View eligibility Requirements');
  }
  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }

  continueClicked() {
    this.afterFunction();
    this.bsModalRef.hide();
  }
}
