import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IServerAppState } from 'src/app/models/data.interfaces';

@Injectable({
  providedIn: 'root',
})
export class RenewService {
  private readonly baseApiUrl: string;

  constructor(private readonly httpClient: HttpClient) {
    this.baseApiUrl = `${environment.baseApiUrl}/renew`;
  }

  public getPolicy(policyNumber: string, primaryTravellerLastName: string): Observable<{ error: string } | IServerAppState> {
    return this.httpClient.get<IServerAppState>(`${this.baseApiUrl}/getPolicy`, { params: { policyNumber, primaryTravellerLastName } });
  }
}
