import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../base-component.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'traveller-conditions',
  templateUrl: './traveller-conditions.component.html',
})
export class TravellerConditionsComponent extends BaseComponent implements OnInit {
  // Used internally to debounce checkbox value changes before emitting the newQuoteOptionsNeeded event.
  private readonly checkValuesChangeSubject = new Subject();
  public showHelpText = false;
  public showStabilityPeriod = false;

  @Output() newQuoteOptionsNeeded = new EventEmitter();
  @Output() checkValuesChanging = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
    this.using(
      this.checkValuesChangeSubject.pipe(debounceTime(1000)).subscribe(() => {
        this.newQuoteOptionsNeeded.emit();
      })
    ).attach(this);
  }

  checkChanged(): void {
    this.checkValuesChanging.emit();
    this.checkValuesChangeSubject.next(null);
  }
}
