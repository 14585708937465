<ng-container *injector="{};let matchMedia = matchMedia;">
  <form #f="ngForm"
        id="coverage"
        connect="tripDetails"
        (ngSubmit)="onSubmit(f)"
        analytic-ama="form"
        analytic-id="coverage-multi-trip">
    <div class="row justify-content-sm-center justify-content-lg-start">
      <div class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-4 col-lg-3">
        <div class="form-group">
          <label class="h6 mt-3"
                 for="numberOfTravellers">
            {{ 'multi_trip.number_of_travellers.prompt' | translate }}
          </label>
          <number-of-travellers [analyticsPrefix]="analyticsPrefix" />
        </div>
      </div>
      <div class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-4 col-lg-3">
        <div class="form-group">
          <label class="h6 mt-3"
                 for="btnPolicyStartDate">{{ 'multi_trip.policy_start_date.prompt' | translate }}</label>
          <div class="input-group">
            <div class="input-group__overlay-input">
              <button class="form-control  multi-policy-start-date"
                      [ngClass]="{ 'is-invalid': (f.submitted && ctlpolicyStartDate.invalid) }"
                      type="button"
                      id="btnPolicyStartDate"
                      (click)="policyStartDateIsOpen = !policyStartDateIsOpen"
                      [attr.aria-expanded]="ctlpolicyStartDate.isOpen"
                      analytic-ama="form">
                <svg-icon name="calendar" />
              </button>
            </div>
            <date-input #ctlpolicyStartDate="ngModel"
                        [inputClass]="{ 'is-invalid': (f.submitted && ctlpolicyStartDate.invalid) }"
                        placeholder="{{ 'multi_trip.policy_start_date.placeholder' | translate }}"
                        [required]="true"
                        [minDate]="minDepartureDate"
                        ngModel
                        ngControl
                        name="policyStartDate"
                        [isOpen]="policyStartDateIsOpen"
                        (onHidden)="this.policyStartDateIsOpen = false"
                        (onShown)="onShowPicker($event)"
                        tabindex="-1" />


            <div *ngIf="f.submitted && ctlpolicyStartDate.invalid"
                 class="invalid-feedback">
              <span *ngIf="ctlpolicyStartDate.errors?.required">{{ 'multi_trip.policy_start_date.required' | translate }}</span>
              <span *ngIf="ctlpolicyStartDate.errors?.minDate">{{ 'multi_trip.policy_start_date.min_date' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-4 col-lg-3">
        <div class="form-group">
          <div class="c-form-label-tooltip">
            <label class="h6 mt-3 u-text-no-wrap"
                   for="durationOfFirstTrip">
              <span>{{
          'multi_trip.duration_of_first_trip.prompt' | translate
        }}</span>
              <i class="c-form-label-tooltip__icon">
                <button type="button"
                        class="btn btn-link m-0 p-0"
                        tooltip="{{ 'multi_trip.duration_of_first_trip.tooltip' | translate }}"
                        [placement]="matchMedia.lg ? 'left' : 'top'"
                        container="body"
                        tabindex="-1">
                  <svg-icon name="question_mark_circle" />
                </button></i></label>
          </div>
          <input type="number"
                 class="form-control multi-trip-duration"
                 #durationOfFirstTrip="ngModel"
                 [ngClass]="{ 'is-invalid': (f.submitted && durationOfFirstTrip.invalid) }"
                 placeholder="{{ 'multi_trip.duration_of_first_trip.placeholder' | translate }}"
                 required
                 [min]="1"
                 [max]="365"
                 digitOnly
                 inputmode="numeric"
                 pattern="[0-9]*"
                 ngModel
                 ngControl
                 id="durationOfFirstTrip"
                 name="durationOfFirstTrip"
                 analytic-ama="form" />
          <div *ngIf="(f.submitted && durationOfFirstTrip.invalid)"
               class="invalid-feedback">
            <span *ngIf="durationOfFirstTrip.errors?.required">{{ 'multi_trip.duration_of_first_trip.required' | translate }}</span>
            <span *ngIf="durationOfFirstTrip.errors?.min">{{ 'multi_trip.duration_of_first_trip.more_than_one' | translate }}</span>
            <span *ngIf="durationOfFirstTrip.errors?.max">{{ 'multi_trip.duration_of_first_trip.more_than_365' | translate }}</span>
            <span *ngIf="durationOfFirstTrip.errors?.pattern">{{ 'multi_trip.duration_of_first_trip.pattern' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-sm-center">
      <div class="col-12 col-sm-8 pl-sm-5 pr-sm-5 pl-md-3 pr-md-3 col-md-5 col-lg-6">
        <button class="btn btn-secondary btn-block btn-lg mt-3 mt-sm-4 mt-md-5 mb-4  multi-trip-submit"
                type="submit"
                (click)="syncDepartureDateReturnDate(ctlpolicyStartDate.value, durationOfFirstTrip.value);">
          {{ 'multi_trip.submit' | translate }}
        </button>
      </div>
    </div>
  </form>
</ng-container>