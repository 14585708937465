import { IAppState, IPolicyInfo, IQuoteOption, IServerError, ITravellerInfo, ITripDetails } from '../models/data.interfaces';
import { GetAppInitialState, GetInitialPolicyInfo, GetInitialTravellerInfo } from './initial-states';
import { IMultiStateUpdateAction } from './trip-details-action-creator.service';
import { cloneDeep } from 'lodash-es';
import { Track } from '../models/track.enum';

// TODO: Identify if we want to store all the possible actions here or with the component Actions.ts file.
export enum SalesFunnelActionType {
  CLEAR_WHOLE_STATE = 'clearWholeState',
  LOAD_QUOTE = 'quote/Load',
  UPDATE_QUOTE_ID = 'quote/UpdateQuoteWithId',
  UPDATE_POLICY_INFO = 'quote/UpdatePolicyInfo',
  UPDATE_TRIP_DETAILS = 'tripDetails/Update',
  UPDATE_POLICY_PRODUCT = 'policyInfo/UpdateProduct',
  UPDATE_TRAVELLING_COUNTRY = 'tripDetails/TravellingCountry',
  UPDATE_TRAVELLING_SUB_REGION = 'tripDetails/TravellingSubRegion',
  UPDATE_NUMBER_OF_TRAVELLERS = '.../UpdateNumberOfTravellers',
  UNDEFINED = 'undefined',
  FETCH_QUOTE_OPTIONS = 'quote/FetchOptions',
  CLEAR_QUOTE_OPTIONS = 'quote/ClearOptionsWhenTripDetailsInvalidated',
  SET_QUOTE_OPTIONS = 'quote/SetOptions',
  UPDATE_POLICY_DURATION = 'tripDetails/UpdatePolicy',
  UPDATE_SELECTED_PRODUCT_GROUP = 'policy/UpdateSelectedProductGroup',
  UPDATE_SELECTED_QUOTE_OPTION = 'currentQuote/SelectedQuoteChanged',
  UPDATE_SELECTED_QUOTE_DEDUCTIBLE = 'currentQuote/UpdateSelectedQuoteDeductible',
  SET_LAST_SERVER_ERROR = 'server/SetLastServerError',
  CLEAR_LAST_SERVER_ERROR = 'server/ClearLastServerError',
  UPDATE_PRIMARY_TRAVELLER_FROM_USER_PROFILE = 'travellerInfo/UpdatePrimaryFromUserProfile',
  UPDATE_MEDICAL_QUESTIONNAIRE = 'medicalQuestionnaire/Update',
  CREATE_MEDICAL_QUESTIONNAIRE = 'medicalQuestionnaire/Create',
  REMOVE_MEDICAL_QUESTIONNAIRE = 'medicalQuestionnaire/Remove',
  UPDATE_MEDICAL_QUESTIONNAIRE_ANSWER = 'medicalQuestionnaireAnswer/Update',
  REPURCHASE_LOAD_POLICY = 'repurchase/LoadPolicy',
  REPURCHASE_RESET_CHANGES = 'repurchase/ResetChanges',
  REPURCHASE_SET_MODIFIED_STATE = 'repurchase/SetModifiedState',
  REPURCHASE_CLEAR_MODIFIED_STATE = 'repurchase/ClearModifiedState',
}

export interface IAppAction {
  type: SalesFunnelActionType;
  appState: IAppState;
}

export interface IPolicyInfoAction {
  type: SalesFunnelActionType;
  policyInformationState: IPolicyInfo;
}

export interface ITravellerInfoAction {
  type: SalesFunnelActionType;
  travellerInfoState: ITravellerInfo;
}

export interface IQuoteOptionAction {
  type: SalesFunnelActionType;
  payload?: IQuoteOption[];
  travellerIndex?: number;
}

export interface ILoadQuoteAction {
  type: SalesFunnelActionType;
  payload?: IAppState;
}

export function policyReducer(state: IPolicyInfo = GetInitialPolicyInfo(), policyAction: IPolicyInfoAction): IPolicyInfo {
  switch (policyAction.type) {
    case SalesFunnelActionType.UNDEFINED:
      return updatePolicy(state, policyAction.policyInformationState);
    case SalesFunnelActionType.UPDATE_POLICY_INFO:
      return updatePolicy(state, policyAction.policyInformationState);
    case SalesFunnelActionType.REPURCHASE_SET_MODIFIED_STATE:
      return { ...state, policyModified: true };
    case SalesFunnelActionType.REPURCHASE_CLEAR_MODIFIED_STATE:
      return { ...state, policyModified: false };
    default:
      return state;
  }
}

function updatePolicy(state: IPolicyInfo, policyInfoState: IPolicyInfo): IPolicyInfo {
  return Object.assign({}, state, { policyInfo: policyInfoState });
}

export function travellerInfoReducer(
  state: ITravellerInfo = GetInitialTravellerInfo(),
  travellerInfoAction: ITravellerInfoAction | IMultiStateUpdateAction
): ITravellerInfo {
  switch (travellerInfoAction.type) {
    case SalesFunnelActionType.UNDEFINED:
    case SalesFunnelActionType.UPDATE_PRIMARY_TRAVELLER_FROM_USER_PROFILE:
      // Dates will be stored as UTC in the store, so before loading a quote
      // we will convert all dates into ISO strings.
      const infoState = (travellerInfoAction as ITravellerInfoAction).travellerInfoState;
      if (infoState) {
        serializeDate(infoState, 'dateOfBirth');
      }
      return updateAllTravellerInfo(state, infoState);
    case SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS:
      return updateAllTravellerInfo(state, (travellerInfoAction as IMultiStateUpdateAction).payload.travellerInfoState);
    default:
      return state;
  }
}

function updateAllTravellerInfo(state: ITravellerInfo, allTravellerInfo: ITravellerInfo): ITravellerInfo {
  return { ...state, ...allTravellerInfo };
}

export function tripDetailsQuoteOptionReducer(state: IQuoteOption[] = [], action: IQuoteOptionAction): IQuoteOption[] {
  switch (action.type) {
    case SalesFunnelActionType.SET_QUOTE_OPTIONS:
      return action.payload;
    default:
      return state;
  }
}

function serializeDate<T>(instance: T, key: keyof T) {
  if (instance && key) {
    let v: any = instance[key];
    if (v && v instanceof Date) {
      v = v.toISOString();
    }
    instance[key] = v;
  }
}

export function appActionReducer() {
  return (state: IAppState, action: IAppAction) => {
    switch (action.type) {
      case SalesFunnelActionType.CLEAR_WHOLE_STATE:
        return GetAppInitialState();
      default:
        return state;
    }
  };
}

export function wholeAppStateReducer() {
  return (state: IAppState, action: ILoadQuoteAction) => {
    switch (action.type) {
      case SalesFunnelActionType.LOAD_QUOTE:
        // Dates will be stored as UTC in the store, so before loading a quote
        // we will convert all dates into ISO strings.
        if (action.payload) {
          serializeDate(action.payload.tripDetails, 'departureDate');
          serializeDate(action.payload.tripDetails, 'returnDate');
          serializeDate(action.payload.tripDetails, 'policyStartDate');
        }
        return action.payload;
      case SalesFunnelActionType.REPURCHASE_LOAD_POLICY:
        const appState = action.payload;
        const newState = {
          ...action.payload,
          quoteOptions: [],
          lastPolicy: {
            travellerInfo: cloneDeep(appState.travellerInfo),
            tripDetails: cloneDeep(appState.tripDetails),
          },
        };

        if (appState.policyInfo.track === Track.SlowTrack) {
          appState.policyInfo.policyModified = true;
        }

        return newState;
      case SalesFunnelActionType.REPURCHASE_RESET_CHANGES:
        return {
          ...state,
          travellerInfo: cloneDeep(state.lastPolicy.travellerInfo),
          tripDetails: cloneDeep(state.lastPolicy.tripDetails),
          quoteOptions: [],
        };
      default:
        return state;
    }
  };
}

// This reducer is here simply as a placeholder reducer for managing the quoteID property of the Redux store.
// Without it, the Redux store will never have a quoteID property whenever the wholeAppStateReducer fires.
export function quoteIdReducer(state: string = null, action: IUpdateQuoteIdAction): string {
  switch (action.type) {
    case SalesFunnelActionType.UPDATE_QUOTE_ID:
      return action.payload;
    default:
      return state;
  }
}

export interface IUpdateQuoteIdAction {
  type: SalesFunnelActionType;
  payload?: string;
}

export interface IServerErrorAction {
  type: SalesFunnelActionType;
  payload?: IServerError;
}

export function lastServerErrorReducer(state: IServerError = null, action: IServerErrorAction) {
  switch (action.type) {
    case SalesFunnelActionType.SET_LAST_SERVER_ERROR:
      return action.payload;
    case SalesFunnelActionType.CLEAR_LAST_SERVER_ERROR:
      return null;
    default:
      return state;
  }
}

export function lastPolicyReducer(state: any = null, action: any) {
  return state;
}
