import { Injectable } from '@angular/core';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, ProductGroup, ITripDetails, IMedicalQuestionnaire } from 'src/app/models/data.interfaces';
import { distinctUntilChanged, tap, startWith, map, skip } from 'rxjs/operators';
import { QuoteService } from 'src/app/core/services/quote.service';
import { isEqual } from 'underscore';
import { updateCurrentQuoteDeductible } from 'src/app/store/current-quote-details.reducer';
import { combineLatest } from 'rxjs';

/**
 * This service is used by different implementations of the trip details page.
 */
@Injectable()
export class TripDetailsService {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private readonly matchMediaService: MatchMediaService,
    private readonly scrollToService: ScrollToService,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly quoteService: QuoteService
  ) {}

  /**
   * Used to automatically scroll to the policy matrix on desktop.
   */
  public scrollToPolicyMatrix() {
    setTimeout(() => {
      if (this.matchMediaService.matches(MEDIA_TYPE.md)) {
        const config: ScrollToConfigOptions = {
          target: 'planMatrix',
          duration: 1400,
          easing: 'easeInOutQuint',
          offset: 200,
        };
        this.scrollToService.scrollTo(config);
      }
    }, 0);
  }

  /**
   * Used to automatically hide the policy matrix after changes to the trip details.
   */
  public clearQuoteOptionsOnChange() {
    // Ignore the product selected when comparing
    const initialTripDetails = <ITripDetails>{ ...this.reduxStore.getState().tripDetails, productSelected: null };
    const initialMedQs = this.reduxStore.getState().medicalQuestionnaires;

    // Any changes should destroy the existing matrix.
    return combineLatest([
      this.reduxStore
        .select((x) => x.tripDetails)
        .pipe(
          // Ignore the product selected when comparing trip details
          map((tripDetails) => <ITripDetails>{ ...tripDetails, productSelected: null })
        ),
      this.reduxStore.select((x) => x.medicalQuestionnaires),
    ]).pipe(
      startWith([initialTripDetails, initialMedQs]),
      distinctUntilChanged<[ITripDetails, IMedicalQuestionnaire[]]>(isEqual),
      // Skip the first event because distinctUntilChanged has nothing to compare against.
      skip(1),
      tap(([newTripDetails, _]) => {
        this.quoteService.clearQuoteOptions();

        // Don't clear the deductible in the repurchase flow
        if (newTripDetails.productGroupFlow !== ProductGroup.RepurchaseFlow) {
          this.reduxStore.dispatch(updateCurrentQuoteDeductible(null));
        }
      })
    );
  }
}
