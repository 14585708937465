import { Component, OnInit } from '@angular/core';
import { ClubConfigurationService } from '../../core/services/club-configuration.service';
import { IAppConfig } from '../../models/app-config.model';
import { TenantEnum } from 'src/app/models/tenant.enum';

@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
})
export class SiteHeaderComponent implements OnInit {
  TenantEnum = TenantEnum;
  public appConfig: IAppConfig;
  public tenant: string;

  constructor(private clubConfigurationService: ClubConfigurationService) {}

  ngOnInit() {
    this.appConfig = this.clubConfigurationService.appConfig;
    this.getTenant();
  }

  public getTenant() {
    this.tenant = this.appConfig.settings.tenant;
  }
}
