import { FunctionSelector, NgRedux } from '@angular-redux-ivy/store';

declare module '@angular-redux-ivy/store/components/ng-redux' {
  export interface NgRedux<RootState> {
    /**
     * If there are any null/undefined properties in the selector, undefined will be returned.
     */
    get<SelectedType>(selector?: FunctionSelector<RootState, SelectedType>): SelectedType;
  }
}

NgRedux.prototype.get = function <RootState, SelectedType>(selector?: FunctionSelector<RootState, SelectedType>): SelectedType {
  try {
    return selector((this as NgRedux<RootState>).getState());
  } catch (error) {}
  return undefined;
};
