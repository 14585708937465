import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';

@Directive({
  selector: '[clubSupportedProvinces][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ClubSupportedProvincesDirective, multi: true }],
})
export class ClubSupportedProvincesDirective implements Validator {
  constructor(private readonly clubConfigService: ClubConfigurationService) {}

  public validate(control: AbstractControl): ValidationErrors | null {
    const provinceCode = control.value;
    if (provinceCode) {
      if (!this.clubConfigService.isProvinceCodeSupported(provinceCode)) {
        return {
          unsupportedProvince: true,
        };
      }
    }
    return null;
  }
}
