<div class="o-save-quote">
    <div class="modal-header justify-content-center align-items-center">
        <h3 [innerHtml]="eventConfig.translationKey + '.title' | translate | safeHtml"></h3>
        <button *ngIf="eventConfig.canDismiss"
                type="button"
                class="u-close u-close--light-hover"
                data-dismiss="modal"
                aria-label="Close"
                (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-10 m-auto">
                <div class="row my-2">
                    <div class="form-group col-12"
                         [innerHtml]="eventConfig.translationKey + '.description' | translate | safeHtml">
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12">
                        <button type="button"
                                class="col-12 no-gutters btn btn-secondary btn-lg"
                                id="event-modal-submit"
                                (click)="close()">
                            <span class="px-1"
                                  [innerHtml]="eventConfig.translationKey + '.cta' | translate | safeHtml"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>