<ng-container *injector="{
    tripDetails: tripDetails$,
    currentQuoteDetails: currentQuoteDetails$,
    quoteSelected: quoteSelected$
};
let tripDetails = tripDetails;
let currentQuoteDetails = currentQuoteDetails;
let quoteSelected = quoteSelected;
">
    <div class="c-policy-table">
        <div *ngIf="tripDetails?.productGroupFlow !== productGroup.RentalVehicleDamageFlow"
             class="c-cd__item">
            <div class="c-cd__item-label col-md-6 px-2">
                {{ 'policy-confirmation.details.travellers_covered.prompt' | translate }}:
            </div>
            <div class="c-cd__item-value col-md-6 px-2">
                {{ tripDetails?.numberOfTravellers }}
            </div>
        </div>
        <div class="c-cd__item">
            <div class="c-cd__item-label  col-md-6 px-2">
                {{ (tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow ? 'policy-confirmation.details.pick_up_date.prompt' : 'policy-confirmation.details.policy_start_date.prompt') | translate }}:
            </div>
            <div class="c-cd__item-value  col-md-6 px-2">
                {{ tripDetails?.policyStartDate | date:dateFormat:'UTC' }}
            </div>
        </div>
        <div class="c-cd__item"
             *ngIf="tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow">
            <div class="c-cd__item-label  col-md-6 px-2">
                {{ 'policy-confirmation.details.drop_off_date.prompt' | translate }}:
            </div>
            <div class="c-cd__item-value  col-md-6 px-2">
                {{ tripDetails?.returnDate | date:dateFormat:'UTC' }}
            </div>
        </div>
        <div class="c-cd__item"
             *ngIf="tripDetails?.durationOfFirstTrip">
            <div class="c-cd__item-label  col-md-6 px-2">
                {{ 'policy-confirmation.details.first_trip_duration.prompt' | translate }}:
            </div>
            <div class="c-cd__item-value  col-md-6 px-2">
                {{ tripDetails?.durationOfFirstTrip }} Days
            </div>
        </div>
        <div *ngIf="tripDetails?.productGroupFlow !== productGroup.RentalVehicleDamageFlow"
             class="c-cd__item">
            <div class="c-cd__item-label  col-md-6 px-2">
                {{ 'policy-confirmation.details.deductible.prompt' | translate }}:
            </div>
            <div class="c-cd__item-value  col-md-6 px-2">
                {{ currentQuoteDetails?.deductibleAmount | currency:'CAD':'symbol-narrow':'1.2-2' }}
            </div>
        </div>
    </div>

    <div class="c-policy-table c-policy-table--total col-lg-8 m-auto">
        <div *ngIf="quoteSelected?.hasMemberDiscount
          || quoteSelected?.hasFamilyDiscount
          || quoteSelected?.hasDeductibleDiscount
          || quoteSelected?.hasPromoDiscount"
             class="c-tds__item c-tds__item--subtotal-price">
            <div class="c-tds__item-info row">
                <label
                       class="c-policy-table__left col-md-6 px-2 d-block"><strong>{{ 'policy-confirmation.details.subtotal.prompt' | translate }}:</strong></label>
                <span class="c-policy-table__right col-md-6 px-2 d-block">
                    {{ quoteSelected?.subtotalPrice   | currency }}
                </span>
            </div>
        </div>
        <ng-container *ngIf="quoteSelected?.hasDeductibleDiscount">
            <div class="c-tds__item c-tds__item--deductible-discount-price u-ama-color--green">
                <div class="c-tds__item-info row">
                    <label
                           class="c-policy-table__left col-md-6 px-2 d-block"><strong>{{ 'policy-confirmation.details.deductible_discount.prompt' | translate }}:</strong></label>
                    <span class="c-policy-table__right col-md-6 px-2 d-block">
                        (-{{ quoteSelected?.deductibleDiscount | currency }})
                    </span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="quoteSelected?.hasFamilyDiscount">
            <div class="c-tds__item c-tds__item--family-discount-price"
                 [ngClass]="{'u-ama-color--green': quoteSelected?.familyDiscount > 0, 'u-ama-color--red': quoteSelected?.familyDiscount < 0}">
                <div class="c-tds__item-info row">
                    <label
                           class="c-policy-table__left col-md-6 px-2 d-block"><strong>{{ 'policy-confirmation.details.family_discount.prompt' | translate }}:</strong></label>
                    <span class="c-policy-table__right col-md-6 px-2 d-block">
                        {{ quoteSelected?.familyDiscount  | discount  }}
                    </span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="quoteSelected?.hasMemberDiscount">
            <div class="c-tds__item c-tds__item--member-discount-price u-ama-color--green">
                <div class="c-tds__item-info row">
                    <label
                           class="c-policy-table__left col-md-6 px-2 d-block"><strong>{{ 'policy-confirmation.details.member_discount.prompt' | translate }}:</strong></label>
                    <span class="c-policy-table__right col-md-6 px-2 d-block">
                        (-{{ quoteSelected?.memberDiscount | currency }})
                    </span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="quoteSelected?.hasPromoDiscount">
            <div class="c-tds__item c-tds__item--promo-discount-price u-ama-color--green">
                <div class="c-tds__item-info row">
                    <label
                           class="c-policy-table__left col-md-6 px-2 d-block"><strong>{{ 'policy-confirmation.details.promo_discount.prompt' | translate }}:</strong></label>
                    <span class="c-policy-table__right col-md-6 px-2 d-block">
                        (-{{ quoteSelected?.promoDiscount | currency }})
                    </span>
                </div>
            </div>
        </ng-container>
        <div class="c-tds__item c-tds__item--total-price">
            <div class="c-tds__item-info row">
                <h4 class="col-md-6 px-2">
                    <label class="c-policy-table__left d-block">{{ 'policy-confirmation.details.total_cost.prompt' | translate }}:</label>
                </h4>
                <h4 class="col-md-6 px-2">
                    <span class="c-policy-table__right d-block text-success">
                        {{ quoteSelected?.totalPrice  | currency  }}
                    </span>
                </h4>
            </div>
        </div>
    </div>
</ng-container>
