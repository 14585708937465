import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'exclusive-saving-message',
  templateUrl: './exclusive-saving-message.component.html',
})
export class ExclusiveSavingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
