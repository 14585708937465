<div class="tst-form tst-form--flight">
	<div class="tst-form__container u-flex u-flex__wrap u-row-xs--none u-row-sm--base">
		<form [formGroup]="flightForm"
			  class="tst-form__form u-rpadding-sm--x"
			  #flightFormElements
			  #form
			  autocomplete="off"
			  [ngClass]="{invalid: selectedFormGroup.invalid,
						'tst-form__form--is-submitted' : selectedNgForm.submitted}">
			<div class="tst-form__form-items">
				<div class="tst-form__options"
					 [formGroup]="flightForm.get('flightOptions')">
					<ng-container *ngFor="let field of formOptionControls; let i = index;">
						<label class="tst-form__option"
							   [attr.analytic-ama]="field.analytics">
							<input [formControl]="radioControl"
								   type="radio"
								   [hidden]
								   [value]="field?.value"
								   [checked]="field?.selected || field?.control?.value[1] == field?.value[1]"
								   name="flightRadios">
							<span class="tst-form__option-button">{{field?.label}}</span>
						</label>
					</ng-container>
				</div>
			</div>
			<div #staticFlightFields
				 class="tst-form__form-items">
				<ng-container *ngFor="let field of formFields; let i = index;">
					<div *ngIf="field?.name == 'fromCity'"
						 [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name"
						 [ngClass]="{rt:!isReturn}">
						<label class="tst-form__control-label"
							   *ngIf="field?.type != 'hidden'">{{field?.label}}</label>
						<div class="tst-form__control tst-form__control--wrap tst-form__control--autocomplete"
							 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && field?.control.invalid, 'cityEmpty': !field?.airport.label, 'invalidLocation': mq.mobile && !selectedForm.formGroup.get('fromCityStatus').value}">
							<input [formControl]="field?.control"
								   #fromCityInput
								   type="text"
								   [(ngModel)]="field.airport.label"
								   (input)="autocompleteTyping($event.target?.value, field)"
								   (blur)="resetAutocomplete()"
								   [placeholder]="field?.placeHolder"
								   class="tst-form__field tst-form__field--autocomplete"
								   [ngClass]="{'tst-form__field--autocomplete' : field?.autocomplete}"
								   [attr.tabindex]="1"
								   required />
							<span class="tst-icon tst-icon--map-marker tst-icon--prefix">
								<fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>
							</span>
							<ul id="autoCompleteLeaveCity"
								#autoCompleteLeaveCity
								[hidden]="!field?.open"
								[class]="'hide tst-form__autocomplete tst-form__autocomplete--'+field?.name"
								(mousedown)="autoCompleteChoice($event,field)"
								[ngClass]="{open:field?.open}">
							</ul>

						</div>
					</div>
					<div *ngIf="field?.name == 'datePickerLeave' && selectedForm?.name != 'return'"
						 [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name + ' ' + datepickerPlacement.placement"
						 [ngClass]="{rt:!isReturn}"
						 ngbDropdown
						 #dropDownElDepartSingle="ngbDropdown"
						 autoClose="outside"
						 (openChange)="tstService.datepickerToggle($event,{dp:field,direction:'from',dates:dates, el:dropDownElDepartSingle, placement:datepickerPlacement})">
						<label class="tst-form__control-label"
							   *ngIf="field?.type != 'hidden'">{{field?.label}}</label>
						<div class="tst-form__control tst-form__control--wrap tst-form__control--datepicker"
							 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && !validateDateField(field?.control, datePickerLeave), 'dateEmpty': !dates.startDate}">
							<div class="tst-form__date-picker-form">
								<!--Datepicker One-way-->
								<input [value]="dates.startDate ? dateFormat(dates.startDate,'ddd, MMM Do') : ''"
									   [placeholder]="dates.dateRngMin.month + '/' + dates.dateRngMin.day + '/' + dates.dateRngMin.year"
									   class="tst-form__field tst-form__field--datepicker"
									   [attr.tabindex]="3"
									   autocomplete="off"
									   autocorrect="off"
									   required>
								<button class="tst-icon__wrap-button"
										type="button"
										id="dateDropDownLeaveSingle"
										ngbDropdownToggle>
									<span class="tst-icon tst-icon--calendar tst-icon--prefix">
										<svg class="c-icon c-icon--tstIcon">
											<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
										</svg>
									</span>
								</button>
								<span ngbDropdownMenu
									  aria-labelledby="dateDropDownLeaveSingle">
									<ng-container
												  *ngTemplateOutlet="singleDateDialog; context: {$implicit: {field: field, index: i, element: dropDownElDepartSingle}}">
									</ng-container>
								</span>
							</div>
							<div class="tst-search__backdrop"></div>
						</div>

					</div>
					<div *ngIf="field?.name == 'toCity'"
						 [ngClass]="{rt:!isReturn}"
						 [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name">
						<label class="tst-form__control-label"
							   *ngIf="field?.type != 'hidden'">{{field?.label}}</label>
						<div class="tst-form__control tst-form__control--wrap tst-form__control--autocomplete"
							 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && field?.control.invalid, 'cityEmpty': !field?.airport.label, 'invalidLocation':mq.mobile && !selectedForm.formGroup.get('toCityStatus').value}">
							<input [formControl]="field?.control"
								   #toCityInput
								   type="text"
								   [(ngModel)]="field.airport.label"
								   (input)="autocompleteTyping($event.target?.value, field)"
								   (blur)="resetAutocomplete()"
								   [placeholder]="field?.placeHolder"
								   [ngClass]="{'tst-form__field--autocomplete' : field?.autocomplete}"
								   [attr.tabindex]="2"
								   [name]="field?.name"
								   required />
							<span class="tst-icon tst-icon--map-marker tst-icon--prefix">
								<fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>
							</span>
							<ul #autoCompletetoCity
								[hidden]="!field?.open"
								[class]="'tst-form__autocomplete tst-form__autocomplete--'+field?.name"
								(mousedown)="autoCompleteChoice($event,field)"
								[ngClass]="{open:field?.open}">
							</ul>
						</div>
					</div>
					<ng-container *ngIf="field?.name == 'datePickerRange' && selectedForm?.name == 'return'">
						<div [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name + 'From ' + datepickerPlacement.placement"
							 [ngClass]="{rt:!isReturn}"
							 ngbDropdown
							 #dropDownElDepart="ngbDropdown"
							 autoClose="outside"
							 (openChange)="tstService.datepickerToggle($event,{dp:field,direction:'from',dates:dates, el:dropDownElDepart, placement:datepickerPlacement})">
							<label class="tst-form__control-label"
								   *ngIf="field?.type != 'hidden'">Depart Date</label>
							<div class="tst-form__control tst-form__control--wrap tst-form__control--datepicker tst-form__control--dropDownElDepart"
								 [ngClass]="{notValid : !validateDateField(dates.fromDateLong | date:'MM/dd/yyyy'), 'dateEmpty': !dates.startDate}">
								<div class="tst-form__date-picker-form">
									<!--range Datepicker From-->
									<input [value]="dates.startDate ? dateFormat(dates.startDate,'ddd, MMM Do') : ''"
										   [placeholder]="dateFormat(this.flightsDatePickerPlaceholder,'ddd, MMM Do')"
										   class="tst-form__field tst-form__field--datepicker"
										   [attr.tabindex]="3"
										   autocomplete="off"
										   autocorrect="off"
										   required>
									<button class="tst-icon__wrap-button"
											type="button"
											ngbDropdownToggle
											id="dateDropDownLeaveRange">
										<span class="tst-icon tst-icon--calendar tst-icon--prefix">
											<svg class="c-icon c-icon--tstIcon">
												<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
											</svg>
										</span>
									</button>
									<span ngbDropdownMenu
										  aria-labelledby="dateDropDownLeaveRange">
										<ng-container
													  *ngTemplateOutlet="dateRangeDialog; context: {$implicit: {field: field, index: i, element:dropDownElDepart, elementToTrigger: dropDownElReturn, flightDirection:'depart'}}">
										</ng-container>
									</span>
								</div>
								<div class="tst-search__backdrop"></div>
							</div>
						</div>
						<div [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name + 'To ' + datepickerPlacement.placement"
							 [ngClass]="{rt:!isReturn}"
							 ngbDropdown
							 #dropDownElReturn="ngbDropdown"
							 autoClose="outside"
							 (openChange)="tstService.datepickerToggle($event,{dp:field,direction:'return',dates:dates, el:dropDownElReturn, placement:datepickerPlacement})">
							<label class="tst-form__control-label"
								   *ngIf="field?.type != 'hidden'">Return Date</label>
							<div class="tst-form__control tst-form__control--wrap tst-form__control--datepicker tst-form__control--dropDownElReturn"
								 [ngClass]="{notValid : !validateDateField(dates.toDateLong), 'dateEmpty': !dates.endDate}">
								<div class="tst-form__date-picker-form">
									<!--range Datepicker To-->
									<input [value]="dates.endDate ? dateFormat(dates.endDate,'ddd, MMM Do') : ''"
										   [placeholder]="dateFormat(this.flightsDatePickerPlaceholder,'ddd, MMM Do')"
										   class="tst-form__field tst-form__field--datepicker"
										   [attr.tabindex]="4"
										   autocomplete="off"
										   autocorrect="off"
										   required>
									<button class="tst-icon__wrap-button"
											type="button"
											ngbDropdownToggle
											id="dateDropDownReturnRange">
										<span class="tst-icon tst-icon--calendar tst-icon--prefix">
											<svg class="c-icon c-icon--tstIcon">
												<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
											</svg>
										</span>
									</button>
									<span ngbDropdownMenu
										  aria-labelledby="dateDropDownReturnRange">
										<ng-container
													  *ngTemplateOutlet="dateRangeDialog; context: {$implicit: {field: field, index: i, element: dropDownElReturn, elementToTrigger: dropDownElDepart, flightDirection:'return'}}">
										</ng-container>
									</span>
								</div>
								<div class="tst-search__backdrop"></div>
							</div>
						</div>
					</ng-container>

					<!-- template for rooms/numOfGuests incrementor -->
					<div #packagesGuests
						 *ngIf="field?.name == 'numGuests'"
						 [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name"
						 [ngClass]="{rt:!isReturn}">
						<div class="tst-search__select-incrementor">
							<span class="tst-icon tst-icon--male tst-icon--prefix person">
								<fa-icon [icon]="['fal', 'male']"></fa-icon>
							</span>
							<form-stepper (callback)="formStepperCallback($event)"
										  (dialogOpenState)="formStepperDialogState($event)"
										  [data]="passengerIncrementorData" />
							<span class="tst-search__select-arrow">
								<span class="tst-icon tst-icon--chevron-down tst-icon--suffix">
									<fa-icon [icon]="['fal', 'chevron-down']"></fa-icon>
								</span>
							</span>
							<span #travellerHiddenFields
								  class="tst-search__incrementor-hidden"></span>
						</div>
					</div>

					<div *ngIf="field?.name == 'flightClass' && showFlightClass"
						 [class]="'tst-form__item tst-form__item--' + field?.type + ' tst-form__item--' + field?.name">
						<label class="tst-form__control-label"
							   *ngIf="field?.type != 'hidden'"
							   for="numAdultsSelect">{{field?.label}}</label>
						<div class="tst-form__control tst-form__control--wrap tst-form__control--select">
							<select #flightClassSelect
									class="tst-form__select tst-form__search--flightClass">
								<option selected>Any</option>
								<option value="Economy"
										selected>Economy</option>
								<option value="Business">Business</option>
								<option value="First">First</option>
								<option value="ExcludeBasicEconomy">Exclude Economy</option>
							</select>
						</div>
					</div>
				</ng-container>
				<form #selectedNgForm="ngForm"
					  [formGroup]="selectedForm?.formGroup"
					  (ngSubmit)="submitFlight($event)"
					  [action]="selectedForm?.endpoint"
					  method="GET"
					  class="tst-form__item tst-form__item--submit">
					<ng-container *ngIf="selectedForm?.name == 'return'">
						<input required
							   type="hidden"
							   [formControlName]="'f'"
							   name="f"
							   [(ngModel)]="this.destination.destination.from.code">
						<input type="hidden"
							   [formControlName]="'ft'">
						<input [required]="selectedForm.name == 'return'"
							   type="hidden"
							   [formControlName]="'d'"
							   [(ngModel)]="this.destination.destination.depart.date">
						<input type="hidden"
							   [formControlName]="'dt'">
						<input required
							   type="hidden"
							   [formControlName]="'t'"
							   name="t"
							   [(ngModel)]="this.destination.destination.to.code">
						<input type="hidden"
							   [formControlName]="'tt'">
						<input [required]="selectedForm.name == 'return'"
							   type="hidden"
							   [formControlName]="'r'"
							   [(ngModel)]="this.destination.destination.return.date">
						<input type="hidden"
							   [formControlName]="'rt'">
						<input required
							   type="hidden"
							   [formControlName]="'a'"
							   [(ngModel)]="this.passengers.adults ">
						<input type="hidden"
							   [formControlName]="'s'"
							   [(ngModel)]="this.passengers.seniors">
						<input type="hidden"
							   [formControlName]="'m'"
							   [(ngModel)]="this.passengers.children">
						<input type="hidden"
							   [formControlName]="'st'">
						<input type="hidden"
							   [formControlName]="'ns'">
						<input type="hidden"
							   [formControlName]="'fd'">
					</ng-container>
					<ng-container *ngIf="selectedForm?.name == 'oneway'">
						<ng-container *ngFor="let field of selectedForm.parameters">
							<input type="hidden"
								   [name]="getFormControlNameForHiddenFields(field)"
								   [formControlName]="getFormControlNameForHiddenFields(field)"
								   required>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="selectedForm?.name == 'multicity'">
						<input type="hidden"
							   [formControlName]="'st'">
						<input type="hidden"
							   [formControlName]="'airSearch'"
							   [(ngModel)]="this.airSearch">
						<input type="hidden"
							   [formControlName]="'packaged'">
					</ng-container>
					<input type="hidden"
						   [formControl]="fromCityStatus" />
					<input type="hidden"
						   [formControl]="toCityStatus" />
					<div class="tst-form__control-submit">
						<button type="submit"
								class="tst-form__button tst-form__button--submit">
							Search Deals
						</button>
					</div>
				</form>
			</div>
			<div *ngIf="isMultiCity"
				 class="tst-from__add-flight">
				<span (click)="addGroup()"
					  class="tst-icon tst-icon--plus-circle u-text-color-blue-royal">
					<fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
				</span>
				&nbsp;
				<a (click)="addGroup()">Add Flight</a>
			</div>
		</form>
		<div class="tst-form__advanced-search-link">
			Don't see what you're looking for? Try our <a href="//albertateachers.tstllc.net/flight">Advanced Search</a>
		</div>
		<span [hidden]="true">
			<ng-container #additionalFlightContainerRef></ng-container>
		</span>
	</div>
</div>

<!--Added flights for multicity-->
<ng-template #addedFlightsTemplateRef
			 let-form="$implicit">
	<div class="tst-form__form-items tst-form__form-items--injected"
		 #injectedFlightsContainer
		 [ngClass]="{isMultiCity:isMultiCity}">
		<input type="hidden"
			   #fIndex
			   [value]="getOrdinalNumber(form.id)" />
		<ng-container *ngFor="let field of form?.formFields; let i=index;">
			<div *ngIf="field?.label == 'fromCity'"
				 class="tst-form__item"
				 #tstAddedFlightFields>
				<label class="tst-form__control-label">{{fIndex.value}} From City</label>
				<div class="tst-form__control tst-form__control--wrap tst-form__control--autocomplete"
					 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && !validateLocationField(field), 'invalidLocation': mq.mobile && !selectedForm.formGroup.get('fromCityStatus').value}">
					<input [formControl]="field?.control"
						   type="text"
						   (input)="autocompleteTyping($event.target?.value, field)"
						   placeholder="Where are you leaving from?"
						   class="tst-form__input-autocomplete"
						   [(ngModel)]="field.airport.label"
						   [ngClass]="{open: field?.open}"
						   required />
					<span class="tst-icon tst-icon--map-marker tst-icon--prefix">
						<fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>
					</span>
					<ul #autoCompleteFromUL
						(mousedown)="autoCompleteChoice($event, field, form.id)"
						[ngClass]="field.elementRefs.class"
						class="tst-form__autocomplete tst-form__autocomplete--from"></ul>
				</div>
			</div>
			<div *ngIf="field?.label == 'toCity'"
				 class="tst-form__item">
				<label class="tst-form__control-label">To City</label>
				<div class="tst-form__control tst-form__control--wrap tst-form__control--autocomplete"
					 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && !validateLocationField(field), 'invalidLocation': mq.mobile && !selectedForm.formGroup.get('fromCityStatus').value}">
					<input [formControl]="field?.control"
						   type="text"
						   (input)="autocompleteTyping($event.target?.value, field)"
						   placeholder="Where do you want to go?"
						   class="tst-form__input-autocomplete"
						   [(ngModel)]="field.airport.label"
						   [ngClass]="{open: field?.open}"
						   required />
					<span class="tst-icon tst-icon--map-marker tst-icon--prefix">
						<fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>
					</span>
					<ul #autoCompleteToUL
						[ngClass]="field.elementRefs.class"
						(mousedown)="autoCompleteChoice($event, field, form.id)"
						class="tst-form__autocomplete tst-form__autocomplete--to"></ul>
				</div>
			</div>
			<div *ngIf="field?.label == 'departDate'"
				 class="tst-form__item tst-form__item--date">
				<div [class]="'tst-form__date-picker' + ' ' + datepickerPlacement.placement"
					 ngbDropdown
					 #dropDownEl="ngbDropdown"
					 autoClose="outside"
					 (openChange)="tstService.datepickerToggle($event,{dp:field,direction:'from',dates:dates, el:dropDownEl, placement:datepickerPlacement})">
					<label class="tst-form__control-label">Depart Date {{form?.name}}</label>
					<div class="tst-form__control tst-form__control--wrap tst-form__control--datepicker tst-form__control--dropDownEl"
						 [ngClass]="{'notValid': (selectedNgForm.submitted || field?.control.touched) && !validateDateField(field?.control, datePicker), 'dateEmpty': !form?.date}">
						<div class="tst-form__date-picker-form">
							<input [value]="form?.date ? dateFormat(form?.date,'ddd, MMM Do') : ''"
								   [placeholder]="minDepartDate | date:'MM/dd/yyyy'"
								   [class]="'tst-form__field tst-form__field--datepicker'"
								   [attr.tabindex]="3"
								   autocomplete="off"
								   autocorrect="off"
								   required>
							<button class="tst-icon__wrap-button"
									type="button"
									ngbDropdownToggle
									id="dateDropDownMulti">

								<span class="tst-icon tst-icon--calendar tst-icon--prefix">
									<svg class="c-icon c-icon--tstIcon">
										<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
									</svg>
								</span>
							</button>
							<span ngbDropdownMenu
								  aria-labelledby="dateDropDownMulti">
								<ng-container
											  *ngTemplateOutlet="singleDateDialog; context: {$implicit: {field: field, index: form?.id, element:dropDownEl, flightDirection:'depart'}}">
								</ng-container>
							</span>
						</div>
						<div class="tst-search__backdrop"></div>
					</div>
				</div>
			</div>
			<div *ngIf="form?.formFields?.length == (i + 1) && (addedFlights.length - 1) == form.id"
				 class="tst-form__remove-flight u-flex u-flex__align-items--end u-rmargin-sm--bottom">
				<div class="tst-form__remove-flight-icon"
					 (click)="removeFlight(form)">
					<fa-icon [icon]="['fal', 'minus-circle']"
							 size="1x"></fa-icon>
				</div>
			</div>
		</ng-container>
	</div>
</ng-template>

<!--Datepicker and Daterange Picker templates using ng-bootstrap-->
<ng-template #dateRangeDialog
			 let-data="$implicit">
	<div class="tst-form__datepicker-heading"
		 *ngIf="mq.mobile">
		<div class="tst-form__datepicker-title">
			{{dates?.msg}}
		</div>
	</div>
	<div class="tst-form__datepicker-content">
		<ngb-datepicker #dp
						[minDate]="dates.dateRngMin"
						[maxDate]="dates.dateRngMax"
						(dateSelect)="dateRangeSelection($event, data)"
						(navigate)="tstService.datePickerNavigation($event, dp, dates)"
						[displayMonths]="1"
						[dayTemplate]="dateRangeDay"
						outsideDays="hidden"
						[startDate]="dates.dateRngFromDate"
						[firstDayOfWeek]="7"
						navigation="false">
		</ngb-datepicker>
	</div>
</ng-template>

<ng-template #singleDateDialog
			 let-data="$implicit">
	<div class="tst-form__datepicker-heading"
		 *ngIf="mq.mobile">
		<div class="tst-form__datepicker-title">
			{{dates?.msg}}
		</div>
	</div>
	<div class="tst-form__datepicker-content">
		<ngb-datepicker #dp
						[minDate]="dates.dateRngMin"
						[maxDate]="dates.dateRngMax"
						(dateSelect)="datePickerSelection($event, data)"
						[displayMonths]="1"
						[dayTemplate]="dateDay"
						outsideDays="hidden"
						[startDate]="dates.dateRngFromDate"
						[firstDayOfWeek]="7"
						navigation="false">
		</ngb-datepicker>
	</div>
</ng-template>

<ng-template #dateRangeDay
			 let-date="date"
			 let-focused="focused"
			 let-month="currentMonth">
	<span class="custom-day"
		  [class.focused]="focused"
		  [class.range]="tstService.dateRangeInRange(date, dates)"
		  [class.start]="tstService.dateRangeIsStartDate(date, dates)"
		  [class.today]="tstService.dateRangeIsToday(date, dates)"
		  [class.end]="tstService.dateRangeIsEndDate(date, dates)"
		  [class.outsideDay]="tstService.dateCalendarOutsideDay(date, dates, month)"
		  [class.faded]="tstService.dateRangeIsHovered(date, dates) || tstService.dateRangeIsInside(date, dates)"
		  [class.notAvailable]="tstService.dateRangeInvalidDate(date, dates)"
		  (mouseenter)="dates.dateRngHoveredDate = date"
		  (mouseleave)="dates.dateRngHoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>

<ng-template #dateDay
			 let-date="date"
			 let-focused="focused"
			 let-month="currentMonth">
	<span class="custom-day"
		  [class.focused]="focused"
		  [class.chosen]="tstService.dateRangeIsStartDate(date, dates)"
		  [class.today]="tstService.dateRangeIsToday(date, dates)"
		  [class.notAvailable]="tstService.dateRangeInvalidDate(date, dates)"
		  [class.outsideDay]="tstService.dateCalendarOutsideDay(date, dates, month)"
		  (mouseenter)="dates.dateRngHoveredDate = date"
		  (mouseleave)="dates.dateRngHoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>