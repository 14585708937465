import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { select, NgRedux } from '@angular-redux-ivy/store';
import { SalesFunnelActionType, IUpdateQuoteIdAction } from 'src/app/store/reducer';
import { Observable, firstValueFrom } from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';
import { BaseComponent } from '../base-component.component';
import { QuoteService } from 'src/app/core/services/quote.service';
import { IAppState } from 'src/app/models/app-state.model';
import { updateCurrentQuoteDetails } from 'src/app/store/current-quote-details.reducer';
import { NgModel } from '@angular/forms';
import { AppInsights } from 'applicationinsights-js';
import { InstrumentationEventNames } from 'src/app/models/data.interfaces';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'save-quote',
  templateUrl: './save-quote.component.html',
})
export class SaveQuoteComponent extends BaseComponent implements OnInit {
  @Input() disabled: boolean;
  modalRef: BsModalRef;
  isModalShown = false;
  xsMax: boolean;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private modalService: BsModalService, public matchMediaService: MatchMediaService) {
    super();
    this.using(
      this.matchMediaService.onChange().subscribe(() => {
        this.xsMax = matchMediaService.matches(MEDIA_TYPE.xs_max);
      })
    ).attach(this);
    this.xsMax = matchMediaService.matches(MEDIA_TYPE.xs_max);
  }

  ngOnInit() {}

  initiateSaveQuote() {
    // The save quote modal has an email input field. When trying to select all
    // with the mouse you can end up closing the modal by accident.
    // tslint:disable-next-line: no-use-before-declare
    this.modalRef = this.modalService.show(SaveQuoteModalContentComponent, {
      ignoreBackdropClick: true,
    });
  }
}

// prettier-ignore
@Unsubscribe()
@Component({
  selector: 'save-email-modal-content',
  templateUrl: './save-quote-modal.component.html',
})
export class SaveQuoteModalContentComponent extends BaseComponent implements AfterViewInit {
  @select(['quoteID']) readonly quoteId$: Observable<string>;
  initialQuoteId = 'unset';

  termsChecked = false;
  isLoading = false;
  submitClickedOnce = false;
  @ViewChild('primaryEmailAddress') primaryEmailAddress: NgModel;

  constructor(
      public bsModalRef: BsModalRef,
      private googleAnalyticsService: GoogleAnalyticsService,
      private quoteService: QuoteService,
      private reduxStore: NgRedux<IAppState>
      ) {
    super();
    this.googleAnalyticsService.setAndSendModalView('save-quote', 'Save Quote');
  }

  onSubmit() {
    this.submitClickedOnce = true;
    // Do not send another email while the first one is processing.
    if (!this.isLoading && this.termsChecked && this.primaryEmailAddress.valid) {
      this.isLoading = true;
      this.saveQuote();
    }
  }

  public saveQuote() {
    this.using(this.quoteService.saveQuote(this.reduxStore.getState(), false).subscribe({next: response => {
      AppInsights.trackEvent(InstrumentationEventNames.QuoteExplicitlySaved);

      this.reduxStore.dispatch({
        type: SalesFunnelActionType.UPDATE_QUOTE_ID,
        payload: response.quoteID,
      } as IUpdateQuoteIdAction);

      // After saving a quote we want to update the saved flag to true.
      // This way any future saves with the same quote option will
      // cause a situation replacement instead of an issue quote.
      const currentQuoteDetails = (this.reduxStore.getState() as IAppState).currentQuoteDetails;
      if (currentQuoteDetails && currentQuoteDetails.quoteOption) {
        this.reduxStore.dispatch(
          updateCurrentQuoteDetails({
            ...currentQuoteDetails,
            quoteOption: {
              ...currentQuoteDetails.quoteOption,
              saved: true,
            },
          })
        );
      }

      // We must trigger the email via toPromise so that it will not be disposed when the modal is closed.
      firstValueFrom(this.quoteService.triggerQuoteEmail(response.quoteID));

      // Now hide the modal
      this.isLoading = false;
      this.bsModalRef.hide();
    },
    error: () => {
      // There was a server error, hide the modal
      this.isLoading = false;
      this.bsModalRef.hide();
    }})).attach(this);
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }
}
