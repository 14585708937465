import { HeapService } from './../../core/services/heap.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { GoogleUserCookieService } from 'src/app/core/services/google-user-cookie.service';
import { NgForm } from '@angular/forms';
import { AppInsights } from 'applicationinsights-js';
import { InstrumentationEventNames } from 'src/app/models/data.interfaces';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sign-in-modal-content',
  templateUrl: './sign-in-modal.component.html',
})
export class SignInModalContentComponent implements OnInit, AfterViewInit {
  private static GENERIC_ERROR_MESSAGE = 'Sign in not successful - your email or password is incorrect.';

  constructor(
    public readonly bsModalRef: BsModalRef,
    public readonly userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private googleUserCookieService: GoogleUserCookieService,
    private heapService: HeapService
  ) {}

  public isBusy = false;

  public email: string;
  public password: string;

  public errorMessage: string;

  ngOnInit() {
    this.googleAnalyticsService.setAndSendModalView('sign-in', 'Sign In');
  }

  hideModal(): void {
    this.bsModalRef.hide();
  }

  onSubmit(f: NgForm) {
    this.errorMessage = '';
    if (f.valid) {
      AppInsights.trackEvent(InstrumentationEventNames.SignInAttempted);

      this.isBusy = true;
      this.userService
        .signIn(this.email, this.password)
        .then((info) => {
          AppInsights.trackEvent(InstrumentationEventNames.SignInSucceeded);
          this.googleUserCookieService.updateFromCurrentUser(this.userService.currentUser);

          if (info.profile && info.profile.authID) {
            this.heapService.identify(info.profile.authID);
            this.heapService.enrichUserSession(info.profile);
          } else {
            this.heapService.resetIdentity();
          }

          this.hideModal();
        })
        .catch(() => {
          AppInsights.trackEvent(InstrumentationEventNames.SignInFailed);

          this.errorMessage = SignInModalContentComponent.GENERIC_ERROR_MESSAGE;
        })
        .finally(() => {
          this.isBusy = false;
        });
    }
  }

  ngAfterViewInit() {
    this.googleAnalyticsService.sendOptimizeEvent();
  }
}
