import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MonerisConfigurationService {
  private readonly baseApiUrl: string;

  private monerisConfig = {} as IMonerisConfigurationResponse;

  constructor(private readonly httpClient: HttpClient) {
    this.baseApiUrl = `${environment.baseApiUrl}/configuration/`;
  }
  public getMonerisConfig(): IMonerisConfigurationResponse {
    return this.monerisConfig;
  }

  public initialize(): Promise<any> {
    return lastValueFrom(
      this.httpClient.get<IMonerisConfigurationResponse>(`${this.baseApiUrl}monerisPaymentInfo`).pipe(
        tap((response: IMonerisConfigurationResponse) => {
          this.monerisConfig = response;
        })
      ),
      undefined
    );
  }
}

export interface IMonerisConfigurationResponse {
  monerisURL: string;
  monerisProfileId: string;
}
