<form #f="ngForm"
      name="f"
      class="o-payment"
      id="payment">
  <div class="row">
    <div class="col-12">
      <div [ngStyle]="{display: showLoading ? 'block': 'none'}"
           class="row o-payment__loading-page">
        <div class="col-12">
          <div class="spinner-border text-primary"
               style="width: 3rem; height: 3rem;"
               role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div [ngStyle]="{display: showSecureCardFrame ? 'block': 'none'}"
           class="row o-payment__content">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <h2>{{ 'payment.title' | translate }}</h2>
              <p>{{ 'payment.prompt' | translate }}</p>
            </div>
          </div>
          <div class="row"
               *ngIf="isMultiTripSelected">
            <div class="col o-payment__auto-renew">
              <div class="form-group">
                <div class="form-check form-check--border">
                  <input class="form-check-input"
                         type="checkbox"
                         value=""
                         id="autoRenewCheck"
                         checked
                         #autoRenewCheck />
                  <label class="form-check-label"
                         for="autoRenewCheck"
                         [innerHTML]="'payment.auto_renew_prompt' | translate | safeHtml">
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="media o-payment__secure-info">
                <i class="mr-2">
                  <svg-icon name="padlock-yellow" />
                </i>
                <div class="media-body">
                  <h5 class="mt-0 mb-0"><strong>{{ 'payment.secure_title' | translate }}</strong></h5>
                  <small>{{ 'payment.secure_prompt' | translate }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-auto o-payment__moneris-frame">
              <iframe #monerisFrame
                      [src]="monerisURL | safeResourceUrl"
                      frameborder="0"></iframe>
            </div>
            <div class="col pl-0 o-payment__images">
              <figure class="o-payment__icon o-payment__icon--card-options">
                <img src="assets/images/credit-card-options.png"
                     alt="Credit card options" />
              </figure>
              <figure class="o-payment__icon o-payment__icon--security-number">
                <svg-icon name="security_number" />
              </figure>
            </div>
          </div>
          <div class="row">
            <div class="col o-payment__terms-conditions">
              <div class="form-group my-4">
                <div class="form-check py-2">
                  <input class="form-check-input"
                         type="checkbox"
                         [(ngModel)]="acceptedTermsAndConditions"
                         name="termsAndConditions"
                         value=""
                         id="invalidCheck" />
                  <label class="form-check-label"
                         for="invalidCheck"
                         [innerHTML]="'payment.terms_and_conditions' | translate: {eligibilityLinkId:eligibilityLinkId}| safeHtml">
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div *ngFor="let error of monerisErrors"
                   class="alert alert-danger"
                   role="alert"
                   [innerHTML]="error | safeHtml">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 o-payment__submit-button">
              <div class="btn-group d-block d-md-inline-block"
                   role="group">
                <button class="btn btn-secondary btn--flex btn-lg d-block d-sm-inline-block col-sm-6 col-md-auto px-5"
                        id="payment-submit"
                        [ngClass]="{'btn--loading': !enableSubmit}"
                        type="button"
                        [disabled]="!enableSubmit"
                        (click)="doMonerisSubmit($event)">
                  <span class="px-1">{{ 'payment.submit' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="alert alert-dark mt-5 py-2 border-0 rounded-0">
                <strong>{{ 'payment.important_information.title' | translate }}</strong>
              </div>
              <ul
                  [innerHTML]="(productGroupFlow === productGroup.RentalVehicleDamageFlow ? 'payment.important_information.list_items_rvd' : 'payment.important_information.list_items') | translate | safeHtml">
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>