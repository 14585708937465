import { AfterViewInit, Component, Inject, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Unsubscribe } from 'amaweb-tsutils';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { TenantEnum } from 'src/app/models/tenant.enum';
import { MatTabGroup, MatTabLabelWrapper, ScrollDirection } from '@angular/material/tabs';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Unsubscribe()
@Component({
  selector: 'exclusive-deals',
  templateUrl: './exclusive-deals.component.html',
})
export class ExclusiveDealsComponent implements AfterViewInit {
  @ViewChild('consultingTabGroup') consultingTabGroup: MatTabGroup;

  deals = [
    {
      title: 'Flight Deals',
      iconSrc: 'https://amatravelinternal.blob.core.windows.net/icons/iconology/circle-plane_lg.png',
      anchor: 'Flight-Deals',
      sectionTitle: 'Flight Deals',
    },
    {
      title: 'Package and Tour Deals',
      iconSrc: 'https://amatravelinternal.blob.core.windows.net/icons/iconology/circle-palm-tree-beach-vacation_lg.png',
      anchor: 'Package-Deals',
      sectionTitle: 'Package & Tour Deals',
    },
    {
      title: 'Professional Consulting',
      iconSrc: 'https://amatravelinternal.blob.core.windows.net/icons/iconology/circle-agent_lg.png',
      anchor: 'Professional-Consulting',
      sectionTitle: 'Professional Consulting',
    },
  ];

  constructor(
    private readonly clubConfigurationService: ClubConfigurationService,
    private readonly router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute
  ) {
    if (this.clubConfigurationService.appConfig.settings.tenant !== TenantEnum.ATA) {
      this.router.navigate(['/coverage'], { replaceUrl: true });
    }
  }
  ngAfterViewInit() {
    const header = this.consultingTabGroup._tabHeader;

    header._scrollToLabel = (labelIndex: number) => {
      this.addMediaQueryStyles();
      if (header.disablePagination) {
        return;
      }

      const selectedLabel = header._items ? header._items.toArray()[labelIndex] : null;

      if (!selectedLabel) {
        return;
      }

      const viewLength = header._tabListContainer.nativeElement.offsetWidth;
      const { offsetLeft, offsetWidth } = selectedLabel.elementRef.nativeElement;

      let labelBeforePos: number, labelAfterPos: number;

      // Add an extra scroll (1/3 of the tab width) if it's not the last tab
      const extraScroll = labelIndex < header._items.length - 1 && labelIndex != 0 ? offsetWidth / 3 : 0;

      if (header._getLayoutDirection() === 'ltr') {
        labelBeforePos = offsetLeft;
        labelAfterPos = labelBeforePos + offsetWidth + extraScroll; // Add extra scroll to the right
      } else {
        labelAfterPos = header._tabListInner.nativeElement.offsetWidth - offsetLeft;
        labelBeforePos = labelAfterPos - offsetWidth - extraScroll; // Add extra scroll to the left
      }

      const beforeVisiblePos = header.scrollDistance;
      const afterVisiblePos = header.scrollDistance + viewLength;

      // Scroll the header to make the selected tab label fully visible, with extra scroll if needed
      if (labelBeforePos < beforeVisiblePos) {
        header.scrollDistance -= beforeVisiblePos - labelBeforePos + extraScroll;
      } else if (labelAfterPos > afterVisiblePos) {
        header.scrollDistance += Math.min(labelAfterPos - afterVisiblePos, labelBeforePos - beforeVisiblePos);
      }

      header.updatePagination();
    };
    this.addMediaQueryStyles();
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      setTimeout(() => {
        this.scrollToAnchor(null, fragment);
      }, 100);
    }
  }
  addMediaQueryStyles() {
    const style = this.renderer.createElement('style');
    style.type = 'text/css';
    const css = `
    @media (min-width: 1400px) {
        .container-xl, .container-lg, .container-md, .container-sm, .container, .o-site-content {
            max-width: 80rem;
        }
    }
    `;
    const text = this.renderer.createText(css);
    this.renderer.appendChild(style, text);
    this.renderer.appendChild(this.document.head, style);
  }
  scrollToAnchor(event: Event, anchor: string) {
    event?.preventDefault();
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
