import { ICurrentQuoteDetails } from '../models/data.interfaces';
import { SalesFunnelActionType } from './reducer';
import { GetInitialCurrentQuoteDetails } from './initial-states';

export interface ICurrentQuoteDetailsInfoAction {
  type: SalesFunnelActionType;
  payload: ICurrentQuoteDetails;
}

export function currentQuoteDetailsReducer(
  state: ICurrentQuoteDetails = GetInitialCurrentQuoteDetails(),
  selectedQuoteOptionAction: ICurrentQuoteDetailsInfoAction
): ICurrentQuoteDetails {
  switch (selectedQuoteOptionAction.type) {
    case SalesFunnelActionType.UPDATE_SELECTED_QUOTE_OPTION:
      return { ...state, ...selectedQuoteOptionAction.payload };
    case SalesFunnelActionType.CLEAR_QUOTE_OPTIONS:
    case SalesFunnelActionType.SET_QUOTE_OPTIONS:
      return { ...state, ...{ planDays: null, topUpDays: null, quoteOption: null } };
    case SalesFunnelActionType.UPDATE_SELECTED_QUOTE_DEDUCTIBLE:
      return { ...state, deductibleAmount: selectedQuoteOptionAction.payload.deductibleAmount };
    default:
      return state;
  }
}

export function updateCurrentQuoteDetails(newQuoteOption: any): ICurrentQuoteDetailsInfoAction {
  return {
    type: SalesFunnelActionType.UPDATE_SELECTED_QUOTE_OPTION,
    payload: newQuoteOption,
  };
}

export function updateCurrentQuoteDeductible(deductibleAmount?: number): ICurrentQuoteDetailsInfoAction {
  return {
    type: SalesFunnelActionType.UPDATE_SELECTED_QUOTE_DEDUCTIBLE,
    payload: {
      deductibleAmount,
    },
  };
}
