import { Component, OnInit } from '@angular/core';
import { select, dispatch, NgRedux } from '@angular-redux-ivy/store';
import { ITravellerInfo, InsuranceProduct, ITripDetails, IAppState } from 'src/app/models/data.interfaces';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { updateDepartureDateAndReturnDate, updatePolicyStartDateAndPlanDays } from 'src/app/store/trip-details-common-reductions';
import { addDays } from 'date-fns';
import { BaseComponent } from '../base-component.component';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { NgForm } from '@angular/forms';
import { QuoteService } from 'src/app/core/services/quote.service';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'renew-policy-details',
  templateUrl: './renew-policy-details.component.html',
})
export class RenewPolicyDetailsComponent extends BaseComponent implements OnInit {
  @select(['travellerInfo']) readonly travellerInfo$: Observable<ITravellerInfo>;
  @select(['tripDetails']) readonly tripDetails$: Observable<ITripDetails>;

  public startDateIsOpen = false;
  public startDate: Date;
  public isLoading: boolean;
  public DateHelpers = DateHelpers;
  public premiumPlanUnavailable: boolean;

  constructor(
    private readonly router: Router,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly quoteService: QuoteService
  ) {
    super();

    // Reset state to whatever last policy had
    this.reduxStore.dispatch({ type: SalesFunnelActionType.REPURCHASE_RESET_CHANGES });
  }

  ngOnInit() {
    this.clearPolicyModifiedFlag();
  }

  submit(form: NgForm) {
    // The only way the form can be invalid is if the policy start date
    // is below the minimum date. This can be simulated by changing the
    // definition of today in the DateHelpers function.
    if (!form.form.valid || this.isLoading) {
      return;
    }

    this.updateDates();

    // Fetch quote options then navigate away
    this.isLoading = true;
    this.using(
      this.quoteService.renewalQuoteOptions().subscribe((quoteOptions) => {
        this.premiumPlanUnavailable = false;
        this.isLoading = false;
        if (quoteOptions && quoteOptions.length > 0) {
          this.insuranceProductSelected(this.reduxStore.get((x) => x.tripDetails.productSelected));
          this.router.navigate(['renew', 'review-quote']);
        } else {
          // This scenario can occur when renewing an annual premium plan after 60 y/o.
          const prevPolicyProductSelected = this.reduxStore.get((x) => x.tripDetails.prevPolicyProductSelected);
          if (prevPolicyProductSelected !== InsuranceProduct.MultiTripMedicalPlan) {
            this.premiumPlanUnavailable = true;
          } else {
            this.editPolicy();
          }
        }
      })
    ).attach(this);
  }

  editPolicy() {
    this.setPolicyAsModified();
    this.updateDates();
    this.router.navigate(['renew', 'trip-details']);
  }

  updateDates() {
    const planDays = this.reduxStore.get((x) => x.tripDetails.durationOfFirstTrip);
    this.syncPolicyStartDate(this.startDate, addDays(this.startDate, planDays - 1));
    this.syncDepartureDateReturnDate(this.startDate, planDays);
  }

  @dispatch() setPolicyAsModified = () => ({ type: SalesFunnelActionType.REPURCHASE_SET_MODIFIED_STATE });
  @dispatch() clearPolicyModifiedFlag = () => ({ type: SalesFunnelActionType.REPURCHASE_CLEAR_MODIFIED_STATE });

  @dispatch() insuranceProductSelected = (productSelected: InsuranceProduct | string) => ({
    type: SalesFunnelActionType.UPDATE_POLICY_PRODUCT,
    tripDetailsState: { productSelected },
  });

  @dispatch() syncPolicyStartDate(departureDate: Date, returnDate: Date) {
    return updatePolicyStartDateAndPlanDays(departureDate, returnDate);
  }

  @dispatch() syncDepartureDateReturnDate(policyStartDate: Date, durationOfFirstTrip: number) {
    return updateDepartureDateAndReturnDate(policyStartDate, durationOfFirstTrip);
  }
}
