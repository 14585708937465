import {
  forwardRef,
  Host,
  SkipSelf,
  Self,
  Inject,
  TemplateRef,
  ViewContainerRef,
  Directive,
  Optional,
  OnInit,
  Input,
  OnDestroy,
  EmbeddedViewRef,
} from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  ValidatorFn,
  Validators,
  AsyncValidatorFn,
  NgModelGroup,
  AbstractControl,
  NgModel,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { NG_ASYNC_VALIDATORS, NG_VALIDATORS } from '@angular/forms';
import { FormStore, ConnectBase } from '@angular-redux-ivy/form';
import { get as deepGet } from 'lodash-es';
import { Unsubscribe } from 'redux';
import { ConnectDirective } from 'src/app/shared/directives/connect';

export class ConnectArrayTemplate {
  constructor(public $implicit: any, public index: number, public item: any) {}
}

function controlPath(name: string, parent: ControlContainer): string[] {
  return [...(parent.path || []), name];
}

// Based on:
// https://raw.githubusercontent.com/angular-redux/form/master/source/connect-array/connect-array.ts
@Directive({
  selector: '[connectArrayFix]',
  providers: [
    {
      provide: ControlContainer,
      useExisting: forwardRef(() => ConnectArrayFixDirective),
    },
  ],
  /**
   * This export is used by ConnectArrayFixModelDirective.
   */
  exportAs: 'connectArrayFix',
})
export class ConnectArrayFixDirective extends ControlContainer implements OnInit, OnDestroy {
  /**
   * We need access to the parent form so we can properly clean up
   * lingering FormGroups.
   */
  private stateSubscription: Unsubscribe;
  private array = new FormArray([]);
  private key?: string;

  constructor(
    @Optional() @Host() @SkipSelf() private parent: ControlContainer,
    @Optional() @Self() @Inject(NG_VALIDATORS) private rawValidators: any[],
    @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) private rawAsyncValidators: any[],
    /**
     * We need the ConnectBase of the parent to compute the full path.
     */
    @Optional() @Host() @SkipSelf() private parentConnect: ConnectDirective,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private store: FormStore
  ) {
    super();
    this.stateSubscription = this.store.subscribe((state) => this.resetState(state));
    this.registerInternals(this.array);
  }

  @Input()
  set connectArrayOf(collection: any) {
    this.key = collection;
    this.resetState(this.store.getState());
  }

  ngOnInit() {
    /**
     * The original implementation uses addControl which fires
     * value propagation before the store can initialize. The registerControl method
     * works better for our case. This is also why we do not call super.ngOnInit().
     */
    this.formDirective.form.registerControl(this.keyName, this.control);
    /**
     * When removing embedded views the FormArray.removeControl is called by OnDestroy.
     * This causes the FormGroup to delete any controls which are currently loaded.
     * Since this is done in a generic manner, it doesn't take into account that FormArray
     * does not implement the removeControl method. That is why we add a manual implementation
     * which calls the removeAt function.
     */
    Object.defineProperties(this.control, {
      removeControl: {
        value: function (index: number) {
          this.removeAt(index);
        },
      },
      /**
       * There is also a known error with _checkAllValuesPresent. There is a delayed value update
       * when reseting the state and propagating to the store. If you remove elements
       * quick enough you can get an error of type
       * "Error: Must supply a value for form control at index: 3.".
       */
      _checkAllValuesPresent: {
        value: function () {},
      },
    });
  }

  get keyName(): string {
    return this.key || '';
  }

  get control(): FormArray {
    return this.array;
  }

  get formDirective(): FormGroupDirective {
    return <FormGroupDirective>this.parent.formDirective;
  }

  get path(): Array<string> {
    return this.key ? controlPath(this.key, this.parent) : [];
  }

  get validator(): ValidatorFn | null {
    return Validators.compose(this.rawValidators);
  }

  get asyncValidator(): AsyncValidatorFn | null {
    return Validators.composeAsync(this.rawAsyncValidators);
  }

  updateValueAndValidity() {}

  ngOnDestroy() {
    this.viewContainerRef.clear();
    if (this.key) {
      this.formDirective.form.removeControl(this.key);
    }
    this.stateSubscription();
  }

  private resetState(state: any) {
    if (this.key == null || this.key.length === 0) {
      return; // no state to retreive if no key is set
    }
    const iterable = deepGet(state, this.parentConnect.path.concat(this.path));

    let index = 0;

    for (const value of iterable) {
      let viewRef = this.viewContainerRef.length > index ? <EmbeddedViewRef<ConnectArrayTemplate>>this.viewContainerRef.get(index) : null;

      if (viewRef == null) {
        viewRef = this.viewContainerRef.createEmbeddedView<ConnectArrayTemplate>(
          this.templateRef,
          new ConnectArrayTemplate(index, index, value),
          index
        );

        this.patchDescendantControls(viewRef);

        this.array.insert(index, this.transform(this.array, viewRef.context.item));
      } else {
        Object.assign(viewRef.context, new ConnectArrayTemplate(index, index, value));
      }

      ++index;
    }

    // Patch all controls before removing the view container ref
    this.patchControlPaths();
    while (this.viewContainerRef.length > index) {
      this.viewContainerRef.remove(this.viewContainerRef.length - 1);
    }

    // Also remove the remaining controls.
    while (this.control.length > index) {
      this.control.removeAt(this.control.length - 1);
    }
  }

  private registerInternals(array: any) {
    array.registerControl = () => {};
    array.registerOnChange = () => {};

    Object.defineProperties(this, {
      _rawValidators: {
        value: this.rawValidators || [],
        configurable: true,
      },
      _rawAsyncValidators: {
        value: this.rawAsyncValidators || [],
        configurable: true,
      },
    });
  }

  private patchDescendantControls(viewRef: any) {
    const ngModels = Object.keys(viewRef._lView)
      .map((k) => viewRef._lView[k])
      .filter((c) => c instanceof NgModel);

    ngModels.forEach((c) => {
      Object.defineProperties(c, {
        _checkParentType: {
          value: () => {},
          configurable: true,
        },
      });
    });

    const groups = Object.keys(viewRef._lView)
      .map((k) => viewRef._lView[k])
      .filter((c) => c instanceof NgModelGroup);

    groups.forEach((c) => {
      Object.defineProperties(c, {
        _parent: {
          value: this,
          configurable: true,
        },
        _checkParentType: {
          value: () => {},
          configurable: true,
        },
      });
    });
  }

  private transform(parent: FormGroup | FormArray, reference: any): AbstractControl {
    const emptyControl = () => {
      const control = new FormControl(null);
      control.setParent(parent);
      return control;
    };
    if (reference == null) {
      return emptyControl();
    }

    if (typeof reference.toJS === 'function') {
      reference = reference.toJS();
    }

    switch (typeof reference) {
      case 'string':
      case 'number':
      case 'boolean':
        return emptyControl();
    }

    const iterate = (iterable: any): FormArray => {
      const array = new FormArray([]);

      this.registerInternals(array);

      for (let i = array.length; i > 0; i--) {
        array.removeAt(i);
      }

      for (const value of iterable) {
        const transformed = this.transform(array, value);
        if (transformed) {
          array.push(transformed);
        }
      }

      return array;
    };

    const associate = (value: any): FormGroup => {
      const group = new FormGroup({});
      group.setParent(parent);

      for (const key of Object.keys(value)) {
        const transformed = this.transform(group, value[key]);
        if (transformed) {
          group.addControl(key, transformed);
        }
      }

      return group;
    };

    if (Array.isArray(reference)) {
      return iterate(<Array<any>>reference);
    } else if (reference instanceof Set) {
      return iterate(<Set<any>>reference);
    } else if (reference instanceof Map) {
      return associate(<Map<string, any>>reference);
    } else if (reference instanceof Object) {
      return associate(reference);
    } else {
      throw new Error(`Cannot convert object of type ${typeof reference} / ${reference.toString()} to form element`);
    }
  }

  /**
   * There is additional properties that need to be patched.
   * The in-built patchDescendantControls method doesn't properly patch
   * all controls and models in the form.
   */
  protected patchControlPaths() {
    // We need access to the ConnectArrayFix instance since we are using function(){}
    const _this = this;

    /**
     * Since this method is called after reset state,
     * the number of embedded views always matches the number of controls.
     */
    for (let index = 0; index < this.viewContainerRef.length; index++) {
      /**
       * The FormGroup within our FormArray also needs to have the proper path and name defined.
       */
      const formGroup: any = _this.control.at(index);
      Object.defineProperties(formGroup, {
        path: {
          get: function () {
            return _this.path.concat([index as any]);
          },
          configurable: true,
        },
        name: {
          get: function () {
            return index;
          },
          configurable: true,
        },
        // We also want to avoid throwing error for missing form fields
        _checkAllValuesPresent: {
          value: function () {},
          configurable: true,
        },
      });

      /**
       * All FormControls under the FormGroup also need their name
       * and path defined.
       */
      Object.keys(formGroup.controls).forEach((key) => {
        Object.defineProperties(formGroup.controls[key], {
          path: {
            get: function () {
              return _this.path.concat([index as any, this.name]);
            },
            configurable: true,
          },
          name: {
            get: function () {
              return key;
            },
            configurable: true,
          },
        });
      });
    }
  }
}
