<ng-container *ngIf="isBanner;else noBanner">
	<ng-container *ngIf="!mq.mobile; else mobileBanner">
		<div class="tst__banner"
			 [style.background-image]="bannerBackgroundImage">
			<div class="container">
				<ng-container *ngIf="showValueProp">
					<div class="tst__banner-promo-top tst__banner-promo-top--default"
						 [innerHTML]="sanitizer.sanitize(activeForm?.valuePropHTML)"></div>
				</ng-container>

				<ng-container *ngTemplateOutlet="tstSearch" />

				<ng-container *ngIf="showPromo">
					<div class="tst__banner-promo-bottom tst__banner-promo-bottom--default"
						 [innerHTML]="sanitizer.sanitize(activeForm?.promoHTML)"></div>
				</ng-container>
				<ng-container *ngIf="showCaption">
					<div class="tst__banner-caption"
						 [innerHTML]="sanitizer.sanitize(captionContent)"></div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #noBanner>
	<ng-container *ngIf="!mq.mobile; else tstSearch">
		<ng-container *ngTemplateOutlet="tstSearch"></ng-container>
	</ng-container>
</ng-template>

<ng-template #mobileBanner>
	<div [class]="'tst__banner locationState--'+ locationState + ' clickedState--'+ tabClickedState"
		 [style.background-image]="bannerBackgroundImage"
		 [ngClass]="{isHome : router.url === '/coverage', isMainTab : currentTab == defaultTab, autoCompleteBypassed: locationState=='bypassed', hideTabs: tabClickedState && locationState == 'live'}">

		<!-- Best Price Guarantee -->
		<ng-container *ngIf="!tabClickedState && (currentTab == 'hotel' || currentTab == 'prepackaged')">
			<div class="p-3">
				<section class="d-flex pt-2">
					<div class="col-6 p-0 mr-2">
						<div class="d-flex">
							<div class="col-3 p-0 mr-1"><img class="mw-100"
									 src="https://amatravelinternal.blob.core.windows.net/tstbanners/tst-banners/best-price-blue.png"></div>
							<div class="col-9 p-0 d-flex flex-column justify-content-center">
								<div class="c-bpg__heading">
									<span class="u-ama-color--white">AMA BEST PRICE</span>
									<div><strong class="c-bpg__subheading u-ama-color--white">GUARANTEE</strong></div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-6 c-bpg__details d-flex align-items-center">
						<strong class="u-ama-color--white"
								*ngIf="currentTab == 'prepackaged';else hotelBpg">Guaranteed lowest prices on
							all vacation bookings including Flight + Hotel Packages</strong>
					</div>
				</section>
			</div>
			<ng-template #hotelBpg><strong class="u-ama-color--white">On Vacation Packages & North American Hotels</strong></ng-template>
		</ng-container>

		<div class="tst__destination">
			<div class="tst__back"
				 (click)="backBtnClicked($event)">
				<span class="tst__back-arrow">
					<svg class="c-icon c-icon--chevron c-icon--medium c-icon--left c-icon--white">
						<use xlink:href="/Content/SVG/sprite-icons.svg#chevron"></use>
					</svg>
				</span>
				<span class="tst__back-icon">
					<ng-container *ngTemplateOutlet="iconTemplates; context:{type:clickedTab}"></ng-container>
				</span>
				<span class="tst__back-label">{{currentTSTLabel}}</span>
			</div>
			<div class="tst__ac-form">
				<tst-autocomplete (selectedLocation)="selectedLocation($event)"
								  [locationState]="locationState"
								  [tabClickedState]="tabClickedState"
								  [destinationFieldWasCleared]="destinationFieldWasCleared"
								  [updatedLocation]="updatedLocation">
				</tst-autocomplete>
			</div>
		</div>
	</div>

	<ng-container *ngTemplateOutlet="tstSearch"></ng-container>

	<div class="tst__banner-promo-bottom u-rpadding-xs--x"
		 *ngIf="showPromo">
		<!--promo-->
		<div class="tst__banner-promo-bottom tst__banner-promo-bottom--default"
			 [innerHTML]="sanitizer.sanitize(promoContent || activeForm?.promoHTML)">
		</div>
	</div>
</ng-template>

<ng-template #tstSearch>
	<tst-search (currentTab)="setCurrentTab($event)"
				(clickedTab)="tabClicked($event)"
				(tabClickedStateEmit)="clickedTabState($event)"
				(destinationFieldCleared)="destinationFieldCleared($event)"
				(updatedLocation)="locationUpdated($event)"
				[isBanner]="isBanner"
				[currentUrl]="currentUrl"
				[theme]="theme"
				[selectedLocation]="locationSelected"
				[valueTyped]="valueTyped"
				[locationState]="locationState"
				[tabClickedStateUpdate]="tabClickedState"
				[bannerOptions]="bannerOptions"
				[defaultTab]="defaultTab"
				[defaultDepartureAirport]="defaultDepartureAirport"
				[defaultDestinationAirport]="defaultDestinationAirport"
				[defaultDestinationCity]="defaultDestinationCity"
				[class]="'tst-search ' + currentTab"
				[enableTmi]="false">
	</tst-search>
</ng-template>

<!--custom tst icons templates -->
<ng-template #iconTemplates
			 let-type="type">
	<ng-container *ngIf="type == 'prepackaged'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--vacations"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'vacation'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--dynamic"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'hotel'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--hotels"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'flight'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--flights"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'car'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cars"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'cruise'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cruise"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'activity'">
		<svg class="'c-icon c-icon--tstIcon c-icon--'+ type">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--activities"></use>
		</svg>
	</ng-container>
</ng-template>
<!--/ end tst icon templates -->