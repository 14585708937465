import { Component, Input } from '@angular/core';
import { NgRedux, select, dispatch, select$ } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { map } from 'rxjs/operators';
import { IAppState } from 'src/app/models/app-state.model';
import { TripDetailsActionCreatorService } from 'src/app/store/trip-details-action-creator.service';

@Component({
  selector: 'number-of-travellers',
  templateUrl: './number-of-travellers.component.html',
})
export class NumberOfTravellersComponent {
  @Input() analyticsPrefix: string;

  @select(['tripDetails', 'numberOfTravellers']) readonly numTrav$: Observable<number>;
  @select$(['tripDetails', 'numberOfTravellers'], (numTrav$) =>
    numTrav$.pipe(
      map((numTrav) => {
        const type = numTrav > 1 ? 'plural' : 'singular';
        return `coverage.number_of_travellers.label_${type}_format`;
      })
    )
  )
  readonly labelFormat$: Observable<string>;
  readonly maxNumberOfTravellers: number;

  constructor(
    private readonly clubConfigurationService: ClubConfigurationService,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly tripDetailsActionCreatorService: TripDetailsActionCreatorService
  ) {
    this.maxNumberOfTravellers = this.clubConfigurationService.appConfig.settings.maximum_number_of_travellers;
  }

  @dispatch() incrementNumTrav(increment: number) {
    const state = this.reduxStore.getState();
    const newValue = (state.tripDetails.numberOfTravellers || 1) + increment;
    return this.tripDetailsActionCreatorService.updateNumberOfTravellers(newValue);
  }
}
