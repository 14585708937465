import {
  Component,
  forwardRef,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterContentInit,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, UntypedFormControl, NG_VALIDATORS, FormControl } from '@angular/forms';
import { isBefore, isAfter, isEqual, subYears, startOfDay } from 'date-fns';
import { environment } from 'src/environments/environment';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';

/**
 * TODO:
 *
 * There is a known issue with the datepicker which causes a memory leak.
 * There is no way of fixing this through monkey patching since the memory leak
 * occurs within the constructor of the BsDatePickerInputDirective.
 *
 * https://github.com/valor-software/ngx-bootstrap/issues/4261
 */

@Component({
  selector: 'date-input',
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor, Validator, OnChanges, AfterViewInit {
  @ViewChild('bsDatepickerInstance') public bsDatepickerInstance: BsDatepickerDirective;
  public value: Date;
  public isDisabled: boolean;
  public propagateChange: (value: string) => void;
  public propagateValidatorChange: () => void;
  public propagateOnTouch: () => void;

  @Input() public id: string;
  @Input() public placeholder: string;
  @Input() public required: boolean;
  @Input() public inputClass: any;
  @Input() public minDate: any;
  @Input() public maxDate: any;
  @Input() public isOpen: boolean;
  @Input() public tabindex: any;
  @Input() public initialAge: number;
  @Input() public autocomplete = 'off';
  @Input() public validationEnabled = true;

  /**
   * Custom validation for other scenarios.
   */
  @Input() public customValidation: (value: Date, data: any) => { key: boolean };
  /**
   * Any additional data that is required for custom validation.
   */
  @Input() public customValidationData: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onHidden = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onShown = new EventEmitter<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  /**
   * This happens when the date picker value changes via selection.
   */
  public bsValueChange(value: Date) {
    this.writeValue(value);
  }
  /**
   * This happens when the store value is updated.
   */
  public writeValue(value: Date): void {
    try {
      let isoString: string = null;
      const newValue = DateHelpers.parseDate(value);
      if (newValue) {
        // The value saved into redux should be the UTC date in ISO format.
        const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()));
        isoString = utcDate.toISOString();
      }

      // Do not write the value unless it's new
      if (!isEqual(newValue, this.value)) {
        this.value = newValue;
        if (this.propagateChange) {
          /*
         When using our custom ConnectArrayFix we run into a situation where the
         connect-array base class determines that the redux date value is an object.
         This causes the date of birth input field to be created as a FormGroup
         instead of a FormControl. To fix workaround this unintended behavior
         we will always write the ISOString() to the redux store. This is the default
         representation by redux so there isn't any data loss.
         */
          this.propagateChange(isoString);
        }
        // When reading from the store the change detection isn't kicking in.
        this.changeDetectorRef.markForCheck();
      }
    } catch (error) {
      // We are force updating the value so we need to activate change detection
      this.value = null;
      this.changeDetectorRef.markForCheck();
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.propagateValidatorChange = fn;
  }

  // When the input changes we need to redo our validation
  public ngOnChanges(changes: SimpleChanges): void {
    if ('required' in changes || 'maxDate' in changes || 'minDate' in changes) {
      if (this.propagateValidatorChange) {
        this.propagateValidatorChange();
      }
    }
  }

  /**
   * We still need to handle disabled state
   */
  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * We will be firing an on touch even after a value is writing.
   */
  public registerOnTouched(fn: () => void): void {
    this.propagateOnTouch = fn;
  }

  public validate(c: FormControl) {
    if (this.validationEnabled === false) {
      return null;
    }

    let errors = {};
    const v = DateHelpers.parseDate(this.value);
    const min = DateHelpers.parseDate(this.minDate);
    const max = DateHelpers.parseDate(this.maxDate);

    // Only one error will every be shown at a time with the following precedence.
    if (this.required && !v) {
      errors = { ...errors, required: true };
    } else if (this.minDate && v && isBefore(startOfDay(v), startOfDay(min))) {
      errors = { ...errors, minDate: true };
    } else if (this.maxDate && v && isAfter(startOfDay(v), startOfDay(max))) {
      errors = { ...errors, maxDate: true };
    }

    if (this.customValidation) {
      const otherErrors = this.customValidation(v, this.customValidationData);
      if (otherErrors) {
        errors = { ...errors, ...otherErrors };
      }
    }
    return Object.keys(errors).length > 0 ? errors : null;
  }

  public bsOnHidden(event: any) {
    // We only want to propagate an on touch event if the user actually interacted with the picker.
    if (this.propagateOnTouch) {
      this.propagateOnTouch();
    }
    this.onHidden.emit(event);
  }

  ngAfterViewInit(): void {
    const dateInputComponent = this;
    // This workaround allows us to set the default date range when a datepicker opens.
    // If you look at the bs-datepicker.state.ts file:
    //
    // eslint-disable-next-line max-len
    // https://github.com/valor-software/ngx-bootstrap/blob/5633d2d7589c20f77780836ace6382d32c976229/src/datepicker/reducer/bs-datepicker.state.ts
    //
    // You will find an _initialView variable which sets the date to new Date().
    const oldFn = this.bsDatepickerInstance.show.bind(this.bsDatepickerInstance);
    this.bsDatepickerInstance.show = function () {
      oldFn.apply(this, []);
      setTimeout(() => {
        try {
          if (dateInputComponent.initialAge && !(this as any)._bsValue) {
            const i = (this as any)._datepickerRef.instance;
            i._store.source._value.view.date = subYears(new Date(), dateInputComponent.initialAge);
            i.setViewMode('year');
          }
        } catch (error) {
          if (!environment.production) {
            // eslint-disable-next-line no-console
            console.warn('Unable to set the initial age value for the BsDatepickerDirective');
          }
        }
      });
    };
    this.changeDetectorRef.detectChanges();
  }
}
