import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';
import { Component, forwardRef, Input, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { MembershipService } from 'src/app/core/services/membership.service';
import { Subscription, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';
import { GoogleUserCookieService } from 'src/app/core/services/google-user-cookie.service';
import { IGaUserCookieMemberStatus } from 'src/app/models/data.interfaces';
import { AttachedSubscription, Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Component({
  selector: 'membership-number',
  templateUrl: './membership-number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MembershipNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MembershipNumberComponent),
      multi: true,
    },
  ],
  exportAs: 'membershipNumberComponent',
})
export class MembershipNumberComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input() name: string;
  @Input() required: boolean;
  @Input() showValidation: boolean;
  @Input() isLoggedIn: boolean;
  @Input() postalCode: string;
  @Input() isDisabled = false;

  /**
   * The value without the prefix.
   */
  public value: string;

  /**
   * The membership number prefix defined in the config.
   */
  private membershipNumberPrefix: string;

  /**
   * The length of a valid membership number defined in the config.
   */
  private membershipNumberLength: number;

  /**
   * The computed validation errors based on the state of the component.
   */
  public errors: ValidationErrors | null;

  /**
   * Has the membership number ever been validated server side?
   * If so we should should treat the input as touched and
   * always show any form errors going forward.
   */
  public hasValidated: boolean;

  /**
   * Are we currently validating the membership number
   * against the server?
   */
  public isValidating: boolean;

  /**
   * The server response for the membership number.
   */
  public isActiveMembership: boolean;

  /**
   * Has a server error occurred when calling the validation API?
   */
  public serverError: boolean;

  /**
   * The subscription used for calling the API. We use a timer
   * to implement debounce.
   */
  private validateSubscription: AttachedSubscription;

  private propagateChange: (value: string) => void = () => {};
  private propagateValidatorChange: () => void = () => {};
  private propagateOnTouch: () => void = () => {};

  constructor(
    private readonly clubConfigService: ClubConfigurationService,
    private readonly membershipService: MembershipService,
    private readonly googleUserCookieService: GoogleUserCookieService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.membershipNumberPrefix = this.clubConfigService.appConfig.settings.membership_number_prefix;
    this.membershipNumberLength = this.clubConfigService.appConfig.settings.membership_number_length;
  }

  writeValue(storeValue: string): void {
    // Remove the prefix from the member number
    if (storeValue && this.membershipNumberPrefix && storeValue.indexOf(this.membershipNumberPrefix) === 0) {
      this.value = storeValue.substring(this.membershipNumberPrefix.length);
    } else {
      this.value = storeValue;
    }
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.propagateValidatorChange = fn;
  }

  /**
   * When the input changes we need to redo the validation
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if ('required' in changes) {
      this.propagateValidatorChange();
    }
    if (changes.postalCode && !this.isDisabled) {
      this.validateMembership(this.getMembershipNumber(this.value));
    }
  }

  /**
   * When a user pastes their membership number we
   * should remove the prefix.
   */
  onPaste(event: ClipboardEvent) {
    const w = window as any;
    // Internet explorer uses the window when pasting
    if (!event.clipboardData && w.clipboardData && w.clipboardData.getData) {
      this.writeValue(w.clipboardData.getData('Text'));
    } else if (event.clipboardData && event.clipboardData.getData) {
      this.writeValue(event.clipboardData.getData('text/plain'));
    }
    return false;
  }

  /**
   * When the input changes we need to write the full
   * membership number back into the store.
   */
  onNgModelChange($event: string) {
    // Set this value right away.
    this.value = $event;

    // The input has been touched
    this.propagateOnTouch();

    // Save the full membership number back into the store.
    this.propagateChange(this.getMembershipNumber(this.value));

    // Validate the membership number.
    this.validateMembership(this.getMembershipNumber(this.value));
  }

  /**
   * These errors are used by the parent form control to stop form submission
   * and to show the invalid membership popup.
   */
  public validate(c: FormControl) {
    this.errors = null;

    // A helper method for adding errors
    const addError = (e) => {
      this.errors = { ...(this.errors || {}), ...e };
    };

    // All validation should be performed against the membership number.
    const v = this.getMembershipNumber(this.value);

    // Do we require a membership number be filled in?
    if (this.required && !v) {
      addError({ required: true });
    } else if (!this.isValidLength(v)) {
      // Is the membership a valid length?
      addError({ minlength: true });
    }

    // Are we currently validating the membership against the server?
    if (this.isValidating) {
      addError({ validating: true });
    } else if (this.isValidLength(v) && !this.isActiveMembership) {
      // Have we finished validating the membership but found it to be inactive?
      addError({ isActiveMembership: true });
    }
    return this.errors;
  }

  /**
   * Is the membership number a valid length?
   */
  private isValidLength(membershipNumber: string) {
    return membershipNumber && membershipNumber.length === this.membershipNumberLength;
  }

  /**
   * Prepend the club prefix to get the membership number.
   */
  private getMembershipNumber(v: string) {
    return this.membershipNumberPrefix && v ? `${this.membershipNumberPrefix}${v}` : v;
  }

  /**
   * Try and validate the membership number.
   */
  validateMembership(membershipNumber: string) {
    this.clearValidateSubscription();

    // Reset your validation state
    this.isValidating = false;
    this.isActiveMembership = false;
    this.serverError = false;
    this.propagateValidatorChange();

    // Should we stop validating right now?
    if (!this.isValidLength(membershipNumber) || !this.postalCode) {
      return;
    }

    this.isValidating = true;
    // We use a timer to implement debounce
    this.validateSubscription = timer(1000)
      .pipe(
        mergeMap(() => this.membershipService.validateMembership(membershipNumber, this.postalCode)),
        finalize(() => {
          this.isValidating = false;
          this.hasValidated = true;
          this.propagateValidatorChange();
        })
      )
      .subscribe({
        next: (result) => {
          this.isActiveMembership = result;
          // only update member status in cookie to "entered" if a user
          // who is not logged in or logged in as a non member enters membership number
          const cookie = this.googleUserCookieService.getCookie();
          if (this.isActiveMembership && (!cookie.isLoggedIn || cookie.memberStatus !== IGaUserCookieMemberStatus.member)) {
            this.googleUserCookieService.updateMembershipStatus(IGaUserCookieMemberStatus.enteredNumberManually);
          }
        },
        error: () => {
          this.serverError = true;
        },
      })
      .attach(this);
  }

  clearValidateSubscription() {
    if (this.validateSubscription) {
      this.validateSubscription.unsubscribe();
      this.validateSubscription = null;
    }
  }
}
