import { Component, ViewChild, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import {
  ITripDetails,
  IAppState,
  IMedicalQuestionnaire,
  requireMedicalQuestionnaire,
  ITravellerAge,
  ICountry,
  ITravellerInfo,
  InstrumentationEventNames,
  ProductGroup,
} from 'src/app/models/data.interfaces';
import { dispatch, select, NgRedux } from '@angular-redux-ivy/store';
import { Observable, combineLatest } from 'rxjs';
import { ConnectArrayFixDirective } from 'src/app/shared/directives/connect-array-fix.directive';
import { BaseComponent } from '../base-component.component';
import { QuestionnaireModalContentComponent } from '../questionnaire-modal/questionnaire-modal.component';
import { IMedicalQuestionnaireAction } from 'src/app/store/medical-questionnaire.reducer';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { map, debounceTime } from 'rxjs/operators';
import { FormArray, FormGroup, NgForm } from '@angular/forms';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AppInsights } from 'applicationinsights-js';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { TripDetailsActionCreatorService } from 'src/app/store/trip-details-action-creator.service';
import { Unsubscribe } from 'amaweb-tsutils';

interface IValidationState {
  questionnaireRequired?: boolean;
  buttonTitle?: string;
  buttonLabel?: string;
  buttonClass?: string;
}

@Unsubscribe()
@Component({
  selector: 'traveller-ages',
  templateUrl: './traveller-ages.component.html',
  styleUrls: ['./traveller-ages.component.scss'],
  exportAs: 'travellerAges',
})
export class TravellerAgesComponent extends BaseComponent implements AfterViewInit {
  @Input() existingTravellersAreEditable: boolean;
  @ViewChild('form') public ngForm: NgForm;
  @select('tripDetails') readonly tripDetails$: Observable<ITripDetails>;
  @select(['tripDetails', 'agesOfTravellers']) readonly agesOfTravellers$: Observable<ITravellerAge[]>;
  @select(['tripDetails', 'countrySelected']) readonly countrySelected$: Observable<ICountry>;
  @select(['travellerInfo']) readonly travellerInfo$: Observable<ITravellerInfo>;
  @select('medicalQuestionnaires') readonly medicalQuestionnaires$: Observable<IMedicalQuestionnaire[]>;

  // Finally the combined state slice

  @ViewChild('agesOfTravellers') agesOfTravellersConnectArray: ConnectArrayFixDirective;

  readonly validationState$: Observable<{ [index: number]: IValidationState }>;

  public data: {
    id?: string;
    firstName: string;
    lastName: string;
    dob?: string;
  }[] = [];

  public maxNumberOfTravellers: number;
  public minAgeForSeniors: number;
  public maxAgeForTraveller: number;
  public maxAgeForVisitors: number;
  public ProductGroup = ProductGroup;

  constructor(
    private readonly reduxStore: NgRedux<IAppState>,
    private modalService: BsModalService,
    private readonly changeDetector: ChangeDetectorRef,
    private clubConfigurationService: ClubConfigurationService,
    private readonly tripDetailsActionCreatorService: TripDetailsActionCreatorService
  ) {
    super();

    this.maxNumberOfTravellers = this.clubConfigurationService.appConfig.settings.maximum_number_of_travellers;
    this.minAgeForSeniors = this.clubConfigurationService.appConfig.settings.minimum_age_for_seniors;
    this.maxAgeForTraveller = this.clubConfigurationService.appConfig.settings.maximum_age_for_traveller;
    this.maxAgeForVisitors = this.clubConfigurationService.appConfig.settings.maximum_age_for_visitors_to_canada;

    this.using(
      combineLatest([this.tripDetails$, this.agesOfTravellers$, this.medicalQuestionnaires$, this.countrySelected$])
        // We do not want to remove the medical questionnaire right away in case the person is typing.
        .pipe(debounceTime(2000))
        .subscribe(([tripDetails, agesOfTravellers, questionnaires, _countrySelected]) => {
          // Look for any travel which has a questionnaire but doesn't need it.
          for (let i = 0; i < agesOfTravellers.length; i++) {
            const questionnaire = this.getQuestionnaire(questionnaires, i);
            if (!this.questionnaireRequired(tripDetails, agesOfTravellers, i) && questionnaire) {
              this.reduxStore.dispatch({
                type: SalesFunnelActionType.REMOVE_MEDICAL_QUESTIONNAIRE,
                payload: questionnaire,
              } as IMedicalQuestionnaireAction);
            }
          }
        })
    ).attach(this);

    this.using(
      this.travellerInfo$.subscribe((info) => {
        const primaryTravellers = [];
        if (info.id) {
          primaryTravellers.push({
            id: info.id,
            firstName: info.firstName,
            lastName: info.lastName,
            dob: info.dateOfBirth,
          });
        }

        const otherTravellers = info.otherTravellers
          .filter((x) => x.id)
          .map((x) => ({
            id: x.id,
            firstName: x.firstName,
            lastName: x.lastName,
            dob: x.dateOfBirth,
          }));

        this.data = primaryTravellers.concat(otherTravellers);
      })
    ).attach(this);

    // This observable updates the validation state and doesn't require a debounce.

    this.validationState$ = combineLatest([
      this.tripDetails$,
      this.agesOfTravellers$,
      this.medicalQuestionnaires$,
      this.countrySelected$,
    ]).pipe(
      map(([tripDetails, agesOfTravellers, questionnaires, countrySelected]) => {
        const validationState = {};
        if (countrySelected) {
          // Set the validation state for each traveller
          for (let i = 0; i < agesOfTravellers.length; i++) {
            validationState[i] = {};
            if (agesOfTravellers[i].age <= this.maxAgeForTraveller) {
              const questionnaire = this.getQuestionnaire(questionnaires, i);

              validationState[i].questionnaireRequired = this.questionnaireRequired(tripDetails, agesOfTravellers, i);

              const buttonState = (isComplete: string, isNotComplete: string, isNotEligible: string) => {
                if (questionnaire && questionnaire.isNotEligible) {
                  return isNotEligible;
                } else if (questionnaire && questionnaire.isCompleted) {
                  return isComplete;
                }
                return isNotComplete;
              };

              validationState[i].buttonTitle = buttonState(
                'trip_details.traveller_ages.list.questionnaire_complete_tooltip',
                'trip_details.traveller_ages.list.questionnaire_not_complete_tooltip',
                'trip_details.traveller_ages.list.questionnaire_not_eligible_tooltip'
              );

              validationState[i].buttonLabel = buttonState(
                'trip_details.traveller_ages.list.questionnaire_complete_label',
                'trip_details.traveller_ages.list.questionnaire_not_complete_label',
                'trip_details.traveller_ages.list.questionnaire_not_eligible_label'
              );

              validationState[i].buttonClass = buttonState('btn-outline-success', 'btn-outline-primary', 'btn-outline-danger');
            }
          }
        }
        return validationState;
      })
    );
  }

  public getQuestionnaire(questionnaires: IMedicalQuestionnaire[], travellerIndex: number): IMedicalQuestionnaire {
    return questionnaires && questionnaires.length && questionnaires.find((q) => q && q.relatesToTravellerIndex === travellerIndex);
  }

  private questionnaireRequired(tripDetails: ITripDetails, agesOfTravellers: ITravellerAge[], travellerIndex: number): boolean {
    return (
      requireMedicalQuestionnaire(tripDetails) &&
      agesOfTravellers[travellerIndex] &&
      agesOfTravellers[travellerIndex].age >= this.minAgeForSeniors
    );
  }

  public ngAfterViewInit() {
    this.using(
      combineLatest([this.tripDetails$, this.agesOfTravellers$, this.medicalQuestionnaires$, this.countrySelected$]).subscribe(
        ([tripDetails, agesOfTravellers, questionnaires, _countrySelected]) => {
          // Set the validation state for each traveller
          for (let i = 0; i < agesOfTravellers.length; i++) {
            const questionnaire = this.getQuestionnaire(questionnaires, i);
            if (questionnaire && questionnaire.isCompleted) {
              const ageInput = ((this.ngForm.form.get('agesOfTravellers') as FormArray).controls[i] as FormGroup).controls.age;
              if (ageInput.errors) {
                // if any questionnaire state is completed, reset input field invalid status by clear up errors.

                // 3 UI elements (button title, label and classes) are bind to this same observable func,
                // we want clearout errors happens after all 3 observable listener finished.
                // wrap up action in setTimeout with 0 second can do the trick.
                setTimeout(() => {
                  ageInput.setErrors(null);
                  // ageInput.markAsTouched();
                  this.changeDetector.detectChanges();
                }, 0);
              }
            }
          }
        }
      )
    ).attach(this);
  }

  public onSubmit() {
    const tripDetails = this.reduxStore.get((x) => x.tripDetails);

    // Does this flow require medical questionnaires?
    if (requireMedicalQuestionnaire(tripDetails)) {
      const questionnaires = this.reduxStore.getState().medicalQuestionnaires;

      for (let i = 0; i < tripDetails.agesOfTravellers.length; i++) {
        const age = tripDetails.agesOfTravellers[i].age;

        // This traveller requires a questionnaire.
        if (age >= this.minAgeForSeniors && age <= this.maxAgeForTraveller) {
          const medQ = questionnaires && questionnaires.find((x) => x.relatesToTravellerIndex === i);

          // All travellers who require a questionnaire should have a completed questionaire
          // that is also eligible.
          if (!medQ || !medQ.isCompleted || medQ.isNotEligible) {
            // traveller with imcompleted questionnaire should set invalid to indicate
            const ageInput = (this.agesOfTravellersConnectArray.control.controls[i] as FormGroup).controls.age;

            if (!medQ || !medQ.isCompleted) {
              ageInput.setErrors({ questionnaire_needed: true });
            } else if (!medQ || medQ.isNotEligible) {
              AppInsights.trackEvent(InstrumentationEventNames.MedicalQuestionnaireIneligible);
              ageInput.setErrors({ not_eligible: true });
            }
            ageInput.markAsTouched();
          }
        }
      }
    }

    // Submitting the form causes the validation errors to appear.
    this.ngForm.onSubmit(null);
    return this.ngForm.valid;
  }

  @dispatch() removeTraveller(index: number) {
    return this.tripDetailsActionCreatorService.removeTravellerByIndex(index);
  }

  @dispatch() addTraveller() {
    const state = this.reduxStore.getState();
    const newValue = (state.tripDetails.numberOfTravellers || 1) + 1;
    return this.tripDetailsActionCreatorService.updateNumberOfTravellers(newValue);
  }

  openModalWithComponent(travellerIndex: number) {
    const config = {
      class: 'modal-lg modal-footer-flex-row',
      initialState: {
        travellerIndex: travellerIndex,
      },
    };
    this.modalService.show(QuestionnaireModalContentComponent, config);
  }
}
