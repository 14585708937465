import { Component } from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { ITripDetails, IQuoteOption, ICurrentQuoteDetails, ProductGroup } from 'src/app/models/data.interfaces';

@Component({
  selector: 'confirmed-details',
  templateUrl: './confirmed-details.component.html',
})
export class ConfirmedDetailsComponent {
  @select(['policyInfo', 'tripDetails']) readonly tripDetails$: Observable<ITripDetails>;
  @select(['policyInfo', 'currentQuoteDetails']) readonly currentQuoteDetails$: Observable<ICurrentQuoteDetails>;
  @select(['policyInfo', 'currentQuoteDetails', 'quoteOption']) readonly quoteSelected$: Observable<IQuoteOption>;

  productGroup = ProductGroup;

  dateFormat = 'MMMM d, yyyy';

  constructor() {}
}
