import { isAfter, isDate, addDays } from 'date-fns';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
dayjs.extend(dayjsUTC);

export class DateHelpers {
  static isLessThan(subjectDate: Date, ruleDate: Date) {
    const dateOne = DateHelpers.hourZero(subjectDate);
    const dateTwo = DateHelpers.hourZero(ruleDate);

    return dateOne < dateTwo;
  }
  static hourZero(subjectDate: Date) {
    const returnDate = new Date(subjectDate);
    returnDate.setHours(0, 0, 0, 0);

    return returnDate;
  }

  static repurchaseMinDate(prevPolicyExpireDate: any): Date {
    let d = prevPolicyExpireDate;

    // Try and parse the min date
    if (d && !(d instanceof Date)) {
      d = DateHelpers.parseDate(d);
    }

    // Calculate the min date
    d = addDays(d, 1);

    // Simulate a day in the future to test client side validation logic.
    // const today = addDays(new Date(), 1000);

    const today = new Date();

    return isDate(d) && isAfter(today, d) ? today : d;
  }

  /**
   * Will attempt to parse a string into a date, will default to local time.
   * If a date is provided it will be returned without any changes to it.
   */
  static parseDate(value: any, utc: boolean = false) {
    // If the value is a string we are going to parse it.
    if (value && typeof value === 'string') {
      const parsed = dayjs.utc(value);
      if (parsed.isValid()) {
        if (utc) {
          return new Date(Date.UTC(parsed.year(), parsed.month(), parsed.date()));
        } else {
          return new Date(parsed.year(), parsed.month(), parsed.date());
        }
      }
    } else if (value instanceof Date) {
      return value;
    }
    return null;
  }

  static forceIntoUtc(value: Date) {
    return new Date(
      Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds())
    );
  }
}
