<div class="full-modal-content">
     <div *ngIf="forceDialog"
          class="text-right u-ama-color--royal-blue d-flex justify-content-end mb-3 pr-4 pt-4">
          <a (click)="bsModalRef.hide()"
             href="javascript:void(0)"
             class="text-decoration-none font-weight-bold d-flex align-items-center">
               Close<span class="font-weight-normal times-icon ml-1">&times;</span>
          </a>
     </div>
     <div *injector="{ productGroupFlow: productGroupFlow$ }; let productGroupFlow = productGroupFlow;"
          class="eligibility-modal-content">
          <ng-container *ngIf="forceDialog;else defaultModal">
               <h2>{{'policy_matrix.eligibility_requirements_title' | translate}}</h2>
               <div class="eligibility-text-container"
                    [innerHtml]="(productGroupFlow === ProductGroup.RentalVehicleDamageFlow ? 'review_quote.rvd.eligibility_text' : 'policy_matrix.eligibility_requirements_text') | translate | safeHtml">
               </div>
               <div class="form-check are-eligible-checkbox"
                    name="canadaOnly">
                    <input type="checkbox"
                           class="form-check-input mt-2"
                           [checked]="areEligible"
                           (change)="areEligible = !areEligible"
                           id="areEligible"
                           name="areEligible"
                           value="false" />
                    <label class="form-check-label font-weight-bold border eligible-checkbox-label ml-3"
                           for="areEligible"
                           [innerHTML]="'policy_matrix.eligibility_checkbox_label' | translate">
                    </label>
               </div>
               <button class="btn btn-secondary btn-lg btn--icon-chevron-right continue-btn px-5 mt-5"
                       analytic-ama="link"
                       type="button"
                       (click)="continueClicked()"
                       [disabled]="!areEligible">
                    <span class="px-1">{{'policy_matrix.continue_to_next_step' | translate}}</span>
               </button>
          </ng-container>
          <ng-template #defaultModal>
               <div class="p-2"
                    [innerHtml]="(productGroupFlow === ProductGroup.RentalVehicleDamageFlow ? 'review_quote.rvd.eligibility_text' : 'policy_matrix.eligibility_text') | translate | safeHtml">
               </div>
               <p class="text-right"><a href="javascript:void(0)"
                       (click)="bsModalRef.hide()">close</a></p>
          </ng-template>
     </div>
</div>