import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from 'src/app/components/base-component.component';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';

@Component({
  selector: 'tooltip-modal',
  templateUrl: './tooltip-modal.component.html',
})
export class TooltipModalComponent extends BaseComponent {
  public areEligible = false;
  public forceDialog: boolean;
  public tooltipHtml: string;

  constructor(public bsModalRef: BsModalRef, private googleAnalyticsService: GoogleAnalyticsService) {
    super();
  }

  continueClicked() {
    this.bsModalRef.hide();
  }
}
