export enum MemberType {
  Primary = 'P',
  Associate = 'A',
  Kids = 'CHILD',
}

export enum MembershipLevel {
  BASIC = 'Basic',
  PLUS = 'Plus',
  PLUSRV = 'Plus RV',
  PREMIER = 'Premier',
  PREMRV = 'Premier RV',
  COMMUNITY = 'Community',
}
