import { SalesFunnelActionType, IQuoteOptionAction } from './reducer';
import { Injectable } from '@angular/core';
import { QuoteService } from '../core/services/quote.service';
import { IQuoteOption } from '../models/data.interfaces';
import { Middleware } from 'redux';
import { ClubConfigurationService } from '../core/services/club-configuration.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Unsubscribe } from 'amaweb-tsutils';

@Unsubscribe()
@Injectable({
  providedIn: 'root',
})
export class TripDetailsQuoteOptionRetrievalMiddleware {
  constructor(private readonly quoteService: QuoteService, private readonly clubConfigurationService: ClubConfigurationService) {}

  middleware: Middleware = (store) => (next) => (action) => {
    next(action);
    if (action.type === SalesFunnelActionType.FETCH_QUOTE_OPTIONS) {
      // Fetch the quote options and remove the reference to the subscription when done.
      this.quoteService.fetchQuoteOptions().pipe(take(1)).subscribe().attach(this);
    }
    if (action.type === SalesFunnelActionType.CLEAR_QUOTE_OPTIONS) {
      this.quoteService.clearQuoteOptions();
    }
  };
}

export const setQuoteOptions = (options: IQuoteOption[]): IQuoteOptionAction => {
  return {
    type: SalesFunnelActionType.SET_QUOTE_OPTIONS,
    payload: options,
  } as IQuoteOptionAction;
};

/**
 * This method will start an async fetch for the quote options through the middleware.
 * If you need to subscribe to the result, use the quoteService directly instead.
 */
export const fetchQuoteOptions = (isValid: boolean): IQuoteOptionAction => {
  if (isValid) {
    return {
      type: SalesFunnelActionType.FETCH_QUOTE_OPTIONS,
    } as IQuoteOptionAction;
  } else {
    return {
      type: SalesFunnelActionType.CLEAR_QUOTE_OPTIONS,
    } as IQuoteOptionAction;
  }
};
