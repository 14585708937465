<footer class="footer c-ata-footer">
    <div class="container c-ata-footer__bottom">
        <p class="text-center text-white">
            <span class="d-none d-md-inline"
                  [innerHTML]="'footer.agents' | translate | safeHtml"></span>
            <span class="d-md-none"
                  [innerHTML]="'footer.agents_mobile' | translate | safeHtml"></span>
        </p>
        <div class="text-center text-white">{{ 'footer.hours.m-f' | translate }}</div>
        <div class="text-center text-white">{{ 'footer.hours.s-s' | translate }}</div>
        <div class="c-ama-footer__links row d-flex justify-content-center text-center text-white mt-3 mt-md-4">
            <span class="col-sm-4 col-md-3"
                  style="white-space: nowrap"
                  [innerHTML]="'footer.copyright_format' | translate | format:currentYear | safeHtml"></span>&nbsp;&nbsp;
            <span class="col-sm-4 col-md-3"
                  [innerHTML]="'footer.terms' | translate | safeHtml"></span>&nbsp;&nbsp;
            <span class="col-sm-4 col-md-3"
                  [innerHTML]="'footer.privacy' | translate | safeHtml"></span>
        </div>
    </div>
</footer>