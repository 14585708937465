import { Injectable, Inject } from '@angular/core';
import { hasValue } from '../helpers/app.helpers';

export interface IStorage {
  put: (key: string, val: any) => void;
  get: (key: string) => any;
  remove: (key: string) => void;
  clear: () => void;
}

@Injectable()
export class StorageService {
  public session: IStorage;
  public local: IStorage;

  constructor() {
    const createStorageService = (type) => {
      const _storage = localStorage[type];
      if (hasValue(_storage)) {
        return {
          put: (key, val) => _storage.setItem(key, JSON.stringify(val)), // Warning is expected
          get: (key) => {
            try {
              return JSON.parse(_storage.getItem(key)); // Warning is expected
            } catch (e) {
              return null;
            }
          },
          remove: (key) => {
            _storage.removeItem(key); // Warning is expected
          },
          clear: () => _storage.clear(), // Warning is expected
        };
      } else {
        return {
          put: (key, val) => {},
          get: (key) => undefined,
          remove: (key) => {},
          clear: () => {},
        };
      }
    };
    this.session = createStorageService('sessionStorage');
    this.local = createStorageService('localStorage');
  }
}
