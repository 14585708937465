<div class="c-ata-memberjoin">
    <div class="c-ata-memberjoin__bottom u-ama-bg-color--navy-blue">
        <div class="container other-services">
            <div class="row">
                <div class="col-12 px-4 option text-center">
                    {{ 'memberjoin.discount_ama' | translate }} &nbsp; <a href="javascript:void(0)"
                       (click)="showMembershipDiscountModal()">{{ 'memberjoin.learn_more' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>