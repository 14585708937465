<!--
  Usage example:
  <svg-icon name="home"></svg-icon>
-->

<svg version="1.1"
     [attr.style]="styles"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
  <use [attr.xlink:href]="url"></use>
</svg>