<ng-container *ngIf="tenant === TenantEnum.AMA">
    <footer class="footer c-ama-footer">
        <div class="c-ama-footer__top container">
            <div class="text-muted text-truncate"></div>
        </div>
        <div class="c-ama-footer__bottom">
            <div class="c-ama-footer__racetrack">
                <div class="container">
                    <p class="text-center text-white mt-3 mt-md-5">
                        <span class="d-none d-md-inline"
                              [innerHTML]="'footer.agents' | translate | safeHtml"></span>
                        <span class="d-md-none"
                              [innerHTML]="'footer.agents_mobile' | translate | safeHtml"></span>
                    </p>
                    <div class="text-center text-white">{{ 'footer.hours.m-f' | translate }}</div>
                    <div class="text-center text-white">{{ 'footer.hours.s-s' | translate }}</div>
                    <div class="c-ama-footer__links row d-flex justify-content-center text-center text-white mt-3 mt-md-4">
                        <span class="col-sm-4 col-md-3 text-muted"
                              style="white-space: nowrap"
                              [innerHTML]="'footer.copyright_format' | translate | format:currentYear | safeHtml"></span>&nbsp;&nbsp;
                        <span class="col-sm-4 col-md-3"
                              [innerHTML]="'footer.terms' | translate | safeHtml"></span>&nbsp;&nbsp;
                        <span class="col-sm-4 col-md-3"
                              [innerHTML]="'footer.privacy' | translate | safeHtml"></span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</ng-container>
<ng-container *ngIf="tenant === TenantEnum.ATA">
    <ata-footer />
</ng-container>
<ng-container *ngIf="tenant === TenantEnum.Other">
    <footer class="footer c-other-footer">
        <div class="container">
            <div class="text-muted text-truncate">
                Place {{ 'title' | translate }} footer content here.
            </div>
        </div>
    </footer>
</ng-container>