import { Injectable } from '@angular/core';
import {
  IAppState,
  IServerAppState,
  IServerQuotePaymentRequest,
  IServerQuotePaymentResponse,
  IServerSelectedQuoteOption,
} from '../../models/data.interfaces';
import { CountriesService } from './countries.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteMapper {
  constructor(private countriesService: CountriesService) {}

  public mapServerStateToIAppState(serverResponse: IServerAppState): IAppState {
    const td = serverResponse.tripDetails;

    // The server only responds with the country and subRegion code.
    // We need to load the API value for the name.
    const countries = this.countriesService.getCountries();
    const apiCountry = countries && countries.find(x => x.code === td.countrySelected);
    let countrySelected = null;
    let subRegionSelected = null;
    if (apiCountry) {
      // Do not include the subRegions in the country. We only need the name.
      countrySelected = { name: apiCountry.name, code: apiCountry.code };
      if (apiCountry.subRegions) {
        subRegionSelected = apiCountry.subRegions.find(x => x.code === td.subRegionSelected);
      }
    }

    return {
      quoteID: serverResponse.quoteID,
      policyInfo: {
        ...serverResponse.policyInfo,
        currentQuoteDetails: null,
        tripDetails: null,
      },
      tripDetails: {
        departureDate: td.departureDate,
        returnDate: td.returnDate,
        policyStartDate: td.policyStartDate,
        durationOfFirstTrip: td.durationOfFirstTrip,
        numberOfTravellers: td.numberOfTravellers,
        productGroupFlow: td.productGroupFlow,
        productSelected: td.productSelected,
        isClubMember: td.isClubMember,
        agesOfTravellers: td.agesOfTravellers,
        isEligibleForFamilyRate: td.isEligibleForFamilyRate,
        isTravellingOnlyInCanada: td.isTravellingOnlyInCanada,
        emergencyContact: td.emergencyContact,
        // Mapped from the API
        countrySelected: countrySelected,
        // Mapped from the API
        subRegionSelected: subRegionSelected,
        sumInsured: td.sumInsured,
        prevPolicyExpireDate: td.prevPolicyExpireDate,
        prevPolicyProductSelected: td.prevPolicyProductSelected,
      },
      travellerInfo: { ...serverResponse.travellerInfo },
      currentQuoteDetails: {
        planDays: td.planDays,
        topUpDays: td.topUpDays,
        deductibleAmount: td.deductibleAmount,
      },
      medicalQuestionnaires: td.medicalQuestionnaires,
      // Ignore these properties
      quoteOptions: null,
    };
  }
  public mapIAppStateToServerAppState(appState: IAppState): IServerAppState {
    const td = appState.tripDetails;

    // Set the selected quote option
    const option = appState.currentQuoteDetails.quoteOption;
    let selectedQuoteOption: IServerSelectedQuoteOption = null;
    if (option) {
      selectedQuoteOption = {
        identifier: option.identifier,
        sumInsured: option.sumInsured,
        saved: option.saved,
      };
    }

    return {
      quoteID: appState.quoteID,
      policyInfo: { ...appState.policyInfo },
      tripDetails: {
        departureDate: td.departureDate,
        returnDate: td.returnDate,
        policyStartDate: td.policyStartDate,
        durationOfFirstTrip: td.durationOfFirstTrip,
        numberOfTravellers: td.numberOfTravellers,
        productGroupFlow: td.productGroupFlow,
        productSelected: td.productSelected,
        isClubMember: td.isClubMember,
        agesOfTravellers: td.agesOfTravellers,
        isEligibleForFamilyRate: td.isEligibleForFamilyRate,
        // We only need to transmit the code
        countrySelected: td.countrySelected && td.countrySelected.code,
        // We only need to transmit the code
        subRegionSelected: td.subRegionSelected && td.subRegionSelected.code,
        isTravellingOnlyInCanada: td.isTravellingOnlyInCanada,
        emergencyContact: td.emergencyContact,
        // Other values
        planDays: appState.currentQuoteDetails.planDays,
        topUpDays: appState.currentQuoteDetails.topUpDays,
        deductibleAmount: appState.currentQuoteDetails.deductibleAmount,
        medicalQuestionnaires: appState.medicalQuestionnaires,
        sumInsured: td.sumInsured,
        // Including previous policy information
        prevPolicyExpireDate: td.prevPolicyExpireDate,
        prevPolicyProductSelected: td.prevPolicyProductSelected,
      },
      travellerInfo: { ...appState.travellerInfo },
      selectedQuoteOption: selectedQuoteOption,
    };
  }
  public MapAppStatePaymentInfoToServerPaymentRequest(appPaymentInfo: any): IServerQuotePaymentRequest {
    return appPaymentInfo;
  }
  public MapServerPaymentResponseToAppPaymentResponse(serverResp: IServerQuotePaymentResponse): any {
    return serverResp;
  }
}
