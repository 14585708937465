import { ITripDetails } from '../../models/data.interfaces';
import { SalesFunnelActionType } from '../../store/reducer';
import { GetInitialTripDetails } from '../../store/initial-states';
import { IMultiStateUpdateAction } from 'src/app/store/trip-details-action-creator.service';

export interface ITripDetailsAction {
  type: SalesFunnelActionType;
  tripDetailsState: ITripDetails;
}

export function tripDetailsReducer(state: ITripDetails = GetInitialTripDetails(), action: ITripDetailsAction | IMultiStateUpdateAction) {
  switch (action.type) {
    case SalesFunnelActionType.UPDATE_POLICY_PRODUCT:
    case SalesFunnelActionType.UPDATE_SELECTED_PRODUCT_GROUP:
    case SalesFunnelActionType.UPDATE_TRIP_DETAILS:
    case SalesFunnelActionType.UPDATE_TRAVELLING_COUNTRY:
    case SalesFunnelActionType.UPDATE_TRAVELLING_SUB_REGION:
    case SalesFunnelActionType.UPDATE_POLICY_DURATION:
      return { ...state, ...(action as ITripDetailsAction).tripDetailsState };
    case SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS:
      return { ...state, ...(action as IMultiStateUpdateAction).payload.tripDetailsState };
    default:
      return state;
  }
}
