import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StencilReviewQuoteMainComponent } from './stencil-review-quote-main.component';

@NgModule({
  declarations: [StencilReviewQuoteMainComponent],
  imports: [CommonModule],
  exports: [StencilReviewQuoteMainComponent],
})
export class StencilReviewQuoteMainModule {}
