import { Directive, HostListener } from '@angular/core';
import { SignInModalContentComponent } from 'src/app/components/sign-in-modal/sign-in-modal.component';
import { IServerConfig, ServerLogin } from 'src/app/models/data.interfaces';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Directive({
  selector: '[linkLogin]',
})
export class LinkLoginDirective {
  private serverConfig: IServerConfig;

  constructor(private clubConfigurationService: ClubConfigurationService, private modalService: BsModalService) {
    this.serverConfig = this.clubConfigurationService.serverConfig;
  }

  @HostListener('click', ['$event']) onClick(e: Event) {
    e.preventDefault();

    switch (this.serverConfig.allowLogin) {
      case ServerLogin.No:
        return;
      case ServerLogin.Password:
        return this.showModal();
      case ServerLogin.Token:
        window.location.href = '/Account/SignIn?' + this.params({ returnUrl: window.location.pathname });
        return;
    }
  }

  showModal() {
    this.modalService.show(SignInModalContentComponent, {
      class: 'sign-in-modal-dialog',
      ignoreBackdropClick: true,
    });
  }

  params(params: any): string {
    return Object.keys(params)
      .map((k) => `${k}=${encodeURIComponent(params[k])}`)
      .join('&');
  }
}
