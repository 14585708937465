import { Directive, HostListener, AfterViewInit, ElementRef, Input } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { MatchMediaService, MEDIA_TYPE } from 'src/app/core/services/match-media.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[auto-placement]',
})
export class AutoPlacementDirective implements AfterViewInit {
  @Input('auto-placement') preference: 'top' | 'bottom' | 'left' | 'right' | null;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private element: ElementRef, private tooltip: TooltipDirective, private matchMediaService: MatchMediaService) {
    this.tooltip.placement = 'bottom';
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.calculateTooltipPosition(), 0);
  }

  @HostListener('window:resize', ['$event'])
  calculateTooltipPosition() {
    const rect = this.element.nativeElement.getBoundingClientRect();
    const positionX = rect.left + (rect.right - rect.left) / 2;
    const percent = positionX / window.innerWidth;

    let position: 'top' | 'bottom' | 'left' | 'right';
    if (this.preference != null && this.matchMediaService.matches(MEDIA_TYPE.md)) {
      position = this.preference;
    } else if (percent < 0.3) {
      position = 'right';
    } else if (percent > 0.7) {
      position = 'left';
    } else {
      position = 'top';
    }

    this.tooltip.placement = position;
  }
}
