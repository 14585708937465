import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StencilReviewQuoteMainModule } from './stencil/stencil-review-quote-main/stencil-review-quote-main.module';
import { StencilContentSidebarModule } from './stencil/stencil-content-sidebar/stencil-content-sidebar.module';
import { LoadingOverlayModule } from './loading-overlay/loading-overlay.module';
import { AirplaneFlyingModule } from './airplane-flying/airplane-flying.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { LoadingSuperflyOverlayModule } from './loading-superfly-overlay/loading-superfly-overlay.module';

@NgModule({
  imports: [CommonModule],
  exports: [
    StencilReviewQuoteMainModule,
    StencilContentSidebarModule,
    LoadingOverlayModule,
    AirplaneFlyingModule,
    LoadingSuperflyOverlayModule,
    AngularDraggableModule,
  ],
})
export class LoadingIndicatorsModule {}
