import { Injectable } from '@angular/core';
import { IIdentifyHeap } from 'src/app/models/identify-heap.model';

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService {
  get nativeWindow(): Window {
    return getWindow();
  }

  get dataLayer(): any[] {
    return this.nativeWindow['dataLayer'];
  }

  public navigateTo(url: string): void {
    this.nativeWindow.location.href = url;
  }

  public reload(): void {
    this.nativeWindow.location.reload();
  }

  get heap(): IIdentifyHeap {
    return this.nativeWindow['heap'];
  }
}
