<div class="row my-5">
    <div class="col-12 col-md-6">
        <p class="h3 mb-3 lato-black text-capitalize">{{ 'coverage.exclusive_saving.title' | translate }}</p>
        <div [innerHTML]="'coverage.exclusive_saving.paragraph' | translate | safeHtml"></div>
        <p><b [innerHTML]="'coverage.exclusive_saving.cta_label' | translate | safeHtml"></b></p>
        <a [href]="'coverage.exclusive_saving.cta_url' | translate"
           target="_blank"
           class="u-ama-color--white enter-cta btn btn-primary2 u-ama-bg-color--orange text-capitalize px-3 py-2">{{ 'coverage.exclusive_saving.cta_title' | translate }}</a>
    </div>
    <div class="col-12 col-md-6 pt-3 pt-lg-0">
        <ul class="exclusive-saving-list">
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet1' | translate }}
            </li>
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet2' | translate }}
            </li>
            <li>
                {{ 'coverage.exclusive_saving.bullets.bullet3' | translate }} <br />
                <span class="mice_text">{{ 'coverage.exclusive_saving.mice_text' | translate }}</span>
            </li>
        </ul>
    </div>
</div>
