import { Component, OnInit, OnDestroy } from '@angular/core';
import { IMedicalFee } from 'src/app/models/medical-fee.model';
import { IQuoteOption, IAppState } from 'src/app/models/data.interfaces';
import { select, dispatch, NgRedux } from '@angular-redux-ivy/store';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { InsuranceProduct } from 'src/app/models/products';
import { EligibilityRequirementsModalComponent } from '../policy-matrix/eligibility-requirements-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Unsubscribe } from 'amaweb-tsutils';

export interface ICoverageOption {
  coverageAmount: number;
  exampleCoverage: string;
  coverPercentage: number;
  recommended?: boolean;
  quotePrice?: number;
}

@Unsubscribe()
@Component({
  selector: 'visitors-upsell-matrix',
  templateUrl: './visitors-upsell-matrix.component.html',
})
export class VisitorsUpsellMatrixComponent implements OnInit {
  @select('quoteOptions') readonly quoteOptions$: Observable<IQuoteOption[]>;
  insuranceProduct = InsuranceProduct;

  medicalFees: IMedicalFee[] = [];

  coverageOptions: { [id: string]: ICoverageOption } = {};
  coverageOptionKeys: string[];
  modalRef: BsModalRef;

  constructor(
    private router: Router,
    private clubConfigurationService: ClubConfigurationService,
    private reduxStore: NgRedux<IAppState>,
    private modalService: BsModalService
  ) {
    this.coverageOptions = this.clubConfigurationService.appConfig.products[InsuranceProduct.VisitorsToCanadaMedicalPlan].coverage_options;
    this.medicalFees = this.clubConfigurationService.appConfig.products[InsuranceProduct.VisitorsToCanadaMedicalPlan].medical_fees;

    this.quoteOptions$
      .subscribe((x) => {
        this.coverageOptionKeys = Object.keys(this.coverageOptions);
        this.coverageOptionKeys.forEach((key) => {
          this.coverageOptions[key].quotePrice = null;
        });

        (x || []).forEach((item) => {
          const amount = item.identifier.match(/(\d+)-/);
          if (amount[1] in this.coverageOptions) {
            this.coverageOptions[amount[1]].quotePrice = item.totalPrice;
          }
        });
      })
      .attach(this);
  }

  ngOnInit() {}

  advanceToReviewQuote() {
    this.router.navigateByUrl('/review-quote');
  }

  eligibiltyAccepted = (amount: number) => {
    // Update the product
    this.reduxStore.dispatch({
      type: SalesFunnelActionType.UPDATE_POLICY_PRODUCT,
      tripDetailsState: { productSelected: InsuranceProduct.VisitorsToCanadaMedicalPlan },
    });
    // Also update the sum insured
    this.reduxStore.dispatch({
      type: SalesFunnelActionType.UPDATE_SELECTED_QUOTE_OPTION,
      payload: { sumInsured: amount },
    });
    this.advanceToReviewQuote();
  };

  openEligibilityDialog(amount: number) {
    const initialState: Record<string, unknown> = {
      forceDialog: true,
      afterFunction: () => {
        this.eligibiltyAccepted(amount);
      },
    };
    this.modalRef = this.modalService.show(
      // tslint:disable-next-line: no-use-before-declare
      EligibilityRequirementsModalComponent,
      {
        class: 'eligibility-dialog-modal',
        initialState: initialState,
        ignoreBackdropClick: true,
        backdrop: true,
      }
    );
  }
}
