<div *injector="{
    policyNumber: policyNumber$,
    quoteOption: quoteOption$,
    tripDetails: tripDetails$
};
let policyNumber = policyNumber;
let quoteOption = quoteOption;
let tripDetails = tripDetails;
"
     class="row justify-content-center">
    <div class="col-12 col-sm-10 col-md-8 text-center">
        <div>
            <div class="c-check"
                 style="margin-bottom: 5px;">
                <div class="c-check-icon c-check-success animate">
                    <span class="c-check-line c-check-tip animateSuccessTip"></span>
                    <span class="c-check-line c-check-long animateSuccessLong"></span>
                    <div class="c-check-placeholder"></div>
                    <div class="c-check-fix"></div>
                </div>
            </div>
            <h1 class="h3 text-success">
                {{'policy-confirmation.title' | translate}}
            </h1>
            <p class="pt-2"
               [innerHTML]="'policy-confirmation.paragraph_1' | translate | safeHtml"></p>
            <p class="pt-2"
               [innerHTML]="'policy-confirmation.paragraph_2' | translate | safeHtml"></p>
            <h4 class="text-capitalize pt-lg-4"
                [innerHTML]="'policy-confirmation.policy_confirmation' | translate | format:policyNumber | safeHtml"></h4>
        </div>
        <div class="mt-3 mt-lg-4">
            <div class="c-policy-table row">
                <h3 class="c-policy-table__left justify-content-center justify-content-lg-end col-12 col-sm-6 px-2">
                    {{ 'policy-confirmation.policy_details_title' | translate }}</h3>
                <h5 class="c-policy-table__right justify-content-center justify-content-lg-start col-12 col-sm-6 px-2">
                    <span>{{ quoteOption?.productName }}</span></h5>
            </div>
            <confirmed-details />
        </div>
        <div class="mt-5">
            <h3>{{ 'policy-confirmation.policy_card_title' | translate }}</h3>
            <div class="my-5 row">
                <div class="col-sm-6">
                    <img src="assets/images/policy-card-icon.png" />
                    <p class="mt-2 mt-sm-3"
                       [innerHtml]="(tripDetails?.productGroupFlow === productGroup.RentalVehicleDamageFlow ? 'policy-confirmation.policy_card_blurb_rvd' : 'policy-confirmation.policy_card_blurb') | translate | safeHtml">
                    </p>
                </div>
                <div class="col-sm-6">
                    <img src="assets/images/travel-agents-icon.png" />
                    <p class="mt-2 mt-sm-3"
                       [innerHtml]="'policy-confirmation.policy_card_agent_blurb' | translate | safeHtml"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<activity-deal-cards [countryName]="countryName"
                     [subRegionName]="subRegionName" />

<div class="c-confirmed-policy_back-to-ama-travel"><a
       href="https://www.amatravel.ca/">{{'policy-confirmation.activity_deal_cards.back_to_travel' | translate}}</a></div>