import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSuperflyOverlayComponent } from './loading-superfly-overlay.component';
import { AirplaneFlyingModule } from '../airplane-flying/airplane-flying.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { LoadingSuperflyOverlayDirective } from './loading-superfly-overlay.directive';

@NgModule({
  declarations: [LoadingSuperflyOverlayComponent, LoadingSuperflyOverlayDirective],
  imports: [CommonModule, AirplaneFlyingModule, AngularDraggableModule],
  exports: [LoadingSuperflyOverlayComponent, LoadingSuperflyOverlayDirective],
})
export class LoadingSuperflyOverlayModule {}
