import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { vsprintf } from 'sprintf-js';

abstract class FormatPipe implements PipeTransform {
  protected abstract regex: RegExp;
  protected abstract format: string;

  public transform(value: any) {
    const result = this.regex.exec(value);
    if (result) {
      // Skip the first capture group because it will be matched to the entire input.
      return vsprintf(this.format, result.slice(1, result.length));
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'phoneNumberFormat' })
export class PhoneNumberFormatPipe extends FormatPipe implements PipeTransform {
  protected regex = /^\s*(\d{3})(\d{3})(\d{4})\s*$/;
  protected format = '%s-%s-%s';
}

@Pipe({ name: 'postalCodeFormat' })
export class PostalCodeFormatPipe extends FormatPipe implements PipeTransform {
  protected regex = /^\s*(\w{3})\s*(\w{3})\s*$/;
  protected format = '%s %s';
}

@NgModule({
  declarations: [PhoneNumberFormatPipe, PostalCodeFormatPipe],
  exports: [PhoneNumberFormatPipe, PostalCodeFormatPipe],
})
export class FormatPipesModule {}
