<div class="c-anim-plane">
  <div class="c-anim-plane__inner">
    <div class="c-anim-plane__plane-frame">
      <div class="c-anim-plane__plane-container">
        <svg class="c-anim-plane__plane"
             viewBox="0 0 580.18688 128.25565">
          <defs><style>.cls-p-1{fill:#e8eaf0;}.cls-p-2{fill:#f7f9ff;}.cls-p-3{fill:#ef6843;}.cls-p-4{fill:#e15426;}.cls-p-5{fill:#434345;}.cls-p-6{fill:#b1b1b5;}.cls-p-7{fill:#263970;}.cls-p-8{fill:#353435;}.cls-p-9{fill:#00549b;}.cls-p-10{fill:#fff;}.cls-p-11{fill:none;stroke:#fff;stroke-width:0.401px;}.cls-p-12{fill:#00539b;}.cls-p-13,.cls-p-14{fill:#db2b27;}.cls-p-14{fill-rule:evenodd;}</style></defs>
          <title>plane</title>
          <path class="cls-p-1"
                d="M382.17512,66.948l17.65846-.01484A22.75751,22.75751,0,0,1,409.91686,69.111l41.15759,20.82943c-20.56382,2.2335-47.832-.13433-47.832-.13433Z"
                transform="translate(0 -3)" />
          <path class="cls-p-2"
                d="M546.58118,82.83133,565.07787,94.452c3.47134,2.18081,14.72309,8.39467,15.08936,12.47776.60487,6.74259-12.899,10.09061-19.4526,11.17373l-21.3105,3.522a72.98293,72.98293,0,0,1-11.58271.97614l-137.15186.59823a164.4307,164.4307,0,0,1-68.933-14.816L272.74681,86.04723l-.03456-7.91654,10.82795-2.464,234.38568-1.02233A53.427,53.427,0,0,1,546.58118,82.83133Z"
                transform="translate(0 -3)" />
          <path class="cls-p-3"
                d="M290.2294,3l33.05624,44.25673a53.08447,53.08447,0,0,0,28.667,19.47546l31.41466,8.49917-39.0596.17034-26.572.11593-34.1956.14908L272.38488,3.07782Z"
                transform="translate(0 -3)" />
          <polygon class="cls-p-4"
                   points="281.595 60.008 274.97 16.902 280.416 16.878 290.69 59.968 281.595 60.008" />
          <path class="cls-p-3"
                d="M558.664,118.44242l-19.2599,3.18315a72.98144,72.98144,0,0,1-11.5828.97612l-137.15177.59824a164.42512,164.42512,0,0,1-53.10641-8.5766l206.15377-.89925a25.67984,25.67984,0,0,1,14.94711,4.71833Z"
                transform="translate(0 -3)" />
          <path class="cls-p-5"
                d="M484.633,90.66171h1.723a2.09,2.09,0,0,1,2.09,2.09v3.75857a2.09015,2.09015,0,0,1-2.09015,2.09015h-1.7231a2.08993,2.08993,0,0,1-2.08993-2.08993V92.75181a2.0901,2.0901,0,0,1,2.0901-2.0901Z"
                transform="translate(971.39919 184.13251) rotate(179.74877)" />
          <path class="cls-p-5"
                d="M474.38306,90.7064h1.723a2.09,2.09,0,0,1,2.09,2.09v3.75873a2.09,2.09,0,0,1-2.09,2.09h-1.72307a2.09016,2.09016,0,0,1-2.09016-2.09016V92.79653a2.09013,2.09013,0,0,1,2.09013-2.09013Z"
                transform="translate(950.89522 184.28954) rotate(179.75151)" />
          <path class="cls-p-5"
                d="M464.133,90.75109h1.72329a2.08993,2.08993,0,0,1,2.08993,2.08993v3.75884a2.09,2.09,0,0,1-2.09,2.09h-1.723a2.09012,2.09012,0,0,1-2.09012-2.09012V92.841a2.09,2.09,0,0,1,2.09-2.09Z"
                transform="translate(930.39473 184.42816) rotate(179.75209)" />
          <path class="cls-p-5"
                d="M453.88314,90.79583h1.72307a2.09017,2.09017,0,0,1,2.09017,2.09017v3.75847a2.09013,2.09013,0,0,1-2.09013,2.09013h-1.723a2.09,2.09,0,0,1-2.09-2.09V92.88582A2.09,2.09,0,0,1,453.88314,90.79583Z"
                transform="matrix(-0.99999, 0.00439, -0.00439, -0.99999, 909.90148, 184.53155)" />
          <path class="cls-p-5"
                d="M443.63325,90.84051h1.72312a2.09,2.09,0,0,1,2.09,2.09v3.75878a2.09,2.09,0,0,1-2.09,2.09h-1.72307a2.09011,2.09011,0,0,1-2.09011-2.09011V92.93052a2.09,2.09,0,0,1,2.09-2.09Z"
                transform="translate(889.40104 184.67024) rotate(179.74881)" />
          <path class="cls-p-5"
                d="M433.38348,90.88525h1.72292a2.09011,2.09011,0,0,1,2.09011,2.09011V96.734a2.09006,2.09006,0,0,1-2.09006,2.09006h-1.723a2.09015,2.09015,0,0,1-2.09015-2.09015V92.97538a2.09013,2.09013,0,0,1,2.09013-2.09013Z"
                transform="matrix(-0.99999, 0.00434, -0.00434, -0.99999, 868.89711, 184.82535)" />
          <path class="cls-p-5"
                d="M423.1334,90.92994h1.72327a2.09,2.09,0,0,1,2.09,2.09v3.75868a2.09013,2.09013,0,0,1-2.09013,2.09013h-1.723a2.09005,2.09005,0,0,1-2.09005-2.09005V93.01992A2.09,2.09,0,0,1,423.1334,90.92994Z"
                transform="translate(848.40289 184.93486) rotate(179.74827)" />
          <path class="cls-p-5"
                d="M412.88347,90.97462h1.72322a2.09,2.09,0,0,1,2.09,2.09v3.75875a2.09,2.09,0,0,1-2.09,2.09h-1.72311a2.09,2.09,0,0,1-2.09-2.09V93.06453A2.08991,2.08991,0,0,1,412.88347,90.97462Z"
                transform="translate(827.90256 185.07327) rotate(179.74881)" />
          <path class="cls-p-5"
                d="M402.63375,91.01936h1.723a2.09,2.09,0,0,1,2.09,2.09V96.868a2.09011,2.09011,0,0,1-2.09011,2.09011h-1.723a2.09015,2.09015,0,0,1-2.09015-2.09015V93.10949A2.09013,2.09013,0,0,1,402.63375,91.01936Z"
                transform="matrix(-0.99999, 0.00434, -0.00434, -0.99999, 807.39856, 185.22691)" />
          <path class="cls-p-5"
                d="M392.38374,91.06405h1.72319a2.09,2.09,0,0,1,2.09,2.09v3.75875a2.09,2.09,0,0,1-2.09,2.09h-1.723a2.09011,2.09011,0,0,1-2.09011-2.09011V93.15406a2.09,2.09,0,0,1,2.09-2.09Z"
                transform="translate(786.90347 185.34195) rotate(179.74881)" />
          <path class="cls-p-5"
                d="M382.13382,91.10879H383.857a2.09,2.09,0,0,1,2.09,2.09v3.75859a2.09013,2.09013,0,0,1-2.09013,2.09013h-1.72305a2.09,2.09,0,0,1-2.09-2.09V93.19878a2.09,2.09,0,0,1,2.09-2.09Z"
                transform="translate(766.40492 185.47271) rotate(179.74827)" />
          <path class="cls-p-4"
                d="M382.66915,122.1142l25.76055,6.36741s29.89317,6.55558,53.3142-.48858l-.0801-18.38361c-7.32479-6.02616-79.04768.34476-79.04768.34476Z"
                transform="translate(0 -3)" />
          <path class="cls-p-6"
                d="M457.75482,108.17466a9.563,9.563,0,0,1,3.909,1.43475l.0802,18.38363c-1.28123.38532-2.58432.72294-3.89821,1.03024Z"
                transform="translate(0 -3)" />
          <path class="cls-p-1"
                d="M368.7591,128.44322l23.56052-.17206a26.57746,26.57746,0,0,0,10.0655-1.89792l52.3987-22.63979c-25.76625-2.12734-59.87959.61947-59.87959.61947Z"
                transform="translate(0 -3)" />
          <path class="cls-p-1"
                d="M270.625,97.355l14.69982.03291a20.77445,20.77445,0,0,0,7.96348-1.51174l35.87-14.9418-51.25518-.092Z"
                transform="translate(0 -3)" />
          <path class="cls-p-7"
                d="M498.52455,86.62517h4.715a5.71907,5.71907,0,0,1,5.71907,5.71907v10.28515a5.719,5.719,0,0,1-5.719,5.719h-4.715a5.71916,5.71916,0,0,1-5.71916-5.71916v-10.285A5.7191,5.7191,0,0,1,498.52455,86.62517Z"
                transform="translate(1002.18467 189.78712) rotate(179.74999)" />
          <path class="cls-p-1"
                d="M498.90813,87.69816h3.94776a5.07048,5.07048,0,0,1,5.07048,5.07048v9.4364a5.07049,5.07049,0,0,1-5.07049,5.07049h-3.94766a5.07048,5.07048,0,0,1-5.07048-5.07048v-9.4365a5.07039,5.07039,0,0,1,5.07039-5.07039Z"
                transform="translate(1002.18459 189.78788) rotate(179.75007)" />
          <path class="cls-p-7"
                d="M364.9008,87.20806h4.71506a5.71909,5.71909,0,0,1,5.71909,5.71909v10.28508a5.7191,5.7191,0,0,1-5.7191,5.7191h-4.715a5.71908,5.71908,0,0,1-5.71908-5.71908V92.92712A5.71906,5.71906,0,0,1,364.9008,87.20806Z"
                transform="translate(734.94075 191.53728) rotate(179.7502)" />
          <path class="cls-p-1"
                d="M365.28447,88.28095h3.94771a5.07048,5.07048,0,0,1,5.07048,5.07048v9.43642a5.07049,5.07049,0,0,1-5.07049,5.07049h-3.94772a5.07047,5.07047,0,0,1-5.07047-5.07047V93.35144a5.07049,5.07049,0,0,1,5.07049-5.07049Z"
                transform="matrix(-0.99999, 0.00436, -0.00436, -0.99999, 734.94094, 191.53634)" />
          <path class="cls-p-5"
                d="M538.55591,85.022l4.4371-.01934a1.09047,1.09047,0,0,1,.96916.58133l2.55614,4.84014a1.09064,1.09064,0,0,1-.95968,1.59991l-6.99708.03052a1.09068,1.09068,0,0,1-1.09537-1.09145l.00385-4.85132A1.09065,1.09065,0,0,1,538.55591,85.022Z"
                transform="translate(0 -3)" />
          <path class="cls-p-5"
                d="M536.05044,86.475l-.00312,3.94064a1.24682,1.24682,0,0,1-1.50378,1.2214c-1.80864-.3812-4.60575-.98521-4.60575-.98521a2.68911,2.68911,0,0,1-2.10344-2.61278l-.00384-.88995a2.08037,2.08037,0,0,1,2.07128-2.08938l4.70767-.02052A1.43474,1.43474,0,0,1,536.05044,86.475Z"
                transform="translate(0 -3)" />
          <path class="cls-p-5"
                d="M546.42552,84.98766l3.56327-.01552,11.11653,6.98416-10.84347.04722a2.14832,2.14832,0,0,1-1.90916-1.14508l-2.551-4.83069A.709.709,0,0,1,546.42552,84.98766Z"
                transform="translate(0 -3)" />
          <polygon class="cls-p-1"
                   points="430.345 65.655 431.594 65.649 435.457 72.004 432.247 72.018 430.345 65.655" />
          <path class="cls-p-8"
                d="M282.83012,71.17046a41.29036,41.29036,0,0,1-13.63471.3545c-6.6778-11.2275-13.3555-22.9765-20.03129-31.9824v64.583c6.67579-12.0791,13.35349-21.414,20.03129-31.2715a41.29036,41.29036,0,0,0,13.63471-.3545Zm-32.33591,28.1026v-55.418c5.8496,8.1963,11.6972,18.332,17.54691,28.2119C262.19141,80.65976,256.34381,88.99666,250.49421,99.27306Z"
                transform="translate(0 -3)" />
          <path class="cls-p-9"
                d="M253.24812,111.41856C131.57912,119.33356,121.668,98.76668,0,106.68168v-79.168c121.668-7.9199,131.57912,12.652,253.24812,4.73688Z"
                transform="translate(0 -3)" />
          <polygon class="cls-p-10"
                   points="163.967 65.761 157.707 65.761 157.707 63.221 173.113 63.221 173.113 65.761 166.851 65.761 166.851 83.357 163.967 83.357 163.967 65.761" />
          <path class="cls-p-10"
                d="M172.72143,71.64453h2.53782v2.27868h.05873a4.4751,4.4751,0,0,1,3.83574-2.62518,7.65549,7.65549,0,0,1,1.789.20115v2.424a4.02779,4.02779,0,0,0-1.49979-.25988c-2.4233,0-4.00972,2.30878-4.00972,6.00063v6.69217h-2.7118Z"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M183.03034,72.68322A9.35955,9.35955,0,0,1,187.877,71.298c4.21086,0,5.91253,2.07607,5.91253,6.08579v6.088a23.38165,23.38165,0,0,0,.11527,2.88432h-2.53783V84.45177h-.05726a5.46125,5.46125,0,0,1-4.5581,2.25005c-3.17357,0-5.395-1.38453-5.395-4.5882,0-3.69112,4.03834-4.84294,6.83679-4.84294,1.0674,0,1.81839,0,2.88433.05653,0-2.53783-.92352-3.83573-3.63386-3.83573a6.91045,6.91045,0,0,0-4.26886,1.49906Zm8.04734,6.66354c-.577,0-1.154-.05726-1.731-.05726-1.46969,0-5.10575.23051-5.10575,2.82413A2.59254,2.59254,0,0,0,186.9828,84.509c2.68171,0,4.09488-1.673,4.09488-3.8629Z"
                transform="translate(0 -3)" />
          <polygon class="cls-p-10"
                   points="203.469 83.356 200.239 83.356 194.959 68.645 197.93 68.645 201.912 80.644 201.97 80.644 206.037 68.645 208.748 68.645 203.469 83.356" />
          <path class="cls-p-10"
                d="M221.47482,85.75064a11.2689,11.2689,0,0,1-4.64546.95067c-5.221,0-7.61494-3.1721-7.61494-7.96,0-4.35622,2.74044-7.44316,6.894-7.44316,4.9046,0,6.491,3.57733,6.491,8.30795H212.09876a4.81508,4.81508,0,0,0,4.84734,4.90314,8.70819,8.70819,0,0,0,4.52872-1.46969Zm-1.75965-8.16333c0-2.25005-1.32655-4.09561-3.664-4.09561-2.65455,0-3.75056,2.19133-3.95244,4.09561Z"
                transform="translate(0 -3)" />
          <rect class="cls-p-10"
                x="225.57449"
                y="61.72155"
                width="2.71033"
                height="21.63429" />
          <path class="cls-p-10"
                d="M83.64749,56.17642H96.32193l6.411,20.07356h.0881l6.6907-20.07356h12.20167V86.366h-7.86307l.11232-23.18693h-.07708l-7.692,23.18693H98.79589L91.43642,63.17911h-.08883L91.5201,86.366H83.64749Zm-21.25989,0h9.85986L83.88828,86.366H75.06792l-2.2618-6.45064H61.48538L59.09144,86.366H50.92Zm4.93177,6.23408H67.2276L63.34341,74.21208h7.56576Zm65.703-6.23408h9.86721L154.52894,86.366h-8.82843l-2.24859-6.45064H132.12164L129.74973,86.366H121.549Zm4.93618,6.23408h-.07562l-3.90254,11.80159h7.587Z"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M57.20367,59.71073a11.10442,11.10442,0,0,1,1.16944,11.63054C53.91117,79.0399,45.52174,81.37952,37.1837,81.80236c-8.81448.05212-17.25751-2.49672-22.143-9.98393a11.16905,11.16905,0,0,1,.79651-11.41691C22.4229,51.85279,33.30832,50.20471,43.663,51.95777c5.09841,1.2223,9.98319,3.18678,13.5407,7.753"
                transform="translate(0 -3)" />
          <path class="cls-p-11"
                d="M57.20367,59.71073a11.10442,11.10442,0,0,1,1.16944,11.63054C53.91117,79.0399,45.52174,81.37952,37.1837,81.80236c-8.81448.05212-17.25751-2.49672-22.143-9.98393a11.16905,11.16905,0,0,1,.79651-11.41691C22.4229,51.85279,33.30832,50.20471,43.663,51.95777,48.76138,53.18007,53.64616,55.14455,57.20367,59.71073Z"
                transform="translate(0 -3)" />
          <path class="cls-p-12"
                d="M36.91743,82.16978c-10.18067,0-17.8514-3.50979-22.18413-10.15057a11.52758,11.52758,0,0,1,.8134-11.842c4.64326-6.02706,11.8816-9.21384,20.93247-9.21384a43.57276,43.57276,0,0,1,7.245.6328c4.376,1.04831,9.87234,2.88726,13.769,7.8895a11.4886,11.4886,0,0,1,1.20321,12.02916c-3.84454,6.63417-10.87367,10.11607-21.49407,10.65417Z"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M36.479,51.33054a43.12034,43.12034,0,0,1,7.184.62767c5.09841,1.22156,9.98319,3.18678,13.5407,7.75222A11.10441,11.10441,0,0,1,58.37318,71.341c-4.46193,7.69863-12.85137,10.039-21.18941,10.4611-.0881.00073-.17766.00073-.26575.00073-8.71831,0-17.04094-2.57159-21.87727-9.98466A11.16594,11.16594,0,0,1,15.83726,60.402c5.06758-6.57911,12.68252-9.07143,20.64176-9.07143m.00074-.73411H36.479c-9.16906,0-16.508,3.236-21.22318,9.357a11.91415,11.91415,0,0,0-.85671,12.2215l.01248.02276.01468.022C18.8295,78.96912,26.60741,82.53691,36.918,82.53691l.27015-.00073c10.787-.54545,17.91381-4.0868,21.82-10.82668a11.88507,11.88507,0,0,0-1.234-12.46082c-3.95833-5.08079-9.52951-6.94764-13.94006-8.00476l-.02423-.00514-.02422-.0044a43.91288,43.91288,0,0,0-7.30588-.63795"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M54.54941,61.9944a7.87843,7.87843,0,0,1,1.22083,6.53066c-2.28236,6.32071-9.18741,8.70877-15.24017,9.55887-7.59366.85084-15.34588-.37072-20.76289-5.57485a8.29316,8.29316,0,0,1-1.96448-8.443c3.13172-6.63858,10.35465-8.54947,16.99176-9.29313,7.7001-.21363,14.9223,1.21936,19.755,7.22146"
                transform="translate(0 -3)" />
          <path class="cls-p-13"
                d="M42.23542,65.44378l.3443.99179c-.613,1.98357-1.19,3.98476-1.7303,6.02118l3.66689-.06387,1.49025,3.38132L41.77,76.90988c-.74-1.858-1.33608-3.84161-2.00192-5.75324H32.77271c-.63134,1.80225-1.19,3.62431-1.69506,5.48088-1.40436-.14242-2.7933-.541-4.19912-.81193a29.931,29.931,0,0,1,.95508-2.90415,6.34738,6.34738,0,0,0,3.639-5.49776l-1.72957-.036L33.7065,55.617c1.71415-.03524,3.17283-.0881,4.81431-.0881Z"
                transform="translate(0 -3)" />
          <path class="cls-p-13"
                d="M53.45067,71.93611l-3.33581-.0022c-.19894-.82808-.45074-1.62165-.68566-2.43285l-4.03688-.03818c-.25327.79431-.36118,1.64074-.55939,2.471l-3.371.03671,3.85556-13.0694,3.80343.00147Z"
                transform="translate(0 -3)" />
          <path class="cls-p-13"
                d="M30.08446,61.27835a3.92773,3.92773,0,0,1,.68419,2.21629l-3.24478.03523a1.80078,1.80078,0,0,0-.59463-1.33314,2.16819,2.16819,0,0,0-1.91089-.30833c-1.98284,1.04611-1.80224,3.38867-1.63927,5.44418.21583,1.04758.59463,2.34475,1.76627,2.65088a2.34672,2.34672,0,0,0,1.69506-.39715,3.37813,3.37813,0,0,0,.81193-1.67524l3.2969.00073A5.65575,5.65575,0,0,1,26.84116,72.671a5.23381,5.23381,0,0,1-5.19311-1.6958,8.07521,8.07521,0,0,1-.95728-8.56341,6.02139,6.02139,0,0,1,4.63371-3.36958,5.54646,5.54646,0,0,1,4.76,2.2361"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M38.52338,67.73207H33.8001l2.25152-7.53566c.84643,2.50553,1.7303,4.99417,2.47176,7.53566"
                transform="translate(0 -3)" />
          <path class="cls-p-10"
                d="M46.09376,66.61666c.27089-1.33461.62987-2.74044,1.027-4.07432l1.2267,4.07432Z"
                transform="translate(0 -3)" />
          <path class="cls-p-14"
                d="M53.92439,69.61889a.75879.75879,0,1,0-.77008-.762.75879.75879,0,0,0,.77008.762m-.61886-.762a.61728.61728,0,1,1,.61886.635.61441.61441,0,0,1-.61886-.635m.45956.06167h.152l.232.3788h.14756l-.24886-.38467a.23058.23058,0,0,0,.22684-.24152c0-.17252-.102-.24813-.30906-.24813h-.33329v.87433h.13288Zm0-.11306v-.26942h.17986c.091,0,.19087.01908.19087.12774,0,.13434-.09984.14168-.21289.14168Z"
                transform="translate(0 -3)" /></svg>
      </div>
    </div>

    <div class="c-anim-plane__clouds c-anim-plane__clouds-bg c-anim-plane__clouds-bg-1">
      <svg class="cloud md"
           viewBox="0 0 76.38685 29.41295">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-2</title>
        <ellipse class="cls-1"
                 cx="14.12671"
                 cy="20.83544"
                 rx="14.12736"
                 ry="8.57694" />
        <ellipse class="cls-1"
                 cx="32.1002"
                 cy="14.70594"
                 rx="16.43358"
                 ry="14.70647" />
        <ellipse class="cls-1"
                 cx="53.9119"
                 cy="19.2874"
                 rx="11.31479"
                 ry="10.12445" />
        <ellipse class="cls-1"
                 cx="67.86142"
                 cy="24.23647"
                 rx="8.52498"
                 ry="5.17645" />
        <path class="cls-1"
              d="M12.516,29.35636l55.34493.05659-.64033-4.41644L55.13709,20.83488,35.14867,19.83456H19.66085S8.22678,22.73134,12.516,29.35636Z" />
        </svg>
      <svg class="cloud sm"
           viewBox="0 0 56.73561 24.58118">
        <defs><style>.cls-1{fill:#eaeaea;}</style></defs>
        <title>cloud-3-grey</title>
        <path class="cls-1"
              d="M23.24358,18.19295c0,3.89719-5.20506,7.0549-11.62134,7.0549C5.20326,25.24785,0,22.09014,0,18.19295c0-3.89755,5.20326-8.939,11.62224-8.939C18.03852,9.254,23.24358,14.29539,23.24358,18.19295Z"
              transform="translate(0 -0.66667)" />
        <ellipse class="cls-1"
                 cx="28.16169"
                 cy="12.00874"
                 rx="13.41859"
                 ry="12.00927" />
        <ellipse class="cls-1"
                 cx="47.93785"
                 cy="18.29502"
                 rx="8.79866"
                 ry="6.28582" />
        <path class="cls-1"
              d="M11.31254,25.19566,48.28,25.24785,45.209,21.15251l-7.48819-3.85776-12.38445-.92928h-9.5986S8.65567,19.05306,11.31254,25.19566Z"
              transform="translate(0 -0.66667)" /></svg>
    </div>
    <div class="c-anim-plane__clouds c-anim-plane__clouds-bg c-anim-plane__clouds-bg-2">
      <svg class="cloud md"
           viewBox="0 0 76.38685 29.41295">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-2</title>
        <ellipse class="cls-1"
                 cx="14.12671"
                 cy="20.83544"
                 rx="14.12736"
                 ry="8.57694" />
        <ellipse class="cls-1"
                 cx="32.1002"
                 cy="14.70594"
                 rx="16.43358"
                 ry="14.70647" />
        <ellipse class="cls-1"
                 cx="53.9119"
                 cy="19.2874"
                 rx="11.31479"
                 ry="10.12445" />
        <ellipse class="cls-1"
                 cx="67.86142"
                 cy="24.23647"
                 rx="8.52498"
                 ry="5.17645" />
        <path class="cls-1"
              d="M12.516,29.35636l55.34493.05659-.64033-4.41644L55.13709,20.83488,35.14867,19.83456H19.66085S8.22678,22.73134,12.516,29.35636Z" />
        </svg>
      <svg class="cloud sm"
           viewBox="0 0 56.73561 24.58118">
        <defs><style>.cls-1{fill:#eaeaea;}</style></defs>
        <title>cloud-3-grey</title>
        <path class="cls-1"
              d="M23.24358,18.19295c0,3.89719-5.20506,7.0549-11.62134,7.0549C5.20326,25.24785,0,22.09014,0,18.19295c0-3.89755,5.20326-8.939,11.62224-8.939C18.03852,9.254,23.24358,14.29539,23.24358,18.19295Z"
              transform="translate(0 -0.66667)" />
        <ellipse class="cls-1"
                 cx="28.16169"
                 cy="12.00874"
                 rx="13.41859"
                 ry="12.00927" />
        <ellipse class="cls-1"
                 cx="47.93785"
                 cy="18.29502"
                 rx="8.79866"
                 ry="6.28582" />
        <path class="cls-1"
              d="M11.31254,25.19566,48.28,25.24785,45.209,21.15251l-7.48819-3.85776-12.38445-.92928h-9.5986S8.65567,19.05306,11.31254,25.19566Z"
              transform="translate(0 -0.66667)" /></svg>
    </div>


    <div class="c-anim-plane__clouds c-anim-plane__clouds-fg c-anim-plane__clouds-fg-1">
      <svg class="cloud xl"
           viewBox="0 0 96.66667 46.65138">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-1</title>
        <ellipse class="cls-1"
                 cx="15.33063"
                 cy="37.3434"
                 rx="15.33125"
                 ry="9.30746" />
        <ellipse class="cls-1"
                 cx="47.27606"
                 cy="17.73345"
                 rx="19.81851"
                 ry="17.73435" />
        <ellipse class="cls-1"
                 cx="72.74032"
                 cy="34.44136"
                 rx="13.64508"
                 ry="12.20977" />
        <ellipse class="cls-1"
                 cx="21.63353"
                 cy="27.74957"
                 rx="13.04241"
                 ry="11.67015" />
        <ellipse class="cls-1"
                 cx="86.38565"
                 cy="40.40916"
                 rx="10.28076"
                 ry="6.24199" />
        <path class="cls-1"
              d="M14.24378,47.76165l72.14161.06778-.777-5.32541L71.04078,37.48593,47.2757,17.25837,28.25543,36.27813S9.07193,39.77245,14.24378,47.76165Z"
              transform="translate(0 -1.17805)" /></svg>
      <svg class="cloud lg"
           viewBox="0 0 76.38685 29.41295">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-2</title>
        <ellipse class="cls-1"
                 cx="14.12671"
                 cy="20.83544"
                 rx="14.12736"
                 ry="8.57694" />
        <ellipse class="cls-1"
                 cx="32.1002"
                 cy="14.70594"
                 rx="16.43358"
                 ry="14.70647" />
        <ellipse class="cls-1"
                 cx="53.9119"
                 cy="19.2874"
                 rx="11.31479"
                 ry="10.12445" />
        <ellipse class="cls-1"
                 cx="67.86142"
                 cy="24.23647"
                 rx="8.52498"
                 ry="5.17645" />
        <path class="cls-1"
              d="M12.516,29.35636l55.34493.05659-.64033-4.41644L55.13709,20.83488,35.14867,19.83456H19.66085S8.22678,22.73134,12.516,29.35636Z" />
        </svg>
    </div>
    <div class="c-anim-plane__clouds c-anim-plane__clouds-fg c-anim-plane__clouds-fg-2">
      <svg class="cloud xl"
           viewBox="0 0 96.66667 46.65138">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-1</title>
        <ellipse class="cls-1"
                 cx="15.33063"
                 cy="37.3434"
                 rx="15.33125"
                 ry="9.30746" />
        <ellipse class="cls-1"
                 cx="47.27606"
                 cy="17.73345"
                 rx="19.81851"
                 ry="17.73435" />
        <ellipse class="cls-1"
                 cx="72.74032"
                 cy="34.44136"
                 rx="13.64508"
                 ry="12.20977" />
        <ellipse class="cls-1"
                 cx="21.63353"
                 cy="27.74957"
                 rx="13.04241"
                 ry="11.67015" />
        <ellipse class="cls-1"
                 cx="86.38565"
                 cy="40.40916"
                 rx="10.28076"
                 ry="6.24199" />
        <path class="cls-1"
              d="M14.24378,47.76165l72.14161.06778-.777-5.32541L71.04078,37.48593,47.2757,17.25837,28.25543,36.27813S9.07193,39.77245,14.24378,47.76165Z"
              transform="translate(0 -1.17805)" /></svg>
      <svg class="cloud lg"
           viewBox="0 0 76.38685 29.41295">
        <defs><style>.cls-1{fill:#fff;}</style></defs>
        <title>cloud-2</title>
        <ellipse class="cls-1"
                 cx="14.12671"
                 cy="20.83544"
                 rx="14.12736"
                 ry="8.57694" />
        <ellipse class="cls-1"
                 cx="32.1002"
                 cy="14.70594"
                 rx="16.43358"
                 ry="14.70647" />
        <ellipse class="cls-1"
                 cx="53.9119"
                 cy="19.2874"
                 rx="11.31479"
                 ry="10.12445" />
        <ellipse class="cls-1"
                 cx="67.86142"
                 cy="24.23647"
                 rx="8.52498"
                 ry="5.17645" />
        <path class="cls-1"
              d="M12.516,29.35636l55.34493.05659-.64033-4.41644L55.13709,20.83488,35.14867,19.83456H19.66085S8.22678,22.73134,12.516,29.35636Z" />
        </svg>
    </div>

    <div class="c-anim-plane__ground c-anim-plane__ground-bg"
         style="background-image: url('https://amatravelinternal.blob.core.windows.net/onlinebooking/oab-images/background.svg');"></div>
    <div class="c-anim-plane__ground c-anim-plane__ground-fg"
         style="background-image: url('https://amatravelinternal.blob.core.windows.net/onlinebooking/oab-images/foreground.svg');"></div>

    <div class="c-anim-plane__description hide">
      <h3 class="c-anim-plane__text-pad u-display--inline">We’re finding specialized travel agents based on your needs</h3>
    </div>
  </div>
</div>