<ng-container *injector="
{
    quoteOptions: quoteOptions$,
    details: tripDetails$
};
let quoteOptions = quoteOptions;
let details = details;
">
  <form #f="ngForm"
        analytic-ama="form"
        connect="tripDetails"
        (ngSubmit)="onSubmit(f, travellerAges)"
        class="o-trip-details"
        id="trip-details">
    <div class="form-row">
      <div class="form-group col col-lg-10">
        <h2>{{'renew_trip_details.header' | translate}}</h2>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <h3 class="h5">{{'renew_trip_details.current_policy_header' | translate}}</h3>
        <span>
          {{ 'renew_policy_details.page.plan_days_format' | translate | format:details?.durationOfFirstTrip:('product_names.' + details.prevPolicyProductSelected | translate)}}
        </span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <h3 class="h5">{{'renew_trip_details.coverage_expires_header' | translate}}</h3>
        <span>{{ details?.prevPolicyExpireDate | date:'longDate':'UTC' }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <h3 class="mb-3 h5"> {{ 'trip_details.club_member.question' | translate }}</h3>
        <div class="form-check form-check--border rounded-top col-lg-10 col-xl-9">
          <input type="radio"
                 class="form-check-input"
                 id="memberRadios1"
                 ngControl
                 ngModel
                 name="isClubMember"
                 #isClubMember="ngModel"
                 [value]="true" />
          <label class="form-check-label"
                 for="memberRadios1">
            <span>
              <span innerHtml="{{ 'trip_details.club_member.yes' | translate }}"></span>
              <span *ngIf="loggedIn"
                    innerHtml="{{ 'trip_details.club_member.logged_in_with_discount' | translate }}"></span>
              <span *ngIf="!loggedIn"
                    innerHtml="{{ 'trip_details.club_member.logged_out_with_discount' | translate }}"></span>
              <span *ngIf="!loggedIn">
                <a href="javascript:void(0)"
                   linkLogin
                   innerHtml="{{ 'trip_details.club_member.login_link_text' | translate }}"></a>
                {{ 'trip_details.club_member.login_link_text_after' | translate }}
              </span>
            </span>
          </label>
        </div>
        <div class="form-check form-check--border rounded-bottom col-lg-10 col-xl-9">
          <input type="radio"
                 class="form-check-input"
                 id="memberRadios2"
                 ngControl
                 ngModel
                 name="isClubMember"
                 #isClubMember="ngModel"
                 [value]="false" />
          <label class="form-check-label"
                 for="memberRadios2"
                 innerHTML="{{ 'trip_details.club_member.no' | translate }}">
          </label>
        </div>
      </div>
    </div>

    <div class="mb-4"
         *ngIf="lastPolicyHasSeniors && !isPremiumPlan"
         [innerHTML]="'renew_trip_details.last_policy_has_seniors' | translate | safeHtml">
    </div>

    <div class="form-row">
      <div class="form-group col">
        <h3 class="h5"
            [class.mt-3]="!(lastPolicyHasSeniors && !isPremiumPlan)">{{'renew_trip_details.travellers_header' | translate}}</h3>
        <div class="row">
          <div class="col-12">
            <traveller-ages #travellerAges="travellerAges"
                            [existingTravellersAreEditable]="false" />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="details?.numberOfTravellers >= familyRatePromptActivationNumber"
         class="form-row">
      <div class="form-group col">
        <div class="alert alert-info rounded-0 text-body"
             role="alert">
          <h4 class="alert-heading">{{ 'trip_details.family_rate.prompt' | translate }}</h4>
          <p class="mb-0"
             innerHTML="{{ 'trip_details.family_rate.description' | translate}}"></p>
          <div class="form-check form-check--border d-inline-block mt-3 rounded bg-white">
            <input class="form-check-input"
                   type="checkbox"
                   value="false"
                   id="familyRate"
                   ngControl
                   ngModel
                   name='isEligibleForFamilyRate'
                   #isEligibleForFamilyRate="ngModel" />
            <label class="form-check-label"
                   for="familyRate">{{ 'trip_details.family_rate.acceptance_text' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mb-4">
      <div class="form-group col-md-7 col-lg-5">
        <h3 class="h5">{{'renew_trip_details.renew_on' | translate}}</h3>
        <div class="input-group">
          <div class="input-group__overlay-input">
            <button class="form-control border-0"
                    type="button"
                    id="btnStartDate"
                    (click)="startDateIsOpen = !startDateIsOpen"
                    [attr.aria-expanded]="startDateInput.isOpen">
              <svg-icon name="calendar" />
            </button>
          </div>
          <date-input #startDateInput="ngModel"
                      [inputClass]="{ 'is-invalid': (f.submitted && startDateInput.invalid) }"
                      [required]="true"
                      ngControl
                      [(ngModel)]="startDate"
                      name="policyStartDate"
                      [isOpen]="startDateIsOpen"
                      (onHidden)="startDateIsOpen = false"
                      [minDate]="details?.prevPolicyExpireDate | map:DateHelpers.repurchaseMinDate"
                      tabindex="-1" />
          <div *ngIf="(f.submitted && startDateInput.invalid)"
               class="invalid-feedback">
            <span *ngIf="startDateInput.errors?.required">{{ 'multi_trip.policy_start_date.required' | translate }}</span>
            <span *ngIf="startDateInput.errors?.minDate">{{ 'multi_trip.policy_start_date.min_date' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="premiumPlanUnavailable"
         class="invalid-feedback d-block mb-3">
      <span>{{ 'renew_trip_details.over_60_premium_not_valid' | translate }}</span>
    </div>

    <button type="submit"
            *ngIf="isPremiumPlan"
            id="review-policy-submit"
            class="btn btn-block btn-lg btn-secondary col-md-7"
            [ngClass]="{'btn--loading': isLoading}">
      {{'renew_trip_details.renew_policy_btn' | translate}}
    </button>
    <div *ngIf="!isPremiumPlan">
      <button type="submit"
              id="view-coverage-options-submit"
              class="btn btn-block btn-lg btn-secondary col-md-7 btn--icon-chevron-right"
              [ngClass]="{'btn--loading': isLoading}"
              *ngIf="quoteOptions?.length === 0">
        {{'renew_trip_details.view_coverage_options_btn' | translate}}
      </button>
    </div>
  </form>
</ng-container>