/* eslint-disable max-lines */
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { hasValue } from 'src/app/core/helpers/app.helpers';
import { BaseComponent } from 'src/app/components/base-component.component';

import { Location, TST_WIDGET_LABELS, AUTOCOMPLETE_ENDPOINTS } from 'src/app/models/tst/tst';
import _ from 'underscore';

import { TSTService } from 'src/app/core/services/tstService';
import { StorageService } from 'src/app/core/services/storageService';
import { Centre } from 'src/app/models/tst/centre';
import { Unsubscribe } from 'amaweb-tsutils';
const ENDPOINTS = AUTOCOMPLETE_ENDPOINTS;

@Unsubscribe()
@Component({
  selector: 'tst-autocomplete',
  styleUrls: ['tstAutocomplete.scss'],
  templateUrl: 'tstAutocomplete.template.html',
})
export class TSTAutoCompleteComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  public TST_WIDGET_LABELS = TST_WIDGET_LABELS;
  /** states */
  @Output() public selectedLocation = new EventEmitter<any>();
  @Input() public locationState: string; // 'begin'|'live'|'bypassed'|'removed'|cancelled
  @Input() public tabClickedState;
  @Input()
  public destinationFieldWasCleared: boolean;
  @Input() public updatedLocation;
  public hasSelectedItem: boolean;
  public closeClicked;
  public amaCenter: Centre;
  public destinationForm: FormGroup;
  public destination: FormControl;
  public locations: Location[];
  public cachedLocations;
  public cruiseCachedResults;
  public prepackagedCachedResults;
  public defaultCity;
  public filteredLocations: Observable<Location[]>;
  public typingCount;
  public increase: boolean;
  public enableActivities: boolean = true;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  public autoCompleteInput: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput') public acInputEl: ElementRef;
  @ViewChild('auto') public auto: MatAutocomplete;

  constructor(private tstService: TSTService, private storageService: StorageService) {
    super();

    this.hasSelectedItem = false;

    this.typingCount = 0;
    this.increase = false;
    this.closeClicked = false;
    this.destination = new FormControl('');
    this.destinationForm = new FormGroup({
      destination: this.destination,
    });
    this.locations = [];
    this.cachedLocations = this.storageService.session.get('AllLocations:CachedResults');
    /* this.locations = this.cachedLocations ? this.cachedLocations.data : []; */

    this.cruiseCachedResults = this.storageService.session.get('CruiseDestinations:CachedResults');
    this.prepackagedCachedResults = this.storageService.session.get('PrepackedDestinations:CachedResults');
  }

  public ngOnInit() {
    this.filteredLocations = this.destination.valueChanges.pipe(
      startWith<string | Location>(''),
      map((value) => (typeof value === 'string' ? value : hasValue(value) ? value.name : '')),
      debounceTime(440),
      distinctUntilChanged(),
      map((name) => (name ? this._filter(name) : this.locations.slice()))
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.locationState && this.locationState == 'removed') ||
      (changes.destinationFieldWasCleared && this.destinationFieldWasCleared)
    ) {
      this.resetAutocomplete();
    }

    this.typingCount = this.destination.value && this.destination.value.name ? this.destination.value.name.length : 0;
    if (changes.updatedLocation && changes.updatedLocation.currentValue) {
      this.destination.setValue(new Location(this.updatedLocation.name, this.updatedLocation.types));
    }
  }

  public ngAfterViewInit() {
    this.auto.showPanel = false;
  }

  private initialApiCalls() {
    /**
     * get destinations that don't need an initial input from user
     */
    // prepackaged api call
    let cacheError = this.prepackagedCachedResults && this.prepackagedCachedResults.data.level;
    !cacheError &&
    this.prepackagedCachedResults &&
    this.prepackagedCachedResults.departCity == this.defaultCity &&
    new Date(this.prepackagedCachedResults.expiresAt).getTime() > new Date().getTime()
      ? this.processInitialLocationResults(
          this.prepackagedCachedResults.data,
          ENDPOINTS[0] // tslint:disable-next-line:indent
        )
      : this.using(
          this.tstService.getDestinationsForPrepackaged(this.defaultCity, ENDPOINTS[0]).subscribe((cities) => {
            if (hasValue(cities)) {
              this.storageService.session.put('PrepackedDestinations:CachedResults', {
                departCity: this.defaultCity,
                expiresAt: new Date().setDate(new Date().getDate() + 1),
                data: cities,
              });
              this.processInitialLocationResults(cities, ENDPOINTS[0]);
            }

            /* //for testing duplicates
						const testDuplicates = [
						{ name: 'All Europe' },
						{ name: 'All Countries' },
						{ name: 'yo Countries' },
						{ name: 'Some Countries' },
					];
					this.processInitialLocationResults(testDuplicates, 'aType'); */
          }) // tslint:disable-next-line:indent
        ).attach(this);
    // cruise api call
    // as of nov6/18 this api call no longer works ** fixed nov 7
    // qa call jsonp callback only works on initial call, subsequent calls are cached by tst, therefore failing
    cacheError = this.cruiseCachedResults && this.cruiseCachedResults.data.level;
    !cacheError &&
    this.cruiseCachedResults &&
    this.cruiseCachedResults.departCity == this.defaultCity &&
    new Date(this.cruiseCachedResults.expiresAt).getTime() > new Date().getTime()
      ? this.processCruiseLocationResults(this.cruiseCachedResults.data)
      : this.using(
          this.tstService.getDestinationsForCruises(ENDPOINTS[4], 2, this.defaultCity).subscribe((places) => {
            if (hasValue(places)) {
              this.storageService.session.put('CruiseDestinations:CachedResults', {
                departCity: this.defaultCity,
                expiresAt: new Date().setDate(new Date().getDate() + 1),
                data: places,
              });
              this.processCruiseLocationResults(places);
            }
          }) // tslint:disable-next-line:indent
        ).attach(this);
  }

  public displayFn(location?: Location): string | undefined {
    /** Function that maps an option's control value to its display value in the trigger. */
    return location ? location.name : undefined;
  }

  private _filter(name: string = null): Location[] {
    const filterValue = name ? name.toLowerCase() : '';

    return this.locations && this.locations.filter((loc) => loc.name && loc.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public scrollWindowToTop(event) {
    const type = event.type;
    const target = event.target || event.srcElement || event.currentTarget;

    if (this.acInputEl.nativeElement === target) {
      if (type === 'focus' || type === 'keyup') {
        this.autoCompleteTyping(event);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
    }
  }

  public autoCompleteTyping(event) {
    /**
     * get destinations that use need autocomplete and need to send
     */
    const target = event.target;
    const value = target.value;
    this.increase = this.typingCount < value.length ? true : false;
    this.typingCount = value.length;
    this.closeClicked = false;
    const acPanel = document.getElementById(this.auto.id);
    this.typingCount > 2 && acPanel ? acPanel.classList.add('above3Chars') : acPanel && acPanel.classList.remove('above3Chars');
    if (this.increase) {
      if (this.typingCount > 2) {
        this.initialApiCalls();
        // hotels api call
        this.using(
          this.tstService.getDestinationsForGeneric(ENDPOINTS[2], event.target.value).subscribe((places) => {
            if (hasValue(places) && places.cities) {
              const _places = _.map(places.cities, (i: any) => {
                return i.label;
              });
              this.processInitialLocationResults(_places, ENDPOINTS[2], false);
            }
          })
        ).attach(this);
        // flights api call
        this.using(
          this.tstService.getDestinationsForGeneric(ENDPOINTS[1], event.target.value).subscribe((places) => {
            if (hasValue(places) && places.airports) {
              const _places = _.map(places.airports, (i: any) => {
                return i.label;
              });
              this.processInitialLocationResults(_places, ENDPOINTS[1], false);
            }
          })
        ).attach(this);
      }

      /**
       * JSONp callbacks is not implemented on TST's activities server, gives CORS errors on QA. commenting out for now.
       */

      if (value.length > 3 && this.enableActivities) {
        // activities api call
        this.using(
          this.tstService.getDestinationsForActivities(ENDPOINTS[6], event.target.value).subscribe((places) => {
            if (hasValue(places)) {
              if (hasValue(places.activities)) {
                const _places = _.map(places.activities, (a: any) => {
                  return a;
                });
                this.processActivityLocationResults(_places);
              }
              if (hasValue(places.locations2 && places.locations2.length)) {
                _.each(places.locations2, (loc: any) => {
                  this.processActivityLocationResults(loc);
                });
              }
            }
          })
        ).attach(this);
      }
    }
  }

  private processInitialLocationResults(places, endpoint, normalize: boolean = true) {
    let loc_names;
    let places_names;
    if (!this.locations.length) {
      // first time (prepackaged for now)
      _.each(places, (place: any) => {
        this.addLocation(place.name, endpoint);
      });
    } else {
      /* process duplicates/unique*/
      // need flat arrays to compare
      places_names = normalize
        ? (places_names = _.map(places, (i: any) => {
            return i.name;
            // tslint:disable-next-line:indent
          }))
        : places;
      loc_names = _.map(this.locations, (i: any) => {
        return i.name;
      });
      const unique = _.filter(places_names, function (val) {
        return loc_names.indexOf(val) < 0;
      });
      const duplicates = _.filter(places_names, function (val) {
        return loc_names.indexOf(val) >= 0;
      });
      _.each(unique, (u) => {
        const newLoc = this.addLocation(u, endpoint);
        endpoint.secondaryType && newLoc.addSecondaryType(endpoint.secondaryType);
      });
      _.each(duplicates, (d) => {
        const updatedLoc = this.updateLocation(d, endpoint);
      });

      this.storageService.session.put('AllLocations:CachedResults', {
        expiresAt: new Date().setDate(new Date().getDate() + 1),
        data: this.locations,
      });
    }
  }

  private processActivityLocationResults(places) {
    /** check for duplicates and add destId to location object */
    _.each(places, (place: any) => {
      let count = 0;
      _.each(this.locations, (loc: any) => {
        if (loc.name == place.label) {
          count++;
        }
      });
      if (count == 0) {
        this.addLocation(place.label, ENDPOINTS[5], place.destinationId, place.productId);
      }
    });
  }

  private processCruiseLocationResults(places) {
    const dest_keys = [];
    if (places.destination.length) {
      _.each(places.destination, (d) => {
        dest_keys.push(Object.keys(d)[0]);
      });
      this.processInitialLocationResults(dest_keys, ENDPOINTS[4], false);
    }
    const dep_keys = [];
    if (places.departurePort.length) {
      _.each(places.departurePort, (d) => {
        dep_keys.push(Object.keys(d)[0]);
      });
      this.processInitialLocationResults(dep_keys, ENDPOINTS[4], false);
    }
  }

  private addLocation(item, endpoint, destid: number = 0, prodid: string = '') {
    const loc = new Location(item, endpoint.type, destid, prodid, endpoint.suggestedTypes);
    /* types[0].length && Array.isArray(types[0])
			? new Location(item, types[0], destid, prodid)
			: new Location(item, types, destid, prodid); */
    this.locations.push(loc);
    return loc;
  }

  private updateLocation(item, endpoint) {
    const location: Location = _.find(this.locations, (loc) => {
      return loc.name == item;
    });
    if (location.types.length) {
      location.push(location.types);
    }
    location.push([endpoint.name]);
    endpoint.suggestedTypes && location.pushSuggestedTypes(endpoint.suggestedTypes);
    return location;
  }

  public clearInput(event) {
    this.closeClicked = true;
    this.resetAutocomplete();
  }

  public locationSelected(event) {
    this.hasSelectedItem = true;
    this.typingCount = this.destination.value ? this.destination.value.name.length : 0;
    this.selectedLocation.emit(event.option.value);
  }

  private resetAutocomplete() {
    this.locationState = 'begin';
    const loc = new Location(null, null);
    this.increase = false;
    this.hasSelectedItem = false;
    const fc = this.destinationForm.get('destination') as FormControl;
    this.destinationFieldWasCleared && this.displayFn(loc);
    fc.reset();
    this.selectedLocation.emit(loc);
    this.typingCount = this.destination.value ? this.destination.value.name.length : 0;
    this.autoCompleteInput.closePanel();
  }
}
