import { compose, combineReducers, Middleware } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux-ivy/form';
import { provideReduxForms } from '@angular-redux-ivy/form';
import { IAppState } from './models/data.interfaces';
import {
  tripDetailsQuoteOptionReducer,
  policyReducer,
  travellerInfoReducer,
  wholeAppStateReducer,
  quoteIdReducer,
  lastServerErrorReducer,
  lastPolicyReducer,
  appActionReducer,
} from './store/reducer';
import { tripDetailsReducer } from './components/coverage-type/tripdetails.reducer';
import { saveState, loadState } from './store/local-storage.middleware';
import { throttle } from 'lodash-es';
import { currentQuoteDetailsReducer } from './store/current-quote-details.reducer';
import { environment } from 'src/environments/environment';
import { GetAppInitialState } from './store/initial-states';
import { medicalQuestionnairesReducer } from './store/medical-questionnaire.reducer';
import { NgRedux, DevToolsExtension } from '@angular-redux-ivy/store';
import { TripDetailsQuoteOptionRetrievalMiddleware } from './store/trip-details-quote-option-retrieval.middleware';

export const initializeStore = (
  store: NgRedux<IAppState>,
  devTools: DevToolsExtension,
  tripDetailsQuoteOptionRetrievalMiddleware: TripDetailsQuoteOptionRetrievalMiddleware,
  testMode: boolean
) => {
  // Create the store manually with the wrapped reducer, initial app state, and dev tools enhancer.
  const enhancers: any = [];

  // To improve performance of the dev tools we add a latency and
  // reduce the max number of history elements.
  if (devTools.isEnabled()) {
    enhancers.push(
      devTools.enhancer({
        latency: 1000,
        maxAge: 10,
      })
    );
  }

  const reducers = composeReducers<IAppState>(
    defaultFormReducer(),
    wholeAppStateReducer(),
    appActionReducer(),
    combineReducers({
      quoteID: quoteIdReducer,
      policyInfo: policyReducer,
      tripDetails: tripDetailsReducer,
      travellerInfo: travellerInfoReducer,
      quoteOptions: tripDetailsQuoteOptionReducer,
      currentQuoteDetails: currentQuoteDetailsReducer,
      lastServerError: lastServerErrorReducer,
      medicalQuestionnaires: medicalQuestionnairesReducer,
      lastPolicy: lastPolicyReducer,
    })
  );

  const middleware: Middleware[] = [tripDetailsQuoteOptionRetrievalMiddleware.middleware];

  // Local storage is disabled on production environments
  let initialState: IAppState = GetAppInitialState();
  if (!environment.production && !testMode) {
    initialState = loadState() || GetAppInitialState();
  }
  store.configureStore(reducers, initialState, middleware, [compose(...enhancers)]);

  // Local storage is disabled on production environments
  if (!environment.production || testMode) {
    store.subscribe(
      throttle(() => {
        const currState = store.getState();
        // Saving known data points for the Travel Insurance Quote
        // App UI State and route state are being omitted on purpose.
        saveState({
          quoteID: currState.quoteID,
          policyInfo: currState.policyInfo,
          tripDetails: currState.tripDetails,
          travellerInfo: currState.travellerInfo,
          quoteOptions: currState.quoteOptions,
          currentQuoteDetails: currState.currentQuoteDetails,
          medicalQuestionnaires: currState.medicalQuestionnaires,
          lastPolicy: currState.lastPolicy,
        } as IAppState);
      }, 1000)
    );
  }
  // Enable syncing of Angular form state with our Redux store.
  provideReduxForms(store);
};
