<form [formGroup]="destinationForm"
	  class="tst-ac__form container"
	  [ngClass]="{hideTabs: tabClickedState && locationState == 'live'}">
	<div class="row">
		<div class="col-sm-12">
			<label class="tst-ac__label"
				   [ngClass]="{selectedLocation: hasSelectedItem}">
				<span *ngIf="hasSelectedItem">You are going to</span>
				<span *ngIf="!hasSelectedItem">Search Travel Destinations</span>
			</label>
			<div class="tst-ac__form-field">
				<mat-form-field class="tst-ac__mat">
					<input #autoCompleteInput
						   matInput
						   #trigger="matAutocompleteTrigger"
						   (input)="autoCompleteTyping($event)"
						   [matAutocomplete]="auto"
						   [formControl]="destination"
						   type="text"
						   placeholder="City, country or place"
						   (focus)="scrollWindowToTop($event);"
						   (keyup)="scrollWindowToTop($event)" />
					<mat-autocomplete #auto="matAutocomplete"
									  [displayWith]="displayFn"
									  disableRipple
									  (optionSelected)="locationSelected($event)">
						<mat-option *ngFor="let location of filteredLocations | async"
									[value]="location"
									class="tst-ac__mat-options">
							<span>{{location.name}}</span>
							<!--shows valid tst product icons for locations/not approved yet-->
							<!-- <span *ngFor="let type of location.types; let i=index" [class]="'tst-ac__mat-type tstIcon u-text-smallest ' + type">
								<ng-container *ngTemplateOutlet="iconTemplates; context: { type: type }"></ng-container>
							</span>
							<ng-container *ngIf="location.suggestedTypes?.length">
								<span *ngFor="let type of location.suggestedTypes; let i=index" [class]="'tst-ac__mat-type tstIcon u-text-smallest ' + type">
									<ng-container *ngTemplateOutlet="iconTemplates; context: { type: type }"></ng-container>
								</span>
							</ng-container> -->
						</mat-option>
					</mat-autocomplete>
					<div class="tst-ac__marker">
						<svg class="c-icon c-icon--tstIcon">
							<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--marker"></use>
						</svg>
					</div>

				</mat-form-field>
				<div class="tst-ac_close"
					 (click)="clearInput($event)"
					 [hidden]="!typingCount && !hasSelectedItem">
					<fa-icon [icon]="['fal', 'times']"
							 size="1x" />
				</div>
			</div>
		</div>
	</div>
</form>

<!-- custom tst icons templates -->
<ng-template #iconTemplates
			 let-type="type">
	<ng-container *ngIf="type == 'prepackaged'">
		<svg class="c-icon c-icon--tstIcon u-text-color-blue-navy">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--vacations"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'vacation'">
		<svg class="c-icon c-icon--tstIcon u-text-color-blue-royal">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--vacations"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'hotel'">
		<svg class="c-icon c-icon--tstIcon u-text-color-blue-teal">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--hotels"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'flight'">
		<svg class="c-icon c-icon--tstIcon u-text-color-red">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--flights"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'car'">
		<svg class="c-icon c-icon--tstIcon u-text-color-orange">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cars"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'cruise'">
		<svg class="c-icon c-icon--tstIcon u-text-color-insurance-cyan--dark">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cruise"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'activity'">
		<svg class="c-icon c-icon--tstIcon u-text-color-rewards-green">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--activities"></use>
		</svg>
	</ng-container>
</ng-template>
<!-- / end tst icon templates -->