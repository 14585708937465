import { Injectable } from '@angular/core';
import { ClubConfigurationService } from '../core/services/club-configuration.service';
import { NgRedux } from '@angular-redux-ivy/store';
import { IAppState, ITravellerInfo, ITripDetails, ITravellerAge, ITraveller } from '../models/data.interfaces';
import { SalesFunnelActionType } from './reducer';
import { cloneDeep } from 'lodash-es';
import { GetTravellerAgeInitialState, GetTravellerInitialState } from './initial-states';

export interface IMultiStateUpdateAction {
  type: SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS;
  payload: IMultiStateUpdatePayload;
}

export interface IMultiStateUpdatePayload {
  travellerInfoState: ITravellerInfo;
  tripDetailsState: ITripDetails;
  indexOfRemovedTraveller: number;
}

@Injectable()
export class TripDetailsActionCreatorService {
  public readonly familyRatePromptActivationNumber: number;
  public readonly maxNumberOfTravellers: number;

  constructor(private readonly clubConfigurationService: ClubConfigurationService, private readonly reduxStore: NgRedux<IAppState>) {
    this.familyRatePromptActivationNumber = this.clubConfigurationService.appConfig.settings.family_rate_prompt_activation_number;
    this.maxNumberOfTravellers = this.clubConfigurationService.appConfig.settings.maximum_number_of_travellers;
  }

  public updateNumberOfTravellers(newNumberOfTravellers: number): IMultiStateUpdateAction {
    // Restrict the number of travellers
    if (newNumberOfTravellers <= 1) {
      newNumberOfTravellers = 1;
    } else if (newNumberOfTravellers > this.maxNumberOfTravellers) {
      newNumberOfTravellers = this.maxNumberOfTravellers;
    }

    // Restrict the number of other travellers
    let newNumberOfOthers = newNumberOfTravellers - 1;
    if (newNumberOfOthers < 1) {
      newNumberOfOthers = 0;
    }

    const state = this.reduxStore.getState();
    let ages = cloneDeep(state.tripDetails.agesOfTravellers || []);
    let others = cloneDeep(state.travellerInfo.otherTravellers || []);

    // Delete
    if (ages.length > newNumberOfTravellers) {
      ages = ages.slice(0, newNumberOfTravellers);
    }
    if (others.length > newNumberOfOthers) {
      others = others.slice(0, newNumberOfOthers);
    }

    // Add
    if (ages.length < newNumberOfTravellers) {
      const newAges = new Array<ITravellerAge>(newNumberOfTravellers - ages.length);
      ages = ages.concat(newAges.fill(GetTravellerAgeInitialState()));
    }
    if (others.length < newNumberOfOthers) {
      const newOthers = new Array<ITraveller>(newNumberOfOthers - others.length);
      others = others.concat(newOthers.fill(GetTravellerInitialState()));
    }

    return {
      type: SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS,
      payload: {
        travellerInfoState: {
          ...state.travellerInfo,
          otherTravellers: others,
        },
        tripDetailsState: {
          ...state.tripDetails,
          numberOfTravellers: newNumberOfTravellers,
          agesOfTravellers: ages,
        },
        indexOfRemovedTraveller: -1,
      },
    } as IMultiStateUpdateAction;
  }

  public removeTravellerByIndex(indexToRemove: number) {
    const state = this.reduxStore.getState();
    const ages = cloneDeep(state.tripDetails.agesOfTravellers || []);
    const others = cloneDeep(state.travellerInfo.otherTravellers || []);

    // Make sure the index is properly set
    if (indexToRemove < 0 || indexToRemove > state.tripDetails.numberOfTravellers) {
      return { type: SalesFunnelActionType.UNDEFINED };
    }
    // You cannot delete the last traveller
    if (state.tripDetails.numberOfTravellers <= 1) {
      return { type: SalesFunnelActionType.UNDEFINED };
    }

    // Ages
    if (ages.length > indexToRemove) {
      ages.splice(indexToRemove, 1);
    }

    // Others
    let newPrimary = { ...GetTravellerInitialState(), ...state.travellerInfo };
    if (indexToRemove === 0) {
      if (others.length < 1) {
        // We need to promote one of the other travellers to the new primary.
        return { type: SalesFunnelActionType.UNDEFINED };
      }
      newPrimary = others.splice(0, 1)[0];
    } else if (others.length > indexToRemove - 1) {
      others.splice(indexToRemove - 1, 1);
    }

    return {
      type: SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS,
      payload: {
        travellerInfoState: {
          ...newPrimary,
          otherTravellers: others,
        },
        tripDetailsState: {
          ...state.tripDetails,
          numberOfTravellers: state.tripDetails.numberOfTravellers - 1,
          agesOfTravellers: ages,
        },
        indexOfRemovedTraveller: indexToRemove,
      },
    } as IMultiStateUpdateAction;
  }
}
