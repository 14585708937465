<div class="o-sign-in-modal">
  <div class="modal-header pb-0 bg-white text-dark border-0">
    <h1 class="modal-title h2 mt-4 my-sm-2">{{ 'sign_in_modal.title' | translate }}</h1>
    <p class="h5">{{ 'sign_in_modal.complete_quote' | translate }}</p>
    <div class="media o-sign-in-modal__discount">
      <figure class="mb-0 mr-2 text-success">
        <svg-icon name="member-save" />
      </figure>
      <div class="media-body align-self-center">
        <p class="my-0 text-success">{{ 'sign_in_modal.member_discount' | translate }}</p>
      </div>
    </div>
    <button type="button"
            class="u-close ga-close--sign-in-modal"
            analytic-ama="form*button*close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-md-9">
        <form #f="ngForm"
              (ngSubmit)="onSubmit(f)"
              class="ga-sign-in-modal__form"
              analytic-ama="form">
          <div class="row">
            <div class="col-12">
              <div class="ga-sign-in-modal__email-field form-group">
                <label class="h6 font-weight-bold mt-3"
                       for="email">{{ 'sign_in_modal.email.prompt' | translate }}</label>
                <input type="email"
                       id="email"
                       analytic-ama="form*input*email"
                       class="ga-sign-in-modal__email-input form-control"
                       [(ngModel)]="email"
                       name="email"
                       ngControl
                       required
                       email
                       #emailAddress="ngModel"
                       [ngClass]="{ 'is-invalid': emailAddress.invalid && f.submitted }" />
                <div *ngIf="emailAddress.invalid && f.submitted"
                     class="invalid-feedback">
                  <span *ngIf="emailAddress.errors?.required">{{ 'sign_in_modal.email.required' | translate }}</span>
                  <span *ngIf="emailAddress.errors?.email">{{ 'sign_in_modal.email.invalid' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="ga-sign-in-modal__pw-field form-group">
                <label class="h6 font-weight-bold mt-3"
                       for="password">{{ 'sign_in_modal.password.prompt' | translate }}</label>
                <input type="password"
                       id="password"
                       analytic-ama="form*input*pw"
                       class="ga-sign-in-modal__pw-input form-control"
                       [(ngModel)]="password"
                       name="password"
                       ngControl
                       required
                       #userPassword="ngModel"
                       [ngClass]="{ 'is-invalid': userPassword.invalid && f.submitted }" />
                <div *ngIf="userPassword.invalid && f.submitted"
                     class="invalid-feedback">
                  <span *ngIf="userPassword.errors?.required">{{ 'sign_in_modal.password.required' | translate }}</span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <a href="{{ 'sign_in_modal.forgot_password_link' | translate }}"
                 analytic-ama="form*link*forgot-pw"
                 class="ga-sign-in-modal__forgot-pw">{{ 'sign_in_modal.forgot_password_prompt' | translate }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-4">
              <span class="font-weight-bold text-danger">{{errorMessage}}</span>
              <button type="submit"
                      class="ga-sign-in-modal__submit-btn btn btn-block btn-secondary btn-lg"
                      analytic-ama="form*submit*modal-login-in"
                      [disabled]="isBusy">
                {{ 'sign_in_modal.submit_button' | translate }}
              </button>
            </div>
            <div class="col-12 text-center">
              {{ 'sign_in_modal.register_prompt' | translate }} <a href="{{ 'sign_in_modal.register_link' | translate }}"
                 class="ga-sign-in-modal__register-link"
                 analytic-ama="form*link*register">
                {{ 'sign_in_modal.register_link_text' | translate }}</a>
            </div>
            <div class="col-12 my-4">
              <button type="button"
                      class="ga-sign-in-modal__guest-link btn btn-block btn-outline-secondary btn-lg"
                      analytic-ama="form*link*guest"
                      (click)="hideModal()">{{ 'sign_in_modal.continue_as_guest' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
