import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../base-component.component';

@Component({
  selector: 'discount-modal',
  templateUrl: './discount-modal.component.html',
})
export class DiscountModalComponent extends BaseComponent {
  public iconSrc: string;
  public title: string;
  public subtitle: string;
  public description: string;
  public cta: string;
  public ctaLink: string;
  public footnote: string;
  constructor(public readonly bsModalRef: BsModalRef) {
    super();
  }
}
