<ng-container *ngIf="!xsMax; else mobileSaveQuote">
    <div class="btn-group ml-2"
         role="group">
        <button class="btn btn-outline-secondary btn-lg btn--icon-svg"
                id="save-quote"
                type="button"
                [disabled]="disabled"
                (click)="initiateSaveQuote()">
            <svg-icon name="upload" />
            <span class="px-1">{{ 'save_quote.prompt' | translate }}</span>
        </button>
    </div>
</ng-container>
<ng-template #mobileSaveQuote>
    <button class="d-flex align-items-center btn btn-link"
            id="save-quote"
            type="button"
            (click)="initiateSaveQuote()">
        <span class="pr-2">
            <svg-icon name="upload"
                      class="d-flex" />
        </span>
        <span>
            <a>{{ 'save_quote.prompt_anchor' | translate }}</a>
        </span>
    </button>
</ng-template>