import { Component, OnInit } from '@angular/core';
import { CountriesService } from 'src/app/core/services/countries.service';
import { ISubRegion } from 'src/app/models/country.model';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { IClubWebsiteMapping, ProductGroup, IAppState } from 'src/app/models/data.interfaces';
import { WindowRefService } from 'src/app/core/services/window-ref.service';
import { UserService } from 'src/app/core/services/user.service';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { NgRedux } from '@angular-redux-ivy/store';
import { GoogleAnalyticsService } from 'src/app/core/services/google.analytics.service';
import { Router } from '@angular/router';
import { setQuoteOptions } from 'src/app/store/trip-details-quote-option-retrieval.middleware';
import { updateCurrentQuoteDeductible } from 'src/app/store/current-quote-details.reducer';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'select-province-modal',
  templateUrl: './select-province-modal.component.html',
})
export class SelectProvinceModalComponent implements OnInit {
  public readonly provinces: ISubRegion[];
  public readonly provinceCode: string;

  public readonly minDepartureDate: Date = new Date();

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly countryService: CountriesService,
    private readonly clubConfigService: ClubConfigurationService,
    private readonly windowService: WindowRefService,
    private readonly userService: UserService,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly router: Router
  ) {
    this.provinceCode = this.clubConfigService.appConfig.settings.supported_province_codes[0];
    this.provinces = this.countryService.getCanada().subRegions;
  }

  public ngOnInit() {
    this.googleAnalyticsService.setAndSendModalView('select-province', 'Select Province');
  }

  public provinceSelected(provinceCode: string): void {
    // If they tell us they are from a supported province, then do nothing and just close.
    // Really, this modal shouldn't ever appear if they ARE from a supported province, but
    // this check is here just in case.
    if (this.clubConfigService.isProvinceCodeSupported(provinceCode)) {
      this.userService.setUserDeclaredProvinceCode(provinceCode);
      this.bsModalRef.hide();
      return;
    }

    // Try to find a club website mapping from our club configuration service.
    const clubWebsite: IClubWebsiteMapping = this.clubConfigService.getClubWebsite(provinceCode);

    if (clubWebsite == null) {
      this.bsModalRef.hide();
      return;
    }

    this.windowService.navigateTo(clubWebsite.url);
  }

  public selectVisitorToCanadaProductFlow() {
    // Bug Fix:
    // Found on: August 22, 2019
    // The out of province modal was only showing in the /coverage page
    // prior to another bug fix. Now that it can show up at any step in the funnel
    // we introduced another bug where you can change your product group after already making a selection.
    // Example:
    // 1. Get to the traveller info screen in the single trip flow and then login.
    // 2. Select the visitors to canada option.
    // 3. You are now in a different product group with an option that is invalid.
    this.bsModalRef.hide();

    const state = this.reduxStore.getState();
    if (state.tripDetails.productGroupFlow !== ProductGroup.VisitorsToCanadaFlow) {
      // Reset the quote options and current deductible
      this.reduxStore.dispatch(setQuoteOptions([]));
      this.reduxStore.dispatch(updateCurrentQuoteDeductible(null));

      // Finally update the selected product group
      this.reduxStore.dispatch({
        type: SalesFunnelActionType.UPDATE_SELECTED_PRODUCT_GROUP,
        tripDetailsState: { productGroupFlow: ProductGroup.VisitorsToCanadaFlow },
      });

      // Make sure the modal doesn't show up again
      this.userService.setUserIsVisitorToCanada();

      // Go back to the coverage page
      this.router.navigate(['/coverage'], { replaceUrl: true });
    }
  }
}
