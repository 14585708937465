import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  constructor(private httpClient: HttpClient) {}

  public validateMembership(membershipId: string, postalCode: string): Observable<boolean> {
    const params = {
      postalCode,
    };
    return this.httpClient.get<boolean>(`${environment.baseApiUrl}/membership/${membershipId}`, { params });
  }
}
