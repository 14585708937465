import { Component } from '@angular/core';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { IAppConfigSettings } from 'src/app/models/app-config.model';
import { DiscountModalComponent } from './discount-info-modal/discount-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'amaweb-tsutils';
import { UrlSigningService } from 'src/app/core/services/url-signing.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';

@Unsubscribe()
@Component({
  selector: 'member-join-widget',
  templateUrl: './member-join-widget.component.html',
})
export class MemberJoinWidgetComponent {
  appConfigSetting: IAppConfigSettings;
  constructor(
    private readonly clubConfigurationService: ClubConfigurationService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private urlSigningService: UrlSigningService
  ) {
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;
  }

  showDiscountModal(textKeys: string[], imgPath: string, ctaLink: string) {
    this.translateService
      .get(textKeys)
      .subscribe((values: string[]) => {
        this.modalService.show(DiscountModalComponent, {
          class: 'discount-modal-dialog',
          ignoreBackdropClick: false,
          initialState: {
            title: values[textKeys[0]],
            subtitle: values[textKeys[1]],
            description: values[textKeys[2]],
            cta: values[textKeys[3]],
            footnote: values[textKeys[4]],
            iconSrc: imgPath,
            ctaLink: ctaLink,
          },
        });
      })
      .attach(this);
  }

  showTravelDiscountModal() {
    const textKeys = [
      'memberjoin.discounts.travel.title',
      'memberjoin.discounts.travel.subtitle',
      'memberjoin.discounts.travel.description',
      'memberjoin.discounts.travel.cta',
      'memberjoin.discounts.travel.footnote',
    ];
    this.showDiscountModal(textKeys, 'assets/images/financial-support-hand-180x180.png', 'https://www.amatravel.ca');
  }

  showMembershipDiscountModal() {
    const textKeys = [
      'memberjoin.discounts.membership.title',
      'memberjoin.discounts.membership.subtitle',
      'memberjoin.discounts.membership.description',
      'memberjoin.discounts.membership.cta',
      'memberjoin.discounts.membership.footnote',
    ];
    this.showDiscountModal(textKeys, 'assets/images/exclusive-ama-member-service-180x180.png', 'https://ama.ab.ca/join');
  }

  memberJoinClickHandler(e: Event) {
    if (this.appConfigSetting.join_ama_link_requires_signing) {
      // Prevent the anchor link from executing
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      // Instead open a properly signed URL.
      this.urlSigningService
        .signUrl(this.appConfigSetting.join_ama_link)
        .subscribe((signedUrl) => {
          window.open(signedUrl);
        })
        .attach(this);
    }
  }
}
