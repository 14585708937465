export interface IGaProduct {
  id: string;
  name: string;
  // category: travel insurance/[funnel-single, multi-trip, tci, visitors-to-canada]/product-name
  category: string;
  brand: string; // as per design always 'travel',
  quantity: number;
  // variant: [Onetime, Annual or Renewal]
  variant: string;
  price: number;
  coupon: string; // as per design ALWAYS blank
  dimension5: string;
  dimension6: string;
}

export interface IGaPurchase {
  id: string;
  affiliation: string; // as per design always 'orion'
  revenue: number;
  tax: number;
  shipping: string; // as per design always ''(blank)
  // coupon:  one of “Member, Family, Deductible, Companion, PromoCode, Price”
  coupon: string;
}

export enum IGaUserCookieMemberStatus {
  member = 'member',
  notMember = 'non member',
  enteredNumberManually = 'entered',
  unknown = 'unknown',
}

export interface IGaUserCookie {
  memberStatus: IGaUserCookieMemberStatus;
  isLoggedIn: boolean;
}
