import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TSTModule } from '../tst/tst.module';
import { BannerComponent } from './banner';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [CommonModule, TSTModule, SharedModule],
  declarations: [BannerComponent],
  entryComponents: [BannerComponent],
  exports: [BannerComponent],
})
export class BannerModule {}
