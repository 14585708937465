import { NgModel, FormArray, FormGroup } from '@angular/forms';
import { ConnectArrayFixDirective } from 'src/app/shared/directives/connect-array-fix.directive';

/**
 * Angular 13+ introduced extra assert statements during template driven form updates.
 * To ConnectArrayFixDirective to work properly we needed to remove some of these assertions.
 */

// NgModel overrides
const _checkParentType = (NgModel.prototype as any)._checkParentType;
(NgModel.prototype as any)._checkParentType = function () {
  if (!(this._parent instanceof ConnectArrayFixDirective)) {
    return _checkParentType.apply(this);
  }
};

// FormArray overrides
(FormArray.prototype as any).setValue = function (value: any, options: any) {
  value.forEach((newValue: any, index: any) => {
    this.at(index).setValue(newValue, { onlySelf: true, emitEvent: options?.emitEvent });
  });
  this.updateValueAndValidity(options);
};

// FormGroup overrides
(FormGroup.prototype as any).setValue = function (value: any, options: any) {
  Object.keys(value).forEach((name) => {
    this.controls[name].setValue(value[name], { onlySelf: true, emitEvent: options?.emitEvent });
  });
  this.updateValueAndValidity(options);
};
