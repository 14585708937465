import { Injectable } from '@angular/core';
import { format, getYear } from 'date-fns';
import { MembershipLevel } from 'src/app/models/membership.model';
import { IUserProfile } from 'src/app/models/user.model';
import { UrlHelpers } from '../helpers/url-helper';
import { WindowRefService } from './window-ref.service';
import { HeapECommerceBusinessUnit, HeapECommerceEventNames } from 'src/app/models/identify-heap.model';
import { IQuoteOption } from 'src/app/models/quote-option.model';
import { InsuranceProduct } from 'src/app/models/products';

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  constructor(private windowService: WindowRefService) {}

  public identify(userId: string) {
    this.windowService.heap.identify(userId);
  }

  public resetIdentity() {
    this.windowService.heap.resetIdentity();
  }

  private getMembershipType(user?: IUserProfile) {
    let membershipType = '';
    if (user) {
      switch (user.memberType) {
        case 'P':
          membershipType = 'Primary';
          break;
        case 'A':
          membershipType = 'Associate';
          break;
        case 'CHILD':
          membershipType = 'KGF';
          break;
      }
    }
    return membershipType;
  }

  // membership properties need to be Title Case
  private getMembershipProps(user?: IUserProfile) {
    if (!user) {
      return {
        membership_status: 'Unknown',
        membership_level: '',
        membership_type: '',
      };
    } else {
      let membershipLevel = '';
      if (user && user.memberCoverage) {
        membershipLevel = MembershipLevel[user.memberCoverage];
      }
      return {
        membership_status: user.isMember ? 'Member' : 'Non-Member',
        membership_level: membershipLevel,
        membership_type: this.getMembershipType(user),
      };
    }
  }

  private getUserProps(user?: IUserProfile) {
    return {
      membership_renewal_date: user && user.renewDate ? this.getHeapDate(user.renewDate) : '',
      user_join_date: user && user.joinDate ? this.getHeapDate(user.joinDate) : '',
      user_birth_year: user && user.birthDate ? getYear(new Date(user.birthDate)) : '',
    };
  }

  public enrichUserSession(user?: IUserProfile) {
    // Initial mapping by user states ...
    // const membershipStatus = ['A', 'AB', 'CF', 'AT'].includes(user?.status)
    //     ? 'Member'
    //     : user?.status
    //     ? 'Non-Member'
    //     : 'Unknown';

    const membershipProps = this.getMembershipProps(user);
    const userProps = this.getUserProps(user);
    this.addUserProperties({
      ...membershipProps,
      ...userProps,
      // not required at current implementation
      // can be requested from the red team to be included in the user API
      // user_gender: user.userGender,
    });

    // Clear event properties from the cookie first
    // https://developers.heap.io/reference/cleareventproperties
    this.clearEventProperties();
    this.addEventProperties(membershipProps);
  }

  public createPurchaseEvent(
    renew: boolean,
    product: InsuranceProduct,
    policyNumber?: string,
    receiptId?: string,
    quoteOption?: IQuoteOption,
    user?: IUserProfile
  ) {
    const funnelName = renew ? HeapECommerceEventNames.RenewTMI : HeapECommerceEventNames.PurchaseTMI;
    const name = funnelName + ' - purchase';
    const membershipProps = this.getMembershipProps(user);
    const ecommerceProps = {
      receipt_id: receiptId ? receiptId.toLocaleLowerCase() : '',
      funnel_name: funnelName,
      product_type: this.mapProductType(product).toLocaleLowerCase(),
      product_id: policyNumber ? policyNumber.toLocaleLowerCase() : '',
      product_purchased: this.mapProductName(product).toLocaleLowerCase(),
      product_price: quoteOption && quoteOption.subtotalPrice ? quoteOption.subtotalPrice.toFixed(2) : '0.00',
      product_count: '1',
      business_unit: HeapECommerceBusinessUnit.Travel,
      user_id: user && user.authID ? user.authID : '',
      ...membershipProps,
    };

    this.trackEvent(name.toLocaleLowerCase(), ecommerceProps);
  }

  public createReceiptEvent(renew: boolean, receiptId?: string, quoteOption?: IQuoteOption, user?: IUserProfile) {
    const funnelName = renew ? HeapECommerceEventNames.RenewTMI : HeapECommerceEventNames.PurchaseTMI;
    const name = funnelName + ' - receipt';
    const membershipProps = this.getMembershipProps(user);
    const ecommerceProps = {
      receipt_id: receiptId ? receiptId.toLocaleLowerCase() : '',
      funnel_name: funnelName,
      receipt_total_price: quoteOption && quoteOption.totalPrice ? quoteOption.totalPrice.toFixed(2) : '0.00',
      receipt_tax_fees: quoteOption && quoteOption.tax ? (quoteOption.tax === 0 ? '' : quoteOption.tax.toFixed(2)) : '',
      product_count: '1',
      business_unit: HeapECommerceBusinessUnit.Travel,
      user_id: user && user.authID ? user.authID : '',
      ...membershipProps,
    };

    this.trackEvent(name.toLocaleLowerCase(), ecommerceProps);
  }

  private mapProductName(product: InsuranceProduct): string {
    switch (product) {
      case InsuranceProduct.SingleTripMedicalPlan:
        return 'Single Trip Medical Plan';
      case InsuranceProduct.MultiTripMedicalPlan:
        return 'Multi-Trip Medical Plan';
      case InsuranceProduct.SingleTripPremiumPackage:
        return 'Vacation Package Single Trip';
      case InsuranceProduct.AnnualPremiumPackage:
        return 'Vacation Package Multi-Trip';
      case InsuranceProduct.NonMedicalPremiumPackage:
        return 'Non-Medical Premium Package';
      case InsuranceProduct.TripCancellationAndTripInterruptionInsurance:
        return 'Trip Cancellation and Trip Interruption Insurance';
      case InsuranceProduct.VisitorsToCanadaMedicalPlan:
        return 'Visitors to Canada Medical Plan';
      case InsuranceProduct.CanadaMedicalPlan:
        return 'Canada Medical Plan';
      case InsuranceProduct.CanadaPackagePlan:
        return 'Vacation Package Multi-Trip';
      case InsuranceProduct.RentalVehicleDamageInsurance:
        return 'Rental Vehicle Damage Insurance';
    }
    return '';
  }

  private mapProductType(product: InsuranceProduct): string {
    switch (product) {
      case InsuranceProduct.SingleTripMedicalPlan:
      case InsuranceProduct.MultiTripMedicalPlan:
        return 'Travel Medical Insurance';
      case InsuranceProduct.SingleTripPremiumPackage:
      case InsuranceProduct.AnnualPremiumPackage:
      case InsuranceProduct.NonMedicalPremiumPackage:
      case InsuranceProduct.TripCancellationAndTripInterruptionInsurance:
        return 'Travel Package Insurance';
      case InsuranceProduct.VisitorsToCanadaMedicalPlan:
        return 'Visitors to Canada Medical Plan';
      case InsuranceProduct.CanadaMedicalPlan:
        return 'Canada Medical Plan';
      case InsuranceProduct.CanadaPackagePlan:
        return 'Canada Package Plan';
      case InsuranceProduct.RentalVehicleDamageInsurance:
        return 'Rental Vehicle Damage Insurance';
    }
    return '';
  }

  private trackEvent(eventName: string, props: Record<string, string | number>) {
    if (this.windowService.heap && this.windowService.heap.track) this.windowService.heap.track(eventName, props);
  }

  private addUserProperties(props: Record<string, string | number>) {
    if (this.windowService.heap && this.windowService.heap.addUserProperties) this.windowService.heap.addUserProperties(props);
  }

  private addEventProperties(props: Record<string, string | number>) {
    if (this.windowService.heap && this.windowService.heap.addEventProperties) this.windowService.heap.addEventProperties(props);
  }

  private clearEventProperties() {
    if (this.windowService.heap && this.windowService.heap.clearEventProperties) this.windowService.heap.clearEventProperties();
  }

  private getHeapDate(date?: Date | string) {
    if (!date) {
      return '';
    }
    return format(new Date(date), 'YYYY/MM/DD');
  }

  public insertHeapScript() {
    const headAppID = UrlHelpers.isProd() ? '291727451' : '495137093';
    const head = document.head;
    const heapScript = document.createElement('script');
    heapScript.innerText = `window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; var r = document.createElement("script"); r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(r, a); for (var n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))) } }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++)heap[p[o]] = n(p[o]) }; heap.load(${headAppID});`;
    head.appendChild(heapScript);
  }
}
