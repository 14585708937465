import { Component, OnInit } from '@angular/core';
import { Unsubscribe } from 'amaweb-tsutils';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { UrlSigningService } from 'src/app/core/services/url-signing.service';
import { IAppConfigSettings } from 'src/app/models/app-config.model';
import { MemberJoinSectionWidget } from 'src/app/models/member-join-section-widget.model';

@Unsubscribe()
@Component({
  selector: 'member-join-section',
  templateUrl: './member-join-section.component.html',
})
export class MemberJoinSectionComponent implements OnInit {
  appConfigSetting: IAppConfigSettings;
  widgetViewModel: MemberJoinSectionWidget[] = [
    {
      iconUrl: 'https://amaabcaprod.blob.core.windows.net/content/images/iconology/shell-fuel-station-lg@2x.png',
      title: 'shell_fuel.title',
      body: 'shell_fuel.body',
    },
    {
      iconUrl: 'https://amaabcaprod.blob.core.windows.net/content/images/iconology/shopping-bags-blue_lg@2x.png',
      title: 'presidents_choice.title',
      body: 'presidents_choice.body',
    },
    {
      iconUrl: 'https://amaabcaprod.blob.core.windows.net/content/images/iconology/hand-holding-phone_lg.png',
      title: 'online_communities.title',
      body: 'online_communities.body',
    },
    {
      iconUrl: 'https://amaabcaprod.blob.core.windows.net/content/images/iconology/backpack-kids-go-free_lg.png',
      title: 'drivers_education.title',
      body: 'drivers_education.body',
    },
  ];
  constructor(private urlSigningService: UrlSigningService, private clubConfigurationService: ClubConfigurationService) {}
  ngOnInit(): void {
    this.appConfigSetting = this.clubConfigurationService.appConfig.settings;
  }

  memberJoinClickHandler(e: Event) {
    if (this.appConfigSetting.join_ama_link_requires_signing) {
      // Prevent the anchor link from executing
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      // Instead open a properly signed URL.
      this.urlSigningService
        .signUrl(this.appConfigSetting.join_ama_link)
        .subscribe((signedUrl) => {
          window.open(signedUrl);
        })
        .attach(this);
    }
  }
}
