import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[matchHeight]',
})
export class MatchHeightDirective implements AfterViewChecked {
  // class name to match height
  @Input() matchHeight: any[];
  @Input() matchHeightDesktopOnly = false;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    this.checkDesktopSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: Event) {
    if (this.matchHeightDesktopOnly) {
      if (window.innerWidth >= 768) {
        this.checkDesktopSize();
      }
    } else {
      this.checkDesktopSize();
    }
  }

  checkDesktopSize() {
    // call matchHeight function
    this.matchHeight.forEach(className => {
      this.matchElHeight(this.el.nativeElement, className);
    });
  }

  matchElHeight(elRef: HTMLElement, className: string, ignoreMatchHeightClass?: string) {
    // match height logic here
    if (!elRef) {
      return;
    }

    // step 1: find all the child elements with the selected class name
    const children = elRef.getElementsByClassName(className);

    if (!children) {
      return;
    }

    // step 1b: reset all children height
    Array.from(children).forEach((x: HTMLElement) => {
      x.style.height = 'auto';
    });

    // step 2a: get all the child elements heights
    const itemHeights = Array.from(children).map(x => x.getBoundingClientRect().height);

    // step 2b: find out the tallest
    const maxHeight = itemHeights.reduce((prev, curr) => {
      return curr > prev ? curr : prev;
    }, 0);

    // step 3: update all the child elements to the tallest height
    Array.from(children).forEach((x: HTMLElement) => (x.style.height = `${maxHeight}px`));
  }
}
