<div class="tst-loading-zone"
	 hidden></div>

<div [class]="'theme--' + theme + ' tst-search__widget locationState--' + locationState + ' search'"
	 [ngClass]="{locationSelected: selectedLocation, isClicked: tabClickedState, 'tst-search__widget--loaded': tstLoaded}">
	<div class="tst-search__track"
		 #tabTrack>
		<div class="tst-search__tabs-heading">What are you looking for?</div>
		<div class="tst-search__tabs tabs">
			<ng-container *ngIf="!mq?.mobile">
				<ng-container *ngFor="let item of bannerConfig">
					<div #formTabs
						 *ngIf="
								locationStatesToReset.indexOf(locationState) >= 0 ||
								selectedLocationTypes?.indexOf(item?.type) >= 0 ||
								isInGroup(item)"
						 [hidden]="!item.groupIndex"
						 (click)="tabClicked($event, item?.type)"
						 (mouseup)="scrollEnd($event)"
						 [id]="'tab-' + item.type"
						 [class]="'tst-search__tab tst-search__tab--' + item.type"
						 [ngClass]="{
								active: item == activeTab, mobileActive: item == activeTab, isMainTab: activeTab?.type == item?.type && cmsTab == item?.type}">
						<span [class]="'tst-search__tab-icon tst-search__tab-icon--' + item?.type">
							<ng-container *ngTemplateOutlet="iconTemplates; context: item"></ng-container>
						</span>
						<a href=""
						   class="tst-search__tab-label">
							{{ item.label }}
						</a>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="mq?.mobile">
				<ng-container *ngFor="let item of bannerConfig">
					<div #formTabs
						 *ngIf="
							locationStatesToReset.indexOf(locationState) >= 0 ||
							selectedLocationTypes?.indexOf(item?.type) >= 0 ||
							isInGroup(item)"
						 [hidden]="!item.groupIndex"
						 (click)="tabClicked($event, item?.type)"
						 (mouseup)="scrollEnd($event)"
						 [id]="'tab-' + item.type"
						 [class]="'tst-search__tab tst-search__tab--' + item.type"
						 [ngClass]="{
							active: item == activeTab, mobileActive: item == activeTab, isMainTab: activeTab?.type == item?.type && cmsTab == item?.type}">
						<span [class]="'tst-search__tab-icon tst-search__tab-icon--' + item?.type">
							<ng-container *ngTemplateOutlet="iconTemplates; context: item"></ng-container>
						</span>
						<a href=""
						   class="tst-search__tab-label">
							{{ item.label }}
						</a>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<!-- </div> -->
	<div class="tst-search__forms"
		 #container>
		<ng-container *ngFor="let group of bannerConfigGrouped; let i = index">
			<div [class]="'tst-search__form-group'">
				<div *ngIf="group[i] && group[i].group == TST_WIDGET_LABELS.ACTIVITY_TAB && (group[i].type == 'activity' || group[i].type == 'tour')"
					 class="tst-search__form-group-options"
					 #activitiesTourOptions
					 [ngClass]="{
					   active: showGroupOptions && activeTypeState == TST_WIDGET_LABELS.ACTIVITY_TAB && tabClickedState}">
					<ng-container *ngTemplateOutlet="activitiesToursForm"></ng-container>
				</div>

				<ng-container *ngFor="let item of group; last as last">
					<ng-container *ngIf="(item.type != 'activity' && item.type != 'tour'); else elseBlock">
						<div class="tst-search__form-group-options"
							 [ngClass]="{
									active: item == activeForm, 'tst-search-widget': item.type != 'flight'}">
							<ng-container [ngTemplateOutlet]="
									(item.type == 'prepackaged') ? optionsForm
									: (item.type == 'vacation') ? dynamicForm
									: (item.type == 'hotel') ? hotelsForm
									: (item.type == 'rentals') ? hotelsForm
									: (item.type == 'flight') ? null
									: (item.type == 'cruise') ? null
									: (item.type == 'car') ? null
									: null">
							</ng-container>
						</div>
						<div *ngIf="!mq?.mobile"
							 #forms
							 [id]="'form-' + item.type"
							 [class]="'tst-search__form tst-search__form--' + item.type"
							 [ngClass]="{ active: item == activeForm, 'tst-search-widget': item.type != 'flight' }"></div>
						<div *ngIf="mq?.mobile"
							 #forms
							 [id]="'form-' + item.type"
							 [class]="'container tst-search__form tst-search__form--' + item.type"
							 [ngClass]="{ active: item?.type == mobileActiveForm, 'tst-search-widget': item.type != 'flight',
							activeLocationType: activeLocationType == 'live' && this.selectedLocation && selectedLocationTypes?.indexOf(item?.type) >= 0}">
						</div>
					</ng-container>
					<ng-template #elseBlock>
						<div #forms
							 [id]="'form-' + item.type"
							 [class]="'tst-search__form tst-search__form--' + item.type"
							 [ngClass]="{ active: item == activeForm, 'tst-search-widget': item.type != 'flight' }">
						</div>
					</ng-template>
				</ng-container>
			</div>
		</ng-container>
		<span class="tst-search__date-pickers"
			  [hidden]="true"
			  #datePickersTemplates>
			<ng-container #datePickerViewContainer></ng-container>
		</span>
		<span class="tst-search__incrementors"
			  #incrementorTemplates
			  [hidden]="true">
			<ng-container #guestIncrementorViewContainer></ng-container>
		</span>
		<span class="tst-search__select-arrows"
			  [hidden]="true"
			  #selectArrows>
			<ng-container #selectArrowContainer></ng-container>
		</span>
		<span class="tst-search__icons"
			  [hidden]="true"
			  #iconInject>
			<ng-container #iconInjectViewContainer></ng-container>
		</span>
		<span class="tst-search__cruise-advanced-container"
			  [hidden]="true"
			  #cruiseAdvancedFields>
			<ng-container #cruiseAdvancedFieldsContainer></ng-container>
		</span>
		<span class="tst-search__cruise-options-container"
			  [hidden]="true">
			<ng-container #cruiseOptionsContainer></ng-container>
		</span>
		<span class="tst-search__cruise-matPanel-container"
			  [hidden]="true">
			<ng-container #cruiseMatPanelContainer></ng-container>
		</span>
		<span class="tst-search__custom-form-container"
			  [hidden]="true">
			<ng-container #customFormContainer></ng-container>
		</span>
		<span class="tst-search__custom-advanced-search"
			  [hidden]="true">
			<ng-container #advancedSearchContainer></ng-container>
		</span>
	</div>
</div>

<!-- datepicker template - Either uses matDatePicker or ngbDatePicker-->
<ng-template #datePickerTemplateRef>
	<ng-container *ngFor="let dp of datePickers; let i = index">
		<ng-container *ngIf="dp.datePickerType == 'ngbDatePicker' && !dp.dateRange">
			<div [class]="'datePickerNode tst-search__date-pickers tst-search__date-pickers--single tst-search__date-picker--ngbDropdown ' + datepickerPlacement.placement"
				 [ngClass]="dp.name"
				 ngbDropdown
				 #dropDownDatePickerFrom="ngbDropdown"
				 autoClose="outside"
				 (openChange)="tstService.datepickerToggle($event,{dp:dp,direction:'from',dates:dates, el:dropDownDatePickerFrom, placement:datepickerPlacement})">
				<!--range Datepicker From-->
				<div [class]="'tst-search__date-picker tst-search__date-picker'">
					<label class="tst-search__control-label">{{ dp.label[0] }}</label>
					<div class="tst-search__date-picker-form">
						<input [id]="'rangeDatePickerFrom-' + activeForm?.type"
							   [value]="dates.startDate ? dateFormat(dates.startDate,'ddd, MMM Do') : ''"
							   [placeholder]="dateFormat(dp.placeHolder,'ddd, MMM Do')"
							   [ngClass]="{'dateEmpty': !dp.formControl.value, 'showErrorMessage': !dp.formControl.value}"
							   class="tst-form__field tst-form__field--datepicker"
							   [attr.tabindex]="3"
							   autocomplete="off"
							   autocorrect="off"
							   required>
						<button class="tst-icon__wrap-button"
								type="button"
								id="dateDropDownLeaveSingle"
								ngbDropdownToggle>
							<span class="tst-icon tst-icon--calendar tst-icon--prefix">
								<svg class="c-icon c-icon--tstIcon">
									<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
								</svg>
							</span>
						</button>
						<span ngbDropdownMenu
							  aria-labelledby="dateDropDownLeaveSingle">
							<ng-container
										  *ngTemplateOutlet="singleDateDialog; context: {$implicit: {field: null, index: i, dp: dp, element: dropDownDatePickerFrom}}">
							</ng-container>
						</span>
					</div>
				</div>
				<div class="tst-search__backdrop"></div>
			</div>
		</ng-container>
		<ng-container *ngIf="dp.datePickerType == 'ngbDatePicker' && dp.dateRange">
			<div class="datePickerNode tst-search__date-pickers"
				 [ngClass]="dp.name">
				<!--range Datepicker From-->
				<div [class]="'tst-search__date-picker tst-search__date-picker--from tst-search__date-picker dateRange tst-search__date-picker--ngbDropdown ' + datepickerPlacement.placement"
					 ngbDropdown
					 #dropDownElDepart="ngbDropdown"
					 autoClose="outside"
					 (openChange)="tstService.datepickerToggle($event,{dp:dp,direction:'from',dates:dates, el:dropDownElDepart, placement:datepickerPlacement})">
					<label class="tst-search__control-label">{{ dp.label[0] }}</label>
					<div class="tst-search__date-picker-form">
						<input [id]="'rangeDatePickerFrom-' + activeForm?.type"
							   [value]="dates.startDate ? dateFormat(dates.startDate,'ddd, MMM Do') : ''"
							   [placeholder]="dateFormat(dp.placeHolder,'ddd, MMM Do')"
							   [ngClass]="{'dateEmpty': !dp.formControl.value, 'showErrorMessage': !dp.formControl.value}"
							   class="tst-form__field tst-form__field--datepicker"
							   [attr.tabindex]="3"
							   autocomplete="off"
							   autocorrect="off"
							   required>
						<button class="tst-icon__wrap-button"
								type="button"
								ngbDropdownToggle
								id="dateDropDownLeaveRange">
							<span class="tst-icon tst-icon--calendar tst-icon--prefix">
								<svg class="c-icon c-icon--tstIcon">
									<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
								</svg>
							</span>
						</button>
						<span ngbDropdownMenu
							  aria-labelledby="dateDropDownLeaveRange">
							<ng-container
										  *ngTemplateOutlet="dateRangeDialog; context: {$implicit: {field: field, index: i, dp: dp, element:dropDownElDepart, elementToTrigger: dropDownElReturn, flightDirection:'depart'}}">
							</ng-container>
						</span>
					</div>
					<div class="tst-search__backdrop"></div>
				</div>
				<!--range Datepicker To-->
				<div [class]="'tst-search__date-picker tst-search__date-picker--to tst-search__date-picker--ngbDropdown ' + dp.name + ' dateRange' + ' ' + datepickerPlacement.placement"
					 ngbDropdown
					 #dropDownElReturn="ngbDropdown"
					 autoClose="outside"
					 (openChange)="tstService.datepickerToggle($event,{dp:dp,direction:'return',dates:dates, el:dropDownElReturn, placement:datepickerPlacement})">
					<label class="tst-search__control-label">{{ dp.label[1] }}</label>
					<div class="tst-search__date-picker-form">
						<input [id]="'rangeDatePickerTo-' + activeForm?.type"
							   [placeholder]="dateFormat(dp.placeHolder,'ddd, MMM Do')"
							   [value]="dates.endDate ? dateFormat(dates.endDate,'ddd, MMM Do') : ''"
							   [ngClass]="{'dateEmpty': !dp.formControl.value, 'showErrorMessage': !dp.formControl.value}"
							   class="tst-form__field tst-form__field--datepicker"
							   [attr.tabindex]="4"
							   autocomplete="off"
							   autocorrect="off"
							   required>
						<button class="tst-icon__wrap-button"
								type="button"
								ngbDropdownToggle
								id="dateDropDownReturnRange">
							<span class="tst-icon tst-icon--calendar tst-icon--prefix">
								<svg class="c-icon c-icon--tstIcon">
									<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
								</svg>
							</span>
						</button>
						<span ngbDropdownMenu
							  aria-labelledby="dateDropDownReturnRange">
							<ng-container
										  *ngTemplateOutlet="dateRangeDialog; context: {$implicit: {field: field, index: i, dp: dp, element: dropDownElReturn, elementToTrigger: dropDownElDepart, flightDirection:'return'}}">
							</ng-container>
						</span>
					</div>
					<div class="tst-search__backdrop"></div>
				</div>
			</div>
		</ng-container>

	</ng-container>
</ng-template>

<!-- For daterange pickers (ng-bootstrap)-->
<ng-template #dateRangeDialog
			 let-data="$implicit">
	<div class="tst-form__datepicker-heading"
		 *ngIf="mq.mobile">
		<div class="tst-form__datepicker-title">
			{{dates?.msg}}
		</div>
	</div>
	<div class="tst-form__datepicker-content">
		<ngb-datepicker #dp
						[minDate]="dates.dateRngMin"
						[maxDate]="dates.dateRngMax"
						(dateSelect)="dateRangeSelection($event, data)"
						(navigate)="tstService.datePickerNavigation($event, dp, dates)"
						[displayMonths]="1"
						[dayTemplate]="dateRangeDay"
						outsideDays="hidden"
						[startDate]="dates.dateRngFromDate"
						[firstDayOfWeek]="7"
						navigation="false" />
	</div>
</ng-template>

<!-- For single date pickers (ng-bootstrap)-->
<ng-template #singleDateDialog
			 let-data="$implicit">
	<div class="tst-form__datepicker-heading"
		 *ngIf="mq.mobile">
		<div class="tst-form__datepicker-title">
			{{dates.msg}}
		</div>
	</div>
	<div class="tst-form__datepicker-content">
		<ngb-datepicker #dp
						[minDate]="dates.dateRngMin"
						[maxDate]="dates.dateRngMax"
						(dateSelect)="datePickerSelection($event, data)"
						[displayMonths]="1"
						[dayTemplate]="dateDay"
						outsideDays="hidden"
						[startDate]="dates.dateRngFromDate"
						[firstDayOfWeek]="7"
						navigation="false" />
	</div>
</ng-template>

<ng-template #dateRangeDay
			 let-date="date"
			 let-focused="focused"
			 let-month="currentMonth">
	<span class="custom-day"
		  [class.focused]="focused"
		  [class.range]="tstService.dateRangeInRange(date, dates)"
		  [class.start]="tstService.dateRangeIsStartDate(date, dates)"
		  [class.today]="tstService.dateRangeIsToday(date, dates)"
		  [class.end]="tstService.dateRangeIsEndDate(date, dates)"
		  [class.faded]="tstService.dateRangeIsHovered(date, dates) || tstService.dateRangeIsInside(date, dates)"
		  [class.notAvailable]="tstService.dateRangeInvalidDate(date, dates)"
		  (mouseenter)="dates.dateRngHoveredDate = date"
		  [class.outsideDay]="tstService.dateCalendarOutsideDay(date, dates, month)"
		  (mouseleave)="dates.dateRngHoveredDate = null">
		{{ date.day }}
	</span>
</ng-template>

<ng-template #dateDay
			 let-date="date"
			 let-focused="focused">
	<span class="custom-day"
		  [class.focused]="focused"
		  [class.chosen]="tstService.dateRangeIsStartDate(date, dates)"
		  [class.today]="tstService.dateRangeIsToday(date, dates)"
		  [class.faded]="tstService.dateRangeIsHovered(date, dates)"
		  [class.notAvailable]="tstService.dateRangeInvalidDate(date, dates)">
		{{ date.day }}
	</span>
</ng-template>

<!-- template for rooms/numOfGuests incrementor -->
<ng-template #packagesGuests
			 let-data="$implicit">
	<div class="tst-search__select-incrementor">
		<form-stepper (callback)="formStepperCallback($event)"
					  (dialogOpenState)="formStepperDialogState($event)"
					  [data]="data">
		</form-stepper>
		<span class="tst-search__incrementor-hidden"></span>
	</div>
</ng-template>

<ng-template #allInclusiveOptions>
	<div class="tst-search__select-all-inclusive-checkbox">
		<span class="tst-search__all-inclusive-checkbox-hidden"></span>
		<div id="tst-search__all-inclusive-checkbox">
			<div class="tst-search__inclusive-checkbox">
				<input id="inclusive"
					   type="checkbox" />
				<h6 class="tst-form__form-checkbox-label">{{ allInclusiveCheckboxLabel }}</h6>
			</div>
		</div>
	</div>
</ng-template>

<!-- template for vacations tab options -->
<ng-template #optionsForm>
	<div class="tst-form__container u-row-xs--none u-row-sm--base  u-rmargin-sm--y-none">
		<div class="tst-form__form u-rpadding-xs--x">
			<div class="tst-form__form-items">
				<h6 class="tst-form__form-title">Search for the best vacation deals to Las Vegas, Mexico, the Caribbean and more.</h6>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #hotelsForm>
	<div class="tst-form__container u-row-xs--none u-row-sm--base  u-rmargin-sm--y-none">
		<form class="tst-form__form u-rpadding-xs--x">
			<div class="tst-form__form-items">
				<h6 class="tst-form__form-title">Explore great deals on hotels and vacation home rentals around the world.</h6>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #dynamicForm>
	<div class="tst-form__container u-row-xs--none u-row-sm--base  u-rmargin-sm--y-none">
		<form [formGroup]="formVacationOptions"
			  class="tst-form__form u-rpadding-xs--x">
			<div class="tst-form__form-items">
				<h6 class="tst-form__form-title">Bundle your flight, hotel & car rental and save on your next trip. For all-inclusive
					vacations, use the <a class="text-decoration-none"
					   (click)="tabClicked($event, 'prepackaged')">Vacations</a> tab to explore top vacation deals.</h6>
				<div class="tst-form__options u-row-xs--quarter vacation">
					<div *ngIf="!mq.mobile; else: mobileDynOptions"
						 class="tst-form__option-group dynamic"
						 [ngClass]="{'invalid':!dynamicOptionsValid}">
						<ng-container *ngTemplateOutlet="dynamicOptions; context: { $implicit: formVacationOptions }"></ng-container>
					</div>
					<ng-template #mobileDynOptions>
						<ng-container *ngTemplateOutlet="dynamicOptions; context: { $implicit: formVacationOptions }"></ng-container>
					</ng-template>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<ng-template #activitiesToursForm>
	<div class="tst-form__container u-row-xs--none u-row-sm--base  u-rmargin-sm--y-none">
		<form [formGroup]="activityFormOptions"
			  class="tst-form__form u-rpadding-xs--x">
			<h6 class="tst-form__form-title">{{ tabTitle }}</h6>
			<div class="tst-form__form-items">
				<div class="tst-form__options u-row-xs--quarter activity">
					<div *ngIf="!mq.mobile; else: mobileVacOptions"
						 class="tst-form__option-group activity">
						<ng-container *ngTemplateOutlet="activitiesTourOptions; context: { $implicit: activityFormOptions }"></ng-container>
					</div>
					<ng-template #mobileVacOptions>
						<ng-container *ngTemplateOutlet="activitiesTourOptions; context: { $implicit: activityFormOptions }"></ng-container>
					</ng-template>
				</div>
			</div>
		</form>
	</div>
</ng-template>

<!-- // Activities & Tours Tab Options -->
<ng-template #activitiesTourOptions
			 let-form>
	<label class="tst-form__option"
		   analytic-ama="tst-widget-button*activities">
		<input type="radio"
			   [formControl]="form.controls.formType"
			   [value]="formOptionValues.activity"
			   name="activityRadioOptions"
			   [checked]="form.controls.formType.value[0] == 'activity'" />
		<span class="tst-form__option-button">Activities</span>
	</label>
	<!-- commenting out tours until TST fixes the api call -->

	<label class="tst-form__option"
		   *ngIf="enableTours"
		   analytic-ama="tst-widget-button*guided-vacations">
		<input type="radio"
			   [value]="formOptionValues.tour"
			   [formControl]="form?.controls?.formType"
			   name="activityRadioOptions"
			   [checked]="form.controls.formType.value[0] == 'tour'">
		<span class="tst-form__option-button">Organized Tours</span>
	</label>

</ng-template>

<ng-template #dynamicOptions
			 let-form>
	<label class="tst-form__option"
		   analytic-ama="tst-widget-button*flight-hotel">
		<input type="radio"
			   [value]="formOptionValues.flightHotel"
			   [formControl]="form.controls.formType"
			   name="dynamicRadioOptions"
			   [checked]="form.controls.formType.value[2] == 'fh' && this.dynamicOptionsValid" />
		<span class="tst-form__option-button">Flight + Hotel</span>
	</label>
	<label class="tst-form__option"
		   analytic-ama="tst-widget-button*flight-hotel-car">
		<input type="radio"
			   [value]="formOptionValues.flightHotelCar"
			   [formControl]="form.controls.formType"
			   name="dynamicRadioOptions"
			   [checked]="form.controls.formType.value[2] == 'fhc' && this.dynamicOptionsValid" />
		<span class="tst-form__option-button">Flight + Hotel + Car</span>
	</label>
	<label class="tst-form__option"
		   analytic-ama="tst-widget-button*flight-car">
		<input type="radio"
			   [value]="formOptionValues.flightCar"
			   [formControl]="form.controls.formType"
			   name="dynamicRadioOptions"
			   [checked]="form.controls.formType.value[2] == 'fc' && this.dynamicOptionsValid" />
		<span class="tst-form__option-button">Flight + Car</span>
	</label>
	<label class="tst-form__option"
		   analytic-ama="tst-widget-button*hotel-car">
		<input type="radio"
			   [value]="formOptionValues.hotelCar"
			   [formControl]="form.controls.formType"
			   name="dynamicRadioOptions"
			   [checked]="form.controls.formType.value[2] == 'hc' && this.dynamicOptionsValid" />
		<span class="tst-form__option-button">Hotel + Car</span>
	</label>
</ng-template>
<!-- //end Vacations Tab Options -->

<!-- cruise toggles -->
<ng-template #cruiseOptions>
	<div class="tst-form__options tst-form__options--cruise u-row-xs--none u-row-sm--base u-rmargin-xs--y-2x u-rmargin-sm--y-none">
		<form class="tst-form__form u-rpadding-xs--x"
			  [formGroup]="formCruiseOptions">
			<label class="tst-search__options  c-toggle">
				<input type="checkbox"
					   [checked]="oceanSelected"
					   [(ngModel)]="oceanSelected"
					   [formControlName]="'ocean'"
					   id="oceanCruise"
					   name="cruiseType" />
				<span class="c-toggle__switch">
					<span class="c-toggle__knob"></span>
				</span>
				<span class="c-toggle__label-button">Ocean Cruise</span>
			</label>

			<label class="tst-search__options c-toggle">
				<input type="checkbox"
					   [checked]="riverSelected"
					   [(ngModel)]="riverSelected"
					   [formControlName]="'river'"
					   id="riverCruise"
					   name="cruiseType" />
				<span class="c-toggle__switch">
					<span class="c-toggle__knob"></span>
				</span>
				<span class="c-toggle__label-button">River Cruise</span>
			</label>
		</form>
	</div>
</ng-template>

<!-- custom flight for -->
<ng-template #customFlightFormTemplateRef>
	<tst-custom-form [tstType]="TST_WIDGET_LABELS.FLIGHT_TAB"
					 (destinationFieldCleared)="destinationFieldChange($event, true)"
					 [airportCode]="airportCode"
					 [defaultDepartureAirport]="defaultDepartureAirport">
	</tst-custom-form>
</ng-template>

<!-- template to inject into cruiseMatPanelContainer viewContainerRef -->
<ng-template #cruiseMatPanel>
	<mat-expansion-panel class="tst-search__cruise-reveal-advanced">
		<mat-expansion-panel-header>
			<mat-panel-title> Advanced Options </mat-panel-title>
		</mat-expansion-panel-header>
	</mat-expansion-panel>
</ng-template>

<!-- template to inject into cruiseAdvancedFieldsContainer viewContainerRef -->
<ng-template #cruiseAdvancedFieldsTemplate>
	<div class="tst-search__cruise-advanced-fields"></div>
</ng-template>

<!--
	template to inject into iconInjectViewContainer viewContainerRef
	For: misc icon injections (map-marker, clock, person, bed)
-->
<ng-template #injectedIcons
			 let-icon>
	<span class="tst-icon"
		  [ngClass]="icon.name">
		<ng-container *ngTemplateOutlet="injectedfaIcons; context: { $implicit: icon.name }"></ng-container>
	</span>
</ng-template>

<ng-template #injectedfaIcons
			 let-name>
	<ng-container *ngIf="name == 'map-marker'">
		<fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>
	</ng-container>
	<ng-container *ngIf="name == 'clock'">
		<fa-icon [icon]="['fal', 'clock']"></fa-icon>
	</ng-container>
	<ng-container *ngIf="name == 'bed'">
		<fa-icon [icon]="['fal', 'bed']"></fa-icon>
	</ng-container>
	<ng-container *ngIf="name == 'person'">
		<fa-icon [icon]="['fal', 'male']"></fa-icon>
	</ng-container>
	<ng-container *ngIf="name == 'calendar'">
		<ng-container *ngTemplateOutlet="iconTemplates; context: { type: name }"></ng-container>
	</ng-container>
</ng-template>

<!-- custom tst icons templates -->
<ng-template #iconTemplates
			 let-type="type">
	<ng-container *ngIf="type == 'prepackaged'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--vacations"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'vacation'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--dynamic"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'hotel'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--hotels"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'rentals'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--rentals"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'flight'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--flights"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'car'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cars"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'cruise'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--cruise"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'activity'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--activities"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'calendar'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--calendar"></use>
		</svg>
	</ng-container>
	<ng-container *ngIf="type == 'marker'">
		<svg class="c-icon c-icon--tstIcon">
			<use xlink:href="/Content/SVG/sprite-tst.svg#tstIcon--marker"></use>
		</svg>
	</ng-container>
</ng-template>
<!-- / end tst icon templates -->

<!-- template to inject into selectArrowContainer viewContainerRef -->
<ng-template #selectArrow
			 let-guests>
	<span class="tst-search__select-arrow"
		  [ngClass]="guests?.dialogRefString">
		<span class="tst-icon tst-icon--chevron-down tst-icon--suffix"
			  [ngClass]="{
				'dialog-open':
					guests?.dialogName == dialogRefFormStepper?.dialogName &&
					dialogRefFormStepper?.open
			}">
			<fa-icon [icon]="['fal', 'chevron-down']"></fa-icon>
		</span>
		<input type="hidden"
			   [value]="dialogRefFormStepper?.dialogName" />
		<input type="hidden"
			   [value]="guests?.dialogName" />
		<input type="hidden"
			   [value]="dialogRefFormStepper?.open" />
	</span>
</ng-template>

<ng-template #advancedSearchLink
			 let-type>
	<div class="tst-form__advanced-search-link">
		Don't see what you're looking for? Try our&nbsp;<a [href]="'//albertateachers.tstllc.net/'+type">Advanced Search</a>
	</div>
</ng-template>