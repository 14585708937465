import { Component, OnInit } from '@angular/core';
import { select, NgRedux } from '@angular-redux-ivy/store';
import { Observable } from 'rxjs';
import { ITripDetails, IAppState } from 'src/app/models/data.interfaces';
import { ProductGroup } from 'src/app/models/products';

@Component({
  selector: 'upsell-matrix',
  templateUrl: './upsell-matrix.component.html',
  styleUrls: ['./upsell-matrix.component.scss'],
})
export class UpsellMatrixComponent implements OnInit {
  @select('tripDetails') readonly tripDetails$: Observable<ITripDetails>;
  productGroup = ProductGroup;

  constructor(private readonly reduxStore: NgRedux<IAppState>) {}

  ngOnInit() {}
}
