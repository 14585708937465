import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IAppState,
  IServerAppState,
  IServerQuotePaymentRequest,
  IServerQuotePaymentResponse,
  IServerLoadQuoteResponse,
  IServerQuoteOptionsResponse,
  InstrumentationEventNames,
  IQuoteOption,
  InsuranceProduct,
} from '../../models/data.interfaces';
import { QuoteMapper } from './quote-mapper.service';
import { map, tap, catchError } from 'rxjs/operators';
import { AppInsights } from 'applicationinsights-js';
import { NgRedux } from '@angular-redux-ivy/store';
import { SalesFunnelActionType, IQuoteOptionAction, IServerErrorAction } from 'src/app/store/reducer';
import { updateCurrentQuoteDeductible } from 'src/app/store/current-quote-details.reducer';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private readonly baseApiUrl: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly quoteMapper: QuoteMapper,
    private readonly reduxStore: NgRedux<IAppState>
  ) {
    this.baseApiUrl = `${environment.baseApiUrl}/quote`;
  }
  public getQuote(quoteId: string): Observable<IServerLoadQuoteResponse> {
    return this.httpClient.get<IServerLoadQuoteResponse>(`${this.baseApiUrl}`, { params: { token: quoteId } }).pipe(
      tap((response) => {
        if (response) {
          AppInsights.trackEvent(InstrumentationEventNames.QuoteLoaded);
        }
      })
    );
  }

  public getQuoteOptions(saveQuoteData: IAppState): Observable<IServerQuoteOptionsResponse> {
    AppInsights.trackEvent(InstrumentationEventNames.OptionsRequested);

    const serverState = this.quoteMapper.mapIAppStateToServerAppState(saveQuoteData);
    return this.httpClient.post<IServerQuoteOptionsResponse>(`${this.baseApiUrl}/options`, serverState.tripDetails).pipe(
      tap((response) => {
        if (response.quoteOptions && response.quoteOptions.length > 0) {
          AppInsights.trackEvent(InstrumentationEventNames.OptionsProvided);
        }
      })
    );
  }

  public saveQuote(saveQuoteData: IAppState, additionalValidation: boolean): Observable<IAppState> {
    const objToSave = this.quoteMapper.mapIAppStateToServerAppState(saveQuoteData);
    return this.httpClient
      .post<IServerAppState>(this.baseApiUrl, { quote: objToSave, additionalValidation, quoteOptions: saveQuoteData.quoteOptions })
      .pipe(
        map((serverState) => {
          return this.quoteMapper.mapServerStateToIAppState(serverState);
        })
      );
  }
  public triggerQuoteEmail(quoteId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseApiUrl}/sendEmail/${quoteId}`).pipe(
      tap((result) => {
        if (result) {
          AppInsights.trackEvent(InstrumentationEventNames.SaveQuoteEmailSent);
        }
      })
    );
  }
  public sendPaymentInfo(paymentInfo: IServerQuotePaymentRequest): Observable<IServerQuotePaymentResponse> {
    AppInsights.trackEvent(InstrumentationEventNames.PaymentAttempted);

    const svrPaymentRequest = this.quoteMapper.MapAppStatePaymentInfoToServerPaymentRequest(paymentInfo);
    return this.httpClient.post<IServerQuotePaymentResponse>(`${environment.baseApiUrl}/payment`, svrPaymentRequest).pipe(
      map((response) => {
        if (response.isSuccess) {
          AppInsights.trackEvent(InstrumentationEventNames.PaymentApproved);
        } else {
          AppInsights.trackEvent(InstrumentationEventNames.PaymentDeclined);
        }

        return this.quoteMapper.MapServerPaymentResponseToAppPaymentResponse(response);
      })
    );
  }

  public fetchQuoteOptions(): Observable<IQuoteOption[]> {
    const localState = this.reduxStore.getState();
    return this.getQuoteOptions(localState).pipe(
      map((response: IServerQuoteOptionsResponse) => {
        const quoteOptions = response.quoteOptions.sort((a, b) => a.planDays - b.planDays);
        this.reduxStore.dispatch(this.setQuoteOptions(quoteOptions));

        // Update the deductible amount using the suggestion.
        // Do not do this if a deductible has already been set.
        const currentQuoteDetails = (this.reduxStore.getState() as IAppState).currentQuoteDetails;
        if (!currentQuoteDetails || currentQuoteDetails.deductibleAmount === null) {
          this.reduxStore.dispatch(updateCurrentQuoteDeductible(response.suggestedDeductible));
        }

        // clean up possible previous server error since we already got quote options back.
        this.reduxStore.dispatch({
          type: SalesFunnelActionType.CLEAR_LAST_SERVER_ERROR,
        } as IServerErrorAction);
        return quoteOptions;
      }),
      catchError((error) => {
        this.reduxStore.dispatch(this.setQuoteOptions([]));
        return of([]);
      })
    );
  }

  public renewalQuoteOptions(): Observable<IQuoteOption[]> {
    const validProductCodes: string[] = [InsuranceProduct.AnnualPremiumPackage, InsuranceProduct.MultiTripMedicalPlan];
    return this.fetchQuoteOptions().pipe(
      map((quoteOptions) => (quoteOptions || []).filter((option) => validProductCodes.includes(option.productCode)))
    );
  }

  public clearQuoteOptions() {
    this.reduxStore.dispatch(this.setQuoteOptions([]));
  }

  private setQuoteOptions(options: IQuoteOption[]): IQuoteOptionAction {
    return {
      type: SalesFunnelActionType.SET_QUOTE_OPTIONS,
      payload: options,
    } as IQuoteOptionAction;
  }
}
