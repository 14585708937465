<div class="container mt-3 p-0">
    <section class="row c-tagline">
        <div class="c-tagline__container">
            <div class="c-tagline__content">
                <div class="c-tagline__text"
                     id="{{anchor}}">
                    <h2 class="mx-3"
                        style="color: black !important;">{{title}}</h2>
                </div>
            </div>
        </div>
    </section>
    <ng-content></ng-content>
</div>