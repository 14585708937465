<div class="full-modal-content">
    <div class="text-right u-ama-color--royal-blue d-flex justify-content-end pr-4 pt-3">
        <a (click)="bsModalRef.hide()"
           href="javascript:void(0)"
           class="text-decoration-none font-weight-bold d-flex align-items-center">
            <span class="font-weight-normal times-icon ml-1">&times;</span>
        </a>
    </div>
    <div class="px-5 pb-3"
         [innerHtml]="tooltipHtml | translate | safeHtml">
    </div>
</div>