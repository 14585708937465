import { ProductGroup } from 'src/app/models/products';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';

export class ExternalLinkParams {
  productGroupFlow?: string;
  numberOfTravellers?: number;
  departureDate?: Date;
  returnDate?: Date;
  policyStartDate?: Date;
  durationOfFirstTrip?: number;
  country?: string;
  subRegion?: string;
  utm_source?: string;
}

export class QueryParamsMapper {
  public static mapInitialState(params: any): ExternalLinkParams {
    const externalLinkParams = new ExternalLinkParams();
    const utm_source = this.getParameterCaseInsensitive(params, 'utm_source');
    if (utm_source) {
      externalLinkParams.utm_source = utm_source;
    }

    const flow = this.getParameterCaseInsensitive(params, 'flow');
    if (flow) {
      externalLinkParams.productGroupFlow = this.getParameterCaseInsensitive(ProductGroup, flow);
    }

    const depDate = DateHelpers.parseDate(this.getParameterCaseInsensitive(params, 'departuredate'), true);

    if (depDate) {
      externalLinkParams.departureDate = depDate;
    } else if (flow === ProductGroup.RentalVehicleDamageFlow) {
      externalLinkParams.departureDate = null;
    }

    const returnDate = DateHelpers.parseDate(this.getParameterCaseInsensitive(params, 'returndate'), true);
    if (returnDate) {
      externalLinkParams.returnDate = returnDate;
    } else if (flow === ProductGroup.RentalVehicleDamageFlow) {
      externalLinkParams.returnDate = null;
    }

    const startDate = DateHelpers.parseDate(this.getParameterCaseInsensitive(params, 'startdate'), true);
    if (startDate) {
      externalLinkParams.policyStartDate = startDate;
    }

    const durationOfFirstTrip = this.getParameterCaseInsensitive(params, 'durationoffirsttrip');
    // tslint:disable-next-line:radix
    if (durationOfFirstTrip && parseInt(durationOfFirstTrip)) {
      // tslint:disable-next-line:radix
      externalLinkParams.durationOfFirstTrip = parseInt(durationOfFirstTrip);
    }
    const travellers = this.getParameterCaseInsensitive(params, 'numberoftravellers');
    // tslint:disable-next-line:radix
    if (travellers && parseInt(travellers)) {
      // tslint:disable-next-line:radix
      externalLinkParams.numberOfTravellers = parseInt(travellers);
    }

    // The country and subregion can also be provided in the url.
    // This was added for RVD testing.
    externalLinkParams.country = this.getParameterCaseInsensitive(params, 'country');
    externalLinkParams.subRegion = this.getParameterCaseInsensitive(params, 'subregion');

    return externalLinkParams;
  }

  private static getParameterCaseInsensitive(object, key) {
    return object[
      Object.keys(object).filter(function (k) {
        return k.toLowerCase() === key.toLowerCase();
      })[0]
    ];
  }
}
