import { Component, Input } from '@angular/core';
import { Unsubscribe } from 'amaweb-tsutils';

Unsubscribe();
@Component({
  selector: 'deals-section',
  templateUrl: './deals-section.component.html',
})
export class DealsSectionComponent {
  @Input() anchor: string;
  @Input() title: string;
  constructor() {}
}
