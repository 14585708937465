<div class="full-grey-bg pt-5">
    <div class="row mb-2">
        <div class="col-lg-6 col-md-12 p-2 mb-3">
            <div class="d-flex justify-content-between mb-6">
                <div>
                    <div class="font-weight-bold mb-3 lato-black mem-join-title"
                         [innerHTML]="'coverage.member_join_widget.title' | translate | safeHtml"></div>
                    <div>
                        {{ 'coverage.member_join_widget.paragraph' | translate }}
                    </div>
                    <div class="mt-4 mb-2">
                        <a href="{{ appConfigSetting.join_ama_link }}"
                           target="_blank"
                           (click)="memberJoinClickHandler($event)"
                           class="mem-join-cta btn btn-primary btn-primary2 u-ama-bg-color--dark-blue text-capitalize px-3 py-2">{{ 'memberjoin.join_ama' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-2 mb-3">
            <img id="side"
                 src="https://amaabcaprod.blob.core.windows.net/content/contact-and-help/contact-us/illustration-person-calling-ama.png"
                 class="main-picture"
                 alt="illustration person calling ama" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-12 p-3"
             *ngFor="let widget of widgetViewModel">
            <div class="d-block my-3">
                <img class="widget-icon"
                     [src]="widget.iconUrl"
                     [alt]="'coverage.member_join_widget.' + widget.title | translate" />
            </div>
            <p class="h6 ">{{ 'coverage.member_join_widget.' + widget.title | translate }}</p>
            <p>{{ 'coverage.member_join_widget.' + widget.body | translate }}</p>
        </div>
    </div>
</div>