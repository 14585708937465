<div class="o-stencil__content-sidebar row">
  <div class="c-tds">
    <div class="card">
      <div class="card-header text-center h3">
      </div>
      <div class="c-tds__content card-body">
        <ul class="list-group list-group-flush">
          <li class="c-tds__item list-group-item">
            <div class="c-tds__item-info">
            </div>
          </li>
          <li class="c-tds__item list-group-item">
            <div class="c-tds__item-info">
            </div>
          </li>
          <li class="c-tds__item list-group-item">
            <div class="c-tds__item-info">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>