import { IMedicalQuestionnaire } from 'src/app/models/data.interfaces';
import { SalesFunnelActionType } from 'src/app/store/reducer';
import { IMultiStateUpdateAction } from './trip-details-action-creator.service';

export interface IMedicalQuestionnaireAction {
  type: SalesFunnelActionType;
  payload: IMedicalQuestionnaire;
}

export interface IMedicalQuestionnaireAnswerAction {
  type: SalesFunnelActionType;
  payload: IMedicalQuestionnaireAnswerItem;
}

export interface IMedicalQuestionnaireAnswerItem {
  travellerIndex: number;
  questionID: string;
  answer: string;
}

export function medicalQuestionnairesReducer(
  state: IMedicalQuestionnaire[] = [],
  action: IMedicalQuestionnaireAnswerAction | IMedicalQuestionnaireAction | IMultiStateUpdateAction
): IMedicalQuestionnaire[] {
  // Default the state to an empty list
  state = state || [];

  // Helper function for applying changes to the state
  const tryUpdateState = (type: SalesFunnelActionType, fn: (index: number) => void) => {
    if (action.type === type) {
      const questionnaire = (action as IMedicalQuestionnaireAction).payload;
      fn(state.findIndex((x) => x.relatesToTravellerIndex === questionnaire.relatesToTravellerIndex));
    }
  };

  tryUpdateState(SalesFunnelActionType.CREATE_MEDICAL_QUESTIONNAIRE, (index) => {
    if (index === -1) {
      const questionnaire = (action as IMedicalQuestionnaireAction).payload;
      state = state.concat(questionnaire);
    }
  });

  tryUpdateState(SalesFunnelActionType.UPDATE_MEDICAL_QUESTIONNAIRE, (index) => {
    if (index !== -1) {
      const medQ = action.payload as IMedicalQuestionnaire;

      // When the server responds with a not eligible questionnaire the elements are missing
      // because Orion threw an error. So we should only be mapping the new states.
      if (medQ.isNotEligible) {
        state[index].isNotEligible = medQ.isNotEligible;
        state[index].isCompleted = medQ.isCompleted;
      } else {
        state[index] = medQ;
      }
    }
  });

  tryUpdateState(SalesFunnelActionType.REMOVE_MEDICAL_QUESTIONNAIRE, (index) => {
    if (index !== -1) {
      state.splice(index, 1);
    }
  });

  if (action.type === SalesFunnelActionType.UPDATE_MEDICAL_QUESTIONNAIRE_ANSWER) {
    const answerItem = (action as IMedicalQuestionnaireAnswerAction).payload;
    const questionnaire = state.find((x) => x.relatesToTravellerIndex === answerItem.travellerIndex);
    const stateAnswer = questionnaire.answers.find((a) => a.questionID === answerItem.questionID);
    if (stateAnswer) {
      stateAnswer.answer = answerItem.answer;
    }
    // Any change to the answer should automatically reset the state
    questionnaire.isCompleted = false;
  }

  // Updating then number of travellers could be adding or removing a traveller.
  // In the case of removing a traveller we should also remove their medical questionnaire.
  if (action.type === SalesFunnelActionType.UPDATE_NUMBER_OF_TRAVELLERS) {
    const multiStateUpdate = (action as IMultiStateUpdateAction).payload;
    if (multiStateUpdate.indexOfRemovedTraveller > -1) {
      const indexToRemove = state.findIndex((x) => x.relatesToTravellerIndex === multiStateUpdate.indexOfRemovedTraveller);

      if (indexToRemove > -1) {
        state.splice(indexToRemove, 1);
      }
      // Shift all the medical questionnaires down even if we didn't need to delete one.
      state.forEach((medQ) => {
        if (medQ.relatesToTravellerIndex > multiStateUpdate.indexOfRemovedTraveller) {
          medQ.relatesToTravellerIndex = medQ.relatesToTravellerIndex - 1;
        }
      });
    }
  }

  // After fixing some memory leak issues we noticed the medical questionnaire not reloading properly.
  // This is happening because we are returning the exact same instance of the array
  // which ends up by passing any deep copy checks for distinctUntilChanged which is used internally
  // by the angular redux store library.
  if (state && state.length > 0) {
    return [...state];
  } else {
    return state;
  }
}
