import { OnChanges } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
})
export class StarRatingComponent implements OnInit, OnChanges {
  private static starEmpty = 'star-o';
  private static starFull = 'star';
  private static starHalf = 'star-half-empty';
  public stars;
  @Input() public rating;
  @Input() public readOnly;
  public starRatingModel;

  constructor() {
    this.stars = [];
  }

  // Setup stars as empty
  public setupStars() {
    this.stars = [];
    for (let i = 0; i < 5; i++) {
      this.stars.push({ style: StarRatingComponent.starEmpty, id: i });
    }
  }

  public drawRatingStars() {
    for (let i = 0; i < this.stars.length; i++) {
      if (this.rating - 1 >= i) {
        // Subtract 1 to compensate for array starting at 0
        this.stars[i].style = StarRatingComponent.starFull;
      }

      if (this.rating - 1.5 === i) {
        // Subtract 1.5 to handle array starting at 0 and check for half stars
        this.stars[i + 1].style = StarRatingComponent.starHalf;
      }
    }
  }

  public ngOnInit() {
    this.setupStars();
    // Set rating style
    this.drawRatingStars();
  }

  public ngOnChanges() {
    this.rating = typeof this.rating === 'string' ? parseFloat(this.rating) : this.rating;
  }

  public clickStar(star) {
    if (typeof this.readOnly != 'undefined' && this.readOnly === false) {
      this.setupStars();
      this.rating = star.id + 1;
      this.drawRatingStars();
      this.starRatingModel = star.id + 1;
    }
  }
}
