import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';
import { IGaProduct, IGaPurchase } from 'src/app/models/data.interfaces';

/**
 * IMPORTANT:
 * When using the funnel stream property it is very important to consider
 * when the data is pushed into the dataLayer.
 *
 * The analytics team expects the "VirtualPageview" event
 * to fire after the funnel stream property is set.
 *
 * You can verify that this happens by looking at the
 * window.dataLayer object and looking at the order of events.
 *
 */
export enum FunnelStream {
  Renewal = 'renew-stream',
  RentalVehicleDamage = 'cdw-stream',
  Direct = 'purchase-stream-coverage-entry',
  Widget = 'purchase-stream-trip-details-entry',
  LoadQuote = 'purchase-stream-review-quote-entry',
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private windowService: WindowRefService) {}

  push(data: any) {
    if (this.windowService.dataLayer && this.windowService.dataLayer.push) {
      this.windowService.dataLayer.push(data);
    }
  }

  setFunnelStream(stream: FunnelStream) {
    this.push({ funnelStream: stream });
  }

  setAndSendPageView() {
    this.push({
      event: 'VirtualPageview',
      virtualPageURL: this.windowService.nativeWindow.location.pathname,
      virtualPageTitle: this.windowService.nativeWindow.document.title,
    });
  }

  setAndSendModalView(modalIdentifier: string, modalName: string) {
    this.push({
      event: 'VirtualPageview',
      virtualPageURL: `/modal*${modalIdentifier}`,
      virtualPageTitle: modalName,
    });
  }

  sendOptimizeEvent() {
    this.push({ event: 'optimize.activate' });
  }

  sendMedicalQuestionnaireCompletedEvent() {
    this.push({ event: 'medical-questionnaire-completed' });
  }
  sendPurchaseEvent(purchaseInfo: IGaPurchase, viewedProduct: IGaProduct) {
    this.push({
      event: 'tmi-purchase',
      ecommerce: {
        purchase: {
          actionField: purchaseInfo,
          products: [viewedProduct],
        },
      },
    });
  }
  sendClientError() {
    this.push({
      event: 'client-error',
      virtualPageURL: this.windowService.nativeWindow.location.pathname,
      virtualPageTitle: this.windowService.nativeWindow.document.title,
    });
  }
  sendDocumentReferrer() {
    this.push({
      event: 'referrer-error',
      virtualPageURL: this.windowService.nativeWindow.location.pathname,
      referrer: this.windowService.nativeWindow.document.referrer,
    });
  }
}
