<div class="form-stepper__field"
	 ngbDropdown
	 #dropDown="ngbDropdown"
	 autoClose="outside"
	 [placement]="dropdownConfig.placement">
	<label class="form-stepper__control-label tst-form__control-label">{{data.title}}</label>
	<!--dropdown trigger-->
	<div class="form-stepper__control"
		 [ngClass]="{squash: full}"
		 id="dropdownForm1"
		 ngbDropdownToggle
		 (click)="dropdownConfig.placement = setPlacement()">
		<!--control label-->
		<ng-container *ngFor="let c of controls; let i=index">
			<span [hidden]="c.control.value < 1"
				  class="form-stepper__label-item">
				<span *ngIf="(i > 0 && !controls[i-1].control?.value < 1) || (i > 0 && controls[i-1].control?.value < 1)"
					  class="form-stepper__label-item-comma">
					,
				</span>
				{{c?.control?.value}}
				<span class="form-stepper__label-item-name form-stepper__label-item-name--not-one"
					  *ngIf="c.control.value > 1 || c.control.value < 1">
					{{c.props?.labels[0]}}
				</span>
				<span *ngIf="c.control.value == 1"
					  class="form-stepper__label-item-name form-stepper__label-item-name--one">
					{{c.props?.labels[1]}}
				</span>
			</span>
		</ng-container>

	</div>
	<!--dropdown reveal-->
	<div ngbDropdownMenu
		 aria-labelledby="dropdownForm1">
		<form [formGroup]="data?.form"
			  class="form-stepper__choices">
			<!--controls-->
			<ng-container *ngFor="let c of controls; let i=index">
				<div *ngIf="showThresholdWarning && c.props.config?.threshold"
					 class="form-stepper__threshold-warning col-sm-12 u-text-smaller u-rpadding-y--half">
					<strong>{{c.props?.thresholdWarning}}</strong>
				</div>
				<div class="form-stepper__choice"
					 [ngClass]="c.props?.label">
					<ng-container>
						<span class="form-stepper__choice-label col-sm-6 u-text-align--center">
							{{c.control.value}}
							<span *ngIf="c.control.value > 1">{{c.props?.labels[0]}}</span>
							<span *ngIf="c.control.value < 2">{{c.props?.labels[1]}}</span>
						</span>
						<span class="form-stepper__choice-stepper col-sm-6 u-flex u-flex__justify--center">
							<input type="hidden"
								   [formControl]="c.control"
								   value="c.control.value" />
							<button class="form-stepper__btn decrease"
									(click)="c.control.setValue(decrease({event: $event, control:c}))">
								<span class="tst-icon tst-icon--plus-circle">
									<fa-icon [icon]="['fal', 'minus-circle']"
											 size="1x" />
								</span>
							</button>
							<button class="form-stepper__btn increase"
									(click)="c.control.setValue(increase({event: $event, control:c}))">
								<span class="tst-icon tst-icon--plus-circle">
									<fa-icon [icon]="['fal', 'plus-circle']"
											 size="1x" />
								</span>
							</button>

						</span>
					</ng-container>
				</div>
			</ng-container>

			<!--triggers-->
			<ng-container *ngFor="let c of controls">
				<ng-container *ngIf="c.props?.trigger && c.props?.trigger?.controlType == 'select'">
					<ng-container *ngTemplateOutlet="selectTrigger; context: { $implicit: c.props?.trigger }"></ng-container>
				</ng-container>
			</ng-container>

			<!--messages-->
			<div class="form-stepper__warning"
				 *ngIf="showTotalWarning">{{data?.config?.totalMaxError}} <a class="u-text-xs"
				   (click)="dismissWarning('showTotalWarning')">[dismiss]</a></div>
			<div class="form-stepper__warning"
				 *ngIf="combinedTotalWarning">{{data?.config?.combinedTotals?.warning}} <a class="u-text-xs"
				   (click)="dismissWarning('combinedTotalWarning')">[dismiss]</a></div>
		</form>
	</div>
</div>

<ng-template #selectTrigger
			 let-data="$implicit">
	<div class="form-stepper__trigger u-flex u-flex__wrap u-flex__justify--space-between"
		 *ngIf="data.addItems.length">
		<label class="form-stepper__trigger-label col-sm-12">{{data.label}}</label>
		<ng-container *ngFor="let item of data.addItems">
			<div class="form-stepper__trigger-control col-sm-6">
				<select [formControl]="item.control"
						(change)="triggerChange($event, item, 'select')"
						class="form-stepper__trigger-select">
					<option *ngFor="let opt of data.options"
							[value]="opt.value"
							[selected]="opt.value == item.control.value">{{opt.label}}
					</option>
				</select>
			</div>
		</ng-container>
	</div>
</ng-template>