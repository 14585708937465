<div class="c-infobar u-ama-bg-color--baby-blue"
	 [class.clickable]="hasUrl"
	 (click)="hasUrl && window.open(getUrl, '_blank')">
	<div class="container">
		<div class="row justify-content-center py-4 text-center">
			<div [ngSwitch]="tenant"
				 class="col-12 col-sm-8 col-md-7 col-lg-6">
				<ng-container *ngSwitchCase="tenantEnum.AMA">
					<span class="h3 d-block">{{ path + '.title' | translate }}</span>
					<small>{{path + '.subtitle' | translate}}</small>
				</ng-container>

				<ng-container *ngSwitchCase="tenantEnum.ATA">
					<span class="h3 d-block text-capitalize"
						  [class.clickable]="hasUrl"
						  (click)="hasUrl && window.open(getUrl, '_blank')">{{ path + '.title' | translate }}</span>
				</ng-container>
			</div>
		</div>
	</div>
</div>