import { Component, OnInit, Input } from '@angular/core';
import { IActivity, IAppConfig } from 'src/app/models/data.interfaces';
import { BaseComponent } from 'src/app/components/base-component.component';
import { ActivityService } from 'src/app/core/services/activity.service';
import { Unsubscribe } from 'amaweb-tsutils';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { TenantEnum } from 'src/app/models/tenant.enum';

@Unsubscribe()
@Component({
  selector: 'activity-deal-cards',
  templateUrl: './activity-deal-cards.component.html',
})
export class ActivityDealCardsComponent extends BaseComponent implements OnInit {
  /**
   * Gets or sets a destination name to display in the view. If the country is Canada, this
   * property gets updated with the province being visited.
   */
  public destination: string;
  public showLoading = true;
  public appConfig: IAppConfig;
  public tenant: string;

  @Input() public countryName: string;
  @Input() public subRegionName: string;

  public cards: IActivity[];

  constructor(private readonly activityService: ActivityService, private clubConfigurationService: ClubConfigurationService) {
    super();
    this.appConfig = this.clubConfigurationService.appConfig;
    this.tenant = this.appConfig.settings.tenant;
    this.cards = new Array<IActivity>();
  }

  ngOnInit() {
    // The visitors to canada section never populates the country field.
    // We should just assume you are going to Canada when no country is selected.
    const country = this.countryName || 'canada';
    const subRegion = this.subRegionName || '';

    if (country.toLowerCase() === 'canada') {
      this.destination = subRegion;
    } else {
      this.destination = country;
    }

    this.using(
      this.activityService.getActivities(country, this.subRegionName).subscribe({
        next: (activities: Array<IActivity>) => {
          this.showLoading = false;
          this.cards = this.substituteBookingUrlHost(activities);
        },
        error: () => {
          this.showLoading = false;
          this.cards = [];
        },
      })
    ).attach(this);
  }

  substituteBookingUrlHost(activities: Array<IActivity>) {
    if (this.tenant === TenantEnum.ATA) {
      activities.forEach((activity) => {
        activity.bookingUrl = activity.bookingUrl.replace(/https?:\/\/[^\/]+/, `https://albertateachers.tstllc.net`);
      });
    }
    return activities;
  }
}
