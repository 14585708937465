import { Component, OnInit } from '@angular/core';
import { RenewService } from 'src/app/core/services/renew.service';
import { SalesFunnelActionType, ILoadQuoteAction } from 'src/app/store/reducer';
import { IAppState, InsuranceProduct, IServerAppState } from 'src/app/models/data.interfaces';
import { NgRedux, dispatch } from '@angular-redux-ivy/store';
import { QuoteMapper } from 'src/app/core/services/quote-mapper.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ClubConfigurationService } from 'src/app/core/services/club-configuration.service';
import { BaseComponent } from '../base-component.component';
import { Track } from 'src/app/models/track.enum';
import { addDays } from 'date-fns';
import { updatePolicyStartDateAndPlanDays, updateDepartureDateAndReturnDate } from 'src/app/store/trip-details-common-reductions';
import { DateHelpers } from 'src/app/core/helpers/date-helpers';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'renew-quote',
  templateUrl: './renew-quote.component.html',
})
export class RenewQuoteComponent extends BaseComponent {
  public policyNumber: string;
  public lastName: string;

  public isLoading: boolean;
  public foundPolicy: boolean = null;
  public exceededAttempts: boolean;

  private minimumAgeForSeniors: number;

  constructor(
    private readonly router: Router,
    private readonly reduxStore: NgRedux<IAppState>,
    private readonly renewService: RenewService,
    private readonly quoteMapper: QuoteMapper,
    private readonly clubConfigurationService: ClubConfigurationService
  ) {
    super();
    this.minimumAgeForSeniors = this.clubConfigurationService.appConfig.settings.minimum_age_for_seniors;
  }

  submit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;
    firstValueFrom(this.renewService.getPolicy(this.policyNumber, this.lastName), undefined)
      .then((r) => {
        // clear error state
        this.foundPolicy = true;
        this.exceededAttempts = false;

        if (!r) {
          this.foundPolicy = false;
          return;
        }

        if ((<{ error: string }>r).error != null) {
          this.exceededAttempts = true;
          return;
        }

        const appState: IAppState = this.quoteMapper.mapServerStateToIAppState(<IServerAppState>r);

        // Check if medical questionnaire needs to be filled out
        // Determines if we hit the fast or slow track
        if (
          appState.tripDetails.productSelected === InsuranceProduct.MultiTripMedicalPlan &&
          appState.tripDetails.agesOfTravellers.filter((x) => x.age >= this.minimumAgeForSeniors).length > 0
        ) {
          appState.policyInfo.track = Track.SlowTrack;
          this.reduxStore.dispatch(this.loadPolicy(appState));

          const startDate = DateHelpers.repurchaseMinDate(appState.tripDetails.prevPolicyExpireDate);
          this.updateDates(startDate, appState.tripDetails.durationOfFirstTrip);

          this.router.navigate(['renew', 'trip-details']);
        } else {
          appState.policyInfo.track = Track.FastTrack;
          this.reduxStore.dispatch(this.loadPolicy(appState));

          this.router.navigate(['renew', 'policy-details']);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private loadPolicy(payload: IAppState): ILoadQuoteAction {
    return {
      type: SalesFunnelActionType.REPURCHASE_LOAD_POLICY,
      payload: payload,
    } as ILoadQuoteAction;
  }

  updateDates(startDate: Date, durationOfFirstTrip: number) {
    this.syncPolicyStartDate(startDate, addDays(startDate, durationOfFirstTrip - 1));
    this.syncDepartureDateReturnDate(startDate, durationOfFirstTrip);
  }

  @dispatch() syncPolicyStartDate(departureDate: Date, returnDate: Date) {
    return updatePolicyStartDateAndPlanDays(departureDate, returnDate);
  }

  @dispatch() syncDepartureDateReturnDate(policyStartDate: Date, durationOfFirstTrip: number) {
    return updateDepartureDateAndReturnDate(policyStartDate, durationOfFirstTrip);
  }
}
