<!-- Based on main content section of 'Review Quote' page -->
<div class="o-stencil__review-quote-main row">
  <div class="col">
    <div class="row">
      <div class="col-7 py-4">
        <!-- Title -->
      </div>
      <div class="col bg-white">
        <!-- Title mask -->
      </div>
    </div>
    <span class="row o-stencil__line-break">
    </span>
    <div class="row justify-content-end">
      <div class="col-4 py-3">
        <!-- Sub-title -->
      </div>
      <div class="col bg-white">
        <!-- Sub-title mask-->
      </div>
    </div>
    <span class="row o-stencil__line-break">
    </span>
    <div class="row o-stencil__border-y-1">
      <div class="col py-3 flex-grow-0"
           style="min-width: 3rem">
        <!-- icon -->
      </div>
      <div class="col o-stencil__spacer-3 bg-white">
        <!-- icon right margin -->
      </div>
      <div class="col-4 o-stencil__border-y-2">
        <!-- list item -->
      </div>
      <div class="col bg-white">
        <!-- list item mask -->
      </div>
    </div>
    <div class="row o-stencil__border-y-1">
      <div class="col py-3 flex-grow-0"
           style="min-width: 3rem">
        <!-- icon -->
      </div>
      <div class="col o-stencil__spacer-3 bg-white">
        <!-- icon right margin -->
      </div>
      <div class="col-7 o-stencil__border-y-2">
        <!-- list item -->
      </div>
      <div class="col bg-white">
        <!-- list item mask -->
      </div>
    </div>
    <div class="row o-stencil__border-y-1">
      <div class="col py-3 flex-grow-0"
           style="min-width: 3rem">
        <!-- icon -->
      </div>
      <div class="col o-stencil__spacer-3 bg-white">
        <!-- icon right margin -->
      </div>
      <div class="col-8 col-sm-9 o-stencil__border-y-2">
        <!-- list item -->
      </div>
      <div class="col bg-white">
        <!-- list item mask -->
      </div>
    </div>
    <div class="row o-stencil__border-y-1">
      <div class="col py-3 flex-grow-0"
           style="min-width: 3rem">
        <!-- icon -->
      </div>
      <div class="col o-stencil__spacer-3 bg-white">
        <!-- icon right margin -->
      </div>
      <div class="col-8 o-stencil__border-y-2">
        <!-- list item -->
      </div>
      <div class="col bg-white">
        <!-- list item mask -->
      </div>
    </div>
    <span class="row o-stencil__line-break">
    </span>
    <div class="row">
      <div class="col-12">
        <div class="c-tds c-tds--review-quote mb-0">
          <div class="card">
            <div class="card-header text-center"></div>
            <div class="c-tds__content card-body">
              <ul class="list-group list-group-flush">
                <li class="c-tds__item c-tds__item--trip-data list-group-item d-block">
                </li>
                <li class="c-tds__editable list-group-item">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-12 col-sm-8 col-xl-5">
                        <div class="alert alert-dark">
                          <div class="c-tds__item list-group-item">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="row o-stencil__line-break">
    </span>
    <div class="row">
      <div class="col-12 col-sm-5 py-4">
        <!-- Submit button -->
      </div>
      <div class="col bg-white">
        <!-- Title mask -->
      </div>
    </div>
  </div>
</div>